import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import UserActivityReducer from "../reducers/UserActivityReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { UserActivityConstants } from "../constants/useractivity.constants";
import { saveFile } from "../utils/common";
const initialState = {
  userActivities: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  userActivitySearchResults: [],
  userActivity: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  userActivityAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const UserActivityContext = createContext({
  ...initialState,
  getAllUserActivity: () => {},
  addUserActivity: () => {},
  searchUserActivity: () => {},
  autoSearchUserActivity: () => {},
  getUserActivity: () => {},
  updateUserActivity: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function UserActivityProvider({ children }) {
  const [state, dispatch] = useReducer(UserActivityReducer, initialState);

  const getAllUserActivity = async (payload) => {
    try {
      dispatch({
        type: UserActivityConstants.FETCH_USERACTIVITY_REQUEST,
      });
      const response = await axios.post("/auth/user-activity", payload);

      dispatch({
        type: UserActivityConstants.FETCH_USERACTIVITY_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: UserActivityConstants.FETCH_USERACTIVITY_FAILURE,
      });
    }
  };
  const searchUserActivity = async (payload) => {
    try {
      const response = await axios.post("/auth/user-activity/search", payload);
      dispatch({
        type: UserActivityConstants.USERACTIVITY_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: UserActivityConstants.USERACTIVITY_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: UserActivityConstants.USERACTIVITY_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchUserActivity = async (payload) => {
    try {
      const response = await axios.post("/auth/user-activity/auto-search", payload);
      dispatch({
        type: UserActivityConstants.USERACTIVITY_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: UserActivityConstants.USERACTIVITY_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: UserActivityConstants.USERACTIVITY_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const getUserActivity = async (id) => {
    const response = await axios.get("/auth/user-activity/" + id);
    dispatch({
      type: UserActivityConstants.GET_USERACTIVITY_REQUEST,
    });
    dispatch({
      type: UserActivityConstants.GET_USERACTIVITY_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: UserActivityConstants.DOWNLOAD_USERACTIVITY_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/auth/user-activity/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "email-log." + fileType);
    dispatch({
      type: UserActivityConstants.DOWNLOAD_USERACTIVITY_REQUEST,
    });
    dispatch({
      type: UserActivityConstants.DOWNLOAD_USERACTIVITY_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <UserActivityContext.Provider
      value={{
        ...state,
        getAllUserActivity,
        searchUserActivity,
        autoSearchUserActivity,
        getUserActivity,
        downloadFile,
      }}
    >
      {children}
    </UserActivityContext.Provider>
  );
}

export { UserActivityContext, UserActivityProvider };
