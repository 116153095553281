import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { EmailToUserProvider } from "../contexts/EmailToUserContext";
import { DialogProvider } from "../contexts/DialogContext";

export const EmailToUserComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<EmailToUserProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};

export default EmailToUserComposer;
