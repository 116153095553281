import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Alert as MuiAlert, Grid, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebounce } from "react-use";

import { Formik } from "formik";
import { useDialog } from "../../../../contexts/DialogContext";
import useVillage from "../../../../hooks/useVillage";
import useCity from "../../../../hooks/useCity";
import useTaluk from "../../../../hooks/useTaluk";
import useCountry from "../../../../hooks/useCountry";
import useDistrict from "../../../../hooks/useDistrict";
import usePincode from "../../../../hooks/usePincode";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import { searchPayload } from "../../../../utils/common";
import useStateMaster from "../../../../hooks/useStateMaster";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const Alert = styled(MuiAlert)(spacing);

const VillageFields = React.memo((props) => {
  const { fields } = props;

  console.log("rendered");

  const searchPayloadValue = searchPayload();
  const { autoSearchCountry, countryAutoSearchResults, isLoadingAutoSearch } = useCountry();
  const { autoSearchPincode, pincodeAutoSearchResults, isLoadingPincodeAutoSearch } = usePincode();
  const {
    autoSearchState,
    stateAutoSearchResults,
    isLoadingAutoSearch: isLoadingStateAutoSearch,
  } = useStateMaster();
  const {
    autoSearchDistrict,
    districtAutoSearchResults,
    isLoadingAutoSearch: isLoadingDistrictAutoSearch,
  } = useDistrict();
  const {
    autoSearchTaluk,
    talukAutoSearchResults,
    isLoadingAutoSearch: isLoadingTalukAutoSearch,
  } = useTaluk();
  const {
    autoSearchCity,
    cityAutoSearchResults,
    isLoadingAutoSearch: isLoadingCityAutoSearch,
  } = useCity();

  const [payload, setPayload] = useState(currentPayload);
  const [pincodePayload, setPincodePayload] = useState(currentPayload);
  const [statePayload, setStatePayload] = useState(currentPayload);
  const [districtPayload, setDistrictPayload] = useState(currentPayload);
  const [talukPayload, setTalukPayload] = useState(currentPayload);
  const [cityPayload, setCityPayload] = useState(currentPayload);
  const [countryOptions, setCountryOptions] = useState([]);
  const [pincodeOptions, setPincodeOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [talukOptions, setTalukOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [pincodeValue, setPincodeValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [talukValue, setTalukValue] = useState("");

  useEffect(() => {
    autoSearchCountry(payload);
  }, [payload]);

  useEffect(() => {
    autoSearchPincode(pincodePayload);
  }, [pincodePayload]);

  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();

  const country = watch("country");
  const pincode = watch("pincode");
  const stateMaster = watch("state");
  const district = watch("district");
  const taluk = watch("taluk");
  const city = watch("city");

  const fetchStates = useCallback((param, payloadParams) => {
    console.log(param, "paramparamparam");
    if (param) {
      const payloadValues = {
        ...payloadParams,
        filters: [
          {
            fieldName: "countryId",
            fieldValue: param.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      };
      autoSearchState(payloadValues);
    }
  }, []);

  const fetchDistrict = useCallback((param, payloadParams) => {
    if (param) {
      const payloadValues = {
        ...payloadParams,
        filters: [
          {
            fieldName: "stateId",
            fieldValue: param.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      };
      autoSearchDistrict(payloadValues);
    }
  }, []);
  const fetchTaluk = useCallback((param, payloadParams) => {
    if (param) {
      const payloadValues = {
        ...payloadParams,
        filters: [
          {
            fieldName: "districtId",
            fieldValue: param.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      };
      autoSearchTaluk(payloadValues);
    }
  }, []);
  const fetchCity = useCallback((param, payloadParams) => {
    if (param) {
      const payloadValues = {
        ...payloadParams,
        filters: [
          {
            fieldName: "talukId",
            fieldValue: param.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      };
      autoSearchCity(payloadValues);
    }
  }, []);

  useDebounce(
    () => {
      if (country) {
        setCountryValue(country);
        setStateOptions([]);
      }
    },
    1000,
    [country],
  );

  useDebounce(
    () => {
      if (pincode) {
        setPincodeValue(pincode);
        setStateOptions([]);
      }
    },
    1000,
    [pincode],
  );

  useDebounce(
    () => {
      if (stateMaster) {
        setStateValue(stateMaster);
      }
    },
    1000,
    [stateMaster],
  );

  useDebounce(
    () => {
      if (district) {
        setDistrictValue(district);
      }
    },
    1000,
    [district],
  );
  useDebounce(
    () => {
      if (taluk) {
        setTalukValue(taluk);
      }
    },
    1000,
    [taluk],
  );

  useEffect(() => {
    fetchStates(countryValue, statePayload);
  }, [fetchStates, countryValue, statePayload]);

  useEffect(() => {
    fetchDistrict(stateValue, districtPayload);
  }, [fetchDistrict, stateValue, districtPayload]);

  useEffect(() => {
    fetchTaluk(districtValue, talukPayload);
  }, [fetchTaluk, districtValue, talukPayload]);

  useEffect(() => {
    fetchCity(talukValue, cityPayload);
  }, [fetchCity, talukValue, cityPayload]);

  useEffect(() => {
    if (countryAutoSearchResults.data.length !== 0) {
      //setCountryOptions([...countryOptions, ...countryAutoSearchResults.data]);
      setCountryOptions(countryAutoSearchResults.data);
    }
  }, [countryAutoSearchResults]);

  useEffect(() => {
    if (pincodeAutoSearchResults.data.length !== 0) {
      //setPincodeOptions([...pincodeOptions, ...pincodeAutoSearchResults.data]);
      setPincodeOptions(pincodeAutoSearchResults.data);
    }
  }, [pincodeAutoSearchResults]);

  useEffect(() => {
    if (stateAutoSearchResults.data.length !== 0) {
      //setStateOptions([...stateOptions, ...stateAutoSearchResults.data]);
      setStateOptions(stateAutoSearchResults.data);
    }
  }, [stateAutoSearchResults]);

  useEffect(() => {
    if (districtAutoSearchResults.data.length !== 0) {
      //setDistrictOptions([...districtOptions, ...districtAutoSearchResults.data]);
      setDistrictOptions(districtAutoSearchResults.data);
    }
  }, [districtAutoSearchResults]);

  useEffect(() => {
    if (talukAutoSearchResults.data.length !== 0) {
      //setTalukOptions([...talukOptions, ...talukAutoSearchResults.data]);
      setTalukOptions(talukAutoSearchResults.data);
    }
  }, [talukAutoSearchResults]);

  useEffect(() => {
    if (cityAutoSearchResults.data.length !== 0) {
      //setCityOptions([...cityOptions, ...cityAutoSearchResults.data]);
      setCityOptions(cityAutoSearchResults.data);
    }
  }, [cityAutoSearchResults]);

  return (
    <Grid container spacing={6} className="row">
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={countryOptions}
          label="Country"
          payload={payload}
          setPayload={setPayload}
          loading={isLoadingAutoSearch}
          name="country"
          multiple={false}
          fields={fields}
          defaultValue={country}
          hasNextPage={countryAutoSearchResults.paging.hasNextPage}
        />
      </Grid>
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={stateOptions}
          label="State"
          payload={statePayload}
          setPayload={setStatePayload}
          loading={isLoadingStateAutoSearch}
          name="state"
          multiple={false}
          defaultValue={stateMaster}
          fields={fields}
          hasNextPage={stateAutoSearchResults.paging.hasNextPage}
        />
      </Grid>
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={districtOptions}
          label="District"
          payload={districtPayload}
          setPayload={setDistrictPayload}
          loading={isLoadingDistrictAutoSearch}
          name="district"
          multiple={false}
          defaultValue={district}
          fields={fields}
          hasNextPage={districtAutoSearchResults.paging.hasNextPage}
        />
      </Grid>
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={talukOptions}
          label="Taluk"
          payload={talukPayload}
          setPayload={setTalukPayload}
          loading={isLoadingTalukAutoSearch}
          name="taluk"
          multiple={false}
          fields={fields}
          defaultValue={taluk}
          hasNextPage={talukAutoSearchResults.paging.hasNextPage}
        />
      </Grid>
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={cityOptions}
          label="City"
          payload={cityPayload}
          setPayload={setCityPayload}
          loading={isLoadingCityAutoSearch}
          name="city"
          fields={fields}
          multiple={false}
          defaultValue={city}
          hasNextPage={cityAutoSearchResults.paging.hasNextPage}
        />
      </Grid>
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={pincodeOptions}
          label="Pincode"
          payload={pincodePayload}
          setPayload={setPincodePayload}
          loading={isLoadingPincodeAutoSearch}
          name="pincode"
          multiple={false}
          fields={fields}
          defaultValue={pincode}
          hasNextPage={pincodeAutoSearchResults.paging.hasNextPage}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("village_name")}
          name="villageName"
          control={control}
          fullWidth
          fields={fields}
          variant="outlined"
          errors={errors}
        />
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          name="status"
          label={t("Status")}
          control={control}
          fields={fields}
          fullWidth
          variant="outlined"
          errors={errors}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
});
export default VillageFields;
