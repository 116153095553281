import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../../components/CustomTextField";
import CustomCheckBox from "../../../../components/CustomCheckBox";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
const BasicDetail = () => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  return (
    <Grid container spacing={12} className="row">
      <Grid item md={6}>
        <ReactHookFormSelect
          id="Tanent"
          label="Tanent"
          name="Tanent"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
        >
          <MenuItem value={"Tanent1"}>Tanent1</MenuItem>
          <MenuItem value={"Tanent2"}>Tanent2</MenuItem>
          <MenuItem value={"Tanent2"}>Tanent3</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          label="Branch Name"
          name="branchName"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
        >
          <MenuItem value={"Branch1"}>Branch1</MenuItem>
          <MenuItem value={"Branch2"}>Branch2</MenuItem>
          <MenuItem value={"Branch2"}>Branch3</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Department Name"
          name="departmentName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
        />
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          id="status"
          name="status"
          label="Status"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
        >
          <MenuItem value={"ACTIVE"}>Active</MenuItem>
          <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
};

export default BasicDetail;
