import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { StateMasterProvider } from "../contexts/StateMasterContext";
import { DistrictProvider } from "../contexts/DistrictContext";
import { CountryProvider } from "../contexts/CountryContext";
import { TalukProvider } from "../contexts/TalukContext";
import { CityProvider } from "../contexts/CityContext";
import { VillageProvider } from "../contexts/VillageContext";
import { PincodeProvider } from "../contexts/PincodeContext";
import { AuthProvider } from "../contexts/JWTContext";
import { DialogProvider } from "../contexts/DialogContext";

export const PincodeComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <PincodeProvider key={0} />,
        <VillageProvider key={1} />,
        <CityProvider key={2} />,
        <TalukProvider key={3} />,
        <DistrictProvider key={4} />,
        <CountryProvider key={5} />,
        <StateMasterProvider key={6} />,
        <DialogProvider key={7} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
