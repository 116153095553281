import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";

import SaveCancel from "../../../components/SaveCancel";
import useNasGroup from "../../../hooks/useNasGroup";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import BasicDetail from "./components/BasicDetail";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import useAuth from "../../../hooks/useAuth";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  nasGroupName: Yup.string().required("Required"),

  description: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function AddNasGroup() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const initialValues = {
    nasGroupName: "",
    description: "",
    status: "ACTIVE",
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const { t } = useTranslation();
  const { addNasGroup, isSubmitting } = useNasGroup();
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (user) {
      setValue("tenant", {
        label: user.name,
        value: user.tenantId,
      });
    }
  }, [user]);

  const navigate = useNavigate();

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>C-Panal</Typography>
        <Typography>Nas Group</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  const onSubmit = async (data) => {
    try {
      const nasGroupDTO = {
        status: data.status,
        nasGroupName: data.nasGroupName,
        description: data.description,
      };
      if (data.region != null) {
        nasGroupDTO["region"] = {
          stateId: data.region.value,
          stateName: data.region.label,
        };
      }
      await addNasGroup(nasGroupDTO);
      //setStatus({ sent: true });
      dispatch(showSuccessSnackbar("Nas group added successfully!"));
      reset();
      cancelBtn();
    } catch (err) {
      //setStatus({ sent: false });
      //setErrorSubmit({ submit: err.message });
      dispatch(showErrorSnackbar(err.message));
    }
  };

  const cancelBtn = () => {
    navigate("/radius/nas-group/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={"Add Nas Group"} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={tabValue} onChange={handleTabChange} aria-label="Add Nas">
                    <Tab label="Basic Details" {...a11yProps(0)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <BasicDetail validationFields={validationSchema.fields} />
                  </TabPanel>
                </Box>

                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddNasGroup;
