import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar, showErrorSnackbar } from "../../redux/slices/snackbar";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider } from "react-hook-form";

import SaveCancel from "../../components/SaveCancel";
import useAgreementTracker from "../../hooks/useAgreementTracker";
import AgreementTrackerFields from "./components/AgreementTrackerFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const initialValues = {
  tenant: "",
  dateOfAgreementStr: "",
  docketNumber: "",
  courierType: "",
  courierName: "",
  dateOfSend: "",
  dateOfSendStr: "",
  dateOfExpiryStr: "",
  dateOfExpiry: "",
  dateOfAgreement: "",
};

const validationSchema = Yup.object().shape({
  tenant: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  dateOfAgreement: Yup.string().required("Required"),
  docketNumber: Yup.string().required("Required"),
  courierType: Yup.string().required("Required"),
  courierName: Yup.string().required("Required"),
  dateOfSend: Yup.string().required("Required"),
  dateOfExpiry: Yup.string().required("Required"),
});

function AddAgreementTracker() {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useDispatch();

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const { addAgreementTracker, hasError, errorMessage, isSubmitting } = useAgreementTracker();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      data["tenant"] = {
        tenantId: data.tenant.value,
        tenantName: data.tenant.label,
      };
      await addAgreementTracker(data);
      dispatch(showSuccessSnackbar("Agreement Tracker added Generated successfully!"));
      navigate("/agreement-Tracker/list");
      reset();
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to generate Agreement Tracker"));
    }
  };

  const cancelBtn = () => {
    navigate("/agreement-Tracker/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Agreement Tracker</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_agreement_tracker")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                AgreementTracker added successfully!
              </Alert>
            )}
            {errors && errors.submit && (
              <Alert severity="error" my={3}>
                {errors.submit}
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <AgreementTrackerFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddAgreementTracker;
