import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Alert as MuiAlert, Grid, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebounce } from "react-use";

import useCountry from "../../../../hooks/useCountry";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import useStateMaster from "../../../../hooks/useStateMaster";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const IppoolFields = React.memo((props) => {
  const { fields } = props;

  const { autoSearchCountry, countryAutoSearchResults, isLoadingAutoSearch } = useCountry();
  const {
    autoSearchState,
    stateAutoSearchResults,
    isLoadingAutoSearch: isLoadingStateAutoSearch,
  } = useStateMaster();

  const [payload, setPayload] = useState(currentPayload);
  const [statePayload, setStatePayload] = useState(currentPayload);

  const [stateResults, setStateResults] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [countryValue, setCountryValue] = useState("");

  useEffect(() => {
    autoSearchCountry(payload);
  }, [payload]);

  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext({
    mode: "onChange",
  });

  const country = watch("country");
  const stateMaster = watch("state");

  const fetchStates = useCallback((param, payloadParams) => {
    if (param) {
      const payloadValues = {
        ...payloadParams,
        filters: [
          {
            fieldName: "countryId",
            fieldValue: param.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      };
      autoSearchState(payloadValues);
    }
  }, []);

  useDebounce(
    () => {
      if (country) {
        setCountryValue(country);
        setStateResults([]);
      }
    },
    1000,
    [country],
  );

  useEffect(() => {
    fetchStates(countryValue, statePayload);
  }, [fetchStates, countryValue, statePayload]);

  useEffect(() => {
    if (stateAutoSearchResults.data.length !== 0) {
      setStateResults([...stateResults, ...stateAutoSearchResults.data]);
    }
  }, [stateAutoSearchResults]);

  useEffect(() => {
    if (countryAutoSearchResults.data.length !== 0) {
      setCountryOptions([...countryOptions, ...countryAutoSearchResults.data]);
    }
  }, [countryAutoSearchResults]);

  return (
    <Grid container spacing={6} className="row">
      <Grid item md={6}>
        <CustomTextField
          label={t("ippool_name")}
          name="poolName"
          control={control}
          fullWidth
          fields={fields}
          variant="outlined"
          errors={errors}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("from_ip")}
          name="fromIp"
          control={control}
          fullWidth
          fields={fields}
          variant="outlined"
          errors={errors}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("to_ip")}
          name="toIp"
          control={control}
          fullWidth
          fields={fields}
          variant="outlined"
          errors={errors}
        />
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          name="status"
          label={t("Status")}
          control={control}
          fullWidth
          fields={fields}
          variant="outlined"
          errors={errors}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("ip_comments")}
          name="comments"
          control={control}
          fullWidth
          fields={fields}
          variant="outlined"
          errors={errors}
        />
      </Grid>
    </Grid>
  );
});
export default IppoolFields;
