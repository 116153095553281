import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider } from "react-hook-form";

import SaveCancel from "../../../components/SaveCancel";
import useInventoryManagement from "../../../hooks/useInventoryManagement";
import MaterialFields from "./components/MaterialFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const initialValues = {
  materialName: "",
  uom: "",
  status: "ACTIVE",
};

const validationSchema = Yup.object().shape({
  materialName: Yup.string().required("Required"),
  uom: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function AddMaterials() {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useDispatch();

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const { addInventoryManagement, hasError, errorMessage, isSubmitting } = useInventoryManagement();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      await addInventoryManagement(data);
      dispatch(showSuccessSnackbar("Material added successfully!"));
      navigate("/inventory-mgnt/materials/list");
      reset();
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to add Material"));
    }
  };

  const cancelBtn = () => {
    navigate("/inventory-mgnt/materials/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Inventory Mgnt</Typography>
        <Typography>Materials</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_materials")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                Material added successfully!
              </Alert>
            )}
            {errors && errors.submit && (
              <Alert severity="error" my={3}>
                {errors.submit}
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <MaterialFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddMaterials;
