import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { HotelManagementProvider } from "../contexts/HotelManagementContext";
import { DialogProvider } from "../contexts/DialogContext";
import { TenantProvider } from "../contexts/TenantContext";
import { PlanGroupProvider } from "../contexts/PlanGroupContext";
import { PlanProvider } from "../contexts/PlanContext";

export const HotelManagementComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <HotelManagementProvider key={0} />,
        <TenantProvider key={1} />,
        <PlanGroupProvider key={2} />,
        <PlanProvider key={3} />,
        <DialogProvider key={4} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
export default HotelManagementComposer;
