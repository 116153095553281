import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useComplaint from "../../../hooks/useComplaint";
import styled from "@emotion/styled";
import { Chart } from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import ComplaintFields from "./ComplaintFields";

import { green, red, orange } from "@mui/material/colors";
import {
  Grid,
  flex,
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};
  }
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const BasicInformation = (props) => {
  const { complaintId } = props;
  const { getComplaint, complaint } = useComplaint();

  const { state } = useLocation();
  console.log(state, "location");
  const { t } = useTranslation();

  const fetchData = async () => {
    await getComplaint(complaintId);
  };

  useEffect(() => {
    console.log("fffff");
    fetchData();
  }, [complaintId]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <>
      <Box
        sx={
          {
            // display: "flex",
            // Maxwidth: "100%",
          }
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card sx={{ backgroundColor: "sss" }}>
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large">
                    {/* <MoreVertical /> */}
                  </IconButton>
                }
                title=""
              />
              {/* <Grid container spacing={2} className="row" pt={4}> */}
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      User Name:
                    </TableCell>

                    <TableCell align="right"> {complaint?.userName} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Complaint Type:
                    </TableCell>
                    <TableCell align="right">
                      {complaint?.complaintType?.complaintTypeName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Subject:
                    </TableCell>
                    <TableCell align="right">{complaint?.subject}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Assigned To:
                    </TableCell>
                    <TableCell align="right">{complaint?.employee?.employeeName}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Address:
                    </TableCell>
                    <TableCell align="center">
                      <div style={{ width: "300px", wordWrap: "break-word" }}>
                        {" "}
                        {complaint?.address}{" "}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card sx={{ backgroundColor: "sss" }}>
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large">
                    {/* <MoreVertical /> */}
                  </IconButton>
                }
                title=""
              />
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Customer Name:
                    </TableCell>

                    <TableCell align="right">{complaint?.customerName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Comment:
                    </TableCell>
                    <TableCell align="right">{complaint?.comments}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Priority:
                    </TableCell>
                    <TableCell align="right">{complaint?.priority}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Mobile Number:
                    </TableCell>
                    <TableCell align="right">{complaint?.mobileNo}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Complaint Register:
                    </TableCell>
                    <TableCell align="right">{complaint?.complaintDate}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BasicInformation;
