import { useContext } from "react";

import { PaidInvoiceContext } from "../contexts/PaidInvoiceContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const usePaidInvoice = () => {
  const context = useContext(PaidInvoiceContext);

  if (!context) throw new Error("PaidInvoiceConstants must be placed within PaidInvoicesProvider");

  return context;
};

export default usePaidInvoice;
