//Masters
import NasList from "../pages/radius/nas";
import AddNas from "../pages/radius/nas/add";
import EditNas from "../pages/radius/nas/edit";
import IppoolList from "../pages/radius/ippool";
import AddIppool from "../pages/radius/ippool/add";
import EditIppool from "../pages/radius/ippool/edit";
import PlanList from "../pages/radius/plan";
import AddPlan from "../pages/radius/plan/add";
import EditPlan from "../pages/radius/plan/edit";
import AddonList from "../pages/radius/addon";
import AddAddon from "../pages/radius/addon/add";
import EditAddon from "../pages/radius/addon/edit";
import AssignedPlan from "../pages/radius/assigned_plan";
import AddNasGroup from "../pages/radius/nas_group/add";
import EditNasGroup from "../pages/radius/nas_group/edit";
import NasGroupList from "../pages/radius/nas_group";
import AddPlanGroup from "../pages/radius/plan_group/add";
import EditPlanGroup from "../pages/radius/plan_group/edit";
import PlanGroupList from "../pages/radius/plan_group";
//Composers
import { NasComposer } from "../composers/NasComposer";
import { IppoolComposer } from "../composers/IppoolComposer";
import { PlanComposer } from "../composers/PlanComposer";
import { AddonComposer } from "../composers/AddonComposer";
import { AssignedPlanComposer } from "../composers/AssignedPlanComposer";
import { NasGroupComposer } from "../composers/NasGroupComposer";
import { DashboardComposer } from "../composers/DashboardComposer";
import { PlanGroupComposer } from "../composers/PlanGroupComposer";
// Guards
import AuthGuard from "../components/guards/AuthGuard";
import DashboardLayout from "../layouts/Dashboard";

const RadiusRoutes = {
  path: "radius",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "nas",
      children: [
        {
          path: "add",
          element: (
            <NasComposer>
              <AddNas />
            </NasComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <NasComposer>
              <EditNas />
            </NasComposer>
          ),
        },
        {
          path: "list",
          element: (
            <NasComposer>
              <NasList />
            </NasComposer>
          ),
        },
      ],
    },
    {
      path: "nas-group",
      children: [
        {
          path: "add",
          element: (
            <NasGroupComposer>
              <AddNasGroup />
            </NasGroupComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <NasGroupComposer>
              <EditNasGroup />
            </NasGroupComposer>
          ),
        },
        {
          path: "list",
          element: (
            <NasGroupComposer>
              <NasGroupList />
            </NasGroupComposer>
          ),
        },
      ],
    },
    {
      path: "ippool",
      children: [
        {
          path: "add",
          element: (
            <IppoolComposer>
              <AddIppool />
            </IppoolComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <IppoolComposer>
              <EditIppool />
            </IppoolComposer>
          ),
        },
        {
          path: "list",
          element: (
            <IppoolComposer>
              <IppoolList />
            </IppoolComposer>
          ),
        },
      ],
    },
    {
      path: "plan",
      children: [
        {
          path: "add",
          element: (
            <PlanComposer>
              <AddPlan />
            </PlanComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <PlanComposer>
              <EditPlan />
            </PlanComposer>
          ),
        },
        {
          path: "list",
          element: (
            <PlanComposer>
              <PlanList />
            </PlanComposer>
          ),
        },
      ],
    },
    {
      path: "plan-group",
      children: [
        {
          path: "add",
          element: (
            <PlanGroupComposer>
              <AddPlanGroup />
            </PlanGroupComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <PlanGroupComposer>
              <EditPlanGroup />
            </PlanGroupComposer>
          ),
        },
        {
          path: "list",
          element: (
            <PlanGroupComposer>
              <PlanGroupList />
            </PlanGroupComposer>
          ),
        },
      ],
    },
    {
      path: "addon",
      children: [
        {
          path: "add",
          element: (
            <AddonComposer>
              <AddAddon />
            </AddonComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <AddonComposer>
              <EditAddon />
            </AddonComposer>
          ),
        },
        {
          path: "list",
          element: (
            <AddonComposer>
              <AddonList />
            </AddonComposer>
          ),
        },
      ],
    },
    {
      path: "assigned-plan",
      element: (
        <AssignedPlanComposer>
          <AssignedPlan />
        </AssignedPlanComposer>
      ),
    },
  ],
};

export default RadiusRoutes;
