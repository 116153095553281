export const IpAddressConstants = {
  FETCH_IP_ADDRESS_REQUEST: "FETCH_IP_ADDRESS_REQUEST",
  FETCH_IP_ADDRESS_SUCCESS: "FETCH_IP_ADDRESS_SUCCESS",
  FETCH_IP_ADDRESS_FAILURE: "FETCH_IP_ADDRESS_FAILURE",
  ADD_IP_ADDRESS_REQUEST: "ADD_IP_ADDRESS_REQUEST",
  ADD_IP_ADDRESS_SUCCESS: "ADD_IP_ADDRESS_SUCCESS",
  ADD_IP_ADDRESS_FAILURE: "ADD_IP_ADDRESS_FAILURE",
  IP_ADDRESS_SEARCH_REQUEST: "IP_ADDRESS_SEARCH_REQUEST",
  IP_ADDRESS_SEARCH_SUCCESS: "IP_ADDRESS_SEARCH_SUCCESS",
  IP_ADDRESS_SEARCH_FAILURE: "IP_ADDRESS_SEARCH_FAILURE",
  IP_ADDRESS_AUTO_SEARCH_REQUEST: "IP_ADDRESS_AUTO_SEARCH_REQUEST",
  IP_ADDRESS_AUTO_SEARCH_SUCCESS: "IP_ADDRESS_AUTO_SEARCH_SUCCESS",
  IP_ADDRESS_AUTO_SEARCH_FAILURE: "IP_ADDRESS_AUTO_SEARCH_FAILURE",
  GET_IP_ADDRESS_REQUEST: "GET_IP_ADDRESS_REQUEST",
  GET_IP_ADDRESS_SUCCESS: "GET_IP_ADDRESS_SUCCESS",
  GET_IP_ADDRESS_FAILURE: "GET_IP_ADDRESS_FAILURE",
  UPDATE_IP_ADDRESS_REQUEST: "UPDATE_IP_ADDRESS_REQUEST",
  UPDATE_IP_ADDRESS_SUCCESS: "UPDATE_IP_ADDRESS_SUCCESS",
  UPDATE_IP_ADDRESS_FAILURE: "UPDATE_IP_ADDRESS_FAILURE",
  DOWNLOAD_IP_ADDRESS_REQUEST: "DOWNLOAD_IP_ADDRESS_REQUEST",
  DOWNLOAD_IP_ADDRESS_SUCCESS: "DOWNLOAD_IP_ADDRESS_SUCCESS",
  DOWNLOAD_IP_ADDRESS_FAILURE: "DOWNLOAD_IP_ADDRESS_FAILURE",
  UPLOAD_IP_ADDRESS_REQUEST: "UPLOAD_IP_ADDRESS_REQUEST",
  UPLOAD_IP_ADDRESS_SUCCESS: "UPLOAD_IP_ADDRESS_SUCCESS",
  UPLOAD_IP_ADDRESS_FAILURE: "UPLOAD_IP_ADDRESS_FAILURE",
};
