import { useContext } from "react";

import { PppSecretContext } from "../contexts/PppSecretContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const usePppSecret = () => {
  const context = useContext(PppSecretContext);

  if (!context) throw new Error("PppSecretContext must be placed within PppSecretProvider");

  return context;
};

export default usePppSecret;
