//Masters
import AddPool from "../pages/mikrotik_mgnt/pool/add";
import PoolList from "../pages/mikrotik_mgnt/pool/index";
import AddAddresses from "../pages/mikrotik_mgnt/addresses/add";
import EditAddresses from "../pages/mikrotik_mgnt/addresses/edit";
import AddressesList from "../pages/mikrotik_mgnt/addresses/index";
import AddRoutes from "../pages/mikrotik_mgnt/routes/add";
import EditRoutes from "../pages/mikrotik_mgnt/routes/edit";
import RoutesList from "../pages/mikrotik_mgnt/routes/index";
import AddRadius from "../pages/mikrotik_mgnt/radius/add";
import EditRadius from "../pages/mikrotik_mgnt/radius/edit";
import RadiusList from "../pages/mikrotik_mgnt/radius/index";
import EditPool from "../pages/mikrotik_mgnt/pool/edit";
import AddPppProfile from "../pages/mikrotik_mgnt/ppp_profile/add";
import EditPppProfile from "../pages/mikrotik_mgnt/ppp_profile/edit";
import PppProfileList from "../pages/mikrotik_mgnt/ppp_profile/index";
import PppSecretList from "../pages/mikrotik_mgnt/ppp_secret/index";
import PppServerList from "../pages/mikrotik_mgnt/ppp_server/index";
import AddPppSecret from "../pages/mikrotik_mgnt/ppp_secret/add";
import AddPppServer from "../pages/mikrotik_mgnt/ppp_server/add";
import EditPppServer from "../pages/mikrotik_mgnt/ppp_server/edit";
import EditPppSecret from "../pages/mikrotik_mgnt/ppp_secret/edit";

//Composers
import { IpAddressComposer } from "../composers/IpAddressComposer";
import { DashboardComposer } from "../composers/DashboardComposer";
import { RoutesComposer } from "../composers/RoutesComposer";
import { MkRadiusComposer } from "../composers/MkRadiusComposer";
import { PppProfileComposer } from "../composers/PppProfileComposer";
import { MkIpPoolComposer } from "../composers/MkIpPoolComposer";
import { PppSecretComposer } from "../composers/PppSecretComposer";
import { PppServerComposer } from "../composers/PppServerComposer";

// Guards
import AuthGuard from "../components/guards/AuthGuard";
import DashboardLayout from "../layouts/Dashboard";

const MikrotikMgntRoutes = {
  path: "mikrotik-mgnt",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "pool",
      children: [
        {
          path: "add",
          element: (
            <MkIpPoolComposer>
              <AddPool />
            </MkIpPoolComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <MkIpPoolComposer>
              <EditPool />
            </MkIpPoolComposer>
          ),
        },
        {
          path: "list",
          element: (
            <MkIpPoolComposer>
              <PoolList />
            </MkIpPoolComposer>
          ),
        },
      ],
    },
    {
      path: "addresses",
      children: [
        {
          path: "add",
          element: (
            <IpAddressComposer>
              <AddAddresses />
            </IpAddressComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <IpAddressComposer>
              <EditAddresses />
            </IpAddressComposer>
          ),
        },
        {
          path: "list",
          element: (
            <IpAddressComposer>
              <AddressesList />
            </IpAddressComposer>
          ),
        },
      ],
    },
    {
      path: "routes",
      children: [
        {
          path: "add",
          element: (
            <RoutesComposer>
              <AddRoutes />
            </RoutesComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <RoutesComposer>
              <EditRoutes />
            </RoutesComposer>
          ),
        },
        {
          path: "list",
          element: (
            <RoutesComposer>
              <RoutesList />
            </RoutesComposer>
          ),
        },
      ],
    },
    {
      path: "mk-radius",
      children: [
        {
          path: "add",
          element: (
            <MkRadiusComposer>
              <AddRadius />
            </MkRadiusComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <MkRadiusComposer>
              <EditRadius />
            </MkRadiusComposer>
          ),
        },
        {
          path: "list",
          element: (
            <MkRadiusComposer>
              <RadiusList />
            </MkRadiusComposer>
          ),
        },
      ],
    },
    {
      path: "ppp-profile",
      children: [
        {
          path: "add",
          element: (
            <PppProfileComposer>
              <AddPppProfile />
            </PppProfileComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <PppProfileComposer>
              <EditPppProfile />
            </PppProfileComposer>
          ),
        },
        {
          path: "list",
          element: (
            <PppProfileComposer>
              <PppProfileList />
            </PppProfileComposer>
          ),
        },
      ],
    },
    {
      path: "ppp-secret",
      children: [
        {
          path: "add",
          element: (
            <PppSecretComposer>
              <AddPppSecret />
            </PppSecretComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <PppSecretComposer>
              <EditPppSecret />
            </PppSecretComposer>
          ),
        },
        {
          path: "list",
          element: (
            <PppSecretComposer>
              <PppSecretList />
            </PppSecretComposer>
          ),
        },
      ],
    },
    {
      path: "ppp-server",
      children: [
        {
          path: "add",
          element: (
            <PppServerComposer>
              <AddPppServer />
            </PppServerComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <PppServerComposer>
              <EditPppServer />
            </PppServerComposer>
          ),
        },
        {
          path: "list",
          element: (
            <PppServerComposer>
              <PppServerList />
            </PppServerComposer>
          ),
        },
      ],
    },
  ],
};

export default MikrotikMgntRoutes;
