import { VillageConstants } from "../constants/village.constants";

const VillageReducer = (state, action) => {
  console.log("1212121212121212", state);
  switch (action.type) {
    case VillageConstants.FETCH_VILLAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case VillageConstants.FETCH_VILLAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        states: action.payload.data,
        paging: action.payload.paging,
      };
    case VillageConstants.FETCH_VILLAGE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case VillageConstants.ADD_VILLAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case VillageConstants.ADD_VILLAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case VillageConstants.ADD_VILLAGE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };
    case VillageConstants.EDIT_VILLAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case VillageConstants.EDIT_VILLAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case VillageConstants.EDIT_VILLAGE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };
    case VillageConstants.VILLAGE_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case VillageConstants.VILLAGE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        villageSearchResults: action.payload,
      };
    case VillageConstants.CITY_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case VillageConstants.GET_VILLAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case VillageConstants.GET_VILLAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        village: action.payload,
      };
    case VillageConstants.GET_VILLAGE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case VillageConstants.UPDATE_VILLAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case VillageConstants.UPDATE_VILLAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        village: action.payload,
      };
    case VillageConstants.UPDATE_VILLAGE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case VillageConstants.DOWNLOAD_VILLAGE_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case VillageConstants.DOWNLOAD_VILLAGE_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case VillageConstants.DOWNLOAD_VILLAGE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case VillageConstants.UPLOAD_VILLAGE_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case VillageConstants.UPLOAD_VILLAGE_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case VillageConstants.UPLOAD_VILLAGE_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default VillageReducer;
