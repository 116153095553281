import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import JWTReducer from "../reducers/JWTReducer";
import { SignInConstants } from "../constants/signin.constants";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/auth/my-account");

          console.log(response, "responseresponse");
          const user = response.data;

          console.log(user);

          dispatch({
            type: SignInConstants.INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: SignInConstants.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: SignInConstants.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (username, password) => {
    const response = await axios.post("/auth/signin", {
      username,
      password,
    });
    const { token, user } = response.data;

    //console.log(response, "responseresponse");

    setSession(token);
    dispatch({
      type: SignInConstants.SIGN_IN,
      // payload: {
      //   username: response.data.username,
      //   roles: [{ name: response.data.authorities[0]["authority"] }],
      // },
      payload: {
        username: response.data.username,
        businessType: response.data.authorities[0],
        tenantId: response.data.tenantId,
        name: response.data.name,
      },
    });
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SignInConstants.SIGN_OUT });
  };

  const signUp = async (username, password, firstName, lastName) => {
    const response = await axios.post("/api/auth/sign-up", {
      username,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: SignInConstants.SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
