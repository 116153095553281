import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";

import SaveCancel from "../../../components/SaveCancel";
import SelectAsyncPaginate from "../../../components/SelectAsyncPaginate";
import useSmsTemplate from "../../../hooks/useSmsTemplate";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import SmsTemplateDetail from "./components/SmsTemplateDetail";
import { useDispatch } from "react-redux";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const validationSchema = Yup.object().shape({
  smsTemplateName: Yup.string().required("Required"),
  smsTemplateCode: Yup.string().required("Required"),
  content: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function EditSmsTemplate() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getSmsTemplate, smstemplate, updateSmsTemplate, isSubmitting } = useSmsTemplate();

  React.useEffect(() => {
    if (Object.keys(smstemplate).length !== 0) {
      console.log(smstemplate.status, "smstemplate.status");

      const initialValues = {
        smsTemplateId: smstemplate.smsTemplateId,
        smsTemplateName: smstemplate.smsTemplateName,
        smsTemplateCode: smstemplate.smsTemplateCode,
        content: smstemplate.content,
        status: smstemplate.status,
      };
      reset(initialValues);
    }
    console.log("i am ere12", smstemplate);
  }, [smstemplate]);

  const form = React.useRef();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  // React.useEffect(() => {
  //   if (form.current) {
  //     const { values, errors } = form.current;
  //     console.log(errors, "i am here to update values");
  //     dispatch({
  //       type: "UPDATE_FORM",
  //       payload: {
  //         id: "smsTemplateId",
  //         data: { values, errors },
  //       },
  //     });
  //   }
  // }, [tabValue]);
  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const [currentSmsTemplate, setCurrentSmsTemplate] = useState(null);
  const { state } = useLocation();
  console.log(state, "location");
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      await updateSmsTemplate(data);
      dispatch(showSuccessSnackbar("SmsTemplate  edited successfully!"));
      navigate("/masters/smstemplate/list");
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to edit SmsTemplate "));
    }
  };

  const cancelBtn = () => {
    navigate("/masters/smstemplate/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Master</Typography>
        <Typography>Sms Template</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getSmsTemplate(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_smstemplate")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                SmsTemplate updated successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <SmsTemplateDetail hideAssignee={true} userNameDisabled={true} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

// function EditEmailMaster() {
//   const { t } = useTranslation();
//   return (
//     <React.Fragment>
//       <EditSmsTemplate t={t} />
//     </React.Fragment>
//   );
// }
export default EditSmsTemplate;
