import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import PppProfileReducer from "../reducers/PppProfileReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { PppProfileConstants } from "../constants/pppprofile.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  pppProfiles: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  pppProfileSearchResults: [],
  pppProfile: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  pppProfileAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const PppProfileContext = createContext({
  ...initialState,
  getAllPppProfile: () => {},
  addPppProfile: () => {},
  searchPppProfile: () => {},
  autoSearchPppProfile: () => {},
  getPppProfile: () => {},
  updatePppProfile: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function PppProfileProvider({ children }) {
  const [state, dispatch] = useReducer(PppProfileReducer, initialState);

  const getAllPppProfile = async (payload) => {
    try {
      dispatch({
        type: PppProfileConstants.FETCH_PPP_PROFILE_REQUEST,
      });
      const response = await axios.post("/ppp-profile/list", payload);
      dispatch({
        type: PppProfileConstants.FETCH_PPP_PROFILE_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: PppProfileConstants.FETCH_PPP_PROFILE_FAILURE,
      });
    }
  };
  const searchPppProfile = async (payload) => {
    try {
      const response = await axios.post("/ppp-profile/search", payload);
      dispatch({
        type: PppProfileConstants.PPP_PROFILE_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PppProfileConstants.PPP_PROFILE_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PppProfileConstants.PPP_PROFILE_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchPppProfile = async (payload) => {
    try {
      const response = await axios.post("/ppp-profile/auto-search", payload);
      dispatch({
        type: PppProfileConstants.PPP_PROFILE_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PppProfileConstants.PPP_PROFILE_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PppProfileConstants.PPP_PROFILE_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addPppProfile = async (payload) => {
    const response = await axios.post("/ppp-profile/add", payload);
    dispatch({
      type: PppProfileConstants.ADD_PPP_PROFILE_REQUEST,
    });
    dispatch({
      type: PppProfileConstants.ADD_PPP_PROFILE_SUCCESS,
      payload: response.data,
    });
  };

  const getPppProfile = async (id) => {
    const response = await axios.get("/ppp-profile/" + id);
    dispatch({
      type: PppProfileConstants.GET_PPP_PROFILE_REQUEST,
    });
    dispatch({
      type: PppProfileConstants.GET_PPP_PROFILE_SUCCESS,
      payload: response.data,
    });
  };

  const updatePppProfile = async (payload) => {
    const response = await axios.put("/ppp-profile/update/" + payload.pppProfileId, payload);
    dispatch({
      type: PppProfileConstants.UPDATE_PPP_PROFILE_REQUEST,
    });
    dispatch({
      type: PppProfileConstants.UPDATE_PPP_PROFILE_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: PppProfileConstants.DOWNLOAD_PPP_PROFILE_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/ppp-profile/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "pppProfile." + fileType);
    dispatch({
      type: PppProfileConstants.DOWNLOAD_PPP_PROFILE_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/ppp-profile/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_pppProfile." + fileType);
    dispatch({
      type: PppProfileConstants.DOWNLOAD_PPP_PROFILE_REQUEST,
    });
    dispatch({
      type: PppProfileConstants.DOWNLOAD_PPP_PROFILE_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: PppProfileConstants.UPLOAD_PPP_PROFILE_REQUEST,
      });
      const response = await axios({
        url: "/ppp-profile/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: PppProfileConstants.UPLOAD_PPP_PROFILE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: PppProfileConstants.UPLOAD_PPP_PROFILE_FAILURE,
      });
    }
  };

  return (
    <PppProfileContext.Provider
      value={{
        ...state,
        getAllPppProfile,
        addPppProfile,
        searchPppProfile,
        autoSearchPppProfile,
        getPppProfile,
        updatePppProfile,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </PppProfileContext.Provider>
  );
}

export { PppProfileContext, PppProfileProvider };
