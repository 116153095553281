import { useContext } from "react";

import { UnpaidInvoiceContext } from "../contexts/UnpaidInvoiceContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useUnpaidInvoice = () => {
  const context = useContext(UnpaidInvoiceContext);

  if (!context)
    throw new Error("UnpaidInvoiceConstants must be placed within UnpaidInvoicesProvider");

  return context;
};

export default useUnpaidInvoice;
