import { useContext } from "react";

import { IpAddressContext } from "../contexts/IpAddressContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useIpAddress = () => {
  const context = useContext(IpAddressContext);

  if (!context)
    throw new Error("MikrotikAccessMgntContext must be placed within IpAddressProvider");

  return context;
};

export default useIpAddress;
