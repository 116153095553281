import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  Typography,
  CardHeader,
} from "@mui/material";
import { spacing } from "@mui/system";
import SaveCancel from "../../../components/SaveCancel";
import { useForm, FormProvider } from "react-hook-form";
import usePincode from "../../../hooks/usePincode";
import PincodeFields from "./components/PincodeFields";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);

const initialValues = {
  pincodeName: "",
  status: "ACTIVE",
};

const validationSchema = Yup.object().shape({
  pincodeName: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});
function BasicForm(props) {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });
  const { addPincode, hasError, errorMessage, isSubmitting } = usePincode();

  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      await addPincode(data);
      setStatus({ sent: true });
      reset();
    } catch (err) {
      setStatus({ sent: false });
      setErrorSubmit({ submit: err.message });
    }
  };

  const cancelBtn = () => {
    navigate("/masters/pincode/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_pincode")} subheader={"We can add pincode here"} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                Pincode added successfully!
              </Alert>
            )}
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <PincodeFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}
function AddPincode() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}
export default AddPincode;
