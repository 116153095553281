import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { EmailToTenantProvider } from "../contexts/EmailToTenantContext";
import { DialogProvider } from "../contexts/DialogContext";

export const EmailToTenantComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<EmailToTenantProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};

export default EmailToTenantComposer;
