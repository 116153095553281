import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { PppServerProvider } from "../contexts/PppServerContext";
import { DialogProvider } from "../contexts/DialogContext";
import { NasProvider } from "../contexts/NasContext";
import { InterfaceProvider } from "../contexts/InterfaceContext";
import { PppProfileProvider } from "../contexts/PppProfileContext";

export const PppServerComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <PppServerProvider key={0} />,
        <NasProvider key={1} />,
        <InterfaceProvider key={2} />,
        <PppProfileProvider key={3} />,
        <DialogProvider key={4} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
