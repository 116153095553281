import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { MkRadiusProvider } from "../contexts/MkRadiusContext";
import { DialogProvider } from "../contexts/DialogContext";
import { NasProvider } from "../contexts/NasContext";

export const MkRadiusComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <MkRadiusProvider key={0} />,
        <NasProvider key={1} />,
        <DialogProvider key={2} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
