import { DistrictConstants } from "../constants/district.constants";

const DistrictReducer = (state, action) => {
  console.log("1212121212121212", state);
  switch (action.type) {
    case DistrictConstants.FETCH_DISTRICT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case DistrictConstants.FETCH_DISTRICT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        districts: action.payload.data,
        paging: action.payload.paging,
      };
    case DistrictConstants.FETCH_DISTRICT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case DistrictConstants.ADD_DISTRICT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case DistrictConstants.ADD_DISTRICT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DistrictConstants.ADD_DISTRICT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };
    case DistrictConstants.EDIT_DISTRICT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case DistrictConstants.EDIT_DISTRICT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DistrictConstants.EDIT_DISTRICT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };
    case DistrictConstants.DISTRICT_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case DistrictConstants.DISTRICT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        districtSearchResults: action.payload,
      };
    case DistrictConstants.DISTRICT_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case DistrictConstants.DISTRICT_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoadingAutoSearch: true,
        hasError: false,
      };
    case DistrictConstants.DISTRICT_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoadingAutoSearch: false,
        districtAutoSearchResults: action.payload,
      };
    case DistrictConstants.DISTRICT_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingAutoSearch: false,
      };
    case DistrictConstants.GET_DISTRICT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case DistrictConstants.GET_DISTRICT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        district: action.payload,
      };
    case DistrictConstants.GET_DISTRICT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case DistrictConstants.UPDATE_DISTRICT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case DistrictConstants.UPDATE_DISTRICT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        district: action.payload,
      };
    case DistrictConstants.UPDATE_DISTRICT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case DistrictConstants.DOWNLOAD_DISTRICT_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case DistrictConstants.DOWNLOAD_DISTRICT_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case DistrictConstants.DOWNLOAD_DISTRICT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case DistrictConstants.UPLOAD_DISTRICT_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case DistrictConstants.UPLOAD_DISTRICT_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case DistrictConstants.UPLOAD_DISTRICT_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default DistrictReducer;
