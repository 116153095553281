import { useContext } from "react";

import { LeadContext } from "../contexts/LeadContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useLead = () => {
  const context = useContext(LeadContext);

  if (!context) throw new Error("LeadContext must be placed within LeadProvider");

  return context;
};

export default useLead;
