export const PincodeConstants = {
  FETCH_PINCODE_REQUEST: "FETCH_PINCODE_REQUEST",
  FETCH_PINCODE_SUCCESS: "FETCH_PINCODE_SUCCESS",
  FETCH_PINCODE_FAILURE: "FETCH_PINCODE_FAILURE",
  ADD_PINCODE_REQUEST: "ADD_PINCODE_REQUEST",
  ADD_PINCODE_SUCCESS: "ADD_PINCODE_SUCCESS",
  ADD_PINCODE_FAILURE: "ADD_PINCODE_FAILURE",
  EDIT_PINCODE_REQUEST: "EDIT_PINCODE_REQUEST",
  EDIT_PINCODE_SUCCESS: "EDIT_PINCODE_SUCCESS",
  EDIT_PINCODE_FAILURE: "EDIT_PINCODE_FAILURE",
  GET_PINCODE_REQUEST: "GET_PINCODE_REQUEST",
  GET_PINCODE_SUCCESS: "GET_PINCODE_SUCCESS",
  GET_PINCODE_FAILURE: "GET_PINCODE_FAILURE",
  UPDATE_PINCODE_REQUEST: "UPDATE_PINCODE_REQUEST",
  UPDATE_PINCODE_SUCCESS: "UPDATE_PINCODE_SUCCESS",
  UPDATE_PINCODE_FAILURE: "UPDATE_PINCODE_FAILURE",
  DOWNLOAD_PINCODE_REQUEST: "DOWNLOAD_PINCODE_REQUEST",
  DOWNLOAD_PINCODE_SUCCESS: "DOWNLOAD_PINCODE_SUCCESS",
  DOWNLOAD_PINCODE_FAILURE: "DOWNLOAD_PINCODE_FAILURE",
  PINCODE_SEARCH_REQUEST: "PINCODE_SEARCH_REQUEST",
  PINCODE_SEARCH_SUCCESS: "PINCODE_SEARCH_SUCCESS",
  PINCODE_SEARCH_FAILURE: "PINCODE_SEARCH_FAILURE",
  UPLOAD_PINCODE_REQUEST: "UPLOAD_PINCODE_REQUEST",
  UPLOAD_PINCODE_SUCCESS: "UPLOAD_PINCODE_SUCCESS",
  UPLOAD_PINCODE_FAILURE: "UPLOAD_PINCODE_FAILURE",
  PINCODE_AUTO_SEARCH_REQUEST: "PINCODE_AUTO_SEARCH_REQUEST",
  PINCODE_AUTO_SEARCH_SUCCESS: "PINCODE_AUTO_SEARCH_SUCCESS",
  PINCODE_AUTO_SEARCH_FAILURE: "PINCODE_AUTO_SEARCH_FAILURE",
};
