import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import CustomDataGrid from "./RateDataGrid";
import useRate from "../../../../../hooks/useRate";
import { useDialog } from "../../../../../contexts/DialogContext";
import { getTimeStamp } from "../../../../../utils/common";
import AddRate from "./AddRate";
import EditRate from "./EditRate";

function RateDataGridPage(props) {
  const { planId } = props;
  console.log(planId, "planIdplanId");
  const { getAllRate, rates, paging, isLoading, downloadFile } = useRate();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const currentPayload = {
    filters: [
      {
        fieldName: "planId",
        fieldValue: planId,
        op: "eq",
        dataType: "int",
        isJoin: true,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllRate(payload);
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const addPlan = () => {
    navigate("/radius/plan/add");
  };
  const editPlan = (id) => {
    console.log(id);
    navigate("/radius/plan/edit", { state: { id: id } });
  };
  const exportFile = async (fileType) => {
    await downloadFile(fileType, payload);
  };
  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const openAddDialog = () => {
    return openDialog("add_rate", "Add Rate", <AddRate key={getTimeStamp()} planId={planId} />);
  };
  const openEditDialog = (id) => {
    return openDialog(
      "edit_rate",
      "Edit Rate",
      <EditRate key={getTimeStamp()} planId={planId} rateId={id} />,
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CustomDataGrid
            trans={t}
            addPage={openAddDialog}
            editPage={openEditDialog}
            downloadFile={exportFile}
            openDownloadDialog={null}
            id="rateId"
            data={rates ? rates : []}
            rowCount={paging.totalNumberOfRecords}
            paging={{
              page: paging.pageNumber,
              pageSize: paging.pageSize,
            }}
            onPageChange={onPageChange}
            isLoading={isLoading}
            sortModel={sortModel}
            handleSortModelChange={handleSortModelChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default RateDataGridPage;
