import { UnpaidInvoiceConstants } from "../constants/unpaidinvoice.constants";

const UnpaidInvoicesReducer = (state, action) => {
  switch (action.type) {
    case UnpaidInvoiceConstants.FETCH_UNPAIDINVOICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UnpaidInvoiceConstants.FETCH_UNPAIDINVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unpaidInvoices: action.payload.data,
        paging: action.payload.paging,
      };
    case UnpaidInvoiceConstants.FETCH_UNPAIDINVOICE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UnpaidInvoiceConstants.UNPAIDINVOICE_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UnpaidInvoiceConstants.UNPAIDINVOICE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unpaidInvoiceSearchResults: action.payload,
      };
    case UnpaidInvoiceConstants.UNPAIDINVOICE_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UnpaidInvoiceConstants.UNPAIDINVOICE_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UnpaidInvoiceConstants.UNPAIDINVOICE_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unpaidInvoiceAutoSearchResults: action.payload,
      };
    case UnpaidInvoiceConstants.UNPAIDINVOICE_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UnpaidInvoiceConstants.GET_UNPAIDINVOICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UnpaidInvoiceConstants.GET_UNPAIDINVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unpaidInvoice: action.payload,
      };
    case UnpaidInvoiceConstants.GET_UNPAIDINVOICE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UnpaidInvoiceConstants.COLLECT_USER_REQUEST:
      return {
        ...state,
        isCollectSubmitting: true,
        hasError: false,
      };
    case UnpaidInvoiceConstants.COLLECT_USER_SUCCESS:
      return {
        ...state,
        isCollectSubmitting: false,
      };
    case UnpaidInvoiceConstants.COLLECT_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isCollectSubmitting: false,
        errorMessage: action.payload.message,
      };

    case UnpaidInvoiceConstants.CANCEL_INVOICE_REQUEST:
      return {
        ...state,
        isCancelSubmitting: true,
        hasError: false,
      };
    case UnpaidInvoiceConstants.CANCEL_INVOICE_SUCCESS:
      return {
        ...state,
        isCancelSubmitting: false,
      };
    case UnpaidInvoiceConstants.CANCEL_INVOICE_FAILURE:
      return {
        ...state,
        hasError: true,
        isCancelSubmitting: false,
        errorMessage: action.payload.message,
      };

    case UnpaidInvoiceConstants.DOWNLOAD_UNPAIDINVOICE_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case UnpaidInvoiceConstants.DOWNLOAD_UNPAIDINVOICE_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case UnpaidInvoiceConstants.DOWNLOAD_UNPAIDINVOICE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };

    default:
      return state;
  }
};

export default UnpaidInvoicesReducer;
