export const TenantConstants = {
  FETCH_TENANT_REQUEST: "FETCH_TENANT_REQUEST",
  FETCH_TENANT_SUCCESS: "FETCH_TENANT_SUCCESS",
  FETCH_TENANT_FAILURE: "FETCH_TENANT_FAILURE",
  ADD_TENANT_REQUEST: "ADD_TENANT_REQUEST",
  ADD_TENANT_SUCCESS: "ADD_TENANT_SUCCESS",
  ADD_TENANT_FAILURE: "ADD_TENANT_FAILURE",
  TENANT_SEARCH_REQUEST: "TENANT_SEARCH_REQUEST",
  TENANT_SEARCH_SUCCESS: "TENANT_SEARCH_SUCCESS",
  TENANT_SEARCH_FAILURE: "TENANT_SEARCH_FAILURE",
  GET_TENANT_REQUEST: "GET_TENANT_REQUEST",
  GET_TENANT_SUCCESS: "GET_TENANT_SUCCESS",
  GET_TENANT_FAILURE: "GET_TENANT_FAILURE",
  UPDATE_TENANT_REQUEST: "UPDATE_TENANT_REQUEST",
  UPDATE_TENANT_SUCCESS: "UPDATE_TENANT_SUCCESS",
  UPDATE_TENANT_FAILURE: "UPDATE_TENANT_FAILURE",
  DOWNLOAD_TENANT_REQUEST: "DOWNLOAD_TENANT_REQUEST",
  DOWNLOAD_TENANT_SUCCESS: "DOWNLOAD_TENANT_SUCCESS",
  DOWNLOAD_TENANT_FAILURE: "DOWNLOAD_TENANT_FAILURE",
  UPLOAD_TENANT_REQUEST: "UPLOAD_TENANT_REQUEST",
  UPLOAD_TENANT_SUCCESS: "UPLOAD_TENANT_SUCCESS",
  UPLOAD_TENANT_FAILURE: "UPLOAD_TENANT_FAILURE",
  UPLOAD_INVOICE_LOGO_REQUEST: "UPLOAD_INVOICE_LOGO_REQUEST",
  UPLOAD_INVOICE_LOGO_SUCCESS: "UPLOAD_INVOICE_LOGO_SUCCESS",
  UPLOAD_INVOICE_LOGO_FAILURE: "UPLOAD_INVOICE_LOGO_FAILURE",
  ADD_TENANT_PLAN_REQUEST: "ADD_TENANT_PLAN_REQUEST",
  ADD_TENANT_PLAN_SUCCESS: "ADD_TENANT_PLAN_SUCCESS",
  ADD_TENANT_PLAN_FAILURE: "ADD_TENANT_PLAN_FAILURE",
  ADD_TENANT_IPTV_PLAN_REQUEST: "ADD_TENANT_IPTV_PLAN_REQUEST",
  ADD_TENANT_IPTV_PLAN_SUCCESS: "ADD_TENANT_IPTV_PLAN_SUCCESS",
  ADD_TENANT_IPTV_PLAN_FAILURE: "ADD_TENANT_IPTV_PLAN_FAILURE",
  ADD_TENANT_IPPOOL_REQUEST: "ADD_TENANT_IPPOOL_REQUEST",
  ADD_TENANT_IPPOOL_SUCCESS: "ADD_TENANT_IPPOOL_SUCCESS",
  ADD_TENANT_IPPOOL_FAILURE: "ADD_TENANT_IPPOOL_FAILURE",
  FETCH_TENANT_PLAN_REQUEST: "FETCH_TENANT_PLAN_REQUEST",
  FETCH_TENANT_PLAN_SUCCESS: "FETCH_TENANT_PLAN_SUCCESS",
  FETCH_TENANT_PLAN_FAILURE: "FETCH_TENANT_PLAN_FAILURE",
  FETCH_TENANT_IPTV_PLAN_REQUEST: "FETCH_TENANT_IPTV_PLAN_REQUEST",
  FETCH_TENANT_IPTV_PLAN_SUCCESS: "FETCH_TENANT_IPTV_PLAN_SUCCESS",
  FETCH_TENANT_IPTV_PLAN_FAILURE: "FETCH_TENANT_IPTV_PLAN_FAILURE",
  FETCH_TENANT_IPPOOL_REQUEST: "FETCH_TENANT_IPPOOL_REQUEST",
  FETCH_TENANT_IPPOOL_SUCCESS: "FETCH_TENANT_IPPOOL_SUCCESS",
  FETCH_TENANT_IPPOOL_FAILURE: "FETCH_TENANT_IPPOOL_FAILURE",
  TENANT_AUTO_SEARCH_REQUEST: "TENANT_AUTO_SEARCH_REQUEST",
  TENANT_AUTO_SEARCH_SUCCESS: "TENANT_AUTO_SEARCH_SUCCESS",
  TENANT_AUTO_SEARCH_FAILURE: "TENANT_AUTO_SEARCH_FAILURE",
  TENANT_PLAN_AUTO_SEARCH_REQUEST: "TENANT_PLAN_AUTO_SEARCH_REQUEST",
  TENANT_PLAN_AUTO_SEARCH_SUCCESS: "TENANT_PLAN_AUTO_SEARCH_SUCCESS",
  TENANT_PLAN_AUTO_SEARCH_FAILURE: "TENANT_PLAN_AUTO_SEARCH_FAILURE",
  TENANT_IPTV_PLAN_AUTO_SEARCH_REQUEST: "TENANT_IPTV_PLAN_AUTO_SEARCH_REQUEST",
  TENANT_IPTV_PLAN_AUTO_SEARCH_SUCCESS: "TENANT_IPTV_PLAN_AUTO_SEARCH_SUCCESS",
  TENANT_IPTV_PLAN_AUTO_SEARCH_FAILURE: "TENANT_IPTV_PLAN_AUTO_SEARCH_FAILURE",
  TENANT_PLAN_GROUP_PLAN_AUTO_SEARCH_REQUEST: "TENANT_PLAN_GROUP_PLAN_AUTO_SEARCH_REQUEST",
  TENANT_PLAN_GROUP_PLAN_AUTO_SEARCH_SUCCESS: "TENANT_PLAN_GROUP_PLAN_AUTO_SEARCH_SUCCESS",
  TENANT_PLAN_GROUP_PLAN_AUTO_SEARCH_FAILURE: "TENANT_PLAN_GROUP_PLAN_AUTO_SEARCH_FAILURE",
};
