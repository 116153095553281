import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import EmailMasterReducer from "../reducers/EmailMasterReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { EmailMasterConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  emailMasters: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  emailmasterSearchResults: [],
  emailmaster: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isLoading: true,
  isLoadingDownload: false,
  emailMasterAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const EmailMasterContext = createContext({
  ...initialState,
  getAllEmailMaster: () => {},
  addEmailMaster: () => {},
  searchEmailMaster: () => {},
  getEmailMaster: () => {},
  updateEmailMaster: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  autoSearchEmailMaster: () => {},
});

function EmailMasterProvider({ children }) {
  const [state, dispatch] = useReducer(EmailMasterReducer, initialState);

  const getAllEmailMaster = async (payload) => {
    try {
      dispatch({
        type: EmailMasterConstants.FETCH_EMAILMASTER_REQUEST,
      });
      const response = await axios.post("/master/email-template/list", payload);
      dispatch({
        type: EmailMasterConstants.FETCH_EMAILMASTER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: EmailMasterConstants.FETCH_EMAILMASTER_FAILURE,
      });
    }
  };
  const searchEmailMaster = async () => {
    try {
      const response = await axios.post("/master/email-template/search", initPayload);
      dispatch({
        type: EmailMasterConstants.EMAILMASTER_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: EmailMasterConstants.EMAILMASTER_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: EmailMasterConstants.EMAILMASTER_SEARCH_FAILURE,
      });
    }
  };
  const addEmailMaster = async (payload) => {
    const response = await axios.post("/master/email-template/add", payload);
    dispatch({
      type: EmailMasterConstants.ADD_EMAILMASTER_REQUEST,
    });
    dispatch({
      type: EmailMasterConstants.ADD_EMAILMASTER_SUCCESS,
      payload: response.data,
    });
  };

  const getEmailMaster = async (id) => {
    const response = await axios.get("/master/email-template/" + id);
    dispatch({
      type: EmailMasterConstants.GET_EMAILMASTER_REQUEST,
    });
    dispatch({
      type: EmailMasterConstants.GET_EMAILMASTER_SUCCESS,
      payload: response.data,
    });
  };

  const updateEmailMaster = async (payload) => {
    const response = await axios.put(
      "/master/email-template/update/" + payload.emailTemplateId,
      payload,
    );
    dispatch({
      type: EmailMasterConstants.UPDATE_EMAILMASTER_REQUEST,
    });
    dispatch({
      type: EmailMasterConstants.UPDATE_EMAILMASTER_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: EmailMasterConstants.DOWNLOAD_EMAILMASTER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/master/email-template/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "emailmaster." + fileType);
    dispatch({
      type: EmailMasterConstants.DOWNLOAD_EMAILMASTER_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/master/email-template/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_emailmaster." + fileType);
    dispatch({
      type: EmailMasterConstants.DOWNLOAD_EMAILMASTER_REQUEST,
    });
    dispatch({
      type: EmailMasterConstants.DOWNLOAD_EMAILMASTER_SUCCESS,
      payload: response.data,
    });
  };

  const autoSearchEmailMaster = async (payload) => {
    try {
      const response = await axios.post("/master/email-template/auto-search", payload);
      dispatch({
        type: EmailMasterConstants.EMAILMASTER_AUTO_SEARCH_REQUEST,
      });
      dispatch({
        type: EmailMasterConstants.EMAILMASTER_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: EmailMasterConstants.EMAILMASTER_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: EmailMasterConstants.UPLOAD_EMAILMASTER_REQUEST,
      });
      const response = await axios({
        url: "/master/email-template/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: EmailMasterConstants.UPLOAD_EMAILMASTER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: EmailMasterConstants.UPLOAD_EMAILMASTER_FAILURE,
      });
    }
  };

  return (
    <EmailMasterContext.Provider
      value={{
        ...state,
        getAllEmailMaster,
        addEmailMaster,
        searchEmailMaster,
        getEmailMaster,
        updateEmailMaster,
        downloadFile,
        sampleFile,
        uploadFile,
        autoSearchEmailMaster,
      }}
    >
      {children}
    </EmailMasterContext.Provider>
  );
}

export { EmailMasterContext, EmailMasterProvider };
