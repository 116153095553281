import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";

import { useDialog } from "../../../../contexts/DialogContext";
import useUser from "../../../../hooks/useUser";
import CustomTextField from "../../../../components/CustomTextField";
import CustomCheckBox from "../../../../components/CustomCheckBox";

import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";

const Alert = styled(MuiAlert)(spacing);

const SuspendUser = (props) => {
  const dispatch = useDispatch();
  const { getUser, suspendUser, user, isRenewSubmitting, addAutoRenewal } = useUser();
  const { userId, fetchData } = props;
  const currentPolicyPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  console.log(userId, "sdfghjkfg");

  const methods = useForm({});
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;
  const { unSetDialog, openDialog } = useDialog();

  const onSubmit = async (data) => {
    try {
      const payload = {
        radUserId: userId,
      };
      await suspendUser(payload);
      dispatch(showSuccessSnackbar("User Suspended successfully!"));
      fetchData();
      unSetDialog("suspend");
    } catch (ex) {
      dispatch(showErrorSnackbar(ex.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <i>Do You want to Suspend the User</i>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<ReplayIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Yes
            </Button>

            <Button variant="text" onClick={() => unSetDialog("suspend")} color="primary">
              No
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default SuspendUser;
