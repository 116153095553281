export const EmailLogConstants = {
  FETCH_EMAILLOG_REQUEST: "FETCH_EMAILLOG_REQUEST",
  FETCH_EMAILLOG_SUCCESS: "FETCH_EMAILLOG_SUCCESS",
  FETCH_EMAILLOG_FAILURE: "FETCH_EMAILLOG_FAILURE",
  ADD_EMAILLOG_REQUEST: "ADD_EMAILLOG_REQUEST",
  ADD_EMAILLOG_SUCCESS: "ADD_EMAILLOG_SUCCESS",
  ADD_EMAILLOG_FAILURE: "ADD_EMAILLOG_FAILURE",
  EMAILLOG_SEARCH_REQUEST: "EMAILLOG_SEARCH_REQUEST",
  EMAILLOG_SEARCH_SUCCESS: "EMAILLOG_SEARCH_SUCCESS",
  EMAILLOG_SEARCH_FAILURE: "EMAILLOG_SEARCH_FAILURE",
  EMAILLOG_AUTO_SEARCH_REQUEST: "EMAILLOG_AUTO_SEARCH_REQUEST",
  EMAILLOG_AUTO_SEARCH_SUCCESS: "EMAILLOG_AUTO_SEARCH_SUCCESS",
  EMAILLOG_AUTO_SEARCH_FAILURE: "EMAILLOG_AUTO_SEARCH_FAILURE",
  GET_EMAILLOG_REQUEST: "GET_EMAILLOG_REQUEST",
  GET_EMAILLOG_SUCCESS: "GET_EMAILLOG_SUCCESS",
  GET_EMAILLOG_FAILURE: "GET_EMAILLOG_FAILURE",
  UPDATE_EMAILLOG_REQUEST: "UPDATE_EMAILLOG_REQUEST",
  UPDATE_EMAILLOG_SUCCESS: "UPDATE_EMAILLOG_SUCCESS",
  UPDATE_EMAILLOG_FAILURE: "UPDATE_EMAILLOG_FAILURE",
  DOWNLOAD_EMAILLOG_REQUEST: "DOWNLOAD_EMAILLOG_REQUEST",
  DOWNLOAD_EMAILLOG_SUCCESS: "DOWNLOAD_EMAILLOG_SUCCESS",
  DOWNLOAD_EMAILLOG_FAILURE: "DOWNLOAD_EMAILLOG_FAILURE",
  UPLOAD_EMAILLOG_REQUEST: "UPLOAD_EMAILLOG_REQUEST",
  UPLOAD_EMAILLOG_SUCCESS: "UPLOAD_EMAILLOG_SUCCESS",
  UPLOAD_EMAILLOG_FAILURE: "UPLOAD_EMAILLOG_FAILURE",
};
