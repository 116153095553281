import React from "react";
import { css } from "@mui/system";
import styled from "@emotion/styled";
import { rgba } from "polished";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTheme } from "@mui/material/styles";

const Typography = styled(MuiTypography)(spacing);

const Stats = ({ title, amount, chip, percentagetext, percentagecolor, illustration, bgColor }) => {
  const theme = useTheme();
  console.log(theme, "themethemetheme");
  const Card = styled(MuiCard)`
    position: relative;
    margin-bottom: ${theme.spacing(6)};

    ${(props) =>
      props.illustration &&
      theme.palette.mode !== "dark" &&
      css`
        background: ${rgba(theme.palette.primary.main, 0.125)};
        color: ${theme.palette.primary.main};
      `}
  `;
  const IllustrationImage = styled.img`
    height: 100px;
    position: absolute;
    right: ${theme.spacing(1)};
    bottom: ${theme.spacing(1)};
    display: none;

    ${theme.breakpoints.between("xs", "lg")} {
      display: block;
    }

    @media (min-width: 1600px) {
      display: block;
    }
  `;
  const CardContent = styled(MuiCardContent)`
    position: relative;
    background-color: ${bgColor};
    color: #fff;

    &:last-child {
      padding-bottom: ${theme.spacing(4)};
    }
  `;
  return (
    <Card illustration={illustration}>
      <CardContent bgColor={bgColor}>
        <Typography variant="h6" mb={4}>
          {title}
        </Typography>
        <Typography variant="h3" mb={3}>
          <Box fontWeight="fontWeightRegular">{amount}</Box>
        </Typography>
        {/* <Percentage
          variant="subtitle2"
          color="textSecondary"
          percentagecolor={percentagecolor}
          illustration={illustration}
        >
          <span>{percentagetext}</span> Since last month
        </Percentage> */}
        {/* {!illustration && <Chip label={chip} />} */}
      </CardContent>

      {!!illustration && <IllustrationImage src={illustration} alt="Illustration" />}
    </Card>
  );
};

export default Stats;
