import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { EmailMasterProvider } from "../contexts/EmailMasterContext";
import { DialogProvider } from "../contexts/DialogContext";
import { FormProvider } from "../contexts/EmailMasterFormContext";
import { StateMasterProvider } from "../contexts/StateMasterContext";
import { DistrictProvider } from "../contexts/DistrictContext";
import { CountryProvider } from "../contexts/CountryContext";
import { TalukProvider } from "../contexts/TalukContext";
import { CityProvider } from "../contexts/CityContext";
import { VillageProvider } from "../contexts/VillageContext";
import { PincodeProvider } from "../contexts/PincodeContext";

export const EmailMasterComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <EmailMasterProvider key={0} />,
        <FormProvider key={1} />,
        <DialogProvider key={2} />,
        <PincodeProvider key={3} />,
        <VillageProvider key={4} />,
        <CityProvider key={5} />,
        <TalukProvider key={6} />,
        <DistrictProvider key={7} />,
        <CountryProvider key={8} />,
        <StateMasterProvider key={9} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
