import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider } from "react-hook-form";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import SaveCancel from "../../../components/SaveCancel";
import usePppServer from "../../../hooks/usePppServer";
import PppServerFields from "./components/PppServerFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const initialValues = {
  serviceName: "",
  nas: "",
  mkInterface: "",
  maxMtu: 1480,
  maxMru: 1480,
  mrRu: 1600,
  keepAliveTimeout: 10,
  pap: 1,
  pppProfile: "",
  status: "ACTIVE",
};

const validationSchema = Yup.object().shape({
  serviceName: Yup.string().required("Required"),
  maxMtu: Yup.string().required("Required"),
  maxMru: Yup.string().required("Required"),
  mrRu: Yup.string().required("Required"),
  keepAliveTimeout: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  nas: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  mkInterface: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  pppProfile: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
});

function AddPppServer() {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useDispatch();

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const { addPppServer, hasError, errorMessage, isSubmitting } = usePppServer();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      data["nas"] = {
        nasId: data.nas.value,
        nasName: data.nas.label,
      };
      data["mkInterface"] = {
        interfaceName: data.mkInterface.label,
        interfaceId: data.mkInterface.value,
      };
      data["pppProfile"] = {
        name: data.pppProfile.label,
        pppProfileId: data.pppProfile.value,
      };
      await addPppServer(data);
      dispatch(showSuccessSnackbar("PPP Server added successfully!"));
      navigate("/mikrotik-mgnt/ppp-server/list");
      reset();
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to add PPP Server"));
    }
  };

  const cancelBtn = () => {
    navigate("/mikrotik-mgnt/ppp-server/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Mikrotik Access Mgnt</Typography>
        <Typography>PPP Server</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_pppServer")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                PppServer added successfully!
              </Alert>
            )}
            {errors && errors.submit && (
              <Alert severity="error" my={3}>
                {errors.submit}
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <PppServerFields fields={validationSchema.fields} />
                <br></br>
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddPppServer;
