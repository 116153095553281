import { useContext } from "react";

import { EmailMasterContext } from "../contexts/EmailMasterContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useEmailMaster = () => {
  const context = useContext(EmailMasterContext);

  if (!context) throw new Error("EmailMasterContext must be placed within EmailMasterProvider");

  return context;
};

export default useEmailMaster;
