import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import { useTranslation } from "react-i18next";
import { searchPayload } from "../../../../utils/common";
import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import useStateMaster from "../../../../hooks/useStateMaster";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const BasicDetail = (props) => {
  const { fields } = props;
  const { validationFields } = props;
  const { autoSearchState, stateAutoSearchResults, isLoadingAutoSearch } = useStateMaster();
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const searchPayloadValue = searchPayload();

  const fetchStates = async (stateId) => {
    if (stateId) {
      searchPayloadValue["filters"] = [
        {
          fieldName: "stateId",
          fieldValue: stateId,
          op: "eq",
          dataType: "int",
          condition: "and",
          isJoin: true,
        },
      ];
      await autoSearchState(searchPayloadValue);
    }
  };

  const [stateMasterPayload, setStateMasterPayload] = useState(currentPayload);

  const [stateOptions, setStateOptions] = useState([]);

  const fetchState = async () => {
    await autoSearchState(stateMasterPayload);
  };

  useEffect(() => {
    fetchState();
  }, [stateMasterPayload]);

  const state = watch("state");

  useEffect(() => {
    if (state) {
      fetchStates(state.value);
    }
  }, [state]);

  useEffect(() => {
    if (stateAutoSearchResults.data.length !== 0) {
      const results = [...stateOptions, ...stateAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setStateOptions(filteredResults);
    }
  }, [stateAutoSearchResults]);

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item md={6}>
        <CustomTextField
          label="Nas Group Name"
          name="nasGroupName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={stateOptions}
          label={t("region")}
          payload={stateMasterPayload}
          setPayload={setStateMasterPayload}
          loading={isLoadingAutoSearch}
          name="region"
          multiple={false}
          fields={fields}
          defaultValue={state}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Description"
          name="description"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          id="status"
          name="status"
          label="Status"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"ACTIVE"}>Active</MenuItem>
          <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
};

export default BasicDetail;
