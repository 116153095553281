import { EmailLogConstants } from "../constants/emaillog.constants";

const EmailLogsReducer = (state, action) => {
  switch (action.type) {
    case EmailLogConstants.FETCH_EMAILLOG_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmailLogConstants.FETCH_EMAILLOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emailLogs: action.payload.data,
        paging: action.payload.paging,
      };
    case EmailLogConstants.FETCH_EMAILLOG_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmailLogConstants.EMAILLOG_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmailLogConstants.EMAILLOG_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emailLogSearchResults: action.payload,
      };
    case EmailLogConstants.EMAILLOG_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmailLogConstants.EMAILLOG_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmailLogConstants.EMAILLOG_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emailLogAutoSearchResults: action.payload,
      };
    case EmailLogConstants.EMAILLOG_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmailLogConstants.GET_EMAILLOG_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmailLogConstants.GET_EMAILLOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emailLog: action.payload,
      };
    case EmailLogConstants.GET_EMAILLOG_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmailLogConstants.DOWNLOAD_EMAILLOG_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case EmailLogConstants.DOWNLOAD_EMAILLOG_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case EmailLogConstants.DOWNLOAD_EMAILLOG_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };

    default:
      return state;
  }
};

export default EmailLogsReducer;
