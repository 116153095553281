import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import WalletReducer from "../reducers/WalletReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { WalletConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  walletEntries: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  walletSearchResults: [],
  wallet: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isLoadingDownload: false,
  paymentTrans: {
    actionUrl: "",
  },
  walletAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};

const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const WalletContext = createContext({
  ...initialState,

  addWallet: () => {},
  searchWallet: () => {},
  getAllWallet: () => {},
  getWallet: () => {},
  updateWallet: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  addPaymentTrans: () => {},
});

function WalletProvider({ children }) {
  const [state, dispatch] = useReducer(WalletReducer, initialState);

  const getAllWallet = async (payload) => {
    try {
      dispatch({
        type: WalletConstants.FETCH_WALLET_REQUEST,
      });
      const response = await axios.post("/cpanel/wallet/list", payload);

      dispatch({
        type: WalletConstants.FETCH_WALLET_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: WalletConstants.FETCH_WALLET_FAILURE,
      });
    }
  };

  const searchWallet = async () => {
    try {
      const response = await axios.post("/cpanel/wallet/search", initPayload);
      dispatch({
        type: WalletConstants.WALLET_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: WalletConstants.WALLET_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: WalletConstants.WALLET_SEARCH_FAILURE,
      });
    }
  };
  const addWallet = async (payload) => {
    const response = await axios.post("/cpanel/wallet/add", payload);
    dispatch({
      type: WalletConstants.ADD_WALLET_REQUEST,
    });
    dispatch({
      type: WalletConstants.ADD_WALLET_SUCCESS,
      payload: response.data,
    });
  };

  const addPaymentTrans = async (payload) => {
    const response = await axios.post("/cpanel/ccavenue/transaction-add", payload);
    dispatch({
      type: WalletConstants.ADD_PAYMENT_TRANS_REQUEST,
    });
    dispatch({
      type: WalletConstants.ADD_PAYMENT_TRANS_SUCCESS,
      payload: response.data,
    });
  };

  const getWallet = async (id) => {
    const response = await axios.get("/cpanel/wallet/" + id);
    dispatch({
      type: WalletConstants.GET_WALLET_REQUEST,
    });
    dispatch({
      type: WalletConstants.GET_WALLET_SUCCESS,
      payload: response.data,
    });
  };

  const updateWallet = async (payload) => {
    const response = await axios.put("/cpanel/wallet/update/" + payload.addOnId, payload);
    dispatch({
      type: WalletConstants.UPDATE_WALLET_REQUEST,
    });
    dispatch({
      type: WalletConstants.UPDATE_WALLET_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: WalletConstants.DOWNLOAD_WALLET_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/cpanel/wallet/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filenameeee");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "wallet." + fileType);
    dispatch({
      type: WalletConstants.DOWNLOAD_WALLET_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/cpanel/wallet/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_wallet." + fileType);
    dispatch({
      type: WalletConstants.DOWNLOAD_WALLET_REQUEST,
    });
    dispatch({
      type: WalletConstants.DOWNLOAD_WALLET_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: WalletConstants.UPLOAD_WALLET_REQUEST,
      });
      const response = await axios({
        url: "/cpanel/wallet/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: WalletConstants.UPLOAD_WALLET_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: WalletConstants.UPLOAD_WALLET_FAILURE,
      });
    }
  };

  return (
    <WalletContext.Provider
      value={{
        ...state,
        addWallet,
        searchWallet,
        getAllWallet,
        getWallet,
        updateWallet,
        downloadFile,
        sampleFile,
        uploadFile,
        addPaymentTrans,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
}

export { WalletContext, WalletProvider };
