import { LeadConstants } from "../constants/lead.constants";

const LeadReducer = (state, action) => {
  switch (action.type) {
    case LeadConstants.FETCH_LEAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case LeadConstants.FETCH_LEAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        leads: action.payload.data,
        paging: action.payload.paging,
      };
    case LeadConstants.FETCH_LEAD_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case LeadConstants.LEAD_ASSIGN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case LeadConstants.LEAD_ASSIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LeadConstants.LEAD_ASSIGN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case LeadConstants.FETCH_LEAD_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case LeadConstants.FETCH_LEAD_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        leadHistories: action.payload.data,
        paging: action.payload.paging,
      };
    case LeadConstants.FETCH_LEAD_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case LeadConstants.FETCH_LEAD_STATS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case LeadConstants.FETCH_LEAD_STATS_SUCCESS:
      console.log(action.payload, "leadstatys");
      return {
        ...state,
        isLoading: false,
        leadStats: action.payload,
      };
    case LeadConstants.FETCH_LEAD_STATS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case LeadConstants.LEAD_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case LeadConstants.LEAD_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        LeadSearchResults: action.payload,
      };
    case LeadConstants.LEAD_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case LeadConstants.LEAD_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case LeadConstants.LEAD_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        LeadAutoSearchResults: action.payload,
      };
    case LeadConstants.LEAD_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case LeadConstants.ADD_LEAD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case LeadConstants.ADD_LEAD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case LeadConstants.ADD_LEAD_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case LeadConstants.GET_LEAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case LeadConstants.GET_LEAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lead: action.payload,
      };
    case LeadConstants.GET_LEAD_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case LeadConstants.GET_LEAD_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case LeadConstants.GET_LEAD_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lead: action.payload,
      };
    case LeadConstants.GET_LEAD_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case LeadConstants.GET_LEAD_STATS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case LeadConstants.GET_LEAD_STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lead: action.payload,
      };
    case LeadConstants.GET_LEAD_STATS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case LeadConstants.UPDATE_LEAD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case LeadConstants.UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        lead: action.payload,
      };
    case LeadConstants.UPDATE_LEAD_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case LeadConstants.DOWNLOAD_LEAD_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case LeadConstants.DOWNLOAD_LEAD_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case LeadConstants.DOWNLOAD_LEAD_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case LeadConstants.UPLOAD_LEAD_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case LeadConstants.UPLOAD_LEAD_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case LeadConstants.UPLOAD_LEAD_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default LeadReducer;
