import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import useMainContentDimensions from "../../../utils/mainContentDimensions";
import moment from "moment";
import { convertSecondToDays, formatBytes } from "../../../utils/common";
import useUser from "../../../hooks/useUser";
import useTrafficReport from "../../../hooks/useTrafficReport";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function TrafficReport() {
  const { state } = useLocation();
  const { t } = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();

  const { user, getUser } = useUser();

  const userId = searchParams.get("id");

  const currentPayload = {
    filters: [
      {
        fieldName: "userName",
        fieldValue: user.userName,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
        attributeName: "radUser",
        entityName: "RadUserEntity",
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "id",
        sort: "DESC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  useEffect(() => {
    getUser(userId);
  }, [userId]);

  const { height } = useWindowDimensions();
  const { getAllTrafficReport, trafficReports, paging, downloadFile } = useTrafficReport();
  const { openDialog } = useDialog();
  const { width: mainContentWidth } = useMainContentDimensions();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllTrafficReport(payload);
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "sessionDate",
      headerName: "Date",
      valueGetter: (params) => moment(params).format("DD-MM-YYYY HH:mm a"),
      width: 200,
    },
    {
      field: "onlineTime",
      headerName: "Online Time",
      valueGetter: (params) => convertSecondToDays(params),
      width: 200,
    },
    {
      field: "upload",
      headerName: "Uploaded",
      valueGetter: (params) => formatBytes(params),
      width: 130,
    },
    {
      field: "download",
      headerName: "Downloaded",
      valueGetter: (params) => formatBytes(params),
      width: 130,
    },
    {
      field: "total",
      headerName: "Total",
      valueGetter: (params) => formatBytes(params),
      width: 150,
    },
  ];

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={<Toolbar downloadFile={downloadFile} />}
        title={t("traffic_report")}
        // subheader={breadCrump()}

        downloadFile={downloadFile}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          columns={columns}
          rows={trafficReports}
          getRowId={(row) => row["sessionDate"]}
          rowCount={paging.totalNumberOfRecords}
          loading={false}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </CardContent>
    </Card>
  );
}

export default TrafficReport;
