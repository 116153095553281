import * as React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";

import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
} from "@mui/material";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)} ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  const theme = useTheme();
  return (
    <Wrapper theme={theme}>
      <Grid container spacing={0}>
        <Grid sx={{ display: { xs: "none", md: "block" } }} container item xs={12} md={6}>
          <List>
            <ListItemButton component="a" href="#" theme={theme}>
              <ListItemText primary="Support" theme={theme} />
            </ListItemButton>
            <ListItemButton component="a" href="#" theme={theme}>
              <ListItemText primary="Help Center" theme={theme} />
            </ListItemButton>
            <ListItemButton component="a" href="#" theme={theme}>
              <ListItemText primary="Privacy" theme={theme} />
            </ListItemButton>
            <ListItemButton component="a" href="#" theme={theme}>
              <ListItemText primary="Terms of Service" theme={theme} />
            </ListItemButton>
          </List>
        </Grid>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItemButton theme={theme}>
              {/* <ListItemText primary={`© ${new Date().getFullYear()} - DID INFOTECH PVT LTD`} /> */}
              <ListItemText primary={`© ${new Date().getFullYear()}`} theme={theme} />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
