import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  Typography,
  CardHeader,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import SaveCancel from "../../../components/SaveCancel";
import useCity from "../../../hooks/useCity";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import CityFields from "./components/CityFields";

const Card = styled(MuiCard)(spacing);

const initialValues = {
  cityName: "",
  taluk: "",
  district: "",
  country: "",
  state: "",
  status: "ACTIVE",
};

const validationSchema = Yup.object().shape({
  cityName: Yup.string().required("Required"),
  taluk: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  district: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  state: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  country: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  status: Yup.string().required("Required"),
});

function BasicForm(props) {
  const dispatch = useDispatch();

  const { addCity, hasError, errorMessage, isSubmitting } = useCity();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { t } = props;
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      console.log(data, "submittttt");
      const cityDTO = {
        country: {
          countryId: data.country.value,
        },
        state: {
          stateId: data.state.value,
        },
        district: {
          districtId: data.district.value,
        },
        taluk: {
          talukId: data.taluk.value,
        },
        status: data.status,
        cityName: data.cityName,
      };
      await addCity(cityDTO);
      reset();
      dispatch(showSuccessSnackbar("City added successfully!"));
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
  };

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const cancelBtn = () => {
    navigate("/masters/city/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_city")} subheader={"We can add city here"} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <CityFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

function AddCity() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}

export default AddCity;
