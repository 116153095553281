import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { UnpaidInvoiceProvider } from "../contexts/UnpaidInvoiceContext";
import { DialogProvider } from "../contexts/DialogContext";
import { PlanProvider } from "../contexts/PlanContext";
import { EmployeeProvider } from "../contexts/EmployeeContext";

export const UnpaidInvoiceComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <UnpaidInvoiceProvider key={0} />,
        <PlanProvider key={1} />,
        <EmployeeProvider key={2} />,
        <DialogProvider key={3} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};

export default UnpaidInvoiceComposer;
