import { HotelManagementConstants } from "../constants/hotelmanagement.constants";

const HotelManagementReducer = (state, action) => {
  switch (action.type) {
    case HotelManagementConstants.FETCH_HOTEL_MANAGEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case HotelManagementConstants.FETCH_HOTEL_MANAGEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hotelManagements: action.payload.data,
        paging: action.payload.paging,
      };
    case HotelManagementConstants.FETCH_HOTEL_MANAGEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case HotelManagementConstants.HOTEL_MANAGEMENT_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case HotelManagementConstants.HOTEL_MANAGEMENT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hotelManagementSearchResults: action.payload,
      };
    case HotelManagementConstants.HOTEL_MANAGEMENT_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case HotelManagementConstants.HOTEL_MANAGEMENT_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case HotelManagementConstants.HOTEL_MANAGEMENT_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hotelManagementAutoSearchResults: action.payload,
      };
    case HotelManagementConstants.HOTEL_MANAGEMENT_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case HotelManagementConstants.ADD_HOTEL_MANAGEMENT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case HotelManagementConstants.ADD_HOTEL_MANAGEMENT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case HotelManagementConstants.ADD_HOTEL_MANAGEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };
    case HotelManagementConstants.ADD_HOTEL_MANAGEMENT_ACTIVATE_USER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case HotelManagementConstants.ADD_HOTEL_MANAGEMENT_ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case HotelManagementConstants.ADD_HOTEL_MANAGEMENT_ACTIVATE_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };
    case HotelManagementConstants.ADD_HOTEL_MANAGEMENT_DEACTIVATE_USER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case HotelManagementConstants.ADD_HOTEL_MANAGEMENT_DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case HotelManagementConstants.ADD_HOTEL_MANAGEMENT_DEACTIVATE_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };
    case HotelManagementConstants.GET_HOTEL_MANAGEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case HotelManagementConstants.GET_HOTEL_MANAGEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hotelManagement: action.payload,
      };
    case HotelManagementConstants.GET_HOTEL_MANAGEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case HotelManagementConstants.UPDATE_HOTEL_MANAGEMENT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case HotelManagementConstants.UPDATE_HOTEL_MANAGEMENT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        hotelManagement: action.payload,
      };
    case HotelManagementConstants.UPDATE_HOTEL_MANAGEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case HotelManagementConstants.DOWNLOAD_HOTEL_MANAGEMENT_REQUEST:
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
      };
    case HotelManagementConstants.DOWNLOAD_HOTEL_MANAGEMENT_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case HotelManagementConstants.DOWNLOAD_HOTEL_MANAGEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case HotelManagementConstants.UPLOAD_HOTEL_MANAGEMENT_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case HotelManagementConstants.UPLOAD_HOTEL_MANAGEMENT_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case HotelManagementConstants.UPLOAD_HOTEL_MANAGEMENT_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    case HotelManagementConstants.DISCONNECT_USER_SESSION_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case HotelManagementConstants.DISCONNECT_USER_SESSION_SUCCESS:
      return {
        ...state,
      };
    case HotelManagementConstants.DISCONNECT_USER_SESSION_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default HotelManagementReducer;
