import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Add as AddICon } from "@mui/icons-material";
import { Tooltip, Fab as MuiFab } from "@mui/material";

import { spacing } from "@mui/system";

const Fab = styled(MuiFab)(spacing);

function MappingToolBar(props) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Tooltip title={t("Add")}>
        <Fab mx={2} size="small" color="secondary" aria-label="Delete">
          <AddICon onClick={props.openDialog} />
        </Fab>
      </Tooltip>
    </React.Fragment>
  );
}

export default MappingToolBar;
