import { useContext } from "react";

import { DashboardContext } from "../contexts/DashboardContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useDashboard = () => {
  const context = useContext(DashboardContext);

  if (!context) throw new Error("DashboardContext must be placed within dashboard Provider");

  return context;
};

export default useDashboard;
