import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import BranchReducer from "../reducers/BranchReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { BranchConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  branches: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  branchSearchResults: [],
  branch: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isLoadingDownload: false,
  branchAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const BranchContext = createContext({
  ...initialState,
  getAllBranch: () => {},
  addBranch: () => {},
  searchBranch: () => {},
  getBranch: () => {},
  updateBranch: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  autoSearchBranch: () => {},
});

function BranchProvider({ children }) {
  const [state, dispatch] = useReducer(BranchReducer, initialState);

  const getAllBranch = async (payload) => {
    try {
      dispatch({
        type: BranchConstants.FETCH_BRANCH_REQUEST,
      });
      const response = await axios.post("/cpanel/branch/list", payload);

      dispatch({
        type: BranchConstants.FETCH_BRANCH_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: BranchConstants.FETCH_BRANCH_FAILURE,
      });
    }
  };
  const searchBranch = async () => {
    try {
      const response = await axios.post("/cpanel/branch/search", initPayload);
      dispatch({
        type: BranchConstants.BRANCH_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: BranchConstants.BRANCH_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: BranchConstants.BRANCH_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchBranch = async (payload) => {
    try {
      const response = await axios.post("/cpanel/branch/auto-search", payload);
      dispatch({
        type: BranchConstants.BRANCH_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: BranchConstants.BRANCH_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: BranchConstants.BRANCH_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addBranch = async (payload) => {
    const response = await axios.post("/cpanel/branch/add", payload);
    dispatch({
      type: BranchConstants.ADD_BRANCH_REQUEST,
    });
    dispatch({
      type: BranchConstants.ADD_BRANCH_SUCCESS,
      payload: response.data,
    });
  };

  const getBranch = async (id) => {
    const response = await axios.get("/cpanel/branch/" + id);
    dispatch({
      type: BranchConstants.GET_BRANCH_REQUEST,
    });
    dispatch({
      type: BranchConstants.GET_BRANCH_SUCCESS,
      payload: response.data,
    });
  };

  const updateBranch = async (payload) => {
    const response = await axios.put("/cpanel/branch/update/" + payload.branchId, payload);
    dispatch({
      type: BranchConstants.UPDATE_BRANCH_REQUEST,
    });
    dispatch({
      type: BranchConstants.UPDATE_BRANCH_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: BranchConstants.DOWNLOAD_BRANCH_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/cpanel/branch/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "branch." + fileType);
    dispatch({
      type: BranchConstants.DOWNLOAD_BRANCH_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/cpanel/branch/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_branch." + fileType);
    dispatch({
      type: BranchConstants.DOWNLOAD_BRANCH_REQUEST,
    });
    dispatch({
      type: BranchConstants.DOWNLOAD_BRANCH_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: BranchConstants.UPLOAD_BRANCH_REQUEST,
      });
      const response = await axios({
        url: "/cpanel/branch/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: BranchConstants.UPLOAD_BRANCH_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: BranchConstants.UPLOAD_BRANCH_FAILURE,
      });
    }
  };

  return (
    <BranchContext.Provider
      value={{
        ...state,
        getAllBranch,
        addBranch,
        searchBranch,
        getBranch,
        updateBranch,
        downloadFile,
        sampleFile,
        uploadFile,
        autoSearchBranch,
      }}
    >
      {children}
    </BranchContext.Provider>
  );
}

export { BranchContext, BranchProvider };
