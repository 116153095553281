import { useContext } from "react";

import { NasContext } from "../contexts/NasContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useNas = () => {
  const context = useContext(NasContext);

  if (!context) throw new Error("NasContext must be placed within NasProvider");

  return context;
};

export default useNas;
