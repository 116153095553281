export const MkRadiusConstants = {
  FETCH_MK_RADIUS_REQUEST: "FETCH_MK_RADIUS_REQUEST",
  FETCH_MK_RADIUS_SUCCESS: "FETCH_MK_RADIUS_SUCCESS",
  FETCH_MK_RADIUS_FAILURE: "FETCH_MK_RADIUS_FAILURE",
  ADD_MK_RADIUS_REQUEST: "ADD_MK_RADIUS_REQUEST",
  ADD_MK_RADIUS_SUCCESS: "ADD_MK_RADIUS_SUCCESS",
  ADD_MK_RADIUS_FAILURE: "ADD_MK_RADIUS_FAILURE",
  MK_RADIUS_SEARCH_REQUEST: "MK_RADIUS_SEARCH_REQUEST",
  MK_RADIUS_SEARCH_SUCCESS: "MK_RADIUS_SEARCH_SUCCESS",
  MK_RADIUS_SEARCH_FAILURE: "MK_RADIUS_SEARCH_FAILURE",
  MK_RADIUS_AUTO_SEARCH_REQUEST: "MK_RADIUS_AUTO_SEARCH_REQUEST",
  MK_RADIUS_AUTO_SEARCH_SUCCESS: "MK_RADIUS_AUTO_SEARCH_SUCCESS",
  MK_RADIUS_AUTO_SEARCH_FAILURE: "MK_RADIUS_AUTO_SEARCH_FAILURE",
  GET_MK_RADIUS_REQUEST: "GET_MK_RADIUS_REQUEST",
  GET_MK_RADIUS_SUCCESS: "GET_MK_RADIUS_SUCCESS",
  GET_MK_RADIUS_FAILURE: "GET_MK_RADIUS_FAILURE",
  UPDATE_MK_RADIUS_REQUEST: "UPDATE_MK_RADIUS_REQUEST",
  UPDATE_MK_RADIUS_SUCCESS: "UPDATE_MK_RADIUS_SUCCESS",
  UPDATE_MK_RADIUS_FAILURE: "UPDATE_MK_RADIUS_FAILURE",
  DOWNLOAD_MK_RADIUS_REQUEST: "DOWNLOAD_MK_RADIUS_REQUEST",
  DOWNLOAD_MK_RADIUS_SUCCESS: "DOWNLOAD_MK_RADIUS_SUCCESS",
  DOWNLOAD_MK_RADIUS_FAILURE: "DOWNLOAD_MK_RADIUS_FAILURE",
  UPLOAD_MK_RADIUS_REQUEST: "UPLOAD_MK_RADIUS_REQUEST",
  UPLOAD_MK_RADIUS_SUCCESS: "UPLOAD_MK_RADIUS_SUCCESS",
  UPLOAD_MK_RADIUS_FAILURE: "UPLOAD_MK_RADIUS_FAILURE",
};
