import { KycDocumentConstants } from "../constants/kycdocument.constants";

const KycDocumentsReducer = (state, action) => {
  switch (action.type) {
    case KycDocumentConstants.FETCH_KYCDOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case KycDocumentConstants.FETCH_KYCDOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        kycDocuments: action.payload.data,
        paging: action.payload.paging,
      };
    case KycDocumentConstants.FETCH_KYCDOCUMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case KycDocumentConstants.KYCDOCUMENT_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case KycDocumentConstants.KYCDOCUMENT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        kycDocumentSearchResults: action.payload,
      };
    case KycDocumentConstants.KYCDOCUMENT_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case KycDocumentConstants.UPLOAD_KYCDOCUMENT_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case KycDocumentConstants.UPLOAD_KYCDOCUMENT_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case KycDocumentConstants.UPLOAD_KYCDOCUMENT_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    case KycDocumentConstants.ADD_KYCDOCUMENT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case KycDocumentConstants.ADD_KYCDOCUMENT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case KycDocumentConstants.ADD_KYCDOCUMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case KycDocumentConstants.KYCDOCUMENT_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case KycDocumentConstants.KYCDOCUMENT_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        kycDocumentAutoSearchResults: action.payload,
      };
    case KycDocumentConstants.KYCDOCUMENT_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case KycDocumentConstants.GET_KYCDOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case KycDocumentConstants.GET_KYCDOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        kycDocument: action.payload,
      };
    case KycDocumentConstants.GET_KYCDOCUMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case KycDocumentConstants.DOWNLOAD_KYCDOCUMENT_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case KycDocumentConstants.DOWNLOAD_KYCDOCUMENT_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case KycDocumentConstants.DOWNLOAD_KYCDOCUMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };

    default:
      return state;
  }
};

export default KycDocumentsReducer;
