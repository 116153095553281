import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { isValidToken, setSession } from "../../utils/jwt";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();

  console.log("hlllllllllllllllll");

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  } else {
    const accessToken = window.localStorage.getItem("accessToken");

    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
