import { IppoolConstants } from "../constants/ippool.constants";

const IppoolReducer = (state, action) => {
  switch (action.type) {
    case IppoolConstants.FETCH_IPPOOL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IppoolConstants.FETCH_IPPOOL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ipPools: action.payload.data,
        paging: action.payload.paging,
      };
    case IppoolConstants.FETCH_IPPOOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IppoolConstants.IPPOOL_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IppoolConstants.IPPOOL_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ippoolSearchResults: action.payload,
      };
    case IppoolConstants.IPPOOL_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IppoolConstants.ADD_IPPOOL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IppoolConstants.ADD_IPPOOL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case IppoolConstants.ADD_IPPOOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case IppoolConstants.GET_IPPOOL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IppoolConstants.GET_IPPOOL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ippool: action.payload,
      };
    case IppoolConstants.GET_IPPOOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IppoolConstants.UPDATE_IPPOOL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IppoolConstants.UPDATE_IPPOOL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ippool: action.payload,
      };
    case IppoolConstants.UPDATE_IPPOOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case IppoolConstants.DOWNLOAD_IPPOOL_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case IppoolConstants.DOWNLOAD_IPPOOL_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case IppoolConstants.DOWNLOAD_IPPOOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case IppoolConstants.UPLOAD_IPPOOL_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case IppoolConstants.UPLOAD_IPPOOL_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case IppoolConstants.UPLOAD_IPPOOL_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    case IppoolConstants.IPPOOL_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        autoSearchLoading: true,
        hasError: false,
      };
    case IppoolConstants.IPPOOL_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        autoSearchLoading: false,
        ippoolAutoSearchResults: action.payload,
      };
    case IppoolConstants.IPPOOL_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        autoSearchLoading: false,
      };

    default:
      return state;
  }
};

export default IppoolReducer;
