export const VillageConstants = {
  FETCH_VILLAGE_REQUEST: "FETCH_VILLAGE_REQUEST",
  FETCH_VILLAGE_SUCCESS: "FETCH_VILLAGE_SUCCESS",
  FETCH_VILLAGE_FAILURE: "FETCH_VILLAGE_FAILURE",
  ADD_VILLAGE_REQUEST: "ADD_VILLAGE_REQUEST",
  ADD_VILLAGE_SUCCESS: "ADD_VILLAGE_SUCCESS",
  ADD_VILLAGE_FAILURE: "ADD_VILLAGE_FAILURE",
  EDIT_VILLAGE_REQUEST: "EDIT_VILLAGE_REQUEST",
  EDIT_VILLAGE_SUCCESS: "EDIT_VILLAGE_SUCCESS",
  EDIT_VILLAGE_FAILURE: "EDIT_VILLAGE_FAILURE",
  GET_VILLAGE_REQUEST: "GET_VILLAGE_REQUEST",
  GET_VILLAGE_SUCCESS: "GET_VILLAGE_SUCCESS",
  GET_VILLAGE_FAILURE: "GET_VILLAGE_FAILURE",
  UPDATE_VILLAGE_REQUEST: "UPDATE_VILLAGE_REQUEST",
  UPDATE_VILLAGE_SUCCESS: "UPDATE_VILLAGE_SUCCESS",
  UPDATE_VILLAGE_FAILURE: "UPDATE_VILLAGE_FAILURE",
  VILLAGE_SEARCH_REQUEST: "VILLAGE_SEARCH_REQUEST",
  VILLAGE_SEARCH_SUCCESS: "VILLAGE_SEARCH_SUCCESS",
  VILLAGE_SEARCH_FAILURE: "VILLAGE_SEARCH_FAILURE",
  DOWNLOAD_VILLAGE_REQUEST: "DOWNLOAD_VILLAGE_REQUEST",
  DOWNLOAD_VILLAGE_SUCCESS: "DOWNLOAD_VILLAGE_SUCCESS",
  DOWNLOAD_VILLAGE_FAILURE: "DOWNLOAD_VILLAGE_FAILURE",
  UPLOAD_VILLAGE_REQUEST: "UPLOAD_VILLAGE_REQUEST",
  UPLOAD_VILLAGE_SUCCESS: "UPLOAD_VILLAGE_SUCCESS",
  UPLOAD_VILLAGE_FAILURE: "UPLOAD_VILLAGE_FAILURE",
};
