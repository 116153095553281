import { useContext } from "react";

import { SmsToTenantContext } from "../contexts/SmsToTenantContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useSmsToTenant = () => {
  const context = useContext(SmsToTenantContext);

  if (!context) throw new Error("SmsToTenantConstants must be placed within SmsToTenantsProvider");

  return context;
};

export default useSmsToTenant;
