export const LoginHistoryConstants = {
  FETCH_LOGINHISTORY_REQUEST: "FETCH_LOGINHISTORY_REQUEST",
  FETCH_LOGINHISTORY_SUCCESS: "FETCH_LOGINHISTORY_SUCCESS",
  FETCH_LOGINHISTORY_FAILURE: "FETCH_LOGINHISTORY_FAILURE",
  ADD_LOGINHISTORY_REQUEST: "ADD_LOGINHISTORY_REQUEST",
  ADD_LOGINHISTORY_SUCCESS: "ADD_LOGINHISTORY_SUCCESS",
  ADD_LOGINHISTORY_FAILURE: "ADD_LOGINHISTORY_FAILURE",
  LOGINHISTORY_SEARCH_REQUEST: "LOGINHISTORY_SEARCH_REQUEST",
  LOGINHISTORY_SEARCH_SUCCESS: "LOGINHISTORY_SEARCH_SUCCESS",
  LOGINHISTORY_SEARCH_FAILURE: "LOGINHISTORY_SEARCH_FAILURE",
  LOGINHISTORY_AUTO_SEARCH_REQUEST: "LOGINHISTORY_AUTO_SEARCH_REQUEST",
  LOGINHISTORY_AUTO_SEARCH_SUCCESS: "LOGINHISTORY_AUTO_SEARCH_SUCCESS",
  LOGINHISTORY_AUTO_SEARCH_FAILURE: "LOGINHISTORY_AUTO_SEARCH_FAILURE",
  GET_LOGINHISTORY_REQUEST: "GET_LOGINHISTORY_REQUEST",
  GET_LOGINHISTORY_SUCCESS: "GET_LOGINHISTORY_SUCCESS",
  GET_LOGINHISTORY_FAILURE: "GET_LOGINHISTORY_FAILURE",
  UPDATE_LOGINHISTORY_REQUEST: "UPDATE_LOGINHISTORY_REQUEST",
  UPDATE_LOGINHISTORY_SUCCESS: "UPDATE_LOGINHISTORY_SUCCESS",
  UPDATE_LOGINHISTORY_FAILURE: "UPDATE_LOGINHISTORY_FAILURE",
  DOWNLOAD_LOGINHISTORY_REQUEST: "DOWNLOAD_LOGINHISTORY_REQUEST",
  DOWNLOAD_LOGINHISTORY_SUCCESS: "DOWNLOAD_LOGINHISTORY_SUCCESS",
  DOWNLOAD_LOGINHISTORY_FAILURE: "DOWNLOAD_LOGINHISTORY_FAILURE",
  UPLOAD_LOGINHISTORY_REQUEST: "UPLOAD_LOGINHISTORY_REQUEST",
  UPLOAD_LOGINHISTORY_SUCCESS: "UPLOAD_LOGINHISTORY_SUCCESS",
  UPLOAD_LOGINHISTORY_FAILURE: "UPLOAD_LOGINHISTORY_FAILURE",
};
