import axios from "axios";

console.log(process.env, "process.envprocess.env");

const apiUrl = process.env.REACT_APP_API_URL;

console.log(apiUrl, "apiUrl");

const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || "Something went wrong"),
);

export default axiosInstance;
