import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import useAuth from "../../../hooks/useAuth";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  CardHeader,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";

import SaveCancel from "../../../components/SaveCancel";
import useEmployee from "../../../hooks/useEmployee";
import EmployeeFields from "./components/EmployeeFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  tenant: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  branchName: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  emailId: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  userName: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  mobileNo: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/, "Must be a valid phone number with up to 10 digits"),
  status: Yup.string().required("Required"),

  confirmPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: () =>
      Yup.string()
        .required()
        .oneOf([Yup.ref("password")], "Both password need to be the same"),
  }),
});

function EditEmployee(props) {
  const dispatch = useDispatch();

  const { getEmployee, employee, updateEmployee, isSubmitting } = useEmployee();

  React.useEffect(() => {
    if (Object.keys(employee).length !== 0) {
      console.log(employee.status, "employee.status");
      const initialValues = {
        employeeId: employee.employeeId,
        tenant: {
          label: employee.tenant?.tenantName,
          value: employee.tenant?.tenantId,
        },
        branchName: {
          label: employee.branch?.branchName,
          value: employee.branch?.branchId,
        },
        firstName: employee.firstName,
        lastName: employee.lastName,
        status: employee.status,
        address: employee.address,
        mobileNo: employee.mobileNo,
        emailId: employee.emailId,
        userName: employee.userName,
      };
      reset(initialValues);
    }
  }, [employee]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [status] = useState({
    sent: false,
  });
  const { user } = useAuth();

  const { state } = useLocation();
  console.log(state, "location");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    setValue,
    formState: {},
  } = methods;

  useEffect(() => {
    if (user) {
      setValue("tenant", {
        label: user.name,
        value: user.tenantId,
      });
    }
  }, [user]);

  const onSubmit = async (data) => {
    try {
      data["tenant"] = {
        tenantId: data.tenant.value,
      };

      data["branch"] = {
        branchId: data.branchName.value,
      };
      await updateEmployee(data);

      dispatch(showSuccessSnackbar("Employee  updated successfully!"));
      navigate("/cpanel/employee/list");
    } catch (err) {
      console.log(err, "ddddddddddddd");
      dispatch(showErrorSnackbar("Failed to edit employee "));
    }
  };

  const cancelBtn = () => {
    navigate("/cpanel/employee/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Employee</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getEmployee(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_employee")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                employee updated successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <EmployeeFields fields={validationSchema.fields} disableUserName={true} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditEmployee;
