import React from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";

import EditDelete from "../../../components/EditDelete";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import useUser from "../../../hooks/useUser";
import AdvanceFilter from "./AdvanceFilter";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>User</Typography>
      <Typography>Online</Typography>
    </Breadcrumbs>
  );
};

const CustomDataGrid = (props) => {
  const { isLoadingDownload, downloadFileType } = useUser();

  const { height, width } = useWindowDimensions();
  const { width: mainContentWidth } = useMainContentDimensions();

  const {
    trans,
    data,
    rowCount,
    paging,
    onPageChange,
    isLoading,
    payload,
    setPayload,
    id,
    handleSortModelChange,
    sortModel,
    downloadFile,
    openDownloadDialog,
    refreshPage,
    setFilterResetFunc,
  } = props;
  console.log(rowCount, "loading child component");
  const { openDialog } = useDialog();

  const columns = [
    {
      field: "userName",
      headerName: "User Name",
      renderCell: (params) => {
        console.log(params, "paramsparamsparams");
      },
      width: 300,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 300,
    },
    {
      field: "ipAddress",
      headerName: "Ip Address",
      width: 130,
    },
    {
      field: "mac",
      headerName: "MAC",
      width: 130,
    },

    {
      field: "userPlan",
      headerName: "User Plan",
      width: 300,
    },
    {
      field: "runningPlan",
      headerName: "Running Plan",
      width: 300,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      valueGetter: (params) => moment(params).format("DD-MM-YYYY HH:mm a"),
      width: 130,
    },
    {
      field: "endTime",
      headerName: "End Time",
      valueGetter: (params) => moment(params).format("DD-MM-YYYY HH:mm a"),
      width: 130,
    },
    {
      field: "onlineTime",
      headerName: "Online Time",
      valueGetter: (params) => convertSecondToDays(params),
      width: 130,
    },
    {
      field: "download",
      headerName: "Downloaded",
      valueGetter: (params) => formatBytes(params),
      width: 130,
    },
    {
      field: "upload",
      headerName: "Uploaded",
      valueGetter: (params) => formatBytes(params),
      width: 130,
    },
    {
      field: "total",
      headerName: "Total",
      valueGetter: (params) => formatBytes(params),
      width: 130,
    },
    {
      field: "nasIp",
      headerName: "Nas Ip",
      width: 300,
    },
  ];

  const openFilterDialog = () => {
    return openDialog(
      "advance_Filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={
          <Toolbar
            downloadFile={downloadFile}
            openDownloadDialog={openDownloadDialog}
            refreshPage={refreshPage}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
            openFilterDialog={openFilterDialog}
          />
        }
        title="Online Users"
        subheader={breadCrump()}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          columns={columns}
          rows={data}
          rowCount={rowCount}
          getRowId={(row) => row[id]}
          loading={isLoading}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </CardContent>
    </Card>
  );
};
CustomDataGrid.propTypes = {
  trans: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  paging: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSortModelChange: PropTypes.func.isRequired,
  sortModel: PropTypes.object.isRequired,
};
export default CustomDataGrid;
