import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  CardHeader,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider, useWatch } from "react-hook-form";

import TalukFields from "./components/TalukFields";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

import EditCancel from "../../../components/SaveCancel";
import useTaluk from "../../../hooks/useTaluk";
import { searchPayload } from "../../../utils/common";

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  talukName: Yup.string().required("Required"),
  district: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  state: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  country: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  status: Yup.string().required("Required"),
});

function BasicForm(props) {
  const dispatch = useDispatch();
  const [isSubmitBtn, setIsSubmitBtn] = useState(false);
  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const { getTaluk, taluk, updateTaluk, isSubmitting } = useTaluk();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const fetchData = async () => {
    await getTaluk(state.id);
  };

  const { state } = useLocation();

  React.useEffect(() => {
    if (Object.keys(taluk).length !== 0) {
      const initialValues = {
        talukId: taluk.talukId,
        talukName: taluk.talukName,
        country: {
          value: taluk.district?.state?.country?.countryId,
          label: taluk.district?.state?.country?.countryName,
        },
        state: {
          value: taluk.district?.state?.stateId,
          label: taluk.district?.state?.stateName,
        },
        district: {
          value: taluk.district?.districtId,
          label: taluk.district?.districtName,
        },
        status: taluk.status,
      };
      reset(initialValues);
    }
  }, [taluk]);

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    setIsSubmitBtn(true);
    try {
      const talukDTO = {
        talukId: data.talukId,
        country: {
          countryId: data.country.value,
        },
        state: {
          stateId: data.state.value,
        },
        district: {
          districtId: data.district.value,
        },
        status: data.status,
        talukName: data.talukName,
      };
      //console.log(districtDTO, "districtDTO");
      await updateTaluk(talukDTO);
      dispatch(showSuccessSnackbar("Taluk updated successfully!"));
      navigate("/masters/taluk/list");
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
    return false;
  };

  console.log(state, "location");
  const { t } = props;
  const navigate = useNavigate();

  const cancelBtn = () => {
    navigate("/masters/taluk/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_taluk")} subheader={"We can edit taluk here"} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <TalukFields fields={validationSchema.fields} />

                <EditCancel cancelBtn={cancelBtn} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

function EditTaluk() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}

export default EditTaluk;
