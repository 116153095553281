import React, { useEffect } from "react";
import { Snackbar, Button, IconButton, Alert } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import { clearSnackbar } from "../../redux/slices/snackbar";

function Notification() {
  const dispatch = useDispatch();

  const { isOpen, message, severity } = useSelector((state) => state.snackbar);

  function handleClose() {
    dispatch(clearSnackbar());
  }
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isOpen}
        onClose={handleClose}
        key={new Date().getTime()}
        action={
          <React.Fragment>
            <IconButton aria-label="close" color="inherit" onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
export default Notification;
