import { useContext } from "react";

import { EmployeeContext } from "../contexts/EmployeeContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useEmployee = () => {
  const context = useContext(EmployeeContext);

  if (!context) throw new Error("EmployeeContext must be placed within EmployeeProvider");

  return context;
};

export default useEmployee;
