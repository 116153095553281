import { SmsLogConstants } from "../constants/smslog.constants";

const SmsLogsReducer = (state, action) => {
  switch (action.type) {
    case SmsLogConstants.FETCH_SMSLOG_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsLogConstants.FETCH_SMSLOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsLogs: action.payload.data,
        paging: action.payload.paging,
      };
    case SmsLogConstants.FETCH_SMSLOG_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsLogConstants.SMSLOG_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsLogConstants.SMSLOG_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsLogSearchResults: action.payload,
      };
    case SmsLogConstants.SMSLOG_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsLogConstants.SMSLOG_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsLogConstants.SMSLOG_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsLogAutoSearchResults: action.payload,
      };
    case SmsLogConstants.SMSLOG_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsLogConstants.GET_SMSLOG_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsLogConstants.GET_SMSLOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsLog: action.payload,
      };
    case SmsLogConstants.GET_SMSLOG_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsLogConstants.DOWNLOAD_SMSLOG_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case SmsLogConstants.DOWNLOAD_SMSLOG_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case SmsLogConstants.DOWNLOAD_SMSLOG_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };

    default:
      return state;
  }
};

export default SmsLogsReducer;
