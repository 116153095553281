import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import CustomTextField from "../../../../components/CustomTextField";
import { useLocation } from "react-router-dom";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import { useDialog } from "../../../../contexts/DialogContext";
import useUser from "../../../../hooks/useUser";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useTenant from "../../../../hooks/useTenant";
import useRate from "../../../../hooks/useRate";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import useEmployee from "../../../../hooks/useEmployee";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import useUnpaidInvoice from "../../../../hooks/useUnpaidInvoice";
const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  employee: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  invoiceNo: Yup.string().required("Required"),
  userName: Yup.string().required("Required"),
  receiptNo: Yup.string().required("Required"),
  paidAmount: Yup.string().required("Required"),
  paymentType: Yup.string().required("Required"),
  collectComments: Yup.string().required("Required"),
  collectionDate: Yup.string().required("Required"),
});

const Collect = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [payload, setPayload] = useState(currentPayload);
  const { renewId, refreshPage } = props;

  const { getRenewal, renewal } = useUser();
  const { collectUser, user, iscollectSubmitting } = useUnpaidInvoice();
  const { autoSearchRate, rateAutoSearchResults, isLoadingAutoSearch: rateLoading } = useRate();
  const { autoSearchEmployee, employeeAutoSearchResults, employee } = useEmployee();

  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [employeePayload, setEmployeePayload] = useState(currentPayload);

  React.useEffect(() => {
    console.log(renewal, " values: formValues,");

    if (Object.keys(renewal).length !== 0) {
      const initialValues = {
        renewId: renewId,
        userName: renewal.userName,
        invoiceNo: renewal.invoiceNo,
        paidAmount: renewal.finalInvoiceAmount,
        collectionDateTemp: "",
        collectionDate: "",
      };
      //console.log(initialValues, "initialValues");
      reset(initialValues);
    }
  }, [renewal]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;
  const { unSetDialog, openDialog } = useDialog();

  const onSubmit = async (data) => {
    try {
      data["collectionDate"] = data.collectionDateTemp;
      data["collectedBy"] = data.employee?.label;
      await collectUser(data);
      dispatch(showSuccessSnackbar("renewal Collected successfully!"));
      unSetDialog("collect");
      refreshPage();
    } catch (ex) {
      dispatch(showErrorSnackbar(ex.message));
    }
  };

  const fetchData = async () => {
    await getRenewal(renewId);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchEmployee = async () => {
    await autoSearchEmployee(employeePayload);
  };
  useEffect(() => {
    fetchEmployee();
  }, [employeePayload]);

  useEffect(() => {
    console.log(employeeAutoSearchResults, "employeeAutoSearchResults");
    if (employeeAutoSearchResults && employeeAutoSearchResults.data.length !== 0) {
      const results = [...employeeOptions, ...employeeAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setEmployeeOptions(filteredResults);
    }
  }, [employeeAutoSearchResults]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <CustomTextField
                label="Invoice No"
                name="invoiceNo"
                control={control}
                disabled={true}
                fullWidth
                fields={validationSchema.fields}
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                label="User Name"
                name="userName"
                control={control}
                disabled={true}
                fullWidth
                fields={validationSchema.fields}
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                name="receiptNo"
                label="Receipt No"
                control={control}
                fields={validationSchema.fields}
                fullWidth
                variant="outlined"
                errors={errors}
              ></CustomTextField>
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                label="Amount"
                name="paidAmount"
                control={control}
                disabled={true}
                fullWidth
                fields={validationSchema.fields}
                variant="outlined"
                errors={errors}
              />
            </Grid>

            <Grid item md={6}>
              <SearchableAutoComplete
                name="employee"
                label="Collected By"
                control={control}
                data={employeeOptions}
                payload={employeePayload}
                setPayload={setEmployeePayload}
                loading={false}
                multiple={false}
                defaultValue={""}
                hasNextPage={false}
                fields={validationSchema.fields}
                fullWidth
                variant="outlined"
                errors={errors}
              ></SearchableAutoComplete>
            </Grid>
            <Grid item md={6} sm={6} xs={8}>
              <CustomDatePicker
                name="collectionDate"
                nameTemp="collectionDateTemp"
                label="Collection Date"
                range={false}
                control={control}
                fields={validationSchema.fields}
              />
            </Grid>
            <Grid item md={12}>
              <ReactHookFormSelect
                name="paymentType"
                label="Payment Type"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
                fields={validationSchema.fields}
              >
                <MenuItem value={"CASH"}>Cash</MenuItem>
                <MenuItem value={"CHEQUE"}>Cheque</MenuItem>
                <MenuItem value={"ONLINE"}>Online</MenuItem>
              </ReactHookFormSelect>
            </Grid>

            <Grid item md={12}>
              <CustomTextField
                label="Notes"
                name="collectComments"
                control={control}
                fullWidth
                fields={validationSchema.fields}
                variant="outlined"
                errors={errors}
                rows={6}
                multiline={true}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<ReplayIcon />}
              endIcon={iscollectSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Collect
            </Button>

            <Button variant="text" onClick={() => unSetDialog("collect")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default Collect;
