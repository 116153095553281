import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";

import SaveCancel from "../../../components/SaveCancel";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import CardInfo from "./components/CardInfo";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import useAuth from "../../../hooks/useAuth";
import PackageInfo from "./components/PlanInfo";
import useCardSystem from "../../../hooks/useCardSystem";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  plan: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  tenant: Yup.object()
    .shape({
      label: Yup.string().required("Tenant is required"),
      value: Yup.string().required("Tenant is required"),
    })
    .nullable()
    .required("Tenant is required"),
  quantity: Yup.string().required("Required"),
  cardValue: Yup.string().required("Required"),
  prefix: Yup.string().required("Required"),
  pinLength: Yup.string().required("Required"),
  passwordLength: Yup.string().required("Required"),
  validTill: Yup.string().required("Required"),
});

function AddCardSystem() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const initialValues = {
    tenant: "",
    plan: "",
    quantity: "",
    status: "ACTIVE",
    cardValue: "",
    prefix: "",
    pinLength: "",
    passwordLength: "",
    validTill: "",
    validTillTemp: "",
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });
  const { t } = useTranslation();
  const { addCardSystem, isSubmitting } = useCardSystem();
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Card System</Typography>
        <Typography>Generate Card</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  const onSubmit = async (data) => {
    try {
      data["tenant"] = {
        tenantId: data.tenant.value,
        tenantName: data.tenant.label,
      };
      data["plan"] = {
        planId: data.plan.value,
        planName: data.plan.label,
      };
      console.log(data, "card value updated");
      await addCardSystem(data);
      dispatch(showSuccessSnackbar("Card Generated successfully!"));
      navigate("/card-System/generate-card/list");
      reset();
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to generate card"));
    }
  };

  const cancelBtn = () => {
    navigate("/card-System/generate-card/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_generate_card")} subheader={breadCrump()} />
          <CardContent>
            {/* {status && status.sent && (
                <Alert severity="success" my={3}>
                 CardSystem added successfully!
                </Alert>
              )} */}
            {/* {errorSubmit && errorSubmit.submit && (
                <Alert severity="error" my={3}>
                  {errorSubmit.submit}
                </Alert>
              )} */}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={tabValue} onChange={handleTabChange} aria-label="Branch">
                    <Tab label="Card Info" {...a11yProps(0)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <CardInfo validationFields={validationSchema.fields} />
                  </TabPanel>
                </Box>

                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddCardSystem;
