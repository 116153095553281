export const SmsToUserConstants = {
  FETCH_SMSTOUSER_REQUEST: "FETCH_SMSTOUSER_REQUEST",
  FETCH_SMSTOUSER_SUCCESS: "FETCH_SMSTOUSER_SUCCESS",
  FETCH_SMSTOUSER_FAILURE: "FETCH_SMSTOUSER_FAILURE",
  ADD_SMSTOUSER_REQUEST: "ADD_SMSTOUSER_REQUEST",
  ADD_SMSTOUSER_SUCCESS: "ADD_SMSTOUSER_SUCCESS",
  ADD_SMSTOUSER_FAILURE: "ADD_SMSTOUSER_FAILURE",
  SMSTOUSER_SEARCH_REQUEST: "SMSTOUSER_SEARCH_REQUEST",
  SMSTOUSER_SEARCH_SUCCESS: "SMSTOUSER_SEARCH_SUCCESS",
  SMSTOUSER_SEARCH_FAILURE: "SMSTOUSER_SEARCH_FAILURE",
  SMSTOUSER_AUTO_SEARCH_REQUEST: "SMSTOUSER_AUTO_SEARCH_REQUEST",
  SMSTOUSER_AUTO_SEARCH_SUCCESS: "SMSTOUSER_AUTO_SEARCH_SUCCESS",
  SMSTOUSER_AUTO_SEARCH_FAILURE: "SMSTOUSER_AUTO_SEARCH_FAILURE",
  GET_SMSTOUSER_REQUEST: "GET_SMSTOUSER_REQUEST",
  GET_SMSTOUSER_SUCCESS: "GET_SMSTOUSER_SUCCESS",
  GET_SMSTOUSER_FAILURE: "GET_SMSTOUSER_FAILURE",
  UPDATE_SMSTOUSER_REQUEST: "UPDATE_SMSTOUSER_REQUEST",
  UPDATE_SMSTOUSER_SUCCESS: "UPDATE_SMSTOUSER_SUCCESS",
  UPDATE_SMSTOUSER_FAILURE: "UPDATE_SMSTOUSER_FAILURE",
  DOWNLOAD_SMSTOUSER_REQUEST: "DOWNLOAD_SMSTOUSER_REQUEST",
  DOWNLOAD_SMSTOUSER_SUCCESS: "DOWNLOAD_SMSTOUSER_SUCCESS",
  DOWNLOAD_SMSTOUSER_FAILURE: "DOWNLOAD_SMSTOUSER_FAILURE",
  UPLOAD_SMSTOUSER_REQUEST: "UPLOAD_SMSTOUSER_REQUEST",
  UPLOAD_SMSTOUSER_SUCCESS: "UPLOAD_SMSTOUSER_SUCCESS",
  UPLOAD_SMSTOUSER_FAILURE: "UPLOAD_SMSTOUSER_FAILURE",
};
