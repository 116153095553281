import { useContext } from "react";

import { PlanContext } from "../contexts/PlanContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const usePlan = () => {
  const context = useContext(PlanContext);

  if (!context) throw new Error("PlanContext must be placed within PlanProvider");

  return context;
};

export default usePlan;
