import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useAuth from "../../hooks/useAuth";

import {
  Grid,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Autocomplete,
  TextField,
  InputAdornment,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarUserDropdown from "./NavbarUserDropdown";
import GlobalCreate from "./GlobalCreate";
import useUser from "../../hooks/useUser";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const Navbar = ({ onDrawerToggle }) => {
  const theme = useTheme();
  const { user } = useAuth();
  console.log(user, "useruseruser");
  const navigate = useNavigate();
  const [searchPayload, setPayload] = useState(currentPayload);
  const { autoSearchUser, userAutoSearchResults } = useUser();
  const [searchResults, setSearchResults] = useState([]);
  const { t } = useTranslation();
  const [inputValue, setinputValue] = useState("");
  const [values, setValues] = useState([]);
  const [searchValue, setSearchValue] = useState(null);

  const searchUser = async (searchResult) => {
    console.log(searchResult, "searchResult");
    if (searchResult == undefined) {
      setSearchResults([]);
    } else {
      if (searchResult.length > 2) {
        const payloadValues = {
          ...searchPayload,
          filters: [
            {
              fieldName: "userName",
              fieldValue: searchResult,
              op: "like",
              dataType: "string",
              condition: "and",
              isJoin: false,
            },
          ],
        };
        console.log(payloadValues, "searchResult");
        await autoSearchUser(payloadValues);
      }
    }
  };
  useEffect(() => {
    if (userAutoSearchResults.data != undefined) {
      setSearchResults(userAutoSearchResults.data);
    }
  }, [userAutoSearchResults]);
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0} theme={theme}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Search theme={theme}>
                <Autocomplete
                  id="combo-box-demo"
                  value={searchValue}
                  options={searchResults}
                  freeSolo
                  style={{ width: 300 }}
                  getOptionLabel={(option) => option?.label || ""}
                  onInputChange={(e) => searchUser(e.target.value)}
                  onChange={(event, value) => {
                    console.log(value, "onhange");
                    if (value != null) {
                      setSearchValue(null);
                      navigate("/user/details?id=" + value.value);
                    }
                  }}
                  onClick={(event, value) => {
                    console.log(value, "dddddddddddd");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Search User"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {" "}
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </Search>
            </Grid>

            <Grid item xs />
            {user &&
              (user.businessType == "SUPER_ADMIN" ||
                user.businessType == "MULTI_SERVICE_OPERATOR") && (
                <Grid item>
                  <GlobalCreate />
                </Grid>
              )}
            <Grid item>
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Navbar;
