import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import {
  CardContent,
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing, display } from "@mui/system";

import InvoiceLogo from "../vendor/invoice_logo.png";
import useUser from "../hooks/useUser";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function InvoiceDetails() {
  const { state } = useLocation();
  const { getRenewal, renewal } = useUser();

  const fetchData = async () => {
    await getRenewal(state.id);
  };

  const printInvoice = async () => {
    const content = document.getElementById("invoice_details");
    const pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Invoice Details" />

      <Typography variant="h3" gutterBottom display="inline">
        {"Invoice #" + renewal.invoiceNo}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Reports</Typography>
        <Typography>Renewal Report</Typography>
        <Typography>Invoice</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container id="invoice_details">
        <Grid item xs={12}>
          <Shadow>
            <Card px={6} pt={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <img src={InvoiceLogo} width={200} height={100} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2">{renewal.tenantName},</Typography>
                    <Typography variant="caption">Chennai,</Typography>
                    <Typography variant="caption">Registered Mobile No: 9333223322.</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption" align="right" display="block">
                      User Name: {renewal.userName}
                      <br />
                      Invoice No: {renewal.invoiceNo}
                      <br />
                      Invoice Date: {renewal.renewDate}
                      <br />
                      Paid Date: {renewal.paidDate}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <Typography variant="caption">Client</Typography>
                    <Typography variant="body2">
                      Anna Walley
                      <br />
                      4183 Forest Avenue
                      <br />
                      New York City
                      <br />
                      10011
                      <br />
                      USA
                      <br />
                      <Link href="mailto:anna@walley.com">anna@walley.com</Link>
                    </Typography>
                  </Grid> */}
                  {/* <Grid item xs={12} md={6}>
                    <Typography variant="caption" align="right" display="block">
                      Payment To
                    </Typography>
                    <Typography variant="body2" align="right">
                      Material App LLC
                      <br />
                      354 Roy Alley
                      <br />
                      Denver
                      <br />
                      80202
                      <br />
                      USA
                      <br />
                      <Link href="mailto:info@material-app.com">info@material-app.com</Link>
                    </Typography>
                  </Grid> */}
                </Grid>
              </CardContent>
            </Card>
            <Card px={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {renewal.planName}
                    </TableCell>
                    <TableCell>1</TableCell>
                    <TableCell align="right">{"₹" + renewal.planAmount}</TableCell>
                  </TableRow>
                  {renewal.sgstValue != 0 && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {"SGST @ " + renewal.sgstValue + "% on Rs. " + renewal.planAmount}
                      </TableCell>
                      <TableCell>1</TableCell>
                      <TableCell align="right">{"₹" + renewal.sgstAmount}</TableCell>
                    </TableRow>
                  )}
                  {renewal.cgstValue != 0 && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {"CGST @ " + renewal.cgstValue + "% on Rs. " + renewal.planAmount}
                      </TableCell>
                      <TableCell>1</TableCell>
                      <TableCell align="right">{"₹" + renewal.cgstAmount}</TableCell>
                    </TableRow>
                  )}
                  {renewal.igstValue != 0 && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {" IGST @ " + renewal.igstValue + "% on Rs. " + renewal.planAmount}
                      </TableCell>
                      <TableCell>1</TableCell>
                      <TableCell align="right">{"₹" + renewal.igstAmount}</TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell />
                    <TableCell>Subtotal</TableCell>
                    <TableCell align="right">{"₹" + renewal.finalInvoiceAmount}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell />
                    <TableCell>Discount</TableCell>
                    <TableCell align="right">0</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell>Total</TableCell>
                    <TableCell align="right">{"₹" + renewal.finalInvoiceAmount}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
            <Card pb={6} px={6}>
              <CardContent style={{ textAlign: "center" }}>
                <div>
                  <Typography variant="caption" gutterBottom align="center">
                    ***This is computer generated receipt no signature required***
                  </Typography>
                </div>
                <Button variant="contained" color="primary" mt={2} onClick={printInvoice}>
                  Print this receipt
                </Button>
              </CardContent>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
      <iframe
        id="ifmcontentstoprint"
        style={{ height: "0px", width: "0px", position: "absolute" }}
      ></iframe>
    </React.Fragment>
  );
}

export default InvoiceDetails;
