import { useContext } from "react";

import { RateContext } from "../contexts/RateContext";

const useRate = () => {
  const context = useContext(RateContext);

  if (!context) throw new Error("RateContext must be placed within Rate Provider");

  return context;
};

export default useRate;
