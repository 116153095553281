//Users
import Lead from "../pages/lead/index.js";
import AddLead from "../pages/lead/add.js";
import EditLead from "../pages/lead/edit.js";

import LeadStats from "../pages/lead/leadstats.js";
import LeadDetails from "../pages/lead/leaddetails.js";
import AssignUser from "../pages/lead/components/assign.js";
import ClosedLead from "../pages/lead/components/Closed.js";

//Composers
import { LeadComposer } from "../composers/LeadComposer";
import { DashboardComposer } from "../composers/DashboardComposer.js";

// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
// import LeadClosed from "../pages/lead/leadclosed.js";

const LeadRoutes = {
  path: "leads",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      children: [
        {
          path: "add",
          element: (
            <LeadComposer>
              <AddLead />
            </LeadComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <LeadComposer>
              <EditLead />
            </LeadComposer>
          ),
        },
        {
          path: "list",
          element: (
            <LeadComposer>
              <Lead />
            </LeadComposer>
          ),
        },
        {
          path: "stats",
          element: (
            <LeadComposer>
              <LeadStats />
            </LeadComposer>
          ),
        },
        {
          path: "Details",
          element: (
            <LeadComposer>
              <LeadDetails />
            </LeadComposer>
          ),
        },
        {
          path: "assign",
          element: (
            <LeadComposer>
              <AssignUser />
            </LeadComposer>
          ),
        },
        {
          path: "closed",
          element: (
            <LeadComposer>
              <ClosedLead />
            </LeadComposer>
          ),
        },
      ],
    },
  ],
};

export default LeadRoutes;
