import { EmailMasterConstants } from "../constants/emailmaster.constants";

const EmailMasterReducer = (state, action) => {
  switch (action.type) {
    case EmailMasterConstants.FETCH_EMAILMASTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmailMasterConstants.FETCH_EMAILMASTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emailMasters: action.payload.data,
        paging: action.payload.paging,
      };
    case EmailMasterConstants.FETCH_EMAILMASTER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmailMasterConstants.EMAILMASTER_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmailMasterConstants.EMAILMASTER_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emailmasterSearchResults: action.payload,
      };
    case EmailMasterConstants.EMAILMASTER_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmailMasterConstants.ADD_EMAILMASTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmailMasterConstants.ADD_EMAILMASTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case EmailMasterConstants.ADD_EMAILMASTER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case EmailMasterConstants.GET_EMAILMASTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmailMasterConstants.GET_EMAILMASTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emailmaster: action.payload,
      };
    case EmailMasterConstants.GET_EMAILMASTER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmailMasterConstants.UPDATE_EMAILMASTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmailMasterConstants.UPDATE_EMAILMASTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emailmaster: action.payload,
      };
    case EmailMasterConstants.UPDATE_EMAILMASTER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case EmailMasterConstants.DOWNLOAD_EMAILMASTER_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case EmailMasterConstants.DOWNLOAD_EMAILMASTER_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case EmailMasterConstants.DOWNLOAD_EMAILMASTER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case EmailMasterConstants.UPLOAD_EMAILMASTER_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case EmailMasterConstants.UPLOAD_EMAILMASTER_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case EmailMasterConstants.UPLOAD_EMAILMASTER_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };
    case EmailMasterConstants.EMAILMASTER_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        autoSearchLoading: true,
        hasError: false,
      };
    case EmailMasterConstants.EMAILMASTER_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        autoSearchLoading: false,
        emailMasterAutoSearchResults: action.payload,
      };
    case EmailMasterConstants.EMAILMASTER_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        autoSearchLoading: false,
      };

    default:
      return state;
  }
};

export default EmailMasterReducer;
