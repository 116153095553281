import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { PppSecretProvider } from "../contexts/PppSecretContext";
import { DialogProvider } from "../contexts/DialogContext";
import { NasProvider } from "../contexts/NasContext";

export const PppSecretComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <PppSecretProvider key={0} />,
        <NasProvider key={1} />,
        <DialogProvider key={2} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
