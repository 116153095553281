import React from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import LeadMore from "../../../components/LeadMore";
import AdvanceFilter from "./AdvanceFilter";
import useLead from "../../../hooks/useLead";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>User</Typography>
      <Typography>Session History</Typography>
    </Breadcrumbs>
  );
};

const CustomDataGrid = (props) => {
  const { isLoadingDownload, downloadFileType } = useLead();

  const { height } = useWindowDimensions();
  const { width: mainContentWidth } = useMainContentDimensions();

  const {
    editPage,
    addPage,
    trans,
    data,
    rowCount,
    paging,
    onPageChange,
    payload,
    setPayload,
    setFilterResetFunc,
    isLoading,
    id,
    handleSortModelChange,
    sortModel,
    downloadFile,
    openDownloadDialog,
    viewDetails,
    fetchData,
    refreshPage,
  } = props;
  console.log(rowCount, "loading child component");

  const { openDialog } = useDialog();

  const columns = [
    {
      field: "action",
      headerName: "Action",
      filter: false,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          console.log(thisRow, "thisRow");
        };
      },
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 150,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 150,
    },

    {
      field: "msc",
      headerName: "Msc",
      width: 100,
    },

    {
      field: "userService",
      headerName: "User Service",
      valueGetter: (params) => params.row.employee?.employeeName,
      type: "string",
      width: 150,
    },
    {
      field: "runningService",
      headerName: "Running Service",
      width: 150,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 150,
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: 130,
    },
    {
      field: "onlineTime",
      headerName: "Online Time",
      width: 150,
    },

    {
      field: "branchName",
      headerName: "Branch Name",
      valueGetter: (params) => params.row.branch?.branchName,
      type: "string",
      width: 300,
    },

    {
      field: "downloaded",
      headerName: "Downloaded",
      width: 300,
    },
    {
      field: "uploaded",
      headerName: "Uploaded",
      width: 300,
    },
    {
      field: "total",
      headerName: "Total",
      width: 300,
    },
    {
      field: "ipAddress",
      headerName: "IP Address",
      width: 300,
    },
    {
      field: "nasIp",
      headerName: "Nas IP",
      width: 300,
    },
    {
      field: "terminateCase",
      headerName: "Terminate Case",
      width: 300,
    },
  ];

  const openFilterDialog = () => {
    return openDialog(
      "advance_filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={
          <Toolbar
            addPage={addPage}
            downloadFile={downloadFile}
            openDownloadDialog={openDownloadDialog}
            refreshPage={refreshPage}
            openFilterDialog={openFilterDialog}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
          />
        }
        title={trans("session_history")}
        subheader={breadCrump()}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          columns={columns}
          rows={data}
          rowCount={rowCount}
          getRowId={(row) => row[id]}
          loading={isLoading}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </CardContent>
    </Card>
  );
};
CustomDataGrid.propTypes = {
  editPage: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  trans: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  paging: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSortModelChange: PropTypes.func.isRequired,
  sortModel: PropTypes.object.isRequired,
};
export default CustomDataGrid;
