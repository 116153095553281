import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";

import {
  Grid,
  Divider as MuiDivider,
  Box,
  CircularProgress,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SaveCancel from "../../../../components/SaveCancel";

import CustomDataGrid from "./components/CustomDataGrid";
import useReports from "../../../../hooks/useReports";
import { useDialog } from "../../../../contexts/DialogContext";
import { getTimeStamp } from "../../../../utils/common";
import Stats from "./../../../../components/Stats";
import CustomTextField from "./../../../../components/CustomTextField";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import { useForm, FormProvider } from "react-hook-form";

import useAuth from "../../../../hooks/useAuth";
import { roundOff } from "../../../../utils/common";
import AddMaterial from "./add";
import useUser from "../../../../hooks/useUser";
import EditMaterial from "./edit";
const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Materials() {
  const { user } = useAuth();
  const { addMaterial, getAllMaterial, materials, paging, isLoading, id, downloadFile } = useUser();

  let [searchParams, setSearchParams] = useSearchParams();
  const { totalNumberOfRecords, hasNextPage, pageNumber, pageSize } = paging;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState([]);

  const userId = searchParams.get("id");

  const methods = useForm({
    resolver: yupResolver,
  });

  const currentPayload = {
    filters: [
      {
        fieldName: "radUserId",
        fieldValue: userId,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
        attributeName: "radUser",
        entityName: "RadUserEntity",
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "id",
        sort: "DESC",
      },
    ],
  };

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllMaterial(payload);
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
  };

  const onPageChange = (newPaginationModel) => {
    console.log(newPaginationModel, "pagepage");
    const pagination = {
      pageNumber: newPaginationModel.page == 0 ? 1 : newPaginationModel.page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
    setPaginationModel(newPaginationModel);
  };

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });

  const paginationMetaRef = useRef();

  // Memoize to avoid flickering when the `hasNextPage` is `undefined` during refetch
  const paginationMeta = React.useMemo(() => {
    if (hasNextPage !== undefined && paginationMetaRef.current?.hasNextPage !== hasNextPage) {
      paginationMetaRef.current = { hasNextPage };
    }
    return paginationMetaRef.current;
  }, [hasNextPage]);

  const exportFile = async (fileType) => {
    await downloadFile(fileType, payload);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addTo = (id) => {
    console.log(id, "ididid");
    return openDialog(
      "add_material",
      "Add Material",
      <AddMaterial
        onChange={handleChange}
        refreshPage={refreshPage}
        userId={searchParams.get("id")}
        key={getTimeStamp()}
        fetchData={fetchData}
        selectedRows={selectedRows}
      />,
    );
  };

  const editTo = (id) => {
    console.log(id, "ididid");
    return openDialog(
      "edit_material",
      "Edit Material",
      <EditMaterial
        onChange={handleChange}
        refreshPage={refreshPage}
        userMaterialId={id}
        userId={searchParams.get("id")}
        key={getTimeStamp()}
        fetchData={fetchData}
        selectedRows={selectedRows}
      />,
    );
  };

  return (
    <React.Fragment>
      <FormProvider>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <CustomDataGrid
              trans={t}
              addPage={addTo}
              editPage={editTo}
              refreshPage={refreshPage}
              downloadFile={exportFile}
              openDownloadDialog={null}
              setFilterResetFunc={setFilterResetFunc}
              id="id"
              isLoading={isLoading}
              sortModel={sortModel}
              handleSortModelChange={handleSortModelChange}
              setPayload={setPayload}
              setSelectedRows={setSelectedRows}
              payload={payload}
              rows={materials}
              rowCount={totalNumberOfRecords}
              onPaginationModelChange={onPageChange}
              paginationMeta={paginationMeta}
              paging={{
                pageNumber: pageNumber,
                pageSize: pageSize,
              }}
              paginationModel={paginationModel}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </React.Fragment>
  );
}

export default Materials;
