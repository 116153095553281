import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import MkIpPoolReducer from "../reducers/MkIpPoolReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { MkIpPoolConstants } from "../constants/mkippool.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  mkIpPools: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  mkIpPoolSearchResults: [],
  mkIpPool: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  mkIpPoolAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const MkIpPoolContext = createContext({
  ...initialState,
  getAllMkIpPool: () => {},
  addMkIpPool: () => {},
  searchMkIpPool: () => {},
  autoSearchMkIpPool: () => {},
  getMkIpPool: () => {},
  updateMkIpPool: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function MkIpPoolProvider({ children }) {
  const [state, dispatch] = useReducer(MkIpPoolReducer, initialState);

  const getAllMkIpPool = async (payload) => {
    try {
      dispatch({
        type: MkIpPoolConstants.FETCH_MK_IP_POOL_REQUEST,
      });
      const response = await axios.post("mk-ip-pool/list", payload);
      dispatch({
        type: MkIpPoolConstants.FETCH_MK_IP_POOL_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: MkIpPoolConstants.FETCH_MK_IP_POOL_FAILURE,
      });
    }
  };
  const searchMkIpPool = async (payload) => {
    try {
      const response = await axios.post("mk-ip-pool/search", payload);
      dispatch({
        type: MkIpPoolConstants.MK_IP_POOL_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: MkIpPoolConstants.MK_IP_POOL_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: MkIpPoolConstants.MK_IP_POOL_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchMkIpPool = async (payload) => {
    try {
      const response = await axios.post("mk-ip-pool/auto-search", payload);
      dispatch({
        type: MkIpPoolConstants.MK_IP_POOL_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: MkIpPoolConstants.MK_IP_POOL_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: MkIpPoolConstants.MK_IP_POOL_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addMkIpPool = async (payload) => {
    const response = await axios.post("mk-ip-pool/add", payload);
    dispatch({
      type: MkIpPoolConstants.ADD_MK_IP_POOL_REQUEST,
    });
    dispatch({
      type: MkIpPoolConstants.ADD_MK_IP_POOL_SUCCESS,
      payload: response.data,
    });
  };

  const getMkIpPool = async (id) => {
    const response = await axios.get("mk-ip-pool/" + id);
    dispatch({
      type: MkIpPoolConstants.GET_MK_IP_POOL_REQUEST,
    });
    dispatch({
      type: MkIpPoolConstants.GET_MK_IP_POOL_SUCCESS,
      payload: response.data,
    });
  };

  const updateMkIpPool = async (payload) => {
    const response = await axios.put("mk-ip-pool/update/" + payload.mkIpPoolId, payload);
    dispatch({
      type: MkIpPoolConstants.UPDATE_MK_IP_POOL_REQUEST,
    });
    dispatch({
      type: MkIpPoolConstants.UPDATE_MK_IP_POOL_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: MkIpPoolConstants.DOWNLOAD_MK_IP_POOL_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("mk-ip-pool/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "mkIpPool." + fileType);
    dispatch({
      type: MkIpPoolConstants.DOWNLOAD_MK_IP_POOL_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("mk-ip-pool/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_mkIpPool." + fileType);
    dispatch({
      type: MkIpPoolConstants.DOWNLOAD_MK_IP_POOL_REQUEST,
    });
    dispatch({
      type: MkIpPoolConstants.DOWNLOAD_MK_IP_POOL_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: MkIpPoolConstants.UPLOAD_MK_IP_POOL_REQUEST,
      });
      const response = await axios({
        url: "mk-ip-pool/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: MkIpPoolConstants.UPLOAD_MK_IP_POOL_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: MkIpPoolConstants.UPLOAD_MK_IP_POOL_FAILURE,
      });
    }
  };

  return (
    <MkIpPoolContext.Provider
      value={{
        ...state,
        getAllMkIpPool,
        addMkIpPool,
        searchMkIpPool,
        autoSearchMkIpPool,
        getMkIpPool,
        updateMkIpPool,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </MkIpPoolContext.Provider>
  );
}

export { MkIpPoolContext, MkIpPoolProvider };
