import * as React from "react";

import FormReducer from "../reducers/FormReducer";

const FormStateContext = React.createContext();
const FormDispatchContext = React.createContext();

const initialState = {
  addOn: {
    values: {
      addOnName: "",
      totalLimit: "",
      price: "",
      taxEnabled: true,
      taxType: "",
      comments: "",
      status: "ACTIVE",
      sample: "testing sample",
    },
    errors: {},
  },
};

const FormProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(FormReducer, initialState);

  return (
    <FormStateContext.Provider value={state}>
      <FormDispatchContext.Provider value={dispatch}>{children}</FormDispatchContext.Provider>
    </FormStateContext.Provider>
  );
};

const useFormState = (id) => {
  const formState = React.useContext(FormStateContext);

  if (formState === undefined) {
    throw new Error('useFormState must be used within a FormProvider"');
  }

  return formState[id];
};

const useFormDispatch = () => {
  const dispatch = React.useContext(FormDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useFormState must be used within a FormProvider"');
  }

  return dispatch;
};

export { FormProvider, useFormState, useFormDispatch };
