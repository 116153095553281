import React, { useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  CardHeader,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider } from "react-hook-form";

import SaveCancel from "../../../components/SaveCancel";
import useDistrict from "../../../hooks/useDistrict";
import DistrictFields from "./components/DistrictFields";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const initialValues = {
  districtName: "",
  country: "",
  state: "",
  status: "ACTIVE",
};

const validationSchema = Yup.object().shape({
  districtName: Yup.string().required("Required"),
  state: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  country: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  status: Yup.string().required("Required"),
});

function AddDistrict() {
  const dispatch = useDispatch();
  const { addDistrict, hasError, errorMessage, isSubmitting } = useDistrict();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      console.log(data, "submittttt");
      const districtDTO = {
        country: {
          countryId: data.country.value,
          countryName: data.country.label,
        },
        state: {
          stateId: data.state.value,
          stateName: data.state.label,
        },
        status: data.status,
        districtName: data.districtName,
      };
      await addDistrict(districtDTO);
      reset();
      dispatch(showSuccessSnackbar("District added successfully!"));
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
  };
  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const cancelBtn = () => {
    navigate("/masters/district/list");
  };

  const { t } = useTranslation();
  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_district")} subheader={"We can add district here"} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <DistrictFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddDistrict;
