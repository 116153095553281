import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import SmsToTenantReducer from "../reducers/SmsToTenantReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { SmsToTenantConstants } from "../constants/smstotenant.constants";
import { saveFile } from "../utils/common";
const initialState = {
  smsToTenants: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  emsToTenantSearchResults: [],
  emsToTenant: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,

  emsToTenantAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const SmsToTenantContext = createContext({
  ...initialState,
  getAllSmsToTenant: () => {},
  addSmsToTenant: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function SmsToTenantProvider({ children }) {
  const [state, dispatch] = useReducer(SmsToTenantReducer, initialState);

  const getAllSmsToTenant = async (payload) => {
    try {
      dispatch({
        type: SmsToTenantConstants.FETCH_SMSTOTENANT_REQUEST,
      });
      const response = await axios.post("/cpanel/tenant/list", payload);

      dispatch({
        type: SmsToTenantConstants.FETCH_SMSTOTENANT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: SmsToTenantConstants.FETCH_SMSTOTENANT_FAILURE,
      });
    }
  };

  const addSmsToTenant = async (payload) => {
    const response = await axios.post(" cpanel/tenant/send-sms", payload);
    dispatch({
      type: SmsToTenantConstants.ADD_SMSTOTENANT_REQUEST,
    });
    dispatch({
      type: SmsToTenantConstants.ADD_SMSTOTENANT_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: SmsToTenantConstants.DOWNLOAD_SMSTOTENANT_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/cpanel/tenant/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "tenant." + fileType);

    dispatch({
      type: SmsToTenantConstants.DOWNLOAD_SMSTOTENANT_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <SmsToTenantContext.Provider
      value={{
        ...state,
        getAllSmsToTenant,
        addSmsToTenant,
        downloadFile,
      }}
    >
      {children}
    </SmsToTenantContext.Provider>
  );
}

export { SmsToTenantContext, SmsToTenantProvider };
