import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";

import SaveCancel from "../../../components/SaveCancel";
import useWallet from "../../../hooks/useWallet";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import BasicDetail from "./components/BasicDetail";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import useAuth from "../../../hooks/useAuth";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const digitsOnly = (value) => /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0;

const validationSchema = Yup.object().shape({
  tenant: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  amount: Yup.string()
    .required()
    .test("inputEntry", "The field should have digits only", digitsOnly),
  comments: Yup.string().required("Required"),
  walletType: Yup.string().required("Required"),
});

function AddWallet() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const initialValues = {
    amount: "",
    walletType: "RECHARGE",
    comments: "",
    tenant: "",
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  console.log(user, "useruser111");

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const { t } = useTranslation();
  const { addWallet, hasError, errorMessage, isSubmitting, paymentTrans, addPaymentTrans } =
    useWallet();
  const [tabValue, setTabValue] = React.useState(0);

  const [isOnline, setIsOnline] = React.useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>C-Panal</Typography>
        <Typography>Wallet</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  useEffect(() => {
    if (user && user.businessType != "SUPER_ADMIN") {
      //setIsOnline(true);
      setValue("tenant", {
        label: user.name,
        value: user.tenantId,
      });
    }
  }, [user]);

  useEffect(() => {
    if (paymentTrans) {
      console.log(paymentTrans, "paymentTranspaymentTrans");
      if (paymentTrans.actionUrl != "") {
        let form = document.createElement("form");
        var element1 = document.createElement("input");
        var element2 = document.createElement("input");

        form.method = "POST";
        form.action = paymentTrans.actionUrl;

        element1.type = "hidden";
        element1.value = paymentTrans.encRequest;
        element1.name = "encRequest";
        form.appendChild(element1);

        element2.type = "hidden";
        element2.value = paymentTrans.accessCode;
        element2.name = "access_code";
        form.appendChild(element2);

        document.body.appendChild(form);

        form.submit();
        //navigate(paymentTrans.actionUrl, { replace: true });
        //window.location = paymentTrans.actionUrl;
      }
    }
  }, [paymentTrans]);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_status = params.get("order_status");
  console.log(order_status, "order_statusorder_status");

  useEffect(() => {
    if (order_status != null) {
      dispatch(showSuccessSnackbar("The transaction of the status is " + order_status));
    }
  }, [order_status]);

  const onSubmit = async (data) => {
    try {
      if (isOnline) {
        const payload = {
          amount: data.amount,
          tenantId: data.tenant.value,
        };
        await addPaymentTrans(payload);
        console.log("is on line payment");
      } else {
        const tenantDto = {
          tenantId: data.tenant.value,
        };
        data["tenant"] = tenantDto;
        console.log(data, "datadata");
        await addWallet(data);
        dispatch(showSuccessSnackbar("Amount added successfully!"));
        reset();
      }
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to add amount"));
      //setStatus({ sent: false });
      //setErrorSubmit({ submit: err.message });
    }
  };

  const cancelBtn = () => {
    navigate("/wallet/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_wallet")} subheader={breadCrump()} />
          <CardContent>
            {/* {status && status.sent && (
                <Alert severity="success" my={3}>
                  Wallet added successfully!
                </Alert>
              )} */}

            {/* {errorSubmit && errorSubmit.submit && (
                <Alert severity="error" my={3}>
                  {errorSubmit.submit}
                </Alert>
              )} */}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={tabValue} onChange={handleTabChange} aria-label="Wallet">
                    <Tab label="Details" {...a11yProps(0)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <BasicDetail validationFields={validationSchema.fields} />
                  </TabPanel>
                </Box>
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddWallet;
