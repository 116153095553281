import { useContext } from "react";

import { TenantSharingContext } from "../contexts/TenantSharingContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useTenantSharing = () => {
  const context = useContext(TenantSharingContext);

  if (!context) throw new Error("TenantSharingContext must be placed within TenantProvider");

  return context;
};

export default useTenantSharing;
