import { RoutesConstants } from "../constants/routes.constants";

const RoutesReducer = (state, action) => {
  switch (action.type) {
    case RoutesConstants.FETCH_ROUTES_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RoutesConstants.FETCH_ROUTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        routes: action.payload.data,
        paging: action.payload.paging,
      };
    case RoutesConstants.FETCH_ROUTES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case RoutesConstants.ROUTES_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RoutesConstants.ROUTES_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        routesSearchResults: action.payload,
      };
    case RoutesConstants.ROUTES_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case RoutesConstants.ROUTES_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RoutesConstants.ROUTES_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        routesAutoSearchResults: action.payload,
      };
    case RoutesConstants.ROUTES_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case RoutesConstants.ADD_ROUTES_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case RoutesConstants.ADD_ROUTES_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case RoutesConstants.ADD_ROUTES_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case RoutesConstants.GET_ROUTES_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RoutesConstants.GET_ROUTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        route: action.payload,
      };
    case RoutesConstants.GET_ROUTES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case RoutesConstants.UPDATE_ROUTES_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case RoutesConstants.UPDATE_ROUTES_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        route: action.payload,
      };
    case RoutesConstants.UPDATE_ROUTES_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case RoutesConstants.DOWNLOAD_ROUTES_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case RoutesConstants.DOWNLOAD_ROUTES_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case RoutesConstants.DOWNLOAD_ROUTES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case RoutesConstants.UPLOAD_ROUTES_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case RoutesConstants.UPLOAD_ROUTES_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case RoutesConstants.UPLOAD_ROUTES_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default RoutesReducer;
