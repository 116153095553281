import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import CustomDataGrid from "./CustomDataGrid";
import useReports from "../../../hooks/useReports";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";

function RenewalReports() {
  const { getRenewalReports, isLoading, renewalReports, downloadRenewalReport } = useReports();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getRenewalReports(payload);
  };

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const exportFile = async (fileType) => {
    await downloadRenewalReport(fileType, payload);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CustomDataGrid
            trans={t}
            addPage={null}
            editPage={null}
            refreshPage={refreshPage}
            downloadFile={exportFile}
            openDownloadDialog={null}
            id="renewId"
            data={renewalReports.data}
            rowCount={renewalReports.paging.totalNumberOfRecords}
            paging={{
              page: renewalReports.paging.pageNumber,
              pageSize: renewalReports.paging.pageSize,
            }}
            onPageChange={onPageChange}
            isLoading={isLoading}
            sortModel={sortModel}
            handleSortModelChange={handleSortModelChange}
            setPayload={setPayload}
            payload={payload}
            setFilterResetFunc={setFilterResetFunc}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default RenewalReports;
