import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";

import Delete from "../../../../components/Delete";
import useAddon from "../../../../hooks/useAddon";

const Paper = styled(MuiPaper)(spacing);

const MappedTenant = ({ addOnId }) => {
  const currentPayload = {
    filters: [
      {
        fieldName: "addOnId",
        fieldValue: addOnId,
        isJoin: true,
        op: "eq",
        dataType: "long",
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };
  const [payload, setPayload] = useState(currentPayload);
  const { isLoading, getAddOnTenants, addOnTenants, paging } = useAddon();

  const fetchData = async () => {
    await getAddOnTenants(payload);
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      filter: false,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          console.log(thisRow, "thisRow");
        };
        return <Delete rowClick={onClick} />;
      },
    },
    {
      field: "tenantName",
      headerName: "Tenant Name",
      width: 300,
    },
  ];
  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };
  return (
    <Card mb={6}>
      <Paper>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            columns={columns}
            rows={addOnTenants}
            rowCount={paging.totalNumberOfRecords}
            getRowId={(row) => row["addOnTenantId"]}
            loading={isLoading}
            rowsPerPageOptions={[5]}
            pagination
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMode="server"
            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageChange(pageSize)}
            checkboxSelection
            sortingMode="server"
            //sortModel={sortModel}
            //onSortModelChange={handleSortModelChange}
          />
        </div>
      </Paper>
    </Card>
  );
};

export default MappedTenant;
