import React from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import TabPanel from "../../components/TabPanel";
import { a11yProps } from "../../utils/common";
import BasicInformation from "./components/BasicInformation";
import LeadHistory from "./components/LeadHistory";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

function LeadDetails() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Lead</Typography>
        <Typography>Lead Details</Typography>
      </Breadcrumbs>
    );
  };

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardHeader title={t("lead_details")} subheader={breadCrump()} />
        <CardContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="Branch">
              <Tab label="Basic Information" {...a11yProps(0)} />
              <Tab label="Lead History" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicInformation />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <LeadHistory />
            </TabPanel>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default LeadDetails;
