export const CityConstants = {
  FETCH_CITY_REQUEST: "FETCH_CITY_REQUEST",
  FETCH_CITY_SUCCESS: "FETCH_CITY_SUCCESS",
  FETCH_CITY_FAILURE: "FETCH_CITY_FAILURE",
  ADD_CITY_REQUEST: "ADD_CITY_REQUEST",
  ADD_CITY_SUCCESS: "ADD_CITY_SUCCESS",
  ADD_CITY_FAILURE: "ADD_CITY_FAILURE",
  EDIT_CITY_REQUEST: "EDIT_CITY_REQUEST",
  EDIT_CITY_SUCCESS: "EDIT_CITY_SUCCESS",
  EDIT_CITY_FAILURE: "EDIT_CITY_FAILURE",
  CITY_SEARCH_REQUEST: "CITY_SEARCH_REQUEST",
  CITY_SEARCH_SUCCESS: "CITY_SEARCH_SUCCESS",
  CITY_SEARCH_FAILURE: "CITY_SEARCH_FAILURE",
  GET_CITY_REQUEST: "GET_CITY_REQUEST",
  GET_CITY_SUCCESS: "GET_CITY_SUCCESS",
  GET_CITY_FAILURE: "GET_CITY_FAILURE",
  UPDATE_CITY_REQUEST: "UPDATE_CITY_REQUEST",
  UPDATE_CITY_SUCCESS: "UPDATE_CITY_SUCCESS",
  UPDATE_CITY_FAILURE: "UPDATE_CITY_FAILURE",
  DOWNLOAD_CITY_REQUEST: "DOWNLOAD_CITY_REQUEST",
  DOWNLOAD_CITY_SUCCESS: "DOWNLOAD_CITY_SUCCESS",
  DOWNLOAD_CITY_FAILURE: "DOWNLOAD_CITY_FAILURE",
  UPLOAD_CITY_REQUEST: "UPLOAD_CITY_REQUEST",
  UPLOAD_CITY_SUCCESS: "UPLOAD_CITY_SUCCESS",
  UPLOAD_CITY_FAILURE: "UPLOAD_CITY_FAILURE",
  CITY_AUTO_SEARCH_REQUEST: "CITY_AUTO_SEARCH_REQUEST",
  CITY_AUTO_SEARCH_SUCCESS: "CITY_AUTO_SEARCH_SUCCESS",
  CITY_AUTO_SEARCH_FAILURE: "CITY_AUTO_SEARCH_FAILURE",
};
