import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { IppoolProvider } from "../contexts/IppoolContext";
import { DialogProvider } from "../contexts/DialogContext";

export const IppoolComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<IppoolProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};
