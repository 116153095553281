import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Alert as MuiAlert,
  Button,
  TextField,
  DialogActions,
  Box,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

import { Formik } from "formik";
import { useDialog } from "../../../../contexts/DialogContext";
import useTenant from "../../../../hooks/useTenant";

const Alert = styled(MuiAlert)(spacing);

const Upload = (props) => {
  const { unSetDialog, openDialog } = useDialog();
  const { sampleFile, uploadFile, getAllTenant, isUpload, isDownload, uploadError, uploadSuccess } =
    useTenant();
  const dispatch = useDispatch();

  const downloadSampleFile = () => {
    sampleFile("xlsx");
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        file: null,
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        file: Yup.mixed().required(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        let formData = new FormData();
        formData.append("file", values.file);
        //formData.append("createdBy", login.user.user_id_pk);
        await uploadFile(formData);
        resetForm();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        status,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {uploadSuccess && (
            <Alert severity="success" my={3}>
              Tenant uploaded successfully!
            </Alert>
          )}
          {uploadError && (
            <Alert severity="error" my={3}>
              Failed to upload file
            </Alert>
          )}
          <TextField
            variant="standard"
            error={Boolean(touched.file && errors.file)}
            fullWidth
            helperText={touched.file && errors.file}
            label="Upload file *"
            margin="normal"
            name="file"
            onBlur={handleBlur}
            onChange={(event) => {
              setFieldValue("file", event.currentTarget.files[0]);
            }}
            type="file"
          />

          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              endIcon={isUpload ? <CircularProgress size={24} /> : null}
              disabled={isUpload}
            >
              Upload
            </Button>
            <Button
              disabled={isDownload}
              variant="contained"
              color="primary"
              onClick={() => downloadSampleFile()}
              endIcon={isDownload ? <CircularProgress size={24} /> : null}
            >
              Download Sample File{" "}
            </Button>
            <Button variant="text" onClick={() => unSetDialog("upload")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};
export default Upload;
