import { useContext } from "react";

import { SessionHistoryContext } from "../contexts/SessionHistoryContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useSessionHistory = () => {
  const context = useContext(SessionHistoryContext);

  if (!context)
    throw new Error("SessionHistoryContext must be placed within SessionHistoryProvider");

  return context;
};

export default useSessionHistory;
