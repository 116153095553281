import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Card, CardContent, CardHeader } from "@mui/material";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";

import MappingToolBar from "../../../../components/MappingToolbar";
import MapPlanModal from "./MapPlanModal";
import { useDialog } from "../../../../contexts/DialogContext";
import { getTimeStamp } from "../../../../utils/common";
import MappedPlan from "./MappedPlan";

const AllowedPlan = ({ planGroupId, isSharingAllowed }) => {
  const { openDialog } = useDialog();

  const openDownloadDialog = () => {
    return openDialog(
      "mapping_plan",
      "Mapping Plan",
      <MapPlanModal
        planGroupId={planGroupId}
        key={getTimeStamp()}
        isNew={true}
        isSharingAllowed={isSharingAllowed}
      />,
    );
  };

  return (
    <Card mb={6}>
      <CardHeader action={<MappingToolBar openDialog={openDownloadDialog} />} />
      <CardContent>
        <MappedPlan planGroupId={planGroupId} />
      </CardContent>
    </Card>
  );
};

export default AllowedPlan;
