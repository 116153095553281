import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { StateMasterProvider } from "../contexts/StateMasterContext";
import { CountryProvider } from "../contexts/CountryContext";
import { AuthProvider } from "../contexts/JWTContext";
import { DialogProvider } from "../contexts/DialogContext";

export const StateMasterComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <StateMasterProvider key={0} />,
        <CountryProvider key={1} />,
        <DialogProvider key={2} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
