import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import TalukReducer from "../reducers/TalukReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { TalukConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  taluks: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  talukSearchResults: [],
  taluk: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  talukSearchResults: [],
  isDownload: false,
  isLoadingAutoSearch: true,
  isLoadingDownload: false,
  talukAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const TalukContext = createContext({
  ...initialState,
  getAllTaluk: () => {},
  addTaluk: () => {},
  editTaluk: () => {},
  searchTaluk: () => {},
  autoSearchTaluk: () => {},
  getTaluk: () => {},
  updateTaluk: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function TalukProvider({ children }) {
  const [state, dispatch] = useReducer(TalukReducer, initialState);

  const getAllTaluk = async (payload) => {
    try {
      dispatch({
        type: TalukConstants.FETCH_TALUK_REQUEST,
      });
      const response = await axios.post("/master/taluk/list", payload);

      dispatch({
        type: TalukConstants.FETCH_TALUK_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: TalukConstants.FETCH_TALUK_FAILURE,
      });
    }
  };
  const addTaluk = async (payload) => {
    const response = await axios.post("/master/taluk/add", payload);
    dispatch({
      type: TalukConstants.ADD_TALUK_REQUEST,
    });
    dispatch({
      type: TalukConstants.ADD_TALUK_SUCCESS,
      payload: response.data,
    });
  };
  const editTaluk = async (payload) => {
    console.log("1111111111111111", payload);
    const response = await axios.put("/master/taluk/update/" + payload.talukId, payload);
    dispatch({
      type: TalukConstants.EDIT_TALUK_REQUEST,
    });
    dispatch({
      type: TalukConstants.EDIT_TALUK_SUCCESS,
      payload: response.data,
    });
  };
  const searchTaluk = async (payload) => {
    try {
      const response = await axios.post("/master/taluk/search", payload);
      dispatch({
        type: TalukConstants.TALUK_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: TalukConstants.TALUK_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: TalukConstants.TALUK_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchTaluk = async (payload) => {
    try {
      const response = await axios.post("/master/taluk/auto-search", payload);
      dispatch({
        type: TalukConstants.TALUK_AUTO_SEARCH_REQUEST,
      });
      dispatch({
        type: TalukConstants.TALUK_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: TalukConstants.TALUK_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const getTaluk = async (id) => {
    console.log(id, "aaaaaaaaaa");
    const response = await axios.get("/master/taluk/" + id);
    dispatch({
      type: TalukConstants.GET_TALUK_REQUEST,
    });
    dispatch({
      type: TalukConstants.GET_TALUK_SUCCESS,
      payload: response.data,
    });
  };

  const updateTaluk = async (payload) => {
    console.log(payload, "Upatetaluk");
    const response = await axios.put("/master/taluk/update/" + payload.talukId, payload);
    dispatch({
      type: TalukConstants.UPDATE_TALUK_REQUEST,
    });
    dispatch({
      type: TalukConstants.UPDATE_TALUK_SUCCESS,
      payload: response.data,
    });
  };
  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: TalukConstants.DOWNLOAD_TALUK_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/master/taluk/export/" + fileType, payload, {
      responseType: "blob",
    });
    //console.log(response);
    await saveFile(response.data, "taluk." + fileType);
    dispatch({
      type: TalukConstants.DOWNLOAD_TALUK_SUCCESS,
      payload: response.data,
    });
  };
  const sampleFile = async (fileType) => {
    const response = await axios.get("/master/taluk/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_taluk." + fileType);
    dispatch({
      type: TalukConstants.DOWNLOAD_TALUK_REQUEST,
    });
    dispatch({
      type: TalukConstants.DOWNLOAD_TALUK_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: TalukConstants.UPLOAD_TALUK_REQUEST,
      });
      const response = await axios({
        url: "/master/taluk/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: TalukConstants.UPLOAD_TALUK_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: TalukConstants.UPLOAD_TALUK_FAILURE,
      });
    }
  };

  return (
    <TalukContext.Provider
      value={{
        ...state,
        getAllTaluk,
        addTaluk,
        editTaluk,
        searchTaluk,
        autoSearchTaluk,
        getTaluk,
        updateTaluk,
        downloadFile,
        uploadFile,
        sampleFile,
      }}
    >
      {children}
    </TalukContext.Provider>
  );
}

export { TalukContext, TalukProvider };
