import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import snackbarReducer from "./slices/snackbar";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    snackbar: snackbarReducer,
  },
});
