import { useContext } from "react";

import { PlanHistoryContext } from "../contexts/PlanHistoryContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const usePlanHistory = () => {
  const context = useContext(PlanHistoryContext);

  if (!context) throw new Error("PlanHistoryContext must be placed within PlanHistoryProvider");

  return context;
};

export default usePlanHistory;
