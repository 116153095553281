import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import Overview from "./components/overview";
import { Grid } from "@mui/material";

import useComplaint from "../../hooks/useComplaint";
import { useDialog } from "../../contexts/DialogContext";
import { getTimeStamp } from "../../utils/common";
import Stats from "./components/Stats";
import Upload from "./components/Upload";

function ComplaintStats() {
  const { getAllComplaint, countries, paging, isLoading, downloadFile } = useComplaint();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "complaintName",
        sort: "ASC",
      },
    ],
  };

  return (
    <React.Fragment>
      {/* <Grid item xs={6}> */}
      <Grid
        container
        spacing={4}
        sx={{
          color: "success.main",
          justifyContent: "center",
        }}
      >
        <Grid item xs={9}>
          <Stats />
        </Grid>
      </Grid>
      {/* </Grid> */}
    </React.Fragment>
  );
}

export default ComplaintStats;
