export const SmsLogConstants = {
  FETCH_SMSLOG_REQUEST: "FETCH_SMSLOG_REQUEST",
  FETCH_SMSLOG_SUCCESS: "FETCH_SMSLOG_SUCCESS",
  FETCH_SMSLOG_FAILURE: "FETCH_SMSLOG_FAILURE",
  ADD_SMSLOG_REQUEST: "ADD_SMSLOG_REQUEST",
  ADD_SMSLOG_SUCCESS: "ADD_SMSLOG_SUCCESS",
  ADD_SMSLOG_FAILURE: "ADD_SMSLOG_FAILURE",
  SMSLOG_SEARCH_REQUEST: "SMSLOG_SEARCH_REQUEST",
  SMSLOG_SEARCH_SUCCESS: "SMSLOG_SEARCH_SUCCESS",
  SMSLOG_SEARCH_FAILURE: "SMSLOG_SEARCH_FAILURE",
  SMSLOG_AUTO_SEARCH_REQUEST: "SMSLOG_AUTO_SEARCH_REQUEST",
  SMSLOG_AUTO_SEARCH_SUCCESS: "SMSLOG_AUTO_SEARCH_SUCCESS",
  SMSLOG_AUTO_SEARCH_FAILURE: "SMSLOG_AUTO_SEARCH_FAILURE",
  GET_SMSLOG_REQUEST: "GET_SMSLOG_REQUEST",
  GET_SMSLOG_SUCCESS: "GET_SMSLOG_SUCCESS",
  GET_SMSLOG_FAILURE: "GET_SMSLOG_FAILURE",
  UPDATE_SMSLOG_REQUEST: "UPDATE_SMSLOG_REQUEST",
  UPDATE_SMSLOG_SUCCESS: "UPDATE_SMSLOG_SUCCESS",
  UPDATE_SMSLOG_FAILURE: "UPDATE_SMSLOG_FAILURE",
  DOWNLOAD_SMSLOG_REQUEST: "DOWNLOAD_SMSLOG_REQUEST",
  DOWNLOAD_SMSLOG_SUCCESS: "DOWNLOAD_SMSLOG_SUCCESS",
  DOWNLOAD_SMSLOG_FAILURE: "DOWNLOAD_SMSLOG_FAILURE",
  UPLOAD_SMSLOG_REQUEST: "UPLOAD_SMSLOG_REQUEST",
  UPLOAD_SMSLOG_SUCCESS: "UPLOAD_SMSLOG_SUCCESS",
  UPLOAD_SMSLOG_FAILURE: "UPLOAD_SMSLOG_FAILURE",
};
