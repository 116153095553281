import { PppSecretConstants } from "../constants/pppsecret.constants";

const PppSecretReducer = (state, action) => {
  switch (action.type) {
    case PppSecretConstants.FETCH_PPP_SECRET_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PppSecretConstants.FETCH_PPP_SECRET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pppSecrets: action.payload.data,
        paging: action.payload.paging,
      };
    case PppSecretConstants.FETCH_PPP_SECRET_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PppSecretConstants.PPP_SECRET_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PppSecretConstants.PPP_SECRET_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pppSecretSearchResults: action.payload,
      };
    case PppSecretConstants.PPP_SECRET_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PppSecretConstants.PPP_SECRET_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PppSecretConstants.PPP_SECRET_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pppSecretAutoSearchResults: action.payload,
      };
    case PppSecretConstants.PPP_SECRET_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PppSecretConstants.ADD_PPP_SECRET_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case PppSecretConstants.ADD_PPP_SECRET_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case PppSecretConstants.ADD_PPP_SECRET_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case PppSecretConstants.GET_PPP_SECRET_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PppSecretConstants.GET_PPP_SECRET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pppSecret: action.payload,
      };
    case PppSecretConstants.GET_PPP_SECRET_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PppSecretConstants.UPDATE_PPP_SECRET_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case PppSecretConstants.UPDATE_PPP_SECRET_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        pppSecret: action.payload,
      };
    case PppSecretConstants.UPDATE_PPP_SECRET_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case PppSecretConstants.DOWNLOAD_PPP_SECRET_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case PppSecretConstants.DOWNLOAD_PPP_SECRET_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PppSecretConstants.DOWNLOAD_PPP_SECRET_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PppSecretConstants.UPLOAD_PPP_SECRET_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case PppSecretConstants.UPLOAD_PPP_SECRET_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case PppSecretConstants.UPLOAD_PPP_SECRET_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default PppSecretReducer;
