export const SmsTemplateConstants = {
  FETCH_SMSTEMPLATE_REQUEST: "FETCH_SMSTEMPLATE_REQUEST",
  FETCH_SMSTEMPLATE_SUCCESS: "FETCH_SMSTEMPLATE_SUCCESS",
  FETCH_SMSTEMPLATE_FAILURE: "FETCH_SMSTEMPLATE_FAILURE",
  ADD_SMSTEMPLATE_REQUEST: "ADD_SMSTEMPLATE_REQUEST",
  ADD_SMSTEMPLATE_SUCCESS: "ADD_SMSTEMPLATE_SUCCESS",
  ADD_SMSTEMPLATE_FAILURE: "ADD_SMSTEMPLATE_FAILURE",
  SMSTEMPLATE_SEARCH_REQUEST: "SMSTEMPLATE_SEARCH_REQUEST",
  SMSTEMPLATE_SEARCH_SUCCESS: "SMSTEMPLATE_SEARCH_SUCCESS",
  SMSTEMPLATE_SEARCH_FAILURE: "SMSTEMPLATE_SEARCH_FAILURE",
  GET_SMSTEMPLATE_REQUEST: "GET_SMSTEMPLATE_REQUEST",
  GET_SMSTEMPLATE_SUCCESS: "GET_SMSTEMPLATE_SUCCESS",
  GET_SMSTEMPLATE_FAILURE: "GET_SMSTEMPLATE_FAILURE",
  UPDATE_SMSTEMPLATE_REQUEST: "UPDATE_SMSTEMPLATE_REQUEST",
  UPDATE_SMSTEMPLATE_SUCCESS: "UPDATE_SMSTEMPLATE_SUCCESS",
  UPDATE_SMSTEMPLATE_FAILURE: "UPDATE_SMSTEMPLATE_FAILURE",
  DOWNLOAD_SMSTEMPLATE_REQUEST: "DOWNLOAD_SMSTEMPLATE_REQUEST",
  DOWNLOAD_SMSTEMPLATE_SUCCESS: "DOWNLOAD_SMSTEMPLATE_SUCCESS",
  DOWNLOAD_SMSTEMPLATE_FAILURE: "DOWNLOAD_SMSTEMPLATE_FAILURE",
  UPLOAD_SMSTEMPLATE_REQUEST: "UPLOAD_SMSTEMPLATE_REQUEST",
  UPLOAD_SMSTEMPLATE_SUCCESS: "UPLOAD_SMSTEMPLATE_SUCCESS",
  UPLOAD_SMSTEMPLATE_FAILURE: "UPLOAD_SMSTEMPLATE_FAILURE",
  SMSTEMPLATE_AUTO_SEARCH_REQUEST: "SMSTEMPLATE_AUTO_SEARCH_REQUEST",
  SMSTEMPLATE_AUTO_SEARCH_SUCCESS: "SMSTEMPLATE_AUTO_SEARCH_SUCCESS",
  SMSTEMPLATE_AUTO_SEARCH_FAILURE: "SMSTEMPLATE_AUTO_SEARCH_FAILURE",
};
