import { BranchConstants } from "../constants/branch.constants";

const BranchReducer = (state, action) => {
  switch (action.type) {
    case BranchConstants.FETCH_BRANCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case BranchConstants.FETCH_BRANCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        branches: action.payload.data,
        paging: action.payload.paging,
      };
    case BranchConstants.FETCH_BRANCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case BranchConstants.BRANCH_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case BranchConstants.BRANCH_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        branchSearchResults: action.payload,
      };
    case BranchConstants.BRANCH_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case BranchConstants.ADD_BRANCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case BranchConstants.ADD_BRANCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case BranchConstants.ADD_BRANCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case BranchConstants.GET_BRANCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case BranchConstants.GET_BRANCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        branch: action.payload,
      };
    case BranchConstants.GET_BRANCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case BranchConstants.UPDATE_BRANCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case BranchConstants.UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        branch: action.payload,
      };
    case BranchConstants.UPDATE_BRANCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case BranchConstants.DOWNLOAD_BRANCH_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case BranchConstants.DOWNLOAD_BRANCH_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case BranchConstants.DOWNLOAD_BRANCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case BranchConstants.UPLOAD_BRANCH_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case BranchConstants.UPLOAD_BRANCH_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case BranchConstants.UPLOAD_BRANCH_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };
    case BranchConstants.BRANCH_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        autoSearchLoading: true,
        hasError: false,
      };
    case BranchConstants.BRANCH_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        autoSearchLoading: false,
        branchAutoSearchResults: action.payload,
      };
    case BranchConstants.BRANCH_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        autoSearchLoading: false,
      };

    default:
      return state;
  }
};

export default BranchReducer;
