import { IpTvConstants } from "../constants/iptv.constants";

const IpTvReducer = (state, action) => {
  switch (action.type) {
    case IpTvConstants.FETCH_IPTV_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IpTvConstants.FETCH_IPTV_SUCCESS:
      return {
        ...state,
        isLoading: false,
        iptvs: action.payload.data,
        paging: action.payload.paging,
      };
    case IpTvConstants.FETCH_IPTV_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IpTvConstants.IPTV_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IpTvConstants.IPTV_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ipTvSearchResults: action.payload,
      };
    case IpTvConstants.IPTV_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IpTvConstants.IPTV_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IpTvConstants.IPTV_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        iptvAutoSearchResults: action.payload,
      };
    case IpTvConstants.IPTV_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IpTvConstants.IPTV_POLICY_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IpTvConstants.IPTV_POLICY_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        iptvPlanAutoSearchResults: action.payload,
      };
    case IpTvConstants.IPTV_POLICY_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IpTvConstants.ADD_IPTV_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case IpTvConstants.ADD_IPTV_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case IpTvConstants.ADD_IPTV_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case IpTvConstants.GET_IPTV_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IpTvConstants.GET_IPTV_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ipTv: action.payload,
      };
    case IpTvConstants.GET_IPTV_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IpTvConstants.UPDATE_IPTV_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case IpTvConstants.UPDATE_IPTV_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        ipTv: action.payload,
      };
    case IpTvConstants.UPDATE_IPTV_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case IpTvConstants.DOWNLOAD_IPTV_REQUEST:
      return {
        ...state,
        isDownload: true,
        hasError: false,
      };
    case IpTvConstants.DOWNLOAD_IPTV_SUCCESS:
      return {
        ...state,
        isDownload: false,
      };
    case IpTvConstants.DOWNLOAD_IPTV_FAILURE:
      return {
        ...state,
        hasError: true,
        isDownload: false,
      };
    case IpTvConstants.UPLOAD_IPTV_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case IpTvConstants.UPLOAD_IPTV_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case IpTvConstants.UPLOAD_IPTV_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default IpTvReducer;
