import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Breadcrumbs as MuiBreadcrumbs, Grid } from "@mui/material";
import { spacing } from "@mui/system";

import CustomDataGrid from "./components/datagrid";
import useDistrict from "../../../hooks/useDistrict";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import Upload from "./components/Upload";

function DataGridPage() {
  const { getAllDistrict, districts, paging, isLoading, downloadFile } = useDistrict();

  const { totalNumberOfRecords, hasNextPage, pageNumber, pageSize } = paging;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  console.log(useDistrict(), "paging");

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "districtName",
        sort: "ASC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllDistrict(payload);
  };

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
  };

  const onPageChange = (newPaginationModel) => {
    console.log(newPaginationModel, "pagepage");
    const pagination = {
      pageNumber: newPaginationModel.page == 0 ? 1 : newPaginationModel.page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
    setPaginationModel(newPaginationModel);
  };

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });

  const paginationMetaRef = useRef();

  // Memoize to avoid flickering when the `hasNextPage` is `undefined` during refetch
  const paginationMeta = React.useMemo(() => {
    if (hasNextPage !== undefined && paginationMetaRef.current?.hasNextPage !== hasNextPage) {
      paginationMetaRef.current = { hasNextPage };
    }
    return paginationMetaRef.current;
  }, [hasNextPage]);

  const isInitialMount = useRef(true);
  useEffect(() => {
    fetchData();
  }, [payload]);

  const addDistrict = () => {
    navigate("/masters/district/add");
  };
  const editDistrict = (id) => {
    navigate("/masters/district/edit", { state: { id: id } });
  };
  const exportFile = async (fileType) => {
    await downloadFile(fileType, payload);
  };
  const openDownloadDialog = () => {
    return openDialog("upload_district", "Upload District", true, <Upload key={getTimeStamp()} />);
  };

  const handleSortModelChange = (newModel) => {
    //console.log(newModel, "newModel");
    if (newModel.length != 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CustomDataGrid
            trans={t}
            addPage={addDistrict}
            editPage={editDistrict}
            refreshPage={refreshPage}
            downloadFile={exportFile}
            setFilterResetFunc={setFilterResetFunc}
            openDownloadDialog={openDownloadDialog}
            id="districtId"
            isLoading={isLoading}
            sortModel={sortModel}
            handleSortModelChange={handleSortModelChange}
            setPayload={setPayload}
            payload={payload}
            rows={districts}
            rowCount={totalNumberOfRecords}
            onPaginationModelChange={onPageChange}
            paginationMeta={paginationMeta}
            paging={{
              pageNumber: pageNumber,
              pageSize: pageSize,
            }}
            paginationModel={paginationModel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DataGridPage;
