import { SettingConstants } from "../constants/setting.constants";

const SettingReducer = (state, action) => {
  switch (action.type) {
    case SettingConstants.GET_EMAIL_SETTING_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SettingConstants.GET_EMAIL_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantEmailTemplates: action.payload.data,
      };
    case SettingConstants.GET_EMAIL_SETTING_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SettingConstants.GET_SMS_SETTING_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SettingConstants.GET_SMS_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantSmsTemplates: action.payload.data,
      };
    case SettingConstants.GET_SMS_SETTING_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SettingConstants.UPDATE_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SettingConstants.UPDATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantSetting: action.payload,
      };
    case SettingConstants.UPDATE_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case SettingConstants.UPDATE_SMS_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SettingConstants.UPDATE_SMS_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantSetting: action.payload,
      };
    case SettingConstants.UPDATE_SMS_TEMPLATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case SettingConstants.GET_TENANT_SETTING_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SettingConstants.GET_TENANT_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantSetting: action.payload,
      };
    case SettingConstants.GET_TENANT_SETTING_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case SettingConstants.UPDATE_TENANT_SETTING_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SettingConstants.UPDATE_TENANT_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantSetting: action.payload,
      };
    case SettingConstants.UPDATE_TENANT_SETTING_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default SettingReducer;
