import { IspConstants } from "../constants/isp.constants";

const IspReducer = (state, action) => {
  switch (action.type) {
    case IspConstants.FETCH_ISP_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IspConstants.FETCH_ISP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isps: action.payload.data,
        paging: action.payload.paging,
      };
    case IspConstants.FETCH_ISP_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IspConstants.ISP_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IspConstants.ISP_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ispSearchResults: action.payload,
      };
    case IspConstants.ISP_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IspConstants.ADD_ISP_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IspConstants.ADD_ISP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case IspConstants.ADD_ISP_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case IspConstants.GET_ISP_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IspConstants.GET_ISP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isp: action.payload,
      };
    case IspConstants.GET_ISP_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IspConstants.UPDATE_ISP_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IspConstants.UPDATE_ISP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isp: action.payload,
      };
    case IspConstants.UPDATE_ISP_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case IspConstants.DOWNLOAD_ISP_REQUEST:
      return {
        ...state,
        isDownload: true,
        hasError: false,
      };
    case IspConstants.DOWNLOAD_ISP_SUCCESS:
      return {
        ...state,
        isDownload: false,
      };
    case IspConstants.DOWNLOAD_ISP_FAILURE:
      return {
        ...state,
        hasError: true,
        isDownload: false,
      };
    case IspConstants.UPLOAD_ISP_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case IspConstants.UPLOAD_ISP_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case IspConstants.UPLOAD_ISP_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    case IspConstants.ADD_ISP_POLICY_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case IspConstants.ADD_ISP_POLICY_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case IspConstants.ADD_ISP_POLICY_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case IspConstants.FETCH_ISP_POLICY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IspConstants.FETCH_ISP_POLICY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ispPlans: action.payload.data,
        paging: action.payload.paging,
      };
    case IspConstants.FETCH_ISP_POLICY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IspConstants.FETCH_ISP_IPPOOL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IspConstants.FETCH_ISP_IPPOOL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ispIppools: action.payload.data,
        paging: action.payload.paging,
      };
    case IspConstants.FETCH_ISP_IPPOOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IspConstants.ADD_ISP_IPPOOL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case IspConstants.ADD_ISP_IPPOOL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case IspConstants.ADD_ISP_IPPOOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };
    case IspConstants.ISP_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IspConstants.ISP_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ispAutoSearchResults: action.payload,
      };
    case IspConstants.ISP_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default IspReducer;
