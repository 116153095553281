import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import KycDocumentReducer from "../reducers/KycDocumentReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { KycDocumentConstants } from "../constants/kycdocument.constants";
import { saveFile } from "../utils/common";
const initialState = {
  kycDocuments: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  smsLogSearchResults: [],
  smsLog: {},
  kycDocument: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  smsLogAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  isLoadingAutoSearch: false,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const KycDocumentContext = createContext({
  ...initialState,
  getAllKycDocument: () => {},
  addKycDocument: () => {},
  searchKycDocument: () => {},
  autoSearchKycDocument: () => {},
  getKycDocument: () => {},
  updateKycDocument: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function KycDocumentProvider({ children }) {
  const [state, dispatch] = useReducer(KycDocumentReducer, initialState);

  const getAllKycDocument = async (payload) => {
    try {
      dispatch({
        type: KycDocumentConstants.FETCH_KYCDOCUMENT_REQUEST,
      });
      const response = await axios.post("/radius/raduser/kyc-documents/list", payload);
      dispatch({
        type: KycDocumentConstants.FETCH_KYCDOCUMENT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: KycDocumentConstants.FETCH_KYCDOCUMENT_FAILURE,
      });
    }
  };
  const searchKycDocument = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/cancelled-invoices/search", payload);
      dispatch({
        type: KycDocumentConstants.KYCDOCUMENT_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: KycDocumentConstants.KYCDOCUMENT_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: KycDocumentConstants.KYCDOCUMENT_SEARCH_FAILURE,
      });
    }
  };

  const addKycDocument = async (payload) => {
    const response = await axios.post("/radius/raduser/kyc-documents/add", payload);
    dispatch({
      type: KycDocumentConstants.ADD_KYCDOCUMENT_REQUEST,
    });
    dispatch({
      type: KycDocumentConstants.ADD_KYCDOCUMENT_SUCCESS,
      payload: response.data,
    });
  };

  const autoSearchKycDocument = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/cancelled-invoices/auto-search", payload);
      dispatch({
        type: KycDocumentConstants.KYCDOCUMENT_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: KycDocumentConstants.KYCDOCUMENT_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: KycDocumentConstants.KYCDOCUMENT_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const getKycDocument = async (id) => {
    const response = await axios.get("/radius/raduser/" + id);
    dispatch({
      type: KycDocumentConstants.GET_KYCDOCUMENT_REQUEST,
    });
    dispatch({
      type: KycDocumentConstants.GET_KYCDOCUMENT_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (file) => {
    try {
      dispatch({
        type: KycDocumentConstants.UPLOAD_KYCDOCUMENT_REQUEST,
      });
      const response = await axios({
        url: "/radius/raduser/kyc-documents/add",
        method: "POST",
        data: file,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: KycDocumentConstants.UPLOAD_KYCDOCUMENT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: KycDocumentConstants.UPLOAD_KYCDOCUMENT_FAILURE,
      });
    }
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: KycDocumentConstants.DOWNLOAD_KYCDOCUMENT_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post(
      "/radius/raduser/cancelled-invoices/export/" + fileType,
      payload,
      {
        responseType: "blob",
      },
    );
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "cancelled-invoice." + fileType);

    dispatch({
      type: KycDocumentConstants.DOWNLOAD_KYCDOCUMENT_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <KycDocumentContext.Provider
      value={{
        ...state,
        getAllKycDocument,
        addKycDocument,
        searchKycDocument,
        autoSearchKycDocument,
        getKycDocument,
        downloadFile,
        uploadFile,
      }}
    >
      {children}
    </KycDocumentContext.Provider>
  );
}

export { KycDocumentContext, KycDocumentProvider };
