import { SessionHistoryConstants } from "../constants/sessionhistory.constants";

const SessionHistoryReducer = (state, action) => {
  switch (action.type) {
    case SessionHistoryConstants.FETCH_SESSION_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case SessionHistoryConstants.FETCH_SESSION_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sessionHistories: action.payload.data,
        paging: action.payload.paging,
      };
    case SessionHistoryConstants.FETCH_SESSION_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SessionHistoryConstants.SESSION_HISTORY_ASSIGN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case SessionHistoryConstants.SESSION_HISTORY_ASSIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SessionHistoryConstants.SESSION_HISTORY_ASSIGN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SessionHistoryConstants.FETCH_SESSION_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case SessionHistoryConstants.FETCH_SESSION_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sessionHistoryHistories: action.payload.data,
        paging: action.payload.paging,
      };
    case SessionHistoryConstants.FETCH_SESSION_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SessionHistoryConstants.FETCH_SESSION_HISTORY_STATS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case SessionHistoryConstants.FETCH_SESSION_HISTORY_STATS_SUCCESS:
      console.log(action.payload, "sessionHistoriestatys");
      return {
        ...state,
        isLoading: false,
        sessionHistoryStats: action.payload,
      };
    case SessionHistoryConstants.FETCH_SESSION_HISTORY_STATS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SessionHistoryConstants.SESSION_HISTORY_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SessionHistoryConstants.SESSION_HISTORY_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        SessionHistorySearchResults: action.payload,
      };
    case SessionHistoryConstants.SESSION_HISTORY_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SessionHistoryConstants.SESSION_HISTORY_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SessionHistoryConstants.SESSION_HISTORY_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        SessionHistoryAutoSearchResults: action.payload,
      };
    case SessionHistoryConstants.SESSION_HISTORY_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SessionHistoryConstants.ADD_SESSION_HISTORY_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case SessionHistoryConstants.ADD_SESSION_HISTORY_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case SessionHistoryConstants.ADD_SESSION_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case SessionHistoryConstants.GET_SESSION_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SessionHistoryConstants.GET_SESSION_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sessionHistory: action.payload,
      };
    case SessionHistoryConstants.GET_SESSION_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SessionHistoryConstants.GET_SESSION_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SessionHistoryConstants.GET_SESSION_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sessionHistory: action.payload,
      };
    case SessionHistoryConstants.GET_SESSION_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SessionHistoryConstants.GET_SESSION_HISTORY_STATS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SessionHistoryConstants.GET_SESSION_HISTORY_STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sessionHistory: action.payload,
      };
    case SessionHistoryConstants.GET_SESSION_HISTORY_STATS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SessionHistoryConstants.UPDATE_SESSION_HISTORY_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case SessionHistoryConstants.UPDATE_SESSION_HISTORY_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        sessionHistory: action.payload,
      };
    case SessionHistoryConstants.UPDATE_SESSION_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case SessionHistoryConstants.DOWNLOAD_SESSION_HISTORY_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case SessionHistoryConstants.DOWNLOAD_SESSION_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case SessionHistoryConstants.DOWNLOAD_SESSION_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case SessionHistoryConstants.UPLOAD_SESSION_HISTORY_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case SessionHistoryConstants.UPLOAD_SESSION_HISTORY_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case SessionHistoryConstants.UPLOAD_SESSION_HISTORY_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default SessionHistoryReducer;
