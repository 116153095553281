import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import DistrictReducer from "../reducers/DistrictReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { DistrictConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  districts: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  district: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  districtSearchResults: [],
  isDownload: false,
  isLoadingAutoSearch: true,
  isLoadingDownload: false,
  districtAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const DistrictContext = createContext({
  ...initialState,
  getAllDistrict: () => {},
  addDistrict: () => {},
  editDistrict: () => {},
  searchDistrict: () => {},
  autoSearchDistrict: () => {},
  getDistrict: () => {},
  updateDistrict: () => {},
  uploadFile: () => {},
  sampleFile: () => {},
});

function DistrictProvider({ children }) {
  const [state, dispatch] = useReducer(DistrictReducer, initialState);

  const getAllDistrict = async (payload) => {
    try {
      dispatch({
        type: DistrictConstants.FETCH_DISTRICT_REQUEST,
      });
      const response = await axios.post("/master/district/list", payload);
      dispatch({
        type: DistrictConstants.FETCH_DISTRICT_SUCCESS,
        payload: response.data,
      });
      console.log(response.data, "sssssssssssssssssssssApiCallpayload");
      return response.data;
    } catch (exception) {
      dispatch({
        type: DistrictConstants.FETCH_DISTRICT_FAILURE,
      });
    }
  };
  const addDistrict = async (payload) => {
    const response = await axios.post("/master/district/add", payload);
    dispatch({
      type: DistrictConstants.ADD_DISTRICT_REQUEST,
    });
    dispatch({
      type: DistrictConstants.ADD_DISTRICT_SUCCESS,
      payload: response.data,
    });
  };
  const editDistrict = async (payload) => {
    console.log("1111111111111111", payload);
    const response = await axios.put("/master/district/update/" + payload.districtId, payload);
    dispatch({
      type: DistrictConstants.EDIT_DISTRICT_REQUEST,
    });
    dispatch({
      type: DistrictConstants.EDIT_DISTRICT_SUCCESS,
      payload: response.data,
    });
  };
  const searchDistrict = async (payload) => {
    try {
      const response = await axios.post("/master/district/search", payload);
      dispatch({
        type: DistrictConstants.DISTRICT_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: DistrictConstants.DISTRICT_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: DistrictConstants.DISTRICT_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchDistrict = async (payload) => {
    try {
      const response = await axios.post("/master/district/auto-search", payload);
      dispatch({
        type: DistrictConstants.DISTRICT_AUTO_SEARCH_REQUEST,
      });
      dispatch({
        type: DistrictConstants.DISTRICT_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: DistrictConstants.DISTRICT_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const getDistrict = async (id) => {
    console.log(id, "aaaaaaaaaa");
    const response = await axios.get("/master/district/" + id);
    dispatch({
      type: DistrictConstants.GET_DISTRICT_REQUEST,
    });
    dispatch({
      type: DistrictConstants.GET_DISTRICT_SUCCESS,
      payload: response.data,
    });
  };

  const updateDistrict = async (payload) => {
    const response = await axios.put("/master/district/update/" + payload.districtId, payload);
    dispatch({
      type: DistrictConstants.UPDATE_DISTRICT_REQUEST,
    });
    dispatch({
      type: DistrictConstants.UPDATE_DISTRICT_SUCCESS,
      payload: response.data,
    });
  };
  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: DistrictConstants.DOWNLOAD_DISTRICT_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/master/district/export/" + fileType, payload, {
      responseType: "blob",
    });
    //console.log(response);
    await saveFile(response.data, "district." + fileType);
    dispatch({
      type: DistrictConstants.DOWNLOAD_DISTRICT_SUCCESS,
      payload: response.data,
    });
  };
  const sampleFile = async (fileType) => {
    const response = await axios.get("/master/district/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_district." + fileType);
    dispatch({
      type: DistrictConstants.DOWNLOAD_DISTRICT_REQUEST,
    });
    dispatch({
      type: DistrictConstants.DOWNLOAD_DISTRICT_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: DistrictConstants.UPLOAD_DISTRICT_REQUEST,
      });
      const response = await axios({
        url: "/master/district/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: DistrictConstants.UPLOAD_DISTRICT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: DistrictConstants.UPLOAD_DISTRICT_FAILURE,
      });
    }
  };

  return (
    <DistrictContext.Provider
      value={{
        ...state,
        getAllDistrict,
        addDistrict,
        editDistrict,
        searchDistrict,
        autoSearchDistrict,
        updateDistrict,
        getDistrict,
        downloadFile,
        uploadFile,
        sampleFile,
      }}
    >
      {children}
    </DistrictContext.Provider>
  );
}

export { DistrictContext, DistrictProvider };
