import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useTenant from "../../../../hooks/useTenant";
import usePlanGroup from "../../../../hooks/usePlanGroup";

import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import useAuth from "../../../../hooks/useAuth";

const currentTenantPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};
const currentPlanGroupPlanPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function HotelUserFields(props) {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { validationFields } = props;
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const [tenantPayload, setTenantPayload] = useState(currentTenantPayload);
  const [tenantOptions, setTenantOptions] = useState([]);
  const { autoSearchTenant, tenantAutoSearchResults } = useTenant();

  const { autoSearchPlanGroupPlan, planGroupPlanAutoSearchResults } = usePlanGroup();
  const [planGroupPlanPayload, setPlanGroupPlanPayload] = useState(currentPlanGroupPlanPayload);
  const [planGroupPlanOptions, setPlanGroupPlanOptions] = useState([]);

  const fetchTenants = async () => {
    await autoSearchTenant(tenantPayload);
  };

  useEffect(() => {
    fetchTenants();
  }, [tenantPayload]);

  const fetchPlanGroupPlans = async () => {
    await autoSearchPlanGroupPlan(planGroupPlanPayload);
  };

  useEffect(() => {
    fetchPlanGroupPlans();
  }, [planGroupPlanPayload]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (tenantAutoSearchResults.data.length !== 0) {
      const results = [...tenantOptions, ...tenantAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setTenantOptions(filteredResults);
    }
  }, [tenantAutoSearchResults]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (planGroupPlanAutoSearchResults.data.length !== 0) {
      const results = [...planGroupPlanOptions, ...planGroupPlanAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setPlanGroupPlanOptions(filteredResults);
    }
  }, [planGroupPlanAutoSearchResults]);

  return (
    <Grid container spacing={6} className="row">
      {user &&
        (user.businessType === "SUPER_ADMIN" ||
          user.businessType == "DISTRIBUTOR" ||
          user.businessType == "MULTI_SERVICE_OPERATOR") && (
          <Grid item md={6}>
            <SearchableAutoComplete
              name="tenant"
              label={t("tenant_name")}
              control={control}
              data={tenantOptions}
              payload={tenantPayload}
              setPayload={setTenantPayload}
              loading={false}
              multiple={false}
              defaultValue={""}
              hasNextPage={false}
              fields={validationFields}
              fullWidth
              variant="outlined"
              errors={errors}
            ></SearchableAutoComplete>
          </Grid>
        )}
      <Grid item md={6}>
        <CustomTextField
          label="User name"
          name="userName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
          // disabled={true}
        />
      </Grid>
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={planGroupPlanOptions}
          label="Select Plan"
          payload={planGroupPlanPayload}
          setPayload={setPlanGroupPlanPayload}
          loading={false}
          name="plan"
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={validationFields}
          errors={errors}
        />
      </Grid>

      <Grid item md={6}>
        <CustomTextField
          label="Password"
          name="password"
          type="password"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("name")}
          name="name"
          control={control}
          fullWidth
          variant="outlined"
          fields={validationFields}
          errors={errors}
        />
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          name="status"
          label={t("Status")}
          control={control}
          fullWidth
          variant="outlined"
          fields={validationFields}
          errors={errors}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
}

export default HotelUserFields;
