import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import SmsToEmployeeReducer from "../reducers/SmsToEmployeeReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { SmsToEmployeeConstants } from "../constants/smstoemployee.constants";
import { saveFile } from "../utils/common";
const initialState = {
  smsToEmployees: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  smsToEmployeeSearchResults: [],
  smsToEmployee: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,

  smsToEmployeeAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const SmsToEmployeeContext = createContext({
  ...initialState,
  getAllSmsToEmployee: () => {},
  addSmsToEmployee: () => {},
  downloadFile: () => {},
});

function SmsToEmployeeProvider({ children }) {
  const [state, dispatch] = useReducer(SmsToEmployeeReducer, initialState);

  const getAllSmsToEmployee = async (payload) => {
    try {
      dispatch({
        type: SmsToEmployeeConstants.FETCH_SMSTOEMPLOYEE_REQUEST,
      });
      const response = await axios.post("/logs/sms-logs/list", payload);
      dispatch({
        type: SmsToEmployeeConstants.FETCH_SMSTOEMPLOYEE_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: SmsToEmployeeConstants.FETCH_SMSTOEMPLOYEE_FAILURE,
      });
    }
  };
  const addSmsToEmployee = async (payload) => {
    const response = await axios.post("/cpanel/tenant/add", payload);
    dispatch({
      type: SmsToEmployeeConstants.ADD_SMSTOEMPLOYEE_REQUEST,
    });
    dispatch({
      type: SmsToEmployeeConstants.ADD_SMSTOEMPLOYEE_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: SmsToEmployeeConstants.DOWNLOAD_SMSTOEMPLOYEE_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/logs/sms-logs/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "tenant." + fileType);

    dispatch({
      type: SmsToEmployeeConstants.DOWNLOAD_SMSTOEMPLOYEE_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <SmsToEmployeeContext.Provider
      value={{
        ...state,
        getAllSmsToEmployee,
        addSmsToEmployee,
        downloadFile,
      }}
    >
      {children}
    </SmsToEmployeeContext.Provider>
  );
}

export { SmsToEmployeeContext, SmsToEmployeeProvider };
