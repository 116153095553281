import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import CustomDataGrid from "./components/CustomDataGrid";
import useDepartment from "../../../hooks/useDepartment";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import Upload from "./components/Upload";

function DataGridPage() {
  const { getAllDepartment, countries, paging, isLoading, downloadFile } = useDepartment();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "nasName",
        sort: "ASC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllDepartment(payload);
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const addDepartment = () => {
    navigate("/cpanel/department/add");
  };
  const editDepartment = (id) => {
    console.log(id);
    navigate("/cpanel/department/edit", { state: { id: id } });
  };

  const exportFile = async (fileType) => {
    await downloadFile(fileType, payload);
  };
  const openDownloadDialog = () => {
    return openDialog("upload_department", "Upload Department", <Upload key={getTimeStamp()} />);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CustomDataGrid
            trans={t}
            addPage={addDepartment}
            editPage={editDepartment}
            downloadFile={exportFile}
            openDownloadDialog={openDownloadDialog}
            id="departmentId"
            data={countries ? countries : []}
            rowCount={paging.totalNumberOfRecords}
            paging={{
              page: paging.pageNumber,
              pageSize: paging.pageSize,
            }}
            onPageChange={onPageChange}
            isLoading={isLoading}
            sortModel={sortModel}
            handleSortModelChange={handleSortModelChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DataGridPage;
