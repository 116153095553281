import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "../../../../contexts/DialogContext";
import { useTranslation } from "react-i18next";

import {
  Card,
  Grid,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "../../../../components/Toolbar";
import useWindowDimensions from "../../../../utils/windowDimensions";
import useSmsToEmployee from "../../../../hooks/useSmsToEmployee";
import AdvanceFilter from "../AdvanceFilter";
import useMainContentDimensions from "../../../../utils/mainContentDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function CustomDataGrid(props) {
  const { isLoadingDownload, downloadFileType } = useSmsToEmployee();

  const { state } = useLocation();
  const { t } = useTranslation();
  const { addPage, refreshPage, setFilterResetFunc } = props;

  const currentPayload = {
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { width: mainContentWidth } = useMainContentDimensions();

  const { getAllSmsToEmployee, smsToEmployees, paging, downloadFile } = useSmsToEmployee();
  const { openDialog } = useDialog();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllSmsToEmployee(payload);
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Sms</Typography>
        <Typography>Employee</Typography>
      </Breadcrumbs>
    );
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "tenantName",
      headerName: "Tenant Name",
      width: 300,
    },
    {
      field: "employee",
      headerName: "Employees",
      width: 300,
    },
    {
      field: "createdAt",
      headerName: "CreatedOn",
      width: 300,
    },
  ];

  const openFilterDialog = () => {
    return openDialog(
      "advance_filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={
          <Toolbar
            addPage={addPage}
            downloadFile={downloadFile}
            refreshPage={refreshPage}
            openFilterDialog={openFilterDialog}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
          />
        }
        title={t("sms_to_employee")}
        subheader={breadCrump()}
        downloadFile={downloadFile}
      />{" "}
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          columns={columns}
          rows={smsToEmployees}
          getRowId={(row) => row["smsLogId"]}
          rowCount={paging.totalNumberOfRecords}
          loading={false}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          onSelectionModelChange={(GridSelectionModelChangeParams) => {
            // This will return {selections: [selected row indexes]}
            console.log(GridSelectionModelChangeParams);
            if (Array.isArray(GridSelectionModelChangeParams.selectionModel)) {
              // Iterate the selection indexes:
              GridSelectionModelChangeParams.selectionModel.forEach(
                // Get the row data:
                (selection_index) => console.log(smsToEmployees[selection_index]),
              );
            }
          }}
        />
      </CardContent>
    </Card>
  );
}

export default CustomDataGrid;
