import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  MenuItem,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { useDialog } from "../../../contexts/DialogContext";
import useUser from "../../../hooks/useUser";
import CustomTextField from "../../../components/CustomTextField";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import useRadUserWallet from "../../../hooks/useRadUserWallet";
import usePlanGroup from "../../../hooks/usePlanGroup";
import SearchableAutoComplete from "../../../components/SearchableAutoComplete";
import useHotelManagement from "../../../hooks/useHotelManagement";

const currentPlanGroupPlanPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  userName: Yup.string().required("Required"),
  password: Yup.string().max(255).required("Password is required"),
  plan: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  activationDate: Yup.string().required("Required"),
  simUse: Yup.string().required("Required"),
});

const ActivateUser = (props) => {
  const dispatch = useDispatch();
  const { getUser, user, isRenewSubmitting } = useUser();
  const { activateUser } = useHotelManagement();

  const { addRadUserWallet } = useRadUserWallet();
  const { userId, selectedRows, refreshPage } = props;

  const { autoSearchPlanGroupPlan, planGroupPlanAutoSearchResults } = usePlanGroup();
  const [planGroupPlanPayload, setPlanGroupPlanPayload] = useState(currentPlanGroupPlanPayload);
  const [planGroupPlanOptions, setPlanGroupPlanOptions] = useState([]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;
  const { unSetDialog, openDialog } = useDialog();

  useEffect(() => {
    getUser(userId);
  }, [userId]);

  React.useEffect(() => {
    if (Object.keys(user).length !== 0) {
      const initialValues = {
        userName: user.userName,
      };
      reset(initialValues);
    }
  }, [user]);

  const fetchPlanGroupPlans = async () => {
    await autoSearchPlanGroupPlan(planGroupPlanPayload);
  };

  useEffect(() => {
    fetchPlanGroupPlans();
  }, [planGroupPlanPayload]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (planGroupPlanAutoSearchResults.data.length !== 0) {
      const results = [...planGroupPlanOptions, ...planGroupPlanAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setPlanGroupPlanOptions(filteredResults);
    }
  }, [planGroupPlanAutoSearchResults]);

  const onSubmit = async (data) => {
    try {
      const payload = {
        radUserId: userId,
        userName: data.userName,
        password: data.password,
        plan: {
          planId: data.plan.value,
          planName: data.plan.label,
        },
        activationDateStr: data.activationDateStr,
        simUse: data.simUse,
      };
      await activateUser([payload]);
      dispatch(showSuccessSnackbar("User Activated successfully!"));
      refreshPage();
      unSetDialog("active_user");
    } catch (ex) {
      dispatch(showErrorSnackbar(ex.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <CustomTextField
                name="userName"
                label="User Name"
                control={control}
                fields={validationSchema.fields}
                disabled={true}
                fullWidth
                variant="outlined"
                errors={errors}
              ></CustomTextField>
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                label="Password"
                name="password"
                type="password"
                my={2}
                control={control}
                fullWidth
                fields={validationSchema.fields}
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <SearchableAutoComplete
                control={control}
                data={planGroupPlanOptions}
                label="Plan"
                payload={planGroupPlanPayload}
                setPayload={setPlanGroupPlanPayload}
                loading={false}
                name="plan"
                multiple={false}
                defaultValue={""}
                hasNextPage={false}
                fields={validationSchema.fields}
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <CustomDatePicker
                name="activationDate"
                nameTemp="activationDateStr"
                label="No of Days"
                range={false}
                control={control}
                fields={validationSchema.fields}
                timePicker={true}
                format="DD-MM-YYYY HH:mm:ss"
                conversionFormat="YYYY-MM-DD HH:mm:ss"
                minDate={new Date()}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                name="simUse"
                label="Sim Use"
                control={control}
                fields={validationSchema.fields}
                fullWidth
                variant="outlined"
                errors={errors}
              ></CustomTextField>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<ReplayIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Save
            </Button>

            <Button variant="text" onClick={() => unSetDialog("activate_user")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default ActivateUser;
