export const DailyUserConstants = {
  FETCH_DAILY_USER_REQUEST: "FETCH_DAILY_USER_REQUEST",
  FETCH_DAILY_USER_SUCCESS: "FETCH_DAILY_USER_SUCCESS",
  FETCH_DAILY_USER_FAILURE: "FETCH_DAILY_USER_FAILURE",
  FETCH_DASHBOARD_USER_REQUEST: "FETCH_DASHBOARD_USER_REQUEST",
  FETCH_DASHBOARD_USER_SUCCESS: "FETCH_DASHBOARD_USER_SUCCESS",
  FETCH_DASHBOARD_USER_FAILURE: "FETCH_DASHBOARD_USER_FAILURE",
  ADD_DAILY_USER_REQUEST: "ADD_DAILY_USER_REQUEST",
  ADD_DAILY_USER_SUCCESS: "ADD_DAILY_USER_SUCCESS",
  ADD_DAILY_USER_FAILURE: "ADD_DAILY_USER_FAILURE",
  DAILY_USER_SEARCH_REQUEST: "DAILY_USER_SEARCH_REQUEST",
  DAILY_USER_SEARCH_SUCCESS: "DAILY_USER_SEARCH_SUCCESS",
  DAILY_USER_SEARCH_FAILURE: "DAILY_USER_SEARCH_FAILURE",
  DAILY_USER_AUTO_SEARCH_REQUEST: "DAILY_USER_AUTO_SEARCH_REQUEST",
  DAILY_USER_AUTO_SEARCH_SUCCESS: "DAILY_USER_AUTO_SEARCH_SUCCESS",
  DAILY_USER_AUTO_SEARCH_FAILURE: "DAILY_USER_AUTO_SEARCH_FAILURE",
  GET_DAILY_USER_REQUEST: "GET_DAILY_USER_REQUEST",
  GET_DAILY_USER_SUCCESS: "GET_DAILY_USER_SUCCESS",
  GET_DAILY_USER_FAILURE: "GET_DAILY_USER_FAILURE",
  UPDATE_DAILY_USER_REQUEST: "UPDATE_DAILY_USER_REQUEST",
  UPDATE_DAILY_USER_SUCCESS: "UPDATE_DAILY_USER_SUCCESS",
  UPDATE_DAILY_USER_FAILURE: "UPDATE_DAILY_USER_FAILURE",
  DOWNLOAD_DAILY_USER_REQUEST: "DOWNLOAD_DAILY_USER_REQUEST",
  DOWNLOAD_DAILY_USER_SUCCESS: "DOWNLOAD_DAILY_USER_SUCCESS",
  DOWNLOAD_DAILY_USER_FAILURE: "DOWNLOAD_DAILY_USER_FAILURE",
  UPLOAD_DAILY_USER_REQUEST: "UPLOAD_DAILY_USER_REQUEST",
  UPLOAD_DAILY_USER_SUCCESS: "UPLOAD_DAILY_USER_SUCCESS",
  UPLOAD_DAILY_USER_FAILURE: "UPLOAD_DAILY_USER_FAILURE",
};
