export const AgreementTrackerConstants = {
  FETCH_AGREEMENT_TRACKER_REQUEST: "FETCH_AGREEMENT_TRACKER_REQUEST",
  FETCH_AGREEMENT_TRACKER_SUCCESS: "FETCH_AGREEMENT_TRACKER_SUCCESS",
  FETCH_AGREEMENT_TRACKER_FAILURE: "FETCH_AGREEMENT_TRACKER_FAILURE",
  FETCH_AGREEMENT_TRACKER_HISTORY_REQUEST: "FETCH_AGREEMENT_TRACKER_HISTORY_REQUEST",
  FETCH_AGREEMENT_TRACKER_HISTORY_SUCCESS: "FETCH_AGREEMENT_TRACKER_HISTORY_SUCCESS",
  FETCH_AGREEMENT_TRACKER_HISTORY_FAILURE: "FETCH_AGREEMENT_TRACKER_HISTORY_FAILURE",
  ADD_AGREEMENT_TRACKER_REQUEST: "ADD_AGREEMENT_TRACKER_REQUEST",
  ADD_AGREEMENT_TRACKER_SUCCESS: "ADD_AGREEMENT_TRACKER_SUCCESS",
  ADD_AGREEMENT_TRACKER_FAILURE: "ADD_AGREEMENT_TRACKER_FAILURE",
  UPDATE_AGREEMENT_RECEIVED_REQUEST: "UPDATE_AGREEMENT_RECEIVED_REQUEST",
  UPDATE_AGREEMENT_RECEIVED_SUCCESS: "UPDATE_AGREEMENT_RECEIVED_SUCCESS",
  UPDATE_AGREEMENT_RECEIVED_FAILURE: "UPDATE_AGREEMENT_RECEIVED_FAILURE",
  UPDATE_SIGNED_AGREEMENT_RECEIVED_REQUEST: "UPDATE_SIGNED_AGREEMENT_RECEIVED_REQUEST",
  UPDATE_SIGNED_AGREEMENT_RECEIVED_SUCCESS: "UPDATE_SIGNED_AGREEMENT_RECEIVED_SUCCESS",
  UPDATE_SIGNED_AGREEMENT_RECEIVED_FAILURE: "UPDATE_SIGNED_AGREEMENT_RECEIVED_FAILURE",
  UPDATE_COURIER_VERIFIED_REQUEST: "UPDATE_COURIER_VERIFIED_REQUEST",
  UPDATE_COURIER_VERIFIED_SUCCESS: "UPDATE_COURIER_VERIFIED_SUCCESS",
  UPDATE_COURIER_VERIFIED_FAILURE: "UPDATE_COURIER_VERIFIED_FAILURE",
  UPDATE_ISP_AGREEMENT_RECEIVED_REQUEST: "UPDATE_ISP_AGREEMENT_RECEIVED_REQUEST",
  UPDATE_ISP_AGREEMENT_RECEIVED_SUCCESS: "UPDATE_ISP_AGREEMENT_RECEIVED_SUCCESS",
  UPDATE_ISP_AGREEMENT_RECEIVED_FAILURE: "UPDATE_ISP_AGREEMENT_RECEIVED_FAILURE",
  UPDATE_AGREEMENT_DISPATCHED_REQUEST: "UPDATE_AGREEMENT_DISPATCHED_REQUEST",
  UPDATE_AGREEMENT_DISPATCHED_SUCCESS: "UPDATE_AGREEMENT_DISPATCHED_SUCCESS",
  UPDATE_AGREEMENT_DISPATCHED_FAILURE: "UPDATE_AGREEMENT_DISPATCHED_FAILURE",
  UPDATE_IDS_AGREEMENT_DISPATCHED_REQUEST: "UPDATE_IDS_AGREEMENT_DISPATCHED_REQUEST",
  UPDATE_IDS_AGREEMENT_DISPATCHED_SUCCESS: "UPDATE_IDS_AGREEMENT_DISPATCHED_SUCCESS",
  UPDATE_IDS_AGREEMENT_DISPATCHED_FAILURE: "UPDATE_IDS_AGREEMENT_DISPATCHED_FAILURE",
  AGREEMENT_TRACKER_SEARCH_REQUEST: "AGREEMENT_TRACKER_SEARCH_REQUEST",
  AGREEMENT_TRACKER_SEARCH_SUCCESS: "AGREEMENT_TRACKER_SEARCH_SUCCESS",
  AGREEMENT_TRACKER_SEARCH_FAILURE: "AGREEMENT_TRACKER_SEARCH_FAILURE",
  AGREEMENT_TRACKER_AUTO_SEARCH_REQUEST: "AGREEMENT_TRACKER_AUTO_SEARCH_REQUEST",
  AGREEMENT_TRACKER_AUTO_SEARCH_SUCCESS: "AGREEMENT_TRACKER_AUTO_SEARCH_SUCCESS",
  AGREEMENT_TRACKER_AUTO_SEARCH_FAILURE: "AGREEMENT_TRACKER_AUTO_SEARCH_FAILURE",
  GET_AGREEMENT_TRACKER_REQUEST: "GET_AGREEMENT_TRACKER_REQUEST",
  GET_AGREEMENT_TRACKER_SUCCESS: "GET_AGREEMENT_TRACKER_SUCCESS",
  GET_AGREEMENT_TRACKER_FAILURE: "GET_AGREEMENT_TRACKER_FAILURE",
  UPDATE_AGREEMENT_TRACKER_REQUEST: "UPDATE_AGREEMENT_TRACKER_REQUEST",
  UPDATE_AGREEMENT_TRACKER_SUCCESS: "UPDATE_AGREEMENT_TRACKER_SUCCESS",
  UPDATE_AGREEMENT_TRACKER_FAILURE: "UPDATE_AGREEMENT_TRACKER_FAILURE",
  DOWNLOAD_AGREEMENT_TRACKER_REQUEST: "DOWNLOAD_AGREEMENT_TRACKER_REQUEST",
  DOWNLOAD_AGREEMENT_TRACKER_SUCCESS: "DOWNLOAD_AGREEMENT_TRACKER_SUCCESS",
  DOWNLOAD_AGREEMENT_TRACKER_FAILURE: "DOWNLOAD_AGREEMENT_TRACKER_FAILURE",
  UPLOAD_AGREEMENT_TRACKER_REQUEST: "UPLOAD_AGREEMENT_TRACKER_REQUEST",
  UPLOAD_AGREEMENT_TRACKER_SUCCESS: "UPLOAD_AGREEMENT_TRACKER_SUCCESS",
  UPLOAD_AGREEMENT_TRACKER_FAILURE: "UPLOAD_AGREEMENT_TRACKER_FAILURE",
};
