import React from "react";
import Badge from "@mui/material/Badge";
import { Chip } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";

export default function CustomNotificationBadge({ count }) {
  return (
    <IconButton aria-label="notifications">
      <Badge
        badgeContent={count > 99 ? "99+" : count}
        color="primary"
        max={99} // Limits the badge number display
        sx={{ "& .MuiBadge-badge": { fontSize: "0.8rem", height: "18px", minWidth: "22px" } }} // Custom styling
      >
        <Chip label="Online" color="success" />
      </Badge>
    </IconButton>
  );
}
