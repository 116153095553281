import { CardSystemConstants } from "../constants/cardsystem.constants";

const CardSystemReducer = (state, action) => {
  switch (action.type) {
    case CardSystemConstants.FETCH_CARD_SYSTEM_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case CardSystemConstants.FETCH_CARD_SYSTEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cardSystems: action.payload.data,
        paging: action.payload.paging,
      };
    case CardSystemConstants.FETCH_CARD_SYSTEM_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case CardSystemConstants.FETCH_PREPAID_CARD_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case CardSystemConstants.FETCH_PREPAID_CARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        prepaidCards: action.payload.data,
        paging: action.payload.paging,
      };
    case CardSystemConstants.FETCH_PREPAID_CARD_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case CardSystemConstants.GET_CARD_SERIES_COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case CardSystemConstants.GET_CARD_SERIES_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cardSeriesCount: action.payload,
      };
    case CardSystemConstants.GET_CARD_SERIES_COUNT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case CardSystemConstants.GET_PREPAID_CARD_COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case CardSystemConstants.GET_PREPAID_CARD_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        prepaidCardCount: action.payload,
      };
    case CardSystemConstants.GET_PREPAID_CARD_COUNT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case CardSystemConstants.CARD_SYSTEM_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case CardSystemConstants.CARD_SYSTEM_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cardSystemSearchResults: action.payload,
      };
    case CardSystemConstants.CARD_SYSTEM_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case CardSystemConstants.CARD_SYSTEM_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case CardSystemConstants.CARD_SYSTEM_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cardSystemAutoSearchResults: action.payload,
      };
    case CardSystemConstants.CARD_SYSTEM_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case CardSystemConstants.ADD_CARD_SYSTEM_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case CardSystemConstants.ADD_CARD_SYSTEM_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case CardSystemConstants.ADD_CARD_SYSTEM_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case CardSystemConstants.GET_CARD_SYSTEM_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case CardSystemConstants.GET_CARD_SYSTEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cardSystem: action.payload,
      };
    case CardSystemConstants.GET_CARD_SYSTEM_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case CardSystemConstants.UPDATE_CARD_SYSTEM_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case CardSystemConstants.UPDATE_CARD_SYSTEM_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        cardSystem: action.payload,
      };
    case CardSystemConstants.UPDATE_CARD_SYSTEM_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case CardSystemConstants.DOWNLOAD_CARD_SYSTEM_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case CardSystemConstants.DOWNLOAD_CARD_SYSTEM_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case CardSystemConstants.DOWNLOAD_CARD_SYSTEM_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case CardSystemConstants.UPLOAD_CARD_SYSTEM_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case CardSystemConstants.UPLOAD_CARD_SYSTEM_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case CardSystemConstants.UPLOAD_CARD_SYSTEM_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default CardSystemReducer;
