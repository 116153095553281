import React from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { green, orange, red } from "@mui/material/colors";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  SaveAs as SaveAsIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import useTenantSharing from "../../../hooks/useTenantSharing";
import SharingDetails from "./components/SharingDetails";
import useTenant from "../../../hooks/useTenant";
import SearchableAutoComplete from "../../../components/SearchableAutoComplete";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function createData(id, product, date, total, status, method) {
  return { id, product, date, total, status, method };
}

const currentTenantPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const validationSchema = Yup.object().shape({
  tenantSharing: Yup.array().of(
    Yup.object().shape({
      tenantSharingId: Yup.string().required("Rate id mendatory"),
      rateId: Yup.string().required("Rate id mendatory"),
      policyId: Yup.string().required("Policy id mendatory"),
      planName: Yup.string().required("Policy is mendatory"),
      rateFullName: Yup.string().required("Rate name is mendatory"),
      msoSharing: Yup.string().required("MSO sharing is mendatory"),
      distSharing: Yup.string().required("Dist sharing is mendatory"),
      lcoSharing: Yup.string().required("LCO sharing is mendatory"),
      tenant: Yup.object().shape({
        tenantId: Yup.string().required("Value is mendatory"),
        tenantName: Yup.string().required("Label is mendatory"),
      }),
    }),
  ),
});

const initialValues = {
  tenant: "",
};

function TenantSharing() {
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useDispatch();

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = methods;

  const { getAllTenantSharing, tenantSharings, paging, addTenantSharing } = useTenantSharing();

  const [tenantPayload, setTenantPayload] = React.useState(currentTenantPayload);
  const [tenantSharingPayload, setTenantSharingPayload] = React.useState({});
  const [tenantOptions, setTenantOptions] = React.useState([]);
  const { autoSearchTenant, tenantAutoSearchResults } = useTenant();

  const fetchTenants = async () => {
    await autoSearchTenant(tenantPayload);
  };

  const fetchTenantSharing = async () => {
    await getAllTenantSharing(tenantSharingPayload);
  };

  const tenant = watch("tenant");

  React.useEffect(() => {
    fetchTenants();
  }, [tenantPayload]);

  React.useEffect(() => {
    if (Object.keys(tenantSharingPayload).length != 0) {
      fetchTenantSharing();
    }
  }, [tenantSharingPayload]);

  React.useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (tenantAutoSearchResults.data.length !== 0) {
      const results = [...tenantOptions, ...tenantAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setTenantOptions(filteredResults);
    }
  }, [tenantAutoSearchResults]);

  React.useEffect(() => {
    if (tenant != null && Object.keys(tenant).length != 0) {
      setTenantSharingPayload({
        filters: [
          {
            fieldName: "tenantId",
            fieldValue: tenant.value,
            op: "eq",
            dataType: "int",
            isJoin: true,
          },
        ],
        pagination: {
          pageNumber: 1,
          pageSize: 100,
        },
        sortFields: [],
      });
    }
  }, [tenant]);

  const { fields, append, remove } = useFieldArray({ name: "tenantSharing", control });

  React.useEffect(() => {
    if (tenantSharings.length != 0) {
      remove();
      for (let i = 0; i < tenantSharings.length; i++) {
        append({
          tenantSharingId: tenantSharings[i]["tenantSharingId"],
          rateId: tenantSharings[i]["rateId"],
          policyId: tenantSharings[i]["policyId"],
          planName: tenantSharings[i]["planName"],
          rateFullName: tenantSharings[i]["rateFullName"],
          msoSharing: tenantSharings[i]["msoSharing"],
          distSharing: tenantSharings[i]["distSharing"],
          lcoSharing: tenantSharings[i]["lcoSharing"],
          tenant: {
            tenantId: tenantSharings[i]["tenantId"],
            tenantName: tenantSharings[i]["tenantName"],
          },
        });
      }
    } else {
      remove();
    }
  }, [tenantSharings]);

  const onSubmit = async (data) => {
    try {
      await addTenantSharing(data.tenantSharing);
      dispatch(showSuccessSnackbar("Tenant sharing updated successfully!"));
      //reset();
    } catch (error) {
      //setStatus({ sent: false });
      //setErrorSubmit({ submit: err.message });
      dispatch(showErrorSnackbar(error.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Helmet title="Tenant Sharing" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Tenant Sharing
              </Typography>

              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/">
                  Cpanel
                </Link>
                <Link component={NavLink} to="/">
                  Tenant Sharing
                </Link>
                <Typography>List</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={4}>
              <div>
                <SearchableAutoComplete
                  control={control}
                  data={tenantOptions}
                  label="Select Tenant"
                  payload={tenantPayload}
                  setPayload={setTenantPayload}
                  loading={false}
                  name="tenant"
                  multiple={false}
                  defaultValue={""}
                  hasNextPage={false}
                  fields={validationSchema.fields}
                />
              </div>
            </Grid>
            <Grid item>
              <div>
                <Button variant="contained" color="primary" type="submit">
                  <AddIcon />
                  Bulk Update
                </Button>
              </div>
            </Grid>
          </Grid>

          <Divider my={6} />

          <Grid container spacing={6}>
            <Grid item xs={12}>
              <SharingDetails
                fields={fields}
                validationFields={validationSchema.fields}
                paging={paging}
                payload={tenantSharingPayload}
                setPayload={setTenantSharingPayload}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default TenantSharing;
