export const ProformaInvoiceConstants = {
  FETCH_PROFORMA_INVOICE_REQUEST: "FETCH_PROFORMA_INVOICE_REQUEST",
  FETCH_PROFORMA_INVOICE_SUCCESS: "FETCH_PROFORMA_INVOICE_SUCCESS",
  FETCH_PROFORMA_INVOICE_FAILURE: "FETCH_PROFORMA_INVOICE_FAILURE",
  ADD_PROFORMA_INVOICE_REQUEST: "ADD_PROFORMA_INVOICE_REQUEST",
  ADD_PROFORMA_INVOICE_SUCCESS: "ADD_PROFORMA_INVOICE_SUCCESS",
  ADD_PROFORMA_INVOICE_FAILURE: "ADD_PROFORMA_INVOICE_FAILURE",
  PROFORMA_INVOICE_SEARCH_REQUEST: "PROFORMA_INVOICE_SEARCH_REQUEST",
  PROFORMA_INVOICE_SEARCH_SUCCESS: "PROFORMA_INVOICE_SEARCH_SUCCESS",
  PROFORMA_INVOICE_SEARCH_FAILURE: "PROFORMA_INVOICE_SEARCH_FAILURE",
  PROFORMA_INVOICE_AUTO_SEARCH_REQUEST: "PROFORMA_INVOICE_AUTO_SEARCH_REQUEST",
  PROFORMA_INVOICE_AUTO_SEARCH_SUCCESS: "PROFORMA_INVOICE_AUTO_SEARCH_SUCCESS",
  PROFORMA_INVOICE_AUTO_SEARCH_FAILURE: "PROFORMA_INVOICE_AUTO_SEARCH_FAILURE",
  GET_PROFORMA_INVOICE_REQUEST: "GET_PROFORMA_INVOICE_REQUEST",
  GET_PROFORMA_INVOICE_SUCCESS: "GET_PROFORMA_INVOICE_SUCCESS",
  GET_PROFORMA_INVOICE_FAILURE: "GET_PROFORMA_INVOICE_FAILURE",
  UPDATE_PROFORMA_INVOICE_REQUEST: "UPDATE_PROFORMA_INVOICE_REQUEST",
  UPDATE_PROFORMA_INVOICE_SUCCESS: "UPDATE_PROFORMA_INVOICE_SUCCESS",
  UPDATE_PROFORMA_INVOICE_FAILURE: "UPDATE_PROFORMA_INVOICE_FAILURE",
  DOWNLOAD_PROFORMA_INVOICE_REQUEST: "DOWNLOAD_PROFORMA_INVOICE_REQUEST",
  DOWNLOAD_PROFORMA_INVOICE_SUCCESS: "DOWNLOAD_PROFORMA_INVOICE_SUCCESS",
  DOWNLOAD_PROFORMA_INVOICE_FAILURE: "DOWNLOAD_PROFORMA_INVOICE_FAILURE",
  UPLOAD_PROFORMA_INVOICE_REQUEST: "UPLOAD_PROFORMA_INVOICE_REQUEST",
  UPLOAD_PROFORMA_INVOICE_SUCCESS: "UPLOAD_PROFORMA_INVOICE_SUCCESS",
  UPLOAD_PROFORMA_INVOICE_FAILURE: "UPLOAD_PROFORMA_INVOICE_FAILURE",
};
