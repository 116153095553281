import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import SaveCancel from "../../../components/SaveCancel";
import useHotelManagement from "../../../hooks/useHotelManagement";
import HotelUserFields from "./components/HotelUserFields";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  plan: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  tenant: Yup.object()
    .shape({
      label: Yup.string().required("Tenant is required"),
      value: Yup.string().required("Tenant is required"),
    })
    .nullable()
    .required("Tenant is required"),
  userName: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  aadharCardNumber: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function EditHotelUser() {
  const { getHotelManagement, hotelManagement, updateHotelManagement, isSubmitting } =
    useHotelManagement();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Object.keys(hotelManagement).length !== 0) {
      console.log(hotelManagement.status, "hotelManagement.status");
      const initialValues = {
        hotelUserId: hotelManagement.hotelUserId,
        tenant: {
          value: hotelManagement.tenant?.tenantId,
          label: hotelManagement.tenant?.tenantName,
        },
        plan: {
          value: hotelManagement.plan?.planId,
          label: hotelManagement.plan?.planName,
        },
        userName: hotelManagement.userName,
        password: null,
        aadharCardNumber: hotelManagement.aadharCardNumber,
        name: hotelManagement.name,
        status: hotelManagement.status,
      };
      reset(initialValues);
    }
  }, [hotelManagement]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const [currentHotelManagement, setCurrentHotelManagement] = useState(null);
  const { state } = useLocation();
  console.log(state, "location");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      data["tenant"] = {
        tenantId: data.tenant.value,
        tenantName: data.tenant.label,
      };
      data["plan"] = {
        planId: data.plan.value,
        planName: data.plan.label,
      };
      await updateHotelManagement(data);
      dispatch(showSuccessSnackbar("Hotel User updated successfully!"));
      navigate("/hms/hotel-user/list");
    } catch (err) {
      console.log(err, "ddddddddddddd");
      dispatch(showErrorSnackbar("Failed to update hotel user "));
    }
  };

  const cancelBtn = () => {
    navigate("/hms/hotel-user/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>HMS</Typography>
        <Typography>Hotel User</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getHotelManagement(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_hotel_user")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                HotelManagement updated successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <HotelUserFields validationFields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditHotelUser;
