import { PlanConstants } from "../constants/plan.constants";

const PlanReducer = (state, action) => {
  switch (action.type) {
    case PlanConstants.FETCH_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanConstants.FETCH_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        plans: action.payload.data,
        paging: action.payload.paging,
      };
    case PlanConstants.FETCH_PLAN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PlanConstants.PLAN_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanConstants.PLAN_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        planSearchResults: action.payload,
      };
    case PlanConstants.PLAN_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PlanConstants.PLAN_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        autoSearchLoading: true,
        hasError: false,
      };
    case PlanConstants.PLAN_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        autoSearchLoading: false,
        planAutoSearchResults: action.payload,
      };
    case PlanConstants.PLAN_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        autoSearchLoading: false,
      };

    case PlanConstants.ADD_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanConstants.ADD_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PlanConstants.ADD_PLAN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case PlanConstants.GET_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanConstants.GET_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        plan: action.payload,
      };
    case PlanConstants.GET_PLAN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PlanConstants.UPDATE_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanConstants.UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        plan: action.payload,
      };
    case PlanConstants.UPDATE_PLAN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case PlanConstants.DOWNLOAD_PLAN_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case PlanConstants.DOWNLOAD_PLAN_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PlanConstants.DOWNLOAD_PLAN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PlanConstants.UPLOAD_PLAN_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case PlanConstants.UPLOAD_PLAN_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case PlanConstants.UPLOAD_PLAN_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default PlanReducer;
