import { useContext } from "react";

import { UserContext } from "../contexts/UserContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useUser = () => {
  const context = useContext(UserContext);

  if (!context) throw new Error("UserContext must be placed within UserProvider");

  return context;
};

export default useUser;
