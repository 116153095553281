import { useContext } from "react";

import { CityContext } from "../contexts/CityContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useCity = () => {
  const context = useContext(CityContext);

  if (!context) throw new Error("CityContext must be placed within CityProvider");

  return context;
};

export default useCity;
