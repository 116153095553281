import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider } from "react-hook-form";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import SaveCancel from "../../../components/SaveCancel";
import usePppProfile from "../../../hooks/usePppProfile";
import PppProfileFields from "./components/PppProfileFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const initialValues = {
  name: "",
  nas: "",
  mkIpPool: "",
  status: "ACTIVE",
  localAddress: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  nas: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  mkIpPool: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  localAddress: Yup.string().required("Required"),
});

function AddPppProfile() {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useDispatch();

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const { addPppProfile, hasError, errorMessage, isSubmitting } = usePppProfile();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      data["nas"] = {
        nasId: data.nas.value,
        nasName: data.nas.label,
      };
      data["mkIpPool"] = {
        name: data.mkIpPool.label,
        mkIpPoolId: data.mkIpPool.value,
      };
      await addPppProfile(data);
      dispatch(showSuccessSnackbar("PPP Profile added successfully!"));
      navigate("/mikrotik-mgnt/ppp-profile/list");
      reset();
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to add PPP Profile"));
    }
  };

  const cancelBtn = () => {
    navigate("/mikrotik-mgnt/ppp-profile/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Mikrotik Access Mgnt</Typography>
        <Typography>PPP Profile</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_pppProfile")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                PppProfile added successfully!
              </Alert>
            )}
            {errors && errors.submit && (
              <Alert severity="error" my={3}>
                {errors.submit}
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <PppProfileFields fields={validationSchema.fields} />
                <br></br>
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddPppProfile;
