import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { SessionHistoryProvider } from "../contexts/SessionHistoryContext";
// import { UserProvider } from "../contexts/UserContext";
import { DialogProvider } from "../contexts/DialogContext";

export const SessionHistoryComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<SessionHistoryProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};
