import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import PppServerReducer from "../reducers/PppServerReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { PppServerConstants } from "../constants/pppserver.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  pppServers: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  pppServerSearchResults: [],
  pppServer: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  pppServerAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const PppServerContext = createContext({
  ...initialState,
  getAllPppServer: () => {},
  addPppServer: () => {},
  searchPppServer: () => {},
  autoSearchPppServer: () => {},
  getPppServer: () => {},
  updatePppServer: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function PppServerProvider({ children }) {
  const [state, dispatch] = useReducer(PppServerReducer, initialState);

  const getAllPppServer = async (payload) => {
    try {
      dispatch({
        type: PppServerConstants.FETCH_PPP_SERVER_REQUEST,
      });
      const response = await axios.post("ppp-server/list", payload);
      dispatch({
        type: PppServerConstants.FETCH_PPP_SERVER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: PppServerConstants.FETCH_PPP_SERVER_FAILURE,
      });
    }
  };
  const searchPppServer = async (payload) => {
    try {
      const response = await axios.post("ppp-server/search", payload);
      dispatch({
        type: PppServerConstants.PPP_SERVER_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PppServerConstants.PPP_SERVER_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PppServerConstants.PPP_SERVER_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchPppServer = async (payload) => {
    try {
      const response = await axios.post("ppp-server/auto-search", payload);
      dispatch({
        type: PppServerConstants.PPP_SERVER_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PppServerConstants.PPP_SERVER_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PppServerConstants.PPP_SERVER_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addPppServer = async (payload) => {
    const response = await axios.post("ppp-server/add", payload);
    dispatch({
      type: PppServerConstants.ADD_PPP_SERVER_REQUEST,
    });
    dispatch({
      type: PppServerConstants.ADD_PPP_SERVER_SUCCESS,
      payload: response.data,
    });
  };

  const getPppServer = async (id) => {
    const response = await axios.get("ppp-server/" + id);
    dispatch({
      type: PppServerConstants.GET_PPP_SERVER_REQUEST,
    });
    dispatch({
      type: PppServerConstants.GET_PPP_SERVER_SUCCESS,
      payload: response.data,
    });
  };

  const updatePppServer = async (payload) => {
    const response = await axios.put("ppp-server/update/" + payload.pppServerId, payload);
    dispatch({
      type: PppServerConstants.UPDATE_PPP_SERVER_REQUEST,
    });
    dispatch({
      type: PppServerConstants.UPDATE_PPP_SERVER_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: PppServerConstants.DOWNLOAD_PPP_SERVER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("ppp-server/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "pppServer." + fileType);
    dispatch({
      type: PppServerConstants.DOWNLOAD_PPP_SERVER_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("ppp-server/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_pppServer." + fileType);
    dispatch({
      type: PppServerConstants.DOWNLOAD_PPP_SERVER_REQUEST,
    });
    dispatch({
      type: PppServerConstants.DOWNLOAD_PPP_SERVER_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: PppServerConstants.UPLOAD_PPP_SERVER_REQUEST,
      });
      const response = await axios({
        url: "ppp-server/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: PppServerConstants.UPLOAD_PPP_SERVER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: PppServerConstants.UPLOAD_PPP_SERVER_FAILURE,
      });
    }
  };

  return (
    <PppServerContext.Provider
      value={{
        ...state,
        getAllPppServer,
        addPppServer,
        searchPppServer,
        autoSearchPppServer,
        getPppServer,
        updatePppServer,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </PppServerContext.Provider>
  );
}

export { PppServerContext, PppServerProvider };
