import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import AgreementTrackerReducer from "../reducers/AgreementTrackerReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { AgreementTrackerConstants } from "../constants/agreementtracker.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  agreementTrackers: [],
  agreementTrackerHistories: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  agreementTrackerSearchResults: [],
  agreementTracker: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  agreementTrackerAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const AgreementTrackerContext = createContext({
  ...initialState,
  getAllAgreementTracker: () => {},
  getAllAgreementTrackerHistory: () => {},
  addAgreementTracker: () => {},
  updateAgreementReceived: () => {},
  searchAgreementTracker: () => {},
  autoSearchAgreementTracker: () => {},
  getAgreementTracker: () => {},
  updateAgreementTracker: () => {},
  updateAgreementDispatched: () => {},
  updatesignedAgreementReceived: () => {},
  updateCourierVerified: () => {},
  updateIspAgreementReceived: () => {},
  updateIdsAgreement: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function AgreementTrackerProvider({ children }) {
  const [state, dispatch] = useReducer(AgreementTrackerReducer, initialState);

  const getAllAgreementTracker = async (payload) => {
    try {
      dispatch({
        type: AgreementTrackerConstants.FETCH_AGREEMENT_TRACKER_REQUEST,
      });
      const response = await axios.post("/agreement-tracker/list", payload);
      dispatch({
        type: AgreementTrackerConstants.FETCH_AGREEMENT_TRACKER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: AgreementTrackerConstants.FETCH_AGREEMENT_TRACKER_FAILURE,
      });
    }
  };
  const getAllAgreementTrackerHistory = async (payload) => {
    try {
      dispatch({
        type: AgreementTrackerConstants.FETCH_AGREEMENT_TRACKER_HISTORY_REQUEST,
      });
      const response = await axios.post("/agreement-tracker/history", payload);
      dispatch({
        type: AgreementTrackerConstants.FETCH_AGREEMENT_TRACKER_HISTORY_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: AgreementTrackerConstants.FETCH_AGREEMENT_TRACKER_HISTORY_FAILURE,
      });
    }
  };
  const searchAgreementTracker = async (payload) => {
    try {
      const response = await axios.post("/agreement-tracker/search", payload);
      dispatch({
        type: AgreementTrackerConstants.AGREEMENT_TRACKER_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: AgreementTrackerConstants.AGREEMENT_TRACKER_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: AgreementTrackerConstants.AGREEMENT_TRACKER_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchAgreementTracker = async (payload) => {
    try {
      const response = await axios.post("/agreement-tracker/auto-search", payload);
      dispatch({
        type: AgreementTrackerConstants.AGREEMENT_TRACKER_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: AgreementTrackerConstants.AGREEMENT_TRACKER_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: AgreementTrackerConstants.AGREEMENT_TRACKER_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addAgreementTracker = async (payload) => {
    const response = await axios.post("/agreement-tracker/add", payload);
    dispatch({
      type: AgreementTrackerConstants.ADD_AGREEMENT_TRACKER_REQUEST,
    });
    dispatch({
      type: AgreementTrackerConstants.ADD_AGREEMENT_TRACKER_SUCCESS,
      payload: response.data,
    });
  };

  const updateAgreementReceived = async (payload) => {
    console.log(payload, "payyyyy");
    const response = await axios.put(
      "/agreement-tracker/update-tenant-agreement-received",
      payload,
    );
    dispatch({
      type: AgreementTrackerConstants.UPDATE_AGREEMENT_RECEIVED_REQUEST,
    });
    dispatch({
      type: AgreementTrackerConstants.UPDATE_AGREEMENT_RECEIVED_SUCCESS,
      payload: response.data,
    });
  };

  const updatesignedAgreementReceived = async (payload) => {
    console.log(payload, "payyyyy");
    const response = await axios.put(
      "/agreement-tracker/update-tenant-signed-agreement-received/" + payload.agreementTrackerId,
      payload,
    );
    dispatch({
      type: AgreementTrackerConstants.UPDATE_SIGNED_AGREEMENT_RECEIVED_REQUEST,
    });
    dispatch({
      type: AgreementTrackerConstants.UPDATE_SIGNED_AGREEMENT_RECEIVED_SUCCESS,
      payload: response.data,
    });
  };

  const updateCourierVerified = async (payload) => {
    console.log(payload, "payyyyy");
    const response = await axios.put("/agreement-tracker/update-courier-verified", payload);
    dispatch({
      type: AgreementTrackerConstants.UPDATE_COURIER_VERIFIED_REQUEST,
    });
    dispatch({
      type: AgreementTrackerConstants.UPDATE_COURIER_VERIFIED_SUCCESS,
      payload: response.data,
    });
  };

  const updateIspAgreementReceived = async (payload) => {
    console.log(payload, "payyyyy");
    const response = await axios.put("/agreement-tracker/update-isp-agreement-received", payload);
    dispatch({
      type: AgreementTrackerConstants.UPDATE_ISP_AGREEMENT_RECEIVED_REQUEST,
    });
    dispatch({
      type: AgreementTrackerConstants.UPDATE_ISP_AGREEMENT_RECEIVED_SUCCESS,
      payload: response.data,
    });
  };

  const updateAgreementDispatched = async (payload) => {
    console.log(payload, "payyyyy");
    const response = await axios.put(
      "/agreement-tracker/update-tenant-agreement-dispatch",
      payload,
    );
    dispatch({
      type: AgreementTrackerConstants.UPDATE_AGREEMENT_DISPATCHED_REQUEST,
    });
    dispatch({
      type: AgreementTrackerConstants.UPDATE_AGREEMENT_DISPATCHED_SUCCESS,
      payload: response.data,
    });
  };

  const updateIdsAgreement = async (payload) => {
    console.log(payload, "payyyyy");
    const response = await axios.put(
      "/agreement-tracker/update-isp-signed-agreement-dispatch",
      payload,
    );
    dispatch({
      type: AgreementTrackerConstants.UPDATE_IDS_AGREEMENT_DISPATCHED_REQUEST,
    });
    dispatch({
      type: AgreementTrackerConstants.UPDATE_IDS_AGREEMENT_DISPATCHED_SUCCESS,
      payload: response.data,
    });
  };

  const getAgreementTracker = async (id) => {
    const response = await axios.get("/agreement-tracker/" + id);
    dispatch({
      type: AgreementTrackerConstants.GET_AGREEMENT_TRACKER_REQUEST,
    });
    dispatch({
      type: AgreementTrackerConstants.GET_AGREEMENT_TRACKER_SUCCESS,
      payload: response.data,
    });
  };

  const updateAgreementTracker = async (payload) => {
    const response = await axios.put(
      "/agreement-tracker/update/" + payload.agreementTrackerId,
      payload,
    );
    dispatch({
      type: AgreementTrackerConstants.UPDATE_AGREEMENT_TRACKER_REQUEST,
    });
    dispatch({
      type: AgreementTrackerConstants.UPDATE_AGREEMENT_TRACKER_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: AgreementTrackerConstants.DOWNLOAD_AGREEMENT_TRACKER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/agreement-tracker/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "agreementTracker." + fileType);
    dispatch({
      type: AgreementTrackerConstants.DOWNLOAD_AGREEMENT_TRACKER_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/agreement-tracker/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_agreementTracker." + fileType);
    dispatch({
      type: AgreementTrackerConstants.DOWNLOAD_AGREEMENT_TRACKER_REQUEST,
    });
    dispatch({
      type: AgreementTrackerConstants.DOWNLOAD_AGREEMENT_TRACKER_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: AgreementTrackerConstants.UPLOAD_AGREEMENT_TRACKER_REQUEST,
      });
      const response = await axios({
        url: "/agreement-tracker/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: AgreementTrackerConstants.UPLOAD_AGREEMENT_TRACKER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: AgreementTrackerConstants.UPLOAD_AGREEMENT_TRACKER_FAILURE,
      });
    }
  };

  return (
    <AgreementTrackerContext.Provider
      value={{
        ...state,
        getAllAgreementTracker,
        getAllAgreementTrackerHistory,
        addAgreementTracker,
        updateAgreementReceived,
        updateAgreementDispatched,
        updateCourierVerified,
        updateIspAgreementReceived,
        updateIdsAgreement,
        searchAgreementTracker,
        autoSearchAgreementTracker,
        updatesignedAgreementReceived,
        getAgreementTracker,
        updateAgreementTracker,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </AgreementTrackerContext.Provider>
  );
}

export { AgreementTrackerContext, AgreementTrackerProvider };
