import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import RoutesReducer from "../reducers/RoutesReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { RoutesConstants } from "../constants/routes.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  routes: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  routesSearchResults: [],
  route: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  routesAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const RoutesContext = createContext({
  ...initialState,
  getAllRoutes: () => {},
  addRoutes: () => {},
  searchRoutes: () => {},
  autoSearchRoutes: () => {},
  getRoutes: () => {},
  updateRoutes: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function RoutesProvider({ children }) {
  const [state, dispatch] = useReducer(RoutesReducer, initialState);

  const getAllRoutes = async (payload) => {
    try {
      dispatch({
        type: RoutesConstants.FETCH_ROUTES_REQUEST,
      });
      const response = await axios.post("/ip-routes/list", payload);
      dispatch({
        type: RoutesConstants.FETCH_ROUTES_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: RoutesConstants.FETCH_ROUTES_FAILURE,
      });
    }
  };
  const searchRoutes = async (payload) => {
    try {
      const response = await axios.post("/master/routes/search", payload);
      dispatch({
        type: RoutesConstants.ROUTES_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: RoutesConstants.ROUTES_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: RoutesConstants.ROUTES_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchRoutes = async (payload) => {
    try {
      const response = await axios.post("/master/routes/auto-search", payload);
      dispatch({
        type: RoutesConstants.ROUTES_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: RoutesConstants.ROUTES_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: RoutesConstants.ROUTES_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addRoutes = async (payload) => {
    const response = await axios.post("/ip-routes/add", payload);
    dispatch({
      type: RoutesConstants.ADD_ROUTES_REQUEST,
    });
    dispatch({
      type: RoutesConstants.ADD_ROUTES_SUCCESS,
      payload: response.data,
    });
  };

  const getRoutes = async (id) => {
    const response = await axios.get("/ip-routes/" + id);
    dispatch({
      type: RoutesConstants.GET_ROUTES_REQUEST,
    });
    dispatch({
      type: RoutesConstants.GET_ROUTES_SUCCESS,
      payload: response.data,
    });
  };

  const updateRoutes = async (payload) => {
    const response = await axios.put("/ip-routes/update/" + payload.ipRoutesId, payload);
    dispatch({
      type: RoutesConstants.UPDATE_ROUTES_REQUEST,
    });
    dispatch({
      type: RoutesConstants.UPDATE_ROUTES_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: RoutesConstants.DOWNLOAD_ROUTES_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/master/routes/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "routes." + fileType);
    dispatch({
      type: RoutesConstants.DOWNLOAD_ROUTES_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/master/routes/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_routes." + fileType);
    dispatch({
      type: RoutesConstants.DOWNLOAD_ROUTES_REQUEST,
    });
    dispatch({
      type: RoutesConstants.DOWNLOAD_ROUTES_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: RoutesConstants.UPLOAD_ROUTES_REQUEST,
      });
      const response = await axios({
        url: "/master/routes/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: RoutesConstants.UPLOAD_ROUTES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: RoutesConstants.UPLOAD_ROUTES_FAILURE,
      });
    }
  };

  return (
    <RoutesContext.Provider
      value={{
        ...state,
        getAllRoutes,
        addRoutes,
        searchRoutes,
        autoSearchRoutes,
        getRoutes,
        updateRoutes,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
}

export { RoutesContext, RoutesProvider };
