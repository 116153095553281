import { useContext } from "react";

import { AddonContext } from "../contexts/AddonContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useAddon = () => {
  const context = useContext(AddonContext);

  if (!context) throw new Error("AddonContext must be placed within AddonProvider");

  return context;
};

export default useAddon;
