import { Chart } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { MoreVertical } from "react-feather";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useCardSystem from "../../../hooks/useCardSystem";
import { useTheme } from "@mui/material/styles";
import { green, red, orange, pink, purple, blue, grey } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};
  }
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const PinkText = styled.span`
  color: ${() => pink[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const PurpleText = styled.span`
  color: ${() => purple[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const BlueText = styled.span`
  color: ${() => blue[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const GreyText = styled.span`
  color: ${() => grey[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const OrangeText = styled.span`
  color: ${() => orange[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const Stats = ({ theme }) => {
  const stheme = useTheme();
  const { t } = useTranslation();
  const { state } = useLocation();

  const currentPayload = {
    filters: [
      {
        fieldName: "leadId",
        fieldValue: state?.id,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "leadStatsId",
        sort: "DESC",
      },
    ],
  };
  const [] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const {
    getPrepaidCardCount,
    getCardSeriesCount,
    prepaidCardCount,
    cardSeriesCount,
    downloadFile,
  } = useCardSystem();
  const [payload] = useState(currentPayload);

  const fetchData = async () => {
    await getPrepaidCardCount(payload);
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const fetchCountData = async () => {
    await getCardSeriesCount(payload);
  };

  useEffect(() => {
    fetchCountData();
  }, [payload]);

  console.log(cardSeriesCount, "ghdhdhdh");
  console.log(prepaidCardCount, "hhjklllllllllj");

  const data = {
    labels: ["Total Series Generated", "Total Cards Generated"],
    datasets: [
      {
        data: [260, 125, 164],
        backgroundColor: [stheme.palette.secondary.main, red[500], orange[500]],
        borderWidth: 5,
        borderColor: stheme.palette.background.paper,
      },
    ],
  };

  const [graphData, setGraphData] = useState(data);

  useEffect(() => {
    const data = {
      labels: ["Total Series Generated", "Total Cards Generated"],
      datasets: [
        {
          data: [cardSeriesCount, prepaidCardCount],
          backgroundColor: [stheme.palette.secondary.main, red[500]],
          borderWidth: 5,
          borderColor: stheme.palette.background.paper,
        },
      ],
    };
    //Set graph data
    setGraphData(data);
  }, [cardSeriesCount, prepaidCardCount]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title={t("card_stats")}
      />
      <CardContent theme={stheme}>
        <ChartWrapper>
          <DoughnutInner>
            <Typography variant="h4"></Typography>
            <Typography variant="caption">Total</Typography>
          </DoughnutInner>
          <Chart type="doughnut" data={graphData} options={options} />
        </ChartWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">Over All Statistics</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Total Series Generated:
              </TableCell>
              <TableCell align="right">
                <BlueText theme={stheme}>{cardSeriesCount}</BlueText>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total Cards Generated:
              </TableCell>
              <TableCell align="right">
                <RedText theme={stheme}>{prepaidCardCount}</RedText>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Stats;
