import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Formik, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";

import SaveCancel from "../../../components/SaveCancel";
import useEmailMaster from "../../../hooks/useEmailMaster";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import EmailMasterDetail from "./components/EmailMasterDetail";
import { useFormState, useFormDispatch } from "../../../contexts/EmailMasterFormContext";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const initialValues = {
  emailTemplateName: "",
  emailTemplateCode: "",
  subject: "",
  content: "",
  status: "ACTIVE",
};

const validationSchema = Yup.object().shape({
  emailTemplateName: Yup.string().required("Required"),
  emailTemplateCode: Yup.string().required("Required"),
  subject: Yup.string().required("Required"),
  content: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

const AddEmailMaster = () => {
  const [defaultContentValue, setDefaultContentValue] = useState("");
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { addEmailMaster, hasError, errorMessage, isSubmitting } = useEmailMaster();

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  React.useEffect(() => {
    console.log(errors, "ddddddd");
  }, [errors]);

  // const [isSubmitting, setIsSubmitting] = useState(false);

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const { state } = useFormState();

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // React.useEffect(() => {
  //   if (form.current) {
  //     const { values, errors } = form.current;
  //     console.log(values, "i am here to update values");
  //     dispatch({
  //       type: "UPDATE_FORM",
  //       payload: {
  //         id: "emailmaster",
  //         data: { values, errors },
  //       },
  //     });
  //   }
  // }, [value]);

  const navigate = useNavigate();

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Email</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  const onSubmit = async (data) => {
    try {
      await addEmailMaster(data);
      dispatch(showSuccessSnackbar("Email template added successfully!"));
      reset();
      setDefaultContentValue("");
      navigate("/masters/emailmaster/list");
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
  };

  const cancelBtn = () => {
    navigate("/masters/emailmaster/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_emailtemplate")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value} onChange={handleTabChange} aria-label="EmailMaster">
                    <Tab label="Details" {...a11yProps(0)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <EmailMasterDetail
                      defaultContentValue={defaultContentValue}
                      fields={validationSchema.fields}
                    />
                  </TabPanel>
                </Box>

                <SaveCancel
                  cancelBtn={cancelBtn}
                  handleSubmit={handleSubmit(onSubmit)}
                  resetForm={reset}
                />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
};

export default AddEmailMaster;
