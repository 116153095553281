import {
  BookOpen,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  Settings,
  Database,
  Framer,
  Aperture,
  Archive,
  Server,
  Account,
} from "react-feather";

import DashboardIcon from "@mui/icons-material/Dashboard";
import StorefrontIcon from "@mui/icons-material/Storefront";

const pagesSection = [
  {
    href: "/hotel-dashboard",
    icon: DashboardIcon,
    title: "Hotel Dashboard",
    href: "/hotel-dashboard/default",
  },
  {
    href: "/cpanel",
    icon: Framer,
    title: "CPanel",
    children: [
      {
        href: "/cpanel/employee/list",
        title: "Employee",
      },
    ],
  },
  {
    href: "/hms",
    icon: StorefrontIcon,
    title: "HMS",
    children: [
      {
        href: "/hms/hotel-user/list",
        title: "Hotel Users",
      },
      {
        href: "/hms/activate-user/list",
        title: "Activate User",
      },
      {
        href: "/hms/deactivate-user/list",
        title: "Deactivate User",
      },
    ],
  },
];

const HotelItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default HotelItems;
