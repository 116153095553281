import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { SettingProvider } from "../contexts/SettingContext";
import { DialogProvider } from "../contexts/DialogContext";
import { FormProvider } from "../contexts/SettingFormContext";
import { CountryProvider } from "../contexts/CountryContext";

export const SettingComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <SettingProvider key={0} />,
        <FormProvider key={1} />,
        <CountryProvider key={2} />,
        <DialogProvider key={3} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
