import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";

function PincodeFields(props) {
  const { fields } = props;

  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Grid container spacing={6} className="row">
      <Grid item md={6}>
        <CustomTextField
          label={t("pincode_name")}
          name="pincodeName"
          control={control}
          fields={fields}
          fullWidth
          variant="outlined"
          errors={errors}
        />
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          name="status"
          label={t("Status")}
          control={control}
          fields={fields}
          fullWidth
          variant="outlined"
          errors={errors}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
}

export default PincodeFields;
