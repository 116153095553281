import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import ReplayIcon from "@mui/icons-material/Replay";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

import {
  ListItemIcon,
  Menu,
  MenuItem,
  ListItemText as MuiListItemText,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";

const ListItemText = styled(MuiListItemText)(spacing);

const ComplaintMore = (props) => {
  const { editPage, rowId, menus, viewDetails, assignTo, clickIcon } = props;
  console.log(props, "menusmenus");
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemClick = () => {
    editPage(rowId);
    handleClose();
  };

  const menuItemNewClick = () => {
    viewDetails(rowId);
    handleClose();
  };
  const menuItemAssignClick = () => {
    assignTo(rowId);
    handleClose();
  };

  const editMenu = () => {};

  return (
    <div mt={3}>
      <IconButton
        aria-owns={anchorEl ? "icon-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="secondary"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="icon-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {menus && menus.includes("edit") && (
          <MenuItem onClick={menuItemClick}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary={t("Edit")} pl={0} />
          </MenuItem>
        )}

        {menus && menus.includes("details") && (
          <MenuItem onClick={menuItemNewClick}>
            <ListItemIcon>
              <PreviewIcon />
            </ListItemIcon>
            <ListItemText primary={t("Details")} pl={0} />
          </MenuItem>
        )}
        {menus && menus.includes("assign") && (
          <MenuItem onClick={menuItemAssignClick}>
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary={t("Assign")} pl={0} />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
ComplaintMore.propTypes = {
  editPage: PropTypes.func,
};

export default ComplaintMore;
