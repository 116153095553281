import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert as MuiAlert, Button, DialogActions, Box, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useDialog } from "../../../../contexts/DialogContext";
import useTenant from "../../../../hooks/useTenant";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useIppool from "../../../../hooks/useIppool";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";

const initialValues = {
  ippool: [],
};

const validationSchema = Yup.object().shape({
  ippool: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.number().required(),
      }),
    )
    .min(1, "You need at least one ippool")
    .required("Please select the ippool"),
});

const Alert = styled(MuiAlert)(spacing);

const MapIppoolModal = ({ tenantId, isNew }) => {
  const { unSetDialog } = useDialog();

  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { autoSearchIppool, ippoolAutoSearchResults, autoSearchLoading } = useIppool();
  const { addTenantIppool, isSubmitting, getTenantIppools } = useTenant();
  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const [payload, setPayload] = useState(currentPayload);

  const [tenantPolicyPayload, setTenantPolicyPayload] = useState({
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  });

  const [ippoolOptions, setIppoolOptions] = useState([]);

  const fetchData = async () => {
    await autoSearchIppool(payload);
  };

  useEffect(() => {
    if (ippoolAutoSearchResults.data.length !== 0) {
      setIppoolOptions([...ippoolOptions, ...ippoolAutoSearchResults.data]);
    }
  }, [ippoolAutoSearchResults]);

  useEffect(() => {
    fetchData();
  }, [payload]);

  const { handleSubmit, control, reset } = methods;

  const onSubmit = async (data) => {
    try {
      const ippoolDetails = [];
      const ippools = data.ippool;
      for (let i = 0; i < ippools.length; i++) {
        ippoolDetails.push({
          tenantId: tenantId,
          ippoolId: ippools[i]["value"],
          poolName: ippools[i]["label"],
        });
      }
      await addTenantIppool(ippoolDetails);
      await getTenantIppools(tenantPolicyPayload);
      reset();
      dispatch(showSuccessSnackbar("Ippool mapped successfully!"));
      unSetDialog("map_ippool_modal");
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
  };
  const [modalNew, setModalNew] = useState(isNew);
  useEffect(() => {
    if (modalNew) {
      setIppoolOptions([]);
      setModalNew(false);
    }
  }, [modalNew]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 10 }}>
            <SearchableAutoComplete
              control={control}
              data={ippoolOptions}
              label="Choose a Ippool"
              payload={payload}
              setPayload={setPayload}
              loading={autoSearchLoading}
              name="ippool"
              multiple={true}
            />
          </Box>
          <DialogActions>
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              endIcon={isSubmitting ? <CircularProgress size={24} /> : null}
            >
              Add
            </Button>

            <Button variant="text" onClick={() => unSetDialog("map_ippool_modal")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default MapIppoolModal;
