import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";

import usePlanGroup from "../../../../hooks/usePlanGroup";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useRate from "../../../../hooks/useRate";
import { searchPayload } from "../../../../utils/common";

const currentPlanGroupPlanPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const currentRatePayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function PlanDetails(props) {
  const searchPayloadValue = searchPayload();
  const { autoSearchPlanGroupPlan, planGroupPlanAutoSearchResults } = usePlanGroup();
  const {
    autoSearchLoading: rateAutoSearchLoading,
    autoSearchRate,
    rateAutoSearchResults,
  } = useRate();
  const { validationFields } = props;
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
    resetField,
  } = useFormContext();

  const [planGroupPlanPayload, setPlanGroupPlanPayload] = useState(currentPlanGroupPlanPayload);
  const [ratePayload, setRatePayload] = useState(currentRatePayload);
  const [planGroupPlanOptions, setPlanGroupPlanOptions] = useState([]);
  const [rateOptions, setRateOptions] = useState([]);

  const tenant = watch("tenant");
  const plan = watch("plan");
  const serviceType = watch("userServiceType");

  console.log(serviceType, "serviceType");

  useEffect(() => {
    console.log(tenant, serviceType, "dssssssssssssss");
    if (Object.keys(tenant).length != 0) {
      console.log(tenant, serviceType, "dssssssssssssss");
      setPlanGroupPlanOptions([]);
      setPlanGroupPlanPayload({
        ...planGroupPlanPayload,
        filters: [
          {
            fieldName: "tenantId",
            fieldValue: tenant.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      });
    } else {
      setPlanGroupPlanOptions([]);
    }
  }, [tenant]);

  const isPlanMounted = useRef(false);

  useEffect(() => {
    if (Object.keys(plan).length != 0) {
      setRateOptions([]);
      setRatePayload({
        ...ratePayload,
        filters: [
          {
            fieldName: "planId",
            fieldValue: plan.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      });
    }
  }, [plan]);

  const fetchPlanGroupPlans = async () => {
    if (Object.keys(planGroupPlanPayload).length != 0 && planGroupPlanPayload.filters.length != 0) {
      await autoSearchPlanGroupPlan(planGroupPlanPayload);
    }
  };

  const fetchRates = async () => {
    if (Object.keys(ratePayload).length != 0 && ratePayload.filters.length != 0) {
      await autoSearchRate(ratePayload);
    }
  };

  useEffect(() => {
    fetchPlanGroupPlans();
  }, [planGroupPlanPayload]);

  useEffect(() => {
    fetchRates();
  }, [ratePayload]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (planGroupPlanAutoSearchResults.data.length !== 0) {
      const results = [...planGroupPlanOptions, ...planGroupPlanAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setPlanGroupPlanOptions(filteredResults);
    }
  }, [planGroupPlanAutoSearchResults]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (rateAutoSearchResults.data.length !== 0) {
      const results = [...rateOptions, ...rateAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setRateOptions(filteredResults);
    }
  }, [rateAutoSearchResults]);

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={planGroupPlanOptions}
          label="Select Plan"
          payload={planGroupPlanPayload}
          setPayload={setPlanGroupPlanPayload}
          loading={false}
          name="plan"
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={rateOptions}
          label="Select Rate"
          payload={ratePayload}
          setPayload={setRatePayload}
          loading={rateAutoSearchLoading}
          name="rate"
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={validationFields}
        />
      </Grid>
    </Grid>
  );
}

export default PlanDetails;
