import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  Typography,
  CardHeader,
} from "@mui/material";
import { spacing } from "@mui/system";
import SaveCancel from "../../../components/SaveCancel";
import useVillage from "../../../hooks/useVillage";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import VillageFields from "./components/VillageFields";

const Card = styled(MuiCard)(spacing);

const initialValues = {
  villageName: "",
  taluk: "",
  district: "",
  country: "",
  state: "",
  city: "",
  pincode: "",
  status: "ACTIVE",
};
const validationSchema = Yup.object().shape({
  villageName: Yup.string().required("Required"),
  city: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  taluk: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  district: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  state: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  country: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  pincode: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  status: Yup.string().required("Required"),
});

function BasicForm(props) {
  const dispatch = useDispatch();

  const { addVillage, hasError, errorMessage, isSubmitting } = useVillage();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { t } = props;
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      console.log(data, "submittttt");
      const villageDTO = {
        country: {
          countryId: data.country.value,
        },
        state: {
          stateId: data.state.value,
        },
        district: {
          districtId: data.district.value,
        },
        taluk: {
          talukId: data.taluk.value,
        },
        city: {
          cityId: data.city.value,
        },
        pincode: {
          pincodeId: data.pincode.value,
        },
        status: data.status,
        villageName: data.villageName,
      };
      await addVillage(villageDTO);
      reset();
      dispatch(showSuccessSnackbar("Village added successfully!"));
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
  };
  const cancelBtn = () => {
    navigate("/masters/village/list");
  };

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_village")} subheader={"We can add village here"} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <VillageFields fields={validationSchema.fields} />

                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

function AddVillage() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}

export default AddVillage;
