import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { ComplaintProvider } from "../contexts/ComplaintContext";
import { DialogProvider } from "../contexts/DialogContext";
import { UserProvider } from "../contexts/UserContext";
import { ComplaintTypeProvider } from "../contexts/ComplaintTypeContext";
import { EmployeeProvider } from "../contexts/EmployeeContext";

export const ComplaintComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <ComplaintProvider key={0} />,
        <UserProvider key={1} />,
        <ComplaintTypeProvider key={2} />,
        <EmployeeProvider key={3} />,

        <DialogProvider key={4} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
