import { useContext } from "react";

import { DistrictContext } from "../contexts/DistrictContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useDistrict = () => {
  const context = useContext(DistrictContext);

  if (!context) throw new Error("DistrictContext must be placed within DistrictProvider");

  return context;
};

export default useDistrict;
