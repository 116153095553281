import { SmsTemplateConstants } from "../constants/smstemplate.constants";

const SmsTemplateReducer = (state, action) => {
  switch (action.type) {
    case SmsTemplateConstants.FETCH_SMSTEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsTemplateConstants.FETCH_SMSTEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsTemplates: action.payload.data,
        paging: action.payload.paging,
      };
    case SmsTemplateConstants.FETCH_SMSTEMPLATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsTemplateConstants.SMSTEMPLATE_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsTemplateConstants.SMSTEMPLATE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smstemplateSearchResults: action.payload,
      };
    case SmsTemplateConstants.SMSTEMPLATE_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsTemplateConstants.ADD_SMSTEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsTemplateConstants.ADD_SMSTEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SmsTemplateConstants.ADD_SMSTEMPLATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case SmsTemplateConstants.GET_SMSTEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsTemplateConstants.GET_SMSTEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smstemplate: action.payload,
      };
    case SmsTemplateConstants.GET_SMSTEMPLATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsTemplateConstants.UPDATE_SMSTEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsTemplateConstants.UPDATE_SMSTEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smstemplate: action.payload,
      };
    case SmsTemplateConstants.UPDATE_SMSTEMPLATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case SmsTemplateConstants.DOWNLOAD_SMSTEMPLATE_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case SmsTemplateConstants.DOWNLOAD_SMSTEMPLATE_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case SmsTemplateConstants.DOWNLOAD_SMSTEMPLATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case SmsTemplateConstants.UPLOAD_SMSTEMPLATE_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case SmsTemplateConstants.UPLOAD_SMSTEMPLATE_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case SmsTemplateConstants.UPLOAD_SMSTEMPLATE_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    case SmsTemplateConstants.SMSTEMPLATE_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        autoSearchLoading: true,
        hasError: false,
      };
    case SmsTemplateConstants.SMSTEMPLATE_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        autoSearchLoading: false,
        smsTemplateAutoSearchResults: action.payload,
      };
    case SmsTemplateConstants.SMSTEMPLATE_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        autoSearchLoading: false,
      };

    default:
      return state;
  }
};

export default SmsTemplateReducer;
