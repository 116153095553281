import { MkIpPoolConstants } from "../constants/mkippool.constants";

const MkIpPoolReducer = (state, action) => {
  switch (action.type) {
    case MkIpPoolConstants.FETCH_MK_IP_POOL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case MkIpPoolConstants.FETCH_MK_IP_POOL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mkIpPools: action.payload.data,
        paging: action.payload.paging,
      };
    case MkIpPoolConstants.FETCH_MK_IP_POOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case MkIpPoolConstants.MK_IP_POOL_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case MkIpPoolConstants.MK_IP_POOL_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mkIpPoolSearchResults: action.payload,
      };
    case MkIpPoolConstants.MK_IP_POOL_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case MkIpPoolConstants.MK_IP_POOL_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case MkIpPoolConstants.MK_IP_POOL_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mkIpPoolAutoSearchResults: action.payload,
      };
    case MkIpPoolConstants.MK_IP_POOL_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case MkIpPoolConstants.ADD_MK_IP_POOL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case MkIpPoolConstants.ADD_MK_IP_POOL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case MkIpPoolConstants.ADD_MK_IP_POOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case MkIpPoolConstants.GET_MK_IP_POOL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case MkIpPoolConstants.GET_MK_IP_POOL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mkIpPool: action.payload,
      };
    case MkIpPoolConstants.GET_MK_IP_POOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case MkIpPoolConstants.UPDATE_MK_IP_POOL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case MkIpPoolConstants.UPDATE_MK_IP_POOL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        mkIpPool: action.payload,
      };
    case MkIpPoolConstants.UPDATE_MK_IP_POOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case MkIpPoolConstants.DOWNLOAD_MK_IP_POOL_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case MkIpPoolConstants.DOWNLOAD_MK_IP_POOL_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case MkIpPoolConstants.DOWNLOAD_MK_IP_POOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case MkIpPoolConstants.UPLOAD_MK_IP_POOL_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case MkIpPoolConstants.UPLOAD_MK_IP_POOL_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case MkIpPoolConstants.UPLOAD_MK_IP_POOL_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default MkIpPoolReducer;
