import { useContext } from "react";

import { VillageContext } from "../contexts/VillageContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useVillage = () => {
  const context = useContext(VillageContext);

  if (!context) throw new Error("VillageContext must be placed within VillageProvider");

  return context;
};

export default useVillage;
