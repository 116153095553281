import React from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";

import EditDelete from "../../../../components/EditDelete";
import Toolbar from "../../../../components/Toolbar";
import useWindowDimensions from "../../../../utils/windowDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>C-Panal</Typography>
      <Typography>Department</Typography>
      <Typography>List</Typography>
    </Breadcrumbs>
  );
};

const CustomDataGrid = (props) => {
  const { height, width } = useWindowDimensions();
  const {
    editPage,
    addPage,
    trans,
    data,
    rowCount,
    paging,
    onPageChange,
    isLoading,
    id,
    handleSortModelChange,
    sortModel,
    downloadFile,
    openDownloadDialog,
  } = props;
  console.log(rowCount, "loading child component");

  const columns = [
    {
      field: "action",
      headerName: "Action",
      filter: false,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          console.log(thisRow, "thisRow");
        };
        return <EditDelete rowClick={onClick} editPage={() => editPage(params.id)} />;
      },
    },
    {
      field: "departmentName",
      headerName: "Department Name",
      width: 300,
    },
    {
      field: "mobileNo",
      headerName: "Mobile",
      width: 300,
    },
    {
      field: "emailId",
      headerName: "Mail Id",
      width: 300,
    },
    {
      field: "gstNo",
      headerName: "GST Number",
      width: 300,
    },
    {
      field: "businessType",
      headerName: "Business Type",
      width: 300,
    },
    {
      field: "status",
      headerName: "Status",
      width: 300,
    },
  ];
  return (
    <Card mb={6}>
      <CardHeader
        action={
          <Toolbar
            addPage={addPage}
            downloadFile={downloadFile}
            openDownloadDialog={openDownloadDialog}
          />
        }
        title={trans("department_list")}
        subheader={breadCrump()}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          columns={columns}
          rows={data}
          rowCount={rowCount}
          getRowId={(row) => row[id]}
          loading={isLoading}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </CardContent>
    </Card>
  );
};
CustomDataGrid.propTypes = {
  editPage: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  trans: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  paging: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSortModelChange: PropTypes.func.isRequired,
  sortModel: PropTypes.object.isRequired,
};
export default CustomDataGrid;
