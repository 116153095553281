//Users
import EditComplaint from "../pages/complaint/edit.js";
import ComplaintType from "../pages/complaint_type/index.js";
import AddComplaintType from "../pages/complaint_type/add.js";
import Complaint from "../pages/complaint/index.js";
import AddComplaint from "../pages/complaint/add.js";
import ComplaintStats from "../pages/complaint/complaintstats.js";
import ComplaintClosed from "../pages/complaint/complaint_closed.js";
import ComplaintDetails from "../pages/complaint/complaintdetails.js";
import EditcomplaintType from "../pages/complaint_type/edit.js";
//Composers

import { ComplaintTypeComposer } from "../composers/ComplaintTypeComposer";
import { DashboardComposer } from "../composers/DashboardComposer.js";
import { ComplaintComposer } from "../composers/ComplaintComposer";

// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
// import EditcomplaintType from "../pages/complaint_type/edit.js";

const ComplaintRoutes = {
  path: "complaints",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      children: [
        {
          path: "add",
          element: (
            <ComplaintComposer>
              <AddComplaint />
            </ComplaintComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <ComplaintComposer>
              <EditComplaint />
            </ComplaintComposer>
          ),
        },
        {
          path: "list",
          element: (
            <ComplaintComposer>
              <Complaint />
            </ComplaintComposer>
          ),
        },
        {
          path: "stats",
          element: (
            <ComplaintComposer>
              <ComplaintStats />
            </ComplaintComposer>
          ),
        },
        {
          path: "closed",
          element: (
            <ComplaintComposer>
              <ComplaintClosed />
            </ComplaintComposer>
          ),
        },
        {
          path: "details",
          element: (
            <ComplaintComposer>
              <ComplaintDetails />
            </ComplaintComposer>
          ),
        },
      ],
    },
    {
      path: "type",
      children: [
        {
          path: "add",
          element: (
            <ComplaintTypeComposer>
              <AddComplaintType />
            </ComplaintTypeComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <ComplaintTypeComposer>
              <EditcomplaintType />
            </ComplaintTypeComposer>
          ),
        },
        {
          path: "list",
          element: (
            <ComplaintTypeComposer>
              <ComplaintType />
            </ComplaintTypeComposer>
          ),
        },
      ],
    },
    {
      path: "type",
      children: [
        {
          path: "add",
          element: (
            <ComplaintComposer>
              <AddComplaint />
            </ComplaintComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <ComplaintComposer>
              <EditcomplaintType />
            </ComplaintComposer>
          ),
        },
        {
          path: "list",
          element: (
            <ComplaintComposer>
              <Complaint />
            </ComplaintComposer>
          ),
        },
      ],
    },
  ],
};

export default ComplaintRoutes;
