import { DepartmentConstants } from "../constants/department.constants";

const DepartmentReducer = (state, action) => {
  switch (action.type) {
    case DepartmentConstants.FETCH_DEPARTMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case DepartmentConstants.FETCH_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: action.payload.data,
        paging: action.payload.paging,
      };
    case DepartmentConstants.FETCH_DEPARTMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case DepartmentConstants.DEPARTMENT_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case DepartmentConstants.DEPARTMENT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        departmentSearchResults: action.payload,
      };
    case DepartmentConstants.DEPARTMENT_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case DepartmentConstants.ADD_DEPARTMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case DepartmentConstants.ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DepartmentConstants.ADD_DEPARTMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case DepartmentConstants.GET_DEPARTMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case DepartmentConstants.GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        department: action.payload,
      };
    case DepartmentConstants.GET_DEPARTMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case DepartmentConstants.UPDATE_DEPARTMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case DepartmentConstants.UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        department: action.payload,
      };
    case DepartmentConstants.UPDATE_DEPARTMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case DepartmentConstants.DOWNLOAD_DEPARTMENT_REQUEST:
      return {
        ...state,
        isDownload: true,
        hasError: false,
      };
    case DepartmentConstants.DOWNLOAD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isDownload: false,
      };
    case DepartmentConstants.DOWNLOAD_DEPARTMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isDownload: false,
      };
    case DepartmentConstants.UPLOAD_DEPARTMENT_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case DepartmentConstants.UPLOAD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case DepartmentConstants.UPLOAD_DEPARTMENT_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default DepartmentReducer;
