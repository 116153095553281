import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";

import SaveCancel from "../../../components/SaveCancel";
import usePppServer from "../../../hooks/usePppServer";
import PppServerFields from "./components/PppServerFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  serviceName: Yup.string().required("Required"),
  maxMtu: Yup.string().required("Required"),
  maxMru: Yup.string().required("Required"),
  mrRu: Yup.string().required("Required"),
  keepAliveTimeout: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  nas: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  mkInterface: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  pppProfile: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
});

function EditPppServer() {
  const { getPppServer, pppServer, updatePppServer, isSubmitting } = usePppServer();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Object.keys(pppServer).length !== 0) {
      console.log(pppServer.status, "pppServer.status");
      console.log(pppServer.nas, "naassnass1111111");
      const initialValues = {
        pppServerId: pppServer.pppServerId,
        nas: {
          value: pppServer.nas.nasId,
          label: pppServer.nas.nasName,
        },
        mkInterface: {
          value: pppServer.mkInterface.interfaceId,
          label: pppServer.mkInterface.interfaceName,
        },
        pppProfile: {
          value: pppServer.pppProfile.pppProfileId,
          label: pppServer.pppProfile.name,
        },
        serviceName: pppServer.serviceName,
        maxMtu: pppServer.maxMtu,
        maxMru: pppServer.maxMru,
        mrRu: pppServer.mrRu,
        keepAliveTimeout: pppServer.keepAliveTimeout,
        pap: pppServer.pap,
        status: pppServer.status,
      };
      reset(initialValues);
    }
  }, [pppServer]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const { state } = useLocation();
  console.log(state, "location");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      (data["nas"] = {
        nasId: data.nas.value,
        nasName: data.nas.label,
      }),
        (data["mkInterface"] = {
          interfaceName: data.mkInterface.label,
          interfaceId: data.mkInterface.value,
        }),
        (data["pppProfile"] = {
          name: data.pppProfile.label,
          pppProfileId: data.pppProfile.value,
        });
      await updatePppServer(data);
      navigate("/mikrotik-mgnt/ppp-server/list");
      reset();
      dispatch(showSuccessSnackbar("PppServer update Successfully!"));
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to update PppServer"));
    }
  };

  const cancelBtn = () => {
    navigate("mikrotik-mgnt/addresses/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Mikrotik Access Mgnt</Typography>
        <Typography>PppServer</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getPppServer(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_pppServer")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                PppServer updated successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <PppServerFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditPppServer;
