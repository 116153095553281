import { useContext } from "react";

import { DepartmentContext } from "../contexts/DepartmentContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useDepartment = () => {
  const context = useContext(DepartmentContext);

  if (!context) throw new Error("DepartmentContext must be placed within DepartmentProvider");

  return context;
};

export default useDepartment;
