export const PaidInvoiceConstants = {
  FETCH_PAIDINVOICE_REQUEST: "FETCH_PAIDINVOICE_REQUEST",
  FETCH_PAIDINVOICE_SUCCESS: "FETCH_PAIDINVOICE_SUCCESS",
  FETCH_PAIDINVOICE_FAILURE: "FETCH_PAIDINVOICE_FAILURE",
  ADD_PAIDINVOICE_REQUEST: "ADD_PAIDINVOICE_REQUEST",
  ADD_PAIDINVOICE_SUCCESS: "ADD_PAIDINVOICE_SUCCESS",
  ADD_PAIDINVOICE_FAILURE: "ADD_PAIDINVOICE_FAILURE",
  PAIDINVOICE_SEARCH_REQUEST: "PAIDINVOICE_SEARCH_REQUEST",
  PAIDINVOICE_SEARCH_SUCCESS: "PAIDINVOICE_SEARCH_SUCCESS",
  PAIDINVOICE_SEARCH_FAILURE: "PAIDINVOICE_SEARCH_FAILURE",
  PAIDINVOICE_AUTO_SEARCH_REQUEST: "PAIDINVOICE_AUTO_SEARCH_REQUEST",
  PAIDINVOICE_AUTO_SEARCH_SUCCESS: "PAIDINVOICE_AUTO_SEARCH_SUCCESS",
  PAIDINVOICE_AUTO_SEARCH_FAILURE: "PAIDINVOICE_AUTO_SEARCH_FAILURE",
  GET_PAIDINVOICE_REQUEST: "GET_PAIDINVOICE_REQUEST",
  GET_PAIDINVOICE_SUCCESS: "GET_PAIDINVOICE_SUCCESS",
  GET_PAIDINVOICE_FAILURE: "GET_PAIDINVOICE_FAILURE",
  UPDATE_PAIDINVOICE_REQUEST: "UPDATE_PAIDINVOICE_REQUEST",
  UPDATE_PAIDINVOICE_SUCCESS: "UPDATE_PAIDINVOICE_SUCCESS",
  UPDATE_PAIDINVOICE_FAILURE: "UPDATE_PAIDINVOICE_FAILURE",
  DOWNLOAD_PAIDINVOICE_REQUEST: "DOWNLOAD_PAIDINVOICE_REQUEST",
  DOWNLOAD_PAIDINVOICE_SUCCESS: "DOWNLOAD_PAIDINVOICE_SUCCESS",
  DOWNLOAD_PAIDINVOICE_FAILURE: "DOWNLOAD_PAIDINVOICE_FAILURE",
  UPLOAD_PAIDINVOICE_REQUEST: "UPLOAD_PAIDINVOICE_REQUEST",
  UPLOAD_PAIDINVOICE_SUCCESS: "UPLOAD_PAIDINVOICE_SUCCESS",
  UPLOAD_PAIDINVOICE_FAILURE: "UPLOAD_PAIDINVOICE_FAILURE",
};
