import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import IspReducer from "../reducers/IspReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { IspConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  isps: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  ispSearchResults: [],
  isp: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  ispPlans: [],
  ispIppools: [],
  isLoading: true,
  ispAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  isLoadingAutoSearch: false,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const IspContext = createContext({
  ...initialState,
  getAllIsp: () => {},
  addIsp: () => {},
  searchIsp: () => {},
  getIsp: () => {},
  updateIsp: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  addIspPolicy: () => {},
  addIspIppool: () => {},
  getIspPlans: () => {},
  getIspIppools: () => {},
});

function IspProvider({ children }) {
  const [state, dispatch] = useReducer(IspReducer, initialState);

  const getAllIsp = async (payload) => {
    try {
      const response = await axios.post("/hyper/isp/list", payload);
      dispatch({
        type: IspConstants.FETCH_ISP_REQUEST,
      });
      dispatch({
        type: IspConstants.FETCH_ISP_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: IspConstants.FETCH_ISP_FAILURE,
      });
    }
  };
  const searchIsp = async () => {
    try {
      const response = await axios.post("/hyper/isp/search", initPayload);
      dispatch({
        type: IspConstants.ISP_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: IspConstants.ISP_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: IspConstants.ISP_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchIsp = async (payload) => {
    try {
      const response = await axios.post("/hyper/isp/auto-search", payload);
      dispatch({
        type: IspConstants.ISP_AUTO_SEARCH_REQUEST,
      });

      dispatch({
        type: IspConstants.ISP_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: IspConstants.ISP_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addIsp = async (payload) => {
    const response = await axios.post("/hyper/isp/add", payload);
    dispatch({
      type: IspConstants.ADD_ISP_REQUEST,
    });
    dispatch({
      type: IspConstants.ADD_ISP_SUCCESS,
      payload: response.data,
    });
  };

  const getIsp = async (id) => {
    const response = await axios.get("/hyper/isp/" + id);
    dispatch({
      type: IspConstants.GET_ISP_REQUEST,
    });
    dispatch({
      type: IspConstants.GET_ISP_SUCCESS,
      payload: response.data,
    });
  };

  const updateIsp = async (payload) => {
    const response = await axios.put("/hyper/isp/update/" + payload.ispId, payload);
    dispatch({
      type: IspConstants.UPDATE_ISP_REQUEST,
    });
    dispatch({
      type: IspConstants.UPDATE_ISP_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    const response = await axios.post("/hyper/isp/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "isp." + fileType);
    dispatch({
      type: IspConstants.DOWNLOAD_ISP_REQUEST,
    });
    dispatch({
      type: IspConstants.DOWNLOAD_ISP_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/hyper/isp/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_isp." + fileType);
    dispatch({
      type: IspConstants.DOWNLOAD_ISP_REQUEST,
    });
    dispatch({
      type: IspConstants.DOWNLOAD_ISP_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: IspConstants.UPLOAD_ISP_REQUEST,
      });
      const response = await axios({
        url: "/hyper/isp/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: IspConstants.UPLOAD_ISP_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: IspConstants.UPLOAD_ISP_FAILURE,
      });
    }
  };

  const addIspPolicy = async (payload) => {
    const response = await axios.post("/hyper/isp/plan/add", payload);
    dispatch({
      type: IspConstants.ADD_ISP_POLICY_REQUEST,
    });
    dispatch({
      type: IspConstants.ADD_ISP_POLICY_SUCCESS,
      payload: response.data,
    });
  };

  const addIspIppool = async (payload) => {
    const response = await axios.post("/hyper/isp/ippool/add", payload);
    dispatch({
      type: IspConstants.ADD_ISP_IPPOOL_REQUEST,
    });
    dispatch({
      type: IspConstants.ADD_ISP_IPPOOL_SUCCESS,
      payload: response.data,
    });
  };

  const getIspPlans = async (payload) => {
    try {
      const response = await axios.post("/hyper/isp/plan/list", payload);
      dispatch({
        type: IspConstants.FETCH_ISP_POLICY_REQUEST,
      });
      dispatch({
        type: IspConstants.FETCH_ISP_POLICY_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: IspConstants.FETCH_ISP_POLICY_FAILURE,
      });
    }
  };

  const getIspIppools = async (payload) => {
    try {
      const response = await axios.post("/hyper/isp/ippool/list", payload);
      dispatch({
        type: IspConstants.FETCH_ISP_IPPOOL_REQUEST,
      });
      dispatch({
        type: IspConstants.FETCH_ISP_IPPOOL_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: IspConstants.FETCH_ISP_IPPOOL_FAILURE,
      });
    }
  };

  return (
    <IspContext.Provider
      value={{
        ...state,
        getAllIsp,
        addIsp,
        searchIsp,
        getIsp,
        updateIsp,
        downloadFile,
        sampleFile,
        uploadFile,
        addIspPolicy,
        getIspPlans,
        getIspIppools,
        addIspIppool,
        autoSearchIsp,
      }}
    >
      {children}
    </IspContext.Provider>
  );
}

export { IspContext, IspProvider };
