export const EmailMasterConstants = {
  FETCH_EMAILMASTER_REQUEST: "FETCH_EMAILMASTER_REQUEST",
  FETCH_EMAILMASTER_SUCCESS: "FETCH_EMAILMASTER_SUCCESS",
  FETCH_EMAILMASTER_FAILURE: "FETCH_EMAILMASTER_FAILURE",
  ADD_EMAILMASTER_REQUEST: "ADD_EMAILMASTER_REQUEST",
  ADD_EMAILMASTER_SUCCESS: "ADD_EMAILMASTER_SUCCESS",
  ADD_EMAILMASTER_FAILURE: "ADD_EMAILMASTER_FAILURE",
  EMAILMASTER_SEARCH_REQUEST: "EMAILMASTER_SEARCH_REQUEST",
  EMAILMASTER_SEARCH_SUCCESS: "EMAILMASTER_SEARCH_SUCCESS",
  EMAILMASTER_SEARCH_FAILURE: "EMAILMASTER_SEARCH_FAILURE",
  EMAILMASTER_AUTO_SEARCH_REQUEST: "EMAILMASTER_AUTO_SEARCH_REQUEST",
  EMAILMASTER_AUTO_SEARCH_SUCCESS: "EMAILMASTER_AUTO_SEARCH_SUCCESS",
  EMAILMASTER_AUTO_SEARCH_FAILURE: "EMAILMASTER_AUTO_SEARCH_FAILURE",
  GET_EMAILMASTER_REQUEST: "GET_EMAILMASTER_REQUEST",
  GET_EMAILMASTER_SUCCESS: "GET_EMAILMASTER_SUCCESS",
  GET_EMAILMASTER_FAILURE: "GET_EMAILMASTER_FAILURE",
  UPDATE_EMAILMASTER_REQUEST: "UPDATE_EMAILMASTER_REQUEST",
  UPDATE_EMAILMASTER_SUCCESS: "UPDATE_EMAILMASTER_SUCCESS",
  UPDATE_EMAILMASTER_FAILURE: "UPDATE_EMAILMASTER_FAILURE",
  DOWNLOAD_EMAILMASTER_REQUEST: "DOWNLOAD_EMAILMASTER_REQUEST",
  DOWNLOAD_EMAILMASTER_SUCCESS: "DOWNLOAD_EMAILMASTER_SUCCESS",
  DOWNLOAD_EMAILMASTER_FAILURE: "DOWNLOAD_EMAILMASTER_FAILURE",
  UPLOAD_EMAILMASTER_REQUEST: "UPLOAD_EMAILMASTER_REQUEST",
  UPLOAD_EMAILMASTER_SUCCESS: "UPLOAD_EMAILMASTER_SUCCESS",
  UPLOAD_EMAILMASTER_FAILURE: "UPLOAD_EMAILMASTER_FAILURE",
};
