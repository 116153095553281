import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import EmailToUserReducer from "../reducers/EmailToUserReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { EmailToUserConstants } from "../constants/emailtouser.constants";
import { saveFile } from "../utils/common";
const initialState = {
  emailToUsers: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  emailToUserSearchResults: [],
  emailToUser: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,

  emailToUserAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const EmailToUserContext = createContext({
  ...initialState,
  getAllEmailToUser: () => {},
  addEmailToUser: () => {},

  downloadFile: () => {},
});

function EmailToUserProvider({ children }) {
  const [state, dispatch] = useReducer(EmailToUserReducer, initialState);

  const getAllEmailToUser = async (payload) => {
    try {
      dispatch({
        type: EmailToUserConstants.FETCH_EMAILTOUSER_REQUEST,
      });
      const response = await axios.post("/radius/raduser/with-tenants", payload);
      dispatch({
        type: EmailToUserConstants.FETCH_EMAILTOUSER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: EmailToUserConstants.FETCH_EMAILTOUSER_FAILURE,
      });
    }
  };

  const addEmailToUser = async (payload) => {
    const response = await axios.post("radius/raduser/send-email", payload);
    dispatch({
      type: EmailToUserConstants.ADD_EMAILTOUSER_REQUEST,
    });
    dispatch({
      type: EmailToUserConstants.ADD_EMAILTOUSER_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: EmailToUserConstants.DOWNLOAD_EMAILTOUSER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/radius/raduser/send-email" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "tenant." + fileType);

    dispatch({
      type: EmailToUserConstants.DOWNLOAD_EMAILTOUSER_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <EmailToUserContext.Provider
      value={{
        ...state,
        getAllEmailToUser,
        addEmailToUser,
        downloadFile,
      }}
    >
      {children}
    </EmailToUserContext.Provider>
  );
}

export { EmailToUserContext, EmailToUserProvider };
