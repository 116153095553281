import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../../components/CustomTextField";
import CustomCheckBox from "../../../../components/CustomCheckBox";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";

const AddonDetail = (props) => {
  const { validationFields } = props;
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item md={6}>
        <CustomTextField
          label="Addon Name"
          name="addOnName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Total Limit"
          name="totalLimit"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Price"
          name="price"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>

      <Grid item md={6}>
        <CustomCheckBox
          label="Tax Enabled"
          name="taxEnabled"
          type="checkbox"
          my={2}
          control={control}
          fullWidth
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          id="taxType"
          name="taxType"
          label="Tax Type"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"INCLUDING_TAX"}>Including Tax</MenuItem>
          <MenuItem value={"EXCLUDING_TAX"}>Excluding Tax</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          id="status"
          name="status"
          label="Status"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"ACTIVE"}>Active</MenuItem>
          <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          type={"text"}
          id="notes"
          name="notes"
          label="Notes"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
    </Grid>
  );
};

export default AddonDetail;
