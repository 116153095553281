import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import MaterialReducer from "../reducers/MaterialReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { MaterialConstants } from "../constants/material.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  materials: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  materialSearchResults: [],
  material: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  materialAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const MaterialContext = createContext({
  ...initialState,
  getAllMaterial: () => {},
  addMaterial: () => {},
  searchMaterial: () => {},
  autoSearchMaterial: () => {},
  getMaterial: () => {},
  updateMaterial: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function MaterialProvider({ children }) {
  const [state, dispatch] = useReducer(MaterialReducer, initialState);

  const getAllMaterial = async (payload) => {
    try {
      dispatch({
        type: MaterialConstants.FETCH_MATERIAL_REQUEST,
      });
      const response = await axios.post("/radius/raduser/material/list", payload);
      dispatch({
        type: MaterialConstants.FETCH_MATERIAL_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: MaterialConstants.FETCH_MATERIAL_FAILURE,
      });
    }
  };
  const searchMaterial = async (payload) => {
    try {
      const response = await axios.post("/master/material/search", payload);
      dispatch({
        type: MaterialConstants.MATERIAL_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: MaterialConstants.MATERIAL_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: MaterialConstants.MATERIAL_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchMaterial = async (payload) => {
    try {
      const response = await axios.post("/master/material/auto-search", payload);
      dispatch({
        type: MaterialConstants.MATERIAL_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: MaterialConstants.MATERIAL_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: MaterialConstants.MATERIAL_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addMaterial = async (payload) => {
    const response = await axios.post("/radius/raduser/material/add", payload);
    dispatch({
      type: MaterialConstants.ADD_MATERIAL_REQUEST,
    });
    dispatch({
      type: MaterialConstants.ADD_MATERIAL_SUCCESS,
      payload: response.data,
    });
  };

  const getMaterial = async (id) => {
    const response = await axios.get("/radius/raduser/material" + id);
    dispatch({
      type: MaterialConstants.GET_MATERIAL_REQUEST,
    });
    dispatch({
      type: MaterialConstants.GET_MATERIAL_SUCCESS,
      payload: response.data,
    });
  };

  const updateMaterial = async (payload) => {
    const response = await axios.put("/master/material/update/" + payload.materialId, payload);
    dispatch({
      type: MaterialConstants.UPDATE_MATERIAL_REQUEST,
    });
    dispatch({
      type: MaterialConstants.UPDATE_MATERIAL_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: MaterialConstants.DOWNLOAD_MATERIAL_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/master/material/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "material." + fileType);
    dispatch({
      type: MaterialConstants.DOWNLOAD_MATERIAL_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/master/material/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_material." + fileType);
    dispatch({
      type: MaterialConstants.DOWNLOAD_MATERIAL_REQUEST,
    });
    dispatch({
      type: MaterialConstants.DOWNLOAD_MATERIAL_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: MaterialConstants.UPLOAD_MATERIAL_REQUEST,
      });
      const response = await axios({
        url: "/master/material/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: MaterialConstants.UPLOAD_MATERIAL_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: MaterialConstants.UPLOAD_MATERIAL_FAILURE,
      });
    }
  };

  return (
    <MaterialContext.Provider
      value={{
        ...state,
        getAllMaterial,
        addMaterial,
        searchMaterial,
        autoSearchMaterial,
        getMaterial,
        updateMaterial,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </MaterialContext.Provider>
  );
}

export { MaterialContext, MaterialProvider };
