import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Box,
  CardContent,
  MenuItem,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import SaveCancel from "../../../components/SaveCancel";
import CustomTextField from "../../../components/CustomTextField";
import ReactHookFormSelect from "../../../components/ReactHookFormSelect";
import { useDialog } from "../../../contexts/DialogContext";
import useUser from "../../../hooks/useUser";
import SearchableAutoComplete from "../../../components/SearchableAutoComplete";
import useTenant from "../../../hooks/useTenant";
import useRate from "../../../hooks/useRate";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import useEmployee from "../../../hooks/useEmployee";
import useLead from "../../../hooks/useLead";

const validationSchema = Yup.object().shape({
  employee: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  status: Yup.string().required("Required"),
  comments: Yup.string().required("Required"),
});

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const AssignUser = (props) => {
  const { t } = useTranslation();
  const { unSetDialog } = useDialog();
  const { getLead, lead, assignLead, isSubmitting } = useLead();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [employeePayload, setEmployeePayload] = useState(currentPayload);
  const { autoSearchEmployee, employeeAutoSearchResults } = useEmployee();

  const { getUser, user, isRenewSubmitting, renewUser } = useUser();
  const { autoSearchRate, rateAutoSearchResults, isLoadingAutoSearch: rateLoading } = useRate();
  const { leadId, fetchData } = props;

  React.useEffect(() => {
    if (Object.keys(lead).length !== 0) {
      console.log(lead.status, "lead.status");
      const initialValues = {
        leadId: lead.leadId,
        employee: {
          label: lead.employee.employeeName,
          value: lead.employee.employeeId,
        },
        status: lead.status,
      };
      reset(initialValues);
    }
  }, [lead]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { state } = useLocation();
  console.log(state, "location");

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;
  const [employeeOptions, setEmployeeOptions] = useState([]);

  const onSubmit = async (data) => {
    try {
      data["employee"] = {
        employeeId: data.employee.value,
      };
      await assignLead(data);

      dispatch(showSuccessSnackbar("Assigned successfully!"));
      unSetDialog("assign");
      fetchData();
    } catch (err) {
      console.log(err, "ddddddddddddd");
      dispatch(showErrorSnackbar("Failed to assign "));
    }
  };

  const cancelBtn = () => {
    navigate("/leads/list");
  };

  // employee

  const fetchEmployee = async () => {
    await autoSearchEmployee(employeePayload);
  };
  useEffect(() => {
    fetchEmployee();
  }, [employeePayload]);

  const fetchLead = async () => {
    await getLead(leadId);
  };

  useEffect(() => {
    fetchLead();
  }, []);

  useEffect(() => {
    console.log(employeeAutoSearchResults, "employeeAutoSearchResults");
    if (employeeAutoSearchResults && employeeAutoSearchResults.data.length !== 0) {
      const results = [...employeeOptions, ...employeeAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setEmployeeOptions(filteredResults);
    }
  }, [employeeAutoSearchResults]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <SearchableAutoComplete
                label={t("assign_to_employee")}
                name="employee"
                data={employeeOptions}
                control={control}
                payload={employeePayload}
                setPayload={setEmployeePayload}
                loading={false}
                fullWidth
                variant="outlined"
                errors={errors}
                multiple={false}
                defaultValue={""}
                hasNextPage={false}
                fields={validationSchema.fields}
              />
            </Grid>
            <Grid item md={6}>
              <ReactHookFormSelect
                name="status"
                label={t("status")}
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
                fields={validationSchema.fields}
              >
                <MenuItem value={"OPEN"}>Open</MenuItem>
                <MenuItem value={"CONFIRMED"}>Confirmed</MenuItem>
                <MenuItem value={"INTERESTED"}>Interested</MenuItem>
                <MenuItem value={"NOT_INTERESTED"}>Not Interested</MenuItem>
                <MenuItem value={"CLOSED"}>Closed</MenuItem>
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                label="Comments"
                name="comments"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
                rows={6}
                multiline={true}
                fields={validationSchema.fields}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <CardContent>
              {isSubmitting ? (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              )}
            </CardContent>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default AssignUser;
