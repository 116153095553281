import * as React from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";

import { IconButton as MuiIconButton, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import useAuth from "../../hooks/useAuth";
import { useDialog } from "../../contexts/DialogContext";
import GlobalCreateModal from "./GlobalCreateModal";
import { getTimeStamp } from "../../utils/common";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 50px;
    height: 50px;
  }
`;

function GlobalCreate() {
  const { openDialog } = useDialog();

  const openGlobalCreate = () => {
    return openDialog(
      "global_creation",
      "Global Creation",
      <GlobalCreateModal key={getTimeStamp()} />,
    );
  };

  return (
    <React.Fragment>
      <Tooltip title="Global Create">
        <IconButton
          aria-haspopup="true"
          onClick={openGlobalCreate}
          color="inherit"
          sx={{ width: "20px", height: "20px", padding: "16px" }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default GlobalCreate;
