import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { BalanceStatementProvider } from "../contexts/BalanceStatementContext";
import { DialogProvider } from "../contexts/DialogContext";

export const BalanceStatementComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<BalanceStatementProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};
