import { useContext } from "react";

import { AuthenticationLogContext } from "../contexts/AuthenticationLogContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useAuthenticationLog = () => {
  const context = useContext(AuthenticationLogContext);

  if (!context)
    throw new Error("AuthenticationLogContext must be placed within AuthenticationLogProvider");

  return context;
};

export default useAuthenticationLog;
