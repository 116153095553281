import React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";

import CustomTextField from "../../../../components/CustomTextField";
import MappedTenant from "./MappedTenant";
import MappingToolBar from "../../../../components/MappingToolbar";
import MapTenantModal from "./MapTenantModal";
import { useDialog } from "../../../../contexts/DialogContext";
import { getTimeStamp } from "../../../../utils/common";

const AllowedTenants = (props) => {
  const { openDialog } = useDialog();

  const { addOnId } = props;

  const openDownloadDialog = () => {
    return openDialog(
      "mapping_tenant",
      "Mapping Tenant",
      <MapTenantModal addOnId={addOnId} key={getTimeStamp()} />,
    );
  };
  return (
    <Card mb={6}>
      <CardHeader action={<MappingToolBar openDialog={openDownloadDialog} />} />
      <CardContent>
        <MappedTenant addOnId={addOnId} />
      </CardContent>
    </Card>
  );
};

export default AllowedTenants;
