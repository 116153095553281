export const SmsToTenantConstants = {
  FETCH_SMSTOTENANT_REQUEST: "FETCH_SMSTOTENANT_REQUEST",
  FETCH_SMSTOTENANT_SUCCESS: "FETCH_SMSTOTENANT_SUCCESS",
  FETCH_SMSTOTENANT_FAILURE: "FETCH_SMSTOTENANT_FAILURE",
  ADD_SMSTOTENANT_REQUEST: "ADD_SMSTOTENANT_REQUEST",
  ADD_SMSTOTENANT_SUCCESS: "ADD_SMSTOTENANT_SUCCESS",
  ADD_SMSTOTENANT_FAILURE: "ADD_SMSTOTENANT_FAILURE",
  SMSTOTENANT_SEARCH_REQUEST: "SMSTOTENANT_SEARCH_REQUEST",
  SMSTOTENANT_SEARCH_SUCCESS: "SMSTOTENANT_SEARCH_SUCCESS",
  SMSTOTENANT_SEARCH_FAILURE: "SMSTOTENANT_SEARCH_FAILURE",
  SMSTOTENANT_AUTO_SEARCH_REQUEST: "SMSTOTENANT_AUTO_SEARCH_REQUEST",
  SMSTOTENANT_AUTO_SEARCH_SUCCESS: "SMSTOTENANT_AUTO_SEARCH_SUCCESS",
  SMSTOTENANT_AUTO_SEARCH_FAILURE: "SMSTOTENANT_AUTO_SEARCH_FAILURE",
  GET_SMSTOTENANT_REQUEST: "GET_SMSTOTENANT_REQUEST",
  GET_SMSTOTENANT_SUCCESS: "GET_SMSTOTENANT_SUCCESS",
  GET_SMSTOTENANT_FAILURE: "GET_SMSTOTENANT_FAILURE",
  UPDATE_SMSTOTENANT_REQUEST: "UPDATE_SMSTOTENANT_REQUEST",
  UPDATE_SMSTOTENANT_SUCCESS: "UPDATE_SMSTOTENANT_SUCCESS",
  UPDATE_SMSTOTENANT_FAILURE: "UPDATE_SMSTOTENANT_FAILURE",
  DOWNLOAD_SMSTOTENANT_REQUEST: "DOWNLOAD_SMSTOTENANT_REQUEST",
  DOWNLOAD_SMSTOTENANT_SUCCESS: "DOWNLOAD_SMSTOTENANT_SUCCESS",
  DOWNLOAD_SMSTOTENANT_FAILURE: "DOWNLOAD_SMSTOTENANT_FAILURE",
  UPLOAD_SMSTOTENANT_REQUEST: "UPLOAD_SMSTOTENANT_REQUEST",
  UPLOAD_SMSTOTENANT_SUCCESS: "UPLOAD_SMSTOTENANT_SUCCESS",
  UPLOAD_SMSTOTENANT_FAILURE: "UPLOAD_SMSTOTENANT_FAILURE",
};
