export const IppoolConstants = {
  FETCH_IPPOOL_REQUEST: "FETCH_IPPOOL_REQUEST",
  FETCH_IPPOOL_SUCCESS: "FETCH_IPPOOL_SUCCESS",
  FETCH_IPPOOL_FAILURE: "FETCH_IPPOOL_FAILURE",
  ADD_IPPOOL_REQUEST: "ADD_IPPOOL_REQUEST",
  ADD_IPPOOL_SUCCESS: "ADD_IPPOOL_SUCCESS",
  ADD_IPPOOL_FAILURE: "ADD_IPPOOL_FAILURE",
  IPPOOL_SEARCH_REQUEST: "IPPOOL_SEARCH_REQUEST",
  IPPOOL_SEARCH_SUCCESS: "IPPOOL_SEARCH_SUCCESS",
  IPPOOL_SEARCH_FAILURE: "IPPOOL_SEARCH_FAILURE",
  GET_IPPOOL_REQUEST: "GET_IPPOOL_REQUEST",
  GET_IPPOOL_SUCCESS: "GET_IPPOOL_SUCCESS",
  GET_IPPOOL_FAILURE: "GET_IPPOOL_FAILURE",
  UPDATE_IPPOOL_REQUEST: "UPDATE_IPPOOL_REQUEST",
  UPDATE_IPPOOL_SUCCESS: "UPDATE_IPPOOL_SUCCESS",
  UPDATE_IPPOOL_FAILURE: "UPDATE_IPPOOL_FAILURE",
  DOWNLOAD_IPPOOL_REQUEST: "DOWNLOAD_IPPOOL_REQUEST",
  DOWNLOAD_IPPOOL_SUCCESS: "DOWNLOAD_IPPOOL_SUCCESS",
  DOWNLOAD_IPPOOL_FAILURE: "DOWNLOAD_IPPOOL_FAILURE",
  UPLOAD_IPPOOL_REQUEST: "UPLOAD_IPPOOL_REQUEST",
  UPLOAD_IPPOOL_SUCCESS: "UPLOAD_IPPOOL_SUCCESS",
  UPLOAD_IPPOOL_FAILURE: "UPLOAD_IPPOOL_FAILURE",
  IPPOOL_AUTO_SEARCH_REQUEST: "IPPOOL_AUTO_SEARCH_REQUEST",
  IPPOOL_AUTO_SEARCH_SUCCESS: "IPPOOL_AUTO_SEARCH_SUCCESS",
  IPPOOL_AUTO_SEARCH_FAILURE: "IPPOOL_AUTO_SEARCH_FAILURE",
};
