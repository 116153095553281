import { EmailToTenantConstants } from "../constants/emailtotenant.constants";

const EmailToTenantsReducer = (state, action) => {
  switch (action.type) {
    case EmailToTenantConstants.FETCH_EMAILTOTENANT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmailToTenantConstants.FETCH_EMAILTOTENANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emailToTenants: action.payload.data,
        paging: action.payload.paging,
      };
    case EmailToTenantConstants.FETCH_EMAILTOTENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmailToTenantConstants.EMAILTOTENANT_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmailToTenantConstants.EMAILTOTENANT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emailToTenantSearchResults: action.payload,
      };
    case EmailToTenantConstants.EMAILTOTENANT_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmailToTenantConstants.EMAILTOTENANT_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmailToTenantConstants.EMAILTOTENANT_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emailToTenantAutoSearchResults: action.payload,
      };
    case EmailToTenantConstants.EMAILTOTENANT_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmailToTenantConstants.GET_EMAILTOTENANT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmailToTenantConstants.GET_EMAILTOTENANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emailToTenant: action.payload,
      };
    case EmailToTenantConstants.GET_EMAILTOTENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmailToTenantConstants.DOWNLOAD_EMAILTOTENANT_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case EmailToTenantConstants.DOWNLOAD_EMAILTOTENANT_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case EmailToTenantConstants.DOWNLOAD_EMAILTOTENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    default:
      return state;
  }
};

export default EmailToTenantsReducer;
