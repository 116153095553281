import { NasConstants } from "../constants/nas.constants";

const NasReducer = (state, action) => {
  switch (action.type) {
    case NasConstants.FETCH_NAS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case NasConstants.FETCH_NAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        nases: action.payload.data,
        paging: action.payload.paging,
      };
    case NasConstants.FETCH_NAS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case NasConstants.NAS_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case NasConstants.NAS_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        nasSearchResults: action.payload,
      };
    case NasConstants.NAS_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case NasConstants.ADD_NAS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case NasConstants.ADD_NAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case NasConstants.ADD_NAS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case NasConstants.GET_NAS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case NasConstants.GET_NAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        nas: action.payload,
      };
    case NasConstants.GET_NAS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case NasConstants.UPDATE_NAS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case NasConstants.UPDATE_NAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        nas: action.payload,
      };
    case NasConstants.UPDATE_NAS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case NasConstants.DOWNLOAD_NAS_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case NasConstants.DOWNLOAD_NAS_SUCCESS:
      return {
        ...state,
        isLoadingDownload: true,
        downloadFileType: "",
      };
    case NasConstants.DOWNLOAD_NAS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case NasConstants.UPLOAD_NAS_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case NasConstants.UPLOAD_NAS_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case NasConstants.UPLOAD_NAS_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };
    case NasConstants.NAS_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoadingAutoSearch: true,
        hasError: false,
      };
    case NasConstants.NAS_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoadingAutoSearch: false,
        nasAutoSearchResults: action.payload,
      };
    case NasConstants.NAS_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingAutoSearch: false,
      };

    default:
      return state;
  }
};

export default NasReducer;
