import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import useStateMaster from "../../../hooks/useStateMaster";
import useCountry from "../../../hooks/useCountry";
import Select from "@mui/material/Select";
import CountryDropDown from "../../components/CountryDropDown";
import StateFields from "./components/StateFields";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";

import EditCancel from "../../../components/EditCancel";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  stateName: Yup.string().required("Required"),
  country: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  status: Yup.string().required("Required"),
});

function BasicForm(props) {
  const dispatch = useDispatch();

  const { getState, statemaster, updateState, isSubmitting } = useStateMaster();

  const { refreshPage } = props;

  const { state } = useLocation();
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const fetchData = async () => {
    await getState(state.id);
  };

  console.log(errors, "errorserrors");

  React.useEffect(() => {
    if (Object.keys(statemaster).length !== 0) {
      const initialValues = {
        stateId: statemaster.stateId,
        stateName: statemaster.stateName,
        country: {
          value: statemaster.country?.countryId,
          label: statemaster.country?.countryName,
        },
        status: statemaster.status,
      };
      console.log(initialValues, "statemasterstatemaster");
      reset(initialValues);
    }
  }, [statemaster]);

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    try {
      const stateDto = {
        stateId: data.stateId,
        stateName: data.stateName,
        status: data.status,
        country: {
          countryId: data.country.value,
        },
      };
      console.log(stateDto, "stateDtostateDto");
      await updateState(stateDto);
      navigate("/masters/state/list");
      reset();
      dispatch(showSuccessSnackbar("State updated successfully!"));
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
  };
  const cancelBtn = () => {
    navigate("/masters/state/list");
  };

  useEffect(() => {
    fetchData();
  }, []);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_state")} subheader={"We can edit state here"} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <StateFields fields={validationSchema.fields} />

                <EditCancel cancelBtn={cancelBtn} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

function EditStateMaster() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}

export default EditStateMaster;
