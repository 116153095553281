import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import useCity from "../../../../hooks/useCity";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import FilterReset from "../../../../components/FilterReset";
import { searchPayload, convertedValue } from "../../../../utils/common";
import { OperatorConstants } from "../../../../constants/operator.constants";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import CustomTextField from "../../../../components/CustomTextField";
import { useDialog } from "../../../../contexts/DialogContext";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const statusOptions = [
  {
    label: "Active",
    value: "A",
  },
  {
    label: "InActive",
    value: "INA",
  },
];

function AdvanceFilter(props) {
  const { unSetDialog } = useDialog();
  const initialValues = {
    cityNameOperator: "eq",
    talukNameOperator: "eq",
    cityName: [],
    talukName: "",
  };

  const { fields, payload, setPayload } = props;

  const { autoSearchCity, cityAutoSearchResults, isLoadingAutoSearch } = useCity();

  const [cityPayload, setCityPayload] = useState(currentPayload);
  const [cityOptions, setCityOptions] = useState([]);
  const [cityMultiValue, setCityMultiValue] = useState(false);
  const [statusMultiValue, setStatusMultiValue] = useState(false);
  const [defaultCity, setDefaultCity] = useState([]);

  const [defaultStatus, setDefaultStatus] = useState([]);

  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = methods;

  const cityOpValue = watch("cityNameOperator");

  const onSubmit = async (data) => {
    try {
      let filters = [];

      if (data.cityName != null && Object.keys(data.cityName).length != 0) {
        filters.push({
          fieldName: "cityId",
          fieldValue: convertedValue(data.cityName, "value").join(","),
          op: data.cityNameOperator,
          dataType: "int",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.talukName != "" && data.talukName != null) {
        filters.push({
          fieldName: "talukName",
          fieldValue: data.talukName,
          op: data.talukNameOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }

      setPayload({
        ...payload,
        filters: filters,
      });
      unSetDialog("advance_filter");
    } catch (err) {
      console.log(err, "errerrerrerr");
    }
  };

  useEffect(() => {
    if (cityOpValue == "in" || cityOpValue == "nin") {
      setDefaultCity([]);
      setValue("cityName", []);
      setCityMultiValue(true);
    } else {
      setDefaultCity({});
      setValue("cityName", {});
      setCityMultiValue(false);
    }
  }, [cityOpValue]);

  const fetchCity = async () => {
    await autoSearchCity(cityPayload);
  };
  useEffect(() => {
    fetchCity();
  }, [cityPayload]);

  useEffect(() => {
    if (cityAutoSearchResults.data.length !== 0) {
      setCityOptions([...cityOptions, ...cityAutoSearchResults.data]);
    }
  }, [cityAutoSearchResults]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} pt={8}>
            <Grid item md={6}>
              <ReactHookFormSelect
                name="cityNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={6}>
              <SearchableAutoComplete
                control={control}
                data={cityOptions}
                label="City Name"
                payload={cityPayload}
                setPayload={setCityPayload}
                loading={isLoadingAutoSearch}
                name="cityName"
                multiple={cityMultiValue}
                defaultValue={defaultCity}
              />
            </Grid>
            <Grid item md={6}>
              <ReactHookFormSelect
                name="talukNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                label={t("taluk_name")}
                name="talukName"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
          </Grid>
          <FilterReset resetForm={reset} />
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AdvanceFilter;
