import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import UnpaidInvoiceReducer from "../reducers/UnpaidInvoiceReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { UnpaidInvoiceConstants } from "../constants/unpaidinvoice.constants";
import { saveFile } from "../utils/common";
const initialState = {
  unpaidInvoices: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  smsLogSearchResults: [],
  smsLog: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  smsLogAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const UnpaidInvoiceContext = createContext({
  ...initialState,
  getAllUnpaidInvoice: () => {},
  collectUser: () => {},
  cancelInvoice: () => {},
  addUnpaidInvoice: () => {},
  searchUnpaidInvoice: () => {},
  autoSearchUnpaidInvoice: () => {},
  getUnpaidInvoice: () => {},
  updateUnpaidInvoice: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function UnpaidInvoiceProvider({ children }) {
  const [state, dispatch] = useReducer(UnpaidInvoiceReducer, initialState);

  const getAllUnpaidInvoice = async (payload) => {
    try {
      dispatch({
        type: UnpaidInvoiceConstants.FETCH_UNPAIDINVOICE_REQUEST,
      });
      const response = await axios.post("/radius/raduser/unpaid-invoices", payload);
      dispatch({
        type: UnpaidInvoiceConstants.FETCH_UNPAIDINVOICE_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: UnpaidInvoiceConstants.FETCH_UNPAIDINVOICE_FAILURE,
      });
    }
  };
  const searchUnpaidInvoice = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/unpaid-invoices/search", payload);
      dispatch({
        type: UnpaidInvoiceConstants.UNPAIDINVOICE_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: UnpaidInvoiceConstants.UNPAIDINVOICE_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: UnpaidInvoiceConstants.UNPAIDINVOICE_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchUnpaidInvoice = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/unpaid-invoices/auto-search", payload);
      dispatch({
        type: UnpaidInvoiceConstants.UNPAIDINVOICE_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: UnpaidInvoiceConstants.UNPAIDINVOICE_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: UnpaidInvoiceConstants.UNPAIDINVOICE_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const getUnpaidInvoice = async (id) => {
    const response = await axios.get("/radius/raduser/unpaid-invoices/" + id);
    dispatch({
      type: UnpaidInvoiceConstants.GET_UNPAIDINVOICE_REQUEST,
    });
    dispatch({
      type: UnpaidInvoiceConstants.GET_UNPAIDINVOICE_SUCCESS,
      payload: response.data,
    });
  };

  const collectUser = async (payload) => {
    const response = await axios.post("radius/raduser/renewal/collect", payload);
    dispatch({
      type: UnpaidInvoiceConstants.COLLECT_USER_REQUEST,
    });
    dispatch({
      type: UnpaidInvoiceConstants.COLLECT_USER_SUCCESS,
      payload: response.data,
    });
  };

  const cancelInvoice = async (payload) => {
    const response = await axios.post("radius/raduser/renewal/cancel", payload);
    dispatch({
      type: UnpaidInvoiceConstants.CANCEL_INVOICE_REQUEST,
    });
    dispatch({
      type: UnpaidInvoiceConstants.CANCEL_INVOICE_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: UnpaidInvoiceConstants.DOWNLOAD_UNPAIDINVOICE_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post(
      "/radius/raduser/unpaid-invoices/export/" + fileType,
      payload,
      {
        responseType: "blob",
      },
    );
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "unpaid-invoice." + fileType);

    dispatch({
      type: UnpaidInvoiceConstants.DOWNLOAD_UNPAIDINVOICE_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <UnpaidInvoiceContext.Provider
      value={{
        ...state,
        getAllUnpaidInvoice,
        collectUser,
        cancelInvoice,
        searchUnpaidInvoice,
        autoSearchUnpaidInvoice,
        getUnpaidInvoice,
        downloadFile,
      }}
    >
      {children}
    </UnpaidInvoiceContext.Provider>
  );
}

export { UnpaidInvoiceContext, UnpaidInvoiceProvider };
