import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import VillageReducer from "../reducers/VillageReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { VillageConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  states: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  village: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  villageSearchResults: [],
  isLoadingDownload: false,
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const VillageContext = createContext({
  ...initialState,
  getAllVillage: () => {},
  addVillage: () => {},
  editVillage: () => {},
  searchVillage: () => {},
  getVillage: () => {},
  updateVillage: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function VillageProvider({ children }) {
  const [state, dispatch] = useReducer(VillageReducer, initialState);

  const getAllVillage = async (payload) => {
    try {
      dispatch({
        type: VillageConstants.FETCH_VILLAGE_REQUEST,
      });
      const response = await axios.post("/master/village/list", payload);

      dispatch({
        type: VillageConstants.FETCH_VILLAGE_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: VillageConstants.FETCH_VILLAGE_FAILURE,
      });
    }
  };
  const addVillage = async (payload) => {
    const response = await axios.post("/master/village/add", payload);
    dispatch({
      type: VillageConstants.ADD_VILLAGE_REQUEST,
    });
    dispatch({
      type: VillageConstants.ADD_VILLAGE_SUCCESS,
      payload: response.data,
    });
  };
  const editVillage = async (payload) => {
    const response = await axios.put("/master/village/update/" + payload.villageId, payload);
    dispatch({
      type: VillageConstants.EDIT_VILLAGE_REQUEST,
    });
    dispatch({
      type: VillageConstants.EDIT_VILLAGE_SUCCESS,
      payload: response.data,
    });
  };
  const searchVillage = async (payload) => {
    try {
      const response = await axios.post("/master/village/search", payload);
      dispatch({
        type: VillageConstants.VILLAGE_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: VillageConstants.VILLAGE_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: VillageConstants.VILLAGE_SEARCH_FAILURE,
      });
    }
  };
  const getVillage = async (id) => {
    const response = await axios.get("/master/village/" + id);
    dispatch({
      type: VillageConstants.GET_VILLAGE_REQUEST,
    });
    dispatch({
      type: VillageConstants.GET_VILLAGE_SUCCESS,
      payload: response.data,
    });
  };

  const updateVillage = async (payload) => {
    console.log(payload, "Upatetaluk");
    const response = await axios.put("/master/village/update/" + payload.villageId, payload);
    dispatch({
      type: VillageConstants.UPDATE_VILLAGE_REQUEST,
    });
    dispatch({
      type: VillageConstants.UPDATE_VILLAGE_SUCCESS,
      payload: response.data,
    });
  };
  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: VillageConstants.DOWNLOAD_VILLAGE_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/master/village/export/" + fileType, payload, {
      responseType: "blob",
    });
    //console.log(response);
    await saveFile(response.data, "village." + fileType);
    dispatch({
      type: VillageConstants.DOWNLOAD_VILLAGE_SUCCESS,
      payload: response.data,
    });
  };
  const sampleFile = async (fileType) => {
    const response = await axios.get("/master/village/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_village." + fileType);
    dispatch({
      type: VillageConstants.DOWNLOAD_VILLAGE_REQUEST,
    });
    dispatch({
      type: VillageConstants.DOWNLOAD_VILLAGE_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: VillageConstants.UPLOAD_VILLAGE_REQUEST,
      });
      const response = await axios({
        url: "/master/village/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: VillageConstants.UPLOAD_VILLAGE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: VillageConstants.UPLOAD_VILLAGE_FAILURE,
      });
    }
  };

  return (
    <VillageContext.Provider
      value={{
        ...state,
        getAllVillage,
        addVillage,
        editVillage,
        searchVillage,
        getVillage,
        updateVillage,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </VillageContext.Provider>
  );
}

export { VillageContext, VillageProvider };
