import { TenantSharingConstants } from "../constants/tenant_sharing.constants";

const TenantSharingReducer = (state, action) => {
  switch (action.type) {
    case TenantSharingConstants.FETCH_TENANT_SHARING_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantSharingConstants.FETCH_TENANT_SHARING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantSharings: action.payload.data,
        paging: action.payload.paging,
      };
    case TenantSharingConstants.FETCH_TENANT_SHARING_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantSharingConstants.TENANT_SHARING_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantSharingConstants.TENANT_SHARING_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantSearchResults: action.payload,
      };
    case TenantSharingConstants.TENANT_SHARING_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantSharingConstants.ADD_TENANT_SHARING_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantSharingConstants.ADD_TENANT_SHARING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case TenantSharingConstants.ADD_TENANT_SHARING_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case TenantSharingConstants.GET_TENANT_SHARING_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantSharingConstants.GET_TENANT_SHARING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenant: action.payload,
      };
    case TenantSharingConstants.GET_TENANT_SHARING_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantSharingConstants.UPDATE_TENANT_SHARING_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantSharingConstants.UPDATE_TENANT_SHARING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenant: action.payload,
      };
    case TenantSharingConstants.UPDATE_TENANT_SHARING_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case TenantSharingConstants.DOWNLOAD_TENANT_SHARING_REQUEST:
      return {
        ...state,
        isDownload: true,
        hasError: false,
      };
    case TenantSharingConstants.DOWNLOAD_TENANT_SHARING_SUCCESS:
      return {
        ...state,
        isDownload: false,
      };
    case TenantSharingConstants.DOWNLOAD_TENANT_SHARING_FAILURE:
      return {
        ...state,
        hasError: true,
        isDownload: false,
      };
    case TenantSharingConstants.UPLOAD_TENANT_SHARING_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case TenantSharingConstants.UPLOAD_TENANT_SHARING_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case TenantSharingConstants.UPLOAD_TENANT_SHARING_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    case TenantSharingConstants.ADD_TENANT_SHARING_POLICY_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case TenantSharingConstants.ADD_TENANT_SHARING_POLICY_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case TenantSharingConstants.ADD_TENANT_SHARING_POLICY_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case TenantSharingConstants.FETCH_TENANT_SHARING_POLICY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantSharingConstants.FETCH_TENANT_SHARING_POLICY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantPlans: action.payload.data,
        paging: action.payload.paging,
      };
    case TenantSharingConstants.FETCH_TENANT_SHARING_POLICY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantSharingConstants.FETCH_TENANT_SHARING_IPPOOL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantSharingConstants.FETCH_TENANT_SHARING_IPPOOL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantIppools: action.payload.data,
        paging: action.payload.paging,
      };
    case TenantSharingConstants.FETCH_TENANT_SHARING_IPPOOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantSharingConstants.ADD_TENANT_SHARING_IPPOOL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case TenantSharingConstants.ADD_TENANT_SHARING_IPPOOL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case TenantSharingConstants.ADD_TENANT_SHARING_IPPOOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };
    case TenantSharingConstants.TENANT_SHARING_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isAutoSearchLoading: true,
        hasError: false,
      };
    case TenantSharingConstants.TENANT_SHARING_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isAutoSearchLoading: false,
        tenantAutoSearchResults: action.payload,
      };
    case TenantSharingConstants.TENANT_SHARING_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isAutoSearchLoading: false,
        errorMessage: action.payload.message,
      };

    case TenantSharingConstants.TENANT_SHARING_POLICY_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isTenantSharingAutoSearchLoading: true,
        hasError: false,
      };
    case TenantSharingConstants.TENANT_SHARING_POLICY_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isTenantSharingAutoSearchLoading: false,
        policyAutoSearchResults: action.payload,
      };
    case TenantSharingConstants.TENANT_SHARING_POLICY_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isTenantSharingAutoSearchLoading: false,
        errorMessage: action.payload.message,
      };

    default:
      return state;
  }
};

export default TenantSharingReducer;
