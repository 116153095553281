import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { spacing } from "@mui/system";
import { green } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

import { Box as MuiBox, Chip, Drawer as MuiDrawer, ListItemButton } from "@mui/material";

// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import Logo from "../../vendor/logo.png";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

// const BrandIcon = styled(Logo)`
//   margin-right: ${(props) => props.theme.spacing(2)};
//   color: ${(props) => props.theme.sidebar.header.brand.color};
//   fill: ${(props) => props.theme.sidebar.header.brand.color};
//   width: 32px;
//   height: 32px;
// `;

const Sidebar = ({ items, showFooter = true, ...rest }) => {
  const theme = useTheme();
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/" theme={theme}>
        {/* <BrandIcon />{" "} */}
        <img
          src={Logo}
          alt="Hyper Radius Logo"
          width={42}
          height={32}
          style={{ marginBottom: "10px" }}
        />{" "}
        <Box ml={1}>HYPER RADIUS</Box>
      </Brand>
      <SidebarNav items={items} />
      {/* {!!showFooter && <Footer />} */}
    </Drawer>
  );
};

export default Sidebar;
