//Users
import Lead from "../pages/lead/index.js";
import AddLead from "../pages/lead/add.js";
import EditLead from "../pages/lead/edit.js";
import LeadFields from "../pages/lead/components/LeadFields.js";
import ComplaintType from "../pages/complaint_type/index.js";
import AddComplaintType from "../pages/complaint_type/add.js";
import Migration from "../pages/users/migration/index.js";
import ActiveUsers from "../pages/users/active_users/index.js";
import UserDetails from "../pages/users/user_details/index.js";
import ExpiredUsers from "../pages/users/expired_users/index.js";
import SuspendedUsers from "../pages/users/suspended_users/index.js";
import LeadStats from "../pages/lead/leadstats.js";
import LeadDetails from "../pages/lead/leaddetails.js";
import AssignUser from "../pages/lead/components/assign.js";
import ClosedLead from "../pages/lead/components/Closed.js";

//Composers
import { SettingComposer } from "../composers/SettingComposer.js";

import { DashboardComposer } from "../composers/DashboardComposer.js";

// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
import Settings from "../pages/cpanel/tenant/general_settings/index.js";
import Sms from "../pages/cpanel/tenant/sms_notifications";
import Email from "../pages/cpanel/tenant/mail_notifications";
// import LeadClosed from "../pages/lead/leadclosed.js";

const SettingsRoutes = {
  path: "cpanel",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "tenant",
      children: [
        {
          path: "general-setting",
          element: (
            <SettingComposer>
              <Settings />
            </SettingComposer>
          ),
        },
      ],
    },
    {
      path: "tenant",
      children: [
        {
          path: "email-setting",
          element: (
            <SettingComposer>
              <Email />
            </SettingComposer>
          ),
        },
      ],
    },
    {
      path: "tenant",
      children: [
        {
          path: "sms-setting",
          element: (
            <SettingComposer>
              <Sms />
            </SettingComposer>
          ),
        },
      ],
    },
  ],
};

export default SettingsRoutes;
