import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import { List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import SidebarNavSection from "./SidebarNavSection";

import "../../vendor/perfect-scrollbar.css";

const SidebarNav = ({ items }) => {
  const theme = useTheme();

  const baseScrollbar = css`
    background-color: ${theme.sidebar.background};
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    flex-grow: 1;
  `;

  const Scrollbar = styled.div`
    ${baseScrollbar}
  `;

  const PerfectScrollbar = styled(ReactPerfectScrollbar)`
    ${baseScrollbar}
  `;

  const Items = styled.div`
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
  `;
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;

  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Items theme={theme}>
          {items &&
            items.map((item) => (
              <SidebarNavSection
                component="div"
                key={item.title}
                pages={item.pages}
                title={item.title}
              />
            ))}
        </Items>
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
