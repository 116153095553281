import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";

import useBranch from "../../../../hooks/useBranch";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import { searchPayload } from "../../../../utils/common";

const currentTenantPolicyPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function BranchAndOthers(props) {
  const searchPayloadValue = searchPayload();
  const { autoSearchBranch, branchAutoSearchResults, autoSearchLoading } = useBranch();

  const { validationFields } = props;
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const [branchPayload, setBranchPayload] = useState(searchPayloadValue);

  const [branchOptions, setBranchOptions] = useState([]);

  const tenant = watch("tenant");

  useEffect(() => {
    console.log(tenant, "tenanttenanttenant");
    if (tenant != null && Object.keys(tenant).length != 0) {
      const payload = {
        ...branchPayload,
        filters: [
          {
            fieldName: "tenantId",
            fieldValue: tenant.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      };
      setBranchOptions([]);
      fetchBranchs(payload);
    }
  }, [tenant]);

  const fetchBranchs = async (payload) => {
    await autoSearchBranch(payload);
  };

  const isInitialMount = useRef(true);

  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //     fetchBranchs();
  //   }
  // }, [branchPayload]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (branchAutoSearchResults.data.length !== 0) {
      const results = [...branchOptions, ...branchAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setBranchOptions(filteredResults);
    }
  }, [branchAutoSearchResults]);

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={branchOptions}
          label="Select Branch"
          payload={branchPayload}
          setPayload={setBranchPayload}
          loading={autoSearchLoading}
          name="branch"
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={validationFields}
        />
      </Grid>
    </Grid>
  );
}

export default BranchAndOthers;
