import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import ScrollBar from "react-perfect-scrollbar";
import EditDelete from "../../components/EditDelete";
import Toolbar from "../../components/Toolbar";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../contexts/DialogContext";
import { useNavigate, useLocation } from "react-router-dom";

import useWindowDimensions from "../../utils/windowDimensions";
// import Details from "./Details";
import { getTimeStamp } from "../../utils/common";
import Upload from "./components/Upload";
import useComplaint from "../../hooks/useComplaint";
import AdvanceFilter from "./components/AdvanceFilter";
import useMainContentDimensions from "../../utils/mainContentDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = (props) => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Complaint</Typography>
      <Typography>List</Typography>
    </Breadcrumbs>
  );
};

const ComplaintClosed = (props) => {
  const { isLoadingDownload, downloadFileType } = useComplaint();
  const { width: mainContentWidth } = useMainContentDimensions();

  const { complaintId } = props;

  const currentPayload = {
    filters: [
      {
        fieldName: "status",
        fieldValue: "CLOSED",
        op: "eq",
        dataType: "enum",
        condition: "and",
        isJoin: false,
        isEnum: true,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "complaintId",
        sort: "DESC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height, width } = useWindowDimensions();
  const { getAllComplaint, complaints, paging, isLoading, downloadFile } = useComplaint();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllComplaint(payload);
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    console.log(complaintId, "complaintIdcomplaintId");
  }, [complaintId]);

  useEffect(() => {
    fetchData();
  }, [payload]);

  const exportFile = async (fileType) => {
    await downloadFile(fileType, payload);
  };
  const openDownloadDialog = () => {
    return openDialog("Upload complaint", true, <Upload key={getTimeStamp()} />);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };
  const columns = [
    {
      field: "complaintId",
      headerName: "Complaint No",
      width: 300,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 300,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 300,
    },
    {
      field: "mobileNo",
      headerName: "Mobile No",
      width: 300,
    },
    {
      field: "address",
      headerName: "Address",
      width: 300,
    },

    {
      field: "complaintType",
      headerName: "Complaint Type",
      valueGetter: (params) => params.row.complaintType?.complaintTypeName,
      type: "string",
      width: 300,
    },

    {
      field: "employee",
      headerName: "Assigned To ",
      valueGetter: (params) => params.row.employee?.employeeName,
      type: "string",
      width: 300,
    },
    // {
    //   field: "employee",
    //   headerName: "Assign To",
    //   width: 300,
    // },
    {
      field: "complaintDate",
      headerName: "Complaint Date",
      width: 300,
    },
    {
      field: "status",
      headerName: "Status",
      width: 300,
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 300,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 300,
    },
  ];
  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
  };

  const openFilterDialog = () => {
    return openDialog(
      "advance_filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={
          <Toolbar
            addPage={null}
            downloadFile={downloadFile}
            openDownloadDialog={openDownloadDialog}
            refreshPage={refreshPage}
            openFilterDialog={openFilterDialog}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
          />
        }
        title={t("complaint_closed")}
        subheader={breadCrump()}
      />
      <Paper>
        <div style={{ height: height - 180 }}>
          <DataGrid
            columns={columns}
            rows={complaints}
            getRowId={(row) => row["complaintId"]}
            rowCount={paging.totalNumberOfRecords}
            loading={false}
            rowsPerPageOptions={[5]}
            pagination
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMode="server"
            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageChange(pageSize)}
            checkboxSelection
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        </div>
      </Paper>
    </Card>
  );
};

export default ComplaintClosed;
