import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Card, CardContent, CardHeader } from "@mui/material";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";

import MappingToolBar from "../../../../components/MappingToolbar";
import MapIppoolModal from "./MapIppoolModal";
import { useDialog } from "../../../../contexts/DialogContext";
import { getTimeStamp } from "../../../../utils/common";
import MappedIppool from "./MappedIppool";

const AllowedIppool = ({ tenantId }) => {
  const { openDialog } = useDialog();

  console.log(tenantId, "tenantIdtenantId123");

  const openModalDialog = () => {
    return openDialog(
      "mapping_ippool",
      "Mapping Ippool",
      <MapIppoolModal tenantId={tenantId} isNew={true} />,
    );
  };

  return (
    <Card mb={6}>
      <CardHeader action={<MappingToolBar openDialog={openModalDialog} />} />
      <CardContent>
        <MappedIppool tenantId={tenantId} />
      </CardContent>
    </Card>
  );
};

export default AllowedIppool;
