import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Card as MuiCard, CardContent, CircularProgress, CardHeader } from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";

import SaveCancel from "../../../components/SaveCancel";
import useStateMaster from "../../../hooks/useStateMaster";
import StateFields from "./components/StateFields";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Card = styled(MuiCard)(spacing);

const initialValues = {
  stateName: "",
  country: "",
  status: "ACTIVE",
};

const validationSchema = Yup.object().shape({
  stateName: Yup.string().required("Required"),
  country: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  status: Yup.string().required("Required"),
});

function BasicForm(props) {
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { addStateMaster } = useStateMaster();
  const { t } = props;
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      const stateDTO = {
        country: {
          countryId: data.country.value,
          countryName: data.country.label,
        },
        status: data.status,
        stateName: data.stateName,
      };
      await addStateMaster(stateDTO);
      reset();
      dispatch(showSuccessSnackbar("State added successfully!"));
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
  };
  const cancelBtn = () => {
    navigate("/masters/state/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_state")} subheader={"We can add state here"} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <StateFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

function AddStateMaster() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}

export default AddStateMaster;
