import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import SaveCancel from "../../../components/SaveCancel";
import useCountry from "../../../hooks/useCountry";
import CountryFields from "./components/CountryFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  countryName: Yup.string().required("Required"),
  countryCode: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function EditCountry(props) {
  const { getCountry, country, updateCountry, isSubmitting } = useCountry();
  const dispatch = useDispatch();
  const { refreshPage } = props;
  React.useEffect(() => {
    if (Object.keys(country).length !== 0) {
      console.log(country.status, "country.status");
      const initialValues = {
        countryId: country.countryId,
        countryName: country.countryName,
        countryCode: country.countryCode,
        status: country.status,
      };
      reset(initialValues);
    }
  }, [country]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const [currentCountry, setCurrentCountry] = useState(null);
  const { state } = useLocation();
  console.log(state, "location");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      await updateCountry(data);
      navigate("/masters/country/list");
      reset();
      dispatch(showSuccessSnackbar("Country Updated successfully!"));
    } catch (error) {
      console.log(error);
      dispatch(showErrorSnackbar(error.message));
    }
  };

  const cancelBtn = () => {
    navigate("/masters/country/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Masters</Typography>
        <Typography>Country</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getCountry(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_country")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                Country updated successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <CountryFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditCountry;
