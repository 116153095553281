import { useContext } from "react";

import { BranchContext } from "../contexts/BranchContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useBranch = () => {
  const context = useContext(BranchContext);

  if (!context) throw new Error("BranchContext must be placed within BranchProvider");

  return context;
};

export default useBranch;
