import { useContext } from "react";

import { IppoolContext } from "../contexts/IppoolContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useIppool = () => {
  const context = useContext(IppoolContext);

  if (!context) throw new Error("IppoolContext must be placed within IppoolProvider");

  return context;
};

export default useIppool;
