import React, { forwardRef } from "react";
import { useController, useFormContext } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

import "react-multi-date-picker/styles/layouts/mobile.css";

function CustomDatePicker(
  {
    name,
    nameTemp,
    fields,
    timePicker = false,
    format,
    conversionFormat,
    hideSeconds = false,
    ...props
  },
  ref,
) {
  console.log(nameTemp, "nameTemp");
  const { field, fieldState } = useController({ name });
  const { register, setValue } = useFormContext();
  const { onChange, ...rest } = register(name);
  const { t } = useTranslation();

  //console.log(props, "requiredrequiredrequiredrrr");
  const required = fields?.[name] == undefined ? false : true;

  return (
    <>
      <DatePicker
        plugins={timePicker ? [<TimePicker hideSeconds={hideSeconds} position="right" />] : []}
        containerStyle={{
          width: "100%",
        }}
        portal
        zIndex={10000}
        value={field.value}
        fullWidth
        {...props}
        name={name}
        onChange={(dates) => {
          console.log(dates, name, "dddddddddddddd3333");
          //onChange({ target: { name, value: dates } });
          if (props.range) {
            onChange({ target: { name, value: dates } });
            if (dates.length == 2) {
              setValue(
                nameTemp,
                dates[0].format("YYYY-MM-DD") +
                  " 00:00:00" +
                  "," +
                  dates[1].format("YYYY-MM-DD") +
                  " 23:59:59",
              );
            } else {
              setValue(
                nameTemp,
                dates[0].format("YYYY-MM-DD") +
                  " 00:00:00" +
                  "," +
                  dates[0].format("YYYY-MM-DD") +
                  " 23:59:59",
              );
            }
          } else {
            const dateFormat =
              conversionFormat == undefined
                ? dates.format("YYYY-MM-DD") + " 00:00:00"
                : dates.format(conversionFormat);
            console.log(dateFormat, "dddddddddddddddddd");
            onChange({ target: { name, value: dates } });

            setValue(nameTemp, dates ? dateFormat : null);
          }
        }}
        {...rest}
        ref={ref}
        format={format}
        range={props.range}
        render={
          <TextField
            autoComplete="new-password"
            name={name}
            label={t(props.label)}
            variant="outlined"
            {...field}
            fullWidth
            error={fieldState.error ? true : false}
            InputLabelProps={{
              shrink: field.value?.toString() ? true : false,
              required: required,
              autoComplete: "new-password",
            }}
            helperText={fieldState.error ? fieldState.error.message : ""}
          />
        }
      />
    </>
  );
}

export default forwardRef(CustomDatePicker);
