import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { CountryProvider } from "../contexts/CountryContext";
import { DialogProvider } from "../contexts/DialogContext";

export const CountryComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<CountryProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};
