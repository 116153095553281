import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import { GlobalStyles } from "@mui/system";

import { CssBaseline } from "@mui/material";

import Settings from "../components/Settings";
import GlobalStyle from "../components/GlobalStyle";

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const Auth = ({ children }) => {
  const theme = useTheme();
  const globalStyles = (
    <GlobalStyles
      styles={{
        body: {
          height: "100%",
          overflowX: "hidden",
          background: `${theme.palette.background.default}`,
          margin: 0,
        },
        html: {
          height: "100%",
          overflowX: "hidden",
        },
        "#root": {
          height: "100%",
          overflowX: "hidden",
        },
        ".MuiCardHeader-action": {
          padding: "4px",
          width: "28px",
          height: "28px",
        },
        ".MuiIconButton-root": {
          adding: "4px",
          width: "28px",
          height: "28px",
        },
      }}
    />
  );
  return (
    <Root>
      <CssBaseline />
      {globalStyles}
      {children}
      <Outlet />
      {/* <Settings /> */}
    </Root>
  );
};

export default Auth;
