import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../../../components/CustomTextField";
import CustomCheckBox from "../../../../../components/CustomCheckBox";
import useTenant from "../../../../../hooks/useTenant";
import useAuth from "../../../../../hooks/useAuth";

const MailSetting = (props) => {
  const { user } = useAuth();
  const { validationFields } = props;
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item md={6}>
        <CustomTextField
          label="Mail From"
          name="mailFrom"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>

      <Grid item md={6}>
        <CustomTextField
          label="Host Name"
          name="hostName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Port"
          name="port"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>

      <Grid item md={6}>
        <CustomCheckBox
          label="SSL Enabled"
          name="isSslEnabled"
          type="checkbox"
          my={2}
          fullWidth
          errors={errors}
          fields={validationFields}
        />
        <CustomCheckBox
          label="Tls Enabled"
          name="isTlsEnabled"
          type="checkbox"
          my={2}
          fullWidth
          errors={errors}
          fields={validationFields}
        />
      </Grid>

      <Grid item md={6}>
        <CustomTextField
          label="User Name"
          name="mailUserName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Password"
          name="mailPassword"
          type="password"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
    </Grid>
  );
};

export default MailSetting;
