import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../../components/CustomTextField";
import { searchPayload } from "../../../../utils/common";

const LoginDetail = (props) => {
  const {
    register,
    formState: { errors },
    control,
    watch,
    resetField,
  } = useFormContext();

  const { fields } = props;

  console.log(fields, "fields");

  const searchPayloadValue = searchPayload();
  const officialEmailId = watch("officialEmailId");

  // useEffect(() => {
  //   console.log(officialEmailId, "dssssssssssssss");
  //   resetField("userName", { defaultValue: officialEmailId });
  // }, [officialEmailId]);

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item md={6}>
        <CustomTextField
          label="User name"
          name="userName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
          // disabled={true}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Password"
          name="password"
          type="password"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
    </Grid>
  );
};

export default LoginDetail;
