import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../components/CustomTextField";
import ReactHookFormSelect from "../../../components/ReactHookFormSelect";
import SearchableAutoComplete from "../../../components/SearchableAutoComplete";
import useEmployee from "../../../hooks/useEmployee";
import useTenant from "../../../hooks/useTenant";
import useBranch from "../../../hooks/useBranch";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function LeadFields(props) {
  const { fields } = props;

  const { autoSearchEmployee, employeeAutoSearchResults } = useEmployee();
  const { autoSearchTenant, tenantAutoSearchResults } = useTenant();
  const { autoSearchBranch, branchAutoSearchResults } = useBranch();

  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();

  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [employeePayload, setEmployeePayload] = useState(currentPayload);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [tenantPayload, setTenantPayload] = useState(currentPayload);
  const [branchOptions, setBranchOptions] = useState([]);
  const [branchPayload, setBranchPayload] = useState(currentPayload);

  // Tenant
  const fetchTenant = async () => {
    await autoSearchTenant(tenantPayload);
  };
  useEffect(() => {
    fetchTenant();
  }, [tenantPayload]);

  useEffect(() => {
    console.log(tenantAutoSearchResults, "tenantAutoSearchResults");
    if (tenantAutoSearchResults && tenantAutoSearchResults.data.length !== 0) {
      const results = [...tenantOptions, ...tenantAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setTenantOptions(filteredResults);
    }
  }, [tenantAutoSearchResults]);

  // Branch
  const fetchBranch = async () => {
    await autoSearchBranch(employeePayload);
  };
  useEffect(() => {
    fetchBranch();
  }, [branchPayload]);

  useEffect(() => {
    console.log(branchAutoSearchResults, "branchAutoSearchResults");
    if (branchAutoSearchResults && branchAutoSearchResults.data.length !== 0) {
      const results = [...branchOptions, ...branchAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setBranchOptions(filteredResults);
    }
  }, [branchAutoSearchResults]);

  // Employee
  const fetchEmployee = async () => {
    await autoSearchEmployee(employeePayload);
  };
  useEffect(() => {
    fetchEmployee();
  }, [employeePayload]);

  useEffect(() => {
    console.log(employeeAutoSearchResults, "employeeAutoSearchResults");
    if (employeeAutoSearchResults && employeeAutoSearchResults.data.length !== 0) {
      const results = [...employeeOptions, ...employeeAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setEmployeeOptions(filteredResults);
    }
  }, [employeeAutoSearchResults]);

  return (
    <>
      <Grid container spacing={6} className="row">
        <Grid item md={6}>
          <SearchableAutoComplete
            name="tenant"
            label={t("tenant_name")}
            control={control}
            data={tenantOptions}
            payload={tenantPayload}
            setPayload={setTenantPayload}
            loading={false}
            multiple={false}
            defaultValue={""}
            hasNextPage={false}
            fields={fields}
            fullWidth
            variant="outlined"
            errors={errors}
          ></SearchableAutoComplete>
        </Grid>
        <Grid item md={6}>
          <SearchableAutoComplete
            name="branchName"
            label={t("branch_name")}
            control={control}
            data={branchOptions}
            payload={branchPayload}
            setPayload={setBranchPayload}
            loading={false}
            multiple={false}
            defaultValue={""}
            hasNextPage={false}
            fields={fields}
            fullWidth
            variant="outlined"
            errors={errors}
          ></SearchableAutoComplete>
        </Grid>

        <Grid item md={6}>
          <SearchableAutoComplete
            name="employee"
            label={t("assign_to_employee")}
            control={control}
            data={employeeOptions}
            payload={employeePayload}
            setPayload={setEmployeePayload}
            loading={false}
            multiple={false}
            defaultValue={""}
            hasNextPage={false}
            fields={fields}
            fullWidth
            variant="outlined"
            errors={errors}
          ></SearchableAutoComplete>
        </Grid>

        <Grid item md={6}>
          <CustomTextField
            label={t("customer_name")}
            name="customerName"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={fields}
          />
        </Grid>

        <Grid item md={6}>
          <ReactHookFormSelect
            name="status"
            label={t("status")}
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={fields}
          >
            <MenuItem value={"OPEN"}>Open</MenuItem>
            <MenuItem value={"CONFIRMED"}>Confirmed</MenuItem>
            <MenuItem value={"INTERESTED"}>Interested</MenuItem>
          </ReactHookFormSelect>
        </Grid>

        <Grid item md={6}>
          <ReactHookFormSelect
            name="leadType"
            label={t("lead_type")}
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={fields}
          >
            <MenuItem value={"NEW CONNECTION"}>New Connection</MenuItem>
            <MenuItem value={"GENERAL INQUIRY"}>General Inquiry</MenuItem>
          </ReactHookFormSelect>
        </Grid>

        <Grid item md={6}>
          <CustomTextField
            label={t("address")}
            name="address"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
          />
        </Grid>

        <Grid item md={6}>
          <CustomTextField
            label={t("mobile_number")}
            name="mobileNo"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={fields}
          />
        </Grid>

        <Grid item md={6}>
          <CustomTextField
            label={t("email_id")}
            name="email"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={fields}
          />
        </Grid>

        <Grid item md={6}>
          <CustomTextField
            label={t("comments")}
            name="comments"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={fields}
          />
        </Grid>
      </Grid>
      <br></br>
    </>
  );
}

export default LeadFields;
