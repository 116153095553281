import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import PaidInvoiceReducer from "../reducers/PaidInvoiceReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { PaidInvoiceConstants } from "../constants/paidinvoice.constants";
import { saveFile } from "../utils/common";
const initialState = {
  paidInvoices: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  smsLogSearchResults: [],
  smsLog: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  smsLogAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  isLoadingAutoSearch: false,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const PaidInvoiceContext = createContext({
  ...initialState,
  getAllPaidInvoice: () => {},
  addPaidInvoice: () => {},
  searchPaidInvoice: () => {},
  autoSearchPaidInvoice: () => {},
  getPaidInvoice: () => {},
  updatePaidInvoice: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function PaidInvoiceProvider({ children }) {
  const [state, dispatch] = useReducer(PaidInvoiceReducer, initialState);

  const getAllPaidInvoice = async (payload) => {
    try {
      dispatch({
        type: PaidInvoiceConstants.FETCH_PAIDINVOICE_REQUEST,
      });
      const response = await axios.post("/radius/raduser/paid-invoices", payload);
      dispatch({
        type: PaidInvoiceConstants.FETCH_PAIDINVOICE_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: PaidInvoiceConstants.FETCH_PAIDINVOICE_FAILURE,
      });
    }
  };
  const searchPaidInvoice = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/paid-invoices/search", payload);
      dispatch({
        type: PaidInvoiceConstants.PAIDINVOICE_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PaidInvoiceConstants.PAIDINVOICE_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PaidInvoiceConstants.PAIDINVOICE_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchPaidInvoice = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/paid-invoices/auto-search", payload);
      dispatch({
        type: PaidInvoiceConstants.PAIDINVOICE_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PaidInvoiceConstants.PAIDINVOICE_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PaidInvoiceConstants.PAIDINVOICE_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const getPaidInvoice = async (id) => {
    const response = await axios.get("/radius/raduser/paid-invoices/" + id);
    dispatch({
      type: PaidInvoiceConstants.GET_PAIDINVOICE_REQUEST,
    });
    dispatch({
      type: PaidInvoiceConstants.GET_PAIDINVOICE_SUCCESS,
      payload: response.data,
    });
    return response.data;
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: PaidInvoiceConstants.DOWNLOAD_PAIDINVOICE_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/radius/raduser/paid-invoices/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "paid-invoice." + fileType);

    dispatch({
      type: PaidInvoiceConstants.DOWNLOAD_PAIDINVOICE_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <PaidInvoiceContext.Provider
      value={{
        ...state,
        getAllPaidInvoice,
        searchPaidInvoice,
        autoSearchPaidInvoice,
        getPaidInvoice,
        downloadFile,
      }}
    >
      {children}
    </PaidInvoiceContext.Provider>
  );
}

export { PaidInvoiceContext, PaidInvoiceProvider };
