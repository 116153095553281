import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import StateMasterReducer from "../reducers/StateMasterReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { StateMasterConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  states: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  stateSearchResults: [],
  statemaster: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isDownload: false,
  isLoadingDownload: false,
  stateAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const StateMasterContext = createContext({
  ...initialState,
  getAllState: () => {},
  addStateMaster: () => {},
  editStateMaster: () => {},
  searchState: () => {},
  autoSearchState: () => {},
  updateState: () => {},
  getState: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function StateMasterProvider({ children }) {
  const [state, dispatch] = useReducer(StateMasterReducer, initialState);

  const getAllState = async (payload) => {
    try {
      dispatch({
        type: StateMasterConstants.FETCH_STATEMASTER_REQUEST,
      });
      const response = await axios.post("/master/state/list", payload);
      dispatch({
        type: StateMasterConstants.FETCH_STATEMASTER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: StateMasterConstants.FETCH_STATEMASTER_FAILURE,
      });
    }
  };
  const addStateMaster = async (payload) => {
    const response = await axios.post("/master/state/add", payload);
    dispatch({
      type: StateMasterConstants.ADD_STATEMASTER_REQUEST,
    });
    dispatch({
      type: StateMasterConstants.ADD_STATEMASTER_SUCCESS,
      payload: response.data,
    });
  };
  const editStateMaster = async (payload) => {
    console.log("1111111111111111", payload);
    const response = await axios.post("/master/state/edit", payload);
    dispatch({
      type: StateMasterConstants.EDIT_STATEMASTER_REQUEST,
    });
    dispatch({
      type: StateMasterConstants.EDIT_STATEMASTER_SUCCESS,
      payload: response.data,
    });
  };
  const searchState = async (payload) => {
    try {
      const response = await axios.post("/master/state/search", payload);
      dispatch({
        type: StateMasterConstants.STATEMASTER_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: StateMasterConstants.STATEMASTER_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: StateMasterConstants.STATEMASTER_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchState = async (payload) => {
    try {
      const response = await axios.post("/master/state/auto-search", payload);
      dispatch({
        type: StateMasterConstants.STATEMASTER_AUTO_SEARCH_REQUEST,
      });
      dispatch({
        type: StateMasterConstants.STATEMASTER_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: StateMasterConstants.STATEMASTER_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const getState = async (id) => {
    console.log(id, "aaaaaaaaaa");
    const response = await axios.get("/master/state/" + id);
    dispatch({
      type: StateMasterConstants.GET_STATEMASTER_REQUEST,
    });
    dispatch({
      type: StateMasterConstants.GET_STATEMASTER_SUCCESS,
      payload: response.data,
    });
  };

  const updateState = async (payload) => {
    const response = await axios.put("/master/state/update/" + payload.stateId, payload);
    dispatch({
      type: StateMasterConstants.UPDATE_STATEMASTER_REQUEST,
    });
    dispatch({
      type: StateMasterConstants.UPDATE_STATEMASTER_SUCCESS,
      payload: response.data,
    });
  };
  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: StateMasterConstants.DOWNLOAD_STATEMASTER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/master/state/export/" + fileType, payload, {
      responseType: "blob",
    });
    //console.log(response);
    await saveFile(response.data, "statemaster." + fileType);
    dispatch({
      type: StateMasterConstants.DOWNLOAD_STATEMASTER_SUCCESS,
      payload: response.data,
    });
  };
  const sampleFile = async (fileType) => {
    const response = await axios.get("/master/state/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_state." + fileType);
    dispatch({
      type: StateMasterConstants.DOWNLOAD_STATEMASTER_REQUEST,
    });
    dispatch({
      type: StateMasterConstants.DOWNLOAD_STATEMASTER_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: StateMasterConstants.UPLOAD_STATEMASTER_REQUEST,
      });
      const response = await axios({
        url: "/master/state/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: StateMasterConstants.UPLOAD_STATEMASTER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: StateMasterConstants.UPLOAD_STATEMASTER_FAILURE,
      });
    }
  };

  return (
    <StateMasterContext.Provider
      value={{
        ...state,
        getAllState,
        addStateMaster,
        editStateMaster,
        searchState,
        autoSearchState,
        getState,
        updateState,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </StateMasterContext.Provider>
  );
}

export { StateMasterContext, StateMasterProvider };
