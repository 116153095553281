export const KycDocumentConstants = {
  FETCH_KYCDOCUMENT_REQUEST: "FETCH_KYCDOCUMENT_REQUEST",
  FETCH_KYCDOCUMENT_SUCCESS: "FETCH_KYCDOCUMENT_SUCCESS",
  FETCH_KYCDOCUMENT_FAILURE: "FETCH_KYCDOCUMENT_FAILURE",
  ADD_KYCDOCUMENT_REQUEST: "ADD_KYCDOCUMENT_REQUEST",
  ADD_KYCDOCUMENT_SUCCESS: "ADD_KYCDOCUMENT_SUCCESS",
  ADD_KYCDOCUMENT_FAILURE: "ADD_KYCDOCUMENT_FAILURE",
  KYCDOCUMENT_SEARCH_REQUEST: "KYCDOCUMENT_SEARCH_REQUEST",
  KYCDOCUMENT_SEARCH_SUCCESS: "KYCDOCUMENT_SEARCH_SUCCESS",
  KYCDOCUMENT_SEARCH_FAILURE: "KYCDOCUMENT_SEARCH_FAILURE",
  KYCDOCUMENT_AUTO_SEARCH_REQUEST: "KYCDOCUMENT_AUTO_SEARCH_REQUEST",
  KYCDOCUMENT_AUTO_SEARCH_SUCCESS: "KYCDOCUMENT_AUTO_SEARCH_SUCCESS",
  KYCDOCUMENT_AUTO_SEARCH_FAILURE: "KYCDOCUMENT_AUTO_SEARCH_FAILURE",
  GET_KYCDOCUMENT_REQUEST: "GET_KYCDOCUMENT_REQUEST",
  GET_KYCDOCUMENT_SUCCESS: "GET_KYCDOCUMENT_SUCCESS",
  GET_KYCDOCUMENT_FAILURE: "GET_KYCDOCUMENT_FAILURE",
  UPDATE_KYCDOCUMENT_REQUEST: "UPDATE_KYCDOCUMENT_REQUEST",
  UPDATE_KYCDOCUMENT_SUCCESS: "UPDATE_KYCDOCUMENT_SUCCESS",
  UPDATE_KYCDOCUMENT_FAILURE: "UPDATE_KYCDOCUMENT_FAILURE",
  DOWNLOAD_KYCDOCUMENT_REQUEST: "DOWNLOAD_KYCDOCUMENT_REQUEST",
  DOWNLOAD_KYCDOCUMENT_SUCCESS: "DOWNLOAD_KYCDOCUMENT_SUCCESS",
  DOWNLOAD_KYCDOCUMENT_FAILURE: "DOWNLOAD_KYCDOCUMENT_FAILURE",
  UPLOAD_KYCDOCUMENT_REQUEST: "UPLOAD_KYCDOCUMENT_REQUEST",
  UPLOAD_KYCDOCUMENT_SUCCESS: "UPLOAD_KYCDOCUMENT_SUCCESS",
  UPLOAD_KYCDOCUMENT_FAILURE: "UPLOAD_KYCDOCUMENT_FAILURE",
};
