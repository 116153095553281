import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../../components/CustomTextField";
import { searchPayload } from "../../../../utils/common";

const ContactDetail = (props) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const { validationFields } = props;

  const searchPayloadValue = searchPayload();

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item md={6}>
        <CustomTextField
          label="First Name"
          name="firstName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Last Name"
          name="lastName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Email Id"
          name="emailId"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Mobile No"
          name="mobileNo"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
    </Grid>
  );
};

export default ContactDetail;
