import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import NasGroupReducer from "../reducers/NasGroupReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { NasGroupConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  nasGroups: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  nasGroupSearchResults: [],
  nasGroup: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  addOnTenants: [],
  isLoading: true,
  nasGroupMaps: [],
  isLoadingDownload: false,
  nasGroupAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const NasGroupContext = createContext({
  ...initialState,
  getAllNasGroup: () => {},
  addNasGroup: () => {},
  addNasGroupMap: () => {},
  searchNasGroup: () => {},
  getNasGroup: () => {},
  updateNasGroup: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  getNasGroupMaps: () => {},
  autoSearchNasGroup: () => {},
});

function NasGroupProvider({ children }) {
  const [state, dispatch] = useReducer(NasGroupReducer, initialState);

  const getAllNasGroup = async (payload) => {
    try {
      dispatch({
        type: NasGroupConstants.FETCH_NAS_GROUP_REQUEST,
      });
      const response = await axios.post("/radius/nas-group/list", payload);
      dispatch({
        type: NasGroupConstants.FETCH_NAS_GROUP_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: NasGroupConstants.FETCH_NAS_GROUP_FAILURE,
      });
    }
  };
  const getNasGroupMaps = async (payload) => {
    try {
      const response = await axios.post("/radius/nas-group/map/list", payload);
      dispatch({
        type: NasGroupConstants.FETCH_NAS_GROUP_MAP_REQUEST,
      });
      dispatch({
        type: NasGroupConstants.FETCH_NAS_GROUP_MAP_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: NasGroupConstants.FETCH_NAS_GROUP_MAP_FAILURE,
      });
    }
  };
  const searchNasGroup = async () => {
    try {
      const response = await axios.post("/radius/nas-group/search", initPayload);
      dispatch({
        type: NasGroupConstants.NAS_GROUP_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: NasGroupConstants.NAS_GROUP_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: NasGroupConstants.NAS_GROUP_SEARCH_FAILURE,
      });
    }
  };
  const addNasGroup = async (payload) => {
    const response = await axios.post("/radius/nas-group/add", payload);
    dispatch({
      type: NasGroupConstants.ADD_NAS_GROUP_REQUEST,
    });
    dispatch({
      type: NasGroupConstants.ADD_NAS_GROUP_SUCCESS,
      payload: response.data,
    });
  };
  const autoSearchNasGroup = async (payload) => {
    try {
      const response = await axios.post("/radius/nas-group/auto-search", payload);
      dispatch({
        type: NasGroupConstants.NAS_GROUP_AUTO_SEARCH_REQUEST,
      });
      dispatch({
        type: NasGroupConstants.NAS_GROUP_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: NasGroupConstants.NAS_GROUP_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const addNasGroupMap = async (payload) => {
    const response = await axios.post("/radius/nas-group/map/add", payload);
    dispatch({
      type: NasGroupConstants.ADD_NAS_GROUP_REQUEST,
    });
    dispatch({
      type: NasGroupConstants.ADD_NAS_GROUP_SUCCESS,
      payload: response.data,
    });
  };

  const getNasGroup = async (id) => {
    const response = await axios.get("/radius/nas-group/" + id);
    dispatch({
      type: NasGroupConstants.GET_NAS_GROUP_REQUEST,
    });
    dispatch({
      type: NasGroupConstants.GET_NAS_GROUP_SUCCESS,
      payload: response.data,
    });
  };

  const updateNasGroup = async (payload) => {
    const response = await axios.put("/radius/nas-group/update/" + payload.nasGroupId, payload);
    dispatch({
      type: NasGroupConstants.UPDATE_NAS_GROUP_REQUEST,
    });
    dispatch({
      type: NasGroupConstants.UPDATE_NAS_GROUP_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    try {
      dispatch({
        type: NasGroupConstants.DOWNLOAD_NAS_GROUP_REQUEST,
        payload: {
          fileType: fileType,
        },
      });
      const response = await axios.post("/radius/nas-group/export/" + fileType, payload, {
        responseType: "blob",
      });
      console.log(response.headers, "filename");
      //const filename = response.headers.get("content-disposition").split('"');

      await saveFile(response.data, "nasGroup." + fileType);

      dispatch({
        type: NasGroupConstants.DOWNLOAD_NAS_GROUP_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: NasGroupConstants.DOWNLOAD_NAS_GROUP_FAILURE,
      });
    }
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/radius/nas-group/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_nasGroup." + fileType);
    dispatch({
      type: NasGroupConstants.DOWNLOAD_NAS_GROUP_REQUEST,
    });
    dispatch({
      type: NasGroupConstants.DOWNLOAD_NAS_GROUP_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: NasGroupConstants.UPLOAD_NAS_GROUP_REQUEST,
      });
      const response = await axios({
        url: "/radius/nas-group/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: NasGroupConstants.UPLOAD_NAS_GROUP_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: NasGroupConstants.UPLOAD_NAS_GROUP_FAILURE,
      });
    }
  };

  return (
    <NasGroupContext.Provider
      value={{
        ...state,
        getAllNasGroup,
        addNasGroup,
        addNasGroupMap,
        searchNasGroup,
        getNasGroup,
        updateNasGroup,
        downloadFile,
        sampleFile,
        uploadFile,
        getNasGroupMaps,
        autoSearchNasGroup,
      }}
    >
      {children}
    </NasGroupContext.Provider>
  );
}

export { NasGroupContext, NasGroupProvider };
