import { useContext } from "react";

import { ProformaInvoiceContext } from "../contexts/ProformaInvoiceContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useProformaInvoice = () => {
  const context = useContext(ProformaInvoiceContext);

  if (!context)
    throw new Error("ProformaInvoiceContext must be placed within ProformaInvoiceProvider");

  return context;
};

export default useProformaInvoice;
