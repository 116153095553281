import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import HotelManagementReducer from "../reducers/HotelManagementReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { HotelManagementConstants } from "../constants/hotelmanagement.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  hotelManagements: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  hotelManagementSearchResults: [],
  hotelManagement: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  hotelManagementAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const HotelManagementContext = createContext({
  ...initialState,
  getAllHotelManagement: () => {},
  addHotelManagement: () => {},
  activateUser: () => {},
  deactivateUser: () => {},
  searchHotelManagement: () => {},
  autoSearchHotelManagement: () => {},
  getHotelManagement: () => {},
  updateHotelManagement: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  disconnectUserSession: () => {},
});

function HotelManagementProvider({ children }) {
  const [state, dispatch] = useReducer(HotelManagementReducer, initialState);

  const getAllHotelManagement = async (payload) => {
    try {
      dispatch({
        type: HotelManagementConstants.FETCH_HOTEL_MANAGEMENT_REQUEST,
      });
      const response = await axios.post("/hotel-user/list", payload);
      dispatch({
        type: HotelManagementConstants.FETCH_HOTEL_MANAGEMENT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: HotelManagementConstants.FETCH_HOTEL_MANAGEMENT_FAILURE,
      });
    }
  };
  const searchHotelManagement = async (payload) => {
    try {
      const response = await axios.post("/master/hotelManagement/search", payload);
      dispatch({
        type: HotelManagementConstants.HOTEL_MANAGEMENT_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: HotelManagementConstants.HOTEL_MANAGEMENT_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: HotelManagementConstants.HOTEL_MANAGEMENT_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchHotelManagement = async (payload) => {
    try {
      const response = await axios.post("/master/hotelManagement/auto-search", payload);
      dispatch({
        type: HotelManagementConstants.HOTEL_MANAGEMENT_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: HotelManagementConstants.HOTEL_MANAGEMENT_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: HotelManagementConstants.HOTEL_MANAGEMENT_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addHotelManagement = async (payload) => {
    const response = await axios.post("/hotel-user/add", payload);
    dispatch({
      type: HotelManagementConstants.ADD_HOTEL_MANAGEMENT_REQUEST,
    });
    dispatch({
      type: HotelManagementConstants.ADD_HOTEL_MANAGEMENT_SUCCESS,
      payload: response.data,
    });
  };
  const activateUser = async (payload) => {
    const response = await axios.post("/hotel-user/activate-user", payload);
    dispatch({
      type: HotelManagementConstants.ADD_HOTEL_MANAGEMENT_ACTIVATE_USER_REQUEST,
    });
    dispatch({
      type: HotelManagementConstants.ADD_HOTEL_MANAGEMENT_ACTIVATE_USER_SUCCESS,
      payload: response.data,
    });
  };
  const deactivateUser = async (payload) => {
    const response = await axios.post("/hotel-user/deactivate-user", payload);
    dispatch({
      type: HotelManagementConstants.ADD_HOTEL_MANAGEMENT_DEACTIVATE_USER_REQUEST,
    });
    dispatch({
      type: HotelManagementConstants.ADD_HOTEL_MANAGEMENT_DEACTIVATE_USER_SUCCESS,
      payload: response.data,
    });
  };

  const disconnectUserSession = async (payload) => {
    const response = await axios.post("/hotel-user/disconnect-user-session", payload);
    dispatch({
      type: HotelManagementConstants.DISCONNECT_USER_SESSION_REQUEST,
    });
    dispatch({
      type: HotelManagementConstants.DISCONNECT_USER_SESSION_SUCCESS,
      payload: response.data,
    });
  };

  const getHotelManagement = async (id) => {
    const response = await axios.get("/hotel-user/" + id);
    dispatch({
      type: HotelManagementConstants.GET_HOTEL_MANAGEMENT_REQUEST,
    });
    dispatch({
      type: HotelManagementConstants.GET_HOTEL_MANAGEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const updateHotelManagement = async (payload) => {
    const response = await axios.put("/hotel-user/update/" + payload.hotelUserId, payload);
    dispatch({
      type: HotelManagementConstants.UPDATE_HOTEL_MANAGEMENT_REQUEST,
    });
    dispatch({
      type: HotelManagementConstants.UPDATE_HOTEL_MANAGEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: HotelManagementConstants.DOWNLOAD_HOTEL_MANAGEMENT_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/hotel-user/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "sample_file_user." + fileType);
    dispatch({
      type: HotelManagementConstants.DOWNLOAD_HOTEL_MANAGEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/hotel-user/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_users." + fileType);
    dispatch({
      type: HotelManagementConstants.DOWNLOAD_HOTEL_MANAGEMENT_REQUEST,
    });
    dispatch({
      type: HotelManagementConstants.DOWNLOAD_HOTEL_MANAGEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: HotelManagementConstants.UPLOAD_HOTEL_MANAGEMENT_REQUEST,
      });
      const response = await axios({
        url: "/hotel-user/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: HotelManagementConstants.UPLOAD_HOTEL_MANAGEMENT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: HotelManagementConstants.UPLOAD_HOTEL_MANAGEMENT_FAILURE,
      });
    }
  };

  return (
    <HotelManagementContext.Provider
      value={{
        ...state,
        getAllHotelManagement,
        addHotelManagement,
        activateUser,
        deactivateUser,
        searchHotelManagement,
        autoSearchHotelManagement,
        getHotelManagement,
        updateHotelManagement,
        downloadFile,
        sampleFile,
        uploadFile,
        disconnectUserSession,
      }}
    >
      {children}
    </HotelManagementContext.Provider>
  );
}

export { HotelManagementContext, HotelManagementProvider };
