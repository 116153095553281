import React from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";

import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Radius</Typography>
      <Typography>Assigned Plan</Typography>
    </Breadcrumbs>
  );
};

const CustomDataGrid = (props) => {
  const { height, width } = useWindowDimensions();
  const {
    editPage,
    addPage,
    trans,
    data,
    rowCount,
    paging,
    onPageChange,
    isLoading,
    id,
    handleSortModelChange,
    sortModel,
    downloadFile,
    openDownloadDialog,
  } = props;
  console.log(rowCount, "loading child component");

  const columns = [
    {
      field: "planName",
      headerName: "Plan Name",
      width: 300,
    },
    {
      field: "uploadSpeed",
      headerName: "Upload Speed",
      width: 300,
    },
    {
      field: "downloadSpeed",
      headerName: "Download Speed",
      width: 300,
    },
    {
      field: "planType",
      headerName: "Plan Type",
      width: 300,
    },
    {
      field: "planDataType",
      headerName: "Plan Data Type",
      width: 300,
    },
    {
      field: "downloadLimit",
      headerName: "Download Limit",
      width: 300,
    },
    {
      field: "uploadLimit",
      headerName: "Upload Limit",
      width: 300,
    },
    {
      field: "status",
      headerName: "status",
      width: 300,
    },
  ];
  return (
    <Card mb={6}>
      <CardHeader
        action={
          <Toolbar
            addPage={addPage}
            downloadFile={downloadFile}
            openDownloadDialog={openDownloadDialog}
          />
        }
        title={trans("plan_list")}
        subheader={breadCrump()}
      />
      <Paper>
        <div style={{ height: height - 180, width: "100%" }}>
          <DataGrid
            columns={columns}
            rows={data}
            rowCount={rowCount}
            getRowId={(row) => row[id]}
            loading={isLoading}
            rowsPerPageOptions={[5]}
            pagination
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMode="server"
            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageChange(pageSize)}
            checkboxSelection
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        </div>
      </Paper>
    </Card>
  );
};
CustomDataGrid.propTypes = {
  trans: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  paging: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSortModelChange: PropTypes.func.isRequired,
  sortModel: PropTypes.object.isRequired,
};
export default CustomDataGrid;
