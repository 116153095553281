export const PlanHistoryConstants = {
  FETCH_PLAN_HISTORY_REQUEST: "FETCH_PLAN_HISTORY_REQUEST",
  FETCH_PLAN_HISTORY_SUCCESS: "FETCH_PLAN_HISTORY_SUCCESS",
  FETCH_PLAN_HISTORY_FAILURE: "FETCH_PLAN_HISTORY_FAILURE",
  ADD_PLAN_HISTORY_REQUEST: "ADD_PLAN_HISTORY_REQUEST",
  ADD_PLAN_HISTORY_SUCCESS: "ADD_PLAN_HISTORY_SUCCESS",
  ADD_PLAN_HISTORY_FAILURE: "ADD_PLAN_HISTORY_FAILURE",
  PLAN_HISTORY_SEARCH_REQUEST: "PLAN_HISTORY_SEARCH_REQUEST",
  PLAN_HISTORY_SEARCH_SUCCESS: "PLAN_HISTORY_SEARCH_SUCCESS",
  PLAN_HISTORY_SEARCH_FAILURE: "PLAN_HISTORY_SEARCH_FAILURE",
  PLAN_HISTORY_AUTO_SEARCH_REQUEST: "PLAN_HISTORY_AUTO_SEARCH_REQUEST",
  PLAN_HISTORY_AUTO_SEARCH_SUCCESS: "PLAN_HISTORY_AUTO_SEARCH_SUCCESS",
  PLAN_HISTORY_AUTO_SEARCH_FAILURE: "PLAN_HISTORY_AUTO_SEARCH_FAILURE",
  GET_PLAN_HISTORY_REQUEST: "GET_PLAN_HISTORY_REQUEST",
  GET_PLAN_HISTORY_SUCCESS: "GET_PLAN_HISTORY_SUCCESS",
  GET_PLAN_HISTORY_FAILURE: "GET_PLAN_HISTORY_FAILURE",
  UPDATE_PLAN_HISTORY_REQUEST: "UPDATE_PLAN_HISTORY_REQUEST",
  UPDATE_PLAN_HISTORY_SUCCESS: "UPDATE_PLAN_HISTORY_SUCCESS",
  UPDATE_PLAN_HISTORY_FAILURE: "UPDATE_PLAN_HISTORY_FAILURE",
  DOWNLOAD_PLAN_HISTORY_REQUEST: "DOWNLOAD_PLAN_HISTORY_REQUEST",
  DOWNLOAD_PLAN_HISTORY_SUCCESS: "DOWNLOAD_PLAN_HISTORY_SUCCESS",
  DOWNLOAD_PLAN_HISTORY_FAILURE: "DOWNLOAD_PLAN_HISTORY_FAILURE",
  UPLOAD_PLAN_HISTORY_REQUEST: "UPLOAD_PLAN_HISTORY_REQUEST",
  UPLOAD_PLAN_HISTORY_SUCCESS: "UPLOAD_PLAN_HISTORY_SUCCESS",
  UPLOAD_PLAN_HISTORY_FAILURE: "UPLOAD_PLAN_HISTORY_FAILURE",
};
