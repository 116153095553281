import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import MigrationReducer from "../reducers/MigrationReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { MigrationConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  hasError: false,
  errorMessage: "",
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
};

const MigrationContext = createContext({
  ...initialState,
  sampleFile: () => {},
  uploadFile: () => {},
});

function MigrationProvider({ children }) {
  const [state, dispatch] = useReducer(MigrationReducer, initialState);

  const sampleFile = async (fileType) => {
    const response = await axios.get("/radius/api/addon/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_addon." + fileType);
    dispatch({
      type: MigrationConstants.DOWNLOAD_ADDON_REQUEST,
    });
    dispatch({
      type: MigrationConstants.DOWNLOAD_ADDON_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: MigrationConstants.UPLOAD_ADDON_REQUEST,
      });
      const response = await axios({
        url: "/radius/api/addon/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: MigrationConstants.UPLOAD_ADDON_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: MigrationConstants.UPLOAD_ADDON_FAILURE,
      });
    }
  };

  return (
    <MigrationContext.Provider
      value={{
        ...state,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </MigrationContext.Provider>
  );
}

export { MigrationContext, MigrationProvider };
