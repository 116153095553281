import { useContext } from "react";

import { StateMasterContext } from "../contexts/StateMasterContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useStateMaster = () => {
  const context = useContext(StateMasterContext);

  if (!context) throw new Error("StateMasterContext must be placed within StateMasterProvider");

  return context;
};

export default useStateMaster;
