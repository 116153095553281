export const ComplaintTypeConstants = {
  FETCH_COMPLAINT_TYPE_REQUEST: "FETCH_COMPLAINT_TYPE_REQUEST",
  FETCH_COMPLAINT_TYPE_SUCCESS: "FETCH_COMPLAINT_TYPE_SUCCESS",
  FETCH_COMPLAINT_TYPE_FAILURE: "FETCH_COMPLAINT_TYPE_FAILURE",
  ADD_COMPLAINT_TYPE_REQUEST: "ADD_COMPLAINT_TYPE_REQUEST",
  ADD_COMPLAINT_TYPE_SUCCESS: "ADD_COMPLAINT_TYPE_SUCCESS",
  ADD_COMPLAINT_TYPE_FAILURE: "ADD_COMPLAINT_TYPE_FAILURE",
  COMPLAINT_TYPE_SEARCH_REQUEST: "COMPLAINT_TYPE_SEARCH_REQUEST",
  COMPLAINT_TYPE_SEARCH_SUCCESS: "COMPLAINT_TYPE_SEARCH_SUCCESS",
  COMPLAINT_TYPE_SEARCH_FAILURE: "COMPLAINT_TYPE_SEARCH_FAILURE",
  COMPLAINT_TYPE_AUTO_SEARCH_REQUEST: "COMPLAINT_TYPE_AUTO_SEARCH_REQUEST",
  COMPLAINT_TYPE_AUTO_SEARCH_SUCCESS: "COMPLAINT_TYPE_AUTO_SEARCH_SUCCESS",
  COMPLAINT_TYPE_AUTO_SEARCH_FAILURE: "COMPLAINT_TYPE_AUTO_SEARCH_FAILURE",
  GET_COMPLAINT_TYPE_REQUEST: "GET_COMPLAINT_TYPE_REQUEST",
  GET_COMPLAINT_TYPE_SUCCESS: "GET_COMPLAINT_TYPE_SUCCESS",
  GET_COMPLAINT_TYPE_FAILURE: "GET_COMPLAINT_TYPE_FAILURE",
  UPDATE_COMPLAINT_TYPE_REQUEST: "UPDATE_COMPLAINT_TYPE_REQUEST",
  UPDATE_COMPLAINT_TYPE_SUCCESS: "UPDATE_COMPLAINT_TYPE_SUCCESS",
  UPDATE_COMPLAINT_TYPE_FAILURE: "UPDATE_COMPLAINT_TYPE_FAILURE",
  DOWNLOAD_COMPLAINT_TYPE_REQUEST: "DOWNLOAD_COMPLAINT_TYPE_REQUEST",
  DOWNLOAD_COMPLAINT_TYPE_SUCCESS: "DOWNLOAD_COMPLAINT_TYPE_SUCCESS",
  DOWNLOAD_COMPLAINT_TYPE_FAILURE: "DOWNLOAD_COMPLAINT_TYPE_FAILURE",
  UPLOAD_COMPLAINT_TYPE_REQUEST: "UPLOAD_COMPLAINT_TYPE_REQUEST",
  UPLOAD_COMPLAINT_TYPE_SUCCESS: "UPLOAD_COMPLAINT_TYPE_SUCCESS",
  UPLOAD_COMPLAINT_TYPE_FAILURE: "UPLOAD_COMPLAINT_TYPE_FAILURE",
};
