import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import {
  PictureAsPdf as PictureAsPdfIcon,
  Refresh as RefreshIcon,
  Print as PrintIcon,
  FilterList as FilterListIcon,
  Add as AddIcon,
  UploadFile as UploadFileIcon,
} from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {
  IconButton,
  Icon,
  Tooltip,
  Fab as MuiFab,
  CircularProgress,
  Stack,
  Paper,
} from "@mui/material";

import Excel from "../vendor/icons/icon-excel.svg";
import Csv from "../vendor/icons/csv-file.png";
import { spacing } from "@mui/system";

const Fab = styled(MuiFab)(spacing);

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function ToolBar(props) {
  const {
    addPage,
    downloadFile,
    openDownloadDialog,
    openFilterDialog,
    refreshPage,
    openActivateDialog,
    isLoadingDownload,
    downloadFileType,
  } = props;

  console.log(isLoadingDownload, "isLoadingDownload");
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {addPage && (
        <Tooltip title={t("Add")}>
          <Fab mx={2} size="small" color="secondary" aria-label="Add" onClick={addPage}>
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
      <Tooltip title={t("Activate")}>
        <IconButton aria-label="activate" onClick={openActivateDialog}>
          <CheckIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Refresh")}>
        <IconButton aria-label="refresh" onClick={refreshPage}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Filter")}>
        <IconButton aria-label="filter" onClick={openFilterDialog}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Excel")}>
        {isLoadingDownload && downloadFileType === "xlsx" ? (
          <CircularProgress size={20} style={{ marginLeft: "10px" }} />
        ) : (
          <IconButton
            aria-label="excel"
            style={{ marginLeft: "10px" }}
            onClick={() => downloadFile("xlsx")}
          >
            <Icon>
              <img src={Excel} height={25} width={25} alt="Excel" />
            </Icon>
          </IconButton>
        )}
      </Tooltip>
      <Tooltip title={t("Csv")}>
        {isLoadingDownload && downloadFileType === "csv" ? (
          <CircularProgress size={20} style={{ marginLeft: "10px" }} />
        ) : (
          <IconButton
            aria-label="csv"
            style={{ marginLeft: "10px" }}
            onClick={() => downloadFile("csv")}
          >
            <Icon>
              <img src={Csv} height={25} width={25} alt="CSV" />
            </Icon>
          </IconButton>
        )}
      </Tooltip>
      <Tooltip title={t("Pdf")}>
        {isLoadingDownload && downloadFileType === "pdf" ? (
          <CircularProgress size={20} style={{ marginLeft: "10px" }} />
        ) : (
          <IconButton
            aria-label="pdf"
            style={{ marginLeft: "10px" }}
            onClick={() => downloadFile("pdf")}
          >
            <PictureAsPdfIcon />
          </IconButton>
        )}
      </Tooltip>
      {/* Todo Need to remove after sometime <Tooltip title={t("Upload")}>
        <IconButton aria-label="upload" style={{ marginLeft: "10px" }}>
          <UploadFileIcon onClick={openDownloadDialog} />
        </IconButton>
      </Tooltip> */}
    </React.Fragment>
  );
}

export default ToolBar;
