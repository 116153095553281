import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import PincodeReducer from "../reducers/PincodeReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { PincodeConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  states: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  pincodeSearchResults: [],
  pinCode: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  talukSearchResults: [],
  isDownload: false,
  isLoadingDownload: false,
  pincodeAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const PincodeContext = createContext({
  ...initialState,
  getAllPincode: () => {},
  addPincode: () => {},
  editPincode: () => {},
  searchPincode: () => {},
  getPincode: () => {},
  autoSearchPincode: () => {},
  updatePincode: () => {},
  downloadFile: () => {},
  uploadFile: () => {},
  sampleFile: () => {},
});

function PincodeProvider({ children }) {
  const [state, dispatch] = useReducer(PincodeReducer, initialState);

  const getAllPincode = async (payload) => {
    console.log("getAllPincode", payload);
    try {
      dispatch({
        type: PincodeConstants.FETCH_PINCODE_REQUEST,
      });
      const response = await axios.post("/master/pincode/list", payload);

      dispatch({
        type: PincodeConstants.FETCH_PINCODE_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PincodeConstants.FETCH_PINCODE_FAILURE,
      });
    }
  };
  const addPincode = async (payload) => {
    const response = await axios.post("/master/pincode/add", payload);
    dispatch({
      type: PincodeConstants.ADD_PINCODE_REQUEST,
    });
    dispatch({
      type: PincodeConstants.ADD_PINCODE_SUCCESS,
      payload: response.data,
    });
  };
  const editPincode = async (payload) => {
    console.log("133131331", payload.pincodeMappingId);
    const response = await axios.put("/master/pincode/update/" + payload.pincodeMappingId, payload);
    dispatch({
      type: PincodeConstants.EDIT_PINCODE_REQUEST,
    });
    dispatch({
      type: PincodeConstants.EDIT_PINCODE_SUCCESS,
      payload: response.data,
    });
  };
  const getPincode = async (id) => {
    console.log(id, "getPincodeaaaaaaaaaa");
    const response = await axios.get("/master/pincode/" + id);
    dispatch({
      type: PincodeConstants.GET_PINCODE_REQUEST,
    });
    dispatch({
      type: PincodeConstants.GET_PINCODE_SUCCESS,
      payload: response.data,
    });
  };
  const autoSearchPincode = async (payload) => {
    try {
      const response = await axios.post("/master/pincode/auto-search", payload);
      dispatch({
        type: PincodeConstants.PINCODE_AUTO_SEARCH_REQUEST,
      });
      dispatch({
        type: PincodeConstants.PINCODE_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PincodeConstants.PINCODE_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const updatePincode = async (payload) => {
    console.log(payload, "Upatetaluk");
    const response = await axios.put("/master/pincode/update/" + payload.pincodeId, payload);
    dispatch({
      type: PincodeConstants.UPDATE_PINCODE_REQUEST,
    });
    dispatch({
      type: PincodeConstants.UPDATE_PINCODE_SUCCESS,
      payload: response.data,
    });
  };
  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: PincodeConstants.DOWNLOAD_PINCODE_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/master/pincode/export/" + fileType, payload, {
      responseType: "blob",
    });
    //console.log(response);
    await saveFile(response.data, "pincode." + fileType);

    dispatch({
      type: PincodeConstants.DOWNLOAD_PINCODE_SUCCESS,
      payload: response.data,
    });
  };
  const sampleFile = async (fileType) => {
    const response = await axios.get("/master/pincode/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_pincode." + fileType);
    dispatch({
      type: PincodeConstants.DOWNLOAD_PINCODE_REQUEST,
    });
    dispatch({
      type: PincodeConstants.DOWNLOAD_PINCODE_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: PincodeConstants.UPLOAD_PINCODE_REQUEST,
      });
      const response = await axios({
        url: "/master/pincode/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: PincodeConstants.UPLOAD_PINCODE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: PincodeConstants.UPLOAD_PINCODE_FAILURE,
      });
    }
  };
  const searchPincode = async () => {
    console.log("qwqwwwqwwqwqwqwqw");
    try {
      const response = await axios.post("/master/pincode/search", initPayload);
      dispatch({
        type: PincodeConstants.PINCODE_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PincodeConstants.PINCODE_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PincodeConstants.PINCODE_SEARCH_FAILURE,
      });
    }
  };

  return (
    <PincodeContext.Provider
      value={{
        ...state,
        getAllPincode,
        addPincode,
        editPincode,
        getPincode,
        autoSearchPincode,
        updatePincode,
        downloadFile,
        uploadFile,
        sampleFile,
        searchPincode,
      }}
    >
      {children}
    </PincodeContext.Provider>
  );
}

export { PincodeContext, PincodeProvider };
