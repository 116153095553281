import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import Toolbar from "../../../components/Toolbar";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../../contexts/DialogContext";
import { useNavigate, useLocation } from "react-router-dom";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

import useWindowDimensions from "../../../utils/windowDimensions";
// import Details from "./Details";
import { getTimeStamp } from "../../../utils/common";
import Upload from "./Upload";
import useComplaint from "../../../hooks/useComplaint";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Complaint</Typography>
      <Typography>List</Typography>
    </Breadcrumbs>
  );
};

const ComplaintHistory = (props) => {
  const { complaintId, refreshPage } = props;

  const currentPayload = {
    filters: [
      {
        fieldName: "complaintId",
        fieldValue: complaintId,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "complaintHistoryId",
        sort: "DESC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height, width } = useWindowDimensions();
  const { width: mainContentWidth } = useMainContentDimensions();

  const { getAllComplaintHistory, complaintHistories, paging, isLoading, downloadFile } =
    useComplaint();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllComplaintHistory(payload);
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    console.log(complaintId, "complaintIdcomplaintId");
  }, [complaintId]);

  useEffect(() => {
    fetchData();
  }, [payload]);

  const exportFile = async (fileType) => {
    await downloadFile(fileType, payload);
  };
  const openDownloadDialog = () => {
    return openDialog("Upload complaint", true, <Upload key={getTimeStamp()} />);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };
  const columns = [
    {
      field: "createdOn",
      headerName: "Changed Date",
      width: 300,
    },
    {
      field: "radUser",
      headerName: "User Name",
      valueGetter: (params) => params.userName,
      width: 300,
    },
    {
      field: "assignedTo",
      headerName: "Assign To",
      valueGetter: (params) => params.employeeName,
      width: 300,
    },
    {
      field: "status",
      headerName: "Status",
      width: 300,
    },
    {
      field: "createdBy",
      headerName: "Status Changed By",
      width: 300,
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 300,
    },
  ];
  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      {" "}
      <CardHeader
        action={
          <Toolbar
            addPage={null}
            downloadFile={downloadFile}
            openDownloadDialog={openDownloadDialog}
            refreshPage={refreshPage}
          />
        }
        // title={trans("complaint_list")}
        // subheader={breadCrump()}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          columns={columns}
          rows={complaintHistories}
          getRowId={(row) => row["complaintHistoryId"]}
          rowCount={paging.totalNumberOfRecords}
          loading={false}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </CardContent>
    </Card>
  );
};

export default ComplaintHistory;
