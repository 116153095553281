export const LeadConstants = {
  FETCH_LEAD_REQUEST: "FETCH_LEAD_REQUEST",
  FETCH_LEAD_SUCCESS: "FETCH_LEAD_SUCCESS",
  FETCH_LEAD_FAILURE: "FETCH_LEAD_FAILURE",
  LEAD_ASSIGN_REQUEST: "LEAD_ASSIGN_REQUEST",
  LEAD_ASSIGN_SUCCESS: "LEAD_ASSIGN_SUCCESS",
  LEAD_ASSIGN_FAILURE: "LEAD_ASSIGN_FAILURE",
  FETCH_LEAD_HISTORY_REQUEST: "FETCH_LEAD_HISTORY_REQUEST",
  FETCH_LEAD_HISTORY_SUCCESS: "FETCH_LEAD_HISTORY_SUCCESS",
  FETCH_LEAD_HISTORY_FAILURE: "FETCH_LEAD_HISTORY_FAILURE",
  FETCH_LEAD_STATS_REQUEST: "FETCH_LEAD_STATS_REQUEST",
  FETCH_LEAD_STATS_SUCCESS: "FETCH_LEAD_STATS_SUCCESS",
  FETCH_LEAD_STATS_FAILURE: "FETCH_LEAD_STATS_FAILURE",
  ADD_LEAD_REQUEST: "ADD_LEAD_REQUEST",
  ADD_LEAD_SUCCESS: "ADD_LEAD_SUCCESS",
  ADD_LEAD_FAILURE: "ADD_LEAD_FAILURE",
  LEAD_SEARCH_REQUEST: "LEAD_SEARCH_REQUEST",
  LEAD_SEARCH_SUCCESS: "LEAD_SEARCH_SUCCESS",
  LEAD_SEARCH_FAILURE: "LEAD_SEARCH_FAILURE",
  LEAD_AUTO_SEARCH_REQUEST: "LEAD_AUTO_SEARCH_REQUEST",
  LEAD_AUTO_SEARCH_SUCCESS: "LEAD_AUTO_SEARCH_SUCCESS",
  LEAD_AUTO_SEARCH_FAILURE: "LEAD_AUTO_SEARCH_FAILURE",
  GET_LEAD_REQUEST: "GET_LEAD_REQUEST",
  GET_LEAD_SUCCESS: "GET_LEAD_SUCCESS",
  GET_LEAD_FAILURE: "GET_LEAD_FAILURE",
  GET_LEAD_HISTORY_REQUEST: "GET_LEAD_HISTORY_REQUEST",
  GET_LEAD_HISTORY_SUCCESS: "GET_LEAD_HISTORY_SUCCESS",
  GET_LEAD_HISTORY_FAILURE: "GET_LEAD_HISTORY_FAILURE",
  GET_LEAD_STATS_REQUEST: "GET_LEAD_STATS_REQUEST",
  GET_LEAD_STATS_SUCCESS: "GET_LEAD_STATS_SUCCESS",
  GET_LEAD_STATS_FAILURE: "GET_LEAD_STATS_FAILURE",
  UPDATE_LEAD_REQUEST: "UPDATE_LEAD_REQUEST",
  UPDATE_LEAD_SUCCESS: "UPDATE_LEAD_SUCCESS",
  UPDATE_LEAD_FAILURE: "UPDATE_LEAD_FAILURE",
  DOWNLOAD_LEAD_REQUEST: "DOWNLOAD_LEAD_REQUEST",
  DOWNLOAD_LEAD_SUCCESS: "DOWNLOAD_LEAD_SUCCESS",
  DOWNLOAD_LEAD_FAILURE: "DOWNLOAD_LEAD_FAILURE",
  UPLOAD_LEAD_REQUEST: "UPLOAD_LEAD_REQUEST",
  UPLOAD_LEAD_SUCCESS: "UPLOAD_LEAD_SUCCESS",
  UPLOAD_LEAD_FAILURE: "UPLOAD_LEAD_FAILURE",
};
