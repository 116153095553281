import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { NasGroupProvider } from "../contexts/NasGroupContext";
import { NasProvider } from "../contexts/NasContext";
import { DialogProvider } from "../contexts/DialogContext";
import { StateMasterProvider } from "../contexts/StateMasterContext";

export const NasGroupComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <NasGroupProvider key={0} />,
        <NasProvider key={1} />,
        <StateMasterProvider key={2} />,
        <DialogProvider key={3} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
