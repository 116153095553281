import { useContext } from "react";

import { CountryContext } from "../contexts/CountryContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useCountry = () => {
  const context = useContext(CountryContext);

  if (!context) throw new Error("CountryContext must be placed within CountryProvider");

  return context;
};

export default useCountry;
