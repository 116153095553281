import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";

import SaveCancel from "../../../components/SaveCancel";
import usePppProfile from "../../../hooks/usePppProfile";
import PppProfileFields from "./components/PppProfileFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  nas: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  mkIpPool: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  localAddress: Yup.string().required("Required"),
});

function EditPppProfile() {
  const { getPppProfile, pppProfile, updatePppProfile, isSubmitting } = usePppProfile();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Object.keys(pppProfile).length !== 0) {
      console.log(pppProfile.status, "pppProfile.status");
      console.log(pppProfile.nas, "naassnass1111111");
      const initialValues = {
        pppProfileId: pppProfile.pppProfileId,
        nas: {
          value: pppProfile.nas.nasId,
          label: pppProfile.nas.nasName,
        },
        mkIpPool: {
          value: pppProfile.mkIpPool.mkIpPoolId,
          label: pppProfile.mkIpPool.name,
        },
        name: pppProfile.name,
        localAddress: pppProfile.localAddress,
        status: pppProfile.status,
      };
      reset(initialValues);
    }
  }, [pppProfile]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const { state } = useLocation();
  console.log(state, "location");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      data["nas"] = {
        nasId: data.nas.value,
        nasName: data.nas.label,
      };
      data["mkIpPool"] = {
        name: data.mkIpPool.label,
        mkIpPoolId: data.mkIpPool.value,
      };
      await updatePppProfile(data);
      navigate("/mikrotik-mgnt/ppp-profile/list");
      reset();
      dispatch(showSuccessSnackbar("PPP Profile updated Successfully!"));
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to update PPP Profile"));
    }
  };

  const cancelBtn = () => {
    navigate("mikrotik-mgnt/ppp-profile/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Mikrotik Access Mgnt</Typography>
        <Typography>PppProfile</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getPppProfile(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_pppProfile")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                PppProfile updated successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <PppProfileFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditPppProfile;
