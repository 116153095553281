import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import SmsToUserReducer from "../reducers/SmsToUserReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { SmsToUserConstants } from "../constants/smstouser.constants";
import { saveFile } from "../utils/common";
const initialState = {
  smsToUsers: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  smsToUserSearchResults: [],
  smsToUser: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,

  smsToUserAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const SmsToUserContext = createContext({
  ...initialState,
  getAllSmsToUser: () => {},
  addSmsToUser: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function SmsToUserProvider({ children }) {
  const [state, dispatch] = useReducer(SmsToUserReducer, initialState);

  const getAllSmsToUser = async (payload) => {
    try {
      dispatch({
        type: SmsToUserConstants.FETCH_SMSTOUSER_REQUEST,
      });
      const response = await axios.post("/radius/raduser/with-tenants", payload);
      dispatch({
        type: SmsToUserConstants.FETCH_SMSTOUSER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: SmsToUserConstants.FETCH_SMSTOUSER_FAILURE,
      });
    }
  };

  const addSmsToUser = async (payload) => {
    const response = await axios.post("/cpanel/tenant/add", payload);
    dispatch({
      type: SmsToUserConstants.ADD_SMSTOUSER_REQUEST,
    });
    dispatch({
      type: SmsToUserConstants.ADD_SMSTOUSER_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: SmsToUserConstants.DOWNLOAD_SMSTOUSER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("radius/raduser/send-sms" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "tenant." + fileType);

    dispatch({
      type: SmsToUserConstants.DOWNLOAD_SMSTOUSER_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <SmsToUserContext.Provider
      value={{
        ...state,
        getAllSmsToUser,
        addSmsToUser,
        downloadFile,
      }}
    >
      {children}
    </SmsToUserContext.Provider>
  );
}

export { SmsToUserContext, SmsToUserProvider };
