import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";

import SaveCancel from "../../../components/SaveCancel";
import useUser from "../../../hooks/useUser";
import UserFields from "./components/UserFields";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import PlanDetails from "./components/PlanDetails";
import BranchAndOthers from "./components/BranchAndOthers";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

import AddressDetail from "../../../components/AddressDetail";
import InstallationAddressDetail from "../../../components/InstallationAddressDetail";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const validationSchema = Yup.object().shape({
  tenant: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  branch: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  // plan: Yup.object()
  //   .shape({
  //     label: Yup.string().required(),
  //     value: Yup.string().required(),
  //   })
  //   .nullable()
  //   .required(),
  // rate: Yup.object()
  //   .shape({
  //     label: Yup.string().required(),
  //     value: Yup.string().required(),
  //   })
  //   .nullable()
  //   .required(),
  // userName: Yup.string()
  //   .required("Required")
  //   .matches(/^[a-zA-Z0-9-_]+$/, "Please enter valid username"),
  mobileNo: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/, "Must be a valid phone number with up to 10 digits"),
  emailId: Yup.string("Enter email id")
    .required("Email id is required")
    .email("Enter a valid email"),
  // routerPassword: Yup.string().max(255),
  // confirmRouterPassword: Yup.string().when("routerPassword", {
  //   is: (val) => (val && val.length > 0 ? true : false),
  //   then: Yup.string().oneOf([Yup.ref("routerPassword")], "Both password need to be the same"),
  // }),
  // appPassword: Yup.string()
  //   .matches(passwordRules, {
  //     message:
  //       "Please create a stronger password, Password must contain min 8 char, " +
  //       " 1 upper case letter, 1 lower case letter, 1 numeric digit",
  //   })
  //   .required("Required"),
  // confirmAppPassword: Yup.string().when("appPassword", {
  //   is: (val) => (val && val.length > 0 ? true : false),
  //   then: Yup.string().oneOf([Yup.ref("appPassword")], "Both password need to be the same"),
  // }),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  doorNo: Yup.string().required("Required"),
  streetName: Yup.string().required("Required"),
  pinCode: Yup.string().required("Required"),
  villageName: Yup.string().required("Required"),
  townName: Yup.string().required("Required"),
  talukId: Yup.string().required("Required"),
  districtId: Yup.string().required("Required"),
  stateId: Yup.string().required("Required"),
  countryId: Yup.string().required("Required"),
  userServiceType: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function EditUser() {
  const dispatch = useDispatch();
  const { getUser, user, updateUser, isSubmitting } = useUser();

  React.useEffect(() => {
    if (Object.keys(user).length !== 0) {
      const initialValues = {
        radUserId: user.radUserId,
        mobileNo: user.mobileNo,
        emailId: user.emailId,
        firstName: user.firstName,
        lastName: user.lastName,
        gstNo: user.gstNo,
        doorNo: user.doorNo,
        streetName: user.streetName,
        pinCode: user.pinCode,
        talukId: user.talukId,
        townName: user.townName,
        districtId: user.districtId,
        stateId: user.stateId,
        countryId: user.countryId,
        villageName: user.villageName,
        tenant: {
          value: user.tenantId,
          label: user.tenantName,
        },
        branch: {
          value: user.branchId,
          label: user.branchName,
        },
        // plan: {
        //   value: user.plan?.planId,
        //   label: user.plan?.planName,
        // },
        // rate: {
        //   value: user.rate?.rateId,
        //   label: user.rate?.rateFullName,
        // },
        userServiceType: user.userServiceType == "IN" ? "INTERNET" : "COMBO",
        status: user.status,
      };
      reset(initialValues);
    }
  }, [user]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { state } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      // const rateDto = {
      //   rateId: data.rate.value,
      // };
      // const planDto = {
      //   planId: data.plan.value,
      // };

      //data["rate"] = rateDto;
      //data["plan"] = planDto;

      data["branch"] = {
        branchId: data.branch.value,
        branchName: data.branch.label,
      };
      data["tenant"] = {
        tenantId: data.tenant.value,
        tenantName: data.tenant.label,
      };
      data["country"] = {
        countryId: data.countryId,
      };
      data["district"] = {
        districtId: data.districtId,
      };
      data["state"] = {
        stateId: data.stateId,
      };
      data["taluk"] = {
        talukId: data.talukId,
      };
      await updateUser(data);
      cancelBtn();
      dispatch(showSuccessSnackbar("User updated successfully!"));
    } catch (err) {
      let messageDetails = [];
      if (err.errors != undefined) {
        let errors = err.errors;
        for (let error in errors) {
          messageDetails.push(errors[error].field + " " + errors[error].message);
        }
        dispatch(showErrorSnackbar(messageDetails.join(",")));
      } else {
        dispatch(showErrorSnackbar(err.message));
      }
    }
  };

  const cancelBtn = () => {
    navigate("/user/userlist/list");
  };

  const [value, setValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>User</Typography>
        <Typography>UserList</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getUser(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_user")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value} onChange={handleTabChange} aria-label="Company">
                    <Tab label="User Details" {...a11yProps(0)} />
                    <Tab label="Address " {...a11yProps(1)} />
                    <Tab label="Installation Address " {...a11yProps(2)} />
                    <Tab label="Branch and Others" {...a11yProps(3)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <UserFields
                      validationFields={validationSchema.fields}
                      hideFields={[
                        "userName",
                        "routerPassword",
                        "confirmRouterPassword",
                        "appPassword",
                        "confirmAppPassword",
                      ]}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <AddressDetail validationFields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <InstallationAddressDetail validationFields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <BranchAndOthers validationFields={validationSchema.fields} />
                  </TabPanel>
                </Box>
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditUser;
