export const RadUserWalletConstants = {
  FETCH_RAD_USER_WALLET_REQUEST: "FETCH_RAD_USER_WALLET_REQUEST",
  FETCH_RAD_USER_WALLET_SUCCESS: "FETCH_RAD_USER_WALLET_SUCCESS",
  FETCH_RAD_USER_WALLET_FAILURE: "FETCH_RAD_USER_WALLET_FAILURE",
  ADD_RAD_USER_WALLET_REQUEST: "ADD_RAD_USER_WALLET_REQUEST",
  ADD_RAD_USER_WALLET_SUCCESS: "ADD_RAD_USER_WALLET_SUCCESS",
  ADD_RAD_USER_WALLET_FAILURE: "ADD_RAD_USER_WALLET_FAILURE",
  RAD_USER_WALLET_SEARCH_REQUEST: "RAD_USER_WALLET_SEARCH_REQUEST",
  RAD_USER_WALLET_SEARCH_SUCCESS: "RAD_USER_WALLET_SEARCH_SUCCESS",
  RAD_USER_WALLET_SEARCH_FAILURE: "RAD_USER_WALLET_SEARCH_FAILURE",
  RAD_USER_WALLET_AUTO_SEARCH_REQUEST: "RAD_USER_WALLET_AUTO_SEARCH_REQUEST",
  RAD_USER_WALLET_AUTO_SEARCH_SUCCESS: "RAD_USER_WALLET_AUTO_SEARCH_SUCCESS",
  RAD_USER_WALLET_AUTO_SEARCH_FAILURE: "RAD_USER_WALLET_AUTO_SEARCH_FAILURE",
  GET_RAD_USER_WALLET_REQUEST: "GET_RAD_USER_WALLET_REQUEST",
  GET_RAD_USER_WALLET_SUCCESS: "GET_RAD_USER_WALLET_SUCCESS",
  GET_RAD_USER_WALLET_FAILURE: "GET_RAD_USER_WALLET_FAILURE",
  UPDATE_RAD_USER_WALLET_REQUEST: "UPDATE_RAD_USER_WALLET_REQUEST",
  UPDATE_RAD_USER_WALLET_SUCCESS: "UPDATE_RAD_USER_WALLET_SUCCESS",
  UPDATE_RAD_USER_WALLET_FAILURE: "UPDATE_RAD_USER_WALLET_FAILURE",
  DOWNLOAD_RAD_USER_WALLET_REQUEST: "DOWNLOAD_RAD_USER_WALLET_REQUEST",
  DOWNLOAD_RAD_USER_WALLET_SUCCESS: "DOWNLOAD_RAD_USER_WALLET_SUCCESS",
  DOWNLOAD_RAD_USER_WALLET_FAILURE: "DOWNLOAD_RAD_USER_WALLET_FAILURE",
  UPLOAD_RAD_USER_WALLET_REQUEST: "UPLOAD_RAD_USER_WALLET_REQUEST",
  UPLOAD_RAD_USER_WALLET_SUCCESS: "UPLOAD_RAD_USER_WALLET_SUCCESS",
  UPLOAD_RAD_USER_WALLET_FAILURE: "UPLOAD_RAD_USER_WALLET_FAILURE",
};
