import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader } from "@mui/material";

import MappingToolBar from "../../../../components/MappingToolbar";
import MapIpTvPlanModal from "./MapIpTvPlanModal";
import { useDialog } from "../../../../contexts/DialogContext";
import { getTimeStamp } from "../../../../utils/common";
import MappedIpTvPlan from "./MappedIpTvPlan";

const AllowedIpTvPlan = ({ tenantId }) => {
  const { openDialog } = useDialog();

  const openDownloadDialog = () => {
    return openDialog(
      "mapping_iptv_policy",
      "Mapping IpTv Policy",
      <MapIpTvPlanModal tenantId={tenantId} key={getTimeStamp()} isNew={true} />,
    );
  };

  return (
    <Card mb={6}>
      <CardHeader action={<MappingToolBar openDialog={openDownloadDialog} />} />
      <CardContent>
        <MappedIpTvPlan tenantId={tenantId} />
      </CardContent>
    </Card>
  );
};

export default AllowedIpTvPlan;
