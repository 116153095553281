import React, { useRef, useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
  CardContent,
  Chip,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import EditDelete from "../../../../components/EditDelete";
import Toolbar from "../../../../components/Toolbar";
import useWindowDimensions from "../../../../utils/windowDimensions";
import { useDialog } from "../../../../contexts/DialogContext";
import useMainContentDimensions from "../../../../utils/mainContentDimensions";
import { convertSecondToDays, formatBytes } from "../../../../utils/common";
import AdvanceFilter from "./AdvanceFilter";
import useAuth from "../../../../hooks/useAuth";
import useUser from "../../../../hooks/useUser";
import DisconnectUserSession from "./DisconnectUserSession";
import { getTimeStamp } from "../../../../utils/common";
import { showErrorSnackbar } from "../../../../redux/slices/snackbar";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>HMS</Typography>
      <Typography>Hotel Users</Typography>
      <Typography>List</Typography>
    </Breadcrumbs>
  );
};

const OnlineUserSession = ({ selectedSession }) => {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const currentPayload = {
    filters: [
      {
        fieldName: "userName",
        fieldValue: selectedSession[0]?.userName,
        op: "eq",
        dataType: "string",
        isJoin: false,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "userName",
        sort: "ASC",
      },
    ],
  };
  const [payload, setPayload] = useState(currentPayload);
  const { t } = useTranslation();
  const { user } = useAuth();
  const { getOnlineUser, onlineUsers, paging, isLoading, downloadFileType } = useUser();
  const { totalNumberOfRecords, hasNextPage, pageNumber, pageSize } = paging;
  const { width: mainContentWidth } = useMainContentDimensions();

  const { height, width } = useWindowDimensions();

  const { openDialog } = useDialog();

  const fetchData = async () => {
    await getOnlineUser(payload);
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "radAcctId",
      sort: "desc",
    },
  ]);

  useEffect(() => {
    fetchData();
  }, [payload]);

  useEffect(() => {
    fetchData();
  }, [payload]);

  const onPageChange = (newPaginationModel) => {
    console.log(newPaginationModel, "pagepage");
    const pagination = {
      pageNumber: newPaginationModel.page == 0 ? 1 : newPaginationModel.page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
    setPaginationModel(newPaginationModel);
  };
  const openActivateDialog = () => {};

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });

  const columns = [
    {
      field: "userName",
      headerName: "User Name",
      width: 200,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      valueGetter: (params) => moment(params).format("DD-MM-YYYY HH:mm a"),
      width: 200,
    },
    {
      field: "onlineTime",
      headerName: "Online Time",
      valueGetter: (params) => convertSecondToDays(params),
      width: 200,
    },
    {
      field: "download",
      headerName: "Downloaded",
      valueGetter: (params) => formatBytes(params),
      width: 130,
    },
    {
      field: "upload",
      headerName: "Uploaded",
      valueGetter: (params) => formatBytes(params),
      width: 130,
    },
    {
      field: "userPlan",
      headerName: "Plan Name",
      width: 200,
    },
  ];

  const openFilterDialog = () => {
    return openDialog(
      "advance_filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };
  const disconnectUserSession = () => {
    if (selectedRows.length != 0) {
      return openDialog(
        "disconnect_user_session",
        "Disconnect User Session",
        <DisconnectUserSession selectedRows={selectedRows} />,
      );
    } else {
      dispatch(showErrorSnackbar("No users selected"));
    }
  };

  const paginationMetaRef = useRef();

  // Memoize to avoid flickering when the `hasNextPage` is `undefined` during refetch
  const paginationMeta = React.useMemo(() => {
    if (hasNextPage !== undefined && paginationMetaRef.current?.hasNextPage !== hasNextPage) {
      paginationMetaRef.current = { hasNextPage };
    }
    return paginationMetaRef.current;
  }, [hasNextPage]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  return (
    <Card>
      <CardHeader
        action={
          <Toolbar
            openFilterDialog={openFilterDialog}
            isLoadingDownload={false}
            downloadFileType={downloadFileType}
            refreshPage={null}
            isEnableImport={false}
            disconnectUserSession={disconnectUserSession}
          />
        }
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          checkboxSelection
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          rowCount={totalNumberOfRecords}
          getRowId={(row) => row["radAcctId"]}
          columns={columns}
          rows={onlineUsers}
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMeta={paginationMeta}
          loading={isLoading}
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
          onPaginationModelChange={onPageChange}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          paginationModel={paginationModel}
          onRowSelectionModelChange={(newRowSelectionModel, data) => {
            console.log(data, "data..............");
            setSelectedRows(newRowSelectionModel);
          }}
        />
      </CardContent>
    </Card>
  );
};

export default OnlineUserSession;
