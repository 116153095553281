import { useContext } from "react";

import { CardSystemContext } from "../contexts/CardSystemContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useCardSystem = () => {
  const context = useContext(CardSystemContext);

  if (!context) throw new Error("CardSystemContext must be placed within CardSystemProvider");

  return context;
};

export default useCardSystem;
