export const SessionHistoryConstants = {
  FETCH_SESSION_HISTORY_REQUEST: "FETCH_SESSION_HISTORY_REQUEST",
  FETCH_SESSION_HISTORY_SUCCESS: "FETCH_SESSION_HISTORY_SUCCESS",
  FETCH_SESSION_HISTORY_FAILURE: "FETCH_SESSION_HISTORY_FAILURE",
  SESSION_HISTORY_ASSIGN_REQUEST: "SESSION_HISTORY_ASSIGN_REQUEST",
  SESSION_HISTORY_ASSIGN_SUCCESS: "SESSION_HISTORY_ASSIGN_SUCCESS",
  SESSION_HISTORY_ASSIGN_FAILURE: "SESSION_HISTORY_ASSIGN_FAILURE",
  FETCH_SESSION_HISTORY_STATS_REQUEST: "FETCH_SESSION_HISTORY_STATS_REQUEST",
  FETCH_SESSION_HISTORY_STATS_SUCCESS: "FETCH_SESSION_HISTORY_STATS_SUCCESS",
  FETCH_SESSION_HISTORY_STATS_FAILURE: "FETCH_SESSION_HISTORY_STATS_FAILURE",
  ADD_SESSION_HISTORY_REQUEST: "ADD_SESSION_HISTORY_REQUEST",
  ADD_SESSION_HISTORY_SUCCESS: "ADD_SESSION_HISTORY_SUCCESS",
  ADD_SESSION_HISTORY_FAILURE: "ADD_SESSION_HISTORY_FAILURE",
  SESSION_HISTORY_SEARCH_REQUEST: "SESSION_HISTORY_SEARCH_REQUEST",
  SESSION_HISTORY_SEARCH_SUCCESS: "SESSION_HISTORY_SEARCH_SUCCESS",
  SESSION_HISTORY_SEARCH_FAILURE: "SESSION_HISTORY_SEARCH_FAILURE",
  SESSION_HISTORY_AUTO_SEARCH_REQUEST: "SESSION_HISTORY_AUTO_SEARCH_REQUEST",
  SESSION_HISTORY_AUTO_SEARCH_SUCCESS: "SESSION_HISTORY_AUTO_SEARCH_SUCCESS",
  SESSION_HISTORY_AUTO_SEARCH_FAILURE: "SESSION_HISTORY_AUTO_SEARCH_FAILURE",
  GET_SESSION_HISTORY_REQUEST: "GET_SESSION_HISTORY_REQUEST",
  GET_SESSION_HISTORY_SUCCESS: "GET_SESSION_HISTORY_SUCCESS",
  GET_SESSION_HISTORY_FAILURE: "GET_SESSION_HISTORY_FAILURE",
  GET_SESSION_HISTORY_REQUEST: "GET_SESSION_HISTORY_REQUEST",
  GET_SESSION_HISTORY_SUCCESS: "GET_SESSION_HISTORY_SUCCESS",
  GET_SESSION_HISTORY_FAILURE: "GET_SESSION_HISTORY_FAILURE",
  GET_SESSION_HISTORY_STATS_REQUEST: "GET_SESSION_HISTORY_STATS_REQUEST",
  GET_SESSION_HISTORY_STATS_SUCCESS: "GET_SESSION_HISTORY_STATS_SUCCESS",
  GET_SESSION_HISTORY_STATS_FAILURE: "GET_SESSION_HISTORY_STATS_FAILURE",
  UPDATE_SESSION_HISTORY_REQUEST: "UPDATE_SESSION_HISTORY_REQUEST",
  UPDATE_SESSION_HISTORY_SUCCESS: "UPDATE_SESSION_HISTORY_SUCCESS",
  UPDATE_SESSION_HISTORY_FAILURE: "UPDATE_SESSION_HISTORY_FAILURE",
  DOWNLOAD_SESSION_HISTORY_REQUEST: "DOWNLOAD_SESSION_HISTORY_REQUEST",
  DOWNLOAD_SESSION_HISTORY_SUCCESS: "DOWNLOAD_SESSION_HISTORY_SUCCESS",
  DOWNLOAD_SESSION_HISTORY_FAILURE: "DOWNLOAD_SESSION_HISTORY_FAILURE",
  UPLOAD_SESSION_HISTORY_REQUEST: "UPLOAD_SESSION_HISTORY_REQUEST",
  UPLOAD_SESSION_HISTORY_SUCCESS: "UPLOAD_SESSION_HISTORY_SUCCESS",
  UPLOAD_SESSION_HISTORY_FAILURE: "UPLOAD_SESSION_HISTORY_FAILURE",
};
