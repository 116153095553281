//Masters
import CountryList from "../pages/masters/country";
import AddCountry from "../pages/masters/country/add";
import EditCountry from "../pages/masters/country/edit";
import StateList from "../pages/masters/state";
import AddState from "../pages/masters/state/add";
import EditState from "../pages/masters/state/edit";
import DistrictList from "../pages/masters/district";
import AddDistrict from "../pages/masters/district/add";
import EditDistrict from "../pages/masters/district/edit";
import TalukList from "../pages/masters/taluk";
import AddTaluk from "../pages/masters/taluk/add";
import EditTaluk from "../pages/masters/taluk/edit";
import CityList from "../pages/masters/city";
import AddCity from "../pages/masters/city/add";
import EditCity from "../pages/masters/city/edit";
import VillageList from "../pages/masters/village";
import AddVillage from "../pages/masters/village/add";
import EditVillage from "../pages/masters/village/edit";
import PincodeList from "../pages/masters/pincode";
import AddPincode from "../pages/masters/pincode/add";
import EditPincode from "../pages/masters/pincode/edit";
import EmailMasterList from "../pages/masters/emailmaster";
import AddEmailMaster from "../pages/masters/emailmaster/add";
import EditEmailMaster from "../pages/masters/emailmaster/edit";
import SmsTemplateList from "../pages/masters/smstemplate";
import AddSmsTemplate from "../pages/masters/smstemplate/add";
import EditSmsTemplate from "../pages/masters/smstemplate/edit";

//Composers
import { CountryComposer } from "../composers/CountryComposer";
import { StateMasterComposer } from "../composers/StateMasterComposer";
import { DistrictComposer } from "../composers/DistrictComposer";
import { TalukComposer } from "../composers/TalukComposer";
import { CityComposer } from "../composers/CityComposer";
import { VillageComposer } from "../composers/VillageComposer";
import { PincodeComposer } from "../composers/PincodeComposer";
import { EmailMasterComposer } from "../composers/EmailMasterComposer";
import { SmsTemplateComposer } from "../composers/SmsTemplateComposer";
import { DashboardComposer } from "../composers/DashboardComposer";

// Guards
import AuthGuard from "../components/guards/AuthGuard";
import DashboardLayout from "../layouts/Dashboard";

const MasterRoutes = {
  path: "masters",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "country",
      children: [
        {
          path: "add",
          element: (
            <CountryComposer>
              <AddCountry />
            </CountryComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <CountryComposer>
              <EditCountry />
            </CountryComposer>
          ),
        },
        {
          path: "list",
          element: (
            <CountryComposer>
              <CountryList />
            </CountryComposer>
          ),
        },
      ],
    },
    {
      path: "state",
      children: [
        {
          path: "add",
          element: (
            <StateMasterComposer>
              <AddState />
            </StateMasterComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <StateMasterComposer>
              <EditState />
            </StateMasterComposer>
          ),
        },
        {
          path: "list",
          element: (
            <StateMasterComposer>
              <StateList />
            </StateMasterComposer>
          ),
        },
      ],
    },
    {
      path: "district",
      children: [
        {
          path: "add",
          element: (
            <DistrictComposer>
              <AddDistrict />
            </DistrictComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <DistrictComposer>
              <EditDistrict />
            </DistrictComposer>
          ),
        },
        {
          path: "list",
          element: (
            <DistrictComposer>
              <DistrictList />
            </DistrictComposer>
          ),
        },
      ],
    },
    {
      path: "taluk",
      children: [
        {
          path: "add",
          element: (
            <TalukComposer>
              <AddTaluk />
            </TalukComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <TalukComposer>
              <EditTaluk />
            </TalukComposer>
          ),
        },
        {
          path: "list",
          element: (
            <TalukComposer>
              <TalukList />
            </TalukComposer>
          ),
        },
      ],
    },
    {
      path: "city",
      children: [
        {
          path: "add",
          element: (
            <CityComposer>
              <AddCity />
            </CityComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <CityComposer>
              <EditCity />
            </CityComposer>
          ),
        },
        {
          path: "list",
          element: (
            <CityComposer>
              <CityList />
            </CityComposer>
          ),
        },
      ],
    },
    {
      path: "village",
      children: [
        {
          path: "add",
          element: (
            <VillageComposer>
              <AddVillage />
            </VillageComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <VillageComposer>
              <EditVillage />
            </VillageComposer>
          ),
        },
        {
          path: "list",
          element: (
            <VillageComposer>
              <VillageList />
            </VillageComposer>
          ),
        },
      ],
    },
    {
      path: "pincode",
      children: [
        {
          path: "add",
          element: (
            <PincodeComposer>
              <AddPincode />
            </PincodeComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <PincodeComposer>
              <EditPincode />
            </PincodeComposer>
          ),
        },
        {
          path: "list",
          element: (
            <PincodeComposer>
              <PincodeList />
            </PincodeComposer>
          ),
        },
      ],
    },
    {
      path: "emailmaster",
      children: [
        {
          path: "add",
          element: (
            <EmailMasterComposer>
              <AddEmailMaster />
            </EmailMasterComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <EmailMasterComposer>
              <EditEmailMaster />
            </EmailMasterComposer>
          ),
        },
        {
          path: "list",
          element: (
            <EmailMasterComposer>
              <EmailMasterList />
            </EmailMasterComposer>
          ),
        },
      ],
    },
    {
      path: "smstemplate",
      children: [
        {
          path: "add",
          element: (
            <SmsTemplateComposer>
              <AddSmsTemplate />
            </SmsTemplateComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <SmsTemplateComposer>
              <EditSmsTemplate />
            </SmsTemplateComposer>
          ),
        },
        {
          path: "list",
          element: (
            <SmsTemplateComposer>
              <SmsTemplateList />
            </SmsTemplateComposer>
          ),
        },
      ],
    },
  ],
};

export default MasterRoutes;
