import { useContext } from "react";

import { InventoryManagementContext } from "../contexts/InventoryManagementContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useInventoryManagement = () => {
  const context = useContext(InventoryManagementContext);

  if (!context)
    throw new Error("InventoryManagementContext must be placed within InventoryManagementProvider");

  return context;
};

export default useInventoryManagement;
