import { useContext } from "react";

import { SettingContext } from "../contexts/SettingContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useSetting = () => {
  const context = useContext(SettingContext);

  if (!context) throw new Error("SettingContext must be placed within SettingProvider");

  return context;
};

export default useSetting;
