import { useContext } from "react";

import { SmsTemplateContext } from "../contexts/SmsTemplateContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useSmsTemplate = () => {
  const context = useContext(SmsTemplateContext);

  if (!context) throw new Error("SmsTemplateContext must be placed within SmsTemplateProvider");

  return context;
};

export default useSmsTemplate;
