import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { EmployeeProvider } from "../contexts/EmployeeContext";
import { BranchProvider } from "../contexts/BranchContext";
import { DialogProvider } from "../contexts/DialogContext";
import { UnpaidInvoiceProvider } from "../contexts/UnpaidInvoiceContext";

export const EmployeeComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <EmployeeProvider key={0} />,
        <BranchProvider key={1} />,
        <DialogProvider key={2} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
