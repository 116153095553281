export const IpTvConstants = {
  FETCH_IPTV_REQUEST: "FETCH_IPTV_REQUEST",
  FETCH_IPTV_SUCCESS: "FETCH_IPTV_SUCCESS",
  FETCH_IPTV_FAILURE: "FETCH_IPTV_FAILURE",
  ADD_IPTV_REQUEST: "ADD_IPTV_REQUEST",
  ADD_IPTV_SUCCESS: "ADD_IPTV_SUCCESS",
  ADD_IPTV_FAILURE: "ADD_IPTV_FAILURE",
  IPTV_SEARCH_REQUEST: "IPTV_SEARCH_REQUEST",
  IPTV_SEARCH_SUCCESS: "IPTV_SEARCH_SUCCESS",
  IPTV_SEARCH_FAILURE: "IPTV_SEARCH_FAILURE",
  IPTV_AUTO_SEARCH_REQUEST: "IPTV_AUTO_SEARCH_REQUEST",
  IPTV_AUTO_SEARCH_SUCCESS: "IPTV_AUTO_SEARCH_SUCCESS",
  IPTV_AUTO_SEARCH_FAILURE: "IPTV_AUTO_SEARCH_FAILURE",
  IPTV_POLICY_AUTO_SEARCH_REQUEST: "IPTV_POLICY_AUTO_SEARCH_REQUEST",
  IPTV_POLICY_AUTO_SEARCH_SUCCESS: "IPTV_POLICY_AUTO_SEARCH_SUCCESS",
  IPTV_POLICY_AUTO_SEARCH_FAILURE: "IPTV_POLICY_AUTO_SEARCH_FAILURE",
  GET_IPTV_REQUEST: "GET_IPTV_REQUEST",
  GET_IPTV_SUCCESS: "GET_IPTV_SUCCESS",
  GET_IPTV_FAILURE: "GET_IPTV_FAILURE",
  UPDATE_IPTV_REQUEST: "UPDATE_IPTV_REQUEST",
  UPDATE_IPTV_SUCCESS: "UPDATE_IPTV_SUCCESS",
  UPDATE_IPTV_FAILURE: "UPDATE_IPTV_FAILURE",
  DOWNLOAD_IPTV_REQUEST: "DOWNLOAD_IPTV_REQUEST",
  DOWNLOAD_IPTV_SUCCESS: "DOWNLOAD_IPTV_SUCCESS",
  DOWNLOAD_IPTV_FAILURE: "DOWNLOAD_IPTV_FAILURE",
  UPLOAD_IPTV_REQUEST: "UPLOAD_IPTV_REQUEST",
  UPLOAD_IPTV_SUCCESS: "UPLOAD_IPTV_SUCCESS",
  UPLOAD_IPTV_FAILURE: "UPLOAD_IPTV_FAILURE",
};
