export const EmailToTenantConstants = {
  FETCH_EMAILTOTENANT_REQUEST: "FETCH_EMAILTOTENANT_REQUEST",
  FETCH_EMAILTOTENANT_SUCCESS: "FETCH_EMAILTOTENANT_SUCCESS",
  FETCH_EMAILTOTENANT_FAILURE: "FETCH_EMAILTOTENANT_FAILURE",
  ADD_EMAILTOTENANT_REQUEST: "ADD_EMAILTOTENANT_REQUEST",
  ADD_EMAILTOTENANT_SUCCESS: "ADD_EMAILTOTENANT_SUCCESS",
  ADD_EMAILTOTENANT_FAILURE: "ADD_EMAILTOTENANT_FAILURE",
  EMAILTOTENANT_SEARCH_REQUEST: "EMAILTOTENANT_SEARCH_REQUEST",
  EMAILTOTENANT_SEARCH_SUCCESS: "EMAILTOTENANT_SEARCH_SUCCESS",
  EMAILTOTENANT_SEARCH_FAILURE: "EMAILTOTENANT_SEARCH_FAILURE",
  EMAILTOTENANT_AUTO_SEARCH_REQUEST: "EMAILTOTENANT_AUTO_SEARCH_REQUEST",
  EMAILTOTENANT_AUTO_SEARCH_SUCCESS: "EMAILTOTENANT_AUTO_SEARCH_SUCCESS",
  EMAILTOTENANT_AUTO_SEARCH_FAILURE: "EMAILTOTENANT_AUTO_SEARCH_FAILURE",
  GET_EMAILTOTENANT_REQUEST: "GET_EMAILTOTENANT_REQUEST",
  GET_EMAILTOTENANT_SUCCESS: "GET_EMAILTOTENANT_SUCCESS",
  GET_EMAILTOTENANT_FAILURE: "GET_EMAILTOTENANT_FAILURE",
  UPDATE_EMAILTOTENANT_REQUEST: "UPDATE_EMAILTOTENANT_REQUEST",
  UPDATE_EMAILTOTENANT_SUCCESS: "UPDATE_EMAILTOTENANT_SUCCESS",
  UPDATE_EMAILTOTENANT_FAILURE: "UPDATE_EMAILTOTENANT_FAILURE",
  DOWNLOAD_EMAILTOTENANT_REQUEST: "DOWNLOAD_EMAILTOTENANT_REQUEST",
  DOWNLOAD_EMAILTOTENANT_SUCCESS: "DOWNLOAD_EMAILTOTENANT_SUCCESS",
  DOWNLOAD_EMAILTOTENANT_FAILURE: "DOWNLOAD_EMAILTOTENANT_FAILURE",
  UPLOAD_EMAILTOTENANT_REQUEST: "UPLOAD_EMAILTOTENANT_REQUEST",
  UPLOAD_EMAILTOTENANT_SUCCESS: "UPLOAD_EMAILTOTENANT_SUCCESS",
  UPLOAD_EMAILTOTENANT_FAILURE: "UPLOAD_EMAILTOTENANT_FAILURE",
};
