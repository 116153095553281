import { AddonConstants } from "../constants/addon.constants";

const AddonReducer = (state, action) => {
  switch (action.type) {
    case AddonConstants.FETCH_ADDON_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AddonConstants.FETCH_ADDON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addOns: action.payload.data,
        paging: action.payload.paging,
      };
    case AddonConstants.FETCH_ADDON_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AddonConstants.ADDON_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AddonConstants.ADDON_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addonSearchResults: action.payload,
      };
    case AddonConstants.ADDON_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AddonConstants.ADD_ADDON_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AddonConstants.ADD_ADDON_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case AddonConstants.ADD_ADDON_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case AddonConstants.GET_ADDON_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AddonConstants.GET_ADDON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addon: action.payload,
      };
    case AddonConstants.GET_ADDON_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AddonConstants.UPDATE_ADDON_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AddonConstants.UPDATE_ADDON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addon: action.payload,
      };
    case AddonConstants.UPDATE_ADDON_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case AddonConstants.DOWNLOAD_ADDON_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case AddonConstants.DOWNLOAD_ADDON_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case AddonConstants.DOWNLOAD_ADDON_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case AddonConstants.UPLOAD_ADDON_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case AddonConstants.UPLOAD_ADDON_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case AddonConstants.UPLOAD_ADDON_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };
    case AddonConstants.ADD_ADDON_TENANT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case AddonConstants.ADD_ADDON_TENANT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case AddonConstants.ADD_ADDON_TENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case AddonConstants.FETCH_ADDON_TENANT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AddonConstants.FETCH_ADDON_TENANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addOnTenants: action.payload.data,
        paging: action.payload.paging,
      };
    case AddonConstants.FETCH_ADDON_TENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AddonConstants.ADDON_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        autoSearchLoading: true,
        hasError: false,
      };
    case AddonConstants.ADDON_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        autoSearchLoading: false,
        addOnAutoSearchResults: action.payload,
      };
    case AddonConstants.ADDON_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        autoSearchLoading: false,
      };

    default:
      return state;
  }
};

export default AddonReducer;
