import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { InventoryManagementProvider } from "../contexts/InventoryManagementContext";
import { DialogProvider } from "../contexts/DialogContext";

export const InventoryManagementComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<InventoryManagementProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};
export default InventoryManagementComposer;
