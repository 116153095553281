import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import CustomDatePicker from "../../../../components/CustomDatePicker";

import CustomTextField from "../../../../components/CustomTextField";
import { searchPayload } from "../../../../utils/common";

const PackageInfo = (props) => {
  const {
    register,
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const { validationFields } = props;

  const expirationLimit = watch("expirationLimit");

  const searchPayloadValue = searchPayload();

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item md={6}>
        <CustomTextField
          label="Download Limit(MB)"
          name="downloadLimit"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Upload Limit(MB)"
          name="uploadLimit"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Total Limit(MB)"
          name="totalLimit"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item xs={6} md={7}>
        <CustomTextField
          label="One Time Limit"
          name="rlValue"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={null}
        />
      </Grid>
      <Grid item md={3}>
        <ReactHookFormSelect
          id="rl_frame"
          name="rlFrame"
          label=""
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={null}
        >
          <MenuItem value="m">Minute(s)</MenuItem>
          <MenuItem value="h">Hour(s)</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          name="expirationLimit"
          label="Expiration"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value="validTill">Defined by valid Till</MenuItem>
          <MenuItem value="calculateValidation">Calculation from card validation</MenuItem>
        </ReactHookFormSelect>
      </Grid>

      {expirationLimit == "calculateValidation" && (
        <>
          <Grid item md={7}>
            <CustomTextField
              label="Available Time From Card Activation"
              name="cardLimit"
              type="text"
              my={2}
              control={control}
              fullWidth
              variant="outlined"
              errors={errors}
              fields={validationFields}
            />
          </Grid>
          <Grid item md={3}>
            <ReactHookFormSelect
              name="cardLimitTime"
              label=""
              control={control}
              fullWidth
              variant="outlined"
              errors={errors}
              fields={null}
            >
              <MenuItem value="m">Minute(s)</MenuItem>
              <MenuItem value="h">Hour(s)</MenuItem>
            </ReactHookFormSelect>
          </Grid>
        </>
      )}

      <Grid item md={6}>
        <CustomTextField
          label="Simultaneous Use"
          name="simultaneousUse"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
    </Grid>
  );
};

export default PackageInfo;
