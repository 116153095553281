import { IpAddressConstants } from "../constants/ipaddress.constants";

const IpAddressReducer = (state, action) => {
  switch (action.type) {
    case IpAddressConstants.FETCH_IP_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IpAddressConstants.FETCH_IP_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ipAddresses: action.payload.data,
        paging: action.payload.paging,
      };
    case IpAddressConstants.FETCH_IP_ADDRESS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IpAddressConstants.IP_ADDRESS_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IpAddressConstants.IP_ADDRESS_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ipAddressSearchResults: action.payload,
      };
    case IpAddressConstants.IP_ADDRESS_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IpAddressConstants.IP_ADDRESS_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IpAddressConstants.IP_ADDRESS_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ipAddressAutoSearchResults: action.payload,
      };
    case IpAddressConstants.IP_ADDRESS_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IpAddressConstants.ADD_IP_ADDRESS_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case IpAddressConstants.ADD_IP_ADDRESS_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case IpAddressConstants.ADD_IP_ADDRESS_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case IpAddressConstants.GET_IP_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case IpAddressConstants.GET_IP_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ipAddress: action.payload,
      };
    case IpAddressConstants.GET_IP_ADDRESS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case IpAddressConstants.UPDATE_IP_ADDRESS_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case IpAddressConstants.UPDATE_IP_ADDRESS_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case IpAddressConstants.UPDATE_IP_ADDRESS_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case IpAddressConstants.DOWNLOAD_IP_ADDRESS_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case IpAddressConstants.DOWNLOAD_IP_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case IpAddressConstants.DOWNLOAD_IP_ADDRESS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case IpAddressConstants.UPLOAD_IP_ADDRESS_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case IpAddressConstants.UPLOAD_IP_ADDRESS_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case IpAddressConstants.UPLOAD_IP_ADDRESS_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default IpAddressReducer;
