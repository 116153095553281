import { useContext } from "react";

import { ScheduleUserContext } from "../contexts/ScheduleUserContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useScheduleUser = () => {
  const context = useContext(ScheduleUserContext);

  if (!context) throw new Error("ScheduleUserContext must be placed within ScheduleUserProvider");

  return context;
};

export default useScheduleUser;
