import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import Overview from "./components/overview";
import { Grid } from "@mui/material";
import CustomDataGrid from "./components/CustomDataGrid";
import useComplaint from "../../hooks/useComplaint";
import { useDialog } from "../../contexts/DialogContext";
import { getTimeStamp } from "../../utils/common";
import Upload from "./components/Upload";

function Complaint(props) {
  const { radUserId, userName, backTo } = props;
  const { getAllComplaint, complaints, paging, isLoading, downloadFile } = useComplaint();

  const { totalNumberOfRecords, hasNextPage, pageNumber, pageSize } = paging;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "complaintId",
        sort: "ASC",
      },
    ],
  };

  useEffect(() => {
    if (radUserId != undefined) {
      const payloadValues = {
        ...currentPayload,
        filters: [
          {
            fieldName: "radUserId",
            fieldValue: radUserId,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
            attributeName: "radUser",
            entityName: "RadUserEntity",
          },
        ],
      };
      setPayload(payloadValues);
    } else {
      setPayload(currentPayload);
    }
  }, [radUserId]);

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const [payload, setPayload] = useState({});

  const fetchData = async () => {
    if (Object.keys(payload).length != 0) {
      await getAllComplaint(payload);
    }
  };

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
  };

  const onPageChange = (newPaginationModel) => {
    console.log(newPaginationModel, "pagepage");
    const pagination = {
      pageNumber: newPaginationModel.page == 0 ? 1 : newPaginationModel.page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
    setPaginationModel(newPaginationModel);
  };

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });

  const paginationMetaRef = useRef();

  // Memoize to avoid flickering when the `hasNextPage` is `undefined` during refetch
  const paginationMeta = React.useMemo(() => {
    if (hasNextPage !== undefined && paginationMetaRef.current?.hasNextPage !== hasNextPage) {
      paginationMetaRef.current = { hasNextPage };
    }
    return paginationMetaRef.current;
  }, [hasNextPage]);

  const isInitialMount = useRef(true);
  useEffect(() => {
    fetchData();
  }, [payload]);

  const exportFile = async (fileType) => {
    await downloadFile(fileType, payload);
  };
  const openDownloadDialog = () => {
    return openDialog("Upload complaint", true, <Upload key={getTimeStamp()} />);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const addcomplaint = () => {
    navigate("/complaints/add", {
      state: { radUserId: radUserId, userName: userName, backTo: backTo },
    });
  };
  const editcomplaint = (id) => {
    console.log(id);
    navigate("/complaints/edit", { state: { id: id, backTo: backTo } });
  };
  const viewDetails = (id) => {
    console.log(id);
    navigate("/complaints/details", { state: { id: id } });
  };
  const assignTo = (id) => {
    console.log(id);
    navigate("/complaints/assign", { state: { id: id } });
  };
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CustomDataGrid
            trans={t}
            addPage={addcomplaint}
            editPage={editcomplaint}
            refreshPage={refreshPage}
            downloadFile={exportFile}
            setFilterResetFunc={setFilterResetFunc}
            openDownloadDialog={openDownloadDialog}
            id="complaintId"
            isLoading={isLoading}
            sortModel={sortModel}
            handleSortModelChange={handleSortModelChange}
            viewDetails={viewDetails}
            assignTo={assignTo}
            fetchData={fetchData}
            setPayload={setPayload}
            payload={payload}
            rows={complaints}
            rowCount={totalNumberOfRecords}
            onPaginationModelChange={onPageChange}
            paginationMeta={paginationMeta}
            paging={{
              pageNumber: pageNumber,
              pageSize: pageSize,
            }}
            paginationModel={paginationModel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Complaint;
