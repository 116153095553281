import { useContext } from "react";

import { IpTvContext } from "../contexts/IpTvContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useIpTv = () => {
  const context = useContext(IpTvContext);

  if (!context) throw new Error("IpTvContext must be placed within IpTvProvider");

  return context;
};

export default useIpTv;
