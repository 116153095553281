import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import PlanReducer from "../reducers/PlanReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { PlanConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  plans: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  planSearchResults: [],
  isLoadingDownload: false,
  planAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  plan: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const PlanContext = createContext({
  ...initialState,
  getAllPlan: () => {},
  addPlan: () => {},
  searchPlan: () => {},
  autoSearchPlan: () => {},
  getPlan: () => {},
  updatePlan: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function PlanProvider({ children }) {
  const [state, dispatch] = useReducer(PlanReducer, initialState);

  const getAllPlan = async (payload) => {
    try {
      dispatch({
        type: PlanConstants.FETCH_PLAN_REQUEST,
      });
      const response = await axios.post("/radius/plan/list", payload);
      dispatch({
        type: PlanConstants.FETCH_PLAN_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: PlanConstants.FETCH_PLAN_FAILURE,
      });
    }
  };
  const searchPlan = async () => {
    try {
      const response = await axios.post("/radius/plan/search", initPayload);
      dispatch({
        type: PlanConstants.PLAN_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PlanConstants.PLAN_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PlanConstants.PLAN_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchPlan = async (payload) => {
    try {
      const response = await axios.post("/radius/plan/auto-search", payload);
      dispatch({
        type: PlanConstants.PLAN_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PlanConstants.PLAN_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PlanConstants.PLAN_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addPlan = async (payload) => {
    const response = await axios.post("/radius/plan/add", payload);
    dispatch({
      type: PlanConstants.ADD_PLAN_REQUEST,
    });
    dispatch({
      type: PlanConstants.ADD_PLAN_SUCCESS,
      payload: response.data,
    });
  };

  const getPlan = async (id) => {
    const response = await axios.get("/radius/plan/" + id);
    dispatch({
      type: PlanConstants.GET_PLAN_REQUEST,
    });
    dispatch({
      type: PlanConstants.GET_PLAN_SUCCESS,
      payload: response.data,
    });
  };

  const updatePlan = async (payload) => {
    const response = await axios.put("/radius/plan/update/" + payload.planId, payload);
    dispatch({
      type: PlanConstants.UPDATE_PLAN_REQUEST,
    });
    dispatch({
      type: PlanConstants.UPDATE_PLAN_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: PlanConstants.DOWNLOAD_PLAN_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/radius/plan/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "plan." + fileType);

    dispatch({
      type: PlanConstants.DOWNLOAD_PLAN_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/radius/plan/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_plan." + fileType);
    dispatch({
      type: PlanConstants.DOWNLOAD_PLAN_REQUEST,
    });
    dispatch({
      type: PlanConstants.DOWNLOAD_PLAN_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: PlanConstants.UPLOAD_PLAN_REQUEST,
      });
      const response = await axios({
        url: "/radius/plan/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: PlanConstants.UPLOAD_PLAN_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: PlanConstants.UPLOAD_PLAN_FAILURE,
      });
    }
  };

  return (
    <PlanContext.Provider
      value={{
        ...state,
        getAllPlan,
        addPlan,
        searchPlan,
        autoSearchPlan,
        getPlan,
        updatePlan,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
}

export { PlanContext, PlanProvider };
