import { useContext } from "react";

import { RoutesContext } from "../contexts/RoutesContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useRoutes = () => {
  const context = useContext(RoutesContext);

  if (!context) throw new Error("RoutesContext must be placed within RoutesProvider");

  return context;
};

export default useRoutes;
