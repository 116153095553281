import { TalukConstants } from "../constants/taluk.constants";

const TalukReducer = (state, action) => {
  console.log("1212121212121212", state);
  switch (action.type) {
    case TalukConstants.FETCH_TALUK_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TalukConstants.FETCH_TALUK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        taluks: action.payload.data,
        paging: action.payload.paging,
      };
    case TalukConstants.FETCH_TALUK_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TalukConstants.ADD_TALUK_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TalukConstants.ADD_TALUK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case TalukConstants.ADD_TALUK_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };
    case TalukConstants.EDIT_TALUK_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TalukConstants.EDIT_TALUK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case TalukConstants.EDIT_TALUK_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };
    case TalukConstants.TALUK_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TalukConstants.TALUK_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        talukSearchResults: action.payload,
      };
    case TalukConstants.TALUK_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case TalukConstants.TALUK_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoadingAutoSearch: true,
        hasError: false,
      };
    case TalukConstants.TALUK_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoadingAutoSearch: false,
        talukAutoSearchResults: action.payload,
      };
    case TalukConstants.TALUK_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingAutoSearch: false,
      };
    case TalukConstants.GET_TALUK_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TalukConstants.GET_TALUK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        taluk: action.payload,
      };
    case TalukConstants.GET_TALUK_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TalukConstants.UPDATE_TALUK_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TalukConstants.UPDATE_TALUK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        taluk: action.payload,
      };
    case TalukConstants.UPDATE_TALUK_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case TalukConstants.DOWNLOAD_TALUK_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case TalukConstants.DOWNLOAD_TALUK_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case TalukConstants.DOWNLOAD_TALUK_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case TalukConstants.UPLOAD_TALUK_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case TalukConstants.UPLOAD_TALUK_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case TalukConstants.UPLOAD_TALUK_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default TalukReducer;
