import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import UserReducer from "../reducers/UserReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { UserConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  users: [],
  onlineUsers: [],
  materials: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  userSearchResults: [],
  user: {
    userName: "",
    userServiceType: "",
    status: "",
    emailId: "",
    firstName: "",
    lastName: "",
    mobileNo: "",
    rate: {
      rateFullName: "",
    },
    plan: {
      planName: "",
      totalLimit: "",
    },
  },
  isUploading: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  userAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
  isRenewSubmitting: false,
  renewals: [],
  renewal: {},
  material: {},
  showPass: {},
  suspendUser: {},
  isUserDashboardLoading: true,
  dashboadUserCount: {
    totalCount: 0,
    onlineCount: 0,
    activeCount: 0,
    expireCount: 0,
    suspendedCount: 0,
  },
  userDetailsInvoice: {
    invoiceAmount: 0,
    dueAmount: 0,
    paidAmount: 0,
  },
  uploadMessage: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const UserContext = createContext({
  ...initialState,
  getAllUser: () => {},
  addUser: () => {},
  renewUser: () => {},
  addMaterial: () => {},
  suspendUser: () => {},
  getAllMaterial: () => {},
  searchUser: () => {},
  autoSearchUser: () => {},
  getUser: () => {},
  addAutoRenewal: () => {},
  getMaterial: () => {},
  getShowPassword: () => {},
  updateMaterial: () => {},
  updateUser: () => {},
  updateRouterPassword: () => {},
  updateAppPassword: () => {},
  updatePlan: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  getOnlineUser: () => {},
  getRenewal: () => {},
  getDashboardUserCount: () => {},
  getUserDetailsInvoice: () => {},
});

function UserProvider({ children }) {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const getAllUser = async (payload) => {
    try {
      dispatch({
        type: UserConstants.FETCH_USER_REQUEST,
      });
      const response = await axios.post("/radius/raduser/list", payload);
      dispatch({
        type: UserConstants.FETCH_USER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: UserConstants.FETCH_USER_FAILURE,
      });
    }
  };

  const getAllMaterial = async (payload) => {
    try {
      dispatch({
        type: UserConstants.FETCH_MATERIAL_REQUEST,
      });
      const response = await axios.post("/radius/raduser/material/list", payload);
      dispatch({
        type: UserConstants.FETCH_MATERIAL_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: UserConstants.FETCH_MATERIAL_FAILURE,
      });
    }
  };

  const getMaterial = async (id) => {
    const response = await axios.get("/radius/raduser/material/" + id);
    dispatch({
      type: UserConstants.GET_MATERIAL_REQUEST,
    });
    dispatch({
      type: UserConstants.GET_MATERIAL_SUCCESS,
      payload: response.data,
    });
  };

  const getShowPassword = async (radUserId) => {
    const response = await axios.get("/radius/raduser/show-router-password/" + radUserId);
    dispatch({
      type: UserConstants.GET_SHOW_ROUTER_PASSWORD_REQUEST,
    });
    dispatch({
      type: UserConstants.GET_SHOW_ROUTER_PASSWORD_SUCCESS,
      payload: response.data,
    });
  };

  const updateMaterial = async (payload) => {
    const response = await axios.put("/radius/raduser/material/" + payload.radUserId, payload);
    dispatch({
      type: UserConstants.UPDATE_MATERIAL_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_MATERIAL_SUCCESS,
      payload: response.data,
    });
  };

  const searchUser = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/search", payload);
      dispatch({
        type: UserConstants.USER_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: UserConstants.USER_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: UserConstants.USER_SEARCH_FAILURE,
      });
    }
  };
  const getDashboardUserCount = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/dashboard-count", payload);
      dispatch({
        type: UserConstants.USER_DASHBOARD_COUNT_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: UserConstants.USER_DASHBOARD_COUNT_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: UserConstants.USER_DASHBOARD_COUNT_FAILURE,
      });
    }
  };
  const getUserDetailsInvoice = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/user-details-invoice", payload);
      dispatch({
        type: UserConstants.USER_DETAIL_INVOICE_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: UserConstants.USER_DETAIL_INVOICE_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: UserConstants.USER_DETAIL_INVOICE_FAILURE,
      });
    }
  };
  const autoSearchUser = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/auto-search", payload);
      dispatch({
        type: UserConstants.USER_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: UserConstants.USER_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: UserConstants.USER_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const getOnlineUser = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/online", payload);
      dispatch({
        type: UserConstants.ONLINE_USER_REQUEST,
      });
      dispatch({
        type: UserConstants.ONLINE_USER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: UserConstants.ONLINE_USER_FAILURE,
      });
    }
  };
  const addUser = async (payload) => {
    const response = await axios.post("/radius/raduser/add", payload);
    dispatch({
      type: UserConstants.RENEW_USER_REQUEST,
    });
    dispatch({
      type: UserConstants.RENEW_USER_SUCCESS,
      payload: response.data,
    });
  };

  const addMaterial = async (payload) => {
    const response = await axios.post("/radius/raduser/material/add", payload);
    dispatch({
      type: UserConstants.ADD_MATERIAL_REQUEST,
    });
    dispatch({
      type: UserConstants.ADD_MATERIAL_SUCCESS,
      payload: response.data,
    });
  };

  const renewUser = async (payload) => {
    const response = await axios.post("/radius/raduser/renew", payload);
    dispatch({
      type: UserConstants.ADD_USER_REQUEST,
    });
    dispatch({
      type: UserConstants.ADD_USER_SUCCESS,
      payload: response.data,
    });
  };

  const getUser = async (id) => {
    const response = await axios.get("/radius/raduser/" + id);
    dispatch({
      type: UserConstants.GET_USER_REQUEST,
    });
    dispatch({
      type: UserConstants.GET_USER_SUCCESS,
      payload: response.data,
    });
    return response.data;
  };

  const getRenewal = async (id) => {
    const response = await axios.get("/radius/raduser/renewal/" + id);
    dispatch({
      type: UserConstants.GET_RENEWAL_REQUEST,
    });
    dispatch({
      type: UserConstants.GET_RENEWAL_SUCCESS,
      payload: response.data,
    });
  };

  const updateUser = async (payload) => {
    const response = await axios.put("/radius/raduser/update/" + payload.radUserId, payload);
    dispatch({
      type: UserConstants.UPDATE_USER_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  };

  const suspendUser = async (payload) => {
    const response = await axios.put("/radius/raduser/suspend-user/" + payload.radUserId, payload);
    dispatch({
      type: UserConstants.UPDATE_SUSPEND_USER_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_SUSPEND_USER_SUCCESS,
      payload: response.data,
    });
  };

  const updateRouterPassword = async (payload) => {
    const response = await axios.put("/radius/raduser/change-router-password", payload);
    dispatch({
      type: UserConstants.UPDATE_PWD_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_PWD_SUCCESS,
      payload: response.data,
    });
  };

  const addAutoRenewal = async (payload) => {
    const response = await axios.post("/radius/raduser/enable-auto-renewal", payload);
    dispatch({
      type: UserConstants.ADD_AUT0_RENEWAL_REQUEST,
    });
    dispatch({
      type: UserConstants.ADD_AUT0_RENEWAL_SUCCESS,
      payload: response.data,
    });
  };

  const updateAppPassword = async (payload) => {
    const response = await axios.put("/radius/raduser/change-app-password", payload);
    dispatch({
      type: UserConstants.UPDATE_PWD_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_PWD_SUCCESS,
      payload: response.data,
    });
  };

  const updatePlan = async (payload) => {
    const response = await axios.put("/radius/raduser/change-plan", payload);
    dispatch({
      type: UserConstants.UPDATE_PLAN_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_PLAN_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: UserConstants.DOWNLOAD_USER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/radius/raduser/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "user." + fileType);
    dispatch({
      type: UserConstants.DOWNLOAD_USER_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/radius/raduser/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_user." + fileType);
    dispatch({
      type: UserConstants.DOWNLOAD_USER_REQUEST,
    });
    dispatch({
      type: UserConstants.DOWNLOAD_USER_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: UserConstants.UPLOAD_USER_REQUEST,
      });
      const response = await axios({
        url: "/radius/raduser/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: UserConstants.UPLOAD_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: UserConstants.UPLOAD_USER_FAILURE,
        payload: error.message,
      });
    }
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        getAllUser,
        addUser,
        renewUser,
        addMaterial,
        getAllMaterial,
        searchUser,
        autoSearchUser,
        getUser,
        addAutoRenewal,
        updateUser,
        updateRouterPassword,
        updateAppPassword,
        updatePlan,
        downloadFile,
        getMaterial,
        getShowPassword,
        updateMaterial,
        suspendUser,
        sampleFile,
        uploadFile,
        getOnlineUser,
        getRenewal,
        getDashboardUserCount,
        getUserDetailsInvoice,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };
