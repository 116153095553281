import { AgreementTrackerConstants } from "../constants/agreementtracker.constants";
import IspAgreementReceived from "../pages/agreement_tracker/components/IspAgreementReceived";

const AgreementTrackerReducer = (state, action) => {
  switch (action.type) {
    case AgreementTrackerConstants.FETCH_AGREEMENT_TRACKER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AgreementTrackerConstants.FETCH_AGREEMENT_TRACKER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        agreementTrackers: action.payload.data,
        paging: action.payload.paging,
      };
    case AgreementTrackerConstants.FETCH_AGREEMENT_TRACKER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case AgreementTrackerConstants.UPDATE_AGREEMENT_RECEIVED_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case AgreementTrackerConstants.UPDATE_AGREEMENT_RECEIVED_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        agreementReceiver: action.payload,
      };
    case AgreementTrackerConstants.UPDATE_AGREEMENT_RECEIVED_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case AgreementTrackerConstants.UPDATE_SIGNED_AGREEMENT_RECEIVED_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case AgreementTrackerConstants.UPDATE_SIGNED_AGREEMENT_RECEIVED_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        signedAgreementReceiver: action.payload,
      };
    case AgreementTrackerConstants.UPDATE_SIGNED_AGREEMENT_RECEIVED_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case AgreementTrackerConstants.UPDATE_COURIER_VERIFIED_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case AgreementTrackerConstants.UPDATE_COURIER_VERIFIED_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        courierVerified: action.payload,
      };
    case AgreementTrackerConstants.UPDATE_COURIER_VERIFIED_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };

    case AgreementTrackerConstants.UPDATE_ISP_AGREEMENT_RECEIVED_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case AgreementTrackerConstants.UPDATE_ISP_AGREEMENT_RECEIVED_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        IspAgreementReceived: action.payload,
      };
    case AgreementTrackerConstants.UPDATE_ISP_AGREEMENT_RECEIVED_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };

    case AgreementTrackerConstants.UPDATE_AGREEMENT_DISPATCHED_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case AgreementTrackerConstants.UPDATE_AGREEMENT_DISPATCHED_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        agreementDispatched: action.payload,
      };
    case AgreementTrackerConstants.UPDATE_AGREEMENT_DISPATCHED_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };

    case AgreementTrackerConstants.UPDATE_IDS_AGREEMENT_DISPATCHED_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case AgreementTrackerConstants.UPDATE_IDS_AGREEMENT_DISPATCHED_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        idsAgreementDispatched: action.payload,
      };
    case AgreementTrackerConstants.UPDATE_IDS_AGREEMENT_DISPATCHED_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };

    case AgreementTrackerConstants.FETCH_AGREEMENT_TRACKER_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AgreementTrackerConstants.FETCH_AGREEMENT_TRACKER_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        agreementTrackerHistories: action.payload.data,
        paging: action.payload.paging,
      };
    case AgreementTrackerConstants.FETCH_AGREEMENT_TRACKER_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AgreementTrackerConstants.AGREEMENT_TRACKER_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AgreementTrackerConstants.AGREEMENT_TRACKER_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        agreementTrackerSearchResults: action.payload,
      };
    case AgreementTrackerConstants.AGREEMENT_TRACKER_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AgreementTrackerConstants.AGREEMENT_TRACKER_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AgreementTrackerConstants.AGREEMENT_TRACKER_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        agreementTrackerAutoSearchResults: action.payload,
      };
    case AgreementTrackerConstants.AGREEMENT_TRACKER_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AgreementTrackerConstants.ADD_AGREEMENT_TRACKER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case AgreementTrackerConstants.ADD_AGREEMENT_TRACKER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case AgreementTrackerConstants.ADD_AGREEMENT_TRACKER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case AgreementTrackerConstants.GET_AGREEMENT_TRACKER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AgreementTrackerConstants.GET_AGREEMENT_TRACKER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        agreementTracker: action.payload,
      };
    case AgreementTrackerConstants.GET_AGREEMENT_TRACKER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AgreementTrackerConstants.UPDATE_AGREEMENT_TRACKER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case AgreementTrackerConstants.UPDATE_AGREEMENT_TRACKER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        agreementTracker: action.payload,
      };
    case AgreementTrackerConstants.UPDATE_AGREEMENT_TRACKER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case AgreementTrackerConstants.DOWNLOAD_AGREEMENT_TRACKER_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case AgreementTrackerConstants.DOWNLOAD_AGREEMENT_TRACKER_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case AgreementTrackerConstants.DOWNLOAD_AGREEMENT_TRACKER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case AgreementTrackerConstants.UPLOAD_AGREEMENT_TRACKER_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case AgreementTrackerConstants.UPLOAD_AGREEMENT_TRACKER_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case AgreementTrackerConstants.UPLOAD_AGREEMENT_TRACKER_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default AgreementTrackerReducer;
