//Users
import EmailLogComposer from "../composers/EmailLogComposer.js";
import SmsLogComposer from "../composers/SmsLogComposer.js";

//Composers
import { DashboardComposer } from "../composers/DashboardComposer.js";
// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
import EmailLog from "../pages/logs/email_logs/index.js";
import SmsLog from "../pages/logs/sms_logs/index.js";
import LoginHistoryComposer from "../composers/LoginHistoryComposer.js";
import LoginHistory from "../pages/logs/login_history/index.js";
import UserActivityComposer from "../composers/UserActivityComposer.js";
import UserActivity from "../pages/logs/user_activities/index.js";

// import LeadClosed from "../pages/lead/leadclosed.js";

const LogRoutes = {
  path: "logs",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "email",
      element: (
        <EmailLogComposer>
          <EmailLog />
        </EmailLogComposer>
      ),
    },
    {
      path: "sms",
      element: (
        <SmsLogComposer>
          <SmsLog />
        </SmsLogComposer>
      ),
    },
    {
      path: "login-history",
      element: (
        <LoginHistoryComposer>
          <LoginHistory />
        </LoginHistoryComposer>
      ),
    },
    {
      path: "user-activities",
      element: (
        <UserActivityComposer>
          <UserActivity />
        </UserActivityComposer>
      ),
    },
  ],
};

export default LogRoutes;
