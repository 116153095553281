import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { SmsLogProvider } from "../contexts/SmsLogContext";
import { DialogProvider } from "../contexts/DialogContext";

export const SmsLogComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<SmsLogProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};

export default SmsLogComposer;
