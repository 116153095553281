import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowUpward, MoreVert, TrendingUp } from "@mui/icons-material";

import {
  Grid,
  IconButton,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";

import { spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";
import { a11yProps } from "../../../utils/common";
import Actions from "./Actions";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import DoughnutChart from "./DoughnutChart";
import Stats from "./Stats";
import Table from "./Table";
import useDashboard from "../../../hooks/useDashboard";
import useAuth from "../../../hooks/useAuth";
import useTenant from "../../../hooks/useTenant";
import UserDashboard from "../../user_dashboard";
import DashboardDataGridWidget from "./DashboardDataGridWidget";
import useDailyUser from "../../../hooks/useDailyUser";

import {
  ButtonBase,
  Button,
  Tabs,
  CardHeader,
  CardMedia,
  Tab,
  Box,
  Paper,
  Avatar,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import TabPanel from "../../../components/TabPanel";
import Today from "./user_expiring/today";
import Yesterday from "./user_expiring/yesterday";
import Tomorrow from "./user_expiring/tomorrow";
import Next from "./user_expiring/next";
import useMainContentDimensions from "../../../utils/mainContentDimensions";
import DashboardTable from "./Table";
import MyBarChart from "./DashboardChart";
import BarCharts from "./DashboardChart";
import AED from "./AgreeExpDet";
import BalanceSheet from "./BalanceSheet";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const useTabStyles = styled({
  root: {
    justifyContent: "center",
    width: "1000px",
  },
  scroller: {
    flexGrow: "0",
  },
});

function Default() {
  const classes = useTabStyles();
  const { user } = useAuth();
  const { getTenant, tenant } = useTenant();
  const { getAllDashboardUser, dashboardUsers } = useDailyUser();
  const { t } = useTranslation();

  const { width: mainContentWidth } = useMainContentDimensions();

  const [payload, setPayload] = useState(currentPayload);

  const { getDashboardUserCount, dashboadUserCount, isUserDashboardLoading } = useDashboard();

  const fetchDashboardData = async () => {
    await getDashboardUserCount(payload);
  };

  const fetchTenantData = async () => {
    await getTenant(user.tenantId);
  };

  console.log(dashboardUsers, "onloiiinne");

  const [fullName, setFullName] = useState("");

  const [walletBalance, setWalletBalance] = useState(0.0);

  const greetMessage = () => {
    const myDate = new Date();
    const hrs = myDate.getHours();

    let greet;

    if (hrs < 12) greet = "Good Morning";
    else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
    else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";
    return greet;
  };

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (user) {
      setFullName(user.name);
      fetchDashboardData();
    }
    if (user && user.businessType != "SUPER_ADMIN") {
      fetchTenantData();
    }
  }, [user]);

  useEffect(() => {
    console.log(tenant, "tenanttenant");
    if (Object.keys(tenant).length !== 0) {
      setWalletBalance(tenant.walletBalance);
    }
  }, [tenant]);

  return (
    <>
      <React.Fragment>
        <Helmet title="Dashboard" />
        <Grid justifyContent="space-between" container spacing={6}>
          <Grid item>
            <Typography variant="h3" gutterBottom>
              {t("dashboard_title")}
            </Typography>
            <Typography variant="subtitle1">
              {greetMessage()}, {fullName} !.{" "}
              <span role="img" aria-label="Waving Hand Sign">
                👋
              </span>
            </Typography>
          </Grid>

          <Grid item>
            <Actions
              walletBalance={walletBalance}
              fetchTenantData={fetchTenantData}
              fetchDashboardData={fetchDashboardData}
              loading={isUserDashboardLoading}
            />
          </Grid>
        </Grid>

        <Divider my={6} />

        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Link to="/user/userlist/list" style={{ textDecoration: "none" }}>
              <Stats
                title="Total Users"
                amount={dashboadUserCount.totalCount}
                chip="Today"
                percentagetext="+26%"
                percentagecolor={green[500]}
                illustration="/static/img/illustrations/total.png"
                bgColor="rgb(156, 39, 176)"
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Link to="/user/active" style={{ textDecoration: "none" }}>
              <Stats
                title="Active Users"
                amount={dashboadUserCount.activeCount}
                chip="Monthly"
                percentagetext="+18%"
                percentagecolor={green[500]}
                illustration="/static/img/illustrations/working.png"
                bgColor="#4782da"
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Link to="/user/online" style={{ textDecoration: "none" }}>
              <Stats
                title="Online Users"
                amount={dashboadUserCount.onlineCount}
                chip="Annual"
                percentagetext="-14%"
                percentagecolor={red[500]}
                illustration="/static/img/illustrations/online.png"
                bgColor="rgb(46, 125, 50)"
              />
            </Link>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Link to="/user/expired" style={{ textDecoration: "none" }}>
              <Stats
                title="Expired Users"
                amount={dashboadUserCount.expireCount}
                chip="Yearly"
                percentagetext="9%"
                percentagecolor={red[500]}
                illustration="/static/img/illustrations/waiting.png"
                bgColor="rgb(237, 108, 2)"
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Link to="/user/suspended" style={{ textDecoration: "none" }}>
              <Stats
                title="Suspended Users"
                amount={dashboadUserCount.suspendedCount}
                chip="Yearly"
                percentagetext="9%"
                percentagecolor={red[500]}
                illustration="/static/img/illustrations/suspend.png"
                bgColor="rgb(211, 47, 47)"
              />
            </Link>
          </Grid>
        </Grid>
        <Grid justifyContent="space-between" container spacing={6}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Card>
              <Box>
                <CardHeader title="User Expiring" />
                <Tabs
                  classes={{ scroller: classes.scroller }}
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="Company"
                  sx={{
                    justifyContent: "center",
                    width: {
                      xs: "100%", // Full width on mobile devices
                      sm: "500px", // Fixed width on small devices
                      md: "700px", // Wider on medium devices
                      lg: "900px", // Even wider on larger devices
                    },
                    minWidth: "300px", // Ensure a minimum width across all devices
                    maxWidth: "100%", // Ensure it doesn't overflow the container
                  }}
                >
                  <Tab label="Yesterday" {...a11yProps(0)} />
                  <Tab label="Today" {...a11yProps(1)} />
                  <Tab label="Tomorrow" {...a11yProps(2)} />
                  <Tab label="Next 15 days" {...a11yProps(3)} />
                </Tabs>

                {/* Tab Panels */}
                <TabPanel value={tabValue} index={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                      <Yesterday />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                      <Today />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                      <Tomorrow />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                      <Next />
                    </Grid>
                  </Grid>
                </TabPanel>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Card>
              <Box>
                <AED />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Card>
              <Box>
                <BalanceSheet />
              </Box>
            </Card>
          </Grid>
          <Grid item md={6}>
            <Card>
              <Box>
                <BarCharts />
              </Box>
            </Card>
          </Grid>
        </Grid>

        {/* <Grid container spacing={6}>
        <Grid item xs={12} lg={8}>
          <UserDashboard />
        </Grid> */}
        {/* <Grid item xs={12} lg={4}>
          <DoughnutChart />
        </Grid> */}
        {/* </Grid> */}
        {/* <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <BarChart />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Table />
        </Grid>
      </Grid> */}
      </React.Fragment>
    </>
  );
}

export default Default;
