export const PppSecretConstants = {
  FETCH_PPP_SECRET_REQUEST: "FETCH_PPP_SECRET_REQUEST",
  FETCH_PPP_SECRET_SUCCESS: "FETCH_PPP_SECRET_SUCCESS",
  FETCH_PPP_SECRET_FAILURE: "FETCH_PPP_SECRET_FAILURE",
  ADD_PPP_SECRET_REQUEST: "ADD_PPP_SECRET_REQUEST",
  ADD_PPP_SECRET_SUCCESS: "ADD_PPP_SECRET_SUCCESS",
  ADD_PPP_SECRET_FAILURE: "ADD_PPP_SECRET_FAILURE",
  PPP_SECRET_SEARCH_REQUEST: "PPP_SECRET_SEARCH_REQUEST",
  PPP_SECRET_SEARCH_SUCCESS: "PPP_SECRET_SEARCH_SUCCESS",
  PPP_SECRET_SEARCH_FAILURE: "PPP_SECRET_SEARCH_FAILURE",
  PPP_SECRET_AUTO_SEARCH_REQUEST: "PPP_SECRET_AUTO_SEARCH_REQUEST",
  PPP_SECRET_AUTO_SEARCH_SUCCESS: "PPP_SECRET_AUTO_SEARCH_SUCCESS",
  PPP_SECRET_AUTO_SEARCH_FAILURE: "PPP_SECRET_AUTO_SEARCH_FAILURE",
  GET_PPP_SECRET_REQUEST: "GET_PPP_SECRET_REQUEST",
  GET_PPP_SECRET_SUCCESS: "GET_PPP_SECRET_SUCCESS",
  GET_PPP_SECRET_FAILURE: "GET_PPP_SECRET_FAILURE",
  UPDATE_PPP_SECRET_REQUEST: "UPDATE_PPP_SECRET_REQUEST",
  UPDATE_PPP_SECRET_SUCCESS: "UPDATE_PPP_SECRET_SUCCESS",
  UPDATE_PPP_SECRET_FAILURE: "UPDATE_PPP_SECRET_FAILURE",
  DOWNLOAD_PPP_SECRET_REQUEST: "DOWNLOAD_PPP_SECRET_REQUEST",
  DOWNLOAD_PPP_SECRET_SUCCESS: "DOWNLOAD_PPP_SECRET_SUCCESS",
  DOWNLOAD_PPP_SECRET_FAILURE: "DOWNLOAD_PPP_SECRET_FAILURE",
  UPLOAD_PPP_SECRET_REQUEST: "UPLOAD_PPP_SECRET_REQUEST",
  UPLOAD_PPP_SECRET_SUCCESS: "UPLOAD_PPP_SECRET_SUCCESS",
  UPLOAD_PPP_SECRET_FAILURE: "UPLOAD_PPP_SECRET_FAILURE",
};
