import React from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
  Box,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useDialog } from "../../../contexts/DialogContext";
import useReport from "../../../hooks/useReports";

import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import AdvanceFilter from "./AdvanceFilter";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Reports</Typography>
      <Typography>Wallet</Typography>
    </Breadcrumbs>
  );
};

const CustomDataGrid = (props) => {
  const { isLoadingDownload, downloadFileType } = useReport();

  const { height, width } = useWindowDimensions();
  const {
    editPage,
    addPage,
    trans,
    data,
    rowCount,
    paging,
    onPageChange,
    payload,
    setPayload,
    setFilterResetFunc,
    isLoading,
    id,
    handleSortModelChange,
    sortModel,
    downloadFile,
    openDownloadDialog,
    refreshPage,
  } = props;
  console.log(rowCount, "loading child component");
  const { openDialog } = useDialog();

  const columns = [
    {
      field: "tenantName",
      headerName: "Tenant Name",
      type: "string",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
            }}
          >
            {cellValues.value}
          </Box>
        );
      },
    },
    {
      field: "walletType",
      headerName: "Wallet Type",
      flex: 1,
    },
  ];

  const openFilterDialog = () => {
    return openDialog(
      "advance_filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <Toolbar
            addPage={addPage}
            downloadFile={downloadFile}
            openDownloadDialog={openDownloadDialog}
            refreshPage={refreshPage}
            openFilterDialog={openFilterDialog}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
          />
        }
        title={trans("wallet_list")}
        subheader={breadCrump()}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          columns={columns}
          rows={data}
          rowCount={rowCount}
          getRowId={(row) => row[id]}
          loading={isLoading}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          rowHeight={70}
        />
      </CardContent>
    </Card>
  );
};
CustomDataGrid.propTypes = {
  editPage: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  trans: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  paging: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSortModelChange: PropTypes.func.isRequired,
  sortModel: PropTypes.object.isRequired,
};
export default CustomDataGrid;
