import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { SmsToUserProvider } from "../contexts/SmsToUserContext";
import { DialogProvider } from "../contexts/DialogContext";

export const SmsToUserComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<SmsToUserProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};

export default SmsToUserComposer;
