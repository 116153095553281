import { DashboardConstants } from "../constants/dashboard.constants";

const DashboardReducer = (state, action) => {
  switch (action.type) {
    case DashboardConstants.USER_DASHBOARD_COUNT_REQUEST:
      return {
        ...state,
        isUserDashboardLoading: true,
        hasError: false,
      };
    case DashboardConstants.USER_DASHBOARD_COUNT_SUCCESS:
      return {
        ...state,
        isUserDashboardLoading: false,
        dashboadUserCount: action.payload,
      };
    case DashboardConstants.USER_DASHBOARD_COUNT_FAILURE:
      return {
        ...state,
        hasError: true,
        isUserDashboardLoading: false,
      };

    default:
      return state;
  }
};

export default DashboardReducer;
