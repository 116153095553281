import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import ComplaintTypeReducer from "../reducers/ComplaintTypeReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { ComplaintTypeConstants } from "../constants/complainttype.constants";
import { saveFile } from "../utils/common";
const initialState = {
  complaintTypes: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  complaintTypeSearchResults: [],
  complaintType: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  complaintTypeAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const ComplaintTypeContext = createContext({
  ...initialState,
  getAllComplaintType: () => {},
  addComplaintType: () => {},
  searchComplaintType: () => {},
  autoSearchComplaintType: () => {},
  getComplaintType: () => {},
  updateComplaintType: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function ComplaintTypeProvider({ children }) {
  const [state, dispatch] = useReducer(ComplaintTypeReducer, initialState);

  const getAllComplaintType = async (payload) => {
    try {
      dispatch({
        type: ComplaintTypeConstants.FETCH_COMPLAINT_TYPE_REQUEST,
      });
      const response = await axios.post("/complaint/type/list", payload);
      dispatch({
        type: ComplaintTypeConstants.FETCH_COMPLAINT_TYPE_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: ComplaintTypeConstants.FETCH_COMPLAINT_TYPE_FAILURE,
      });
    }
  };
  const searchComplaintType = async (payload) => {
    try {
      const response = await axios.post("/complaint/type/search", payload);
      dispatch({
        type: ComplaintTypeConstants.COMPLAINT_TYPE_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: ComplaintTypeConstants.COMPLAINT_TYPE_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: ComplaintTypeConstants.COMPLAINT_TYPE_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchComplaintType = async (payload) => {
    try {
      const response = await axios.post("/complaint/type/auto-search", payload);
      dispatch({
        type: ComplaintTypeConstants.COMPLAINT_TYPE_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: ComplaintTypeConstants.COMPLAINT_TYPE_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: ComplaintTypeConstants.COMPLAINT_TYPE_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addComplaintType = async (payload) => {
    const response = await axios.post("/complaint/type/add", payload);
    dispatch({
      type: ComplaintTypeConstants.ADD_COMPLAINT_TYPE_REQUEST,
    });
    dispatch({
      type: ComplaintTypeConstants.ADD_COMPLAINT_TYPE_SUCCESS,
      payload: response.data,
    });
  };

  const getComplaintType = async (id) => {
    const response = await axios.get("/complaint/type/" + id);
    dispatch({
      type: ComplaintTypeConstants.GET_COMPLAINT_TYPE_REQUEST,
    });
    dispatch({
      type: ComplaintTypeConstants.GET_COMPLAINT_TYPE_SUCCESS,
      payload: response.data,
    });
  };

  const updateComplaintType = async (payload) => {
    const response = await axios.put("/complaint/type/update/" + payload.complaintTypeId, payload);
    dispatch({
      type: ComplaintTypeConstants.UPDATE_COMPLAINT_TYPE_REQUEST,
    });
    dispatch({
      type: ComplaintTypeConstants.UPDATE_COMPLAINT_TYPE_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: ComplaintTypeConstants.DOWNLOAD_COMPLAINT_TYPE_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/complaint/type/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "complaintType." + fileType);
    dispatch({
      type: ComplaintTypeConstants.DOWNLOAD_COMPLAINT_TYPE_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/complaint/type/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_complaintType." + fileType);
    dispatch({
      type: ComplaintTypeConstants.DOWNLOAD_COMPLAINT_TYPE_REQUEST,
    });
    dispatch({
      type: ComplaintTypeConstants.DOWNLOAD_COMPLAINT_TYPE_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: ComplaintTypeConstants.UPLOAD_COMPLAINT_TYPE_REQUEST,
      });
      const response = await axios({
        url: "/complaint/type/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: ComplaintTypeConstants.UPLOAD_COMPLAINT_TYPE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: ComplaintTypeConstants.UPLOAD_COMPLAINT_TYPE_FAILURE,
      });
    }
  };

  return (
    <ComplaintTypeContext.Provider
      value={{
        ...state,
        getAllComplaintType,
        addComplaintType,
        searchComplaintType,
        autoSearchComplaintType,
        getComplaintType,
        updateComplaintType,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </ComplaintTypeContext.Provider>
  );
}

export { ComplaintTypeContext, ComplaintTypeProvider };
