import * as React from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Stack from "@mui/material/Stack";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const SaveCancel = (props) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row-reverse" spacing={2}>
      <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
        {t("Save")}
      </Button>
      <Button variant="outlined" startIcon={<CancelIcon />} onClick={props.cancelBtn}>
        {t("Cancel")}
      </Button>
      <Button
        variant="outlined"
        startIcon={<RestartAltIcon />}
        onClick={() => {
          props.resetForm();
        }}
      >
        {t("Reset")}
      </Button>
    </Stack>
  );
};
SaveCancel.propTypes = {
  cancelBtn: PropTypes.func,
  resetForm: PropTypes.func,
};

export default SaveCancel;
