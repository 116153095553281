import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import SmsLogReducer from "../reducers/SmsLogReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { SmsLogConstants } from "../constants/smslog.constants";
import { saveFile } from "../utils/common";
const initialState = {
  smsLogs: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  smsLogSearchResults: [],
  smsLog: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  smsLogAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const SmsLogContext = createContext({
  ...initialState,
  getAllSmsLog: () => {},
  addSmsLog: () => {},
  searchSmsLog: () => {},
  autoSearchSmsLog: () => {},
  getSmsLog: () => {},
  updateSmsLog: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function SmsLogProvider({ children }) {
  const [state, dispatch] = useReducer(SmsLogReducer, initialState);

  const getAllSmsLog = async (payload) => {
    try {
      dispatch({
        type: SmsLogConstants.FETCH_SMSLOG_REQUEST,
      });
      const response = await axios.post("/logs/sms-logs/list", payload);

      dispatch({
        type: SmsLogConstants.FETCH_SMSLOG_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: SmsLogConstants.FETCH_SMSLOG_FAILURE,
      });
    }
  };
  const searchSmsLog = async (payload) => {
    try {
      const response = await axios.post("/logs/sms-logs/search", payload);
      dispatch({
        type: SmsLogConstants.SMSLOG_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: SmsLogConstants.SMSLOG_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: SmsLogConstants.SMSLOG_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchSmsLog = async (payload) => {
    try {
      const response = await axios.post("/logs/sms-logs/auto-search", payload);
      dispatch({
        type: SmsLogConstants.SMSLOG_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: SmsLogConstants.SMSLOG_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: SmsLogConstants.SMSLOG_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const getSmsLog = async (id) => {
    const response = await axios.get("/logs/sms-logs/" + id);
    dispatch({
      type: SmsLogConstants.GET_SMSLOG_REQUEST,
    });
    dispatch({
      type: SmsLogConstants.GET_SMSLOG_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: SmsLogConstants.DOWNLOAD_SMSLOG_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/logs/sms-logs/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "sms-log." + fileType);

    dispatch({
      type: SmsLogConstants.DOWNLOAD_SMSLOG_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <SmsLogContext.Provider
      value={{
        ...state,
        getAllSmsLog,
        searchSmsLog,
        autoSearchSmsLog,
        getSmsLog,
        downloadFile,
      }}
    >
      {children}
    </SmsLogContext.Provider>
  );
}

export { SmsLogContext, SmsLogProvider };
