import {
  BookOpen,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  Settings,
  Database,
  Framer,
  Aperture,
  Archive,
  Server,
} from "react-feather";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import docsSection from "./docsSection";
import elementsSection from "./elementsSection";

const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
    href: "/dashboard/default",
    // children: [
    //   {
    //     href: "/dashboard/default",
    //     title: "Default",
    //   },
    // {
    //   href: "/dashboard/analytics",
    //   title: "Analytics",
    // },
    // {
    //   href: "/dashboard/saas",
    //   title: "SaaS",
    // },
    //],
  },
  {
    href: "/radius",
    icon: Aperture,
    title: "Radius",
    children: [
      {
        href: "/radius/assigned-policy",
        title: "Assigned Plan",
      },
    ],
  },
  {
    href: "/wallet/list",
    icon: AccountBalanceWalletIcon,
    title: "Wallet",
  },
  {
    href: "/hyper c-panel",
    icon: Framer,
    title: "Hyper C-Panel",
    children: [
      // {
      //   href: "/cpanel/wallet/list",
      //   title: "Wallet",
      // },
      {
        href: "/cpanel/branch/list",
        title: "Branch",
      },
    ],
  },

  {
    href: "/user",
    icon: Users,
    title: "User",
    children: [
      {
        href: "/user/userlist/list",
        title: "List",
      },
      {
        href: "/user/online",
        title: "Online Users",
      },
      {
        href: "/user/active",
        title: "Active Users",
      },
      {
        href: "/user/expired",
        title: "Expired Users",
      },
      {
        href: "/user/suspended",
        title: "Suspended Users",
      },
      // {
      //   href: "/user/migration",
      //   title: "Migration",
      // },
      // {
      //   href: "/user/session-history",
      //   title: "Session History",
      // },
      // {
      //   href: "/user/schedule",
      //   title: "Schedule List",
      // },
    ],
  },
  // {
  //   href: "/settings",
  //   icon: Settings,
  //   title: "Settings",
  //   children: [
  //     {
  //       href: "/settings/smsapi/list",
  //       title: "SMS API",
  //     },
  //     {
  //       href: "/settings/whatsapp/list",
  //       title: "Whats App API",
  //     },
  //   ],
  // },
  {
    href: "/reports",
    icon: Sliders,
    title: "Reports",
    children: [
      {
        href: "/reports/renewal",
        title: "Renewal Report",
      },
      // {
      //   href: "/reports/dues",
      //   title: "Dues Report",
      // },
      // {
      //   href: "/reports/collection",
      //   title: "Collection Report",
      // },
      {
        href: "/reports/wallet",
        title: "Wallet Report",
      },
      {
        href: "/reports/revenueshare",
        title: "Revenue Share Report",
      },
      // {
      //   href: "/reports/paymentgateway",
      //   title: "Payment Gateway Report",
      // },
      // {
      //   href: "/reports/tenant-payment-gateway",
      //   title: "Tenant Payment Gateway Report",
      // },
      // {
      //   href: "/reports/hotel-activation",
      //   title: "Hotel Activation Report",
      // },
    ],
  },
  // {
  //   href: "/issues",
  //   icon: Archive,
  //   title: "Issues",
  //   children: [
  //     {
  //       href: "/issues/list",
  //       title: "Issue List",
  //     },
  //     {
  //       href: "/issues/type",
  //       title: "Issue Type",
  //     },
  //   ],
  // },
  {
    href: "/invoices",
    icon: CreditCard,
    title: "Invoices",
    children: [
      {
        href: "/invoices",
        title: "List",
      },
    ],
  },
  // {
  //   href: "/card-system",
  //   icon: CreditCard,
  //   title: "Card System",
  //   children: [
  //     {
  //       href: "/card-system/generate-card",
  //       title: "Generate Card",
  //     },
  //     {
  //       href: "/card-system/card-series",
  //       title: "List Card Series",
  //     },
  //     {
  //       href: "/card-system/prepaid-card",
  //       title: "List Prepaid Cards",
  //     },
  //   ],
  // },
  // {
  //   href: "/logs",
  //   icon: Server,
  //   title: "Logs",
  //   children: [
  //     {
  //       href: "/logs/email",
  //       title: "Email Logs",
  //     },
  //     {
  //       href: "/logs/sms",
  //       title: "SMS Logs",
  //     },
  //     {
  //       href: "/logs/error",
  //       title: "Error Logs",
  //     },
  //     {
  //       href: "/logs/schedule",
  //       title: "Schedule Logs",
  //     },
  //   ],
  // },
  // {
  //   href: "/pages",
  //   icon: Layout,
  //   title: "Pages",
  //   children: [
  //     {
  //       href: "/pages/profile",
  //       title: "Profile",
  //     },
  //     {
  //       href: "/pages/settings",
  //       title: "Settings",
  //     },
  //     {
  //       href: "/pages/pricing",
  //       title: "Pricing",
  //     },
  //     {
  //       href: "/pages/chat",
  //       title: "Chat",
  //     },
  //     {
  //       href: "/pages/blank",
  //       title: "Blank Page",
  //     },
  //   ],
  // },
];

// const navItems = [
//   {
//     title: "Pages",
//     pages: pagesSection,
//   },
//   {
//     title: "Elements",
//     pages: elementsSection,
//   },
//   {
//     title: "Material App",
//     pages: docsSection,
//   },
// ];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;
