export const SmsToEmployeeConstants = {
  FETCH_SMSTOEMPLOYEE_REQUEST: "FETCH_SMSTOEMPLOYEE_REQUEST",
  FETCH_SMSTOEMPLOYEE_SUCCESS: "FETCH_SMSTOEMPLOYEE_SUCCESS",
  FETCH_SMSTOEMPLOYEE_FAILURE: "FETCH_SMSTOEMPLOYEE_FAILURE",
  ADD_SMSTOEMPLOYEE_REQUEST: "ADD_SMSTOEMPLOYEE_REQUEST",
  ADD_SMSTOEMPLOYEE_SUCCESS: "ADD_SMSTOEMPLOYEE_SUCCESS",
  ADD_SMSTOEMPLOYEE_FAILURE: "ADD_SMSTOEMPLOYEE_FAILURE",
  SMSTOEMPLOYEE_SEARCH_REQUEST: "SMSTOEMPLOYEE_SEARCH_REQUEST",
  SMSTOEMPLOYEE_SEARCH_SUCCESS: "SMSTOEMPLOYEE_SEARCH_SUCCESS",
  SMSTOEMPLOYEE_SEARCH_FAILURE: "SMSTOEMPLOYEE_SEARCH_FAILURE",
  SMSTOEMPLOYEE_AUTO_SEARCH_REQUEST: "SMSTOEMPLOYEE_AUTO_SEARCH_REQUEST",
  SMSTOEMPLOYEE_AUTO_SEARCH_SUCCESS: "SMSTOEMPLOYEE_AUTO_SEARCH_SUCCESS",
  SMSTOEMPLOYEE_AUTO_SEARCH_FAILURE: "SMSTOEMPLOYEE_AUTO_SEARCH_FAILURE",
  GET_SMSTOEMPLOYEE_REQUEST: "GET_SMSTOEMPLOYEE_REQUEST",
  GET_SMSTOEMPLOYEE_SUCCESS: "GET_SMSTOEMPLOYEE_SUCCESS",
  GET_SMSTOEMPLOYEE_FAILURE: "GET_SMSTOEMPLOYEE_FAILURE",
  UPDATE_SMSTOEMPLOYEE_REQUEST: "UPDATE_SMSTOEMPLOYEE_REQUEST",
  UPDATE_SMSTOEMPLOYEE_SUCCESS: "UPDATE_SMSTOEMPLOYEE_SUCCESS",
  UPDATE_SMSTOEMPLOYEE_FAILURE: "UPDATE_SMSTOEMPLOYEE_FAILURE",
  DOWNLOAD_SMSTOEMPLOYEE_REQUEST: "DOWNLOAD_SMSTOEMPLOYEE_REQUEST",
  DOWNLOAD_SMSTOEMPLOYEE_SUCCESS: "DOWNLOAD_SMSTOEMPLOYEE_SUCCESS",
  DOWNLOAD_SMSTOEMPLOYEE_FAILURE: "DOWNLOAD_SMSTOEMPLOYEE_FAILURE",
  UPLOAD_SMSTOEMPLOYEE_REQUEST: "UPLOAD_SMSTOEMPLOYEE_REQUEST",
  UPLOAD_SMSTOEMPLOYEE_SUCCESS: "UPLOAD_SMSTOEMPLOYEE_SUCCESS",
  UPLOAD_SMSTOEMPLOYEE_FAILURE: "UPLOAD_SMSTOEMPLOYEE_FAILURE",
};
