import { useContext } from "react";

import { PppServerContext } from "../contexts/PppServerContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const usePppServer = () => {
  const context = useContext(PppServerContext);

  if (!context) throw new Error("PppServerContext must be placed within PppServerProvider");

  return context;
};

export default usePppServer;
