import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Alert as MuiAlert,
  Button,
  DialogActions,
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useDialog } from "../../../../contexts/DialogContext";
import usePlan from "../../../../hooks/usePlan";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import usePlanGroup from "../../../../hooks/usePlanGroup";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import CustomTextField from "../../../../components/CustomTextField";
import CustomCheckBox from "../../../../components/CustomCheckBox";

const initialValues = {
  planName: [],
  msoSharing: 0,
  distSharing: 0,
  lcoSharing: 0,
  agrSharing: 0,
  isSharingAllowed: true,
};

const validationSchema = Yup.object().shape({
  planName: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.number().required(),
      }),
    )
    .min(1, "You need at least one plan")
    .required("Please select the plan"),
  msoSharing: Yup.number().when("isSharingAllowed", {
    is: true,
    then: () =>
      Yup.number()
        .typeError("MSO sharing must be a number")
        .min(0, "Minimum value should be 0")
        .max(100, "Maximum value should be 100")
        .required(),
  }),
  distSharing: Yup.number().when("isSharingAllowed", {
    is: true,
    then: () =>
      Yup.number()
        .typeError("Dist sharing must be a number")
        .min(0, "Minimum value should be 0")
        .max(100, "Maximum value should be 100")
        .required(),
  }),
  lcoSharing: Yup.number().when("isSharingAllowed", {
    is: true,
    then: () =>
      Yup.number()
        .typeError("LCO sharing must be a number")
        .min(0, "Minimum value should be 0")
        .max(100, "Maximum value should be 100")
        .required(),
  }),
  agrSharing: Yup.number().when("isSharingAllowed", {
    is: true,
    then: () =>
      Yup.number()
        .typeError("AGR sharing must be a number")
        .min(0, "Minimum value should be 0")
        .max(8, "Maximum value should be 8")
        .required(),
  }),
  // totalSharing: Yup.array().of(
  //   Yup.object()
  //     .shape({
  //       msoSharing: Yup.number()
  //         .required("First Name is required.")
  //         .min(0, "Minimum atleast 0")
  //         .max(100, "Only 5 characters allowed."),
  //     })
  //     .test({
  //       message: "The error message if length > 10",
  //       test: ({ msoSharing }) => {
  //         console.log(msoSharing, "msoSharingssss");
  //         const len = msoSharing;
  //         console.log({ len });
  //         return len <= 100;
  //       },
  //     }),
  // ),
});

const Alert = styled(MuiAlert)(spacing);

const MapPlanModal = ({ planGroupId, isNew, isSharingAllowed }) => {
  const { unSetDialog } = useDialog();

  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { autoSearchPlan, planAutoSearchResults, autoSearchLoading } = usePlan();
  const { addPlanGroupPlan, isSubmitting, getPlanGroupPlans } = usePlanGroup();
  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const [payload, setPayload] = useState(currentPayload);
  const [modalNew, setModalNew] = useState(isNew);
  const [planGroupPlanPayload, setPlanGroupPlanPayload] = useState({
    filters: [
      {
        fieldName: "planGroupId",
        fieldValue: planGroupId,
        op: "eq",
        isJoin: true,
        dataType: "long",
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  });

  const [planOptions, setPlanOptions] = useState([]);

  const fetchData = async () => {
    await autoSearchPlan(payload);
  };

  useEffect(() => {
    if (planAutoSearchResults.data.length !== 0) {
      setPlanOptions([...planOptions, ...planAutoSearchResults.data]);
    }
  }, [planAutoSearchResults]);

  useEffect(() => {
    fetchData();
  }, [payload]);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const planDetails = [];
      const plans = data.planName;
      console.log(data, "planDetails");

      // validationSchema
      //   .validate({
      //     totalSharing: [
      //       {
      //         msoSharing: data.msoSharing,
      //       },
      //     ],
      //   })
      //   .catch((err) => console.log(err.message, "ddsdsdsdsd"))
      //   .then(() => console.log("valid"));
      const totalSharing = data.msoSharing + data.distSharing + data.lcoSharing;
      if (totalSharing < 100 && isSharingAllowed) {
        dispatch(showErrorSnackbar("Sharing should be equal to 100"));
        return;
      }
      for (let i = 0; i < plans.length; i++) {
        planDetails.push({
          planGroup: {
            planGroupId: planGroupId,
          },
          planId: plans[i]["value"],
          planName: plans[i]["label"],
          msoSharing: data.msoSharing,
          distSharing: data.distSharing,
          lcoSharing: data.lcoSharing,
          agrSharing: data.agrSharing,
        });
      }

      console.log(planDetails, "planDetails");

      await addPlanGroupPlan(planDetails);
      await getPlanGroupPlans(planGroupPlanPayload);
      reset();
      dispatch(showSuccessSnackbar("Plan mapped successfully!"));
      unSetDialog("map_modal_plan");
    } catch (err) {
      console.log(err, "errerrerr");
      dispatch(showErrorSnackbar(err.message));
    }
  };

  useEffect(() => {
    console.log(planOptions, "modalNewmodalNew");
    if (modalNew) {
      setPlanOptions([]);
      setModalNew(false);
    }
  }, [modalNew]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 10 }}>
            <Grid container spacing={12} className="row" pt={8}>
              <Grid item md={12}>
                <SearchableAutoComplete
                  control={control}
                  data={planOptions}
                  label="Plan Name"
                  payload={payload}
                  setPayload={setPayload}
                  loading={autoSearchLoading}
                  name="planName"
                  multiple={true}
                />
              </Grid>
              {isSharingAllowed && (
                <Grid item md={3}>
                  <CustomTextField
                    label="MSO Sharing %"
                    name="msoSharing"
                    type="text"
                    my={2}
                    control={control}
                    fullWidth
                    variant="outlined"
                    errors={errors}
                    fields={validationSchema.fields}
                  />
                </Grid>
              )}
              {isSharingAllowed && (
                <Grid item md={3}>
                  <CustomTextField
                    label="Dist Sharing %"
                    name="distSharing"
                    type="text"
                    my={2}
                    control={control}
                    fullWidth
                    variant="outlined"
                    errors={errors}
                    fields={validationSchema.fields}
                  />
                </Grid>
              )}
              {isSharingAllowed && (
                <Grid item md={3}>
                  <CustomTextField
                    label="LCO Sharing %"
                    name="lcoSharing"
                    type="text"
                    my={2}
                    control={control}
                    fullWidth
                    variant="outlined"
                    errors={errors}
                    fields={validationSchema.fields}
                  />
                </Grid>
              )}
              {isSharingAllowed && (
                <Grid item md={3}>
                  <CustomTextField
                    label="AGR Sharing %"
                    name="agrSharing"
                    type="text"
                    my={2}
                    control={control}
                    fullWidth
                    variant="outlined"
                    errors={errors}
                    fields={validationSchema.fields}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <DialogActions>
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              endIcon={isSubmitting ? <CircularProgress size={24} /> : null}
            >
              Add
            </Button>

            <Button variant="text" onClick={() => unSetDialog("map_plan_modal")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default MapPlanModal;
