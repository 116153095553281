import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import AddonReducer from "../reducers/AddonReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { AddonConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  addOns: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  addonSearchResults: [],
  addon: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  addOnTenants: [],
  isLoading: true,
  isLoadingDownload: false,
  addOnAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const AddonContext = createContext({
  ...initialState,
  getAllAddon: () => {},
  addAddon: () => {},
  searchAddon: () => {},
  getAddon: () => {},
  updateAddon: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  addAddOnTenant: () => {},
  getAddOnTenants: () => {},
  autoSearchAddOn: () => {},
});

function AddonProvider({ children }) {
  const [state, dispatch] = useReducer(AddonReducer, initialState);

  const getAllAddon = async (payload) => {
    try {
      dispatch({
        type: AddonConstants.FETCH_ADDON_REQUEST,
      });
      const response = await axios.post("/radius/addon/list", payload);

      dispatch({
        type: AddonConstants.FETCH_ADDON_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: AddonConstants.FETCH_ADDON_FAILURE,
      });
    }
  };
  const searchAddon = async () => {
    try {
      const response = await axios.post("/radius/addon/search", initPayload);
      dispatch({
        type: AddonConstants.ADDON_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: AddonConstants.ADDON_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: AddonConstants.ADDON_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchAddOn = async (payload) => {
    try {
      const response = await axios.post("/radius/addon/auto-search", payload);
      dispatch({
        type: AddonConstants.ADDON_AUTO_SEARCH_REQUEST,
      });
      dispatch({
        type: AddonConstants.ADDON_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: AddonConstants.ADDON_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addAddon = async (payload) => {
    const response = await axios.post("/radius/addon/add", payload);
    dispatch({
      type: AddonConstants.ADD_ADDON_REQUEST,
    });
    dispatch({
      type: AddonConstants.ADD_ADDON_SUCCESS,
      payload: response.data,
    });
  };

  const getAddon = async (id) => {
    const response = await axios.get("/radius/addon/" + id);
    dispatch({
      type: AddonConstants.GET_ADDON_REQUEST,
    });
    dispatch({
      type: AddonConstants.GET_ADDON_SUCCESS,
      payload: response.data,
    });
  };
  const addAddOnTenant = async (payload) => {
    const response = await axios.post("/radius/addon/tenant/add", payload);
    dispatch({
      type: AddonConstants.ADD_ADDON_TENANT_REQUEST,
    });
    dispatch({
      type: AddonConstants.ADD_ADDON_TENANT_SUCCESS,
      payload: response.data,
    });
  };

  const getAddOnTenants = async (payload) => {
    try {
      const response = await axios.post("/radius/addon/tenant/list", payload);
      dispatch({
        type: AddonConstants.FETCH_ADDON_TENANT_REQUEST,
      });
      dispatch({
        type: AddonConstants.FETCH_ADDON_TENANT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: AddonConstants.FETCH_ADDON_TENANT_FAILURE,
      });
    }
  };

  const updateAddon = async (payload) => {
    const response = await axios.put("/radius/addon/update/" + payload.addOnId, payload);
    dispatch({
      type: AddonConstants.UPDATE_ADDON_REQUEST,
    });
    dispatch({
      type: AddonConstants.UPDATE_ADDON_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: AddonConstants.DOWNLOAD_ADDON_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/radius/addon/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "addon." + fileType);

    dispatch({
      type: AddonConstants.DOWNLOAD_ADDON_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/radius/addon/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_addon." + fileType);
    dispatch({
      type: AddonConstants.DOWNLOAD_ADDON_REQUEST,
    });
    dispatch({
      type: AddonConstants.DOWNLOAD_ADDON_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: AddonConstants.UPLOAD_ADDON_REQUEST,
      });
      const response = await axios({
        url: "/radius/addon/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: AddonConstants.UPLOAD_ADDON_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: AddonConstants.UPLOAD_ADDON_FAILURE,
      });
    }
  };

  return (
    <AddonContext.Provider
      value={{
        ...state,
        getAllAddon,
        addAddon,
        searchAddon,
        getAddon,
        updateAddon,
        downloadFile,
        sampleFile,
        uploadFile,
        addAddOnTenant,
        getAddOnTenants,
        autoSearchAddOn,
      }}
    >
      {children}
    </AddonContext.Provider>
  );
}

export { AddonContext, AddonProvider };
