import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import PlanGroupReducer from "../reducers/PlanGroupReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { PlanGroupConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  planGroups: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  planGroupSearchResults: [],
  planGroup: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  addOnTenants: [],
  isLoading: true,
  planGroupPlans: [],
  isLoadingAutoSearch: false,
  isLoadingDownload: false,
  planGroupAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoadingAutoSearch: true,
  downloadFileType: "",
  planGroupPlanAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const PlanGroupContext = createContext({
  ...initialState,
  getAllPlanGroup: () => {},
  addPlanGroup: () => {},
  addPlanGroupPlan: () => {},
  searchPlanGroup: () => {},
  getPlanGroup: () => {},
  updatePlanGroup: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  getPlanGroupPlans: () => {},
  autoSearchPlanGroup: () => {},
  autoSearchPlanGroupPlan: () => {},
});

function PlanGroupProvider({ children }) {
  const [state, dispatch] = useReducer(PlanGroupReducer, initialState);

  const getAllPlanGroup = async (payload) => {
    try {
      dispatch({
        type: PlanGroupConstants.FETCH_PLAN_GROUP_REQUEST,
      });
      const response = await axios.post("/radius/plan-group/list", payload);
      dispatch({
        type: PlanGroupConstants.FETCH_PLAN_GROUP_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: PlanGroupConstants.FETCH_PLAN_GROUP_FAILURE,
      });
    }
  };
  const getPlanGroupPlans = async (payload) => {
    try {
      const response = await axios.post("/radius/plan-group/plan/list", payload);
      dispatch({
        type: PlanGroupConstants.FETCH_PLAN_GROUP_PLAN_REQUEST,
      });
      dispatch({
        type: PlanGroupConstants.FETCH_PLAN_GROUP_PLAN_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: PlanGroupConstants.FETCH_PLAN_GROUP_PLAN_FAILURE,
      });
    }
  };
  const searchPlanGroup = async () => {
    try {
      const response = await axios.post("/radius/plan-group/search", initPayload);
      dispatch({
        type: PlanGroupConstants.PLAN_GROUP_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PlanGroupConstants.PLAN_GROUP_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PlanGroupConstants.PLAN_GROUP_SEARCH_FAILURE,
      });
    }
  };
  const addPlanGroup = async (payload) => {
    const response = await axios.post("/radius/plan-group/add", payload);
    dispatch({
      type: PlanGroupConstants.ADD_PLAN_GROUP_REQUEST,
    });
    dispatch({
      type: PlanGroupConstants.ADD_PLAN_GROUP_SUCCESS,
      payload: response.data,
    });
  };
  const autoSearchPlanGroup = async (payload) => {
    try {
      const response = await axios.post("/radius/plan-group/auto-search", payload);
      dispatch({
        type: PlanGroupConstants.PLAN_GROUP_AUTO_SEARCH_REQUEST,
      });
      dispatch({
        type: PlanGroupConstants.PLAN_GROUP_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PlanGroupConstants.PLAN_GROUP_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const autoSearchPlanGroupPlan = async (payload) => {
    try {
      const response = await axios.post("/radius/plan-group/auto-search-plan", payload);
      dispatch({
        type: PlanGroupConstants.PLAN_GROUP_PLAN_AUTO_SEARCH_REQUEST,
      });
      dispatch({
        type: PlanGroupConstants.PLAN_GROUP_PLAN_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PlanGroupConstants.PLAN_GROUP_PLAN_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const addPlanGroupPlan = async (payload) => {
    const response = await axios.post("/radius/plan-group/plan/add", payload);
    dispatch({
      type: PlanGroupConstants.ADD_PLAN_GROUP_REQUEST,
    });
    dispatch({
      type: PlanGroupConstants.ADD_PLAN_GROUP_SUCCESS,
      payload: response.data,
    });
  };

  const getPlanGroup = async (id) => {
    const response = await axios.get("/radius/plan-group/" + id);
    dispatch({
      type: PlanGroupConstants.GET_PLAN_GROUP_REQUEST,
    });
    dispatch({
      type: PlanGroupConstants.GET_PLAN_GROUP_SUCCESS,
      payload: response.data,
    });
  };

  const updatePlanGroup = async (payload) => {
    const response = await axios.put("/radius/plan-group/update/" + payload.planGroupId, payload);
    dispatch({
      type: PlanGroupConstants.UPDATE_PLAN_GROUP_REQUEST,
    });
    dispatch({
      type: PlanGroupConstants.UPDATE_PLAN_GROUP_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: PlanGroupConstants.DOWNLOAD_PLAN_GROUP_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/radius/plan-group/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "planGroup." + fileType);
    dispatch({
      type: PlanGroupConstants.DOWNLOAD_PLAN_GROUP_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/radius/plan-group/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_planGroup." + fileType);
    dispatch({
      type: PlanGroupConstants.DOWNLOAD_PLAN_GROUP_REQUEST,
    });
    dispatch({
      type: PlanGroupConstants.DOWNLOAD_PLAN_GROUP_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: PlanGroupConstants.UPLOAD_PLAN_GROUP_REQUEST,
      });
      const response = await axios({
        url: "/radius/plan-group/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: PlanGroupConstants.UPLOAD_PLAN_GROUP_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: PlanGroupConstants.UPLOAD_PLAN_GROUP_FAILURE,
      });
    }
  };

  return (
    <PlanGroupContext.Provider
      value={{
        ...state,
        getAllPlanGroup,
        addPlanGroup,
        addPlanGroupPlan,
        searchPlanGroup,
        getPlanGroup,
        updatePlanGroup,
        downloadFile,
        sampleFile,
        uploadFile,
        getPlanGroupPlans,
        autoSearchPlanGroup,
        autoSearchPlanGroupPlan,
      }}
    >
      {children}
    </PlanGroupContext.Provider>
  );
}

export { PlanGroupContext, PlanGroupProvider };
