export const RoutesConstants = {
  FETCH_ROUTES_REQUEST: "FETCH_ROUTES_REQUEST",
  FETCH_ROUTES_SUCCESS: "FETCH_ROUTES_SUCCESS",
  FETCH_ROUTES_FAILURE: "FETCH_ROUTES_FAILURE",
  ADD_ROUTES_REQUEST: "ADD_ROUTES_REQUEST",
  ADD_ROUTES_SUCCESS: "ADD_ROUTES_SUCCESS",
  ADD_ROUTES_FAILURE: "ADD_ROUTES_FAILURE",
  ROUTES_SEARCH_REQUEST: "ROUTES_SEARCH_REQUEST",
  ROUTES_SEARCH_SUCCESS: "ROUTES_SEARCH_SUCCESS",
  ROUTES_SEARCH_FAILURE: "ROUTES_SEARCH_FAILURE",
  ROUTES_AUTO_SEARCH_REQUEST: "ROUTES_AUTO_SEARCH_REQUEST",
  ROUTES_AUTO_SEARCH_SUCCESS: "ROUTES_AUTO_SEARCH_SUCCESS",
  ROUTES_AUTO_SEARCH_FAILURE: "ROUTES_AUTO_SEARCH_FAILURE",
  GET_ROUTES_REQUEST: "GET_ROUTES_REQUEST",
  GET_ROUTES_SUCCESS: "GET_ROUTES_SUCCESS",
  GET_ROUTES_FAILURE: "GET_ROUTES_FAILURE",
  UPDATE_ROUTES_REQUEST: "UPDATE_ROUTES_REQUEST",
  UPDATE_ROUTES_SUCCESS: "UPDATE_ROUTES_SUCCESS",
  UPDATE_ROUTES_FAILURE: "UPDATE_ROUTES_FAILURE",
  DOWNLOAD_ROUTES_REQUEST: "DOWNLOAD_ROUTES_REQUEST",
  DOWNLOAD_ROUTES_SUCCESS: "DOWNLOAD_ROUTES_SUCCESS",
  DOWNLOAD_ROUTES_FAILURE: "DOWNLOAD_ROUTES_FAILURE",
  UPLOAD_ROUTES_REQUEST: "UPLOAD_ROUTES_REQUEST",
  UPLOAD_ROUTES_SUCCESS: "UPLOAD_ROUTES_SUCCESS",
  UPLOAD_ROUTES_FAILURE: "UPLOAD_ROUTES_FAILURE",
};
