import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import CustomDataGrid from "./components/CustomDataGrid";
import useNas from "../../../hooks/useNas";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import Upload from "./components/Upload";

function DataGridPage() {
  const { getAllNas, nases, paging, isLoading, downloadFile } = useNas();

  const { totalNumberOfRecords, hasNextPage, pageNumber, pageSize } = paging;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const currentPayload = {
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    filters: [],
    sortFields: [
      {
        field: "shortName",
        sort: "ASC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "shortName",
      sort: "asc",
    },
  ]);

  const [payload, setPayload] = useState(currentPayload);

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const fetchData = async () => {
    await getAllNas(payload);
  };

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
  };

  const onPageChange = (newPaginationModel) => {
    console.log(newPaginationModel, "pagepage");
    const pagination = {
      pageNumber: newPaginationModel.page == 0 ? 1 : newPaginationModel.page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
    setPaginationModel(newPaginationModel);
  };

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });

  const paginationMetaRef = useRef();

  // Memoize to avoid flickering when the `hasNextPage` is `undefined` during refetch
  const paginationMeta = React.useMemo(() => {
    if (hasNextPage !== undefined && paginationMetaRef.current?.hasNextPage !== hasNextPage) {
      paginationMetaRef.current = { hasNextPage };
    }
    return paginationMetaRef.current;
  }, [hasNextPage]);

  const isInitialMount = useRef(true);
  useEffect(() => {
    fetchData();
  }, [payload]);

  const addNas = () => {
    navigate("/radius/nas/add");
  };
  const editNas = (id) => {
    console.log(id);
    navigate("/radius/nas/edit", { state: { id: id } });
  };

  const exportFile = async (fileType) => {
    await downloadFile(fileType, payload);
  };
  const openDownloadDialog = () => {
    return openDialog("upload_nas", "Upload Nas", <Upload key={getTimeStamp()} />);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  return (
    <React.Fragment>
      <CustomDataGrid
        trans={t}
        addPage={addNas}
        editPage={editNas}
        refreshPage={refreshPage}
        setFilterResetFunc={setFilterResetFunc}
        downloadFile={exportFile}
        openDownloadDialog={openDownloadDialog}
        id="nasId"
        isLoading={isLoading}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        payload={payload}
        setPayload={setPayload}
        rows={nases}
        rowCount={totalNumberOfRecords}
        onPaginationModelChange={onPageChange}
        paginationMeta={paginationMeta}
        paging={{
          pageNumber: pageNumber,
          pageSize: pageSize,
        }}
        paginationModel={paginationModel}
      />
    </React.Fragment>
  );
}

export default DataGridPage;
