import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { PlanProvider } from "../contexts/PlanContext";
import { RateProvider } from "../contexts/RateContext";
import { DialogProvider } from "../contexts/DialogContext";
import { TenantProvider } from "../contexts/TenantContext";
import { NasGroupProvider } from "../contexts/NasGroupContext";

export const PlanComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <PlanProvider key={0} />,
        <RateProvider key={1} />,
        <TenantProvider key={2} />,
        <NasGroupProvider key={3} />,
        <DialogProvider key={4} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
