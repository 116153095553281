import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Divider as MuiDivider,
  Box,
  CircularProgress,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SaveCancel from "../../../components/SaveCancel";

import CustomDataGrid from "../send_sms_to_employee/components/CustomDataGrid";
import useReports from "../../../hooks/useReports";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import Stats from "./../../../components/Stats";
import CustomTextField from "./../../../components/CustomTextField";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";

import useAuth from "../../../hooks/useAuth";
import { roundOff } from "../../../utils/common";
import useSmsToEmployee from "../../../hooks/useSmsToEmployee";
import SendMessage from "./components/SendMessage";
const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function SmsToEmployee() {
  const { user } = useAuth();
  const {
    addSmsToEmployee,
    getAllSmsToEmployee,
    smsToEmployees,
    isLoading,
    downloadFile,
    isSubmitting,
  } = useSmsToEmployee();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  const dispatch = useDispatch();

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const methods = useForm({
    resolver: yupResolver,
  });

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    try {
      await addSmsToEmployee(data);
      dispatch(showSuccessSnackbar("Issue type added successfully!"));
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to add issue type"));
    }
  };

  const cancelBtn = () => {
    navigate("/leads/list");
  };
  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllSmsToEmployee(payload);
  };

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const exportFile = async (fileType) => {
    await downloadFile(fileType, payload);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const addTo = (id) => {
    return openDialog(
      "send_message",
      "Send Message",
      <SendMessage
        key={getTimeStamp()}
        leadId={id}
        fetchData={fetchData}
        selectedRows={selectedRows}
      />,
    );
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <CustomDataGrid
              trans={t}
              addPage={addTo}
              editPage={null}
              refreshPage={refreshPage}
              downloadFile={exportFile}
              openDownloadDialog={null}
              id="smsToEmployeeId"
              data={smsToEmployees.data}
              onPageChange={onPageChange}
              isLoading={isLoading}
              sortModel={sortModel}
              handleSortModelChange={handleSortModelChange}
              setSelectedRows={setSelectedRows}
              setPayload={setPayload}
              payload={payload}
              setFilterResetFunc={setFilterResetFunc}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </React.Fragment>
  );
}

export default SmsToEmployee;
