import { useContext } from "react";

import { HotelManagementContext } from "../contexts/HotelManagementContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useHotelManagement = () => {
  const context = useContext(HotelManagementContext);

  if (!context)
    throw new Error("HotelManagementContext must be placed within HotelManagementProvider");

  return context;
};

export default useHotelManagement;
