import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import PppSecretReducer from "../reducers/PppSecretReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { PppSecretConstants } from "../constants/pppsecret.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  pppSecrets: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  pppSecretSearchResults: [],
  pppSecret: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  pppSecretAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const PppSecretContext = createContext({
  ...initialState,
  getAllPppSecret: () => {},
  addPppSecret: () => {},
  searchPppSecret: () => {},
  autoSearchPppSecret: () => {},
  getPppSecret: () => {},
  updatePppSecret: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function PppSecretProvider({ children }) {
  const [state, dispatch] = useReducer(PppSecretReducer, initialState);

  const getAllPppSecret = async (payload) => {
    try {
      dispatch({
        type: PppSecretConstants.FETCH_PPP_SECRET_REQUEST,
      });
      const response = await axios.post("/ppp-secret/list", payload);
      dispatch({
        type: PppSecretConstants.FETCH_PPP_SECRET_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: PppSecretConstants.FETCH_PPP_SECRET_FAILURE,
      });
    }
  };
  const searchPppSecret = async (payload) => {
    try {
      const response = await axios.post("/ppp-secret/search", payload);
      dispatch({
        type: PppSecretConstants.PPP_SECRET_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PppSecretConstants.PPP_SECRET_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PppSecretConstants.PPP_SECRET_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchPppSecret = async (payload) => {
    try {
      const response = await axios.post("/ppp-secret/auto-search", payload);
      dispatch({
        type: PppSecretConstants.PPP_SECRET_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PppSecretConstants.PPP_SECRET_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PppSecretConstants.PPP_SECRET_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addPppSecret = async (payload) => {
    const response = await axios.post("/ppp-secret/add", payload);
    dispatch({
      type: PppSecretConstants.ADD_PPP_SECRET_REQUEST,
    });
    dispatch({
      type: PppSecretConstants.ADD_PPP_SECRET_SUCCESS,
      payload: response.data,
    });
  };

  const getPppSecret = async (id) => {
    const response = await axios.get("/ppp-secret/" + id);
    dispatch({
      type: PppSecretConstants.GET_PPP_SECRET_REQUEST,
    });
    dispatch({
      type: PppSecretConstants.GET_PPP_SECRET_SUCCESS,
      payload: response.data,
    });
  };

  const updatePppSecret = async (payload) => {
    const response = await axios.put("/ppp-secret/update/" + payload.pppSecretId, payload);
    dispatch({
      type: PppSecretConstants.UPDATE_PPP_SECRET_REQUEST,
    });
    dispatch({
      type: PppSecretConstants.UPDATE_PPP_SECRET_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: PppSecretConstants.DOWNLOAD_PPP_SECRET_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/ppp-secret/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "pppSecret." + fileType);
    dispatch({
      type: PppSecretConstants.DOWNLOAD_PPP_SECRET_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/ppp-secret/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_pppSecret." + fileType);
    dispatch({
      type: PppSecretConstants.DOWNLOAD_PPP_SECRET_REQUEST,
    });
    dispatch({
      type: PppSecretConstants.DOWNLOAD_PPP_SECRET_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: PppSecretConstants.UPLOAD_PPP_SECRET_REQUEST,
      });
      const response = await axios({
        url: "/ppp-secret/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: PppSecretConstants.UPLOAD_PPP_SECRET_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: PppSecretConstants.UPLOAD_PPP_SECRET_FAILURE,
      });
    }
  };

  return (
    <PppSecretContext.Provider
      value={{
        ...state,
        getAllPppSecret,
        addPppSecret,
        searchPppSecret,
        autoSearchPppSecret,
        getPppSecret,
        updatePppSecret,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </PppSecretContext.Provider>
  );
}

export { PppSecretContext, PppSecretProvider };
