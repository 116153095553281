import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import DashboardReducer from "../reducers/DashboardReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { DashboardConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  isUserDashboardLoading: true,
  dashboadUserCount: {
    totalCount: 0,
    onlineCount: 0,
    activeCount: 0,
    expireCount: 0,
    suspendedCount: 0,
  },
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const DashboardContext = createContext({
  ...initialState,

  getDashboardUserCount: () => {},
});

function DashboardProvider({ children }) {
  const [state, dispatch] = useReducer(DashboardReducer, initialState);

  const getDashboardUserCount = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/dashboard-count", payload);
      dispatch({
        type: DashboardConstants.USER_DASHBOARD_COUNT_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: DashboardConstants.USER_DASHBOARD_COUNT_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: DashboardConstants.USER_DASHBOARD_COUNT_FAILURE,
      });
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        ...state,

        getDashboardUserCount,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

export { DashboardContext, DashboardProvider };
