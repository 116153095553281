import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
} from "@mui/material";

import { spacing } from "@mui/system";
import SaveCancel from "../../../components/SaveCancel";
import usePincode from "../../../hooks/usePincode";
import PincodeFields from "./components/PincodeFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  pincodeName: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});
function BasicForm(props) {
  const { getPincode, pinCode, updatePincode, isSubmitting } = usePincode();

  React.useEffect(() => {
    if (Object.keys(pinCode).length !== 0) {
      console.log(pinCode.status, "pincode.status");
      const initialValues = {
        pincodeId: pinCode.pincodeId,
        pincodeName: pinCode.pincodeName,
        status: pinCode.status,
      };
      reset(initialValues);
    }
  }, [pinCode]);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const [currentPincode, setCurrentPincode] = useState(null);
  const { state } = useLocation();
  console.log(state, "location");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      await updatePincode(data);
      setStatus({ sent: true });
      reset();
    } catch (err) {
      setStatus({ sent: false });
      setErrorSubmit({ submit: err.message });
    }
  };

  const cancelBtn = () => {
    navigate("/masters/pincode/list");
  };
  const fetchData = async () => {
    await getPincode(state.id);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Masters</Typography>
        <Typography>Pincode</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };
  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_pincode")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                Pincode updated successfully!
              </Alert>
            )}
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <PincodeFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}
function EditPincode() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}
export default EditPincode;
