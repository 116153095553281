export const StateMasterConstants = {
  FETCH_STATEMASTER_REQUEST: "FETCH_STATEMASTER_REQUEST",
  FETCH_STATEMASTER_SUCCESS: "FETCH_STATEMASTER_SUCCESS",
  FETCH_STATEMASTER_FAILURE: "FETCH_STATEMASTER_FAILURE",
  ADD_STATEMASTER_REQUEST: "ADD_STATEMASTER_REQUEST",
  ADD_STATEMASTER_SUCCESS: "ADD_STATEMASTER_SUCCESS",
  ADD_STATEMASTER_FAILURE: "ADD_STATEMASTER_FAILURE",
  EDIT_STATEMASTER_REQUEST: "EDIT_STATEMASTER_REQUEST",
  EDIT_STATEMASTER_SUCCESS: "EDIT_STATEMASTER_SUCCESS",
  EDIT_STATEMASTER_FAILURE: "EDIT_STATEMASTER_FAILURE",
  STATEMASTER_SEARCH_REQUEST: "STATEMASTER_SEARCH_REQUEST",
  STATEMASTER_SEARCH_SUCCESS: "STATEMASTER_SEARCH_SUCCESS",
  STATEMASTER_SEARCH_FAILURE: "STATEMASTER_SEARCH_FAILURE",
  STATEMASTER_AUTO_SEARCH_REQUEST: "STATEMASTER_AUTO_SEARCH_REQUEST",
  STATEMASTER_AUTO_SEARCH_SUCCESS: "STATEMASTER_AUTO_SEARCH_SUCCESS",
  STATEMASTER_AUTO_SEARCH_FAILURE: "STATEMASTER_AUTO_SEARCH_FAILURE",
  GET_STATEMASTER_REQUEST: "GET_STATEMASTER_REQUEST",
  GET_STATEMASTER_SUCCESS: "GET_STATEMASTER_SUCCESS",
  GET_STATEMASTER_FAILURE: "GET_STATEMASTER_FAILURE",
  UPDATE_STATEMASTER_REQUEST: "UPDATE_STATEMASTER_REQUEST",
  UPDATE_STATEMASTER_SUCCESS: "UPDATE_STATEMASTER_SUCCESS",
  UPDATE_STATEMASTER_FAILURE: "UPDATE_STATEMASTER_FAILURE",
  DOWNLOAD_STATEMASTER_REQUEST: "DOWNLOAD_STATEMASTER_REQUEST",
  DOWNLOAD_STATEMASTER_SUCCESS: "DOWNLOAD_STATEMASTER_SUCCESS",
  DOWNLOAD_STATEMASTER_FAILURE: "DOWNLOAD_STATEMASTER_FAILURE",
  UPLOAD_STATEMASTER_REQUEST: "UPLOAD_STATEMASTER_REQUEST",
  UPLOAD_STATEMASTER_SUCCESS: "UPLOAD_STATEMASTER_SUCCESS",
  UPLOAD_STATEMASTER_FAILURE: "UPLOAD_STATEMASTER_FAILURE",
};
