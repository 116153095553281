import React from "react";

import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
const headCells = [
  { id: "planName", alignment: "right", label: "Plan Name" },
  { id: "rateFullName", alignment: "left", label: "Rate" },
  { id: "msoSharing", alignment: "left", label: "MSO Sharing" },
  { id: "distSharing", alignment: "right", label: "Distributor Sharing" },
  { id: "lcoSharing", alignment: "left", label: "LCO Sharing" },
  { id: "actions", alignment: "right", label: "Actions" },
];
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export default EnhancedTableHead;
