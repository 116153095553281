import { useContext } from "react";

import { TrafficReportContext } from "../contexts/TrafficReportContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useTrafficReport = () => {
  const context = useContext(TrafficReportContext);

  if (!context) throw new Error("TrafficReportContext must be placed within TrafficReportProvider");

  return context;
};

export default useTrafficReport;
