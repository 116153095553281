import styled from "@emotion/styled";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import { useTheme } from "@mui/material/styles";
import useComplaint from "../../../hooks/useComplaint";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from "../../../utils/windowDimensions";
import { green, red, orange, pink, purple, blue, grey } from "@mui/material/colors";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import Upload from "../components/Upload";
// import { DataGrid } from "@mui/x-data-grid";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import { spacing, textAlign } from "@mui/system";

Chart.register(ArcElement, Tooltip, Legend);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};
  }
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 5rem;
  padding-right: 5rem;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;
const OrangeText = styled.span`
  color: ${() => orange[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;
const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;
const BlueText = styled.span`
  color: ${() => blue[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const Stats = ({ theme }) => {
  const stheme = useTheme();
  const { t } = useTranslation();
  const { state } = useLocation();

  const currentPayload = {
    filters: [
      {
        fieldName: "complaintId",
        fieldValue: state?.id,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "complaintStatsId",
        sort: "DESC",
      },
    ],
  };
  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height, width } = useWindowDimensions();
  const { getAllComplaintStats, complaintStats, paging, isLoading, downloadFile } = useComplaint();
  const { openDialog } = useDialog();
  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllComplaintStats(payload);
  };
  useEffect(() => {
    fetchData();
  }, [payload]);

  // const onPageChange = (page) => {
  //   console.log(page, "pagepage");
  //   const pagination = {
  //     pageNumber: page == 0 ? 1 : page + 1,
  //     pageSize: payload.pagination.pageSize,
  //   };
  //   setPayload((prev) => ({ ...prev, pagination }));
  // };

  const data = {
    labels: ["Total", " Assigned", "Reassigned", "Resolved", "Closed"],
    datasets: [
      {
        data: [
          complaintStats.total,
          complaintStats.assigned,
          complaintStats.reAssigned,
          complaintStats.resolved,
          complaintStats.closed,
        ],
      },
    ],
  };

  const [graphData, setGraphData] = useState(data);

  useEffect(() => {
    const data = {
      labels: ["Total", "Assigned", "Reassigned", "Resolved", "Closed"],
      datasets: [
        {
          data: [
            complaintStats.total,
            complaintStats.assigned,
            complaintStats.reAssigned,
            complaintStats.resolved,
            complaintStats.closed,
          ],
          backgroundColor: [
            stheme.palette.secondary.main,
            red[500],
            orange[500],
            pink[500],
            green[500],
            grey[500],
          ],
          borderWidth: 5,
          borderColor: stheme.palette.background.paper,
        },
      ],
    };
    setGraphData(data);
  }, [complaintStats]);

  const exportFile = async (fileType) => {
    await downloadFile(fileType, payload);
  };
  const openDownloadDialog = () => {
    return openDialog("Upload complaint", true, <Upload key={getTimeStamp()} />);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title={t("complaint_stats")}
      />

      <CardContent theme={stheme}>
        <ChartWrapper>
          <DoughnutInner>
            <Typography variant="h4">{complaintStats.total}</Typography>
            <Typography variant="caption">Total</Typography>
          </DoughnutInner>
          <Doughnut data={graphData} options={options} />
        </ChartWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell align="right">Count</TableCell>
              {/* <TableCell align="right">Value</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Total
              </TableCell>
              <TableCell align="right">
                <RedText theme={stheme}>{complaintStats.total}</RedText>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Assigned
              </TableCell>
              <TableCell align="right">
                <RedText theme={stheme}>{complaintStats.assigned}</RedText>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Reassigned
              </TableCell>
              <TableCell align="right">
                <OrangeText theme={stheme}>{complaintStats.reAssigned}</OrangeText>
              </TableCell>
              {/* <TableCell align="right">
                <OrangeText>-12%</OrangeText>
              </TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Resolved
              </TableCell>
              <TableCell align="right">
                <BlueText theme={stheme}>{complaintStats.resolved}</BlueText>
              </TableCell>
              {/* <TableCell align="right">
                <GreenText>+46%</GreenText>
              </TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Closed
              </TableCell>
              <TableCell align="right">
                <GreenText theme={stheme}>{complaintStats.closed}</GreenText>
              </TableCell>
              {/* <TableCell align="right">
                <GreenText>+46%</GreenText>
              </TableCell> */}
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Stats;
