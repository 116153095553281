import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useFormContext, useFieldArray } from "react-hook-form";

import {
  Box,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";

import { SaveAs as SaveAsIcon } from "@mui/icons-material";

import { spacing } from "@mui/system";

import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import CustomTextField from "../../../../components/CustomTextField";

const Paper = styled(MuiPaper)(spacing);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

function SharingDetails(props) {
  const {
    register,
    formState: { errors },
    watch,
    control,
  } = useFormContext();
  const { fields, validationFields, paging, payload, setPayload } = props;

  //const { getAllTenantSharing, tenantSharings } = useTenantSharing();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = fields.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    const pagination = {
      pageNumber: newPage == 0 ? 1 : newPage + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 100));
    //setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, fields.length - paging.pageNumber * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={"medium"} aria-label="enhanced table">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={fields.length}
            />
            <TableBody>
              {fields.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`${row.id}-${index}`}
                    selected={isItemSelected}
                  >
                    <TableCell align="left">{row.planName}</TableCell>
                    <TableCell align="left">{row.rateFullName}</TableCell>
                    <TableCell align="left">
                      <CustomTextField
                        label="MSO Sharing"
                        name={`tenantSharing.${index}.msoSharing`}
                        control={control}
                        fullWidth
                        variant="outlined"
                        errors={errors}
                        fields={validationFields}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <CustomTextField
                        label="Dist Sharing"
                        name={`tenantSharing.${index}.distSharing`}
                        control={control}
                        fullWidth
                        variant="outlined"
                        errors={errors}
                        fields={validationFields}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomTextField
                        label="LCO Sharing"
                        name={`tenantSharing.${index}.lcoSharing`}
                        control={control}
                        fullWidth
                        variant="outlined"
                        errors={errors}
                        fields={validationFields}
                      />
                    </TableCell>

                    <TableCell padding="none" align="right">
                      <Box mr={2}>
                        <IconButton aria-label="details" size="large">
                          <SaveAsIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100, 200, 1000]}
          component="div"
          count={paging.totalNumberOfRecords}
          rowsPerPage={rowsPerPage}
          page={paging.pageNumber - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
export default SharingDetails;
