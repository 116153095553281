export const BranchConstants = {
  FETCH_BRANCH_REQUEST: "FETCH_BRANCH_REQUEST",
  FETCH_BRANCH_SUCCESS: "FETCH_BRANCH_SUCCESS",
  FETCH_BRANCH_FAILURE: "FETCH_BRANCH_FAILURE",
  ADD_BRANCH_REQUEST: "ADD_BRANCH_REQUEST",
  ADD_BRANCH_SUCCESS: "ADD_BRANCH_SUCCESS",
  ADD_BRANCH_FAILURE: "ADD_BRANCH_FAILURE",
  BRANCH_SEARCH_REQUEST: "BRANCH_SEARCH_REQUEST",
  BRANCH_SEARCH_SUCCESS: "BRANCH_SEARCH_SUCCESS",
  BRANCH_SEARCH_FAILURE: "BRANCH_SEARCH_FAILURE",
  GET_BRANCH_REQUEST: "GET_BRANCH_REQUEST",
  GET_BRANCH_SUCCESS: "GET_BRANCH_SUCCESS",
  GET_BRANCH_FAILURE: "GET_BRANCH_FAILURE",
  UPDATE_BRANCH_REQUEST: "UPDATE_BRANCH_REQUEST",
  UPDATE_BRANCH_SUCCESS: "UPDATE_BRANCH_SUCCESS",
  UPDATE_BRANCH_FAILURE: "UPDATE_BRANCH_FAILURE",
  DOWNLOAD_BRANCH_REQUEST: "DOWNLOAD_BRANCH_REQUEST",
  DOWNLOAD_BRANCH_SUCCESS: "DOWNLOAD_BRANCH_SUCCESS",
  DOWNLOAD_BRANCH_FAILURE: "DOWNLOAD_BRANCH_FAILURE",
  UPLOAD_BRANCH_REQUEST: "UPLOAD_BRANCH_REQUEST",
  UPLOAD_BRANCH_SUCCESS: "UPLOAD_BRANCH_SUCCESS",
  UPLOAD_BRANCH_FAILURE: "UPLOAD_BRANCH_FAILURE",
  BRANCH_AUTO_SEARCH_REQUEST: "BRANCH_AUTO_SEARCH_REQUEST",
  BRANCH_AUTO_SEARCH_SUCCESS: "BRANCH_AUTO_SEARCH_SUCCESS",
  BRANCH_AUTO_SEARCH_FAILURE: "BRANCH_AUTO_SEARCH_FAILURE",
};
