import { useContext } from "react";

import { WalletContext } from "../contexts/WalletContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useWallet = () => {
  const context = useContext(WalletContext);

  if (!context) throw new Error("WalletContext must be placed within WalletProvider");

  return context;
};

export default useWallet;
