export const AddonConstants = {
  FETCH_ADDON_REQUEST: "FETCH_ADDON_REQUEST",
  FETCH_ADDON_SUCCESS: "FETCH_ADDON_SUCCESS",
  FETCH_ADDON_FAILURE: "FETCH_ADDON_FAILURE",
  ADD_ADDON_REQUEST: "ADD_ADDON_REQUEST",
  ADD_ADDON_SUCCESS: "ADD_ADDON_SUCCESS",
  ADD_ADDON_FAILURE: "ADD_ADDON_FAILURE",
  ADDON_SEARCH_REQUEST: "ADDON_SEARCH_REQUEST",
  ADDON_SEARCH_SUCCESS: "ADDON_SEARCH_SUCCESS",
  ADDON_SEARCH_FAILURE: "ADDON_SEARCH_FAILURE",
  GET_ADDON_REQUEST: "GET_ADDON_REQUEST",
  GET_ADDON_SUCCESS: "GET_ADDON_SUCCESS",
  GET_ADDON_FAILURE: "GET_ADDON_FAILURE",
  UPDATE_ADDON_REQUEST: "UPDATE_ADDON_REQUEST",
  UPDATE_ADDON_SUCCESS: "UPDATE_ADDON_SUCCESS",
  UPDATE_ADDON_FAILURE: "UPDATE_ADDON_FAILURE",
  DOWNLOAD_ADDON_REQUEST: "DOWNLOAD_ADDON_REQUEST",
  DOWNLOAD_ADDON_SUCCESS: "DOWNLOAD_ADDON_SUCCESS",
  DOWNLOAD_ADDON_FAILURE: "DOWNLOAD_ADDON_FAILURE",
  UPLOAD_ADDON_REQUEST: "UPLOAD_ADDON_REQUEST",
  UPLOAD_ADDON_SUCCESS: "UPLOAD_ADDON_SUCCESS",
  UPLOAD_ADDON_FAILURE: "UPLOAD_ADDON_FAILURE",
  ADD_ADDON_TENANT_REQUEST: "ADD_ADDON_TENANT_REQUEST",
  ADD_ADDON_TENANT_SUCCESS: "ADD_ADDON_TENANT_SUCCESS",
  ADD_ADDON_TENANT_FAILURE: "ADD_ADDON_TENANT_FAILURE",
  FETCH_ADDON_TENANT_REQUEST: "FETCH_ADDON_TENANT_REQUEST",
  FETCH_ADDON_TENANT_SUCCESS: "FETCH_ADDON_TENANT_SUCCESS",
  FETCH_ADDON_TENANT_FAILURE: "FETCH_ADDON_TENANT_FAILURE",
  ADDON_AUTO_SEARCH_REQUEST: "ADDON_AUTO_SEARCH_REQUEST",
  ADDON_AUTO_SEARCH_SUCCESS: "ADDON_AUTO_SEARCH_SUCCESS",
  ADDON_AUTO_SEARCH_FAILURE: "ADDON_AUTO_SEARCH_FAILURE",
};
