import {
  BookOpen,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  Settings,
  Database,
  Framer,
  Aperture,
  Archive,
  Server,
  Account,
} from "react-feather";

import DashboardIcon from "@mui/icons-material/Dashboard";
import StorefrontIcon from "@mui/icons-material/Storefront";

const pagesSection = [
  {
    href: "/employee-dashboard",
    icon: DashboardIcon,
    title: "Dashboard",
    href: "/employee-dashboard/default",
  },
  {
    href: "/complaints",
    icon: Archive,
    title: "Complaints",
    children: [
      {
        href: "/complaints/list",
        title: "Complaint List",
      },
      {
        href: "/complaints/stats",
        title: "Complaint Stats",
      },
      {
        href: "/complaints/closed",
        title: "Complaint Closed",
      },
    ],
  },
  {
    href: "/leads",
    icon: Archive,
    title: "Leads",
    children: [
      {
        href: "/leads/list",
        title: "Leads List",
      },
      {
        href: "/leads/stats",
        title: "Leads Stats",
      },
      {
        href: "/leads/closed",
        title: "Leads Closed",
      },
    ],
  },
  {
    href: "/hms",
    icon: StorefrontIcon,
    title: "HMS",
    children: [
      {
        href: "/hms/hotel-user/list",
        title: "Hotel Users",
      },
      {
        href: "/hms/activate-user/list",
        title: "Activate User",
      },
      {
        href: "/hms/deactivate-user/list",
        title: "Deactivate User",
      },
    ],
  },
];

const HotelItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default HotelItems;
