import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { SmsToTenantProvider } from "../contexts/SmsToTenantContext";
import { DialogProvider } from "../contexts/DialogContext";

export const SmsToTenantComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<SmsToTenantProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};

export default SmsToTenantComposer;
