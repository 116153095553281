export const InventoryManagementConstants = {
  FETCH_INVENTORY_MANAGEMENT_REQUEST: "FETCH_INVENTORY_MANAGEMENT_REQUEST",
  FETCH_INVENTORY_MANAGEMENT_SUCCESS: "FETCH_INVENTORY_MANAGEMENT_SUCCESS",
  FETCH_INVENTORY_MANAGEMENT_FAILURE: "FETCH_INVENTORY_MANAGEMENT_FAILURE",
  ADD_INVENTORY_MANAGEMENT_REQUEST: "ADD_INVENTORY_MANAGEMENT_REQUEST",
  ADD_INVENTORY_MANAGEMENT_SUCCESS: "ADD_INVENTORY_MANAGEMENT_SUCCESS",
  ADD_INVENTORY_MANAGEMENT_FAILURE: "ADD_INVENTORY_MANAGEMENT_FAILURE",
  INVENTORY_MANAGEMENT_SEARCH_REQUEST: "INVENTORY_MANAGEMENT_SEARCH_REQUEST",
  INVENTORY_MANAGEMENT_SEARCH_SUCCESS: "INVENTORY_MANAGEMENT_SEARCH_SUCCESS",
  INVENTORY_MANAGEMENT_SEARCH_FAILURE: "INVENTORY_MANAGEMENT_SEARCH_FAILURE",
  INVENTORY_MANAGEMENT_AUTO_SEARCH_REQUEST: "INVENTORY_MANAGEMENT_AUTO_SEARCH_REQUEST",
  INVENTORY_MANAGEMENT_AUTO_SEARCH_SUCCESS: "INVENTORY_MANAGEMENT_AUTO_SEARCH_SUCCESS",
  INVENTORY_MANAGEMENT_AUTO_SEARCH_FAILURE: "INVENTORY_MANAGEMENT_AUTO_SEARCH_FAILURE",
  GET_INVENTORY_MANAGEMENT_REQUEST: "GET_INVENTORY_MANAGEMENT_REQUEST",
  GET_INVENTORY_MANAGEMENT_SUCCESS: "GET_INVENTORY_MANAGEMENT_SUCCESS",
  GET_INVENTORY_MANAGEMENT_FAILURE: "GET_INVENTORY_MANAGEMENT_FAILURE",
  UPDATE_INVENTORY_MANAGEMENT_REQUEST: "UPDATE_INVENTORY_MANAGEMENT_REQUEST",
  UPDATE_INVENTORY_MANAGEMENT_SUCCESS: "UPDATE_INVENTORY_MANAGEMENT_SUCCESS",
  UPDATE_INVENTORY_MANAGEMENT_FAILURE: "UPDATE_INVENTORY_MANAGEMENT_FAILURE",
  DOWNLOAD_INVENTORY_MANAGEMENT_REQUEST: "DOWNLOAD_INVENTORY_MANAGEMENT_REQUEST",
  DOWNLOAD_INVENTORY_MANAGEMENT_SUCCESS: "DOWNLOAD_INVENTORY_MANAGEMENT_SUCCESS",
  DOWNLOAD_INVENTORY_MANAGEMENT_FAILURE: "DOWNLOAD_INVENTORY_MANAGEMENT_FAILURE",
  UPLOAD_INVENTORY_MANAGEMENT_REQUEST: "UPLOAD_INVENTORY_MANAGEMENT_REQUEST",
  UPLOAD_INVENTORY_MANAGEMENT_SUCCESS: "UPLOAD_INVENTORY_MANAGEMENT_SUCCESS",
  UPLOAD_INVENTORY_MANAGEMENT_FAILURE: "UPLOAD_INVENTORY_MANAGEMENT_FAILURE",
};
