import { EmployeeConstants } from "../constants/employee.constants";

const EmployeeReducer = (state, action) => {
  switch (action.type) {
    case EmployeeConstants.FETCH_EMPLOYEE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmployeeConstants.FETCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employees: action.payload.data,
        paging: action.payload.paging,
      };
    case EmployeeConstants.FETCH_EMPLOYEE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmployeeConstants.EMPLOYEE_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmployeeConstants.EMPLOYEE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employeeSearchResults: action.payload,
      };
    case EmployeeConstants.EMPLOYEE_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmployeeConstants.EMPLOYEE_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmployeeConstants.EMPLOYEE_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employeeAutoSearchResults: action.payload,
      };
    case EmployeeConstants.EMPLOYEE_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmployeeConstants.ADD_EMPLOYEE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case EmployeeConstants.ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case EmployeeConstants.ADD_EMPLOYEE_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case EmployeeConstants.GET_EMPLOYEE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EmployeeConstants.GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employee: action.payload,
      };
    case EmployeeConstants.GET_EMPLOYEE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case EmployeeConstants.UPDATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case EmployeeConstants.UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        employee: action.payload,
      };
    case EmployeeConstants.UPDATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case EmployeeConstants.DOWNLOAD_EMPLOYEE_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case EmployeeConstants.DOWNLOAD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case EmployeeConstants.DOWNLOAD_EMPLOYEE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case EmployeeConstants.UPLOAD_EMPLOYEE_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case EmployeeConstants.UPLOAD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case EmployeeConstants.UPLOAD_EMPLOYEE_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default EmployeeReducer;
