import { SmsToEmployeeConstants } from "../constants/smstoemployee.constants";

const SmsToEmployeesReducer = (state, action) => {
  switch (action.type) {
    case SmsToEmployeeConstants.FETCH_SMSTOEMPLOYEE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsToEmployeeConstants.FETCH_SMSTOEMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsToEmployees: action.payload.data,
        paging: action.payload.paging,
      };
    case SmsToEmployeeConstants.FETCH_SMSTOEMPLOYEE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsToEmployeeConstants.SMSTOEMPLOYEE_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsToEmployeeConstants.SMSTOEMPLOYEE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsToEmployeeSearchResults: action.payload,
      };
    case SmsToEmployeeConstants.SMSTOEMPLOYEE_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsToEmployeeConstants.SMSTOEMPLOYEE_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsToEmployeeConstants.SMSTOEMPLOYEE_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsToEmployeeAutoSearchResults: action.payload,
      };
    case SmsToEmployeeConstants.SMSTOEMPLOYEE_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsToEmployeeConstants.GET_SMSTOEMPLOYEE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsToEmployeeConstants.GET_SMSTOEMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsToEmployee: action.payload,
      };
    case SmsToEmployeeConstants.GET_SMSTOEMPLOYEE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsToEmployeeConstants.DOWNLOAD_SMSTOEMPLOYEE_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case SmsToEmployeeConstants.DOWNLOAD_SMSTOEMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case SmsToEmployeeConstants.DOWNLOAD_SMSTOEMPLOYEE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };

    default:
      return state;
  }
};

export default SmsToEmployeesReducer;
