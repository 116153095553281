import { useContext } from "react";

import { SmsToEmployeeContext } from "../contexts/SmsToEmployeeContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useSmsToEmployee = () => {
  const context = useContext(SmsToEmployeeContext);

  if (!context)
    throw new Error("SmsToEmployeeConstants must be placed within SmsToEmployeesProvider");

  return context;
};

export default useSmsToEmployee;
