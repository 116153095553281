import { CityConstants } from "../constants/city.constants";

const CityReducer = (state, action) => {
  console.log("1212121212121212", state);
  switch (action.type) {
    case CityConstants.FETCH_CITY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case CityConstants.FETCH_CITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        states: action.payload.data,
        paging: action.payload.paging,
      };
    case CityConstants.FETCH_CITY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case CityConstants.ADD_CITY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case CityConstants.ADD_CITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case CityConstants.ADD_CITY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };
    case CityConstants.EDIT_CITY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case CityConstants.EDIT_CITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case CityConstants.EDIT_CITY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };
    case CityConstants.CITY_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case CityConstants.CITY_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        citySearchResults: action.payload,
      };
    case CityConstants.CITY_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case CityConstants.CITY_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoadingAutoSearch: true,
        hasError: false,
      };
    case CityConstants.CITY_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoadingAutoSearch: false,
        cityAutoSearchResults: action.payload,
      };
    case CityConstants.CITY_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingAutoSearch: false,
      };
    case CityConstants.GET_CITY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case CityConstants.GET_CITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        city: action.payload,
      };
    case CityConstants.GET_CITY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case CityConstants.UPDATE_CITY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case CityConstants.UPDATE_CITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        city: action.payload,
      };
    case CityConstants.UPDATE_CITY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case CityConstants.DOWNLOAD_CITY_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case CityConstants.DOWNLOAD_CITY_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case CityConstants.DOWNLOAD_CITY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case CityConstants.UPLOAD_CITY_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case CityConstants.UPLOAD_CITY_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case CityConstants.UPLOAD_CITY_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default CityReducer;
