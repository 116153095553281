export const PppServerConstants = {
  FETCH_PPP_SERVER_REQUEST: "FETCH_PPP_SERVER_REQUEST",
  FETCH_PPP_SERVER_SUCCESS: "FETCH_PPP_SERVER_SUCCESS",
  FETCH_PPP_SERVER_FAILURE: "FETCH_PPP_SERVER_FAILURE",
  ADD_PPP_SERVER_REQUEST: "ADD_PPP_SERVER_REQUEST",
  ADD_PPP_SERVER_SUCCESS: "ADD_PPP_SERVER_SUCCESS",
  ADD_PPP_SERVER_FAILURE: "ADD_PPP_SERVER_FAILURE",
  PPP_SERVER_SEARCH_REQUEST: "PPP_SERVER_SEARCH_REQUEST",
  PPP_SERVER_SEARCH_SUCCESS: "PPP_SERVER_SEARCH_SUCCESS",
  PPP_SERVER_SEARCH_FAILURE: "PPP_SERVER_SEARCH_FAILURE",
  PPP_SERVER_AUTO_SEARCH_REQUEST: "PPP_SERVER_AUTO_SEARCH_REQUEST",
  PPP_SERVER_AUTO_SEARCH_SUCCESS: "PPP_SERVER_AUTO_SEARCH_SUCCESS",
  PPP_SERVER_AUTO_SEARCH_FAILURE: "PPP_SERVER_AUTO_SEARCH_FAILURE",
  GET_PPP_SERVER_REQUEST: "GET_PPP_SERVER_REQUEST",
  GET_PPP_SERVER_SUCCESS: "GET_PPP_SERVER_SUCCESS",
  GET_PPP_SERVER_FAILURE: "GET_PPP_SERVER_FAILURE",
  UPDATE_PPP_SERVER_REQUEST: "UPDATE_PPP_SERVER_REQUEST",
  UPDATE_PPP_SERVER_SUCCESS: "UPDATE_PPP_SERVER_SUCCESS",
  UPDATE_PPP_SERVER_FAILURE: "UPDATE_PPP_SERVER_FAILURE",
  DOWNLOAD_PPP_SERVER_REQUEST: "DOWNLOAD_PPP_SERVER_REQUEST",
  DOWNLOAD_PPP_SERVER_SUCCESS: "DOWNLOAD_PPP_SERVER_SUCCESS",
  DOWNLOAD_PPP_SERVER_FAILURE: "DOWNLOAD_PPP_SERVER_FAILURE",
  UPLOAD_PPP_SERVER_REQUEST: "UPLOAD_PPP_SERVER_REQUEST",
  UPLOAD_PPP_SERVER_SUCCESS: "UPLOAD_PPP_SERVER_SUCCESS",
  UPLOAD_PPP_SERVER_FAILURE: "UPLOAD_PPP_SERVER_FAILURE",
};
