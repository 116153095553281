import { PlanHistoryConstants } from "../constants/planhistory.constants";

const PlanHistoryReducer = (state, action) => {
  switch (action.type) {
    case PlanHistoryConstants.FETCH_PLAN_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanHistoryConstants.FETCH_PLAN_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        planHistories: action.payload.data,
        paging: action.payload.paging,
      };
    case PlanHistoryConstants.FETCH_PLAN_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PlanHistoryConstants.PLAN_HISTORY_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanHistoryConstants.PLAN_HISTORY_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        planHistorySearchResults: action.payload,
      };
    case PlanHistoryConstants.PLAN_HISTORY_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PlanHistoryConstants.PLAN_HISTORY_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        autoSearchLoading: true,
        hasError: false,
      };
    case PlanHistoryConstants.PLAN_HISTORY_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        autoSearchLoading: false,
        planHistoryAutoSearchResults: action.payload,
      };
    case PlanHistoryConstants.PLAN_HISTORY_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        autoSearchLoading: false,
      };

    case PlanHistoryConstants.ADD_PLAN_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanHistoryConstants.ADD_PLAN_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PlanHistoryConstants.ADD_PLAN_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case PlanHistoryConstants.GET_PLAN_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanHistoryConstants.GET_PLAN_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        planHistory: action.payload,
      };
    case PlanHistoryConstants.GET_PLAN_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PlanHistoryConstants.UPDATE_PLAN_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanHistoryConstants.UPDATE_PLAN_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        planHistory: action.payload,
      };
    case PlanHistoryConstants.UPDATE_PLAN_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case PlanHistoryConstants.DOWNLOAD_PLAN_HISTORY_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case PlanHistoryConstants.DOWNLOAD_PLAN_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PlanHistoryConstants.DOWNLOAD_PLAN_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PlanHistoryConstants.UPLOAD_PLAN_HISTORY_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case PlanHistoryConstants.UPLOAD_PLAN_HISTORY_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case PlanHistoryConstants.UPLOAD_PLAN_HISTORY_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default PlanHistoryReducer;
