import { useContext } from "react";

import { NasGroupContext } from "../contexts/NasGroupContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useNasGroup = () => {
  const context = useContext(NasGroupContext);

  if (!context) throw new Error("NasGroup Context must be placed within sharing group provider");

  return context;
};

export default useNasGroup;
