import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";

import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import useNas from "../../../../hooks/useNas";
import useInterface from "../../../../hooks/useInterface";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function AddressesFields(props) {
  const { t } = useTranslation();
  const { fields } = props;

  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const { autoSearchNas, nasAutoSearchResults, getNas, nas } = useNas();

  const [nasOptions, setNasOptions] = useState([]);
  const [nasPayload, setNasPayload] = useState(currentPayload);

  const { autoSearchInterface, interfaceAutoSearchResults } = useInterface();

  const [interfaceOptions, setInterfaceOptions] = useState([]);
  const [interfacePayload, setInterfacePayload] = useState(currentPayload);

  const fetchNas = async () => {
    await autoSearchNas(nasPayload);
  };
  useEffect(() => {
    fetchNas();
  }, [nasPayload]);

  const selectedNas = watch("nas");

  useEffect(() => {
    console.log(selectedNas, "selectedNasselectedNasselectedNas");
    if (selectedNas != null && Object.keys(selectedNas).length != 0) {
      const payload = {
        ...interfacePayload,
        filters: [
          {
            fieldName: "nasId",
            fieldValue: selectedNas?.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
            attributeName: "nas",
            entityName: "InterfaceEntity",
          },
        ],
      };
      setInterfaceOptions([]);
      setInterfacePayload(payload);
    }
  }, [selectedNas]);

  const fetchInterface = async () => {
    await autoSearchInterface(interfacePayload);
  };
  useEffect(() => {
    fetchInterface();
  }, [interfacePayload]);

  useEffect(() => {
    console.log(nasAutoSearchResults, "nasAutoSearchResults");
    if (nasAutoSearchResults && nasAutoSearchResults.data.length !== 0) {
      const results = [...nasOptions, ...nasAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setNasOptions(filteredResults);
    }
  }, [nasAutoSearchResults]);

  useEffect(() => {
    console.log(interfaceAutoSearchResults, "interfaceAutoSearchResults");
    if (interfaceAutoSearchResults && interfaceAutoSearchResults.data.length !== 0) {
      const results = [...interfaceOptions, ...interfaceAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setInterfaceOptions(filteredResults);
    }
  }, [interfaceAutoSearchResults]);

  return (
    <Grid container spacing={6} className="row">
      <Grid item md={6}>
        <SearchableAutoComplete
          name="nas"
          label={t("nas")}
          control={control}
          data={nasOptions}
          payload={nasPayload}
          setPayload={setNasPayload}
          loading={false}
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={fields}
          fullWidth
          variant="outlined"
          errors={errors}
        ></SearchableAutoComplete>
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("address_name")}
          name="address"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <SearchableAutoComplete
          name="interfaces"
          label={t("interfaces_name")}
          control={control}
          data={interfaceOptions}
          payload={interfacePayload}
          setPayload={setInterfacePayload}
          loading={false}
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={fields}
          fullWidth
          variant="outlined"
          errors={errors}
        ></SearchableAutoComplete>
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          name="status"
          label={t("Status")}
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
}

export default AddressesFields;
