import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import InterfaceReducer from "../reducers/InterfaceReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { InterfaceConstants } from "../constants/interface.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  interfaces: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  interfaceSearchResults: [],
  interface: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  interfaceAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const InterfaceContext = createContext({
  ...initialState,
  getAllInterface: () => {},
  addInterface: () => {},
  searchInterface: () => {},
  autoSearchInterface: () => {},
  getInterface: () => {},
  updateInterface: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function InterfaceProvider({ children }) {
  const [state, dispatch] = useReducer(InterfaceReducer, initialState);

  const getAllInterface = async (payload) => {
    try {
      dispatch({
        type: InterfaceConstants.FETCH_INTERFACE_REQUEST,
      });
      const response = await axios.post("/master/interface/list", payload);
      dispatch({
        type: InterfaceConstants.FETCH_INTERFACE_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: InterfaceConstants.FETCH_INTERFACE_FAILURE,
      });
    }
  };
  const searchInterface = async (payload) => {
    try {
      const response = await axios.post("/master/interface/search", payload);
      dispatch({
        type: InterfaceConstants.INTERFACE_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: InterfaceConstants.INTERFACE_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: InterfaceConstants.INTERFACE_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchInterface = async (payload) => {
    try {
      const response = await axios.post("/interface/auto-search", payload);
      dispatch({
        type: InterfaceConstants.INTERFACE_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: InterfaceConstants.INTERFACE_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: InterfaceConstants.INTERFACE_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addInterface = async (payload) => {
    const response = await axios.post("/master/interface/add", payload);
    dispatch({
      type: InterfaceConstants.ADD_INTERFACE_REQUEST,
    });
    dispatch({
      type: InterfaceConstants.ADD_INTERFACE_SUCCESS,
      payload: response.data,
    });
  };

  const getInterface = async (id) => {
    const response = await axios.get("/master/interface/" + id);
    dispatch({
      type: InterfaceConstants.GET_INTERFACE_REQUEST,
    });
    dispatch({
      type: InterfaceConstants.GET_INTERFACE_SUCCESS,
      payload: response.data,
    });
  };

  const updateInterface = async (payload) => {
    const response = await axios.put("/master/interface/update/" + payload.interfaceId, payload);
    dispatch({
      type: InterfaceConstants.UPDATE_INTERFACE_REQUEST,
    });
    dispatch({
      type: InterfaceConstants.UPDATE_INTERFACE_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: InterfaceConstants.DOWNLOAD_INTERFACE_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/master/interface/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "interface." + fileType);
    dispatch({
      type: InterfaceConstants.DOWNLOAD_INTERFACE_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/master/interface/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_interface." + fileType);
    dispatch({
      type: InterfaceConstants.DOWNLOAD_INTERFACE_REQUEST,
    });
    dispatch({
      type: InterfaceConstants.DOWNLOAD_INTERFACE_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: InterfaceConstants.UPLOAD_INTERFACE_REQUEST,
      });
      const response = await axios({
        url: "/master/interface/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: InterfaceConstants.UPLOAD_INTERFACE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: InterfaceConstants.UPLOAD_INTERFACE_FAILURE,
      });
    }
  };

  return (
    <InterfaceContext.Provider
      value={{
        ...state,
        getAllInterface,
        addInterface,
        searchInterface,
        autoSearchInterface,
        getInterface,
        updateInterface,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  );
}

export { InterfaceContext, InterfaceProvider };
