import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Card, CardContent, CardHeader } from "@mui/material";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";

import MappingToolBar from "../../../../components/MappingToolbar";
import MapNasModal from "./MapNasModal";
import { useDialog } from "../../../../contexts/DialogContext";
import { getTimeStamp } from "../../../../utils/common";
import MappedNas from "./MappedNas";

const AllowedNas = ({ nasGroupId }) => {
  const { openDialog } = useDialog();

  const openDownloadDialog = () => {
    return openDialog(
      "mapping_nas",
      "Mapping Nas",
      <MapNasModal nasGroupId={nasGroupId} key={getTimeStamp()} isNew={true} />,
    );
  };

  return (
    <Card mb={6}>
      <CardHeader action={<MappingToolBar openDialog={openDownloadDialog} />} />
      <CardContent>
        <MappedNas nasGroupId={nasGroupId} />
      </CardContent>
    </Card>
  );
};

export default AllowedNas;
