//Masters

import RenewalReports from "../pages/reports/renewal_reports";
import DueReports from "../pages/reports/due_reports";
import CollectionReports from "../pages/reports/collection_reports";
import WalletReports from "../pages/reports/wallet_reports";
import SharingReports from "../pages/reports/sharing_reports";
import GstInvoice from "../pages/reports/gst_invoices/index.js";
import MaterialList from "../pages/reports/material_reports/index.js";
import HotelActivationList from "../pages/reports/hotel_activation_report/index.js";
//Composers
import PaidInvoiceComposer from "../composers/PaidInvoiceComposer.js";
import { ReportComposer } from "../composers/ReportComposer";
import { DashboardComposer } from "../composers/DashboardComposer";
import MaterialComposer from "../composers/MaterialComposer.js";
import HotelManagementComposer from "../composers/HotelManagementComposer.js";

// Guards
import AuthGuard from "../components/guards/AuthGuard";
import DashboardLayout from "../layouts/Dashboard";
import { BalanceStatementComposer } from "../composers/BalanceStatementComposefr.js";
import BalanceStatement from "../pages/reports/balance_statement/index.js";
const ReportRoutes = {
  path: "reports",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "renewal",
      element: (
        <ReportComposer>
          <RenewalReports />
        </ReportComposer>
      ),
    },
    {
      path: "dues",
      element: (
        <ReportComposer>
          <DueReports />
        </ReportComposer>
      ),
    },
    {
      path: "collection",
      element: (
        <ReportComposer>
          <CollectionReports />
        </ReportComposer>
      ),
    },
    {
      path: "wallet",
      element: (
        <ReportComposer>
          <WalletReports />
        </ReportComposer>
      ),
    },
    {
      path: "revenueshare",
      element: (
        <ReportComposer>
          <SharingReports />
        </ReportComposer>
      ),
    },
    {
      children: [
        {
          path: "gst-invoices",
          element: (
            <PaidInvoiceComposer>
              <GstInvoice />
            </PaidInvoiceComposer>
          ),
        },
        {
          path: "balance-statement",
          element: (
            <BalanceStatementComposer>
              <BalanceStatement />
            </BalanceStatementComposer>
          ),
        },
        {
          path: "material-reports",
          element: (
            <MaterialComposer>
              <MaterialList />
            </MaterialComposer>
          ),
        },
        {
          path: "hotel-activation-reports",
          element: (
            <HotelManagementComposer>
              <HotelActivationList />
            </HotelManagementComposer>
          ),
        },
      ],
    },
  ],
};

export default ReportRoutes;
