import { useContext } from "react";

import { SmsToUserContext } from "../contexts/SmsToUserContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useSmsToUser = () => {
  const context = useContext(SmsToUserContext);

  if (!context) throw new Error("SmsToUserConstants must be placed within SmsToUsersProvider");

  return context;
};

export default useSmsToUser;
