import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useDispatch } from "react-redux";
import CustomTextField from "../../../components/CustomTextField";
import { useLocation } from "react-router-dom";
import ReactHookFormSelect from "../../../components/ReactHookFormSelect";
import { useDialog } from "../../../contexts/DialogContext";
import useUser from "../../../hooks/useUser";
import SearchableAutoComplete from "../../../components/SearchableAutoComplete";
import useTenant from "../../../hooks/useTenant";
import useRate from "../../../hooks/useRate";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import useEmployee from "../../../hooks/useEmployee";
import useUnpaidInvoice from "../../../hooks/useUnpaidInvoice";
import CustomFileField from "../../../components/CustomFileField";
import useKycDocument from "../../../hooks/useKycDocument";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const validationSchema = Yup.object().shape({
  file: Yup.mixed()
    .required("A file is required")
    .test("fileFormat", "Only support pdf and image file", (value) => {
      console.log(value, "ffffffffffff");
      return (
        value && ["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(value.type)
      );
    }),
  documentType: Yup.string().required("Required"),
});

const Alert = styled(MuiAlert)(spacing);

const UploadId = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [payload, setPayload] = useState(currentPayload);
  const { radUserId, refreshPage, id, kycDocument, dropDownValues } = props;

  console.log(dropDownValues, "dropDownValues");
  const { addKycDocument, kycDocuments, getKycDocument, uploadFile, isSubmitting } =
    useKycDocument();

  const initialValues = {
    radUserId: radUserId,
    documentType: "",
    file: null,
  };

  //

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;
  const { unSetDialog, openDialog } = useDialog();

  const onSubmit = async (data, props) => {
    try {
      console.log(data, "datadata");
      let formData = new FormData();
      formData.append("file", data.file);
      formData.append("radUserId", radUserId);
      formData.append("documentType", data.documentType);
      // formData.append(
      //   "payload",
      //   JSON.stringify({
      //     radUserId: radUserId,
      //     documentType: data.documentType,
      //   }),
      // );

      const { idProof, addressProof } = props;

      const params = {
        radUserId: radUserId,
        documentType: data.documentType,
        file: formData,
      };

      await uploadFile(formData);
      dispatch(showSuccessSnackbar("Id Document added successfully!"));
      unSetDialog("upload_id");
    } catch (ex) {
      dispatch(showErrorSnackbar(ex.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <ReactHookFormSelect
                name="documentType"
                label="DocumentType"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
                fields={validationSchema.fields}
              >
                {dropDownValues.map((dropDownValue) => {
                  return <MenuItem value={dropDownValue.key}>{dropDownValue.value}</MenuItem>;
                })}
              </ReactHookFormSelect>
            </Grid>

            <Grid item md={6}>
              <CustomFileField
                label="Upload"
                name="file"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
                fields={validationSchema.fields}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<UploadFileIcon />}
              endIcon={isSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Upload
            </Button>

            <Button variant="text" onClick={() => unSetDialog("upload_id")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default UploadId;
