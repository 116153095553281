export const TalukConstants = {
  FETCH_TALUK_REQUEST: "FETCH_TALUK_REQUEST",
  FETCH_TALUK_SUCCESS: "FETCH_TALUK_SUCCESS",
  FETCH_TALUK_FAILURE: "FETCH_TALUK_FAILURE",
  ADD_TALUK_REQUEST: "ADD_TALUK_REQUEST",
  ADD_TALUK_SUCCESS: "ADD_TALUK_SUCCESS",
  ADD_TALUK_FAILURE: "ADD_TALUK_FAILURE",
  EDIT_TALUK_REQUEST: "EDIT_TALUK_REQUEST",
  EDIT_TALUK_SUCCESS: "EDIT_TALUK_SUCCESS",
  EDIT_TALUK_FAILURE: "EDIT_TALUK_FAILURE",
  TALUK_SEARCH_REQUEST: "TALUK_SEARCH_REQUEST",
  TALUK_SEARCH_SUCCESS: "TALUK_SEARCH_SUCCESS",
  TALUK_SEARCH_FAILURE: "TALUK_SEARCH_FAILURE",
  GET_TALUK_REQUEST: "GET_TALUK_REQUEST",
  GET_TALUK_SUCCESS: "GET_TALUK_SUCCESS",
  GET_TALUK_FAILURE: "GET_TALUK_FAILURE",
  UPDATE_TALUK_REQUEST: "UPDATE_TALUK_REQUEST",
  UPDATE_TALUK_SUCCESS: "UPDATE_TALUK_SUCCESS",
  UPDATE_TALUK_FAILURE: "UPDATE_TALUK_FAILURE",
  DOWNLOAD_TALUK_REQUEST: "DOWNLOAD_TALUK_REQUEST",
  DOWNLOAD_TALUK_SUCCESS: "DOWNLOAD_TALUK_SUCCESS",
  DOWNLOAD_TALUK_FAILURE: "DOWNLOAD_TALUK_FAILURE",
  UPLOAD_TALUK_REQUEST: "UPLOAD_TALUK_REQUEST",
  UPLOAD_TALUK_SUCCESS: "UPLOAD_TALUK_SUCCESS",
  UPLOAD_TALUK_FAILURE: "UPLOAD_TALUK_FAILURE",
  TALUK_AUTO_SEARCH_REQUEST: "TALUK_AUTO_SEARCH_REQUEST",
  TALUK_AUTO_SEARCH_SUCCESS: "TALUK_AUTO_SEARCH_SUCCESS",
  TALUK_AUTO_SEARCH_FAILURE: "TALUK_AUTO_SEARCH_FAILURE",
};
