import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { SmsToEmployeeProvider } from "../contexts/SmsToEmployeeContext";
import { DialogProvider } from "../contexts/DialogContext";

export const SmsToEmployeeComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<SmsToEmployeeProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};

export default SmsToEmployeeComposer;
