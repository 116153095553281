import React from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar, showErrorSnackbar } from "../../redux/slices/snackbar";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  CardHeader,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider } from "react-hook-form";

import SaveCancel from "../../components/SaveCancel";
import useLead from "../../hooks/useLead";
import LeadFields from "./components/LeadFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const initialValues = {
  tenant: "",
  branchName: "",
  employee: "",
  customerName: "",
  status: "",
  leadType: "",
  address: "",
  mobileNo: "",
  email: "",
  comments: "",
};

const validationSchema = Yup.object().shape({
  employee: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  tenant: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  branchName: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  leadType: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  customerName: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  mobileNo: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/, "Must be a valid phone number with up to 10 digits"),
  comments: Yup.string().required("Required"),
});

function AddLead() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { addLead, isSubmitting } = useLead();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: {},
  } = methods;

  const onSubmit = async (data) => {
    try {
      data["tenant"] = {
        tenantId: data.tenant.value,
        tenantName: data.tenant.label,
      };
      if (data.employee !== "") {
        data["employee"] = {
          employeeId: data.employee.value,
          employeeName: data.employee.label,
        };
      }
      data["branch"] = {
        branchId: data.branchName.value,
        branchName: data.branchName.label,
      };

      console.log(data, "lead data fff");

      await addLead(data);
      dispatch(showSuccessSnackbar("Lead added successfully!"));
      navigate("/leads/list");
      reset();
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to add Lead"));
    }
  };

  const cancelBtn = () => {
    navigate("/leads/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Lead</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_lead")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <LeadFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddLead;
