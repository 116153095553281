import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";

import SaveCancel from "../../../../components/SaveCancel";
import useWallet from "../../../../hooks/useWallet";
import TabPanel from "../../../../components/TabPanel";
import { a11yProps } from "../../../../utils/common";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import useAuth from "../../../../hooks/useAuth";
import SmsNotification from "../sms_notifications/components/notification";
import useSetting from "../../../../hooks/useSetting";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const digitsOnly = (value) => /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0;

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function Sms() {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const { getAllTenantSmsTemplates, tenantSmsTemplates, updateSmsSetting } = useSetting();

  const methods = useForm();

  console.log(user, "useruser111");

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  React.useEffect(() => {
    if (tenantSmsTemplates.length != 0) {
      let initialValues = {};
      for (let index in tenantSmsTemplates) {
        const tenantSmsTemplate = tenantSmsTemplates[index];
        initialValues["tenantSmsId-" + tenantSmsTemplate.tenantSmsId] = tenantSmsTemplate.enabled;
      }
      console.log(initialValues, "initialValues");
      reset(initialValues);
    }
  }, [tenantSmsTemplates]);

  const { state } = useLocation();

  React.useEffect(() => {
    if (state != null) {
      console.log(state, "statestatestate1");
      const payloadValues = {
        ...payload,
        filters: [
          {
            fieldName: "tenantId",
            fieldValue: state?.id,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      };
      console.log(payloadValues, "payloadValues");
      fetchData(payloadValues);
      //console.log(payloadValues, "payloadValues");
    }
  }, [state]);
  console.log(state, "location");

  const { t } = useTranslation();
  const { addWallet, hasError, errorMessage, isSubmitting, paymentTrans, addPaymentTrans } =
    useWallet();
  const [tabValue, setTabValue] = React.useState(0);

  const [isOnline, setIsOnline] = React.useState(false);

  const [payload, setPayload] = useState(currentPayload);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>cpanel</Typography>
        <Typography>tenant</Typography>
        <Typography>sms setting</Typography>
      </Breadcrumbs>
    );
  };

  useEffect(() => {
    if (user && user.businessType != "SUPER_ADMIN") {
      setIsOnline(true);
      setValue("tenant", {
        label: user.name,
        value: user.tenantId,
      });
    }
  }, [user]);

  useEffect(() => {
    if (paymentTrans) {
      console.log(paymentTrans, "paymentTranspaymentTrans");
      if (paymentTrans.actionUrl != "") {
        let form = document.createElement("form");
        var element1 = document.createElement("input");
        var element2 = document.createElement("input");

        form.method = "POST";
        form.action = paymentTrans.actionUrl;

        element1.type = "hidden";
        element1.value = paymentTrans.encRequest;
        element1.name = "encRequest";
        form.appendChild(element1);

        element2.type = "hidden";
        element2.value = paymentTrans.accessCode;
        element2.name = "access_code";
        form.appendChild(element2);

        document.body.appendChild(form);

        form.submit();
        //navigate(paymentTrans.actionUrl, { replace: true });
        //window.location = paymentTrans.actionUrl;
      }
    }
  }, [paymentTrans]);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_status = params.get("order_status");
  console.log(order_status, "order_statusorder_status");

  useEffect(() => {
    if (order_status != null) {
      dispatch(showSuccessSnackbar("The transaction of the status is " + order_status));
    }
  }, [order_status]);

  const onSubmit = async (data) => {
    try {
      console.log(data, "datadatadatadata");
      let tenantSmsResults = [];
      for (const [key, value] of Object.entries(data)) {
        const tenantSmsSplit = key.split("-");
        tenantSmsResults.push({
          tenantSmsId: tenantSmsSplit[1],
          isEnabled: value,
        });
      }
      await updateSmsSetting(tenantSmsResults);
      dispatch(showSuccessSnackbar("Sms template updated successfully!"));
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
  };

  const cancelBtn = () => {
    navigate("/cpanel/tenant/list");
  };

  const fetchData = async (params) => {
    await getAllTenantSmsTemplates(params);
  };

  // useEffect(() => {
  //   fetchData();
  // }, [payload]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("sms_settings")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={tabValue} onChange={handleTabChange} aria-label="Notification">
                    <Tab label="Notification" {...a11yProps(0)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <SmsNotification
                      validationFields={null}
                      tenantSmsTemplates={tenantSmsTemplates}
                    />
                  </TabPanel>
                </Box>
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default Sms;
