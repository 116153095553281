import React, { createContext, useCallback, useContext, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { css } from "@emotion/react";

const DialogContext = createContext();

const StyledDialogTitle = styled((props) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
})`
  & .MuiTypography-root {
    display: inline;
  }

  & .MuiIconButton-root {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  ${({ theme }) => css`
    .MuiTypography-root {
      color: ${theme.palette.primary.main};
    }

    .MuiIconButton-root {
      color: ${theme.palette.secondary.main};
    }
  `}
`;

const CustomDialog = ({ dialogId, title, content, onClose, maxWidth, height }) => {
  return (
    <Dialog
      disableEnforceFocus
      disableEscapeKeyDown
      open={Boolean(dialogId)}
      keepMounted
      onClose={onClose}
      fullWidth
      maxWidth={maxWidth}
    >
      <StyledDialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </StyledDialogTitle>
      <DialogContent style={{ height: "calc(100% - 64px)", width: "auto" }}>
        {content}
      </DialogContent>
    </Dialog>
  );
};

const DialogProvider = ({ children }) => {
  const [dialogs, setDialogs] = useState([]);

  const openDialog = (dialogId, title, content, maxWidth = "sm", height = "120px") => {
    setDialogs((prevDialogs) => [
      ...prevDialogs.filter((dialog) => dialog.dialogId !== dialogId),
      { dialogId, title, content, maxWidth, height },
    ]);
  };

  const unSetDialog = (dialogId) => {
    console.log(dialogId, "dialogIddialogId");
    setDialogs((prevDialogs) => prevDialogs.filter((dialog) => dialog.dialogId !== dialogId));
  };

  return (
    <DialogContext.Provider value={{ openDialog, unSetDialog }}>
      {children}
      {dialogs.map((dialog) => (
        <CustomDialog
          key={dialog.dialogId}
          dialogId={dialog.dialogId}
          title={dialog.title}
          content={dialog.content}
          onClose={() => unSetDialog(dialog.dialogId)}
          maxWidth={dialog.maxWidth}
          height={dialog.height}
        />
      ))}
    </DialogContext.Provider>
  );
};

const useDialog = () => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};

export { DialogProvider, useDialog };
