import { useContext } from "react";

import { EmailLogContext } from "../contexts/EmailLogContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useEmail = () => {
  const context = useContext(EmailLogContext);

  if (!context) throw new Error("EmailLogConstants must be placed within EmailLogsProvider");

  return context;
};

export default useEmail;
