import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { WalletProvider } from "../contexts/WalletContext";
import { DialogProvider } from "../contexts/DialogContext";
import { FormProvider } from "../contexts/WalletFormContext";
import { TenantProvider } from "../contexts/TenantContext";

export const WalletComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <WalletProvider key={0} />,
        <FormProvider key={1} />,
        <TenantProvider key={2} />,
        <DialogProvider key={3} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
