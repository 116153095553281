import { InventoryManagementConstants } from "../constants/inventorymanagement.constants";

const InventoryManagementReducer = (state, action) => {
  switch (action.type) {
    case InventoryManagementConstants.FETCH_INVENTORY_MANAGEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case InventoryManagementConstants.FETCH_INVENTORY_MANAGEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        inventoryManagements: action.payload.data,
        paging: action.payload.paging,
      };
    case InventoryManagementConstants.FETCH_INVENTORY_MANAGEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case InventoryManagementConstants.INVENTORY_MANAGEMENT_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case InventoryManagementConstants.INVENTORY_MANAGEMENT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        inventoryManagementSearchResults: action.payload,
      };
    case InventoryManagementConstants.INVENTORY_MANAGEMENT_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case InventoryManagementConstants.INVENTORY_MANAGEMENT_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case InventoryManagementConstants.INVENTORY_MANAGEMENT_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        inventoryManagementAutoSearchResults: action.payload,
      };
    case InventoryManagementConstants.INVENTORY_MANAGEMENT_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case InventoryManagementConstants.ADD_INVENTORY_MANAGEMENT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case InventoryManagementConstants.ADD_INVENTORY_MANAGEMENT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case InventoryManagementConstants.ADD_INVENTORY_MANAGEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case InventoryManagementConstants.GET_INVENTORY_MANAGEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case InventoryManagementConstants.GET_INVENTORY_MANAGEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        inventoryManagement: action.payload,
      };
    case InventoryManagementConstants.GET_INVENTORY_MANAGEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case InventoryManagementConstants.UPDATE_INVENTORY_MANAGEMENT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case InventoryManagementConstants.UPDATE_INVENTORY_MANAGEMENT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        inventoryManagement: action.payload,
      };
    case InventoryManagementConstants.UPDATE_INVENTORY_MANAGEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case InventoryManagementConstants.DOWNLOAD_INVENTORY_MANAGEMENT_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case InventoryManagementConstants.DOWNLOAD_INVENTORY_MANAGEMENT_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case InventoryManagementConstants.DOWNLOAD_INVENTORY_MANAGEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case InventoryManagementConstants.UPLOAD_INVENTORY_MANAGEMENT_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case InventoryManagementConstants.UPLOAD_INVENTORY_MANAGEMENT_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case InventoryManagementConstants.UPLOAD_INVENTORY_MANAGEMENT_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default InventoryManagementReducer;
