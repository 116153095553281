export const ComplaintConstants = {
  FETCH_COMPLAINT_REQUEST: "FETCH_COMPLAINT_REQUEST",
  FETCH_COMPLAINT_SUCCESS: "FETCH_COMPLAINT_SUCCESS",
  FETCH_COMPLAINT_FAILURE: "FETCH_COMPLAINT_FAILURE",
  FETCH_COMPLAINT_HISTORY_REQUEST: "FETCH_COMPLAINT_HISTORY_REQUEST",
  FETCH_COMPLAINT_HISTORY_SUCCESS: "FETCH_COMPLAINT_HISTORY_SUCCESS",
  FETCH_COMPLAINT_HISTORY_FAILURE: "FETCH_COMPLAINT_HISTORY_FAILURE",
  FETCH_COMPLAINT_STATS_REQUEST: "FETCH_COMPLAINT_STATS_REQUEST",
  FETCH_COMPLAINT_STATS_SUCCESS: "FETCH_COMPLAINT_STATS_SUCCESS",
  FETCH_COMPLAINT_STATS_FAILURE: "FETCH_COMPLAINT_STATS_FAILURE",

  COMPLAINT_ASSIGN_REQUEST: "COMPLAINT_ASSIGN_REQUEST",
  COMPLAINT_ASSIGN_SUCCESS: "COMPLAINT_ASSIGN_SUCCESS",
  COMPLAINT_ASSIGN_FAILURE: "COMPLAINT_ASSIGN_FAILURE",

  ADD_COMPLAINT_REQUEST: "ADD_COMPLAINT_REQUEST",
  ADD_COMPLAINT_SUCCESS: "ADD_COMPLAINT_SUCCESS",
  ADD_COMPLAINT_FAILURE: "ADD_COMPLAINT_FAILURE",
  COMPLAINT_SEARCH_REQUEST: "COMPLAINT_SEARCH_REQUEST",
  COMPLAINT_SEARCH_SUCCESS: "COMPLAINT_SEARCH_SUCCESS",
  COMPLAINT_SEARCH_FAILURE: "COMPLAINT_SEARCH_FAILURE",
  COMPLAINT_AUTO_SEARCH_REQUEST: "COMPLAINT_AUTO_SEARCH_REQUEST",
  COMPLAINT_AUTO_SEARCH_SUCCESS: "COMPLAINT_AUTO_SEARCH_SUCCESS",
  COMPLAINT_AUTO_SEARCH_FAILURE: "COMPLAINT_AUTO_SEARCH_FAILURE",

  GET_COMPLAINT_REQUEST: "GET_COMPLAINT_REQUEST",
  GET_COMPLAINT_SUCCESS: "GET_COMPLAINT_SUCCESS",
  GET_COMPLAINT_FAILURE: "GET_COMPLAINT_FAILURE",

  GET_COMPLAINT_ASSIGN_REQUEST: "GET_COMPLAINT_ASSIGN_REQUEST",
  GET_COMPLAINT_ASSIGN_SUCCESS: "GET_COMPLAINT_ASSIGN_SUCCESS",
  GET_COMPLAINT_ASSIGN_FAILURE: "GET_COMPLAINT_ASSIGN_FAILURE",

  GET_COMPLAINT_HISTORY_REQUEST: "GET_COMPLAINT_HISTORY_REQUEST",
  GET_COMPLAINT_HISTORY_SUCCESS: "GET_COMPLAINT_HISTORY_SUCCESS",
  GET_COMPLAINT_HISTORY_FAILURE: "GET_COMPLAINT_HISTORY_FAILURE",
  GET_COMPLAINT_STATS_REQUEST: "GET_COMPLAINT_STATS_REQUEST",
  GET_COMPLAINT_STATS_SUCCESS: "GET_COMPLAINT_STATS_SUCCESS",
  GET_COMPLAINT_STATS_FAILURE: "GET_COMPLAINT_STATS_FAILURE",

  UPDATE_COMPLAINT_REQUEST: "UPDATE_COMPLAINT_REQUEST",
  UPDATE_COMPLAINT_SUCCESS: "UPDATE_COMPLAINT_SUCCESS",
  UPDATE_COMPLAINT_FAILURE: "UPDATE_COMPLAINT_FAILURE",
  DOWNLOAD_COMPLAINT_REQUEST: "DOWNLOAD_COMPLAINT_REQUEST",
  DOWNLOAD_COMPLAINT_SUCCESS: "DOWNLOAD_COMPLAINT_SUCCESS",
  DOWNLOAD_COMPLAINT_FAILURE: "DOWNLOAD_COMPLAINT_FAILURE",
  UPLOAD_COMPLAINT_REQUEST: "UPLOAD_COMPLAINT_REQUEST",
  UPLOAD_COMPLAINT_SUCCESS: "UPLOAD_COMPLAINT_SUCCESS",
  UPLOAD_COMPLAINT_FAILURE: "UPLOAD_COMPLAINT_FAILURE",
};
