import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Breadcrumbs as MuiBreadcrumbs, Grid } from "@mui/material";
import { spacing } from "@mui/system";

import CustomDataGrid from "./components/datagrid";
import useDistrict from "../../../hooks/useDistrict";
import useTaluk from "../../../hooks/useTaluk";

import useVillage from "../../../hooks/useVillage";
import usePincode from "../../../hooks/usePincode";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import Upload from "./components/Upload";

function DataGridPage() {
  const { getAllPincode, states, paging, isLoading, downloadFile } = usePincode();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  console.log(usePincode(), "paging");

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllPincode(payload);
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const addPincode = () => {
    navigate("/masters/pincode/add");
  };
  const editPincode = (id) => {
    navigate("/masters/pincode/edit", { state: { id: id } });
  };
  const exportFile = async (fileType) => {
    await downloadFile(fileType, payload);
  };
  const openDownloadDialog = () => {
    return openDialog("upload_pincode", "Upload Pincode", <Upload key={getTimeStamp()} />);
  };

  const handleSortModelChange = (newModel) => {
    //console.log(newModel, "newModel");
    if (newModel.length != 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CustomDataGrid
            trans={t}
            addPage={addPincode}
            editPage={editPincode}
            refreshPage={fetchData}
            downloadFile={exportFile}
            openDownloadDialog={openDownloadDialog}
            id="pincodeId"
            data={states ? states : []}
            rowCount={paging.totalNumberOfRecords}
            paging={{
              page: paging.pageNumber,
              pageSize: paging.pageSize,
            }}
            onPageChange={onPageChange}
            isLoading={isLoading}
            sortModel={sortModel}
            handleSortModelChange={handleSortModelChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DataGridPage;
