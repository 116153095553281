export const DistrictConstants = {
  FETCH_DISTRICT_REQUEST: "FETCH_DISTRICT_REQUEST",
  FETCH_DISTRICT_SUCCESS: "FETCH_DISTRICT_SUCCESS",
  FETCH_DISTRICT_FAILURE: "FETCH_DISTRICT_FAILURE",
  ADD_DISTRICT_REQUEST: "ADD_DISTRICT_REQUEST",
  ADD_DISTRICT_SUCCESS: "ADD_DISTRICT_SUCCESS",
  ADD_DISTRICT_FAILURE: "ADD_DISTRICT_FAILURE",
  EDIT_DISTRICT_REQUEST: "EDIT_DISTRICT_REQUEST",
  EDIT_DISTRICT_SUCCESS: "EDIT_DISTRICT_SUCCESS",
  EDIT_DISTRICT_FAILURE: "EDIT_DISTRICT_FAILURE",
  DISTRICT_SEARCH_REQUEST: "DISTRICT_SEARCH_REQUEST",
  DISTRICT_SEARCH_SUCCESS: "DISTRICT_SEARCH_SUCCESS",
  DISTRICT_SEARCH_FAILURE: "DISTRICT_SEARCH_FAILURE",
  GET_DISTRICT_REQUEST: "GET_DISTRICT_REQUEST",
  GET_DISTRICT_SUCCESS: "GET_DISTRICT_SUCCESS",
  GET_DISTRICT_FAILURE: "GET_DISTRICT_FAILURE",
  UPDATE_DISTRICT_REQUEST: "UPDATE_DISTRICT_REQUEST",
  UPDATE_DISTRICT_SUCCESS: "UPDATE_DISTRICT_SUCCESS",
  UPDATE_DISTRICT_FAILURE: "UPDATE_DISTRICT_FAILURE",
  DOWNLOAD_DISTRICT_REQUEST: "DOWNLOAD_DISTRICT_REQUEST",
  DOWNLOAD_DISTRICT_SUCCESS: "DOWNLOAD_DISTRICT_SUCCESS",
  DOWNLOAD_DISTRICT_FAILURE: "DOWNLOAD_DISTRICT_FAILURE",
  UPLOAD_DISTRICT_REQUEST: "UPLOAD_DISTRICT_REQUEST",
  UPLOAD_DISTRICT_SUCCESS: "UPLOAD_DISTRICT_SUCCESS",
  UPLOAD_DISTRICT_FAILURE: "UPLOAD_DISTRICT_FAILURE",
  DISTRICT_AUTO_SEARCH_REQUEST: "DISTRICT_AUTO_SEARCH_REQUEST",
  DISTRICT_AUTO_SEARCH_SUCCESS: "DISTRICT_AUTO_SEARCH_SUCCESS",
  DISTRICT_AUTO_SEARCH_FAILURE: "DISTRICT_AUTO_SEARCH_FAILURE",
};
