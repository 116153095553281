import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import EditCancel from "../../../components/EditCancel";
import useNas from "../../../hooks/useNas";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import NasFields from "./components/NasFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const validationSchema = Yup.object().shape({
  nasName: Yup.string().required("Required"),
  nasType: Yup.string().required("Required"),
  apiUserName: Yup.string().when("nasType", {
    is: (val) => (val && val == "Mikrotik" ? true : false),
    then: () => Yup.string().required("Api username is required"),
  }),
  mkApiPort: Yup.string().when("nasType", {
    is: (val) => (val && val == "Mikrotik" ? true : false),
    then: () => Yup.string().required("Api port is required"),
  }),
  shortName: Yup.string()
    .required("Required")
    .matches(
      /^[a-zA-Z0-9_]*$/g,
      "Nas name should be alpha numberic and underscore, there is no space allowed",
    ),
  status: Yup.string().required("Required"),
});

function EditNas() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getNas, nas, updateNas, isSubmitting } = useNas();

  const { state } = useLocation();
  console.log(state, "location");
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    if (Object.keys(nas).length !== 0) {
      const initialValues = {
        nasId: nas.nasId,
        nasName: nas.nasName,
        nasType: nas.nasType,
        shortName: nas.shortName,
        apiUserName: nas.apiUserName,
        apiPassword: nas.apiPassword,
        mkApiPort: nas.mkApiPort,
        secret: nas.secret,
        status: nas.status,
        notes: nas.notes,
      };
      reset(initialValues);
    }
  }, [nas]);

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      await updateNas(data);
      reset();
      dispatch(showSuccessSnackbar("Nas updated successfully!"));
      navigate("/radius/nas/list");
    } catch (error) {
      console.log(error);
      dispatch(showErrorSnackbar(error.message));
    }
  };
  const cancelBtn = () => {
    navigate("/radius/nas/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Radius</Typography>
        <Typography>Nas</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getNas(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_nas")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <NasFields fields={validationSchema.fields} />
                <EditCancel cancelBtn={cancelBtn} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditNas;
