import { useContext } from "react";

import { ReportContext } from "../contexts/ReportContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useReport = () => {
  const context = useContext(ReportContext);

  if (!context) throw new Error("ReportContext must be placed within ReportProvider");

  return context;
};

export default useReport;
