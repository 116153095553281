import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { useForm, FormProvider, useWatch } from "react-hook-form";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import SaveCancel from "../../../components/SaveCancel";
import useVillage from "../../../hooks/useVillage";
import VillageFields from "./components/VillageFields";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Card = styled(MuiCard)(spacing);

const validationSchema = Yup.object().shape({
  villageName: Yup.string().required("Required"),
  city: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  taluk: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  district: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  state: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  country: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  pincode: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  status: Yup.string().required("Required"),
});

function BasicForm(props) {
  const dispatch = useDispatch();
  const [isSubmitBtn, setIsSubmitBtn] = useState(false);
  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });
  const { getVillage, village, updateVillage, isSubmitting } = useVillage();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;
  const fetchData = async () => {
    await getVillage(state.id);
  };

  const { state } = useLocation();

  const { t } = props;
  React.useEffect(() => {
    if (Object.keys(village).length !== 0) {
      const initialValues = {
        villageId: village.villageId,
        villageName: village.villageName,
        country: {
          value: village.city?.taluk?.district?.state?.country?.countryId,
          label: village.city?.taluk?.district?.state?.country?.countryName,
        },
        state: {
          value: village.city?.taluk?.district?.state?.stateId,
          label: village.city?.taluk?.district?.state?.stateName,
        },
        district: {
          value: village.city?.taluk?.district?.districtId,
          label: village.city?.taluk?.district?.districtName,
        },
        taluk: {
          value: village.city?.taluk?.talukId,
          label: village.city?.taluk?.talukName,
        },
        pincode: {
          value: village.pincode?.pincodeId,
          label: village.pincode?.pincodeName,
        },
        city: {
          value: village.city?.cityId,
          label: village.city?.cityName,
        },
        status: village.status,
      };
      reset(initialValues);
    }
  }, [village]);

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    setIsSubmitBtn(true);
    try {
      const villageDTO = {
        villageId: data.villageId,
        country: {
          countryId: data.country.value,
        },
        state: {
          stateId: data.state.value,
        },
        district: {
          districtId: data.district.value,
        },
        taluk: {
          talukId: data.taluk.value,
        },
        city: {
          cityId: data.city.value,
        },
        pincode: {
          pincodeId: data.pincode.value,
        },
        status: data.status,
        villageName: data.villageName,
      };
      //console.log(districtDTO, "districtDTO");
      await updateVillage(villageDTO);
      dispatch(showSuccessSnackbar("Village updated successfully!"));
      navigate("/masters/village/list");
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
    return false;
  };
  console.log(state, "location");
  const navigate = useNavigate();

  const cancelBtn = () => {
    navigate("/masters/village/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_village")} subheader={"We can edit village here"} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <VillageFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}
function EditVillage() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}
export default EditVillage;
