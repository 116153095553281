import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../components/CustomTextField";
import ReactHookFormSelect from "../../../components/ReactHookFormSelect";

function ComplaintTypeFields() {
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Grid container spacing={6} className="row">
      <Grid item md={6}>
        <CustomTextField
          label={t("complaint_type_name")}
          name="complaintTypeName"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
        />
      </Grid>

      <Grid item md={6}>
        <CustomTextField
          label={t("notes")}
          name="notes"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
        />
      </Grid>

      {/* <Grid item md={6}>
        <CustomTextField
          label={t("country")}
          name="country"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
        />
      </Grid> */}

      <Grid item md={6}>
        <ReactHookFormSelect
          name="status"
          label={t("status")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
}

export default ComplaintTypeFields;
