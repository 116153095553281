import * as React from "react";

import FormReducer from "../reducers/FormReducer";

const FormStateContext = React.createContext();
const FormDispatchContext = React.createContext();

const initialState = {
  wallet: {
    values: {
      walletName: "",
      commants: "",
      status: "ACTIVE",
    },
    errors: {},
  },
};

const FormProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(FormReducer, initialState);

  const handleChange = React.useCallback(
    (name, newValue) => {
      const values = {
        name: name,
        value: newValue,
      };
      dispatch({
        type: "UPDATE_STATE_FORM",
        payload: {
          id: "wallet",
          data: {
            name: name,
            value: newValue,
          },
        },
      });
    },
    [dispatch],
  );

  return (
    <FormStateContext.Provider value={{ state, handleChange }}>
      <FormDispatchContext.Provider value={dispatch}>{children}</FormDispatchContext.Provider>
    </FormStateContext.Provider>
  );
};

const useFormState = (id) => {
  const formState = React.useContext(FormStateContext);

  if (formState === undefined) {
    throw new Error('useFormState must be used within a FormProvider"');
  }
  return formState;
};

const useFormDispatch = () => {
  const dispatch = React.useContext(FormDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useFormState must be used within a FormProvider"');
  }

  return dispatch;
};

export { FormProvider, useFormState, useFormDispatch };
