import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { PlanGroupProvider } from "../contexts/PlanGroupContext";
import { PlanProvider } from "../contexts/PlanContext";
import { DialogProvider } from "../contexts/DialogContext";

export const PlanGroupComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <PlanGroupProvider key={0} />,
        <PlanProvider key={1} />,
        <DialogProvider key={3} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
