import { SignInConstants } from "../constants/signin.constants";

const JWTReducer = (state, action) => {
  switch (action.type) {
    case SignInConstants.INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SignInConstants.SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case SignInConstants.SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SignInConstants.SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

export default JWTReducer;
