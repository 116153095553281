import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Box, Card, CardContent, Typography, CardHeader } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { green, red } from "@mui/material/colors";
import useDailyUser from "../../../hooks/useDailyUser";
import { useNavigate, useLocation } from "react-router-dom";

// Register the components with ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const BarCharts = () => {
  const { getAllDashboardUser, dashboardUsers } = useDailyUser();

  console.log(dashboardUsers, "dataaaaaadddddddddddd");

  const [dashbordData, setDashBoardData] = useState({
    labels: [0, 0, 0, 0, 0, 0, 0],
    datasets: [
      {
        label: "Active",
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "rgb(71, 130, 218)",
        borderColor: "rgb(71, 130, 218)",
        borderWidth: 1,
      },
      {
        label: "Online",
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "rgb(46, 125, 50)",
        borderColor: "rgb(46, 125, 50)",
        borderWidth: 1,
      },
      {
        label: "New",
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "rgba(255, 206, 86, 0.6)",
        borderColor: "rgba(255, 206, 86, 0.6)",
        borderWidth: 1,
      },
      {
        label: "Expired",
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "rgb(237, 108, 2)",
        borderColor: "rgb(237, 108, 2)",
        borderWidth: 1,
      },
    ],
  });

  const { state } = useLocation();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllDashboardUser(payload);
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  useEffect(() => {
    if (dashboardUsers.length == 7) {
      let labels = [
        getMonthDateSplit(dashboardUsers[0]["createdAt"].split("T")[0]),
        getMonthDateSplit(dashboardUsers[1]["createdAt"].split("T")[0]),
        getMonthDateSplit(dashboardUsers[2]["createdAt"].split("T")[0]),
        getMonthDateSplit(dashboardUsers[3]["createdAt"].split("T")[0]),
        getMonthDateSplit(dashboardUsers[4]["createdAt"].split("T")[0]),
        getMonthDateSplit(dashboardUsers[5]["createdAt"].split("T")[0]),
        getMonthDateSplit(dashboardUsers[6]["createdAt"].split("T")[0]),
      ];
      let dataSets = [
        {
          label: "Active",
          data: [
            dashboardUsers[0]["activeCount"],
            dashboardUsers[1]["activeCount"],
            dashboardUsers[2]["activeCount"],
            dashboardUsers[3]["activeCount"],
            dashboardUsers[4]["activeCount"],
            dashboardUsers[5]["activeCount"],
            dashboardUsers[6]["activeCount"],
          ],
          backgroundColor: "rgb(71, 130, 218)",
          borderColor: "rgb(71, 130, 218)",
          borderWidth: 1,
        },
        {
          label: "Online",
          data: [
            dashboardUsers[0]["onlineCount"],
            dashboardUsers[1]["onlineCount"],
            dashboardUsers[2]["onlineCount"],
            dashboardUsers[3]["onlineCount"],
            dashboardUsers[4]["onlineCount"],
            dashboardUsers[5]["onlineCount"],
            dashboardUsers[6]["onlineCount"],
          ],
          backgroundColor: "rgb(46, 125, 50)",
          borderColor: "rgb(46, 125, 50)",
          borderWidth: 1,
        },
        {
          label: "New",
          data: [
            dashboardUsers[0]["newCount"],
            dashboardUsers[1]["newCount"],
            dashboardUsers[2]["newCount"],
            dashboardUsers[3]["newCount"],
            dashboardUsers[4]["newCount"],
            dashboardUsers[5]["newCount"],
            dashboardUsers[6]["newCount"],
          ],
          backgroundColor: "rgba(255, 206, 86, 0.6)",
          borderColor: "rgba(255, 206, 86, 0.6)",
          borderWidth: 1,
        },
        {
          label: "Expired",
          data: [
            dashboardUsers[0]["expiryCount"],
            dashboardUsers[1]["expiryCount"],
            dashboardUsers[2]["expiryCount"],
            dashboardUsers[3]["expiryCount"],
            dashboardUsers[4]["expiryCount"],
            dashboardUsers[5]["expiryCount"],
            dashboardUsers[6]["expiryCount"],
          ],
          backgroundColor: "rgb(237, 108, 2)",
          borderColor: "rgb(237, 108, 2)",
          borderWidth: 1,
        },
      ];
      setDashBoardData({
        labels: labels,
        datasets: dataSets,
      });
    }
  }, [dashboardUsers]);

  const getMonthDateSplit = (dataString) => {
    const date = dataString.split("-");
    return date[1] + "-" + date[2];
  };

  // Options for the chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 14,
            weight: "bold",
          },
          color: "#333",
        },
      },
      title: {
        display: true,
        font: {
          size: 18,
        },
        color: "#333",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "#555",
        },
      },
      x: {
        ticks: {
          color: "#555",
        },
      },
    },
  };

  return (
    <Card>
      <CardContent>
        <CardHeader title="User Status" />

        <Box sx={{ height: 250 }}>
          <Bar data={dashbordData} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default BarCharts;
