export const EmployeeConstants = {
  FETCH_EMPLOYEE_REQUEST: "FETCH_EMPLOYEE_REQUEST",
  FETCH_EMPLOYEE_SUCCESS: "FETCH_EMPLOYEE_SUCCESS",
  FETCH_EMPLOYEE_FAILURE: "FETCH_EMPLOYEE_FAILURE",
  ADD_EMPLOYEE_REQUEST: "ADD_EMPLOYEE_REQUEST",
  ADD_EMPLOYEE_SUCCESS: "ADD_EMPLOYEE_SUCCESS",
  ADD_EMPLOYEE_FAILURE: "ADD_EMPLOYEE_FAILURE",
  EMPLOYEE_SEARCH_REQUEST: "EMPLOYEE_SEARCH_REQUEST",
  EMPLOYEE_SEARCH_SUCCESS: "EMPLOYEE_SEARCH_SUCCESS",
  EMPLOYEE_SEARCH_FAILURE: "EMPLOYEE_SEARCH_FAILURE",
  EMPLOYEE_AUTO_SEARCH_REQUEST: "EMPLOYEE_AUTO_SEARCH_REQUEST",
  EMPLOYEE_AUTO_SEARCH_SUCCESS: "EMPLOYEE_AUTO_SEARCH_SUCCESS",
  EMPLOYEE_AUTO_SEARCH_FAILURE: "EMPLOYEE_AUTO_SEARCH_FAILURE",
  GET_EMPLOYEE_REQUEST: "GET_EMPLOYEE_REQUEST",
  GET_EMPLOYEE_SUCCESS: "GET_EMPLOYEE_SUCCESS",
  GET_EMPLOYEE_FAILURE: "GET_EMPLOYEE_FAILURE",
  UPDATE_EMPLOYEE_REQUEST: "UPDATE_EMPLOYEE_REQUEST",
  UPDATE_EMPLOYEE_SUCCESS: "UPDATE_EMPLOYEE_SUCCESS",
  UPDATE_EMPLOYEE_FAILURE: "UPDATE_EMPLOYEE_FAILURE",
  DOWNLOAD_EMPLOYEE_REQUEST: "DOWNLOAD_EMPLOYEE_REQUEST",
  DOWNLOAD_EMPLOYEE_SUCCESS: "DOWNLOAD_EMPLOYEE_SUCCESS",
  DOWNLOAD_EMPLOYEE_FAILURE: "DOWNLOAD_EMPLOYEE_FAILURE",
  UPLOAD_EMPLOYEE_REQUEST: "UPLOAD_EMPLOYEE_REQUEST",
  UPLOAD_EMPLOYEE_SUCCESS: "UPLOAD_EMPLOYEE_SUCCESS",
  UPLOAD_EMPLOYEE_FAILURE: "UPLOAD_EMPLOYEE_FAILURE",
};
