import { ComplaintConstants } from "../constants/complaint.constants";

const ComplaintReducer = (state, action) => {
  switch (action.type) {
    case ComplaintConstants.FETCH_COMPLAINT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ComplaintConstants.FETCH_COMPLAINT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaints: action.payload.data,
        paging: action.payload.paging,
      };

    case ComplaintConstants.FETCH_COMPLAINT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    // ComplaintHistory
    case ComplaintConstants.FETCH_COMPLAINT_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case ComplaintConstants.FETCH_COMPLAINT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaintHistories: action.payload.data,
        paging: action.payload.paging,
      };
    case ComplaintConstants.FETCH_COMPLAINT_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    // ASSIGN
    case ComplaintConstants.FETCH_COMPLAINT_ASSIGN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case ComplaintConstants.FETCH_COMPLAINT_ASSIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaintHistories: action.payload.data,
        paging: action.payload.paging,
      };
    case ComplaintConstants.FETCH_COMPLAINT_ASSIGN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    // STATS

    case ComplaintConstants.FETCH_COMPLAINT_STATS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case ComplaintConstants.FETCH_COMPLAINT_STATS_SUCCESS:
      console.log(action.payload, "leadstatys");

      return {
        ...state,
        isLoading: false,
        complaintStats: action.payload,
      };
    case ComplaintConstants.FETCH_COMPLAINT_STATS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ComplaintConstants.COMPLAINT_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ComplaintConstants.COMPLAINT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaintSearchResults: action.payload,
      };
    case ComplaintConstants.COMPLAINT_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ComplaintConstants.COMPLAINT_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ComplaintConstants.COMPLAINT_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaintAutoSearchResults: action.payload,
      };
    case ComplaintConstants.COMPLAINT_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ComplaintConstants.ADD_COMPLAINT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case ComplaintConstants.ADD_COMPLAINT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case ComplaintConstants.ADD_COMPLAINT_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case ComplaintConstants.GET_COMPLAINT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ComplaintConstants.GET_COMPLAINT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaint: action.payload,
      };
    case ComplaintConstants.GET_COMPLAINT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ComplaintConstants.GET_COMPLAINT_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ComplaintConstants.GET_COMPLAINT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaint: action.payload,
      };
    case ComplaintConstants.GET_COMPLAINT_HISTORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    // ASSIGN

    case ComplaintConstants.GET_COMPLAINT_ASSIGN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ComplaintConstants.GET_COMPLAINT_ASSIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaint: action.payload,
      };
    case ComplaintConstants.GET_COMPLAINT_ASSIGN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    // Stats

    case ComplaintConstants.GET_COMPLAINT_STATS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ComplaintConstants.GET_COMPLAINT_STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaint: action.payload,
      };
    case ComplaintConstants.GET_COMPLAINT_STATSS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ComplaintConstants.UPDATE_COMPLAINT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };

    case ComplaintConstants.UPDATE_COMPLAINT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        Complaint: action.payload,
      };
    case ComplaintConstants.UPDATE_COMPLAINT_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case ComplaintConstants.DOWNLOAD_COMPLAINT_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case ComplaintConstants.DOWNLOAD_COMPLAINT_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case ComplaintConstants.DOWNLOAD_COMPLAINT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case ComplaintConstants.UPLOAD_COMPLAINT_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case ComplaintConstants.UPLOAD_COMPLAINT_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case ComplaintConstants.UPLOAD_COMPLAINT_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default ComplaintReducer;
