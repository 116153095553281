import { useContext } from "react";

import { EmailToUserContext } from "../contexts/EmailToUserContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useEmailToUser = () => {
  const context = useContext(EmailToUserContext);

  if (!context) throw new Error("EmailToUserConstants must be placed within EmailToUsersProvider");

  return context;
};

export default useEmailToUser;
