import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import CountryReducer from "../reducers/CountryReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { CountryConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  countries: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  countrySearchResults: [],
  country: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  countryAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const CountryContext = createContext({
  ...initialState,
  getAllCountry: () => {},
  addCountry: () => {},
  searchCountry: () => {},
  autoSearchCountry: () => {},
  getCountry: () => {},
  updateCountry: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function CountryProvider({ children }) {
  const [state, dispatch] = useReducer(CountryReducer, initialState);

  const getAllCountry = async (payload) => {
    try {
      dispatch({
        type: CountryConstants.FETCH_COUNTRY_REQUEST,
      });
      const response = await axios.post("/master/country/list", payload);
      dispatch({
        type: CountryConstants.FETCH_COUNTRY_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: CountryConstants.FETCH_COUNTRY_FAILURE,
      });
    }
  };
  const searchCountry = async (payload) => {
    try {
      const response = await axios.post("/master/country/search", payload);
      dispatch({
        type: CountryConstants.COUNTRY_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: CountryConstants.COUNTRY_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: CountryConstants.COUNTRY_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchCountry = async (payload) => {
    try {
      const response = await axios.post("/master/country/auto-search", payload);
      dispatch({
        type: CountryConstants.COUNTRY_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: CountryConstants.COUNTRY_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: CountryConstants.COUNTRY_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addCountry = async (payload) => {
    const response = await axios.post("/master/country/add", payload);
    dispatch({
      type: CountryConstants.ADD_COUNTRY_REQUEST,
    });
    dispatch({
      type: CountryConstants.ADD_COUNTRY_SUCCESS,
      payload: response.data,
    });
  };

  const getCountry = async (id) => {
    const response = await axios.get("/master/country/" + id);
    dispatch({
      type: CountryConstants.GET_COUNTRY_REQUEST,
    });
    dispatch({
      type: CountryConstants.GET_COUNTRY_SUCCESS,
      payload: response.data,
    });
  };

  const updateCountry = async (payload) => {
    const response = await axios.put("/master/country/update/" + payload.countryId, payload);
    dispatch({
      type: CountryConstants.UPDATE_COUNTRY_REQUEST,
    });
    dispatch({
      type: CountryConstants.UPDATE_COUNTRY_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: CountryConstants.DOWNLOAD_COUNTRY_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/master/country/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "country." + fileType);
    dispatch({
      type: CountryConstants.DOWNLOAD_COUNTRY_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/master/country/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_country." + fileType);
    dispatch({
      type: CountryConstants.DOWNLOAD_COUNTRY_REQUEST,
    });
    dispatch({
      type: CountryConstants.DOWNLOAD_COUNTRY_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: CountryConstants.UPLOAD_COUNTRY_REQUEST,
      });
      const response = await axios({
        url: "/master/country/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: CountryConstants.UPLOAD_COUNTRY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: CountryConstants.UPLOAD_COUNTRY_FAILURE,
      });
    }
  };

  return (
    <CountryContext.Provider
      value={{
        ...state,
        getAllCountry,
        addCountry,
        searchCountry,
        autoSearchCountry,
        getCountry,
        updateCountry,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </CountryContext.Provider>
  );
}

export { CountryContext, CountryProvider };
