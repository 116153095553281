export const RateConstants = {
  FETCH_RATE_REQUEST: "FETCH_RATE_REQUEST",
  FETCH_RATE_SUCCESS: "FETCH_RATE_SUCCESS",
  FETCH_RATE_FAILURE: "FETCH_RATE_FAILURE",
  ADD_RATE_REQUEST: "ADD_RATE_REQUEST",
  ADD_RATE_SUCCESS: "ADD_RATE_SUCCESS",
  ADD_RATE_FAILURE: "ADD_RATE_FAILURE",
  RATE_SEARCH_REQUEST: "RATE_SEARCH_REQUEST",
  RATE_SEARCH_SUCCESS: "RATE_SEARCH_SUCCESS",
  RATE_SEARCH_FAILURE: "RATE_SEARCH_FAILURE",
  RATE_AUTO_SEARCH_REQUEST: "RATE_AUTO_SEARCH_REQUEST",
  RATE_AUTO_SEARCH_SUCCESS: "RATE_AUTO_SEARCH_SUCCESS",
  RATE_AUTO_SEARCH_FAILURE: "RATE_AUTO_SEARCH_FAILURE",
  GET_RATE_REQUEST: "GET_RATE_REQUEST",
  GET_RATE_SUCCESS: "GET_RATE_SUCCESS",
  GET_RATE_FAILURE: "GET_RATE_FAILURE",
  UPDATE_RATE_REQUEST: "UPDATE_RATE_REQUEST",
  UPDATE_RATE_SUCCESS: "UPDATE_RATE_SUCCESS",
  UPDATE_RATE_FAILURE: "UPDATE_RATE_FAILURE",
  DOWNLOAD_RATE_REQUEST: "DOWNLOAD_RATE_REQUEST",
  DOWNLOAD_RATE_SUCCESS: "DOWNLOAD_RATE_SUCCESS",
  DOWNLOAD_RATE_FAILURE: "DOWNLOAD_RATE_FAILURE",
  UPLOAD_RATE_REQUEST: "UPLOAD_RATE_REQUEST",
  UPLOAD_RATE_SUCCESS: "UPLOAD_RATE_SUCCESS",
  UPLOAD_RATE_FAILURE: "UPLOAD_RATE_FAILURE",
};
