import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import useTenant from "../../../../hooks/useTenant";
import { useForm } from "react-hook-form";
import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Table,
  Box,
  Grid,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const Contact = (props) => {
  const { getTenant, tenant, tenants } = useTenant();

  const methods = useForm({
    resolver: yupResolver(),
  });

  const { state } = useLocation();

  const { id, tenantId } = props;

  console.log(state, "location");

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Card sx={{ backgroundColor: "sss" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      First Name:
                    </TableCell>

                    <TableCell align="right">{tenant.firstName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Last Name:
                    </TableCell>
                    <TableCell align="right">{tenant.lastName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Email Id:
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <a href={"mailto: " + tenant.emailId}>{tenant.emailId}</a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Mobile No:
                    </TableCell>
                    <TableCell align="right">{tenant.mobileNo}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Contact;
