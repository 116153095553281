import { useContext } from "react";

import { BalanceStatementContext } from "../contexts/BalanceStatementContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useBalanceStatement = () => {
  const context = useContext(BalanceStatementContext);

  if (!context)
    throw new Error("BalanceStatementContext must be placed within BalanceStatementProvider");

  return context;
};

export default useBalanceStatement;
