import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MuiAutocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Controller } from "react-hook-form";

// TODO edit after fix the bug - issue #30249
//https://codesandbox.io/s/eager-wiles-xkz3qg?file=/src/App.js:3211-3241
//MUI - React Form Hook
export default function SearchableAutoComplete({
  control,
  data,
  payload,
  setPayload,
  label,
  loading,
  name,
  multiple,
  defaultValue,
  hasNextPage,
  fields,
  errors,
}) {
  const [page, setPage] = useState(1);

  const required = fields?.[name] == undefined ? false : true;
  console.log(errors?.[name], "requiredrequiredrequiredeeee");

  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  // take the scroll position at the moment
  const [position, setPosition] = useState(0);

  // take the ListboxNode's DOM Node
  // this value is unlikely to change,
  // but it is necessary to specify it in useEffect
  const [listboxNode, setListboxNode] = useState("");

  // useEffect(() => {
  //   setOptions([...options, ...data]);
  // }, [data]);

  console.log(data, "datadatadata");

  // change scroll position,
  // if the position's state has been changed
  useEffect(() => {
    // this condition checks if there is a necessary DOM Node
    if (listboxNode !== "") {
      listboxNode.scrollTop = position;
    }
    // it will only work when the position or node changes
  }, [position, listboxNode]);

  const loadMoreResults = () => {
    const nextPage = page + 1;

    //console.log(pagination, nextPage);
    if (hasNextPage) {
      console.log(nextPage, "i am here ");
      setPage(nextPage);
      setPayload({
        ...payload,
        pagination: {
          pageNumber: nextPage,
          pageSize: 100,
        },
      });
    }

    // setPage(nextPage);

    // const items = paginate(countries, 10, nextPage);
    // setOptions([...options, ...items]);
  };
  console.log(defaultValue, "sssssssssssssss");

  const [dValue, setDValue] = useState([]);

  useEffect(() => {
    if (defaultValue == undefined && !multiple) {
      setDValue("");
    } else if (defaultValue == undefined && multiple) {
      console.log(dValue, "dValuedValue");
      setDValue([]);
    } else {
      setDValue(defaultValue);
    }
  }, [defaultValue]);

  const handleScroll = (event) => {
    // const ListboxNode = event.currentTarget;
    // replaced by this
    setListboxNode(event.currentTarget);

    // this is necessary constant,
    // because if we change the position state
    // in this place then, with any scrolling,
    // we will be immediately returned to a position
    // equal to this value,
    // since our useEffect is triggered immediately
    const x = listboxNode.scrollTop + listboxNode.clientHeight;

    console.log(listboxNode.scrollHeight, x, listboxNode.scrollHeight - x, "jjjjjj");

    // only when checking this condition we change the position
    if (listboxNode.scrollHeight - x <= 1) {
      setPosition(x);
      loadMoreResults();
    }
  };

  const selectedDefaultValue = {};

  return (
    <Controller
      render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
        <MuiAutocomplete
          {...field}
          multiple={multiple}
          options={data}
          autoHighlight
          loading={loading}
          getOptionLabel={(option) => option.label || ""}
          onChange={(_, data) => onChange(data)}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.label || ""}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              inputRef={ref}
              InputLabelProps={{
                required: required,
              }}
              inputProps={{
                ...params.inputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              fullWidth
              variant="outlined"
              name={name}
              error={error !== undefined}
              helperText={error ? error.message : ""}
            />
          )}
          ListboxProps={{
            onScroll: handleScroll,
          }}
        />
      )}
      name={name}
      control={control}
      defaultValue={dValue}
    />
  );
}
