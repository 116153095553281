export const TenantSharingConstants = {
  FETCH_TENANT_SHARING_REQUEST: "FETCH_TENANT_SHARING_REQUEST",
  FETCH_TENANT_SHARING_SUCCESS: "FETCH_TENANT_SHARING_SUCCESS",
  FETCH_TENANT_SHARING_FAILURE: "FETCH_TENANT_SHARING_FAILURE",
  ADD_TENANT_SHARING_REQUEST: "ADD_TENANT_SHARING_REQUEST",
  ADD_TENANT_SHARING_SUCCESS: "ADD_TENANT_SHARING_SUCCESS",
  ADD_TENANT_SHARING_FAILURE: "ADD_TENANT_SHARING_FAILURE",
  TENANT_SHARING_SEARCH_REQUEST: "TENANT_SHARING_SEARCH_REQUEST",
  TENANT_SHARING_SEARCH_SUCCESS: "TENANT_SHARING_SEARCH_SUCCESS",
  TENANT_SHARING_SEARCH_FAILURE: "TENANT_SHARING_SEARCH_FAILURE",
  GET_TENANT_SHARING_REQUEST: "GET_TENANT_SHARING_REQUEST",
  GET_TENANT_SHARING_SUCCESS: "GET_TENANT_SHARING_SUCCESS",
  GET_TENANT_SHARING_FAILURE: "GET_TENANT_SHARING_FAILURE",
  UPDATE_TENANT_SHARING_REQUEST: "UPDATE_TENANT_SHARING_REQUEST",
  UPDATE_TENANT_SHARING_SUCCESS: "UPDATE_TENANT_SHARING_SUCCESS",
  UPDATE_TENANT_SHARING_FAILURE: "UPDATE_TENANT_SHARING_FAILURE",
  DOWNLOAD_TENANT_SHARING_REQUEST: "DOWNLOAD_TENANT_SHARING_REQUEST",
  DOWNLOAD_TENANT_SHARING_SUCCESS: "DOWNLOAD_TENANT_SHARING_SUCCESS",
  DOWNLOAD_TENANT_SHARING_FAILURE: "DOWNLOAD_TENANT_SHARING_FAILURE",
  UPLOAD_TENANT_SHARING_REQUEST: "UPLOAD_TENANT_SHARING_REQUEST",
  UPLOAD_TENANT_SHARING_SUCCESS: "UPLOAD_TENANT_SHARING_SUCCESS",
  UPLOAD_TENANT_SHARING_FAILURE: "UPLOAD_TENANT_SHARING_FAILURE",
  ADD_TENANT_SHARING_TENANT_REQUEST: "ADD_TENANT_SHARING_TENANT_REQUEST",
  ADD_TENANT_SHARING_TENANT_SUCCESS: "ADD_TENANT_SHARING_TENANT_SUCCESS",
  ADD_TENANT_SHARING_TENANT_FAILURE: "ADD_TENANT_SHARING_TENANT_FAILURE",
  FETCH_TENANT_SHARING_TENANT_REQUEST: "FETCH_TENANT_SHARING_TENANT_REQUEST",
  FETCH_TENANT_SHARING_TENANT_SUCCESS: "FETCH_TENANT_SHARING_TENANT_SUCCESS",
  FETCH_TENANT_SHARING_TENANT_FAILURE: "FETCH_TENANT_SHARING_TENANT_FAILURE",
};
