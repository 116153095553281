import { StateMasterConstants } from "../constants/statemaster.constants";

const StateMasterReducer = (state, action) => {
  switch (action.type) {
    case StateMasterConstants.FETCH_STATEMASTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case StateMasterConstants.FETCH_STATEMASTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        states: action.payload.data,
        paging: action.payload.paging,
      };
    case StateMasterConstants.FETCH_STATEMASTER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case StateMasterConstants.ADD_STATEMASTER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case StateMasterConstants.ADD_STATEMASTER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case StateMasterConstants.ADD_STATEMASTER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };
    case StateMasterConstants.EDIT_STATEMASTER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case StateMasterConstants.EDIT_STATEMASTER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case StateMasterConstants.EDIT_STATEMASTER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };
    case StateMasterConstants.STATEMASTER_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case StateMasterConstants.STATEMASTER_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stateSearchResults: action.payload,
      };
    case StateMasterConstants.STATEMASTER_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case StateMasterConstants.STATEMASTER_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoadingAutoSearch: true,
        hasError: false,
      };
    case StateMasterConstants.STATEMASTER_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoadingAutoSearch: false,
        stateAutoSearchResults: action.payload,
      };
    case StateMasterConstants.STATEMASTER_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingAutoSearch: false,
      };
    case StateMasterConstants.GET_STATEMASTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case StateMasterConstants.GET_STATEMASTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        statemaster: action.payload,
      };
    case StateMasterConstants.GET_STATEMASTER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case StateMasterConstants.UPDATE_STATEMASTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case StateMasterConstants.UPDATE_STATEMASTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        statemaster: action.payload,
      };
    case StateMasterConstants.UPDATE_STATEMASTER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case StateMasterConstants.DOWNLOAD_STATEMASTER_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case StateMasterConstants.DOWNLOAD_STATEMASTER_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case StateMasterConstants.DOWNLOAD_STATEMASTER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case StateMasterConstants.UPLOAD_STATEMASTER_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case StateMasterConstants.UPLOAD_STATEMASTER_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case StateMasterConstants.UPLOAD_STATEMASTER_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default StateMasterReducer;
