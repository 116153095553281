import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";

import EditCancel from "../../../components/EditCancel";
import usePlan from "../../../hooks/usePlan";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import PlanDetail from "./components/PlanDetail";
import AllowedTenant from "./components/AllowedTenant";
import RateDataGridPage from "./components/rate";
import NasMapping from "./components/NasMapping";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const validationSchema = Yup.object().shape({
  planName: Yup.string()
    .required("Required")
    .matches(
      /^[a-zA-Z0-9_]*$/g,
      "Plan name should be alpha numberic and underscore, there is no space allowed",
    ),
  uploadSpeed: Yup.string().required("Required"),
  downloadSpeed: Yup.string().required("Required"),
  planType: Yup.string().required("Required"),
  planDataType: Yup.string().required("Required"),
  // downloadLimit: Yup.string().required("Required"),
  // uploadLimit: Yup.string().required("Required"),
  totalLimit: Yup.string().when("planDataType", {
    is: (val) => (val && (val === "Limited" || val === "Unlimited_FUP") ? true : false),
    then: () => Yup.string().required("Total limit is required"),
  }),
  serviceType: Yup.string().required("Required"),
  taxType: Yup.string().required("Required"),
  nasGroup: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required("Nas group required"),
  status: Yup.string().required("Required"),
});

function BasicForm(props) {
  const dispatch = useDispatch();
  const { getPlan, plan, updatePlan, isSubmitting } = usePlan();

  const [value, setValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  React.useEffect(() => {
    if (Object.keys(plan).length !== 0) {
      const initialValues = {
        planId: plan.planId,
        planName: plan.planName,
        uploadSpeed: plan.uploadSpeed,
        downloadSpeed: plan.downloadSpeed,
        planType: plan.planType,
        planDataType: plan.planDataType,
        // downloadLimit: plan.downloadLimit,
        // uploadLimit: plan.uploadLimit,
        totalLimit: plan.totalLimit,
        taxType: plan.taxType,
        serviceType: plan.serviceType,
        nasGroup: {
          value: plan?.nasGroup?.nasGroupId,
          label: plan?.nasGroup?.nasGroupName,
        },
        status: plan.status,
      };
      reset(initialValues);
    }
  }, [plan]);

  const { state } = useLocation();
  const { t } = props;
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      data["nasGroup"] = {
        nasGroupId: data?.nasGroup?.value,
        nasGroupName: data?.nasGroup?.label,
      };
      await updatePlan(data);
      navigate("/radius/plan/list");
      reset();
      dispatch(showSuccessSnackbar("Plan updated successfully!"));
    } catch (error) {
      console.log(error);
      dispatch(showErrorSnackbar(error.message));
    }
  };
  const cancelBtn = () => {
    navigate("/radius/plan/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Radius</Typography>
        <Typography>Plan</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getPlan(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_plan")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value} onChange={handleTabChange} aria-label="Plan">
                    <Tab label="Plan Details" {...a11yProps(0)} />
                    <Tab label="Rate Details" {...a11yProps(1)} />
                    {/* <Tab label="Allowed Tenant" {...a11yProps(2)} /> */}
                    {/* <Tab label="Allowed Nas" {...a11yProps(3)} /> */}
                    {/* <Tab label="Allowed Ip Pool" {...a11yProps(4)} /> */}
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <PlanDetail validationFields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <RateDataGridPage planId={plan?.planId} />
                  </TabPanel>
                  {/* <TabPanel value={value} index={2}>
                    <AllowedTenant planId={plan?.planId} />
                  </TabPanel> */}
                  {/* <TabPanel value={value} index={3}>
                    <NasMapping />
                  </TabPanel> */}
                  {/* <TabPanel value={value} index={4}>
                    Item Five
                  </TabPanel> */}
                </Box>

                <EditCancel cancelBtn={cancelBtn} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

function EditPlan() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}

export default EditPlan;
