export const InterfaceConstants = {
  FETCH_INTERFACE_REQUEST: "FETCH_INTERFACE_REQUEST",
  FETCH_INTERFACE_SUCCESS: "FETCH_INTERFACE_SUCCESS",
  FETCH_INTERFACE_FAILURE: "FETCH_INTERFACE_FAILURE",
  ADD_INTERFACE_REQUEST: "ADD_INTERFACE_REQUEST",
  ADD_INTERFACE_SUCCESS: "ADD_INTERFACE_SUCCESS",
  ADD_INTERFACE_FAILURE: "ADD_INTERFACE_FAILURE",
  INTERFACE_SEARCH_REQUEST: "INTERFACE_SEARCH_REQUEST",
  INTERFACE_SEARCH_SUCCESS: "INTERFACE_SEARCH_SUCCESS",
  INTERFACE_SEARCH_FAILURE: "INTERFACE_SEARCH_FAILURE",
  INTERFACE_AUTO_SEARCH_REQUEST: "INTERFACE_AUTO_SEARCH_REQUEST",
  INTERFACE_AUTO_SEARCH_SUCCESS: "INTERFACE_AUTO_SEARCH_SUCCESS",
  INTERFACE_AUTO_SEARCH_FAILURE: "INTERFACE_AUTO_SEARCH_FAILURE",
  GET_INTERFACE_REQUEST: "GET_INTERFACE_REQUEST",
  GET_INTERFACE_SUCCESS: "GET_INTERFACE_SUCCESS",
  GET_INTERFACE_FAILURE: "GET_INTERFACE_FAILURE",
  UPDATE_INTERFACE_REQUEST: "UPDATE_INTERFACE_REQUEST",
  UPDATE_INTERFACE_SUCCESS: "UPDATE_INTERFACE_SUCCESS",
  UPDATE_INTERFACE_FAILURE: "UPDATE_INTERFACE_FAILURE",
  DOWNLOAD_INTERFACE_REQUEST: "DOWNLOAD_INTERFACE_REQUEST",
  DOWNLOAD_INTERFACE_SUCCESS: "DOWNLOAD_INTERFACE_SUCCESS",
  DOWNLOAD_INTERFACE_FAILURE: "DOWNLOAD_INTERFACE_FAILURE",
  UPLOAD_INTERFACE_REQUEST: "UPLOAD_INTERFACE_REQUEST",
  UPLOAD_INTERFACE_SUCCESS: "UPLOAD_INTERFACE_SUCCESS",
  UPLOAD_INTERFACE_FAILURE: "UPLOAD_INTERFACE_FAILURE",
};
