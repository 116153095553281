import { useContext } from "react";

import { RadUserWalletContext } from "../contexts/RadUserWalletContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useRadUserWallet = () => {
  const context = useContext(RadUserWalletContext);

  if (!context) throw new Error("RadUserWalletContext must be placed within RadUserWalletProvider");

  return context;
};

export default useRadUserWallet;
