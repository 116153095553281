import { AuthenticationLogConstants } from "../constants/authenticationlog.constants";

const AuthenticationLogReducer = (state, action) => {
  switch (action.type) {
    case AuthenticationLogConstants.FETCH_AUTHENTICATION_LOG_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case AuthenticationLogConstants.FETCH_AUTHENTICATION_LOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authenticationLogs: action.payload.data,
        paging: action.payload.paging,
      };
    case AuthenticationLogConstants.FETCH_AUTHENTICATION_LOG_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AuthenticationLogConstants.AUTHENTICATION_LOG_ASSIGN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case AuthenticationLogConstants.AUTHENTICATION_LOG_ASSIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case AuthenticationLogConstants.AUTHENTICATION_LOG_ASSIGN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AuthenticationLogConstants.FETCH_AUTHENTICATION_LOG_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case AuthenticationLogConstants.FETCH_AUTHENTICATION_LOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authenticationLogHistories: action.payload.data,
        paging: action.payload.paging,
      };
    case AuthenticationLogConstants.FETCH_AUTHENTICATION_LOG_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AuthenticationLogConstants.FETCH_AUTHENTICATION_LOG_STATS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case AuthenticationLogConstants.FETCH_AUTHENTICATION_LOG_STATS_SUCCESS:
      console.log(action.payload, "authenticationLogstatys");
      return {
        ...state,
        isLoading: false,
        authenticationLogStats: action.payload,
      };
    case AuthenticationLogConstants.FETCH_AUTHENTICATION_LOG_STATS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AuthenticationLogConstants.AUTHENTICATION_LOG_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AuthenticationLogConstants.AUTHENTICATION_LOG_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        AuthenticationLogSearchResults: action.payload,
      };
    case AuthenticationLogConstants.AUTHENTICATION_LOG_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AuthenticationLogConstants.AUTHENTICATION_LOG_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AuthenticationLogConstants.AUTHENTICATION_LOG_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        AuthenticationLogAutoSearchResults: action.payload,
      };
    case AuthenticationLogConstants.AUTHENTICATION_LOG_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AuthenticationLogConstants.ADD_AUTHENTICATION_LOG_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case AuthenticationLogConstants.ADD_AUTHENTICATION_LOG_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case AuthenticationLogConstants.ADD_AUTHENTICATION_LOG_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case AuthenticationLogConstants.GET_AUTHENTICATION_LOG_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AuthenticationLogConstants.GET_AUTHENTICATION_LOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authenticationLog: action.payload,
      };
    case AuthenticationLogConstants.GET_AUTHENTICATION_LOG_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AuthenticationLogConstants.GET_AUTHENTICATION_LOG_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AuthenticationLogConstants.GET_AUTHENTICATION_LOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authenticationLog: action.payload,
      };
    case AuthenticationLogConstants.GET_AUTHENTICATION_LOG_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AuthenticationLogConstants.GET_AUTHENTICATION_LOG_STATS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AuthenticationLogConstants.GET_AUTHENTICATION_LOG_STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authenticationLog: action.payload,
      };
    case AuthenticationLogConstants.GET_AUTHENTICATION_LOG_STATS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case AuthenticationLogConstants.UPDATE_AUTHENTICATION_LOG_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case AuthenticationLogConstants.UPDATE_AUTHENTICATION_LOG_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        authenticationLog: action.payload,
      };
    case AuthenticationLogConstants.UPDATE_AUTHENTICATION_LOG_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case AuthenticationLogConstants.DOWNLOAD_AUTHENTICATION_LOG_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case AuthenticationLogConstants.DOWNLOAD_AUTHENTICATION_LOG_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case AuthenticationLogConstants.DOWNLOAD_AUTHENTICATION_LOG_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case AuthenticationLogConstants.UPLOAD_AUTHENTICATION_LOG_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case AuthenticationLogConstants.UPLOAD_AUTHENTICATION_LOG_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case AuthenticationLogConstants.UPLOAD_AUTHENTICATION_LOG_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default AuthenticationLogReducer;
