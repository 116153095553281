export const BalanceStatementConstants = {
  FETCH_BALANCE_STATEMENT_REQUEST: "FETCH_BALANCE_STATEMENT_REQUEST",
  FETCH_BALANCE_STATEMENT_SUCCESS: "FETCH_BALANCE_STATEMENT_SUCCESS",
  FETCH_BALANCE_STATEMENT_FAILURE: "FETCH_BALANCE_STATEMENT_FAILURE",
  FETCH_BALANCE_REPORT_REQUEST: "FETCH_BALANCE_REPORT_REQUEST",
  FETCH_BALANCE_REPORT_SUCCESS: "FETCH_BALANCE_REPORT_SUCCESS",
  FETCH_BALANCE_REPORT_FAILURE: "FETCH_BALANCE_REPORT_FAILURE",
  ADD_BALANCE_STATEMENT_REQUEST: "ADD_BALANCE_STATEMENT_REQUEST",
  ADD_BALANCE_STATEMENT_SUCCESS: "ADD_BALANCE_STATEMENT_SUCCESS",
  ADD_BALANCE_STATEMENT_FAILURE: "ADD_BALANCE_STATEMENT_FAILURE",
  BALANCE_STATEMENT_SEARCH_REQUEST: "BALANCE_STATEMENT_SEARCH_REQUEST",
  BALANCE_STATEMENT_SEARCH_SUCCESS: "BALANCE_STATEMENT_SEARCH_SUCCESS",
  BALANCE_STATEMENT_SEARCH_FAILURE: "BALANCE_STATEMENT_SEARCH_FAILURE",
  BALANCE_STATEMENT_AUTO_SEARCH_REQUEST: "BALANCE_STATEMENT_AUTO_SEARCH_REQUEST",
  BALANCE_STATEMENT_AUTO_SEARCH_SUCCESS: "BALANCE_STATEMENT_AUTO_SEARCH_SUCCESS",
  BALANCE_STATEMENT_AUTO_SEARCH_FAILURE: "BALANCE_STATEMENT_AUTO_SEARCH_FAILURE",
  GET_BALANCE_STATEMENT_REQUEST: "GET_BALANCE_STATEMENT_REQUEST",
  GET_BALANCE_STATEMENT_SUCCESS: "GET_BALANCE_STATEMENT_SUCCESS",
  GET_BALANCE_STATEMENT_FAILURE: "GET_BALANCE_STATEMENT_FAILURE",
  UPDATE_BALANCE_STATEMENT_REQUEST: "UPDATE_BALANCE_STATEMENT_REQUEST",
  UPDATE_BALANCE_STATEMENT_SUCCESS: "UPDATE_BALANCE_STATEMENT_SUCCESS",
  UPDATE_BALANCE_STATEMENT_FAILURE: "UPDATE_BALANCE_STATEMENT_FAILURE",
  DOWNLOAD_BALANCE_STATEMENT_REQUEST: "DOWNLOAD_BALANCE_STATEMENT_REQUEST",
  DOWNLOAD_BALANCE_STATEMENT_SUCCESS: "DOWNLOAD_BALANCE_STATEMENT_SUCCESS",
  DOWNLOAD_BALANCE_STATEMENT_FAILURE: "DOWNLOAD_BALANCE_STATEMENT_FAILURE",
  UPLOAD_BALANCE_STATEMENT_REQUEST: "UPLOAD_BALANCE_STATEMENT_REQUEST",
  UPLOAD_BALANCE_STATEMENT_SUCCESS: "UPLOAD_BALANCE_STATEMENT_SUCCESS",
  UPLOAD_BALANCE_STATEMENT_FAILURE: "UPLOAD_BALANCE_STATEMENT_FAILURE",
};
