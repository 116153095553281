import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import useTenant from "../../../../hooks/useTenant";
import { useForm } from "react-hook-form";
import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Table,
  Box,
  Grid,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const Address = (props) => {
  const { getTenant, tenant, tenants } = useTenant();

  const methods = useForm({
    resolver: yupResolver(),
  });

  const { state } = useLocation();

  const { id, tenantId } = props;

  console.log(state, "location");

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Card sx={{ backgroundColor: "sss" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      User Name:
                    </TableCell>
                    <TableCell align="right">{tenant.userName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Door no:
                    </TableCell>
                    <TableCell align="right">{tenant.doorNo}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Street Name:
                    </TableCell>
                    <TableCell align="right">{tenant.streetName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Pincode:
                    </TableCell>
                    <TableCell align="right">{tenant.pinCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Village Name:
                    </TableCell>
                    <TableCell align="right">{tenant.villageName}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Card sx={{ backgroundColor: "sss" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Town Name:
                    </TableCell>
                    <TableCell align="right">{tenant.townName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Taluk:
                    </TableCell>
                    <TableCell align="right">{tenant.taluk?.talukName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      District:
                    </TableCell>
                    <TableCell align="right">{tenant.district?.districtName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      State:
                    </TableCell>
                    <TableCell align="right">{tenant.state?.stateName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Country:
                    </TableCell>
                    <TableCell align="right">{tenant.country?.countryName}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Address;
