import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";
import useSetting from "../../../../hooks/useSetting";

import SaveCancel from "../../../../components/SaveCancel";
import useWallet from "../../../../hooks/useWallet";
import TabPanel from "../../../../components/TabPanel";
import { a11yProps } from "../../../../utils/common";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import useAuth from "../../../../hooks/useAuth";
import MailSetting from "./components/MailSetting";
import Localization from "./components/Localization";
import SmsApi from "./components/SmsApi";
import Common from "./components/Common";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const digitsOnly = (value) => /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0;

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const validationSchema = Yup.object().shape({
  isEmailEnabled: Yup.boolean(),
  isSmsEnabled: Yup.boolean(),
  mailFrom: Yup.string().when("isEmailEnabled", {
    is: true,
    then: (schema) => schema.required("Mail from is required"),
    //use arrow function to resolve branch is not defined error
    otherwise: (schema) => schema.notRequired(),
  }),

  hostName: Yup.string().when("isEmailEnabled", {
    is: true,
    //use arrow function to resolve branch is not defined error
    then: (schema) => schema.required("Hostname is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  mailUserName: Yup.string().when("isEmailEnabled", {
    is: true,
    //use arrow function to resolve branch is not defined error
    then: (schema) => schema.required("Mail username is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  smsUserName: Yup.string().when("isSmsEnabled", {
    is: true,
    //use arrow function to resolve branch is not defined error
    then: (schema) => schema.required("Sms username is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  smsUrl: Yup.string().when("isSmsEnabled", {
    is: true,
    //use arrow function to resolve branch is not defined error
    then: (schema) => schema.required("Sms url is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  country: Yup.object().shape({
    label: Yup.string().required("Is required"),
    value: Yup.string().required("Is required"),
  }),
  timeZone: Yup.string().required("Required"),
  mailPassword: Yup.string()
    .nullable()
    .when("isEmailEnabled", {
      is: true,
      //use arrow function to resolve branch is not defined error
      then: (schema) => schema.required("Mail password is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  smsPassword: Yup.string()
    .nullable()
    .when("isSmsEnabled", {
      is: true,
      //use arrow function to resolve branch is not defined error
      then: (schema) => schema.required("Sms password is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  port: Yup.string().when("isEmailEnabled", {
    is: true,
    //use arrow function to resolve branch is not defined error
    then: (schema) => schema.required("Port is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

function Settings() {
  const { state } = useLocation();
  const [payload, setPayload] = useState(currentPayload);

  console.log(state, "statestatestate");
  const dispatch = useDispatch();

  const { getTenantSetting, tenantSetting, updateTenantSetting, isSubmitting } = useSetting();

  //Test validation issues
  // const testData = {
  //   isEmailEnabled: true, // or false
  //   mailFrom: "", // Should trigger the validation error when `isEmailEnabled` is true
  // };

  // validationSchema
  //   .validate(testData, { abortEarly: false })
  //   .then((data) => {
  //     console.log("Validation succeeded:", data);
  //   })
  //   .catch((err) => {
  //     console.log("Validation errors:", err);
  //   });
  React.useEffect(() => {
    if (Object.keys(tenantSetting).length !== 0) {
      const initialValues = {
        tenantSettingId: tenantSetting.tenantSettingId,
        mailFrom: tenantSetting.mailFrom == null ? "" : tenantSetting.mailFrom,
        country: {
          label: tenantSetting.country.countryName,
          value: tenantSetting.country.countryId,
        },
        mailPassword: tenantSetting.mailPassword,
        smsPassword: tenantSetting.smsPassword,
        smsUrl: tenantSetting.smsUrl,
        mobileNo: tenantSetting.mobileNo,
        email: tenantSetting.email,
        mailUserName: tenantSetting.mailUserName,
        isEmailEnabled: tenantSetting.isEmailEnabled,
        isSmsEnabled: tenantSetting.isSmsEnabled,
        isSslEnabled: tenantSetting.isSslEnabled,
        isTlsEnabled: tenantSetting.isTlsEnabled,
        port: tenantSetting.port,
        smsUserName: tenantSetting.smsUserName,
        timeZone: tenantSetting.timeZone,
        hostName: tenantSetting.hostName,
      };
      console.log(initialValues, "initialValues");
      reset(initialValues);
    }
  }, [tenantSetting]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [status] = useState({
    sent: false,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: {},
  } = methods;

  const onSubmit = async (data) => {
    try {
      data["country"] = {
        countryId: data.country.value,
        countryName: data.country.label,
      };
      await updateTenantSetting(data);

      dispatch(showSuccessSnackbar("General Settings added successfully!"));
    } catch (err) {
      console.log(err, "ddddddddddddd");
      dispatch(showErrorSnackbar(err.message));
    }
  };

  const cancelBtn = () => {
    navigate("/cpanel/tenant/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>cpanel</Typography>
        <Typography>tenant</Typography>
        <Typography>genaral setting</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getTenantSetting(state.id);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("general_settings")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={tabValue} onChange={handleTabChange} aria-label="Notification">
                    <Tab label="Common" {...a11yProps(0)} />
                    <Tab label="Mail Setting" {...a11yProps(1)} />
                    <Tab label="Localization" {...a11yProps(2)} />
                    <Tab label="Sms Api" {...a11yProps(3)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <Common validationFields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <MailSetting validationFields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    <Localization validationFields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    <SmsApi validationFields={validationSchema.fields} />
                  </TabPanel>
                </Box>
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default Settings;
