export const AuthenticationLogConstants = {
  FETCH_AUTHENTICATION_LOG_REQUEST: "FETCH_AUTHENTICATION_LOG_REQUEST",
  FETCH_AUTHENTICATION_LOG_SUCCESS: "FETCH_AUTHENTICATION_LOG_SUCCESS",
  FETCH_AUTHENTICATION_LOG_FAILURE: "FETCH_AUTHENTICATION_LOG_FAILURE",
  AUTHENTICATION_LOG_ASSIGN_REQUEST: "AUTHENTICATION_LOG_ASSIGN_REQUEST",
  AUTHENTICATION_LOG_ASSIGN_SUCCESS: "AUTHENTICATION_LOG_ASSIGN_SUCCESS",
  AUTHENTICATION_LOG_ASSIGN_FAILURE: "AUTHENTICATION_LOG_ASSIGN_FAILURE",
  FETCH_AUTHENTICATION_LOG_REQUEST: "FETCH_AUTHENTICATION_LOG_REQUEST",
  FETCH_AUTHENTICATION_LOG_SUCCESS: "FETCH_AUTHENTICATION_LOG_SUCCESS",
  FETCH_AUTHENTICATION_LOG_FAILURE: "FETCH_AUTHENTICATION_LOG_FAILURE",
  FETCH_AUTHENTICATION_LOG_STATS_REQUEST: "FETCH_AUTHENTICATION_LOG_STATS_REQUEST",
  FETCH_AUTHENTICATION_LOG_STATS_SUCCESS: "FETCH_AUTHENTICATION_LOG_STATS_SUCCESS",
  FETCH_AUTHENTICATION_LOG_STATS_FAILURE: "FETCH_AUTHENTICATION_LOG_STATS_FAILURE",
  ADD_AUTHENTICATION_LOG_REQUEST: "ADD_AUTHENTICATION_LOG_REQUEST",
  ADD_AUTHENTICATION_LOG_SUCCESS: "ADD_AUTHENTICATION_LOG_SUCCESS",
  ADD_AUTHENTICATION_LOG_FAILURE: "ADD_AUTHENTICATION_LOG_FAILURE",
  AUTHENTICATION_LOG_SEARCH_REQUEST: "AUTHENTICATION_LOG_SEARCH_REQUEST",
  AUTHENTICATION_LOG_SEARCH_SUCCESS: "AUTHENTICATION_LOG_SEARCH_SUCCESS",
  AUTHENTICATION_LOG_SEARCH_FAILURE: "AUTHENTICATION_LOG_SEARCH_FAILURE",
  AUTHENTICATION_LOG_AUTO_SEARCH_REQUEST: "AUTHENTICATION_LOG_AUTO_SEARCH_REQUEST",
  AUTHENTICATION_LOG_AUTO_SEARCH_SUCCESS: "AUTHENTICATION_LOG_AUTO_SEARCH_SUCCESS",
  AUTHENTICATION_LOG_AUTO_SEARCH_FAILURE: "AUTHENTICATION_LOG_AUTO_SEARCH_FAILURE",
  GET_AUTHENTICATION_LOG_REQUEST: "GET_AUTHENTICATION_LOG_REQUEST",
  GET_AUTHENTICATION_LOG_SUCCESS: "GET_AUTHENTICATION_LOG_SUCCESS",
  GET_AUTHENTICATION_LOG_FAILURE: "GET_AUTHENTICATION_LOG_FAILURE",
  GET_AUTHENTICATION_LOG_REQUEST: "GET_AUTHENTICATION_LOG_REQUEST",
  GET_AUTHENTICATION_LOG_SUCCESS: "GET_AUTHENTICATION_LOG_SUCCESS",
  GET_AUTHENTICATION_LOG_FAILURE: "GET_AUTHENTICATION_LOG_FAILURE",
  GET_AUTHENTICATION_LOG_STATS_REQUEST: "GET_AUTHENTICATION_LOG_STATS_REQUEST",
  GET_AUTHENTICATION_LOG_STATS_SUCCESS: "GET_AUTHENTICATION_LOG_STATS_SUCCESS",
  GET_AUTHENTICATION_LOG_STATS_FAILURE: "GET_AUTHENTICATION_LOG_STATS_FAILURE",
  UPDATE_AUTHENTICATION_LOG_REQUEST: "UPDATE_AUTHENTICATION_LOG_REQUEST",
  UPDATE_AUTHENTICATION_LOG_SUCCESS: "UPDATE_AUTHENTICATION_LOG_SUCCESS",
  UPDATE_AUTHENTICATION_LOG_FAILURE: "UPDATE_AUTHENTICATION_LOG_FAILURE",
  DOWNLOAD_AUTHENTICATION_LOG_REQUEST: "DOWNLOAD_AUTHENTICATION_LOG_REQUEST",
  DOWNLOAD_AUTHENTICATION_LOG_SUCCESS: "DOWNLOAD_AUTHENTICATION_LOG_SUCCESS",
  DOWNLOAD_AUTHENTICATION_LOG_FAILURE: "DOWNLOAD_AUTHENTICATION_LOG_FAILURE",
  UPLOAD_AUTHENTICATION_LOG_REQUEST: "UPLOAD_AUTHENTICATION_LOG_REQUEST",
  UPLOAD_AUTHENTICATION_LOG_SUCCESS: "UPLOAD_AUTHENTICATION_LOG_SUCCESS",
  UPLOAD_AUTHENTICATION_LOG_FAILURE: "UPLOAD_AUTHENTICATION_LOG_FAILURE",
};
