import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { useDialog } from "../../../../contexts/DialogContext";
import SaveIcon from "@mui/icons-material/Save";

import { Grid, DialogActions, Button, CircularProgress } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import useHotelManagement from "../../../../hooks/useHotelManagement";

function DisconnectUserSession(props) {
  const { selectedRows, refreshPage } = props;
  const { deactivateUser, isRenewSubmitting, disconnectUserSession } = useHotelManagement();
  const dispatch = useDispatch();
  const { unSetDialog, openDialog } = useDialog();

  const methods = useForm();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      console.log(selectedRows, "selectedRows");
      await disconnectUserSession(selectedRows);
      dispatch(showSuccessSnackbar("User session disconnected Successfully!"));
      refreshPage();
      unSetDialog("disconnect_user_session");
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to disconnected user session"));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <i>Do you want to disconnect the user session ?</i>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Yes
            </Button>

            <Button
              variant="text"
              onClick={() => unSetDialog("disconnect_user_session")}
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default DisconnectUserSession;
