//Users
import HotelUserList from "../pages/hms/hotel_users/index.js";
import AddHotelUser from "../pages/hms/hotel_users/add.js";
import EditHotelUser from "../pages/hms/hotel_users/edit.js";
import ActivateUser from "../pages/hms/activate_users/index.js";
import DeactivateUser from "../pages/hms/deactivate_users/index.js";

//Composers
import HotelManagementComposer from "../composers/HotelManagementComposer.js";
import { DashboardComposer } from "../composers/DashboardComposer.js";
// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";

const HotelManagementRoutes = {
  path: "hms",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "hotel-user",
      children: [
        {
          path: "add",
          element: (
            <HotelManagementComposer>
              <AddHotelUser />
            </HotelManagementComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <HotelManagementComposer>
              <EditHotelUser />
            </HotelManagementComposer>
          ),
        },
        {
          path: "list",
          element: (
            <HotelManagementComposer>
              <HotelUserList />
            </HotelManagementComposer>
          ),
        },
      ],
    },
    {
      path: "activate-user",
      children: [
        {
          path: "list",
          element: (
            <HotelManagementComposer>
              <ActivateUser />
            </HotelManagementComposer>
          ),
        },
      ],
    },
    {
      path: "deactivate-user",
      children: [
        {
          path: "list",
          element: (
            <HotelManagementComposer>
              <DeactivateUser />
            </HotelManagementComposer>
          ),
        },
      ],
    },
  ],
};

export default HotelManagementRoutes;
