import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider } from "react-hook-form";

import SaveCancel from "../../../components/SaveCancel";
import useHotelManagement from "../../../hooks/useHotelManagement";
import HotelUserFields from "../hotel_users/components/HotelUserFields";
import useAuth from "../../../hooks/useAuth";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const initialValues = {
  tenant: "",
  plan: "",
  userName: "",
  password: "",
  name: "",
  status: "ACTIVE",
};

const validationSchema = Yup.object().shape({
  plan: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  tenant: Yup.object()
    .shape({
      label: Yup.string().required("Tenant is required"),
      value: Yup.string().required("Tenant is required"),
    })
    .nullable()
    .required("Tenant is required"),
  userName: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function AddHotelUser() {
  const { user } = useAuth();
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useDispatch();

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const { addHotelManagement, hasError, errorMessage, isSubmitting } = useHotelManagement();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods;

  useEffect(() => {
    if (user && user.businessType == "HOTEL") {
      setValue("tenant", {
        label: user.name,
        value: user.tenantId,
      });
    }
  }, [user]);

  const onSubmit = async (data) => {
    try {
      data["tenant"] = {
        tenantId: data.tenant.value,
        tenantName: data.tenant.label,
      };
      data["plan"] = {
        planId: data.plan.value,
        planName: data.plan.label,
      };
      await addHotelManagement(data);
      dispatch(showSuccessSnackbar("Hotel user added successfully!"));
      navigate("/hms/hotel-user/list");
      reset();
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to add hotel user"));
    }
  };

  const cancelBtn = () => {
    navigate("/hms/hotel-user/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>HMS</Typography>
        <Typography>Hotel User</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_hotel_user")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                HotelManagement added successfully!
              </Alert>
            )}
            {errors && errors.submit && (
              <Alert severity="error" my={3}>
                {errors.submit}
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <HotelUserFields validationFields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddHotelUser;
