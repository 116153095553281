export const NasConstants = {
  FETCH_NAS_REQUEST: "FETCH_NAS_REQUEST",
  FETCH_NAS_SUCCESS: "FETCH_NAS_SUCCESS",
  FETCH_NAS_FAILURE: "FETCH_NAS_FAILURE",
  ADD_NAS_REQUEST: "ADD_NAS_REQUEST",
  ADD_NAS_SUCCESS: "ADD_NAS_SUCCESS",
  ADD_NAS_FAILURE: "ADD_NAS_FAILURE",
  NAS_SEARCH_REQUEST: "NAS_SEARCH_REQUEST",
  NAS_SEARCH_SUCCESS: "NAS_SEARCH_SUCCESS",
  NAS_SEARCH_FAILURE: "NAS_SEARCH_FAILURE",
  GET_NAS_REQUEST: "GET_NAS_REQUEST",
  GET_NAS_SUCCESS: "GET_NAS_SUCCESS",
  GET_NAS_FAILURE: "GET_NAS_FAILURE",
  UPDATE_NAS_REQUEST: "UPDATE_NAS_REQUEST",
  UPDATE_NAS_SUCCESS: "UPDATE_NAS_SUCCESS",
  UPDATE_NAS_FAILURE: "UPDATE_NAS_FAILURE",
  DOWNLOAD_NAS_REQUEST: "DOWNLOAD_NAS_REQUEST",
  DOWNLOAD_NAS_SUCCESS: "DOWNLOAD_NAS_SUCCESS",
  DOWNLOAD_NAS_FAILURE: "DOWNLOAD_NAS_FAILURE",
  UPLOAD_NAS_REQUEST: "UPLOAD_NAS_REQUEST",
  UPLOAD_NAS_SUCCESS: "UPLOAD_NAS_SUCCESS",
  UPLOAD_NAS_FAILURE: "UPLOAD_NAS_FAILURE",
  NAS_AUTO_SEARCH_REQUEST: "NAS_AUTO_SEARCH_REQUEST",
  NAS_AUTO_SEARCH_SUCCESS: "NAS_AUTO_SEARCH_SUCCESS",
  NAS_AUTO_SEARCH_FAILURE: "NAS_AUTO_SEARCH_FAILURE",
};
