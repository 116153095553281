export const SettingConstants = {
  GET_GENERAL_SETTING_REQUEST: "GET_GENERAL_SETTING_REQUEST",
  GET_GENERAL_SETTING_SUCCESS: "GET_GENERAL_SETTING_SUCCESS",
  GET_GENERAL_SETTING_FAILURE: "GET_GENERAL_SETTING_FAILURE",
  GET_EMAIL_SETTING_REQUEST: "GET_EMAIL_SETTING_REQUEST",
  GET_EMAIL_SETTING_SUCCESS: "GET_EMAIL_SETTING_SUCCESS",
  GET_EMAIL_SETTING_FAILURE: "GET_EMAIL_SETTING_FAILURE",
  GET_SMS_SETTING_REQUEST: "GET_SMS_SETTING_REQUEST",
  GET_SMS_SETTING_SUCCESS: "GET_SMS_SETTING_SUCCESS",
  GET_SMS_SETTING_FAILURE: "GET_SMS_SETTING_FAILURE",
  UPDATE_EMAIL_TEMPLATE_REQUEST: "UPDATE_EMAIL_TEMPLATE_REQUEST",
  UPDATE_EMAIL_TEMPLATE_SUCCESS: "UPDATE_EMAIL_TEMPLATE_SUCCESS",
  UPDATE_EMAIL_TEMPLATE_FAILURE: "UPDATE_EMAIL_TEMPLATE_FAILURE",
  UPDATE_SMS_TEMPLATE_REQUEST: "UPDATE_SMS_TEMPLATE_REQUEST",
  UPDATE_SMS_TEMPLATE_SUCCESS: "UPDATE_SMS_TEMPLATE_SUCCESS",
  UPDATE_SMS_TEMPLATE_FAILURE: "UPDATE_SMS_TEMPLATE_FAILURE",
  GET_TENANT_SETTING_REQUEST: "GET_TENANT_SETTING_REQUEST",
  GET_TENANT_SETTING_SUCCESS: "GET_TENANT_SETTING_SUCCESS",
  GET_TENANT_SETTING_FAILURE: "GET_TENANT_SETTING_FAILURE",
  UPDATE_TENANT_SETTING_REQUEST: "UPDATE_TENANT_SETTING_REQUEST",
  UPDATE_TENANT_SETTING_SUCCESS: "UPDATE_TENANT_SETTING_SUCCESS",
  UPDATE_TENANT_SETTING_FAILURE: "UPDATE_TENANT_SETTING_FAILURE",
};
