import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import TrafficReportReducer from "../reducers/TrafficReportReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { TrafficReportConstants } from "../constants/trafficreport.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  trafficReports: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  trafficReportSearchResults: [],
  trafficReport: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  trafficReportAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const TrafficReportContext = createContext({
  ...initialState,
  getAllTrafficReport: () => {},
  addTrafficReport: () => {},
  searchTrafficReport: () => {},
  autoSearchTrafficReport: () => {},
  getTrafficReport: () => {},
  updateTrafficReport: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function TrafficReportProvider({ children }) {
  const [state, dispatch] = useReducer(TrafficReportReducer, initialState);

  const getAllTrafficReport = async (payload) => {
    try {
      dispatch({
        type: TrafficReportConstants.FETCH_TRAFFIC_REPORT_REQUEST,
      });
      const response = await axios.post("/radius/raduser/traffic-reports", payload);
      dispatch({
        type: TrafficReportConstants.FETCH_TRAFFIC_REPORT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: TrafficReportConstants.FETCH_TRAFFIC_REPORT_FAILURE,
      });
    }
  };
  const searchTrafficReport = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/traffic-reports/search", payload);
      dispatch({
        type: TrafficReportConstants.TRAFFIC_REPORT_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: TrafficReportConstants.TRAFFIC_REPORT_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: TrafficReportConstants.TRAFFIC_REPORT_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchTrafficReport = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/traffic-reports/auto-search", payload);
      dispatch({
        type: TrafficReportConstants.TRAFFIC_REPORT_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: TrafficReportConstants.TRAFFIC_REPORT_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: TrafficReportConstants.TRAFFIC_REPORT_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addTrafficReport = async (payload) => {
    const response = await axios.post("/radius/raduser/traffic-reports/add", payload);
    dispatch({
      type: TrafficReportConstants.ADD_TRAFFIC_REPORT_REQUEST,
    });
    dispatch({
      type: TrafficReportConstants.ADD_TRAFFIC_REPORT_SUCCESS,
      payload: response.data,
    });
  };

  const getTrafficReport = async (id) => {
    const response = await axios.get("/radius/raduser/traffic-reports/" + id);
    dispatch({
      type: TrafficReportConstants.GET_TRAFFIC_REPORT_REQUEST,
    });
    dispatch({
      type: TrafficReportConstants.GET_TRAFFIC_REPORT_SUCCESS,
      payload: response.data,
    });
  };

  const updateTrafficReport = async (payload) => {
    const response = await axios.put(
      "/radius/raduser/traffic-reports/update/" + payload.trafficReportId,
      payload,
    );
    dispatch({
      type: TrafficReportConstants.UPDATE_TRAFFIC_REPORT_REQUEST,
    });
    dispatch({
      type: TrafficReportConstants.UPDATE_TRAFFIC_REPORT_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: TrafficReportConstants.DOWNLOAD_TRAFFIC_REPORT_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post(
      "/radius/raduser/traffic-reports/export/" + fileType,
      payload,
      {
        responseType: "blob",
      },
    );
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "trafficReport." + fileType);
    dispatch({
      type: TrafficReportConstants.DOWNLOAD_TRAFFIC_REPORT_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/radius/raduser/traffic-reports/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_trafficReport." + fileType);
    dispatch({
      type: TrafficReportConstants.DOWNLOAD_TRAFFIC_REPORT_REQUEST,
    });
    dispatch({
      type: TrafficReportConstants.DOWNLOAD_TRAFFIC_REPORT_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: TrafficReportConstants.UPLOAD_TRAFFIC_REPORT_REQUEST,
      });
      const response = await axios({
        url: "/radius/raduser/traffic-reports/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: TrafficReportConstants.UPLOAD_TRAFFIC_REPORT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: TrafficReportConstants.UPLOAD_TRAFFIC_REPORT_FAILURE,
      });
    }
  };

  return (
    <TrafficReportContext.Provider
      value={{
        ...state,
        getAllTrafficReport,
        addTrafficReport,
        searchTrafficReport,
        autoSearchTrafficReport,
        getTrafficReport,
        updateTrafficReport,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </TrafficReportContext.Provider>
  );
}

export { TrafficReportContext, TrafficReportProvider };
