import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import IpTvReducer from "../reducers/IpTvReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { IpTvConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  iptvs: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  iptvSearchResults: [],
  iptv: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  iptvAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  iptvPlanAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  isLoadingAutoSearch: false,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const IpTvContext = createContext({
  ...initialState,
  getAllIpTv: () => {},
  addIpTv: () => {},
  searchIpTv: () => {},
  autoSearchIpTv: () => {},
  getIpTv: () => {},
  updateIpTv: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  autoSearchIpTvPlan: () => {},
});

function IpTvProvider({ children }) {
  const [state, dispatch] = useReducer(IpTvReducer, initialState);

  const getAllIpTv = async (payload) => {
    try {
      const response = await axios.post("/master/iptv/list", payload);
      dispatch({
        type: IpTvConstants.FETCH_IPTV_REQUEST,
      });
      dispatch({
        type: IpTvConstants.FETCH_IPTV_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: IpTvConstants.FETCH_IPTV_FAILURE,
      });
    }
  };
  const searchIpTv = async (payload) => {
    try {
      const response = await axios.post("/master/iptv/search", payload);
      dispatch({
        type: IpTvConstants.IPTV_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: IpTvConstants.IPTV_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: IpTvConstants.IPTV_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchIpTv = async (payload) => {
    try {
      const response = await axios.post("/master/iptv/auto-search", payload);
      dispatch({
        type: IpTvConstants.IPTV_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: IpTvConstants.IPTV_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: IpTvConstants.IPTV_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchIpTvPlan = async (payload) => {
    try {
      console.log("autoSearchIpTvPlan");
      const response = await axios.post("/master/iptv/plan/auto-search", payload);
      dispatch({
        type: IpTvConstants.IPTV_POLICY_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: IpTvConstants.IPTV_POLICY_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: IpTvConstants.IPTV_POLICY_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addIpTv = async (payload) => {
    const response = await axios.post("/master/iptv/add", payload);
    dispatch({
      type: IpTvConstants.ADD_IPTV_REQUEST,
    });
    dispatch({
      type: IpTvConstants.ADD_IPTV_SUCCESS,
      payload: response.data,
    });
  };

  const getIpTv = async (id) => {
    const response = await axios.get("/master/iptv/" + id);
    dispatch({
      type: IpTvConstants.GET_IPTV_REQUEST,
    });
    dispatch({
      type: IpTvConstants.GET_IPTV_SUCCESS,
      payload: response.data,
    });
  };

  const updateIpTv = async (payload) => {
    const response = await axios.put("/master/iptv/update/" + payload.iptvId, payload);
    dispatch({
      type: IpTvConstants.UPDATE_IPTV_REQUEST,
    });
    dispatch({
      type: IpTvConstants.UPDATE_IPTV_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    const response = await axios.post("/master/iptv/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "iptv." + fileType);
    dispatch({
      type: IpTvConstants.DOWNLOAD_IPTV_REQUEST,
    });
    dispatch({
      type: IpTvConstants.DOWNLOAD_IPTV_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/master/iptv/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_iptv." + fileType);
    dispatch({
      type: IpTvConstants.DOWNLOAD_IPTV_REQUEST,
    });
    dispatch({
      type: IpTvConstants.DOWNLOAD_IPTV_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: IpTvConstants.UPLOAD_IPTV_REQUEST,
      });
      const response = await axios({
        url: "/master/iptv/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: IpTvConstants.UPLOAD_IPTV_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: IpTvConstants.UPLOAD_IPTV_FAILURE,
      });
    }
  };

  return (
    <IpTvContext.Provider
      value={{
        ...state,
        getAllIpTv,
        addIpTv,
        searchIpTv,
        autoSearchIpTv,
        getIpTv,
        updateIpTv,
        downloadFile,
        sampleFile,
        uploadFile,
        autoSearchIpTvPlan,
      }}
    >
      {children}
    </IpTvContext.Provider>
  );
}

export { IpTvContext, IpTvProvider };
