import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import { MoreVertical } from "react-feather";
import { green, red, orange } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  Chip,
} from "@mui/material";
import { spacing } from "@mui/system";
import useScheduleUser from "../../../hooks/useScheduleUser";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const HotelBasicDetails = ({ user }) => {
  const { activeScheduleUser } = useScheduleUser();
  const theme = useTheme();
  const CardContent = styled(MuiCardContent)`
    &:last-child {
      padding-bottom: ${theme.spacing(2)};
    }
  `;
  console.log(user, "user");
  const data = {
    labels: ["Social", "Search Engines", "Direct", "Other"],
    datasets: [
      {
        data: [260, 125, 164],
        backgroundColor: [theme.palette.secondary.main, red[500], orange[500]],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const userStatus = (status) => {
    if (status == "active") {
      return <Chip label="Active" color="primary" />;
    } else if (status == "online") {
      return <Chip label="Online" color="success" />;
    } else if (status == "expired") {
      return <Chip label="Expired" color="warning" />;
    }
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <Card mb={6} sx={{ backgroundColor: "sss" }}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Account Details"
      />

      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Account No:
              </TableCell>
              <TableCell align="right">{user.radUserId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                UserName:
              </TableCell>
              <TableCell align="right">{user.userName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Name:
              </TableCell>
              <TableCell align="right">{user.firstName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Plan Name:
              </TableCell>
              <TableCell align="right">{user.planName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Expiration:
              </TableCell>
              <TableCell align="right">{user.expiration}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                User Status:
              </TableCell>
              <TableCell align="right">{userStatus(user.userStatus)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default HotelBasicDetails;
