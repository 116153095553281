import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import SaveCancel from "../../../components/SaveCancel";
import useRoutes from "../../../hooks/useRoutes";
import RoutesFields from "./components/RoutesFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  nas: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  dstAddress: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  gateway: Yup.string().required("Required"),
});

function EditRoutes() {
  const { getRoutes, route, updateRoutes, isSubmitting } = useRoutes();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Object.keys(route).length !== 0) {
      console.log(route, "route.status");
      const initialValues = {
        ipRoutesId: route.ipRoutesId,
        nas: {
          value: route.nas.nasId,
          label: route.nas.nasName,
        },
        dstAddress: route.dstAddress,
        gateway: route.gateway,
        status: route.status,
      };
      reset(initialValues);
    }
  }, [route]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const { state } = useLocation();
  console.log(state, "location");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      data["nas"] = {
        nasId: data.nas.value,
        nasName: data.nas.label,
      };
      await updateRoutes(data);
      dispatch(showSuccessSnackbar("Routes updated successfully!"));
      navigate("/mikrotik-mgnt/routes/list");
      reset();
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to update routes"));
    }
  };

  const cancelBtn = () => {
    navigate("mikrotik-mgnt/routes/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Mikrotik Access Mgnt</Typography>
        <Typography>Routes</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getRoutes(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_routes")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                Routes updated successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <RoutesFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditRoutes;
