//Users
import MaterialList from "../pages/inventory_management/materials/index.js";
import AddMaterials from "../pages/inventory_management/materials/add.js";
import EditMaterial from "../pages/inventory_management/materials/edit.js";
//Composers
import InventoryManagementComposer from "../composers/InventoryManagementComposer.js";
import { DashboardComposer } from "../composers/DashboardComposer.js";
// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";

const InventoryManagementRoutes = {
  path: "inventory-mgnt",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "materials",
      children: [
        {
          path: "add",
          element: (
            <InventoryManagementComposer>
              <AddMaterials />
            </InventoryManagementComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <InventoryManagementComposer>
              <EditMaterial />
            </InventoryManagementComposer>
          ),
        },
        {
          path: "list",
          element: (
            <InventoryManagementComposer>
              <MaterialList />
            </InventoryManagementComposer>
          ),
        },
      ],
    },
  ],
};

export default InventoryManagementRoutes;
