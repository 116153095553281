import React, { useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";

import SaveCancel from "../../../components/SaveCancel";
import usePlan from "../../../hooks/usePlan";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import PlanDetail from "./components/PlanDetail";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const initialValues = {
  planName: "",
  uploadSpeed: "",
  downloadSpeed: "",
  planType: "",
  planDataType: "",
  downloadLimit: "",
  uploadLimit: "",
  taxType: "INCLUDING_TAX",
  serviceType: "INTERNET",
  status: "ACTIVE",
};

const validationSchema = Yup.object().shape({
  planName: Yup.string()
    .required("Required")
    .matches(
      /^[a-zA-Z0-9_]*$/g,
      "Plan name should be alpha numberic and underscore, there is no space allowed",
    ),
  uploadSpeed: Yup.string().required("Required"),
  downloadSpeed: Yup.string().required("Required"),
  planType: Yup.string().required("Required"),
  planDataType: Yup.string().required("Required"),
  // downloadLimit: Yup.string().when("planDataType", {
  //   is: (val) => (val && (val === "Limited" || val === "Unlimited_FUP") ? true : false),
  //   then: Yup.string().required("Download limit is required"),
  // }),
  // uploadLimit: Yup.string().when("planDataType", {
  //   is: (val) => (val && (val === "Limited" || val === "Unlimited_FUP") ? true : false),
  //   then: Yup.string().required("Upload limit is required"),
  // }),
  totalLimit: Yup.string().when("planDataType", {
    is: (val) => (val && (val === "Limited" || val === "Unlimited_FUP") ? true : false),
    then: () => Yup.string().required("Total limit is required"),
  }),
  serviceType: Yup.string().required("Required"),
  taxType: Yup.string().required("Required"),
  nasGroup: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required("Nas group required"),
  status: Yup.string().required("Required"),
});

function AddPlan() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addPlan, hasError, errorMessage, isSubmitting } = usePlan();
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = methods;
  const navigate = useNavigate();

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Radius</Typography>
        <Typography>Plan</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  // const handleSubmit = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
  //   try {
  //     console.log(values, "fff");
  //     await addPlan(values);
  //     resetForm();
  //     setStatus({ sent: true });
  //     setSubmitting(false);
  //   } catch (error) {
  //     setStatus({ sent: false });
  //     setErrors({ submit: error.message });
  //     setSubmitting(false);
  //   }
  // };

  const onSubmit = async (data) => {
    try {
      data["nasGroup"] = {
        nasGroupId: data?.nasGroup?.value,
        nasGroupName: data?.nasGroup?.label,
      };
      await addPlan(data);
      reset();
      dispatch(showSuccessSnackbar("Plan added successfully!"));
    } catch (error) {
      console.log(error);
      dispatch(showErrorSnackbar(error.message));
    }
  };

  const cancelBtn = () => {
    navigate("/radius/plan/list");
  };

  const planDataType = watch("planDataType");

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_plan")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value} onChange={handleTabChange} aria-label="Plan">
                    <Tab label="Plan Details" {...a11yProps(0)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <PlanDetail validationFields={validationSchema.fields} />
                  </TabPanel>
                </Box>

                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddPlan;
