export const NasGroupConstants = {
  FETCH_NAS_GROUP_REQUEST: "FETCH_NAS_GROUP_REQUEST",
  FETCH_NAS_GROUP_SUCCESS: "FETCH_NAS_GROUP_SUCCESS",
  FETCH_NAS_GROUP_FAILURE: "FETCH_NAS_GROUP_FAILURE",
  ADD_NAS_GROUP_REQUEST: "ADD_NAS_GROUP_REQUEST",
  ADD_NAS_GROUP_SUCCESS: "ADD_NAS_GROUP_SUCCESS",
  ADD_NAS_GROUP_FAILURE: "ADD_NAS_GROUP_FAILURE",
  ADD_NAS_GROUP_MAP_REQUEST: "ADD_NAS_GROUP_MAP_REQUEST",
  ADD_NAS_GROUP_MAP_SUCCESS: "ADD_NAS_GROUP_MAP_SUCCESS",
  ADD_NAS_GROUP_MAP_FAILURE: "ADD_NAS_GROUP_MAP_FAILURE",
  NAS_GROUP_SEARCH_REQUEST: "NAS_GROUP_SEARCH_REQUEST",
  NAS_GROUP_SEARCH_SUCCESS: "NAS_GROUP_SEARCH_SUCCESS",
  NAS_GROUP_SEARCH_FAILURE: "NAS_GROUP_SEARCH_FAILURE",
  GET_NAS_GROUP_REQUEST: "GET_NAS_GROUP_REQUEST",
  GET_NAS_GROUP_SUCCESS: "GET_NAS_GROUP_SUCCESS",
  GET_NAS_GROUP_FAILURE: "GET_NAS_GROUP_FAILURE",
  UPDATE_NAS_GROUP_REQUEST: "UPDATE_NAS_GROUP_REQUEST",
  UPDATE_NAS_GROUP_SUCCESS: "UPDATE_NAS_GROUP_SUCCESS",
  UPDATE_NAS_GROUP_FAILURE: "UPDATE_NAS_GROUP_FAILURE",
  DOWNLOAD_NAS_GROUP_REQUEST: "DOWNLOAD_NAS_GROUP_REQUEST",
  DOWNLOAD_NAS_GROUP_SUCCESS: "DOWNLOAD_NAS_GROUP_SUCCESS",
  DOWNLOAD_NAS_GROUP_FAILURE: "DOWNLOAD_NAS_GROUP_FAILURE",
  UPLOAD_NAS_GROUP_REQUEST: "UPLOAD_NAS_GROUP_REQUEST",
  UPLOAD_NAS_GROUP_SUCCESS: "UPLOAD_NAS_GROUP_SUCCESS",
  UPLOAD_NAS_GROUP_FAILURE: "UPLOAD_NAS_GROUP_FAILURE",
  ADD_NAS_GROUP_TENANT_REQUEST: "ADD_NAS_GROUP_TENANT_REQUEST",
  ADD_NAS_GROUP_TENANT_SUCCESS: "ADD_NAS_GROUP_TENANT_SUCCESS",
  ADD_NAS_GROUP_TENANT_FAILURE: "ADD_NAS_GROUP_TENANT_FAILURE",
  FETCH_NAS_GROUP_TENANT_REQUEST: "FETCH_NAS_GROUP_TENANT_REQUEST",
  FETCH_NAS_GROUP_TENANT_SUCCESS: "FETCH_NAS_GROUP_TENANT_SUCCESS",
  FETCH_NAS_GROUP_TENANT_FAILURE: "FETCH_NAS_GROUP_TENANT_FAILURE",
  FETCH_NAS_GROUP_MAP_REQUEST: "FETCH_NAS_GROUP_MAP_REQUEST",
  FETCH_NAS_GROUP_MAP_SUCCESS: "FETCH_NAS_GROUP_MAP_SUCCESS",
  FETCH_NAS_GROUP_MAP_FAILURE: "FETCH_NAS_GROUP_MAP_FAILURE",
  NAS_GROUP_AUTO_SEARCH_REQUEST: "NAS_GROUP_AUTO_SEARCH_REQUEST",
  NAS_GROUP_AUTO_SEARCH_SUCCESS: "NAS_GROUP_AUTO_SEARCH_SUCCESS",
  NAS_GROUP_AUTO_SEARCH_FAILURE: "NAS_GROUP_AUTO_SEARCH_FAILURE",
};
