import { useContext } from "react";

import { LoginHistoryContext } from "../contexts/LoginHistoryContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useLoginHistory = () => {
  const context = useContext(LoginHistoryContext);

  if (!context)
    throw new Error("LoginHistoryConstants must be placed within LoginHistoriesProvider");

  return context;
};

export default useLoginHistory;
