export const WalletConstants = {
  FETCH_WALLET_REQUEST: "FETCH_WALLET_REQUEST",
  FETCH_WALLET_SUCCESS: "FETCH_WALLET_SUCCESS",
  FETCH_WALLET_FAILURE: "FETCH_WALLET_FAILURE",
  ADD_WALLET_REQUEST: "ADD_WALLET_REQUEST",
  ADD_WALLET_SUCCESS: "ADD_WALLET_SUCCESS",
  ADD_WALLET_FAILURE: "ADD_WALLET_FAILURE",
  WALLET_SEARCH_REQUEST: "WALLET_SEARCH_REQUEST",
  WALLET_SEARCH_SUCCESS: "WALLET_SEARCH_SUCCESS",
  WALLET_SEARCH_FAILURE: "WALLET_SEARCH_FAILURE",
  GET_WALLET_REQUEST: "GET_WALLET_REQUEST",
  GET_WALLET_SUCCESS: "GET_WALLET_SUCCESS",
  GET_WALLET_FAILURE: "GET_WALLET_FAILURE",
  UPDATE_WALLET_REQUEST: "UPDATE_WALLET_REQUEST",
  UPDATE_WALLET_SUCCESS: "UPDATE_WALLET_SUCCESS",
  UPDATE_WALLET_FAILURE: "UPDATE_WALLET_FAILURE",
  DOWNLOAD_WALLET_REQUEST: "DOWNLOAD_WALLET_REQUEST",
  DOWNLOAD_WALLET_SUCCESS: "DOWNLOAD_WALLET_SUCCESS",
  DOWNLOAD_WALLET_FAILURE: "DOWNLOAD_WALLET_FAILURE",
  UPLOAD_WALLET_REQUEST: "UPLOAD_WALLET_REQUEST",
  UPLOAD_WALLET_SUCCESS: "UPLOAD_WALLET_SUCCESS",
  UPLOAD_WALLET_FAILURE: "UPLOAD_WALLET_FAILURE",
  ADD_PAYMENT_TRANS_REQUEST: "ADD_PAYMENT_TRANS_REQUEST",
  ADD_PAYMENT_TRANS_SUCCESS: "ADD_PAYMENT_TRANS_SUCCESS",
  ADD_PAYMENT_TRANS_FAILURE: "ADD_PAYMENT_TRANS_FAILURE",
  WALLET_AUTO_SEARCH_REQUEST: "WALLET_AUTO_SEARCH_REQUEST",
  WALLET_AUTO_SEARCH_SUCCESS: "WALLET_AUTO_SEARCH_SUCCESS",
  WALLET_AUTO_SEARCH_FAILURE: "WALLET_AUTO_SEARCH_FAILURE",
};
