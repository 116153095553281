import React from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import EditDelete from "../../../components/EditDelete";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import useCountry from "../../../hooks/useCountry";
import useMainContentDimensions from "../../../utils/mainContentDimensions";
import AdvanceFilter from "./AdvanceFilter";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Agreement Tracker</Typography>
      <Typography>List</Typography>
    </Breadcrumbs>
  );
};

const CustomDataGrid = (props) => {
  const { isLoadingDownload, downloadFileType } = useCountry();
  const { width: mainContentWidth } = useMainContentDimensions();

  const { height, width } = useWindowDimensions();
  const {
    editPage,
    addPage,
    trans,
    rows,
    rowCount,
    isLoading,
    id,
    handleSortModelChange,
    sortModel,
    downloadFile,
    payload,
    setPayload,
    refreshPage,
    setFilterResetFunc,
    paginationModel,
    paginationMeta,
    paging,
    onPaginationModelChange,
  } = props;
  console.log(rowCount, "loading child component");
  const { openDialog } = useDialog();
  const columns = [
    {
      field: "action",
      headerName: "Action",
      filter: false,
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          console.log(thisRow, "thisRow");
        };
        return (
          <EditDelete rowClick={onClick} editPage={() => editPage(params.id)} menus={["edit"]} />
        );
      },
    },
    {
      field: "agreementNumber",
      headerName: "Agreement Number",
      renderCell: (params) => (
        <Link to={"/agreement-tracker/details?id=" + params.id}>{params.value}</Link>
      ),
      width: 150,
    },
    {
      field: "dateOfAgreementStr",
      headerName: "Date of Agreement",
      width: 130,
    },
    {
      field: "docketNumber",
      headerName: "Docket Number",
      width: 130,
    },
    {
      field: "courierType",
      headerName: "Courier Type",
      width: 130,
    },
    {
      field: "courierName",
      headerName: "Courier Name",
      width: 130,
    },
    {
      field: "dateOfSendStr",
      headerName: "Date of Send",
      width: 130,
    },
    {
      field: "dateOfExpiryStr",
      headerName: "Date of Expiry",
      width: 130,
    },
  ];

  const openFilterDialog = () => {
    return openDialog(
      "advance_filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={
          <Toolbar
            addPage={addPage}
            downloadFile={downloadFile}
            isLoadingDownload={isLoadingDownload}
            openFilterDialog={openFilterDialog}
            downloadFileType={downloadFileType}
            refreshPage={refreshPage}
          />
        }
        title={trans("agreement_tracker_list")}
        subheader={breadCrump()}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          checkboxSelection
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          rowCount={rowCount}
          getRowId={(row) => row[id]}
          columns={columns}
          rows={rows}
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMeta={paginationMeta}
          loading={isLoading}
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
          onPaginationModelChange={onPaginationModelChange}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          paginationModel={paginationModel}
        />
      </CardContent>
    </Card>
  );
};
CustomDataGrid.propTypes = {
  editPage: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  trans: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSortModelChange: PropTypes.func.isRequired,
  sortModel: PropTypes.object.isRequired,
};
export default CustomDataGrid;
