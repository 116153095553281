import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import {
  ListItemIcon,
  Menu,
  MenuItem,
  ListItemText as MuiListItemText,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";

const ListItemText = styled(MuiListItemText)(spacing);

const Delete = (props) => {
  const { editPage } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div mt={3}>
      <IconButton
        aria-owns={anchorEl ? "icon-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="secondary"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="icon-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary={t("Delete")} pl={0} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Delete;
