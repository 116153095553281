import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import Overview from "./components/overview";
import { Grid } from "@mui/material";
import useUser from "../../hooks/useUser";
import useAuth from "../../hooks/useAuth";
import BasicDetails from "../users/user_details/BasicDetails";
import PlanDetails from "../users/user_details/PlanDetails";

function UserDashboard() {
  const { user } = useAuth();
  const { getUser, getUserDetailsInvoice, userDetailsInvoice } = useUser();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState({});

  console.log(user, "ppppppppppppppppppppddddddddddd");

  const fetchData = async () => {
    const userData = await getUser(user.radUserId);
    setUserDetails(userData);
  };

  useEffect(() => {
    if (user != null) {
      fetchData();
    }
  }, [user]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <BasicDetails user={userDetails} />
        </Grid>
        <Grid item xs={6}>
          <PlanDetails user={userDetails} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UserDashboard;
