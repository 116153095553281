import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import ComplaintReducer from "../reducers/ComplaintReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { ComplaintConstants } from "../constants/complaint.constants";
import { saveFile } from "../utils/common";
const initialState = {
  complaints: [],
  complaint: [],
  complaintStats: {
    total: 0,
    assigned: 0,
    reAssigned: 0,
    resolved: 0,
    closed: 0,
  },
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },

  complaintHistories: [],

  hasError: false,
  errorMessage: "",
  ComplaintSearchResults: [],
  complaint: {},
  complaintHistory: {},

  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  ComplaintAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const ComplaintContext = createContext({
  ...initialState,
  getAllComplaint: () => {},
  getAllComplaintHistory: () => {},
  getAllComplaintStats: () => {},

  addComplaint: () => {},
  assignComplaint: () => {},

  searchComplaint: () => {},
  autoSearchComplaint: () => {},
  getComplaint: () => {},
  updateComplaint: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function ComplaintProvider({ children }) {
  const [state, dispatch] = useReducer(ComplaintReducer, initialState);

  const getAllComplaint = async (payload) => {
    try {
      dispatch({
        type: ComplaintConstants.FETCH_COMPLAINT_REQUEST,
      });
      const response = await axios.post("/complaint/list", payload);

      dispatch({
        type: ComplaintConstants.FETCH_COMPLAINT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: ComplaintConstants.FETCH_COMPLAINT_FAILURE,
      });
    }
  };

  const getAllComplaintHistory = async (payload) => {
    try {
      const response = await axios.post("/complaint/history", payload);
      dispatch({
        type: ComplaintConstants.FETCH_COMPLAINT_HISTORY_REQUEST,
      });
      dispatch({
        type: ComplaintConstants.FETCH_COMPLAINT_HISTORY_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: ComplaintConstants.FETCH_COMPLAINT_HISTORY_FAILURE,
      });
    }
  };

  // assign
  const assignComplaint = async (payload) => {
    const response = await axios.post("/complaint/assign", payload);
    dispatch({
      type: ComplaintConstants.UPDATE_ASSIGN_LEAD_REQUEST,
    });
    dispatch({
      type: ComplaintConstants.UPDATE_ASSIGN_LEAD_SUCCESS,
      payload: response.data,
    });
  };

  // stats field

  const getAllComplaintStats = async (payload) => {
    try {
      const response = await axios.post("/complaint/stats", payload);
      dispatch({
        type: ComplaintConstants.FETCH_COMPLAINT_STATS_REQUEST,
      });
      dispatch({
        type: ComplaintConstants.FETCH_COMPLAINT_STATS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: ComplaintConstants.FETCH_COMPLAINT_STATS_FAILURE,
      });
    }
  };

  const searchComplaint = async (payload) => {
    try {
      const response = await axios.post("/complaint/search", payload);
      dispatch({
        type: ComplaintConstants.COMPLAINT_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: ComplaintConstants.COMPLAINT_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: ComplaintConstants.COMPLAINT_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchComplaint = async (payload) => {
    try {
      const response = await axios.post("/complaint/auto-search", payload);
      dispatch({
        type: ComplaintConstants.COMPLAINT_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: ComplaintConstants.COMPLAINT_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: ComplaintConstants.COMPLAINT_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addComplaint = async (payload) => {
    const response = await axios.post("/complaint/add", payload);
    dispatch({
      type: ComplaintConstants.ADD_COMPLAINT_REQUEST,
    });
    dispatch({
      type: ComplaintConstants.ADD_COMPLAINT_SUCCESS,
      payload: response.data,
    });
  };

  const getComplaint = async (id) => {
    const response = await axios.get("/complaint/" + id);
    dispatch({
      type: ComplaintConstants.GET_COMPLAINT_REQUEST,
    });
    dispatch({
      type: ComplaintConstants.GET_COMPLAINT_SUCCESS,
      payload: response.data,
    });
  };

  const updateComplaint = async (payload) => {
    const response = await axios.put("/complaint/update/" + payload.complaintId, payload);
    dispatch({
      type: ComplaintConstants.UPDATE_COMPLAINT_REQUEST,
    });
    dispatch({
      type: ComplaintConstants.UPDATE_COMPLAINT_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: ComplaintConstants.DOWNLOAD_COMPLAINT_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/complaint/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "Complaint." + fileType);

    dispatch({
      type: ComplaintConstants.DOWNLOAD_COMPLAINT_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/complaint/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_Complaint." + fileType);
    dispatch({
      type: ComplaintConstants.DOWNLOAD_COMPLAINT_REQUEST,
    });
    dispatch({
      type: ComplaintConstants.DOWNLOAD_COMPLAINT_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: ComplaintConstants.UPLOAD_COMPLAINT_REQUEST,
      });
      const response = await axios({
        url: "/complaint/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: ComplaintConstants.UPLOAD_COMPLAINT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: ComplaintConstants.UPLOAD_COMPLAINT_FAILURE,
      });
    }
  };

  return (
    <ComplaintContext.Provider
      value={{
        ...state,
        getAllComplaint,
        getAllComplaintHistory,
        getAllComplaintStats,
        assignComplaint,
        addComplaint,
        searchComplaint,
        autoSearchComplaint,
        getComplaint,
        updateComplaint,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </ComplaintContext.Provider>
  );
}

export { ComplaintContext, ComplaintProvider };
