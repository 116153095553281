export const CardSystemConstants = {
  FETCH_CARD_SYSTEM_REQUEST: "FETCH_CARD_SYSTEM_REQUEST",
  FETCH_CARD_SYSTEM_SUCCESS: "FETCH_CARD_SYSTEM_SUCCESS",
  FETCH_CARD_SYSTEM_FAILURE: "FETCH_CARD_SYSTEM_FAILURE",
  FETCH_PREPAID_CARD_REQUEST: "FETCH_PREPAID_CARD_REQUEST",
  FETCH_PREPAID_CARD_SUCCESS: "FETCH_PREPAID_CARD_SUCCESS",
  FETCH_PREPAID_CARD_FAILURE: "FETCH_PREPAID_CARD_FAILURE",
  GET_CARD_SERIES_COUNT_REQUEST: "GET_CARD_SERIES_COUNT_REQUEST",
  GET_CARD_SERIES_COUNT_SUCCESS: "GET_CARD_SERIES_COUNT_SUCCESS",
  GET_CARD_SERIES_COUNT_FAILURE: "GET_CARD_SERIES_COUNT_FAILURE",
  GET_PREPAID_CARD_COUNT_REQUEST: "GET_PREPAID_CARD_COUNT_REQUEST",
  GET_PREPAID_CARD_COUNT_SUCCESS: "GET_PREPAID_CARD_COUNT_SUCCESS",
  GET_PREPAID_CARD_COUNT_FAILURE: "GET_PREPAID_CARD_COUNT_FAILURE",
  ADD_CARD_SYSTEM_REQUEST: "ADD_CARD_SYSTEM_REQUEST",
  ADD_CARD_SYSTEM_SUCCESS: "ADD_CARD_SYSTEM_SUCCESS",
  ADD_CARD_SYSTEM_FAILURE: "ADD_CARD_SYSTEM_FAILURE",
  CARD_SYSTEM_SEARCH_REQUEST: "CARD_SYSTEM_SEARCH_REQUEST",
  CARD_SYSTEM_SEARCH_SUCCESS: "CARD_SYSTEM_SEARCH_SUCCESS",
  CARD_SYSTEM_SEARCH_FAILURE: "CARD_SYSTEM_SEARCH_FAILURE",
  CARD_SYSTEM_AUTO_SEARCH_REQUEST: "CARD_SYSTEM_AUTO_SEARCH_REQUEST",
  CARD_SYSTEM_AUTO_SEARCH_SUCCESS: "CARD_SYSTEM_AUTO_SEARCH_SUCCESS",
  CARD_SYSTEM_AUTO_SEARCH_FAILURE: "CARD_SYSTEM_AUTO_SEARCH_FAILURE",
  GET_CARD_SYSTEM_REQUEST: "GET_CARD_SYSTEM_REQUEST",
  GET_CARD_SYSTEM_SUCCESS: "GET_CARD_SYSTEM_SUCCESS",
  GET_CARD_SYSTEM_FAILURE: "GET_CARD_SYSTEM_FAILURE",
  UPDATE_CARD_SYSTEM_REQUEST: "UPDATE_CARD_SYSTEM_REQUEST",
  UPDATE_CARD_SYSTEM_SUCCESS: "UPDATE_CARD_SYSTEM_SUCCESS",
  UPDATE_CARD_SYSTEM_FAILURE: "UPDATE_CARD_SYSTEM_FAILURE",
  DOWNLOAD_CARD_SYSTEM_REQUEST: "DOWNLOAD_CARD_SYSTEM_REQUEST",
  DOWNLOAD_CARD_SYSTEM_SUCCESS: "DOWNLOAD_CARD_SYSTEM_SUCCESS",
  DOWNLOAD_CARD_SYSTEM_FAILURE: "DOWNLOAD_CARD_SYSTEM_FAILURE",
  UPLOAD_CARD_SYSTEM_REQUEST: "UPLOAD_CARD_SYSTEM_REQUEST",
  UPLOAD_CARD_SYSTEM_SUCCESS: "UPLOAD_CARD_SYSTEM_SUCCESS",
  UPLOAD_CARD_SYSTEM_FAILURE: "UPLOAD_CARD_SYSTEM_FAILURE",
};
