import { UserActivityConstants } from "../constants/useractivity.constants";

const UserActivitiesReducer = (state, action) => {
  switch (action.type) {
    case UserActivityConstants.FETCH_USERACTIVITY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserActivityConstants.FETCH_USERACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userActivities: action.payload.data,
        paging: action.payload.paging,
      };
    case UserActivityConstants.FETCH_USERACTIVITY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UserActivityConstants.USERACTIVITY_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserActivityConstants.USERACTIVITY_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userActivitySearchResults: action.payload,
      };
    case UserActivityConstants.USERACTIVITY_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UserActivityConstants.USERACTIVITY_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserActivityConstants.USERACTIVITY_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userActivityAutoSearchResults: action.payload,
      };
    case UserActivityConstants.USERACTIVITY_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UserActivityConstants.GET_USERACTIVITY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserActivityConstants.GET_USERACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userActivity: action.payload,
      };
    case UserActivityConstants.GET_USERACTIVITY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UserActivityConstants.DOWNLOAD_USERACTIVITY_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case UserActivityConstants.DOWNLOAD_USERACTIVITY_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case UserActivityConstants.DOWNLOAD_USERACTIVITY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };

    default:
      return state;
  }
};

export default UserActivitiesReducer;
