import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";

import SaveCancel from "../../../components/SaveCancel";
import useBranch from "../../../hooks/useBranch";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import BasicDetail from "./components/BasicDetail";
import ContactDetail from "./components/ContactDetail";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import useAuth from "../../../hooks/useAuth";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  branchName: Yup.string().required("Required"),
  tenant: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  status: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  emailId: Yup.string().required("Required"),
  mobileNo: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/, "Must be a valid phone number with up to 10 digits"),
});

function AddBranch() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const initialValues = {
    tenant: "",
    branchName: "",
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNo: "",
    status: "ACTIVE",
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });
  const { t } = useTranslation();
  const { addBranch, isSubmitting } = useBranch();
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    console.log(user, "useruserusersss");
    if (user && user.businessType == "LOCAL_CABLE_OPERATOR") {
      setValue("tenant", {
        label: user.name,
        value: user.tenantId,
      });
    }
  }, [user]);

  const navigate = useNavigate();

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>C-Panal</Typography>
        <Typography>Branch</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  const onSubmit = async (data) => {
    try {
      const tenantDto = {
        tenantId: data.tenant.value,
      };
      data["tenant"] = tenantDto;
      await addBranch(data);
      //setStatus({ sent: true });
      dispatch(showSuccessSnackbar("Branch added successfully!"));
      reset();
      cancelBtn();
    } catch (err) {
      //setStatus({ sent: false });
      //setErrorSubmit({ submit: err.message });
      dispatch(showErrorSnackbar("Failed to add branch"));
    }
  };

  const cancelBtn = () => {
    navigate("/cpanel/branch/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_branch")} subheader={breadCrump()} />
          <CardContent>
            {/* {status && status.sent && (
                <Alert severity="success" my={3}>
                  Branch added successfully!
                </Alert>
              )} */}
            {/* {errorSubmit && errorSubmit.submit && (
                <Alert severity="error" my={3}>
                  {errorSubmit.submit}
                </Alert>
              )} */}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={tabValue} onChange={handleTabChange} aria-label="Branch">
                    <Tab label="Basic Details" {...a11yProps(0)} />
                    <Tab label="Contact Details" {...a11yProps(1)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <BasicDetail validationFields={validationSchema.fields} />
                  </TabPanel>

                  <TabPanel value={tabValue} index={1}>
                    <ContactDetail validationFields={validationSchema.fields} />
                  </TabPanel>
                </Box>

                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddBranch;
