import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import useProformaInvoice from "../../../hooks/useProformaInvoice";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function ProformaInvoices() {
  const { state } = useLocation();
  const { t } = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();

  const userId = searchParams.get("id");

  const currentPayload = {
    filters: [
      {
        fieldName: "radUserId",
        fieldValue: userId,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
        attributeName: "radUser",
        entityName: "RadUserEntity",
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "radUser",
        sort: "DESC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { getAllProformaInvoice, proformaInvoices, paging, downloadFile } = useProformaInvoice();
  const { openDialog } = useDialog();
  const { width: mainContentWidth } = useMainContentDimensions();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllProformaInvoice(payload);
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "invoiceNo",
      headerName: "Invoice No",
      width: 150,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 200,
    },
    {
      field: "mobileNo",
      headerName: "Mobile No",
      width: 100,
    },
    {
      field: "address",
      headerName: "Address",
      width: 500,
    },
    {
      field: "renewDate",
      headerName: "Invoice Date",
      width: 200,
    },
    {
      field: "finalInvoiceAmount",
      headerName: "Invoice Amount",
      width: 150,
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
      width: 200,
    },
  ];

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={<Toolbar downloadFile={downloadFile} />}
        title={t("proforma_invoices")}
        // subheader={breadCrump()}
        downloadFile={downloadFile}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          columns={columns}
          rows={proformaInvoices}
          getRowId={(row) => row["renewId"]}
          rowCount={paging.totalNumberOfRecords}
          loading={false}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </CardContent>
    </Card>
  );
}

export default ProformaInvoices;
