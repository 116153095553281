import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { NasProvider } from "../contexts/NasContext";
import { DialogProvider } from "../contexts/DialogContext";

export const NasComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<NasProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};
