//Users
import EmailLogComposer from "../composers/EmailLogComposer.js";
import Composer, { SmsToEmployeeComposer } from "../composers/SmsToEmployeeComposer.js";

//Composers
import { DashboardComposer } from "../composers/DashboardComposer.js";
// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
import EmailLog from "../pages/logs/email_logs/index.js";
import SmsToEmployee from "../pages/sms_and_email/send_sms_to_employee/index.js";
import LoginHistoryComposer from "../composers/LoginHistoryComposer.js";
import LoginHistory from "../pages/logs/login_history/index.js";
import UserActivityComposer from "../composers/UserActivityComposer.js";
import UserActivity from "../pages/logs/user_activities/index.js";
import SmsToTenantComposer from "../composers/SmsToTenantComposer.js";
import EmailToUserComposer from "../composers/EmailToUserComposer";
import EmailToTenantComposer from "../composers/EmailToTenantComposer";
import SmsToUserComposer from "../composers/SmsToUserComposer.js";
import EmailToTenant from "../pages/sms_and_email/send_email_to_tenants/index.js";
import EmailToUser from "../pages/sms_and_email/send_email_to_users/index.js";
import SmsToUser from "../pages/sms_and_email/send_sms_to_users/index.js";
import SmsToTenant from "../pages/sms_and_email/send_sms_to_tenants/index.js";

// import LeadClosed from "../pages/lead/leadclosed.js";

const SmsAndEmailRoutes = {
  path: "sms-and-email",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "email",
      element: (
        <EmailLogComposer>
          <EmailLog />
        </EmailLogComposer>
      ),
    },
    {
      path: "sms-employee",
      element: (
        <SmsToEmployeeComposer>
          <SmsToEmployee />
        </SmsToEmployeeComposer>
      ),
    },
    {
      path: "sms-tenant",
      element: (
        <SmsToTenantComposer>
          <SmsToTenant />
        </SmsToTenantComposer>
      ),
    },
    {
      path: "sms-user",
      element: (
        <SmsToUserComposer>
          <SmsToUser />
        </SmsToUserComposer>
      ),
    },
    {
      path: "email-user",
      element: (
        <EmailToUserComposer>
          <EmailToUser />
        </EmailToUserComposer>
      ),
    },
    {
      path: "email-Tenant",
      element: (
        <EmailToTenantComposer>
          <EmailToTenant />
        </EmailToTenantComposer>
      ),
    },
  ],
};

export default SmsAndEmailRoutes;
