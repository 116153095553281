import * as React from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Stack from "@mui/material/Stack";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const SaveReset = (props) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row-reverse" spacing={2}>
      {/* <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
        {props.loading ? <CircularProgress /> + " " + t("Save") : t("Save")}
      </Button> */}
      <LoadingButton
        loading={props.loading}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
        color="primary"
        type="submit"
      >
        Save
      </LoadingButton>
      {/* <Button
        variant="outlined"
        startIcon={<RestartAltIcon />}
        onClick={() => {
          props.resetForm();
        }}
      >
        {t("Reset")}
      </Button> */}
    </Stack>
  );
};
SaveReset.propTypes = {
  resetForm: PropTypes.func,
};

export default SaveReset;
