import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { ReportProvider } from "../contexts/ReportContext";
import { DialogProvider } from "../contexts/DialogContext";
import { PlanProvider } from "../contexts/PlanContext";
import { EmployeeProvider } from "../contexts/EmployeeContext";
export const ReportComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <ReportProvider key={0} />,
        <PlanProvider key={1} />,
        <EmployeeProvider key={2} />,
        <DialogProvider key={3} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
