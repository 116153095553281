import React, { useEffect } from "react";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import useLead from "../../../hooks/useLead";
import { useForm } from "react-hook-form";
import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Table,
  Box,
  Grid,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const validationSchema = Yup.object().shape({
  tenant: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  branchName: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  employee: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  status: Yup.string().required("Required"),
  leadType: Yup.string().required("Required"),
  mobileNo: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  customerName: Yup.string().required("Required"),
});

const BasicInformation = ({ user }) => {
  const { getLead, lead } = useLead();

  React.useEffect(() => {
    if (Object.keys(lead).length !== 0) {
      console.log(lead.status, "lead.status");
    }
  }, [lead]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { state } = useLocation();
  console.log(state, "location");

  console.log(user, "user");

  const fetchData = async () => {
    await getLead(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card sx={{ backgroundColor: "sss" }}>
              <CardHeader
                action={<IconButton aria-label="settings" size="large"></IconButton>}
                title=""
              />

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Status
                    </TableCell>

                    <TableCell align="right"> {lead.status} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Name:
                    </TableCell>
                    <TableCell align="right">{lead.customerName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      mobileNo:
                    </TableCell>
                    <TableCell align="right">{lead.mobileNo}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Comments:
                    </TableCell>
                    <TableCell align="right">{lead.comments}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card sx={{ backgroundColor: "sss" }}>
              <CardHeader
                action={<IconButton aria-label="settings" size="large"></IconButton>}
                title=""
              />
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Lead Type:
                    </TableCell>
                    <TableCell align="right">{lead.leadType}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Address:
                    </TableCell>
                    <TableCell align="right">{lead.address}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Email:
                    </TableCell>
                    <TableCell align="right">{lead.email}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BasicInformation;
