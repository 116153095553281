import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import BalanceStatementReducer from "../reducers/BalanceStatementReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { BalanceStatementConstants } from "../constants/balancestatement.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  balanceStatements: [],
  balanceReports: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  balanceStatementSearchResults: [],
  balanceStatement: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  balanceStatementAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const BalanceStatementContext = createContext({
  ...initialState,
  getAllBalanceStatement: () => {},
  getBalanceReport: () => {},
  addBalanceStatement: () => {},
  searchBalanceStatement: () => {},
  autoSearchBalanceStatement: () => {},
  getBalanceStatement: () => {},
  updateBalanceStatement: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function BalanceStatementProvider({ children }) {
  const [state, dispatch] = useReducer(BalanceStatementReducer, initialState);

  const getAllBalanceStatement = async (payload) => {
    try {
      dispatch({
        type: BalanceStatementConstants.FETCH_BALANCE_STATEMENT_REQUEST,
      });
      const response = await axios.post("/balance-statement/list", payload);
      dispatch({
        type: BalanceStatementConstants.FETCH_BALANCE_STATEMENT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: BalanceStatementConstants.FETCH_BALANCE_STATEMENT_FAILURE,
      });
    }
  };
  const getBalanceReport = async (payload) => {
    try {
      dispatch({
        type: BalanceStatementConstants.FETCH_BALANCE_REPORT_REQUEST,
      });
      const response = await axios.post("/balance-statement/report", payload);
      dispatch({
        type: BalanceStatementConstants.FETCH_BALANCE_REPORT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: BalanceStatementConstants.FETCH_BALANCE_REPORT_FAILURE,
      });
    }
  };
  const searchBalanceStatement = async (payload) => {
    try {
      const response = await axios.post("/balance-statement/search", payload);
      dispatch({
        type: BalanceStatementConstants.BALANCE_STATEMENT_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: BalanceStatementConstants.BALANCE_STATEMENT_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: BalanceStatementConstants.BALANCE_STATEMENT_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchBalanceStatement = async (payload) => {
    try {
      const response = await axios.post("/balance-statement/auto-search", payload);
      dispatch({
        type: BalanceStatementConstants.BALANCE_STATEMENT_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: BalanceStatementConstants.BALANCE_STATEMENT_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: BalanceStatementConstants.BALANCE_STATEMENT_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addBalanceStatement = async (payload) => {
    const response = await axios.post("/balance-statement/add", payload);
    dispatch({
      type: BalanceStatementConstants.ADD_BALANCE_STATEMENT_REQUEST,
    });
    dispatch({
      type: BalanceStatementConstants.ADD_BALANCE_STATEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const getBalanceStatement = async (id) => {
    const response = await axios.get("/balance-statement/" + id);
    dispatch({
      type: BalanceStatementConstants.GET_BALANCE_STATEMENT_REQUEST,
    });
    dispatch({
      type: BalanceStatementConstants.GET_BALANCE_STATEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const updateBalanceStatement = async (payload) => {
    const response = await axios.put(
      "/balance-statement/update/" + payload.balanceStatementId,
      payload,
    );
    dispatch({
      type: BalanceStatementConstants.UPDATE_BALANCE_STATEMENT_REQUEST,
    });
    dispatch({
      type: BalanceStatementConstants.UPDATE_BALANCE_STATEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: BalanceStatementConstants.DOWNLOAD_BALANCE_STATEMENT_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/balance-statement/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "balanceStatement." + fileType);
    dispatch({
      type: BalanceStatementConstants.DOWNLOAD_BALANCE_STATEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/balance-statement/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_balanceStatement." + fileType);
    dispatch({
      type: BalanceStatementConstants.DOWNLOAD_BALANCE_STATEMENT_REQUEST,
    });
    dispatch({
      type: BalanceStatementConstants.DOWNLOAD_BALANCE_STATEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: BalanceStatementConstants.UPLOAD_BALANCE_STATEMENT_REQUEST,
      });
      const response = await axios({
        url: "/balance-statement/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: BalanceStatementConstants.UPLOAD_BALANCE_STATEMENT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: BalanceStatementConstants.UPLOAD_BALANCE_STATEMENT_FAILURE,
      });
    }
  };

  return (
    <BalanceStatementContext.Provider
      value={{
        ...state,
        getAllBalanceStatement,
        getBalanceReport,
        addBalanceStatement,
        searchBalanceStatement,
        autoSearchBalanceStatement,
        getBalanceStatement,
        updateBalanceStatement,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </BalanceStatementContext.Provider>
  );
}

export { BalanceStatementContext, BalanceStatementProvider };
