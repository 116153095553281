import { RadUserWalletConstants } from "../constants/raduserwallet.constants";

const RadUserWalletReducer = (state, action) => {
  switch (action.type) {
    case RadUserWalletConstants.FETCH_RAD_USER_WALLET_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RadUserWalletConstants.FETCH_RAD_USER_WALLET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        radUserWallets: action.payload.data,
        paging: action.payload.paging,
      };
    case RadUserWalletConstants.FETCH_RAD_USER_WALLET_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case RadUserWalletConstants.RAD_USER_WALLET_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RadUserWalletConstants.RAD_USER_WALLET_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        radUserWalletSearchResults: action.payload,
      };
    case RadUserWalletConstants.RAD_USER_WALLET_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case RadUserWalletConstants.RAD_USER_WALLET_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RadUserWalletConstants.RAD_USER_WALLET_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        radUserWalletAutoSearchResults: action.payload,
      };
    case RadUserWalletConstants.RAD_USER_WALLET_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case RadUserWalletConstants.ADD_RAD_USER_WALLET_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case RadUserWalletConstants.ADD_RAD_USER_WALLET_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case RadUserWalletConstants.ADD_RAD_USER_WALLET_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case RadUserWalletConstants.GET_RAD_USER_WALLET_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RadUserWalletConstants.GET_RAD_USER_WALLET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        radUserWallet: action.payload,
      };
    case RadUserWalletConstants.GET_RAD_USER_WALLET_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case RadUserWalletConstants.UPDATE_RAD_USER_WALLET_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case RadUserWalletConstants.UPDATE_RAD_USER_WALLET_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        radUserWallet: action.payload,
      };
    case RadUserWalletConstants.UPDATE_RAD_USER_WALLET_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case RadUserWalletConstants.DOWNLOAD_RAD_USER_WALLET_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case RadUserWalletConstants.DOWNLOAD_RAD_USER_WALLET_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case RadUserWalletConstants.DOWNLOAD_RAD_USER_WALLET_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case RadUserWalletConstants.UPLOAD_RAD_USER_WALLET_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case RadUserWalletConstants.UPLOAD_RAD_USER_WALLET_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case RadUserWalletConstants.UPLOAD_RAD_USER_WALLET_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default RadUserWalletReducer;
