import { useContext } from "react";

import { PppProfileContext } from "../contexts/PppProfileContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const usePppProfile = () => {
  const context = useContext(PppProfileContext);

  if (!context) throw new Error("PppProfileContext must be placed within PppProfileProvider");

  return context;
};

export default usePppProfile;
