import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import { Controller } from "react-hook-form";
import styled from "@emotion/styled";

const ReactHookFormSelect = ({
  name,
  label,
  control,
  defaultValue,
  children,
  errors,
  fields,
  ...props
}) => {
  const labelId = `${name}-label`;
  const required = fields?.[name] == undefined ? false : true;
  console.log(required, "requiredrequiredrequired");
  return (
    <FormControl {...props}>
      <InputLabel id={labelId} error={errors[name] ? true : false} required={required}>
        {label}
      </InputLabel>
      <Controller
        render={({ field }) => (
          <Select
            {...field}
            labelId={labelId}
            id={labelId}
            label={label}
            error={errors[name] ? true : false}
            value={field.value ? field.value : ""}
          >
            {children}
          </Select>
        )}
        name={name}
        control={control}
      />
      <FormHelperText sx={{ color: "#d32f2f" }}>{errors[name]?.message}</FormHelperText>
    </FormControl>
  );
};
export default ReactHookFormSelect;
