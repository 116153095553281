import { useContext } from "react";

import { EmailToTenantContext } from "../contexts/EmailToTenantContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useEmailToTenant = () => {
  const context = useContext(EmailToTenantContext);

  if (!context)
    throw new Error("EmailToTenantConstants must be placed within EmailToTenantsProvider");

  return context;
};

export default useEmailToTenant;
