import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import SaveCancel from "../../../components/SaveCancel";
import SelectAsyncPaginate from "../../../components/SelectAsyncPaginate";
import useEmailMaster from "../../../hooks/useEmailMaster";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import EmailMasterDetail from "./components/EmailMasterDetail";
import { useDispatch } from "react-redux";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const validationSchema = Yup.object().shape({
  emailTemplateName: Yup.string().required("Required"),
  emailTemplateCode: Yup.string().required("Required"),
  subject: Yup.string().required("Required"),
  content: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function BasicForm(props) {
  const [defaultContentValue, setDefaultContentValue] = useState("");
  const form = React.useRef();
  const dispatch = useDispatch();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    formState: {},
  } = methods;

  const [value, setValue] = React.useState(0);

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // const { state: formState } = useFormState();

  // const { values: formValues, errors: formErrors } = formState.emailmaster;
  const { getEmailMaster, emailmaster, updateEmailMaster } = useEmailMaster();
  console.log(emailmaster, " values: formValues,");

  React.useEffect(() => {
    if (Object.keys(emailmaster).length !== 0) {
      console.log(emailmaster.status, " emailmaster.status,");
      const initialValues = {
        emailTemplateId: emailmaster.emailTemplateId,
        emailTemplateName: emailmaster.emailTemplateName,
        emailTemplateCode: emailmaster.emailTemplateCode,
        subject: emailmaster.subject,
        content: emailmaster.content,
        status: emailmaster.status,
      };
      console.log(initialValues, "initialValues");
      reset(initialValues);
      setDefaultContentValue(emailmaster.content);
    }
  }, [emailmaster]);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const [currentEmailMaster, setCurrentEmailMaster] = useState(null);
  const { state } = useLocation();
  console.log(state, "location");
  const { t } = props;

  React.useEffect(() => {
    if (form.current) {
      const { values, errors } = form.current;
      console.log(errors, "i am here to update values");
      dispatch({
        type: "UPDATE_FORM",
        payload: {
          id: "emailTemplateId",
          data: { values, errors },
        },
      });
    }
  }, [tabValue]);
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      console.log(data, "ffff");
      await updateEmailMaster(data);
      dispatch(showSuccessSnackbar("Email template updated successfully!"));
      setDefaultContentValue("");
      navigate("/masters/emailmaster/list");
      reset();
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
  };
  const cancelBtn = () => {
    navigate("/masters/emailmaster/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Master</Typography>
        <Typography>EmailTemplate</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getEmailMaster(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_emailtemplate")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value} onChange={handleTabChange} aria-label="EmailMaster">
                    <Tab label="Details" {...a11yProps(0)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <EmailMasterDetail defaultContentValue={defaultContentValue} />
                  </TabPanel>
                </Box>

                <SaveCancel cancelBtn={cancelBtn} handleSubmit={handleSubmit(onSubmit)} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

function EditEmailMaster() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}

export default EditEmailMaster;
