import { WalletConstants } from "../constants/wallet.constants";

const WalletReducer = (state, action) => {
  switch (action.type) {
    case WalletConstants.FETCH_WALLET_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case WalletConstants.FETCH_WALLET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: action.payload.data,
        paging: action.payload.paging,
      };
    case WalletConstants.FETCH_WALLET_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case WalletConstants.WALLET_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case WalletConstants.WALLET_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        walletSearchResults: action.payload,
      };
    case WalletConstants.WALLET_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case WalletConstants.ADD_WALLET_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case WalletConstants.ADD_WALLET_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WalletConstants.ADD_WALLET_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case WalletConstants.GET_WALLET_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case WalletConstants.GET_WALLET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wallet: action.payload,
      };
    case WalletConstants.GET_WALLET_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case WalletConstants.UPDATE_WALLET_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case WalletConstants.UPDATE_WALLET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wallet: action.payload,
      };
    case WalletConstants.UPDATE_WALLET_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case WalletConstants.DOWNLOAD_WALLET_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case WalletConstants.DOWNLOAD_WALLET_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case WalletConstants.DOWNLOAD_WALLET_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case WalletConstants.UPLOAD_WALLET_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case WalletConstants.UPLOAD_WALLET_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case WalletConstants.UPLOAD_WALLET_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    case WalletConstants.ADD_PAYMENT_TRANS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case WalletConstants.ADD_PAYMENT_TRANS_SUCCESS:
      return {
        ...state,
        paymentTrans: action.payload,
        isLoading: false,
      };
    case WalletConstants.ADD_PAYMENT_TRANS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    default:
      return state;
  }
};

export default WalletReducer;
