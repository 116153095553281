import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import LeadReducer from "../reducers/LeadReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { LeadConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  leads: [],
  leadStats: {
    total: 0,
    open: 0,
    confirmed: 0,
    interested: 0,
    notInterested: 0,
    closed: 0,
  },
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  leadHistories: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  leadSearchResults: [],
  leadHistorySearchResults: [],
  lead: {},
  leadHistory: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  LeadAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },

  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const LeadContext = createContext({
  ...initialState,
  getAllLead: () => {},
  getAllLeadHistory: () => {},
  getAllStats: () => {},

  addLead: () => {},
  searchLead: () => {},
  autoSearchLead: () => {},
  getLead: () => {},
  assignLead: () => {},
  updateLead: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function LeadProvider({ children }) {
  const [state, dispatch] = useReducer(LeadReducer, initialState);

  const getAllLead = async (payload) => {
    try {
      const response = await axios.post("/lead/list", payload);
      dispatch({
        type: LeadConstants.FETCH_LEAD_REQUEST,
      });
      dispatch({
        type: LeadConstants.FETCH_LEAD_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: LeadConstants.FETCH_LEAD_FAILURE,
      });
    }
  };

  const getAllLeadHistory = async (payload) => {
    try {
      dispatch({
        type: LeadConstants.FETCH_LEAD_HISTORY_REQUEST,
      });
      const response = await axios.post("/lead/history", payload);

      dispatch({
        type: LeadConstants.FETCH_LEAD_HISTORY_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: LeadConstants.FETCH_LEAD_HISTORY_FAILURE,
      });
    }
  };

  const getAllLeadStats = async (payload) => {
    try {
      const response = await axios.post("/lead/stats", payload);
      dispatch({
        type: LeadConstants.FETCH_LEAD_STATS_REQUEST,
      });
      dispatch({
        type: LeadConstants.FETCH_LEAD_STATS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: LeadConstants.FETCH_LEAD_STATS_FAILURE,
      });
    }
  };

  const searchLead = async (payload) => {
    try {
      const response = await axios.post("/lead/search", payload);
      dispatch({
        type: LeadConstants.LEAD_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: LeadConstants.LEAD_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: LeadConstants.LEAD_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchLead = async (payload) => {
    try {
      const response = await axios.post("/lead/auto-search", payload);
      dispatch({
        type: LeadConstants.LEAD_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: LeadConstants.LEAD_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: LeadConstants.LEAD_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addLead = async (payload) => {
    const response = await axios.post("/lead/add", payload);
    dispatch({
      type: LeadConstants.ADD_LEAD_REQUEST,
    });
    console.log(response.data, "addaddadd");
    dispatch({
      type: LeadConstants.ADD_LEAD_SUCCESS,
      payload: response.data,
    });
  };

  const getLead = async (id) => {
    const response = await axios.get("/lead/" + id);
    dispatch({
      type: LeadConstants.GET_LEAD_REQUEST,
    });
    dispatch({
      type: LeadConstants.GET_LEAD_SUCCESS,
      payload: response.data,
    });
  };

  const updateLead = async (payload) => {
    const response = await axios.put("/lead/update/" + payload.leadId, payload);
    dispatch({
      type: LeadConstants.UPDATE_LEAD_REQUEST,
    });
    dispatch({
      type: LeadConstants.UPDATE_LEAD_SUCCESS,
      payload: response.data,
    });
  };

  const assignLead = async (payload) => {
    const response = await axios.post("/lead/assign", payload);
    dispatch({
      type: LeadConstants.LEAD_ASSIGN_REQUEST,
    });
    dispatch({
      type: LeadConstants.LEAD_ASSIGN_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: LeadConstants.DOWNLOAD_LEAD_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/lead/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "lead." + fileType);

    dispatch({
      type: LeadConstants.DOWNLOAD_LEAD_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/lead/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_lead." + fileType);
    dispatch({
      type: LeadConstants.DOWNLOAD_LEAD_REQUEST,
    });
    dispatch({
      type: LeadConstants.DOWNLOAD_LEAD_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: LeadConstants.UPLOAD_LEAD_REQUEST,
      });
      const response = await axios({
        url: "/lead/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: LeadConstants.UPLOAD_LEAD_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: LeadConstants.UPLOAD_LEAD_FAILURE,
      });
    }
  };

  return (
    <LeadContext.Provider
      value={{
        ...state,
        getAllLead,
        getAllLeadHistory,
        getAllLeadStats,
        addLead,
        searchLead,
        autoSearchLead,
        getLead,
        assignLead,
        updateLead,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </LeadContext.Provider>
  );
}

export { LeadContext, LeadProvider };
