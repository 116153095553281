import React from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import AssignUser from "./assign";
import { useDialog } from "../../.././contexts/DialogContext";
import { getTimeStamp } from "../../.././utils/common";

import ComplaintMore from "../../../components/ComplaintMore";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import AdvanceFilter from "./AdvanceFilter";
import useComplaint from "../../../hooks/useComplaint";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Complaint</Typography>
      <Typography>List</Typography>
    </Breadcrumbs>
  );
};

const CustomDataGrid = (props) => {
  const { isLoadingDownload, downloadFileType } = useComplaint();

  const { height, width } = useWindowDimensions();
  const { width: mainContentWidth } = useMainContentDimensions();

  const {
    editPage,
    addPage,
    trans,
    rows,
    rowCount,
    payload,
    setPayload,
    setFilterResetFunc,
    isLoading,
    id,
    handleSortModelChange,
    sortModel,
    downloadFile,
    openDownloadDialog,
    viewDetails,
    fetchData,
    refreshPage,
    paginationModel,
    paginationMeta,
    paging,
    onPaginationModelChange,
  } = props;
  console.log(rowCount, "loading child component");

  const { openDialog } = useDialog();
  const assignTo = (id) => {
    return openDialog(
      "Assign",
      true,
      <AssignUser key={getTimeStamp()} complaintId={id} fetchData={fetchData} />,
    );
  };
  const columns = [
    {
      field: "action",
      headerName: "Action",
      filter: false,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          console.log(thisRow, "thisRow");
        };
        return (
          <ComplaintMore
            rowClick={onClick}
            editPage={() => editPage(params.id)}
            assignTo={() => assignTo(params.id)}
            viewDetails={() => viewDetails(params.id)}
            menus={["edit", "details", "assign"]}
          />
        );
      },
    },
    {
      field: "complaintNo",
      headerName: "Complaint No",
      width: 120,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 200,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 200,
    },
    {
      field: "mobileNo",
      headerName: "Mobile No",
      width: 120,
    },
    {
      field: "address",
      headerName: "Address",
      width: 300,
    },

    {
      field: "complaintType",
      headerName: "Complaint Type",
      valueGetter: (params) => {
        return params.complaintTypeName;
      },
      type: "string",
      width: 120,
    },

    {
      field: "employee",
      headerName: "Assigned To ",
      valueGetter: (params) => params.employeeName,
      type: "string",
      width: 200,
    },
    // {
    //   field: "employee",
    //   headerName: "Assign To",
    //   width: 300,
    // },
    {
      field: "complaintDate",
      headerName: "Complaint Date",
      width: 180,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 70,
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 300,
    },
  ];

  const openFilterDialog = () => {
    return openDialog(
      "advance_filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={
          <Toolbar
            addPage={addPage}
            downloadFile={downloadFile}
            openDownloadDialog={openDownloadDialog}
            refreshPage={refreshPage}
            openFilterDialog={openFilterDialog}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
          />
        }
        title={trans("complaint_list")}
        subheader={breadCrump()}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          checkboxSelection
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          rowCount={rowCount}
          getRowId={(row) => row[id]}
          columns={columns}
          rows={rows}
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMeta={paginationMeta}
          loading={isLoading}
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
          onPaginationModelChange={onPaginationModelChange}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          paginationModel={paginationModel}
        />
      </CardContent>
    </Card>
  );
};
CustomDataGrid.propTypes = {
  editPage: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  trans: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  paging: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSortModelChange: PropTypes.func.isRequired,
  sortModel: PropTypes.object.isRequired,
};
export default CustomDataGrid;
