import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { AgreementTrackerProvider } from "../contexts/AgreementTrackerContext";
import { DialogProvider } from "../contexts/DialogContext";
import { TenantProvider } from "../contexts/TenantContext";

export const AgreementTrackerComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <AgreementTrackerProvider key={0} />,
        <TenantProvider key={1} />,
        <DialogProvider key={2} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
