import React, { useEffect, useState } from "react";
import useMainContentDimensions from "../../../../utils/mainContentDimensions";

import { Card, CardContent } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useWindowDimensions from "../../../../utils/windowDimensions";
import useAuth from "../../../../hooks/useAuth";
import useUser from "../../../../hooks/useUser";
import { formatDate, addDays } from "../../../../utils/common";

function Next() {
  const { user } = useAuth();
  const { getAllUser, users: expiredNext15Users, paging, isLoading } = useUser();
  const { width: mainContentWidth } = useMainContentDimensions();

  const currentPayload = {
    filters: [
      {
        fieldName: "expiration",
        fieldValue:
          formatDate(addDays(new Date(), 15)) +
          " 00:00:00," +
          formatDate(addDays(new Date(), 15)) +
          " 23:59:59",
        op: "btn",
        dataType: "string",
        condition: "and",
        isJoin: false,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
    sortFields: [
      {
        field: "createdAt",
        sort: "DESC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    if (Object.keys(payload).length != 0) {
      await getAllUser(payload);
    }
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: currentPayload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "userName",
      headerName: "User Name",
      width: 100,
    },
    {
      field: "firstName",
      headerName: "Customer Name",
      width: 130,
    },
    {
      field: "mobileNo",
      headerName: "Mobile",
      width: 150,
    },
  ];

  return (
    <Card sx={{ width: mainContentWidth - 600 }}>
      <CardContent sx={{ height: height - 200 }}>
        <DataGrid
          columns={columns}
          rows={expiredNext15Users}
          refreshPage={fetchData}
          getRowId={(row) => row["radUserId"]}
          rowCount={paging?.totalNumberOfRecords}
          loading={isLoading}
          rowsPerPageOptions={[5]}
          pagination
          page={paging?.pageNumber}
          pageSize={paging?.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </CardContent>
    </Card>
  );
}

export default Next;
