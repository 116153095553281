import { useContext } from "react";

import { ComplaintContext } from "../contexts/ComplaintContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useComplaint = () => {
  const context = useContext(ComplaintContext);

  if (!context) throw new Error("ComplaintTypeContext must be placed within ComplaintProvider");

  return context;
};

export default useComplaint;
