import React from "react";

const ShowHtmlContent = (props) => {
  const { content } = props;
  return (
    <div>
      {content.indexOf("</") !== -1 ? (
        <div
          dangerouslySetInnerHTML={{
            __html: content.replace(/(<? *script)/gi, "illegalscript"),
          }}
        ></div>
      ) : (
        content
      )}
    </div>
  );
};

export default ShowHtmlContent;
