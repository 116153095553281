import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import CustomTextField from "../../../components/CustomTextField";
import { useLocation } from "react-router-dom";
import ReactHookFormSelect from "../../../components/ReactHookFormSelect";
import { useDialog } from "../../../contexts/DialogContext";
import useUser from "../../../hooks/useUser";
import SearchableAutoComplete from "../../../components/SearchableAutoComplete";
import useTenant from "../../../hooks/useTenant";
import useRate from "../../../hooks/useRate";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import useEmployee from "../../../hooks/useEmployee";
import useUnpaidInvoice from "../../../hooks/useUnpaidInvoice";
const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  cancelComments: Yup.string().required("Required"),
});

const Cancel = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [payload, setPayload] = useState(currentPayload);
  const { renewId, refreshPage } = props;

  const { getRenewal, renewal } = useUser();
  const { cancelInvoice, iscancelSubmitting } = useUnpaidInvoice();

  React.useEffect(() => {
    console.log(renewal, " values: formValues,");

    if (Object.keys(renewal).length !== 0) {
      const initialValues = {
        renewId: renewId,
        invoiceNo: renewal.invoiceNo,
        cancelComments: "",
      };
      //console.log(initialValues, "initialValues");
      reset(initialValues);
    }
  }, [renewal]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;
  const { unSetDialog, openDialog } = useDialog();

  const onSubmit = async (data) => {
    try {
      await cancelInvoice(data);
      dispatch(showSuccessSnackbar("Renewal cancelled successfully!"));
      unSetDialog("cancel");
      refreshPage();
    } catch (ex) {
      dispatch(showErrorSnackbar(ex.message));
    }
  };

  const fetchData = async () => {
    await getRenewal(renewId);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={12}>
              <CustomTextField
                label="Comment"
                name="cancelComments"
                control={control}
                fullWidth
                fields={validationSchema.fields}
                variant="outlined"
                errors={errors}
                rows={6}
                multiline={true}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<ReplayIcon />}
              endIcon={iscancelSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Cancel
            </Button>

            <Button variant="text" onClick={() => unSetDialog("cancel")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default Cancel;
