import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { getTimeStamp } from "../../utils/common";
import { spacing } from "@mui/system";
import TabPanel from "../../components/TabPanel";
import { a11yProps } from "../../utils/common";
import BasicInformation from "./components/BasicInformation";
import TrackerHistory from "./components/AgTrackHistory";
import Button from "@mui/material/Button";
import AgreementReceived from "./components/AgreementReceived";
import { useDialog } from "../../contexts/DialogContext";
import useAgreementTracker from "../../hooks/useAgreementTracker";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AgreementDispatched from "./components/AgreementDispatched";
import CourierVerify from "./components/CourierVerified";
import IspAgreementReceived from "./components/IspAgreementReceived";
import IDSAgreement from "./components/IspDispatchSignedAgreement";
import { green, red, orange, pink, purple, blue, grey } from "@mui/material/colors";
import SignedAgreementReceived from "./components/SignedAgreementReceived";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

const initialValues = {
  tenAgrMntDispatchedDate: "",
  tenAgrMntDispatchedDateStr: "",
  agreementReceivedDate: "",
  agreementReceivedDateStr: "",
  tenDocketNumber: "",
  tenCourierType: "professor",
  tenCourierName: "dtd",
  comments: "",
};

const validationSchema = Yup.object().shape({
  tenAgrMntDispatchedDate: Yup.string().required("Required"),
  agreementReceivedDate: Yup.string().required("Required"),
  tenDocketNumber: Yup.string().required("Required"),
  tenCourierType: Yup.string().required("Required"),
  tenCourierName: Yup.string().required("Required"),
  comments: Yup.string().required("Required"),
});

function AgTrackDetails() {
  const { state } = useLocation();

  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  let [searchParams, setSearchParams] = useSearchParams();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const currentPayload = {
    filters: [
      {
        fieldName: "agreementTrackerHistoryId",
        fieldValue: searchParams.get("id"),
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "createdAt",
        sort: "DESC",
      },
    ],
  };

  console.log(searchParams.get("id"), "consoooooo");

  const {
    getAllAgreementTrackerHistory,
    getAgreementTracker,
    agreementTracker,
    agreementTrackers,
  } = useAgreementTracker();

  const { openDialog } = useDialog();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAgreementTracker(searchParams.get("id"));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchHistoryData = async () => {
    await getAllAgreementTrackerHistory(payload);
  };

  useEffect(() => {
    fetchHistoryData();
  }, [payload]);

  const tenantAgreementReceived = () => {
    return openDialog(
      "agreement_received",
      "Agreement Received",
      <AgreementReceived
        key={getTimeStamp()}
        fetchData={fetchData}
        fields={validationSchema.fields}
        agreementTrackerId={searchParams.get("id")}
      />,
    );
  };

  const tenantAgreementDispatched = () => {
    return openDialog(
      "agreement_dispatched",
      "Agreement Dispatched",
      <AgreementDispatched
        key={getTimeStamp()}
        fetchData={fetchData}
        fields={validationSchema.fields}
        agreementTrackerId={searchParams.get("id")}
      />,
    );
  };

  const tenantCourierVerified = () => {
    return openDialog(
      "courier_verified",
      "Courier Verified",
      <CourierVerify
        key={getTimeStamp()}
        fetchData={fetchData}
        fields={validationSchema.fields}
        agreementTrackerId={searchParams.get("id")}
      />,
    );
  };

  const ispAgreementReceived = () => {
    return openDialog(
      "isp_agreement_received",
      "ISP Agreement Received",
      <IspAgreementReceived
        key={getTimeStamp()}
        fetchData={fetchData}
        fields={validationSchema.fields}
        agreementTrackerId={searchParams.get("id")}
      />,
    );
  };

  const idsAgreement = () => {
    return openDialog(
      "isp_dispatch_signed_agreement",
      "ISP Dispatch Signed Agreement",
      <IDSAgreement
        key={getTimeStamp()}
        fetchData={fetchData}
        fields={validationSchema.fields}
        agreementTrackerId={searchParams.get("id")}
      />,
    );
  };

  const signedAgreementReceived = () => {
    return openDialog(
      "signed_agreement_received",
      "Signed Agreement Received",
      true,
      <SignedAgreementReceived
        key={getTimeStamp()}
        fetchData={fetchData}
        fields={validationSchema.fields}
        agreementTrackerId={searchParams.get("id")}
      />,
    );
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Agreement Tracker</Typography>
        <Typography>Agreement Tracker Details</Typography>
      </Breadcrumbs>
    );
  };

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardHeader title={t("ag_tracker_details")} subheader={breadCrump()} />
        <CardContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            {agreementTracker && agreementTracker.status == "ISP_AGRMT_DISPATCHED" && (
              <Button variant="contained" onClick={tenantAgreementReceived}>
                Tenant Agreement Received
              </Button>
            )}
            {agreementTracker && agreementTracker.status == "TENANT_AGRMT_RECEIVED" && (
              <Button variant="contained" color="warning" onClick={tenantAgreementDispatched}>
                Tenant Agreement Dispatched
              </Button>
            )}
            {agreementTracker && agreementTracker.status == "TENANT_AGRMT_DISPATCHED" && (
              <Button variant="contained" color="success" onClick={tenantCourierVerified}>
                Tenant Courier Verified
              </Button>
            )}
            {agreementTracker && agreementTracker.status == "COURIER_VERIFIED" && (
              <Button variant="contained" color="error" onClick={ispAgreementReceived}>
                ISP Agreement Received
              </Button>
            )}
            {agreementTracker && agreementTracker.status == "ISP_AGRMT_RECEIVED" && (
              <Button variant="contained" color="secondary" onClick={idsAgreement}>
                ISP Dispatch Signed Agreement
              </Button>
            )}
            {agreementTracker && agreementTracker.status == "ISP_DISPATCHED_SIGNED_AGRMT" && (
              <Button variant="contained" color="success" onClick={signedAgreementReceived}>
                Tenant Agreement Signed Received
              </Button>
            )}
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="Branch">
              <Tab label="Basic Information" {...a11yProps(0)} />
              <Tab label="Tracker History" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicInformation />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <TrackerHistory />
              </Grid>
            </TabPanel>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default AgTrackDetails;
