import { InterfaceConstants } from "../constants/interface.constants";

const InterfaceReducer = (state, action) => {
  switch (action.type) {
    case InterfaceConstants.FETCH_INTERFACE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case InterfaceConstants.FETCH_INTERFACE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        interfaces: action.payload.data,
        paging: action.payload.paging,
      };
    case InterfaceConstants.FETCH_INTERFACE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case InterfaceConstants.INTERFACE_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case InterfaceConstants.INTERFACE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        interfaceSearchResults: action.payload,
      };
    case InterfaceConstants.INTERFACE_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case InterfaceConstants.INTERFACE_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case InterfaceConstants.INTERFACE_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        interfaceAutoSearchResults: action.payload,
      };
    case InterfaceConstants.INTERFACE_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case InterfaceConstants.ADD_INTERFACE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case InterfaceConstants.ADD_INTERFACE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case InterfaceConstants.ADD_INTERFACE_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case InterfaceConstants.GET_INTERFACE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case InterfaceConstants.GET_INTERFACE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        interface: action.payload,
      };
    case InterfaceConstants.GET_INTERFACE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case InterfaceConstants.UPDATE_INTERFACE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case InterfaceConstants.UPDATE_INTERFACE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        interface: action.payload,
      };
    case InterfaceConstants.UPDATE_INTERFACE_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case InterfaceConstants.DOWNLOAD_INTERFACE_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case InterfaceConstants.DOWNLOAD_INTERFACE_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case InterfaceConstants.DOWNLOAD_INTERFACE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case InterfaceConstants.UPLOAD_INTERFACE_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case InterfaceConstants.UPLOAD_INTERFACE_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case InterfaceConstants.UPLOAD_INTERFACE_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default InterfaceReducer;
