import React from "react";
import styled from "@emotion/styled";

import { Button as MuiButton, Menu, MenuItem } from "@mui/material";
import { Loop as LoopIcon, FilterList as FilterListIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

import { roundOff } from "../../../utils/common";

const Button = styled(MuiButton)(spacing);

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

function Actions(props) {
  const { walletBalance, fetchTenantData, fetchDashboardData, loading } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const refreshDashboard = () => {
    fetchTenantData();
    fetchDashboardData();
  };

  return (
    <React.Fragment>
      <Button variant="contained" color="secondary" aria-haspopup="true">
        Wallet Balance: {roundOff(walletBalance)}
      </Button>
      <SmallButton size="small" mr={2}>
        <LoopIcon onClick={() => refreshDashboard()} />
      </SmallButton>
      {loading && <CircularProgress />}

      {/* <SmallButton size="small" mr={2}>
        <FilterListIcon />
      </SmallButton> */}
      {/* <Button
        variant="contained"
        color="secondary"
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        Today: April 29
      </Button> */}
      {/* <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose}>Today</MenuItem>
        <MenuItem onClick={handleClose}>Yesterday</MenuItem>
        <MenuItem onClick={handleClose}>Last 7 days</MenuItem>
        <MenuItem onClick={handleClose}>Last 30 days</MenuItem>
        <MenuItem onClick={handleClose}>This month</MenuItem>
        <MenuItem onClick={handleClose}>Last month</MenuItem>
      </Menu> */}
    </React.Fragment>
  );
}

export default Actions;
