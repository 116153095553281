import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";

import SaveCancel from "../../../components/SaveCancel";
import SelectAsyncPaginate from "../../../components/SelectAsyncPaginate";
import useDepartment from "../../../hooks/useDepartment";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import BasicDetail from "./components/BasicDetail";
import { useFormState, useFormDispatch } from "../../../contexts/DepartmentFormContext";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const validationSchema = Yup.object().shape({
  departmentName: Yup.string().required("Required"),
  totalLimit: Yup.string().required("Required"),
  price: Yup.string().required("Required"),
  taxEnabled: Yup.string().required("Required"),
  taxType: Yup.string().required("Required"),
  comments: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function BasicForm(props) {
  const form = React.useRef();
  const dispatch = useFormDispatch();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { reset, handleSubmit, setValue } = methods;

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { getDepartment, department, updateDepartment } = useDepartment();

  React.useEffect(() => {
    console.log(department, " values: formValues,");

    if (Object.keys(department).length !== 0) {
      const initialValues = {
        departmentId: department.departmentId,
        departmentName: department.departmentName,
        totalLimit: department.totalLimit,
        price: department.price,
        taxEnabled: department.taxEnabled,
        taxType: department.taxType,
        comments: department.comments,
        status: department.status,
      };
      console.log(initialValues, "initialValues");
      reset(initialValues);
    }
  }, [department]);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const { state } = useLocation();
  console.log(state, "location");
  const { t } = props;

  React.useEffect(() => {
    if (form.current) {
      const { values, errors } = form.current;
      console.log(errors, "i am here to update values");
      dispatch({
        type: "UPDATE_FORM",
        payload: {
          id: "department",
          data: { values, errors },
        },
      });
    }
  }, [tabValue]);

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      console.log(data, "ffff");
      await updateDepartment(data);
      setStatus({ sent: true });
      //reset();
    } catch (err) {
      setStatus({ sent: false });
      setErrorSubmit({ submit: err.message });
    }
  };

  const cancelBtn = () => {
    navigate("/cpanel/department/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>C-Panal</Typography>
        <Typography>Department</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getDepartment(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form>
          <Card mb={6}>
            <CardHeader title={t("edit_department")} subheader={breadCrump()} />
            <CardContent>
              {status && status.sent && (
                <Alert severity="success" my={3}>
                  Department updated successfully!
                </Alert>
              )}

              {errorSubmit && errorSubmit.submit && (
                <Alert severity="error" my={3}>
                  {errorSubmit.submit}
                </Alert>
              )}

              {isSubmitting ? (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="Department">
                      <Tab label="Basic Details" {...a11yProps(0)} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                      <BasicDetail />
                    </TabPanel>
                  </Box>

                  <SaveCancel cancelBtn={cancelBtn} handleSubmit={handleSubmit(onSubmit)} />
                </form>
              )}
            </CardContent>
          </Card>
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

function EditDepartment() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}

export default EditDepartment;
