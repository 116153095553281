import React from "react";

function Challenge() {
  return (
    <React.Fragment>
      <div>Welcome to challegne</div>
    </React.Fragment>
  );
}

export default Challenge;
