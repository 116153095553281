import { PaidInvoiceConstants } from "../constants/paidinvoice.constants";

const PaidInvoicesReducer = (state, action) => {
  switch (action.type) {
    case PaidInvoiceConstants.FETCH_PAIDINVOICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PaidInvoiceConstants.FETCH_PAIDINVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paidInvoices: action.payload.data,
        paging: action.payload.paging,
      };
    case PaidInvoiceConstants.FETCH_PAIDINVOICE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PaidInvoiceConstants.PAIDINVOICE_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PaidInvoiceConstants.PAIDINVOICE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paidInvoiceSearchResults: action.payload,
      };
    case PaidInvoiceConstants.PAIDINVOICE_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PaidInvoiceConstants.PAIDINVOICE_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PaidInvoiceConstants.PAIDINVOICE_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paidInvoiceAutoSearchResults: action.payload,
      };
    case PaidInvoiceConstants.PAIDINVOICE_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PaidInvoiceConstants.GET_PAIDINVOICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PaidInvoiceConstants.GET_PAIDINVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paidInvoice: action.payload,
      };
    case PaidInvoiceConstants.GET_PAIDINVOICE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PaidInvoiceConstants.DOWNLOAD_PAIDINVOICE_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case PaidInvoiceConstants.DOWNLOAD_PAIDINVOICE_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PaidInvoiceConstants.DOWNLOAD_PAIDINVOICE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };

    default:
      return state;
  }
};

export default PaidInvoicesReducer;
