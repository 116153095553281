import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import { useTranslation } from "react-i18next";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import useScheduleUser from "../../../hooks/useScheduleUser";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function ScheduleUser() {
  const { state } = useLocation();
  const { t } = useTranslation();

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "createdAt",
        sort: "DESC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { getAllScheduleUser, scheduleUsers, paging, downloadFile } = useScheduleUser();
  const { openDialog } = useDialog();
  const { width: mainContentWidth } = useMainContentDimensions();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllScheduleUser(payload);
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  // const breadCrump = () => {
  //   return (
  //     <Breadcrumbs aria-label="Breadcrumb" mt={2}>
  //       <Typography>Invoices</Typography>
  //       <Typography>Paid</Typography>
  //     </Breadcrumbs>
  //   );
  // };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "plan",
      headerName: "Plan",
      valueGetter: (params) => params?.planName,
      width: 150,
    },
    {
      field: "rate",
      headerName: "Rate",
      valueGetter: (params) => params?.rateName,
      width: 150,
    },
    {
      field: "scheduleDateStr",
      headerName: "Schedule Date",
      width: 150,
    },
    {
      field: "scheduleStatus",
      headerName: "Status",
      width: 150,
    },
    {
      field: "scheduleType",
      headerName: "Schedule Type",
      width: 150,
    },
    {
      field: "radUser",
      headerName: "Rad User",
      valueGetter: (params) => params?.userName,
      width: 150,
    },
    {
      field: "renewedDateStr",
      headerName: "Renewed Date",
      width: 150,
    },
  ];

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={<Toolbar downloadFile={downloadFile} />}
        title={t("schedule_user_list")}
        // subheader={breadCrump()}
        downloadFile={downloadFile}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          columns={columns}
          rows={scheduleUsers}
          getRowId={(row) => row["scheduleUserId"]}
          rowCount={paging.totalNumberOfRecords}
          loading={false}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </CardContent>
    </Card>
  );
}

export default ScheduleUser;
