import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { LeadProvider } from "../contexts/LeadContext";
// import { UserProvider } from "../contexts/UserContext";
import { DialogProvider } from "../contexts/DialogContext";
import { EmployeeProvider } from "../contexts/EmployeeContext";
import { TenantProvider } from "../contexts/TenantContext";
import { BranchProvider } from "../contexts/BranchContext";

export const LeadComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <LeadProvider key={0} />,
        <EmployeeProvider key={1} />,
        <DialogProvider key={2} />,
        <TenantProvider key={3} />,
        <BranchProvider key={4} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
