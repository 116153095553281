import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import PlanHistoryReducer from "../reducers/PlanHistoryReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { PlanHistoryConstants } from "../constants/planhistory.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  planHistories: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  planHistorySearchResults: [],
  isLoadingDownload: false,
  planHistoryAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  planHistory: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const PlanHistoryContext = createContext({
  ...initialState,
  getAllUserPlanHistory: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function PlanHistoryProvider({ children }) {
  const [state, dispatch] = useReducer(PlanHistoryReducer, initialState);

  const getAllUserPlanHistory = async (payload) => {
    try {
      dispatch({
        type: PlanHistoryConstants.FETCH_PLAN_HISTORY_REQUEST,
      });
      const response = await axios.post("/radius/raduser/plan-history/list", payload);
      dispatch({
        type: PlanHistoryConstants.FETCH_PLAN_HISTORY_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: PlanHistoryConstants.FETCH_PLAN_HISTORY_FAILURE,
      });
    }
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: PlanHistoryConstants.DOWNLOAD_PLAN_HISTORY_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/radius/planHistory/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "planHistory." + fileType);

    dispatch({
      type: PlanHistoryConstants.DOWNLOAD_PLAN_HISTORY_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/radius/planHistory/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_planHistory." + fileType);
    dispatch({
      type: PlanHistoryConstants.DOWNLOAD_PLAN_HISTORY_REQUEST,
    });
    dispatch({
      type: PlanHistoryConstants.DOWNLOAD_PLAN_HISTORY_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: PlanHistoryConstants.UPLOAD_PLAN_HISTORY_REQUEST,
      });
      const response = await axios({
        url: "/radius/planHistory/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: PlanHistoryConstants.UPLOAD_PLAN_HISTORY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: PlanHistoryConstants.UPLOAD_PLAN_HISTORY_FAILURE,
      });
    }
  };

  return (
    <PlanHistoryContext.Provider
      value={{
        ...state,
        getAllUserPlanHistory,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </PlanHistoryContext.Provider>
  );
}

export { PlanHistoryContext, PlanHistoryProvider };
