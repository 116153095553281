import { useContext } from "react";

import { AgreementTrackerContext } from "../contexts/AgreementTrackerContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useAgreementTracker = () => {
  const context = useContext(AgreementTrackerContext);

  if (!context)
    throw new Error("AgreementTrackerContext must be placed within AgreementTrackerProvider");

  return context;
};

export default useAgreementTracker;
