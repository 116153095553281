import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { spacing } from "@mui/system";

import SaveCancel from "../../../components/SaveCancel";
import SelectAsyncPaginate from "../../../components/SelectAsyncPaginate";
import useBranch from "../../../hooks/useBranch";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import CardInfo from "./components/CardInfo";
import { useFormState, useFormDispatch } from "../../../contexts/BranchFormContext";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import PackageInfo from "./components/PlanInfo";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const validationSchema = Yup.object().shape({
  cardSystemName: Yup.string().required("Required"),
  tenant: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  status: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  mobileNo: Yup.string().required("Required"),
  emailId: Yup.string().required("Required"),
});

function BasicForm(props) {
  const dispatch = useDispatch();
  const form = React.useRef();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { reset, handleSubmit, setValue } = methods;

  const { getBranch, cardSystem, updateBranch, isSubmitting } = useBranch();
  React.useEffect(() => {
    console.log(cardSystem, " values: formValues,");

    if (Object.keys(cardSystem).length !== 0) {
      const initialValues = {
        cardSystemId: cardSystem.cardSystemId,
        cardSystemName: cardSystem.cardSystemName,
        firstName: cardSystem.firstName,
        lastName: cardSystem.lastName,
        emailId: cardSystem.emailId,
        mobileNo: cardSystem.mobileNo,
        tenant: {
          value: cardSystem.tenant?.tenantId,
          label: cardSystem.tenant?.tenantName,
        },
        status: cardSystem.status,
      };
      //console.log(initialValues, "initialValues");
      reset(initialValues);
    }
  }, [cardSystem]);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const { state } = useLocation();
  console.log(state, "location");
  const { t } = props;

  React.useEffect(() => {
    if (form.current) {
      const { values, errors } = form.current;
      console.log(errors, "i am here to update values");
      dispatch({
        type: "UPDATE_FORM",
        payload: {
          id: "cardSystem",
          data: { values, errors },
        },
      });
    }
  }, [tabValue]);

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const tenantDto = {
        tenantId: data.tenant.value,
      };
      data["tenant"] = tenantDto;
      await updateBranch(data);
      dispatch(showSuccessSnackbar("Branch updated successfully!"));
      //setStatus({ sent: true });
      //reset();
      cancelBtn();
    } catch (err) {
      //setStatus({ sent: false });
      //setErrorSubmit({ submit: err.message });
      dispatch(showErrorSnackbar("Failed to update cardSystem"));
    }
  };

  const cancelBtn = () => {
    navigate("/cpanel/cardSystem/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>C-Panal</Typography>
        <Typography>Branch</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getBranch(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_cardSystem")} subheader={breadCrump()} />
          <CardContent>
            {/* {status && status.sent && (
              <Alert severity="success" my={3}>
               CardSystem updated successfully!
              </Alert>
            )} */}
            {/* {errorSubmit && errorSubmit.submit && (
              <Alert severity="error" my={3}>
                {errorSubmit.submit}
              </Alert>
            )} */}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={tabValue} onChange={handleTabChange} aria-label="Branch">
                    <Tab label="Card Info" {...a11yProps(0)} />
                    <Tab label="Package Info" {...a11yProps(1)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <CardInfo validationFields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <PackageInfo validationFields={validationSchema.fields} />
                  </TabPanel>
                </Box>

                <SaveCancel cancelBtn={cancelBtn} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

function EditCardSystem() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}

export default EditCardSystem;
