export const ReportConstants = {
  DOWNLOAD_USER_REQUEST: "DOWNLOAD_USER_REQUEST",
  DOWNLOAD_USER_SUCCESS: "DOWNLOAD_USER_SUCCESS",
  DOWNLOAD_USER_FAILURE: "DOWNLOAD_USER_FAILURE",
  FETCH_RENEWAL_REPORT_REQUEST: "FETCH_RENEWAL_REPORT_REQUEST",
  FETCH_RENEWAL_REPORT_SUCCESS: "FETCH_RENEWAL_REPORT_SUCCESS",
  FETCH_RENEWAL_REPORT_FAILURE: "FETCH_RENEWAL_REPORT_FAILURE",
  FETCH_DUE_REPORT_REQUEST: "FETCH_DUE_REPORT_REQUEST",
  FETCH_DUE_REPORT_SUCCESS: "FETCH_DUE_REPORT_SUCCESS",
  FETCH_DUE_REPORT_FAILURE: "FETCH_DUE_REPORT_FAILURE",
  FETCH_COLLECTION_REPORT_REQUEST: "FETCH_COLLECTION_REPORT_REQUEST",
  FETCH_COLLECTION_REPORT_SUCCESS: "FETCH_COLLECTION_REPORT_SUCCESS",
  FETCH_COLLECTION_REPORT_FAILURE: "FETCH_COLLECTION_REPORT_FAILURE",
  FETCH_WALLET_REQUEST: "FETCH_WALLET_REQUEST",
  FETCH_WALLET_SUCCESS: "FETCH_WALLET_SUCCESS",
  FETCH_WALLET_FAILURE: "FETCH_WALLET_FAILURE",
  FETCH_SHARING_REPORT_REQUEST: "FETCH_SHARING_REPORT_REQUEST",
  FETCH_SHARING_REPORT_SUCCESS: "FETCH_SHARING_REPORT_SUCCESS",
  FETCH_SHARING_REPORT_FAILURE: "FETCH_SHARING_REPORT_FAILURE",
};
