import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import DailyUserReducer from "../reducers/DailyUserReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { DailyUserConstants } from "../constants/dailyuser.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  dailyUsers: [],
  dashboardUsers: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  dailyUserSearchResults: [],
  dailyUser: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  dailyUserAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const DailyUserContext = createContext({
  ...initialState,
  getAllDailyUser: () => {},
  getAllDashboardUser: () => {},
  addDailyUser: () => {},
  searchDailyUser: () => {},
  autoSearchDailyUser: () => {},
  getDailyUser: () => {},
  updateDailyUser: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function DailyUserProvider({ children }) {
  const [state, dispatch] = useReducer(DailyUserReducer, initialState);

  const getAllDailyUser = async (payload) => {
    try {
      dispatch({
        type: DailyUserConstants.FETCH_DAILY_USER_REQUEST,
      });
      const response = await axios.post("/daily-user/list", payload);
      dispatch({
        type: DailyUserConstants.FETCH_DAILY_USER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: DailyUserConstants.FETCH_DAILY_USER_FAILURE,
      });
    }
  };

  const getAllDashboardUser = async (payload) => {
    try {
      console.log("i am calling");
      dispatch({
        type: DailyUserConstants.FETCH_DASHBOARD_USER_REQUEST,
      });
      const response = await axios.post("/daily-user/dashboard", payload);
      dispatch({
        type: DailyUserConstants.FETCH_DASHBOARD_USER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: DailyUserConstants.FETCH_DASHBOARD_USER_FAILURE,
      });
    }
  };

  const searchDailyUser = async (payload) => {
    try {
      const response = await axios.post("/daily-user/search", payload);
      dispatch({
        type: DailyUserConstants.DAILY_USER_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: DailyUserConstants.DAILY_USER_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: DailyUserConstants.DAILY_USER_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchDailyUser = async (payload) => {
    try {
      const response = await axios.post("/daily-user/auto-search", payload);
      dispatch({
        type: DailyUserConstants.DAILY_USER_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: DailyUserConstants.DAILY_USER_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: DailyUserConstants.DAILY_USER_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addDailyUser = async (payload) => {
    const response = await axios.post("/daily-user/add", payload);
    dispatch({
      type: DailyUserConstants.ADD_DAILY_USER_REQUEST,
    });
    dispatch({
      type: DailyUserConstants.ADD_DAILY_USER_SUCCESS,
      payload: response.data,
    });
  };

  const getDailyUser = async (id) => {
    const response = await axios.get("/daily-user/" + id);
    dispatch({
      type: DailyUserConstants.GET_DAILY_USER_REQUEST,
    });
    dispatch({
      type: DailyUserConstants.GET_DAILY_USER_SUCCESS,
      payload: response.data,
    });
  };

  const updateDailyUser = async (payload) => {
    const response = await axios.put("/daily-user/update/" + payload.dailyUserId, payload);
    dispatch({
      type: DailyUserConstants.UPDATE_DAILY_USER_REQUEST,
    });
    dispatch({
      type: DailyUserConstants.UPDATE_DAILY_USER_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: DailyUserConstants.DOWNLOAD_DAILY_USER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/daily-user/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "dailyUser." + fileType);
    dispatch({
      type: DailyUserConstants.DOWNLOAD_DAILY_USER_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/daily-user/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_dailyUser." + fileType);
    dispatch({
      type: DailyUserConstants.DOWNLOAD_DAILY_USER_REQUEST,
    });
    dispatch({
      type: DailyUserConstants.DOWNLOAD_DAILY_USER_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: DailyUserConstants.UPLOAD_DAILY_USER_REQUEST,
      });
      const response = await axios({
        url: "/daily-user/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: DailyUserConstants.UPLOAD_DAILY_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: DailyUserConstants.UPLOAD_DAILY_USER_FAILURE,
      });
    }
  };

  return (
    <DailyUserContext.Provider
      value={{
        ...state,
        getAllDailyUser,
        getAllDashboardUser,
        addDailyUser,
        searchDailyUser,
        autoSearchDailyUser,
        getDailyUser,
        updateDailyUser,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </DailyUserContext.Provider>
  );
}

export { DailyUserContext, DailyUserProvider };
