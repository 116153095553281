import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import DepartmentReducer from "../reducers/DepartmentReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { DepartmentConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  countries: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  departmentSearchResults: [],
  department: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const DepartmentContext = createContext({
  ...initialState,
  getAllDepartment: () => {},
  addDepartment: () => {},
  searchDepartment: () => {},
  getDepartment: () => {},
  updateDepartment: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function DepartmentProvider({ children }) {
  const [state, dispatch] = useReducer(DepartmentReducer, initialState);

  const getAllDepartment = async (payload) => {
    try {
      const response = await axios.post("/hyper/department/list", payload);
      dispatch({
        type: DepartmentConstants.FETCH_DEPARTMENT_REQUEST,
      });
      dispatch({
        type: DepartmentConstants.FETCH_DEPARTMENT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: DepartmentConstants.FETCH_DEPARTMENT_FAILURE,
      });
    }
  };
  const searchDepartment = async () => {
    try {
      const response = await axios.post("/hyper/department/search", initPayload);
      dispatch({
        type: DepartmentConstants.DEPARTMENT_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: DepartmentConstants.DEPARTMENT_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: DepartmentConstants.DEPARTMENT_SEARCH_FAILURE,
      });
    }
  };
  const addDepartment = async (payload) => {
    const response = await axios.post("/hyper/department/add", payload);
    dispatch({
      type: DepartmentConstants.ADD_DEPARTMENT_REQUEST,
    });
    dispatch({
      type: DepartmentConstants.ADD_DEPARTMENT_SUCCESS,
      payload: response.data,
    });
  };

  const getDepartment = async (id) => {
    const response = await axios.get("/hyper/department/" + id);
    dispatch({
      type: DepartmentConstants.GET_DEPARTMENT_REQUEST,
    });
    dispatch({
      type: DepartmentConstants.GET_DEPARTMENT_SUCCESS,
      payload: response.data,
    });
  };

  const updateDepartment = async (payload) => {
    const response = await axios.put("/hyper/department/update/" + payload.addOnId, payload);
    dispatch({
      type: DepartmentConstants.UPDATE_DEPARTMENT_REQUEST,
    });
    dispatch({
      type: DepartmentConstants.UPDATE_DEPARTMENT_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    const response = await axios.post("/hyper/department/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "department." + fileType);
    dispatch({
      type: DepartmentConstants.DOWNLOAD_DEPARTMENT_REQUEST,
    });
    dispatch({
      type: DepartmentConstants.DOWNLOAD_DEPARTMENT_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/hyper/department/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_department." + fileType);
    dispatch({
      type: DepartmentConstants.DOWNLOAD_DEPARTMENT_REQUEST,
    });
    dispatch({
      type: DepartmentConstants.DOWNLOAD_DEPARTMENT_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: DepartmentConstants.UPLOAD_DEPARTMENT_REQUEST,
      });
      const response = await axios({
        url: "/hyper/department/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: DepartmentConstants.UPLOAD_DEPARTMENT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: DepartmentConstants.UPLOAD_DEPARTMENT_FAILURE,
      });
    }
  };

  return (
    <DepartmentContext.Provider
      value={{
        ...state,
        getAllDepartment,
        addDepartment,
        searchDepartment,
        getDepartment,
        updateDepartment,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </DepartmentContext.Provider>
  );
}

export { DepartmentContext, DepartmentProvider };
