import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useTenant from "../../../../hooks/useTenant";
import usePlanGroup from "../../../../hooks/usePlanGroup";
import useAuth from "../../../../hooks/useAuth";

const currentMsoPayload = {
  filters: [
    {
      fieldName: "businessType",
      fieldValue: "MULTI_SERVICE_OPERATOR",
      op: "eq",
      dataType: "businessType",
      isJoin: false,
    },
  ],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const currentDSTPayload = {
  filters: [
    {
      fieldName: "businessType",
      fieldValue: "DISTRIBUTOR",
      op: "eq",
      dataType: "businessType",
      isJoin: false,
    },
  ],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const TenantDetail = (props) => {
  const [businessTypes, setBusinessTypes] = useState([
    {
      label: "LOCAL_CABLE_OPERATOR",
      value: "LCO - Local Cable Operator",
    },
    {
      label: "DISTRIBUTOR",
      value: "DST - Distributor",
    },
    {
      label: "MULTI_SERVICE_OPERATOR",
      value: "MSO - Multi Service Operator",
    },
    {
      label: "BULK_BANDWIDTH",
      value: "BBW - Bulk Bandwidth",
    },
    {
      label: "HOTEL",
      value: "Hotel",
    },
  ]);
  const { autoSearchPlanGroup, planGroupAutoSearchResults, isLoadingAutoSearch } = usePlanGroup();
  const {
    register,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useFormContext();

  const { user } = useAuth();

  const businessType = watch("businessType");

  const { fields } = props;

  const [msoPayload, setMsoPayload] = useState(currentMsoPayload);
  const [msoOptions, setMsoOptions] = useState([]);

  const [distPayload, setDistPayload] = useState(currentDSTPayload);
  const [distOptions, setDistOptions] = useState([]);
  const [planGroupOptions, setPlanGroupOptions] = useState([]);
  const [planGroupPayload, setPlanGroupPayload] = useState({
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  });

  const [isMsoVisible, setIsMsoVisible] = useState(true);

  useEffect(() => {
    if (user && user.businessType == "MULTI_SERVICE_OPERATOR") {
      setBusinessTypes([
        {
          value: "LOCAL_CABLE_OPERATOR",
          label: "LCO - Local Cable Operator",
        },
        {
          value: "DISTRIBUTOR",
          label: "DST - Distributor",
        },
        {
          value: "BULK_BANDWIDTH",
          label: "BBW - Bulk Bandwidth",
        },
        {
          value: "HOTEL",
          label: "Hotel",
        },
      ]);
      setValue("mso", {
        label: user.name,
        value: user.tenantId,
      });
      setIsMsoVisible(false);
    }
  }, [user]);

  const { autoSearchTenant } = useTenant();

  const [msoAutoSearchResults, setMsoAutoSearchResults] = useState({
    data: [],
  });
  const [distAutoSearchResults, setDistAutoSearchResults] = useState({
    data: [],
  });

  const fetchMsos = async () => {
    const msoResults = await autoSearchTenant(msoPayload);
    setMsoAutoSearchResults(msoResults);
  };

  const fetchDisitributors = async () => {
    const dstResults = await autoSearchTenant(distPayload);
    console.log(dstResults, "dstResults");
    setDistAutoSearchResults(dstResults);
  };
  const fetchPlanGroups = async () => {
    await autoSearchPlanGroup(planGroupPayload);
  };

  useEffect(() => {
    fetchMsos();
  }, [msoPayload]);

  useEffect(() => {
    fetchDisitributors();
  }, [businessType, distPayload]);

  useEffect(() => {
    fetchPlanGroups();
  }, [planGroupPayload]);

  useEffect(() => {
    console.log("msoOptions", msoAutoSearchResults);
    if (msoAutoSearchResults && msoAutoSearchResults.data.length !== 0) {
      const results = [...msoOptions, ...msoAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setMsoOptions(filteredResults);
    }
  }, [msoAutoSearchResults]);

  useEffect(() => {
    if (distAutoSearchResults && distAutoSearchResults.data.length !== 0) {
      const results = [...distOptions, ...distAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setDistOptions(filteredResults);
    }
  }, [distAutoSearchResults]);

  useEffect(() => {
    if (planGroupAutoSearchResults && planGroupAutoSearchResults.data.length !== 0) {
      const results = [...planGroupOptions, ...planGroupAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setPlanGroupOptions(filteredResults);
    }
  }, [planGroupAutoSearchResults]);

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item md={6}>
        <ReactHookFormSelect
          id="businessType"
          name="businessType"
          label="Business Type"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          {businessTypes &&
            businessTypes.map((businessTypeData) => {
              return <MenuItem value={businessTypeData.value}>{businessTypeData.label}</MenuItem>;
            })}
        </ReactHookFormSelect>
      </Grid>
      {businessType != "MULTI_SERVICE_OPERATOR" && isMsoVisible && (
        <Grid item md={6}>
          <SearchableAutoComplete
            control={control}
            data={msoOptions}
            label="Multi Service Operator"
            payload={msoPayload}
            setPayload={setMsoPayload}
            loading={false}
            name="mso"
            multiple={false}
            defaultValue={""}
            hasNextPage={false}
            fields={fields}
            errors={errors}
          />
        </Grid>
      )}
      {businessType == "LOCAL_CABLE_OPERATOR" && (
        <Grid item md={6}>
          <SearchableAutoComplete
            control={control}
            data={distOptions}
            label="Distributor"
            payload={distPayload}
            setPayload={setDistPayload}
            loading={false}
            name="distributor"
            multiple={false}
            defaultValue={""}
            hasNextPage={false}
            fields={fields}
            errors={errors}
          />
        </Grid>
      )}
      <Grid item md={6}>
        <CustomTextField
          label="Tenant Name"
          name="tenantName"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Official Mobile No"
          name="officialMobileNo"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Official Email Id"
          name="officialEmailId"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Gst No"
          name="gstNo"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>

      <Grid item md={6}>
        <ReactHookFormSelect
          id="sharingType"
          name="sharingType"
          label="Sharing Type"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          <MenuItem value={"POLICY_WISE"}>Policy Wise</MenuItem>
          <MenuItem value={"FIXED"}>Fixed</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      {businessType == "LOCAL_CABLE_OPERATOR" && (
        <Grid item md={6}>
          <SearchableAutoComplete
            control={control}
            data={planGroupOptions}
            label="Plan Group"
            payload={planGroupPayload}
            setPayload={setPlanGroupPayload}
            loading={false}
            name="planGroup"
            multiple={false}
            defaultValue={""}
            hasNextPage={false}
            fields={fields}
          />
        </Grid>
      )}
      <Grid item md={6}>
        <ReactHookFormSelect
          id="status"
          name="status"
          label="Status"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          <MenuItem value={"ACTIVE"}>Active</MenuItem>
          <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
};

export default TenantDetail;
