import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Box,
  CardContent,
  MenuItem,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import SaveCancel from "../../../components/SaveCancel";
import CustomTextField from "../../../components/CustomTextField";
import ReactHookFormSelect from "../../../components/ReactHookFormSelect";
import { useDialog } from "../../../contexts/DialogContext";
import useUser from "../../../hooks/useUser";
import SearchableAutoComplete from "../../../components/SearchableAutoComplete";
import useTenant from "../../../hooks/useTenant";
import useRate from "../../../hooks/useRate";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import useEmployee from "../../../hooks/useEmployee";
import CustomDatePicker from "../../../components/CustomDatePicker";
import useAgreementTracker from "../../../hooks/useAgreementTracker";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const IspAgreementReceived = (props) => {
  const { t } = useTranslation();
  const { unSetDialog } = useDialog();
  const { updateIspAgreementReceived, isSubmitting } = useAgreementTracker();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [employeePayload, setEmployeePayload] = useState(currentPayload);
  const { autoSearchEmployee, employeeAutoSearchResults } = useEmployee();

  const { getUser, user, isRenewSubmitting, renewUser } = useUser();
  const { autoSearchRate, rateAutoSearchResults, isLoadingAutoSearch: rateLoading } = useRate();

  const { fields, agreementTrackerId, fetchData } = props;

  const methods = useForm({});

  const { state } = useLocation();
  console.log(state, "location");

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;
  const [employeeOptions, setEmployeeOptions] = useState([]);

  const onSubmit = async (data) => {
    try {
      data["agreementTrackerId"] = agreementTrackerId;
      await updateIspAgreementReceived(data);
      dispatch(showSuccessSnackbar("Added successfully!"));
      unSetDialog("isp_agreement_received");
      fetchData();
    } catch (err) {
      console.log(err, "ddddddddddddd");
      dispatch(showErrorSnackbar("Failed to add "));
    }
  };

  const cancelBtn = () => {
    navigate("/leads/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <CustomDatePicker
                name="agreementReceivedDate"
                nameTemp="agreementReceivedDateStr"
                label="Agreement Received Date"
                range={false}
                control={control}
                fields={fields}
                timePicker={false}
                format="DD-MM-YYYY"
                conversionFormat="YYYY-MM-DD"
                minDate={new Date()}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Save
            </Button>

            <Button
              variant="text"
              onClick={() => unSetDialog("isp_agreement_received")}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default IspAgreementReceived;
