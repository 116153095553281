import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar, showErrorSnackbar } from "../../redux/slices/snackbar";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider } from "react-hook-form";

import SaveCancel from "../../components/SaveCancel";
import useComplaint from "../../hooks/useComplaint";
import ComplaintFields from "./components/ComplaintFields";
// import AddComplaintTypeFields from "./components/ComplaintTypeFields";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const initialValues = {
  userName: "",
  mobileNo: "",
  employee: "",
  complaintType: "",
  priority: "LOW",
  subject: "",
  comments: "",
};

const validationSchema = Yup.object().shape({
  userName: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  employee: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  complaintType: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  mobileNo: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/, "Must be a valid phone number with up to 10 digits"),
  priority: Yup.string().required("Required"),
  subject: Yup.string().required("Required"),
  comments: Yup.string().required("Required"),
});

function AddComplaint() {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { state } = useLocation();
  console.log(state, "location");

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const { addComplaint, hasError, errorMessage, isSubmitting } = useComplaint();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  React.useEffect(() => {
    console.log(state, "statestate");
    if (
      state != null &&
      Object.keys(state).length !== 0 &&
      state.userName != undefined &&
      state.radUserId != undefined
    ) {
      const initialValues = {
        userName: {
          label: state?.userName,
          value: state?.radUserId,
        },
      };
      reset(initialValues);
    }
  }, [state]);

  const onSubmit = async (data) => {
    try {
      data["radUser"] = {
        radUserId: data.userName.value,
        userName: data.userName.label,
      };
      if (data.employee !== "") {
        data["employee"] = {
          employeeId: data.employee.value,
          employeeName: data.employee.label,
        };
      }
      data["complaintType"] = {
        complaintTypeId: data.complaintType.value,
        complaintTypeName: data.complaintType.label,
      };

      console.log(data, "complaint data fff");

      await addComplaint(data);
      dispatch(showSuccessSnackbar("Complaint added successfully!"));
      reset();
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to add complaint "));
    }
  };

  const cancelBtn = () => {
    if (state != null && Object.keys(state).length != 0) {
      navigate(state?.backTo);
    } else {
      navigate("/complaints/list");
    }
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Complaint</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_complaint")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <ComplaintFields fields={validationSchema.fields} />

                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddComplaint;
