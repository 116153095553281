import { useState, useEffect } from "react";

export default function useMainContentDimensions() {
  const [mainContentDimensions, setMainContentDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    let element = document.getElementById("main-content");
    let width = element.offsetWidth;
    setMainContentDimensions({
      width: width,
      height: 0,
    });
  }, []);

  return mainContentDimensions;
}
