import React, { useEffect, useState } from "react";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

import { Card, CardHeader, CardContent } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useWindowDimensions from "../../../utils/windowDimensions";
import useBalanceStatement from "../../../hooks/useBalanceStatement";
import useAuth from "../../../hooks/useAuth";

function BalanceSheet() {
  const { user } = useAuth();

  const { width: mainContentWidth } = useMainContentDimensions();

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
    sortFields: [
      {
        field: "createdAt",
        sort: "DESC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { getBalanceReport, balanceReports, paging } = useBalanceStatement();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    if (Object.keys(payload).length != 0) {
      await getBalanceReport(payload);
    }
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: currentPayload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "tenantName",
      headerName: "Tenant Name",
      width: 200,
    },

    {
      field: "openingBalance",
      headerName: "Opening Bal",
      width: 150,
    },
    {
      field: "closingBalance",
      headerName: "Closing Bal",
      width: 150,
    },
  ];

  return (
    <Card sx={{ width: mainContentWidth - 600 }}>
      <CardHeader title="Balance Statement" />
      <CardContent sx={{ height: height - 200 }}>
        <DataGrid
          columns={columns}
          rows={balanceReports}
          refreshPage={fetchData}
          getRowId={(row) => row["tenantId"]}
          rowCount={paging.totalNumberOfRecords}
          loading={false}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </CardContent>
    </Card>
  );
}

export default BalanceSheet;
