export const CancelledInvoiceConstants = {
  FETCH_CANCELLEDINVOICE_REQUEST: "FETCH_CANCELLEDINVOICE_REQUEST",
  FETCH_CANCELLEDINVOICE_SUCCESS: "FETCH_CANCELLEDINVOICE_SUCCESS",
  FETCH_CANCELLEDINVOICE_FAILURE: "FETCH_CANCELLEDINVOICE_FAILURE",
  ADD_CANCELLEDINVOICE_REQUEST: "ADD_CANCELLEDINVOICE_REQUEST",
  ADD_CANCELLEDINVOICE_SUCCESS: "ADD_CANCELLEDINVOICE_SUCCESS",
  ADD_CANCELLEDINVOICE_FAILURE: "ADD_CANCELLEDINVOICE_FAILURE",
  CANCELLEDINVOICE_SEARCH_REQUEST: "CANCELLEDINVOICE_SEARCH_REQUEST",
  CANCELLEDINVOICE_SEARCH_SUCCESS: "CANCELLEDINVOICE_SEARCH_SUCCESS",
  CANCELLEDINVOICE_SEARCH_FAILURE: "CANCELLEDINVOICE_SEARCH_FAILURE",
  CANCELLEDINVOICE_AUTO_SEARCH_REQUEST: "CANCELLEDINVOICE_AUTO_SEARCH_REQUEST",
  CANCELLEDINVOICE_AUTO_SEARCH_SUCCESS: "CANCELLEDINVOICE_AUTO_SEARCH_SUCCESS",
  CANCELLEDINVOICE_AUTO_SEARCH_FAILURE: "CANCELLEDINVOICE_AUTO_SEARCH_FAILURE",
  GET_CANCELLEDINVOICE_REQUEST: "GET_CANCELLEDINVOICE_REQUEST",
  GET_CANCELLEDINVOICE_SUCCESS: "GET_CANCELLEDINVOICE_SUCCESS",
  GET_CANCELLEDINVOICE_FAILURE: "GET_CANCELLEDINVOICE_FAILURE",
  UPDATE_CANCELLEDINVOICE_REQUEST: "UPDATE_CANCELLEDINVOICE_REQUEST",
  UPDATE_CANCELLEDINVOICE_SUCCESS: "UPDATE_CANCELLEDINVOICE_SUCCESS",
  UPDATE_CANCELLEDINVOICE_FAILURE: "UPDATE_CANCELLEDINVOICE_FAILURE",
  DOWNLOAD_CANCELLEDINVOICE_REQUEST: "DOWNLOAD_CANCELLEDINVOICE_REQUEST",
  DOWNLOAD_CANCELLEDINVOICE_SUCCESS: "DOWNLOAD_CANCELLEDINVOICE_SUCCESS",
  DOWNLOAD_CANCELLEDINVOICE_FAILURE: "DOWNLOAD_CANCELLEDINVOICE_FAILURE",
  UPLOAD_CANCELLEDINVOICE_REQUEST: "UPLOAD_CANCELLEDINVOICE_REQUEST",
  UPLOAD_CANCELLEDINVOICE_SUCCESS: "UPLOAD_CANCELLEDINVOICE_SUCCESS",
  UPLOAD_CANCELLEDINVOICE_FAILURE: "UPLOAD_CANCELLEDINVOICE_FAILURE",
};
