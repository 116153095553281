import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Alert as MuiAlert, Grid, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebounce } from "react-use";

import { Formik } from "formik";
import { useDialog } from "../../../../contexts/DialogContext";
import useTaluk from "../../../../hooks/useTaluk";
import useCountry from "../../../../hooks/useCountry";
import useDistrict from "../../../../hooks/useDistrict";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import { searchPayload } from "../../../../utils/common";
import useStateMaster from "../../../../hooks/useStateMaster";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const Alert = styled(MuiAlert)(spacing);

const TalukFields = React.memo((props) => {
  const { fields } = props;

  console.log("rendered");

  const searchPayloadValue = searchPayload();
  const { autoSearchCountry, countryAutoSearchResults, isLoadingAutoSearch } = useCountry();
  const {
    autoSearchState,
    stateAutoSearchResults,
    isLoadingAutoSearch: isLoadingStateAutoSearch,
  } = useStateMaster();
  const {
    autoSearchDistrict,
    districtAutoSearchResults,
    isLoadingAutoSearch: isLoadingDistrictAutoSearch,
  } = useDistrict();

  const [payload, setPayload] = useState(currentPayload);
  const [statePayload, setStatePayload] = useState(currentPayload);
  const [districtPayload, setDistrictPayload] = useState(currentPayload);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [stateValue, setStateValue] = useState("");

  useEffect(() => {
    autoSearchCountry(payload);
  }, [payload]);

  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();

  const country = watch("country");
  const stateMaster = watch("state");
  const district = watch("district");

  const fetchStates = useCallback((param, payloadParams) => {
    console.log(param, "paramparamparam");
    if (param) {
      const payloadValues = {
        ...payloadParams,
        filters: [
          {
            fieldName: "countryId",
            fieldValue: param.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      };
      autoSearchState(payloadValues);
    }
  }, []);

  const fetchDistrict = useCallback((param, payloadParams) => {
    if (param) {
      const payloadValues = {
        ...payloadParams,
        filters: [
          {
            fieldName: "stateId",
            fieldValue: param.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      };
      autoSearchDistrict(payloadValues);
    }
  }, []);

  useDebounce(
    () => {
      if (country) {
        setCountryValue(country);
        setStateOptions([]);
      }
    },
    1000,
    [country],
  );

  useDebounce(
    () => {
      if (stateMaster) {
        setStateValue(stateMaster);
      }
    },
    1000,
    [stateMaster],
  );

  useEffect(() => {
    fetchStates(countryValue, statePayload);
  }, [fetchStates, countryValue, statePayload]);

  useEffect(() => {
    fetchDistrict(stateValue, districtPayload);
  }, [fetchDistrict, stateValue, districtPayload]);

  useEffect(() => {
    if (countryAutoSearchResults.data.length !== 0) {
      setCountryOptions([...countryOptions, ...countryAutoSearchResults.data]);
    }
  }, [countryAutoSearchResults]);

  useEffect(() => {
    if (stateAutoSearchResults.data.length !== 0) {
      setStateOptions([...stateOptions, ...stateAutoSearchResults.data]);
    }
  }, [stateAutoSearchResults]);

  useEffect(() => {
    if (districtAutoSearchResults.data.length !== 0) {
      setDistrictOptions([...districtOptions, ...districtAutoSearchResults.data]);
    }
  }, [districtAutoSearchResults]);

  return (
    <Grid container spacing={6} className="row">
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={countryOptions}
          label="Country"
          payload={payload}
          setPayload={setPayload}
          loading={isLoadingAutoSearch}
          fields={fields}
          name="country"
          multiple={false}
          defaultValue={country}
          hasNextPage={countryAutoSearchResults.paging.hasNextPage}
        />
      </Grid>
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={stateOptions}
          label="State"
          payload={statePayload}
          setPayload={setStatePayload}
          loading={isLoadingStateAutoSearch}
          name="state"
          fields={fields}
          multiple={false}
          defaultValue={stateMaster}
          hasNextPage={stateAutoSearchResults.paging.hasNextPage}
        />
      </Grid>
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={districtOptions}
          label="District"
          payload={districtPayload}
          setPayload={setDistrictPayload}
          loading={isLoadingDistrictAutoSearch}
          name="district"
          fields={fields}
          multiple={false}
          defaultValue={district}
          hasNextPage={districtAutoSearchResults.paging.hasNextPage}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("taluk_name")}
          name="talukName"
          control={control}
          fullWidth
          fields={fields}
          variant="outlined"
          errors={errors}
        />
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          name="status"
          label={t("Status")}
          control={control}
          fields={fields}
          fullWidth
          variant="outlined"
          errors={errors}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
});
export default TalukFields;
