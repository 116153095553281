export const PppProfileConstants = {
  FETCH_PPP_PROFILE_REQUEST: "FETCH_PPP_PROFILE_REQUEST",
  FETCH_PPP_PROFILE_SUCCESS: "FETCH_PPP_PROFILE_SUCCESS",
  FETCH_PPP_PROFILE_FAILURE: "FETCH_PPP_PROFILE_FAILURE",
  ADD_PPP_PROFILE_REQUEST: "ADD_PPP_PROFILE_REQUEST",
  ADD_PPP_PROFILE_SUCCESS: "ADD_PPP_PROFILE_SUCCESS",
  ADD_PPP_PROFILE_FAILURE: "ADD_PPP_PROFILE_FAILURE",
  PPP_PROFILE_SEARCH_REQUEST: "PPP_PROFILE_SEARCH_REQUEST",
  PPP_PROFILE_SEARCH_SUCCESS: "PPP_PROFILE_SEARCH_SUCCESS",
  PPP_PROFILE_SEARCH_FAILURE: "PPP_PROFILE_SEARCH_FAILURE",
  PPP_PROFILE_AUTO_SEARCH_REQUEST: "PPP_PROFILE_AUTO_SEARCH_REQUEST",
  PPP_PROFILE_AUTO_SEARCH_SUCCESS: "PPP_PROFILE_AUTO_SEARCH_SUCCESS",
  PPP_PROFILE_AUTO_SEARCH_FAILURE: "PPP_PROFILE_AUTO_SEARCH_FAILURE",
  GET_PPP_PROFILE_REQUEST: "GET_PPP_PROFILE_REQUEST",
  GET_PPP_PROFILE_SUCCESS: "GET_PPP_PROFILE_SUCCESS",
  GET_PPP_PROFILE_FAILURE: "GET_PPP_PROFILE_FAILURE",
  UPDATE_PPP_PROFILE_REQUEST: "UPDATE_PPP_PROFILE_REQUEST",
  UPDATE_PPP_PROFILE_SUCCESS: "UPDATE_PPP_PROFILE_SUCCESS",
  UPDATE_PPP_PROFILE_FAILURE: "UPDATE_PPP_PROFILE_FAILURE",
  DOWNLOAD_PPP_PROFILE_REQUEST: "DOWNLOAD_PPP_PROFILE_REQUEST",
  DOWNLOAD_PPP_PROFILE_SUCCESS: "DOWNLOAD_PPP_PROFILE_SUCCESS",
  DOWNLOAD_PPP_PROFILE_FAILURE: "DOWNLOAD_PPP_PROFILE_FAILURE",
  UPLOAD_PPP_PROFILE_REQUEST: "UPLOAD_PPP_PROFILE_REQUEST",
  UPLOAD_PPP_PROFILE_SUCCESS: "UPLOAD_PPP_PROFILE_SUCCESS",
  UPLOAD_PPP_PROFILE_FAILURE: "UPLOAD_PPP_PROFILE_FAILURE",
};
