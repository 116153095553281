import { useContext } from "react";

import { MkIpPoolContext } from "../contexts/MkIpPoolContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useMkIpPool = () => {
  const context = useContext(MkIpPoolContext);

  if (!context) throw new Error("MkIpPoolContext must be placed within MkIpPoolProvider");

  return context;
};

export default useMkIpPool;
