export const MaterialConstants = {
  FETCH_MATERIAL_REQUEST: "FETCH_MATERIAL_REQUEST",
  FETCH_MATERIAL_SUCCESS: "FETCH_MATERIAL_SUCCESS",
  FETCH_MATERIAL_FAILURE: "FETCH_MATERIAL_FAILURE",
  ADD_MATERIAL_REQUEST: "ADD_MATERIAL_REQUEST",
  ADD_MATERIAL_SUCCESS: "ADD_MATERIAL_SUCCESS",
  ADD_MATERIAL_FAILURE: "ADD_MATERIAL_FAILURE",
  MATERIAL_SEARCH_REQUEST: "MATERIAL_SEARCH_REQUEST",
  MATERIAL_SEARCH_SUCCESS: "MATERIAL_SEARCH_SUCCESS",
  MATERIAL_SEARCH_FAILURE: "MATERIAL_SEARCH_FAILURE",
  MATERIAL_AUTO_SEARCH_REQUEST: "MATERIAL_AUTO_SEARCH_REQUEST",
  MATERIAL_AUTO_SEARCH_SUCCESS: "MATERIAL_AUTO_SEARCH_SUCCESS",
  MATERIAL_AUTO_SEARCH_FAILURE: "MATERIAL_AUTO_SEARCH_FAILURE",
  GET_MATERIAL_REQUEST: "GET_MATERIAL_REQUEST",
  GET_MATERIAL_SUCCESS: "GET_MATERIAL_SUCCESS",
  GET_MATERIAL_FAILURE: "GET_MATERIAL_FAILURE",
  UPDATE_MATERIAL_REQUEST: "UPDATE_MATERIAL_REQUEST",
  UPDATE_MATERIAL_SUCCESS: "UPDATE_MATERIAL_SUCCESS",
  UPDATE_MATERIAL_FAILURE: "UPDATE_MATERIAL_FAILURE",
  DOWNLOAD_MATERIAL_REQUEST: "DOWNLOAD_MATERIAL_REQUEST",
  DOWNLOAD_MATERIAL_SUCCESS: "DOWNLOAD_MATERIAL_SUCCESS",
  DOWNLOAD_MATERIAL_FAILURE: "DOWNLOAD_MATERIAL_FAILURE",
  UPLOAD_MATERIAL_REQUEST: "UPLOAD_MATERIAL_REQUEST",
  UPLOAD_MATERIAL_SUCCESS: "UPLOAD_MATERIAL_SUCCESS",
  UPLOAD_MATERIAL_FAILURE: "UPLOAD_MATERIAL_FAILURE",
};
