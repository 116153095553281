import { MigrationConstants } from "../constants/migration.constants";

const MigrationReducer = (state, action) => {
  switch (action.type) {
    case MigrationConstants.UPLOAD_USER_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case MigrationConstants.UPLOAD_USER_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case MigrationConstants.UPLOAD_USER_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default MigrationReducer;
