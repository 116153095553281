import { PincodeConstants } from "../constants/pincode.constants";

const PincodeReducer = (state, action) => {
  console.log("1212121212121212", state);
  switch (action.type) {
    case PincodeConstants.FETCH_PINCODE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PincodeConstants.FETCH_PINCODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        states: action.payload.data,
        paging: action.payload.paging,
      };
    case PincodeConstants.FETCH_PINCODE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PincodeConstants.ADD_PINCODE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PincodeConstants.ADD_PINCODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PincodeConstants.ADD_PINCODE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };
    case PincodeConstants.EDIT_PINCODE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PincodeConstants.EDIT_PINCODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PincodeConstants.EDIT_PINCODE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };
    case PincodeConstants.GET_PINCODE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PincodeConstants.GET_PINCODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pinCode: action.payload,
      };
    case PincodeConstants.GET_PINCODE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case PincodeConstants.PINCODE_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoadingAutoSearch: true,
        hasError: false,
      };
    case PincodeConstants.PINCODE_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoadingAutoSearch: false,
        pincodeAutoSearchResults: action.payload,
      };
    case PincodeConstants.PINCODE_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingAutoSearch: false,
      };

    case PincodeConstants.UPDATE_PINCODE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PincodeConstants.UPDATE_PINCODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pinCode: action.payload,
      };
    case PincodeConstants.UPDATE_PINCODE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case PincodeConstants.DOWNLOAD_PINCODE_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case PincodeConstants.DOWNLOAD_PINCODE_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PincodeConstants.DOWNLOAD_PINCODE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PincodeConstants.PINCODE_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PincodeConstants.PINCODE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pincodeSearchResults: action.payload,
      };
    case PincodeConstants.PINCODE_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case PincodeConstants.UPLOAD_PINCODE_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case PincodeConstants.UPLOAD_PINCODE_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case PincodeConstants.UPLOAD_PINCODE_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default PincodeReducer;
