import { BalanceStatementConstants } from "../constants/balancestatement.constants";

const BalanceStatementReducer = (state, action) => {
  switch (action.type) {
    case BalanceStatementConstants.FETCH_BALANCE_STATEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case BalanceStatementConstants.FETCH_BALANCE_STATEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        balanceStatements: action.payload.data,
        paging: action.payload.paging,
      };
    case BalanceStatementConstants.FETCH_BALANCE_STATEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case BalanceStatementConstants.FETCH_BALANCE_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case BalanceStatementConstants.FETCH_BALANCE_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        balanceReports: action.payload.data,
        paging: action.payload.paging,
      };
    case BalanceStatementConstants.FETCH_BALANCE_REPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case BalanceStatementConstants.BALANCE_STATEMENT_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case BalanceStatementConstants.BALANCE_STATEMENT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        balanceStatementSearchResults: action.payload,
      };
    case BalanceStatementConstants.BALANCE_STATEMENT_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case BalanceStatementConstants.BALANCE_STATEMENT_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case BalanceStatementConstants.BALANCE_STATEMENT_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        balanceStatementAutoSearchResults: action.payload,
      };
    case BalanceStatementConstants.BALANCE_STATEMENT_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case BalanceStatementConstants.ADD_BALANCE_STATEMENT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case BalanceStatementConstants.ADD_BALANCE_STATEMENT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case BalanceStatementConstants.ADD_BALANCE_STATEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case BalanceStatementConstants.GET_BALANCE_STATEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case BalanceStatementConstants.GET_BALANCE_STATEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        balanceStatement: action.payload,
      };
    case BalanceStatementConstants.GET_BALANCE_STATEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case BalanceStatementConstants.UPDATE_BALANCE_STATEMENT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case BalanceStatementConstants.UPDATE_BALANCE_STATEMENT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        balanceStatement: action.payload,
      };
    case BalanceStatementConstants.UPDATE_BALANCE_STATEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case BalanceStatementConstants.DOWNLOAD_BALANCE_STATEMENT_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case BalanceStatementConstants.DOWNLOAD_BALANCE_STATEMENT_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case BalanceStatementConstants.DOWNLOAD_BALANCE_STATEMENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case BalanceStatementConstants.UPLOAD_BALANCE_STATEMENT_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case BalanceStatementConstants.UPLOAD_BALANCE_STATEMENT_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case BalanceStatementConstants.UPLOAD_BALANCE_STATEMENT_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default BalanceStatementReducer;
