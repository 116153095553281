import { useContext } from "react";

import { ComplaintTypeContext } from "../contexts/ComplaintTypeContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useComplaintType = () => {
  const context = useContext(ComplaintTypeContext);

  if (!context) throw new Error("ComplaintTypeContext must be placed within ComplaintProvider");

  return context;
};

export default useComplaintType;
