import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import BasicInformation from "./components/BasicInformation";
// import ComplaintTableOne from "./components/ComplaintTableOne";
import ComplaintHistory from "./components/ComplaintHistory";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";

// import SaveCancel from "../../../components/SaveCancel";
import TabPanel from "../../components/TabPanel";
import { a11yProps } from "../../utils/common";

// import AddressDetail from "../../../components/AddressDetail";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

function AddUser() {
  const { state } = useLocation();
  const { t } = useTranslation();
  console.log(state, "statestatestate");

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Complaints</Typography>

        <Typography>Complaint Details</Typography>
      </Breadcrumbs>
    );
  };

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardHeader title={t("complaint_details")} subheader={breadCrump()} />
        <CardContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="Company">
              <Tab label="Complaint Information" {...a11yProps(0)} />

              <Tab label="Complaint History " {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicInformation complaintId={state?.id} />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <ComplaintHistory complaintId={state?.id} />
            </TabPanel>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default AddUser;
