const FormReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_FORM":
      return {
        ...state,
        [payload.id]: payload.data,
      };
    case "UPDATE_FORM":
      return {
        ...state,
        [payload.id]: {
          ...payload.data,
        },
      };
    case "UPDATE_STATE_FORM":
      console.log(state, payload, "UPDATE_STATE_FORM");
      return {
        ...state,
        [payload.id]: {
          ["values"]: {
            ...state[payload.id]["values"],
            [payload.data.name]: payload.data.value,
          },
          ["errors"]: {
            ...state[payload.id]["errors"],
          },
        },
      };
    case "UPDATE_VALUES":
      return {
        ...state,
        [payload.id]: {
          values: {
            ...payload.data,
          },
        },
      };
    case "UPDATE_ERRORS":
      return {
        ...state,
        [payload.id]: {
          errors: {
            ...payload.data,
          },
        },
      };
    default:
      return state;
  }
};

export default FormReducer;
