import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import ProformaInvoiceReducer from "../reducers/ProformaInvoiceReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { ProformaInvoiceConstants } from "../constants/proformainvoice.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  proformaInvoices: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  proformaInvoiceSearchResults: [],
  proformaInvoice: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  proformaInvoiceAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const ProformaInvoiceContext = createContext({
  ...initialState,
  getAllProformaInvoice: () => {},
  addProformaInvoice: () => {},
  searchProformaInvoice: () => {},
  autoSearchProformaInvoice: () => {},
  getProformaInvoice: () => {},
  updateProformaInvoice: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function ProformaInvoiceProvider({ children }) {
  const [state, dispatch] = useReducer(ProformaInvoiceReducer, initialState);

  const getAllProformaInvoice = async (payload) => {
    try {
      dispatch({
        type: ProformaInvoiceConstants.FETCH_PROFORMA_INVOICE_REQUEST,
      });
      const response = await axios.post("/radius/raduser/proforma-invoices", payload);
      dispatch({
        type: ProformaInvoiceConstants.FETCH_PROFORMA_INVOICE_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: ProformaInvoiceConstants.FETCH_PROFORMA_INVOICE_FAILURE,
      });
    }
  };
  const searchProformaInvoice = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/proforma-invoices/search", payload);
      dispatch({
        type: ProformaInvoiceConstants.PROFORMA_INVOICE_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: ProformaInvoiceConstants.PROFORMA_INVOICE_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: ProformaInvoiceConstants.PROFORMA_INVOICE_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchProformaInvoice = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/proforma-invoices/auto-search", payload);
      dispatch({
        type: ProformaInvoiceConstants.PROFORMA_INVOICE_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: ProformaInvoiceConstants.PROFORMA_INVOICE_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: ProformaInvoiceConstants.PROFORMA_INVOICE_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addProformaInvoice = async (payload) => {
    const response = await axios.post("/radius/raduser/proforma-invoices/add", payload);
    dispatch({
      type: ProformaInvoiceConstants.ADD_PROFORMA_INVOICE_REQUEST,
    });
    dispatch({
      type: ProformaInvoiceConstants.ADD_PROFORMA_INVOICE_SUCCESS,
      payload: response.data,
    });
  };

  const getProformaInvoice = async (id) => {
    const response = await axios.get("/radius/raduser/proforma-invoices/" + id);
    dispatch({
      type: ProformaInvoiceConstants.GET_PROFORMA_INVOICE_REQUEST,
    });
    dispatch({
      type: ProformaInvoiceConstants.GET_PROFORMA_INVOICE_SUCCESS,
      payload: response.data,
    });
  };

  const updateProformaInvoice = async (payload) => {
    const response = await axios.put(
      "/radius/raduser/proforma-invoices/update/" + payload.proformaInvoiceId,
      payload,
    );
    dispatch({
      type: ProformaInvoiceConstants.UPDATE_PROFORMA_INVOICE_REQUEST,
    });
    dispatch({
      type: ProformaInvoiceConstants.UPDATE_PROFORMA_INVOICE_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: ProformaInvoiceConstants.DOWNLOAD_PROFORMA_INVOICE_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post(
      "/radius/raduser/proforma-invoices/export/" + fileType,
      payload,
      {
        responseType: "blob",
      },
    );
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "proformaInvoice." + fileType);
    dispatch({
      type: ProformaInvoiceConstants.DOWNLOAD_PROFORMA_INVOICE_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/radius/raduser/proforma-invoices/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_proformaInvoice." + fileType);
    dispatch({
      type: ProformaInvoiceConstants.DOWNLOAD_PROFORMA_INVOICE_REQUEST,
    });
    dispatch({
      type: ProformaInvoiceConstants.DOWNLOAD_PROFORMA_INVOICE_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: ProformaInvoiceConstants.UPLOAD_PROFORMA_INVOICE_REQUEST,
      });
      const response = await axios({
        url: "/radius/raduser/proforma-invoices/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: ProformaInvoiceConstants.UPLOAD_PROFORMA_INVOICE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: ProformaInvoiceConstants.UPLOAD_PROFORMA_INVOICE_FAILURE,
      });
    }
  };

  return (
    <ProformaInvoiceContext.Provider
      value={{
        ...state,
        getAllProformaInvoice,
        addProformaInvoice,
        searchProformaInvoice,
        autoSearchProformaInvoice,
        getProformaInvoice,
        updateProformaInvoice,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </ProformaInvoiceContext.Provider>
  );
}

export { ProformaInvoiceContext, ProformaInvoiceProvider };
