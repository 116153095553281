import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import RadUserWalletReducer from "../reducers/RadUserWalletReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { RadUserWalletConstants } from "../constants/raduserwallet.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  radUserWallets: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  radUserWalletSearchResults: [],
  radUserWallet: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  radUserWalletAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const RadUserWalletContext = createContext({
  ...initialState,
  getAllRadUserWallet: () => {},
  addRadUserWallet: () => {},
  searchRadUserWallet: () => {},
  autoSearchRadUserWallet: () => {},
  getRadUserWallet: () => {},
  updateRadUserWallet: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function RadUserWalletProvider({ children }) {
  const [state, dispatch] = useReducer(RadUserWalletReducer, initialState);

  const getAllRadUserWallet = async (payload) => {
    try {
      dispatch({
        type: RadUserWalletConstants.FETCH_RAD_USER_WALLET_REQUEST,
      });
      const response = await axios.post("/raduser-wallet/list", payload);
      dispatch({
        type: RadUserWalletConstants.FETCH_RAD_USER_WALLET_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: RadUserWalletConstants.FETCH_RAD_USER_WALLET_FAILURE,
      });
    }
  };
  const searchRadUserWallet = async (payload) => {
    try {
      const response = await axios.post("/raduser-wallet/search", payload);
      dispatch({
        type: RadUserWalletConstants.RAD_USER_WALLET_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: RadUserWalletConstants.RAD_USER_WALLET_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: RadUserWalletConstants.RAD_USER_WALLET_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchRadUserWallet = async (payload) => {
    try {
      const response = await axios.post("/raduser-wallet/auto-search", payload);
      dispatch({
        type: RadUserWalletConstants.RAD_USER_WALLET_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: RadUserWalletConstants.RAD_USER_WALLET_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: RadUserWalletConstants.RAD_USER_WALLET_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addRadUserWallet = async (payload) => {
    const response = await axios.post("/raduser-wallet/add", payload);
    dispatch({
      type: RadUserWalletConstants.ADD_RAD_USER_WALLET_REQUEST,
    });
    dispatch({
      type: RadUserWalletConstants.ADD_RAD_USER_WALLET_SUCCESS,
      payload: response.data,
    });
  };

  const getRadUserWallet = async (id) => {
    const response = await axios.get("/raduser-wallet/" + id);
    dispatch({
      type: RadUserWalletConstants.GET_RAD_USER_WALLET_REQUEST,
    });
    dispatch({
      type: RadUserWalletConstants.GET_RAD_USER_WALLET_SUCCESS,
      payload: response.data,
    });
  };

  const updateRadUserWallet = async (payload) => {
    const response = await axios.put("/raduser-wallet/update/" + payload.radUserWalletId, payload);
    dispatch({
      type: RadUserWalletConstants.UPDATE_RAD_USER_WALLET_REQUEST,
    });
    dispatch({
      type: RadUserWalletConstants.UPDATE_RAD_USER_WALLET_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: RadUserWalletConstants.DOWNLOAD_RAD_USER_WALLET_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/raduser-wallet/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "raduser-wallet." + fileType);
    dispatch({
      type: RadUserWalletConstants.DOWNLOAD_RAD_USER_WALLET_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/raduser-wallet/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_raduser-wallet." + fileType);
    dispatch({
      type: RadUserWalletConstants.DOWNLOAD_RAD_USER_WALLET_REQUEST,
    });
    dispatch({
      type: RadUserWalletConstants.DOWNLOAD_RAD_USER_WALLET_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: RadUserWalletConstants.UPLOAD_RAD_USER_WALLET_REQUEST,
      });
      const response = await axios({
        url: "/raduser-wallet/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: RadUserWalletConstants.UPLOAD_RAD_USER_WALLET_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: RadUserWalletConstants.UPLOAD_RAD_USER_WALLET_FAILURE,
      });
    }
  };

  return (
    <RadUserWalletContext.Provider
      value={{
        ...state,
        getAllRadUserWallet,
        addRadUserWallet,
        searchRadUserWallet,
        autoSearchRadUserWallet,
        getRadUserWallet,
        updateRadUserWallet,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </RadUserWalletContext.Provider>
  );
}

export { RadUserWalletContext, RadUserWalletProvider };
