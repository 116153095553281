import { SmsToUserConstants } from "../constants/smstouser.constants";

const SmsToUsersReducer = (state, action) => {
  switch (action.type) {
    case SmsToUserConstants.FETCH_SMSTOUSER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsToUserConstants.FETCH_SMSTOUSER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsToUsers: action.payload.data,
        paging: action.payload.paging,
      };
    case SmsToUserConstants.FETCH_SMSTOUSER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsToUserConstants.SMSTOUSER_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsToUserConstants.SMSTOUSER_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsToUserSearchResults: action.payload,
      };
    case SmsToUserConstants.SMSTOUSER_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsToUserConstants.SMSTOUSER_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsToUserConstants.SMSTOUSER_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsToUserAutoSearchResults: action.payload,
      };
    case SmsToUserConstants.SMSTOUSER_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsToUserConstants.GET_SMSTOUSER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsToUserConstants.GET_SMSTOUSER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsToUser: action.payload,
      };
    case SmsToUserConstants.GET_SMSTOUSER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsToUserConstants.DOWNLOAD_SMSTOUSER_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case SmsToUserConstants.DOWNLOAD_SMSTOUSER_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case SmsToUserConstants.DOWNLOAD_SMSTOUSER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };

    default:
      return state;
  }
};

export default SmsToUsersReducer;
