import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import EmployeeReducer from "../reducers/EmployeeReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { EmployeeConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  employees: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  employeeSearchResults: [],
  employee: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  employeeAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: false,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const EmployeeContext = createContext({
  ...initialState,
  getAllEmployee: () => {},
  addEmployee: () => {},
  searchEmployee: () => {},
  autoSearchEmployee: () => {},
  getEmployee: () => {},
  updateEmployee: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function EmployeeProvider({ children }) {
  const [state, dispatch] = useReducer(EmployeeReducer, initialState);

  const getAllEmployee = async (payload) => {
    try {
      dispatch({
        type: EmployeeConstants.FETCH_EMPLOYEE_REQUEST,
      });
      const response = await axios.post("/cpanel/employee/list", payload);
      dispatch({
        type: EmployeeConstants.FETCH_EMPLOYEE_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: EmployeeConstants.FETCH_EMPLOYEE_FAILURE,
      });
    }
  };
  const searchEmployee = async (payload) => {
    try {
      const response = await axios.post("/cpanel/employee/search", payload);
      dispatch({
        type: EmployeeConstants.EMPLOYEE_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: EmployeeConstants.EMPLOYEE_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: EmployeeConstants.EMPLOYEE_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchEmployee = async (payload) => {
    try {
      const response = await axios.post("/cpanel/employee/auto-search", payload);
      dispatch({
        type: EmployeeConstants.EMPLOYEE_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: EmployeeConstants.EMPLOYEE_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: EmployeeConstants.EMPLOYEE_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addEmployee = async (payload) => {
    const response = await axios.post("/cpanel/employee/add", payload);
    dispatch({
      type: EmployeeConstants.ADD_EMPLOYEE_REQUEST,
    });
    dispatch({
      type: EmployeeConstants.ADD_EMPLOYEE_SUCCESS,
      payload: response.data,
    });
  };

  const getEmployee = async (id) => {
    const response = await axios.get("/cpanel/employee/" + id);
    dispatch({
      type: EmployeeConstants.GET_EMPLOYEE_REQUEST,
    });
    dispatch({
      type: EmployeeConstants.GET_EMPLOYEE_SUCCESS,
      payload: response.data,
    });
  };

  const updateEmployee = async (payload) => {
    const response = await axios.put("/cpanel/employee/update/" + payload.employeeId, payload);
    dispatch({
      type: EmployeeConstants.UPDATE_EMPLOYEE_REQUEST,
    });
    dispatch({
      type: EmployeeConstants.UPDATE_EMPLOYEE_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: EmployeeConstants.DOWNLOAD_EMPLOYEE_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/cpanel/employee/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "employee." + fileType);
    dispatch({
      type: EmployeeConstants.DOWNLOAD_EMPLOYEE_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/cpanel/employee/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_employee." + fileType);
    dispatch({
      type: EmployeeConstants.DOWNLOAD_EMPLOYEE_REQUEST,
    });
    dispatch({
      type: EmployeeConstants.DOWNLOAD_EMPLOYEE_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: EmployeeConstants.UPLOAD_EMPLOYEE_REQUEST,
      });
      const response = await axios({
        url: "/cpanel/employee/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: EmployeeConstants.UPLOAD_EMPLOYEE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: EmployeeConstants.UPLOAD_EMPLOYEE_FAILURE,
      });
    }
  };

  return (
    <EmployeeContext.Provider
      value={{
        ...state,
        getAllEmployee,
        addEmployee,
        searchEmployee,
        autoSearchEmployee,
        getEmployee,
        updateEmployee,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
}

export { EmployeeContext, EmployeeProvider };
