import React, { useEffect, useState, useCallback } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "./CustomTextField";
import ReactHookFormSelect from "./ReactHookFormSelect";

import useVillage from "../hooks/useVillage";
import usePincode from "../hooks/usePincode";
import useCity from "../hooks/useCity";
import useTaluk from "../hooks/useTaluk";
import useDistrict from "../hooks/useDistrict";
import useCountry from "../hooks/useCountry";
import useStateMaster from "../hooks/useStateMaster";
import { searchPayloadEqInt } from "../utils/common";
import CustomCheckBox from "../components/CustomCheckBox";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const InstallationAddressDetail = ({ fields, watchFields, presentAddress }) => {
  const {
    register,
    watch,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const sameAsPresentAddress = watch("sameAsPresentAddress");

  const { searchTaluk, talukSearchResults } = useTaluk();
  const { searchDistrict, districtSearchResults } = useDistrict();
  const { searchState, stateSearchResults } = useStateMaster();
  const { searchCountry, countrySearchResults } = useCountry();

  useEffect(() => {
    if (sameAsPresentAddress) {
      Object.keys(presentAddress).forEach((key) => {
        setValue(key, presentAddress[key]);
      });
    }
  }, [sameAsPresentAddress, presentAddress, setValue]);

  console.log(watchFields, "keeyyyyy");

  // React.useEffect(() => {
  //   if (Object.keys(watchFields).length !== 0) {
  //     setValue("doorNo", watchFields.doorNo);
  //     setValue("streetName", watchFields.streetName);
  //     setValue("pinCode", watchFields.pinCode);
  //     setValue("villageName", watchFields.villageName);
  //     setValue("townName", watchFields.townName);
  //     setValue("talukId", watchFields.talukId);
  //     setValue("districtId", watchFields.districtId);
  //     setValue("stateId", watchFields.stateId);
  //     setValue("countryId", watchFields.countryId);
  //   }
  // }, [watchFields]);

  const fetchData = async () => {
    await searchTaluk(currentPayload);
    await searchCountry(276);
  };

  const handleDataFetching = (id, data, callback) => {
    const value = data.find((item) => item.id === id);
    if (value) {
      callback(value);
    }
  };

  const fetchDistricts = async (selectedDistrictId) => {
    if (selectedDistrictId) {
      searchPayloadValue = searchPayloadEqInt("districtId", selectedDistrictId, false);
      await searchDistrict(searchPayloadValue);
    }
  };

  const fetchState = async (selectedStateId) => {
    if (selectedStateId) {
      searchPayloadValue = searchPayloadEqInt("stateId", selectedStateId, false);
      await searchState(searchPayloadValue);
    }
  };

  const fetchCountry = async (selectedCountryId) => {
    if (selectedCountryId) {
      searchPayloadValue = searchPayloadEqInt("countryId", selectedCountryId, false);
      await searchCountry(searchPayloadValue);
    }
  };

  useEffect(() => {
    handleDataFetching(watch("talukId"), talukSearchResults, (value) => {
      fetchDistricts(value.districtId);
    });
  }, [watch("talukId"), talukSearchResults]);

  useEffect(() => {
    handleDataFetching(watch("districtId"), districtSearchResults, (value) => {
      fetchState(value.stateId);
    });
  }, [watch("districtId"), districtSearchResults]);

  useEffect(() => {
    handleDataFetching(watch("stateId"), stateSearchResults, (value) => {
      fetchCountry(value.countryId);
    });
  }, [watch("stateId"), stateSearchResults]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item md={12}>
        <CustomCheckBox
          label="Same as present address"
          name="sameAsPresentAddress"
          type="checkbox"
          my={2}
          fullWidth
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Door No / Flat No"
          name="doorNo"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Street Name"
          name="streetName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Pincode"
          name="pinCode"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Village Name"
          name="villageName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Town Name"
          name="townName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          id="talukId"
          name="talukId"
          label="Taluk"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          {talukSearchResults.map((taluk) => (
            <MenuItem key={taluk.talukId} value={taluk.talukId}>
              {taluk.talukName}
            </MenuItem>
          ))}
        </ReactHookFormSelect>
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          id="districtId"
          name="districtId"
          label="District"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          {districtSearchResults.map((district) => (
            <MenuItem key={district.districtId} value={district.districtId}>
              {district.districtName}
            </MenuItem>
          ))}
        </ReactHookFormSelect>
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          id="stateId"
          name="stateId"
          label="State"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          {stateSearchResults.map((state) => (
            <MenuItem key={state.stateId} value={state.stateId}>
              {state.stateName}
            </MenuItem>
          ))}
        </ReactHookFormSelect>
      </Grid>
      <Grid item md={6}>
        <ReactHookFormSelect
          id="countryId"
          name="countryId"
          label="Country"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          {countrySearchResults.map((country) => (
            <MenuItem key={country.countryId} value={country.countryId}>
              {country.countryName}
            </MenuItem>
          ))}
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
};

export default InstallationAddressDetail;
