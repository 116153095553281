import React from "react";
import { Grid } from "@mui/material";
import Stats from "./components/Stats";

function LeadStats() {
  return (
    <React.Fragment>
      <Grid
        container
        spacing={4}
        sx={{
          color: "success.main",
          justifyContent: "center",
        }}
      >
        <Grid item xs={9}>
          <Stats />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default LeadStats;
