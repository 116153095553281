import { useContext } from "react";

import { CancelledInvoiceContext } from "../contexts/CancelledInvoiceContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useCancelledInvoice = () => {
  const context = useContext(CancelledInvoiceContext);

  if (!context)
    throw new Error("CancelledInvoiceConstants must be placed within CancelledInvoicesProvider");

  return context;
};

export default useCancelledInvoice;
