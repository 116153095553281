//Users
import EditCardSystem from "../pages/card_system/generate_card/edit.js";
import CardSystem from "../pages/card_system/generate_card/index.js";
import AddCardSystem from "../pages/card_system/generate_card/add.js";
import OnlineCards from "../pages/card_system/online_cards/index.js";
import PrepaidCards from "../pages/card_system/list_prepaid_cards/index.js";
import RefillCards from "../pages/card_system/list_refill_cards/index.js";
import CardStats from "../pages/card_system/card_statistics/cardstats.js";
import CardSessionHistory from "../pages/card_system/card_session_history/index.js";

//Composers

import { DashboardComposer } from "../composers/DashboardComposer.js";
import { CardSystemComposer } from "../composers/CardSystemComposer";

// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";

// import EditcardSystemType from "../pages/cardSystem_type/edit.js";

const CardSystemRoutes = {
  path: "card-system",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),

  children: [
    {
      path: "online-card",
      children: [
        {
          path: "list",
          element: (
            <CardSystemComposer>
              <OnlineCards />
            </CardSystemComposer>
          ),
        },
      ],
    },
    {
      path: "prepaid-card",
      children: [
        {
          path: "list",
          element: (
            <CardSystemComposer>
              <PrepaidCards />
            </CardSystemComposer>
          ),
        },
      ],
    },
    {
      path: "refill-card",
      children: [
        {
          path: "list",
          element: (
            <CardSystemComposer>
              <RefillCards />
            </CardSystemComposer>
          ),
        },
      ],
    },
    {
      children: [
        {
          path: "card-stastics",
          element: (
            <CardSystemComposer>
              <CardStats />
            </CardSystemComposer>
          ),
        },
      ],
    },
    {
      children: [
        {
          path: "card-session-history",
          element: (
            <CardSystemComposer>
              <CardSessionHistory />
            </CardSystemComposer>
          ),
        },
      ],
    },
    {
      path: "generate-card",
      children: [
        {
          path: "add",
          element: (
            <CardSystemComposer>
              <AddCardSystem />
            </CardSystemComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <CardSystemComposer>
              <EditCardSystem />
            </CardSystemComposer>
          ),
        },
        {
          path: "list",
          element: (
            <CardSystemComposer>
              <CardSystem />
            </CardSystemComposer>
          ),
        },
      ],
    },
  ],
};

export default CardSystemRoutes;
