import React, { useRef, useEffect } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useFormContext } from "react-hook-form";

const SunEditorWrapper = ({ name, defaultValue, ...rest }) => {
  const {
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const editorRef = useRef(null);

  console.log(errors, "errors00000");

  useEffect(() => {
    // Register the SunEditor field with validation rules
    register(name, {
      required: "This field is required",
      minLength: { value: 10, message: "Content must be at least 10 characters long" },
    });
  }, [register, name]);

  const handleChange = (content) => {
    setValue(name, content); // Update form value when content changes
    trigger(name); // Trigger validation on content change
  };

  const getSunEditorInstance = (editor) => {
    editorRef.current = editor; // Store editor instance in ref
  };

  useEffect(() => {
    if (editorRef.current && defaultValue) {
      editorRef.current.setContents(defaultValue); // Set initial content
    }
  }, [defaultValue]);

  return (
    <div>
      <SunEditor
        height="100%"
        setOptions={{
          height: 400,
          buttonList: [
            [
              "font",
              "fontSize",
              "formatBlock",
              "bold",
              "underline",
              "italic",
              "strike",
              "align",
              "list",
              "table",
              "link",
              "image",
              "video",
            ],
          ],
        }}
        onChange={handleChange}
        getSunEditorInstance={getSunEditorInstance}
        {...rest}
      />
      {/* Display validation error */}
      {errors[name] && <p style={{ color: "red" }}>{errors[name].message}</p>}
    </div>
  );
};

export default SunEditorWrapper;
