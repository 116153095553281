import { useContext } from "react";

import { InterfaceContext } from "../contexts/InterfaceContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useInterface = () => {
  const context = useContext(InterfaceContext);

  if (!context) throw new Error("InterfaceContext must be placed within InterfaceProvider");

  return context;
};

export default useInterface;
