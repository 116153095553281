import { MaterialConstants } from "../constants/material.constants";

const MaterialReducer = (state, action) => {
  switch (action.type) {
    case MaterialConstants.FETCH_MATERIAL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case MaterialConstants.FETCH_MATERIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        materials: action.payload.data,
        paging: action.payload.paging,
      };
    case MaterialConstants.FETCH_MATERIAL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case MaterialConstants.MATERIAL_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case MaterialConstants.MATERIAL_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        materialSearchResults: action.payload,
      };
    case MaterialConstants.MATERIAL_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case MaterialConstants.MATERIAL_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case MaterialConstants.MATERIAL_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        materialAutoSearchResults: action.payload,
      };
    case MaterialConstants.MATERIAL_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case MaterialConstants.ADD_MATERIAL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case MaterialConstants.ADD_MATERIAL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case MaterialConstants.ADD_MATERIAL_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case MaterialConstants.GET_MATERIAL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case MaterialConstants.GET_MATERIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        material: action.payload,
      };
    case MaterialConstants.GET_MATERIAL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case MaterialConstants.UPDATE_MATERIAL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case MaterialConstants.UPDATE_MATERIAL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        material: action.payload,
      };
    case MaterialConstants.UPDATE_MATERIAL_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case MaterialConstants.DOWNLOAD_MATERIAL_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case MaterialConstants.DOWNLOAD_MATERIAL_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case MaterialConstants.DOWNLOAD_MATERIAL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case MaterialConstants.UPLOAD_MATERIAL_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case MaterialConstants.UPLOAD_MATERIAL_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case MaterialConstants.UPLOAD_MATERIAL_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default MaterialReducer;
