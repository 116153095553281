import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import useLoginHistory from "../../../hooks/useLoginHistory";
import ShowHtmlContent from "../../../components/ShowHtmlContent";
import AdvanceFilter from "./AdvanceFilter";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function LoginHistory(props) {
  const { isLoadingDownload, downloadFileType } = useLoginHistory();
  const { width: mainContentWidth } = useMainContentDimensions();

  const { state } = useLocation();
  const { t } = useTranslation();
  const { refreshPage } = props;

  const currentPayload = {
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { getAllLoginHistory, loginHistories, paging, downloadFile } = useLoginHistory();
  const { openDialog } = useDialog();

  const [payload, setPayload] = useState(currentPayload);

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const fetchData = async () => {
    await getAllLoginHistory(payload);
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Logs</Typography>
        <Typography>login-history</Typography>
        <Typography>index</Typography>
      </Breadcrumbs>
    );
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "user",
      headerName: "User Name",
      valueGetter: (params) => params?.name,
      width: 150,
    },
    {
      field: "loginDate",
      headerName: "Login Date",
      width: 300,
    },
    {
      field: "loginIp",
      headerName: "Login Ip",
      width: 150,
    },
  ];

  const openFilterDialog = () => {
    return openDialog(
      "advance_filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={
          <Toolbar
            downloadFile={downloadFile}
            refreshPage={refreshPage}
            openFilterDialog={openFilterDialog}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
          />
        }
        title={t("login_history")}
        subheader={breadCrump()}
        downloadFile={downloadFile}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          columns={columns}
          rows={loginHistories}
          refreshPage={fetchData}
          getRowId={(row) => row["loginHistoryId"]}
          rowCount={paging.totalNumberOfRecords}
          loading={false}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </CardContent>
    </Card>
  );
}

export default LoginHistory;
