export const EmailToUserConstants = {
  FETCH_EMAILTOUSER_REQUEST: "FETCH_EMAILTOUSER_REQUEST",
  FETCH_EMAILTOUSER_SUCCESS: "FETCH_EMAILTOUSER_SUCCESS",
  FETCH_EMAILTOUSER_FAILURE: "FETCH_EMAILTOUSER_FAILURE",
  ADD_EMAILTOUSER_REQUEST: "ADD_EMAILTOUSER_REQUEST",
  ADD_EMAILTOUSER_SUCCESS: "ADD_EMAILTOUSER_SUCCESS",
  ADD_EMAILTOUSER_FAILURE: "ADD_EMAILTOUSER_FAILURE",
  EMAILTOUSER_SEARCH_REQUEST: "EMAILTOUSER_SEARCH_REQUEST",
  EMAILTOUSER_SEARCH_SUCCESS: "EMAILTOUSER_SEARCH_SUCCESS",
  EMAILTOUSER_SEARCH_FAILURE: "EMAILTOUSER_SEARCH_FAILURE",
  EMAILTOUSER_AUTO_SEARCH_REQUEST: "EMAILTOUSER_AUTO_SEARCH_REQUEST",
  EMAILTOUSER_AUTO_SEARCH_SUCCESS: "EMAILTOUSER_AUTO_SEARCH_SUCCESS",
  EMAILTOUSER_AUTO_SEARCH_FAILURE: "EMAILTOUSER_AUTO_SEARCH_FAILURE",
  GET_EMAILTOUSER_REQUEST: "GET_EMAILTOUSER_REQUEST",
  GET_EMAILTOUSER_SUCCESS: "GET_EMAILTOUSER_SUCCESS",
  GET_EMAILTOUSER_FAILURE: "GET_EMAILTOUSER_FAILURE",
  UPDATE_EMAILTOUSER_REQUEST: "UPDATE_EMAILTOUSER_REQUEST",
  UPDATE_EMAILTOUSER_SUCCESS: "UPDATE_EMAILTOUSER_SUCCESS",
  UPDATE_EMAILTOUSER_FAILURE: "UPDATE_EMAILTOUSER_FAILURE",
  DOWNLOAD_EMAILTOUSER_REQUEST: "DOWNLOAD_EMAILTOUSER_REQUEST",
  DOWNLOAD_EMAILTOUSER_SUCCESS: "DOWNLOAD_EMAILTOUSER_SUCCESS",
  DOWNLOAD_EMAILTOUSER_FAILURE: "DOWNLOAD_EMAILTOUSER_FAILURE",
  UPLOAD_EMAILTOUSER_REQUEST: "UPLOAD_EMAILTOUSER_REQUEST",
  UPLOAD_EMAILTOUSER_SUCCESS: "UPLOAD_EMAILTOUSER_SUCCESS",
  UPLOAD_EMAILTOUSER_FAILURE: "UPLOAD_EMAILTOUSER_FAILURE",
};
