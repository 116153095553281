import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  CardHeader,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider, useWatch } from "react-hook-form";

import EditCancel from "../../../components/EditCancel";
import useDistrict from "../../../hooks/useDistrict";
import DistrictFields from "./components/DistrictFields";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  districtName: Yup.string().required("Required"),
  state: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  country: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  status: Yup.string().required("Required"),
});

function EditDistrict() {
  const dispatch = useDispatch();
  const [isSubmitBtn, setIsSubmitBtn] = useState(false);
  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });
  const { getDistrict, district, updateDistrict, isSubmitting } = useDistrict();

  const { state } = useLocation();
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const fetchData = async () => {
    await getDistrict(state.id);
  };

  React.useEffect(() => {
    if (Object.keys(district).length !== 0) {
      const initialValues = {
        districtId: district.districtId,
        districtName: district.districtName,
        country: {
          value: district.state?.country?.countryId,
          label: district.state?.country?.countryName,
        },
        state: {
          value: district.state?.stateId,
          label: district.state?.stateName,
        },
        status: district.status,
      };
      reset(initialValues);
    }
  }, [district]);

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    setIsSubmitBtn(true);
    try {
      const districtDTO = {
        districtId: data.districtId,
        country: {
          countryId: data.country.value,
        },
        state: {
          stateId: data.state.value,
        },
        status: data.status,
        districtName: data.districtName,
      };
      //console.log(districtDTO, "districtDTO");
      await updateDistrict(districtDTO);
      setStatus({ sent: true });
      dispatch(showSuccessSnackbar("District updated successfully!"));
      navigate("/masters/district/list");
    } catch (err) {
      setStatus({ sent: false });
      setErrorSubmit({ submit: err.message });
      dispatch(showErrorSnackbar(err.message));
    }
    return false;
  };
  const cancelBtn = () => {
    navigate("/masters/district/list");
  };

  const { t } = useTranslation();
  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_district")} subheader={"We can edit district here"} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <DistrictFields fields={validationSchema.fields} />
                <EditCancel cancelBtn={cancelBtn} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditDistrict;
