import { useContext } from "react";

import { UserActivityContext } from "../contexts/UserActivityContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useUserActivity = () => {
  const context = useContext(UserActivityContext);

  if (!context)
    throw new Error("UserActivityConstants must be placed within UserActivitiesProvider");

  return context;
};

export default useUserActivity;
