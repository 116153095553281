import { useContext } from "react";

import { MkRadiusContext } from "../contexts/MkRadiusContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useMkRadius = () => {
  const context = useContext(MkRadiusContext);

  if (!context) throw new Error("MkRadiusContext must be placed within MkRadiusProvider");

  return context;
};

export default useMkRadius;
