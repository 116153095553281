export const UnpaidInvoiceConstants = {
  FETCH_UNPAIDINVOICE_REQUEST: "FETCH_UNPAIDINVOICE_REQUEST",
  FETCH_UNPAIDINVOICE_SUCCESS: "FETCH_UNPAIDINVOICE_SUCCESS",
  FETCH_UNPAIDINVOICE_FAILURE: "FETCH_UNPAIDINVOICE_FAILURE",
  ADD_UNPAIDINVOICE_REQUEST: "ADD_UNPAIDINVOICE_REQUEST",
  ADD_UNPAIDINVOICE_SUCCESS: "ADD_UNPAIDINVOICE_SUCCESS",
  ADD_UNPAIDINVOICE_FAILURE: "ADD_UNPAIDINVOICE_FAILURE",
  COLLECT_USER_REQUEST: "COLLECT_USER_REQUEST",
  COLLECT_USER_SUCCESS: "COLLECT_USER_SUCCESS",
  COLLECT_USER_FAILURE: "COLLECT_USER_FAILURE",
  CANCEL_INVOICE_REQUEST: "CANCEL_INVOICE_REQUEST",
  CANCEL_INVOICE_SUCCESS: "CANCEL_INVOICE_SUCCESS",
  CANCEL_INVOICE_FAILURE: "CANCEL_INVOICE_FAILURE",

  UNPAIDINVOICE_SEARCH_REQUEST: "UNPAIDINVOICE_SEARCH_REQUEST",
  UNPAIDINVOICE_SEARCH_SUCCESS: "UNPAIDINVOICE_SEARCH_SUCCESS",
  UNPAIDINVOICE_SEARCH_FAILURE: "UNPAIDINVOICE_SEARCH_FAILURE",
  UNPAIDINVOICE_AUTO_SEARCH_REQUEST: "UNPAIDINVOICE_AUTO_SEARCH_REQUEST",
  UNPAIDINVOICE_AUTO_SEARCH_SUCCESS: "UNPAIDINVOICE_AUTO_SEARCH_SUCCESS",
  UNPAIDINVOICE_AUTO_SEARCH_FAILURE: "UNPAIDINVOICE_AUTO_SEARCH_FAILURE",
  GET_UNPAIDINVOICE_REQUEST: "GET_UNPAIDINVOICE_REQUEST",
  GET_UNPAIDINVOICE_SUCCESS: "GET_UNPAIDINVOICE_SUCCESS",
  GET_UNPAIDINVOICE_FAILURE: "GET_UNPAIDINVOICE_FAILURE",

  UPDATE_UNPAIDINVOICE_REQUEST: "UPDATE_UNPAIDINVOICE_REQUEST",
  UPDATE_UNPAIDINVOICE_SUCCESS: "UPDATE_UNPAIDINVOICE_SUCCESS",
  UPDATE_UNPAIDINVOICE_FAILURE: "UPDATE_UNPAIDINVOICE_FAILURE",
  DOWNLOAD_UNPAIDINVOICE_REQUEST: "DOWNLOAD_UNPAIDINVOICE_REQUEST",
  DOWNLOAD_UNPAIDINVOICE_SUCCESS: "DOWNLOAD_UNPAIDINVOICE_SUCCESS",
  DOWNLOAD_UNPAIDINVOICE_FAILURE: "DOWNLOAD_UNPAIDINVOICE_FAILURE",
  UPLOAD_UNPAIDINVOICE_REQUEST: "UPLOAD_UNPAIDINVOICE_REQUEST",
  UPLOAD_UNPAIDINVOICE_SUCCESS: "UPLOAD_UNPAIDINVOICE_SUCCESS",
  UPLOAD_UNPAIDINVOICE_FAILURE: "UPLOAD_UNPAIDINVOICE_FAILURE",
};
