import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import { useFormContext, FormProvider } from "react-hook-form";

import useAuth from "../../../../../hooks/useAuth";
import CustomCheckBox from "../../../../../components/CustomCheckBox";

const SmsNotification = (props) => {
  const { user } = useAuth();
  const { validationFields, tenantSmsTemplates } = props;
  const {
    formState: { errors },
  } = useFormContext();

  console.log(tenantSmsTemplates, "tenantSmsTemplates");

  return (
    <Grid container spacing={12} className="row" pt={8} sx={{ paddingLeft: "16px" }}>
      {tenantSmsTemplates.map((tenantSmsTemplate) => {
        return (
          <Grid item md={4}>
            <CustomCheckBox
              defaultValue={true}
              label={tenantSmsTemplate.smsTemplate.smsTemplateName}
              name={`tenantSmsId-${tenantSmsTemplate.tenantSmsId}`}
              type="checkbox"
              my={2}
              fullWidth
              errors={errors}
              fields={validationFields}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SmsNotification;
