export const MkIpPoolConstants = {
  FETCH_MK_IP_POOL_REQUEST: "FETCH_MK_IP_POOL_REQUEST",
  FETCH_MK_IP_POOL_SUCCESS: "FETCH_MK_IP_POOL_SUCCESS",
  FETCH_MK_IP_POOL_FAILURE: "FETCH_MK_IP_POOL_FAILURE",
  ADD_MK_IP_POOL_REQUEST: "ADD_MK_IP_POOL_REQUEST",
  ADD_MK_IP_POOL_SUCCESS: "ADD_MK_IP_POOL_SUCCESS",
  ADD_MK_IP_POOL_FAILURE: "ADD_MK_IP_POOL_FAILURE",
  MK_IP_POOL_SEARCH_REQUEST: "MK_IP_POOL_SEARCH_REQUEST",
  MK_IP_POOL_SEARCH_SUCCESS: "MK_IP_POOL_SEARCH_SUCCESS",
  MK_IP_POOL_SEARCH_FAILURE: "MK_IP_POOL_SEARCH_FAILURE",
  MK_IP_POOL_AUTO_SEARCH_REQUEST: "MK_IP_POOL_AUTO_SEARCH_REQUEST",
  MK_IP_POOL_AUTO_SEARCH_SUCCESS: "MK_IP_POOL_AUTO_SEARCH_SUCCESS",
  MK_IP_POOL_AUTO_SEARCH_FAILURE: "MK_IP_POOL_AUTO_SEARCH_FAILURE",
  GET_MK_IP_POOL_REQUEST: "GET_MK_IP_POOL_REQUEST",
  GET_MK_IP_POOL_SUCCESS: "GET_MK_IP_POOL_SUCCESS",
  GET_MK_IP_POOL_FAILURE: "GET_MK_IP_POOL_FAILURE",
  UPDATE_MK_IP_POOL_REQUEST: "UPDATE_MK_IP_POOL_REQUEST",
  UPDATE_MK_IP_POOL_SUCCESS: "UPDATE_MK_IP_POOL_SUCCESS",
  UPDATE_MK_IP_POOL_FAILURE: "UPDATE_MK_IP_POOL_FAILURE",
  DOWNLOAD_MK_IP_POOL_REQUEST: "DOWNLOAD_MK_IP_POOL_REQUEST",
  DOWNLOAD_MK_IP_POOL_SUCCESS: "DOWNLOAD_MK_IP_POOL_SUCCESS",
  DOWNLOAD_MK_IP_POOL_FAILURE: "DOWNLOAD_MK_IP_POOL_FAILURE",
  UPLOAD_MK_IP_POOL_REQUEST: "UPLOAD_MK_IP_POOL_REQUEST",
  UPLOAD_MK_IP_POOL_SUCCESS: "UPLOAD_MK_IP_POOL_SUCCESS",
  UPLOAD_MK_IP_POOL_FAILURE: "UPLOAD_MK_IP_POOL_FAILURE",
};
