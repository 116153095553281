import { useContext } from "react";

import { TenantContext } from "../contexts/TenantContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useTenant = () => {
  const context = useContext(TenantContext);

  if (!context) throw new Error("TenantContext must be placed within TenantProvider");

  return context;
};

export default useTenant;
