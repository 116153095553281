import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { MaterialProvider } from "../contexts/MaterialContext";
import { DialogProvider } from "../contexts/DialogContext";

export const MaterialComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<MaterialProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};

export default MaterialComposer;
