import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import MkRadiusReducer from "../reducers/MkRadiusReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { MkRadiusConstants } from "../constants/mkradius.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  mkRadiuses: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  mkRadiusSearchResults: [],
  mkRadius: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  mkRadiusAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const MkRadiusContext = createContext({
  ...initialState,
  getAllMkRadius: () => {},
  addMkRadius: () => {},
  searchMkRadius: () => {},
  autoSearchMkRadius: () => {},
  getMkRadius: () => {},
  updateMkRadius: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function MkRadiusProvider({ children }) {
  const [state, dispatch] = useReducer(MkRadiusReducer, initialState);

  const getAllMkRadius = async (payload) => {
    try {
      dispatch({
        type: MkRadiusConstants.FETCH_MK_RADIUS_REQUEST,
      });
      const response = await axios.post("/mk-radius/list", payload);
      dispatch({
        type: MkRadiusConstants.FETCH_MK_RADIUS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: MkRadiusConstants.FETCH_MK_RADIUS_FAILURE,
      });
    }
  };
  const searchMkRadius = async (payload) => {
    try {
      const response = await axios.post("/mk-radius/search", payload);
      dispatch({
        type: MkRadiusConstants.MK_RADIUS_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: MkRadiusConstants.MK_RADIUS_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: MkRadiusConstants.MK_RADIUS_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchMkRadius = async (payload) => {
    try {
      const response = await axios.post("/mk-radius/auto-search", payload);
      dispatch({
        type: MkRadiusConstants.MK_RADIUS_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: MkRadiusConstants.MK_RADIUS_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: MkRadiusConstants.MK_RADIUS_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addMkRadius = async (payload) => {
    const response = await axios.post("/mk-radius/add", payload);
    dispatch({
      type: MkRadiusConstants.ADD_MK_RADIUS_REQUEST,
    });
    dispatch({
      type: MkRadiusConstants.ADD_MK_RADIUS_SUCCESS,
      payload: response.data,
    });
  };

  const getMkRadius = async (id) => {
    const response = await axios.get("/mk-radius/" + id);
    dispatch({
      type: MkRadiusConstants.GET_MK_RADIUS_REQUEST,
    });
    dispatch({
      type: MkRadiusConstants.GET_MK_RADIUS_SUCCESS,
      payload: response.data,
    });
  };

  const updateMkRadius = async (payload) => {
    const response = await axios.put("/mk-radius/update/" + payload.radiusId, payload);
    dispatch({
      type: MkRadiusConstants.UPDATE_MK_RADIUS_REQUEST,
    });
    dispatch({
      type: MkRadiusConstants.UPDATE_MK_RADIUS_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: MkRadiusConstants.DOWNLOAD_MK_RADIUS_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/mk-radius/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "mkRadius." + fileType);
    dispatch({
      type: MkRadiusConstants.DOWNLOAD_MK_RADIUS_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/mk-radius/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_mkRadius." + fileType);
    dispatch({
      type: MkRadiusConstants.DOWNLOAD_MK_RADIUS_REQUEST,
    });
    dispatch({
      type: MkRadiusConstants.DOWNLOAD_MK_RADIUS_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: MkRadiusConstants.UPLOAD_MK_RADIUS_REQUEST,
      });
      const response = await axios({
        url: "/mk-radius/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: MkRadiusConstants.UPLOAD_MK_RADIUS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: MkRadiusConstants.UPLOAD_MK_RADIUS_FAILURE,
      });
    }
  };

  return (
    <MkRadiusContext.Provider
      value={{
        ...state,
        getAllMkRadius,
        addMkRadius,
        searchMkRadius,
        autoSearchMkRadius,
        getMkRadius,
        updateMkRadius,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </MkRadiusContext.Provider>
  );
}

export { MkRadiusContext, MkRadiusProvider };
