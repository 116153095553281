import { ComplaintTypeConstants } from "../constants/complainttype.constants";

const ComplaintTypeReducer = (state, action) => {
  switch (action.type) {
    case ComplaintTypeConstants.FETCH_COMPLAINT_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ComplaintTypeConstants.FETCH_COMPLAINT_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaintTypes: action.payload.data,
        paging: action.payload.paging,
      };
    case ComplaintTypeConstants.FETCH_COMPLAINT_TYPE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ComplaintTypeConstants.COMPLAINT_TYPE_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ComplaintTypeConstants.COMPLAINT_TYPE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaintTypeSearchResults: action.payload,
      };
    case ComplaintTypeConstants.COMPLAINT_TYPE_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ComplaintTypeConstants.COMPLAINT_TYPE_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ComplaintTypeConstants.COMPLAINT_TYPE_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaintTypeAutoSearchResults: action.payload,
      };
    case ComplaintTypeConstants.COMPLAINT_TYPE_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ComplaintTypeConstants.ADD_COMPLAINT_TYPE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case ComplaintTypeConstants.ADD_COMPLAINT_TYPE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case ComplaintTypeConstants.ADD_COMPLAINT_TYPE_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case ComplaintTypeConstants.GET_COMPLAINT_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ComplaintTypeConstants.GET_COMPLAINT_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaintType: action.payload,
      };
    case ComplaintTypeConstants.GET_COMPLAINT_TYPE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ComplaintTypeConstants.UPDATE_COMPLAINT_TYPE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case ComplaintTypeConstants.UPDATE_COMPLAINT_TYPE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        complaintType: action.payload,
      };
    case ComplaintTypeConstants.UPDATE_COMPLAINT_TYPE_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case ComplaintTypeConstants.DOWNLOAD_COMPLAINT_TYPE_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case ComplaintTypeConstants.DOWNLOAD_COMPLAINT_TYPE_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case ComplaintTypeConstants.DOWNLOAD_COMPLAINT_TYPE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case ComplaintTypeConstants.UPLOAD_COMPLAINT_TYPE_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case ComplaintTypeConstants.UPLOAD_COMPLAINT_TYPE_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case ComplaintTypeConstants.UPLOAD_COMPLAINT_TYPE_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default ComplaintTypeReducer;
