import React, { useEffect, useState } from "react";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

import { Card, CardHeader, CardContent } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useWindowDimensions from "../../../utils/windowDimensions";
import useAgreementTracker from "../../../hooks/useAgreementTracker";
import useAuth from "../../../hooks/useAuth";
import { formatDate, addDays } from "../../../utils/common";

function AED() {
  const { user } = useAuth();
  const { width: mainContentWidth } = useMainContentDimensions();

  const currentPayload = {
    filters: [
      {
        fieldName: "dateOfExpiry",
        fieldValue:
          formatDate(addDays(new Date(), 30)) +
          " 00:00:00," +
          formatDate(addDays(new Date(), 30)) +
          " 23:59:59",
        op: "btn",
        dataType: "string",
        condition: "and",
        isJoin: false,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
    sortFields: [
      {
        field: "createdAt",
        sort: "DESC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { getAllAgreementTracker, agreementTrackers, paging, isLoading } = useAgreementTracker();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    if (Object.keys(payload).length != 0) {
      await getAllAgreementTracker(payload);
    }
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: currentPayload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "tenant",
      headerName: "Tenant Name",
      valueGetter: (params) => params.tenantName,
      width: 200,
    },

    {
      field: "dateOfExpiryStr",
      headerName: "Agreement Expiry Date",
      width: 180,
    },
  ];

  return (
    <Card sx={{ width: mainContentWidth - 600 }}>
      <CardHeader title="Agreement Expiry Details" />
      <CardContent sx={{ height: height - 200 }}>
        <DataGrid
          columns={columns}
          rows={agreementTrackers}
          refreshPage={fetchData}
          getRowId={(row) => row["agreementTrackerId"]}
          rowCount={paging.totalNumberOfRecords}
          loading={isLoading}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </CardContent>
    </Card>
  );
}

export default AED;
