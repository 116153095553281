import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import LoginHistoryReducer from "../reducers/LoginHistoryReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { LoginHistoryConstants } from "../constants/loginhistory.constants";
import { saveFile } from "../utils/common";
const initialState = {
  loginHistories: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  loginHistorySearchResults: [],
  loginHistory: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  loginHistoryAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const LoginHistoryContext = createContext({
  ...initialState,
  getAllLoginHistory: () => {},
  addLoginHistory: () => {},
  searchLoginHistory: () => {},
  autoSearchLoginHistory: () => {},
  getLoginHistory: () => {},
  updateLoginHistory: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function LoginHistoryProvider({ children }) {
  const [state, dispatch] = useReducer(LoginHistoryReducer, initialState);

  const getAllLoginHistory = async (payload) => {
    try {
      dispatch({
        type: LoginHistoryConstants.FETCH_LOGINHISTORY_REQUEST,
      });
      const response = await axios.post("/auth/login-history", payload);

      dispatch({
        type: LoginHistoryConstants.FETCH_LOGINHISTORY_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: LoginHistoryConstants.FETCH_LOGINHISTORY_FAILURE,
      });
    }
  };
  const searchLoginHistory = async (payload) => {
    try {
      const response = await axios.post("/auth/login-history/search", payload);
      dispatch({
        type: LoginHistoryConstants.LOGINHISTORY_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: LoginHistoryConstants.LOGINHISTORY_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: LoginHistoryConstants.LOGINHISTORY_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchLoginHistory = async (payload) => {
    try {
      const response = await axios.post("/auth/login-history/auto-search", payload);
      dispatch({
        type: LoginHistoryConstants.LOGINHISTORY_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: LoginHistoryConstants.LOGINHISTORY_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: LoginHistoryConstants.LOGINHISTORY_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const getLoginHistory = async (id) => {
    const response = await axios.get("/auth/login-history/" + id);
    dispatch({
      type: LoginHistoryConstants.GET_LOGINHISTORY_REQUEST,
    });
    dispatch({
      type: LoginHistoryConstants.GET_LOGINHISTORY_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: LoginHistoryConstants.DOWNLOAD_LOGINHISTORY_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/auth/login-history/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "login-history." + fileType);

    dispatch({
      type: LoginHistoryConstants.DOWNLOAD_LOGINHISTORY_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <LoginHistoryContext.Provider
      value={{
        ...state,
        getAllLoginHistory,
        searchLoginHistory,
        autoSearchLoginHistory,
        getLoginHistory,
        downloadFile,
      }}
    >
      {children}
    </LoginHistoryContext.Provider>
  );
}

export { LoginHistoryContext, LoginHistoryProvider };
