import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import NasReducer from "../reducers/NasReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { NasConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  nases: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  nasSearchResults: [],
  nas: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isLoading: true,
  isLoadingDownload: false,
  nasAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const NasContext = createContext({
  ...initialState,
  getAllNas: () => {},
  addNas: () => {},
  searchNas: () => {},
  getNas: () => {},
  updateNas: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  autoSearchNas: () => {},
});

function NasProvider({ children }) {
  const [state, dispatch] = useReducer(NasReducer, initialState);

  const getAllNas = async (payload) => {
    try {
      dispatch({
        type: NasConstants.FETCH_NAS_REQUEST,
      });
      const response = await axios.post("/radius/nas/list", payload);

      dispatch({
        type: NasConstants.FETCH_NAS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: NasConstants.FETCH_NAS_FAILURE,
      });
    }
  };
  const searchNas = async () => {
    try {
      const response = await axios.post("/radius/nas/search", initPayload);
      dispatch({
        type: NasConstants.NAS_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: NasConstants.NAS_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: NasConstants.NAS_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchNas = async (payload) => {
    try {
      const response = await axios.post("/radius/nas/auto-search", payload);
      dispatch({
        type: NasConstants.NAS_AUTO_SEARCH_REQUEST,
      });
      dispatch({
        type: NasConstants.NAS_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: NasConstants.NAS_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addNas = async (payload) => {
    const response = await axios.post("/radius/nas/add", payload);
    dispatch({
      type: NasConstants.ADD_NAS_REQUEST,
    });
    dispatch({
      type: NasConstants.ADD_NAS_SUCCESS,
      payload: response.data,
    });
  };

  const getNas = async (id) => {
    const response = await axios.get("/radius/nas/" + id);
    dispatch({
      type: NasConstants.GET_NAS_REQUEST,
    });
    dispatch({
      type: NasConstants.GET_NAS_SUCCESS,
      payload: response.data,
    });
  };

  const updateNas = async (payload) => {
    const response = await axios.put("/radius/nas/update/" + payload.nasId, payload);
    dispatch({
      type: NasConstants.UPDATE_NAS_REQUEST,
    });
    dispatch({
      type: NasConstants.UPDATE_NAS_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: NasConstants.DOWNLOAD_NAS_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/radius/nas/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "nas." + fileType);

    dispatch({
      type: NasConstants.DOWNLOAD_NAS_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/radius/nas/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_nas." + fileType);
    dispatch({
      type: NasConstants.DOWNLOAD_NAS_REQUEST,
    });
    dispatch({
      type: NasConstants.DOWNLOAD_NAS_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: NasConstants.UPLOAD_NAS_REQUEST,
      });
      const response = await axios({
        url: "/radius/nas/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: NasConstants.UPLOAD_NAS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: NasConstants.UPLOAD_NAS_FAILURE,
      });
    }
  };

  return (
    <NasContext.Provider
      value={{
        ...state,
        getAllNas,
        addNas,
        searchNas,
        getNas,
        updateNas,
        downloadFile,
        sampleFile,
        uploadFile,
        autoSearchNas,
      }}
    >
      {children}
    </NasContext.Provider>
  );
}

export { NasContext, NasProvider };
