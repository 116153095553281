import { SmsToTenantConstants } from "../constants/smstotenant.constants";

const SmsToTenantsReducer = (state, action) => {
  switch (action.type) {
    case SmsToTenantConstants.FETCH_SMSTOTENANT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsToTenantConstants.FETCH_SMSTOTENANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsToTenants: action.payload.data,
        paging: action.payload.paging,
      };
    case SmsToTenantConstants.FETCH_SMSTOTENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsToTenantConstants.SMSTOTENANT_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsToTenantConstants.SMSTOTENANT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsToTenantSearchResults: action.payload,
      };
    case SmsToTenantConstants.SMSTOTENANT_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsToTenantConstants.SMSTOTENANT_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsToTenantConstants.SMSTOTENANT_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsToTenantAutoSearchResults: action.payload,
      };
    case SmsToTenantConstants.SMSTOTENANT_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsToTenantConstants.GET_SMSTOTENANT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case SmsToTenantConstants.GET_SMSTOTENANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsToTenant: action.payload,
      };
    case SmsToTenantConstants.GET_SMSTOTENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case SmsToTenantConstants.DOWNLOAD_SMSTOTENANT_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case SmsToTenantConstants.DOWNLOAD_SMSTOTENANT_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case SmsToTenantConstants.DOWNLOAD_SMSTOTENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    default:
      return state;
  }
};

export default SmsToTenantsReducer;
