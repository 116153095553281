import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import CustomDataGrid from "./CustomDataGrid";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import useTenant from "../../../hooks/useTenant";
import useAuth from "../../../hooks/useAuth";

function AssignedPlan() {
  const { user } = useAuth();

  const { getTenantPlans, tenantPlans, paging, isLoading, downloadFile } = useTenant();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getTenantPlans(payload);
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };
  const isPlanMounted = useRef(false);
  useEffect(() => {
    fetchData();
  }, [payload]);

  useEffect(() => {
    console.log(user, "useruseruser");
    if (user) {
      setPayload({
        ...payload,
        filters: [
          {
            fieldName: "tenantId",
            fieldValue: user.tenantId,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      });
    }
  }, [user]);

  const exportFile = async (fileType) => {
    await downloadFile(fileType, payload);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CustomDataGrid
            trans={t}
            addPage={null}
            editPage={null}
            downloadFile={exportFile}
            openDownloadDialog={null}
            id="planId"
            data={tenantPlans ? tenantPlans : []}
            rowCount={paging.totalNumberOfRecords}
            paging={{
              page: paging.pageNumber,
              pageSize: paging.pageSize,
            }}
            onPageChange={onPageChange}
            isLoading={isLoading}
            sortModel={sortModel}
            handleSortModelChange={handleSortModelChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AssignedPlan;
