import React from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";

import EditDelete from "../../../components/EditDelete";
import Toolbar from "../../../components/Toolbar";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>User</Typography>
      <Typography>Online</Typography>
    </Breadcrumbs>
  );
};

const CustomDataGrid = (props) => {
  const { width: mainContentWidth } = useMainContentDimensions();

  const {
    trans,
    data,
    rowCount,
    paging,
    onPageChange,
    isLoading,
    id,
    handleSortModelChange,
    sortModel,
    downloadFile,
    openDownloadDialog,
  } = props;
  console.log(rowCount, "loading child component");

  const columns = [
    {
      field: "userName",
      headerName: "User Name",
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 300,
    },
    {
      field: "ipAddress",
      headerName: "Ip Address",
      width: 300,
    },
    {
      field: "mac",
      headerName: "MAC",
      width: 300,
    },

    {
      field: "userPolicy",
      headerName: "User Service",
      width: 300,
    },
    {
      field: "runningPolicy",
      headerName: "Running Service",
      width: 300,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 300,
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: 300,
    },
    {
      field: "onlineTime",
      headerName: "Online Time",
    },
    {
      field: "download",
      headerName: "Downloaded",
    },
    {
      field: "upload",
      headerName: "Uploaded",
    },
    {
      field: "total",
      headerName: "Total",
    },
    {
      field: "nasIp",
      headerName: "Nas Ip",
      width: 300,
    },
  ];
  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={<Toolbar downloadFile={downloadFile} openDownloadDialog={openDownloadDialog} />}
        title="Online Users"
        subheader={breadCrump()}
      />
      <Paper>
        <div style={{ height: 400 }}>
          <DataGrid
            columns={columns}
            rows={data}
            rowCount={rowCount}
            getRowId={(row) => row[id]}
            loading={isLoading}
            rowsPerPageOptions={[5]}
            pagination
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMode="server"
            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageChange(pageSize)}
            checkboxSelection
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        </div>
      </Paper>
    </Card>
  );
};
CustomDataGrid.propTypes = {
  trans: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  paging: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSortModelChange: PropTypes.func.isRequired,
  sortModel: PropTypes.object.isRequired,
};
export default CustomDataGrid;
