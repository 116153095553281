export const TrafficReportConstants = {
  FETCH_TRAFFIC_REPORT_REQUEST: "FETCH_TRAFFIC_REPORT_REQUEST",
  FETCH_TRAFFIC_REPORT_SUCCESS: "FETCH_TRAFFIC_REPORT_SUCCESS",
  FETCH_TRAFFIC_REPORT_FAILURE: "FETCH_TRAFFIC_REPORT_FAILURE",
  ADD_TRAFFIC_REPORT_REQUEST: "ADD_TRAFFIC_REPORT_REQUEST",
  ADD_TRAFFIC_REPORT_SUCCESS: "ADD_TRAFFIC_REPORT_SUCCESS",
  ADD_TRAFFIC_REPORT_FAILURE: "ADD_TRAFFIC_REPORT_FAILURE",
  TRAFFIC_REPORT_SEARCH_REQUEST: "TRAFFIC_REPORT_SEARCH_REQUEST",
  TRAFFIC_REPORT_SEARCH_SUCCESS: "TRAFFIC_REPORT_SEARCH_SUCCESS",
  TRAFFIC_REPORT_SEARCH_FAILURE: "TRAFFIC_REPORT_SEARCH_FAILURE",
  TRAFFIC_REPORT_AUTO_SEARCH_REQUEST: "TRAFFIC_REPORT_AUTO_SEARCH_REQUEST",
  TRAFFIC_REPORT_AUTO_SEARCH_SUCCESS: "TRAFFIC_REPORT_AUTO_SEARCH_SUCCESS",
  TRAFFIC_REPORT_AUTO_SEARCH_FAILURE: "TRAFFIC_REPORT_AUTO_SEARCH_FAILURE",
  GET_TRAFFIC_REPORT_REQUEST: "GET_TRAFFIC_REPORT_REQUEST",
  GET_TRAFFIC_REPORT_SUCCESS: "GET_TRAFFIC_REPORT_SUCCESS",
  GET_TRAFFIC_REPORT_FAILURE: "GET_TRAFFIC_REPORT_FAILURE",
  UPDATE_TRAFFIC_REPORT_REQUEST: "UPDATE_TRAFFIC_REPORT_REQUEST",
  UPDATE_TRAFFIC_REPORT_SUCCESS: "UPDATE_TRAFFIC_REPORT_SUCCESS",
  UPDATE_TRAFFIC_REPORT_FAILURE: "UPDATE_TRAFFIC_REPORT_FAILURE",
  DOWNLOAD_TRAFFIC_REPORT_REQUEST: "DOWNLOAD_TRAFFIC_REPORT_REQUEST",
  DOWNLOAD_TRAFFIC_REPORT_SUCCESS: "DOWNLOAD_TRAFFIC_REPORT_SUCCESS",
  DOWNLOAD_TRAFFIC_REPORT_FAILURE: "DOWNLOAD_TRAFFIC_REPORT_FAILURE",
  UPLOAD_TRAFFIC_REPORT_REQUEST: "UPLOAD_TRAFFIC_REPORT_REQUEST",
  UPLOAD_TRAFFIC_REPORT_SUCCESS: "UPLOAD_TRAFFIC_REPORT_SUCCESS",
  UPLOAD_TRAFFIC_REPORT_FAILURE: "UPLOAD_TRAFFIC_REPORT_FAILURE",
};
