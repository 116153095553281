export const UserActivityConstants = {
  FETCH_USERACTIVITY_REQUEST: "FETCH_USERACTIVITY_REQUEST",
  FETCH_USERACTIVITY_SUCCESS: "FETCH_USERACTIVITY_SUCCESS",
  FETCH_USERACTIVITY_FAILURE: "FETCH_USERACTIVITY_FAILURE",
  ADD_USERACTIVITY_REQUEST: "ADD_USERACTIVITY_REQUEST",
  ADD_USERACTIVITY_SUCCESS: "ADD_USERACTIVITY_SUCCESS",
  ADD_USERACTIVITY_FAILURE: "ADD_USERACTIVITY_FAILURE",
  USERACTIVITY_SEARCH_REQUEST: "USERACTIVITY_SEARCH_REQUEST",
  USERACTIVITY_SEARCH_SUCCESS: "USERACTIVITY_SEARCH_SUCCESS",
  USERACTIVITY_SEARCH_FAILURE: "USERACTIVITY_SEARCH_FAILURE",
  USERACTIVITY_AUTO_SEARCH_REQUEST: "USERACTIVITY_AUTO_SEARCH_REQUEST",
  USERACTIVITY_AUTO_SEARCH_SUCCESS: "USERACTIVITY_AUTO_SEARCH_SUCCESS",
  USERACTIVITY_AUTO_SEARCH_FAILURE: "USERACTIVITY_AUTO_SEARCH_FAILURE",
  GET_USERACTIVITY_REQUEST: "GET_USERACTIVITY_REQUEST",
  GET_USERACTIVITY_SUCCESS: "GET_USERACTIVITY_SUCCESS",
  GET_USERACTIVITY_FAILURE: "GET_USERACTIVITY_FAILURE",
  UPDATE_USERACTIVITY_REQUEST: "UPDATE_USERACTIVITY_REQUEST",
  UPDATE_USERACTIVITY_SUCCESS: "UPDATE_USERACTIVITY_SUCCESS",
  UPDATE_USERACTIVITY_FAILURE: "UPDATE_USERACTIVITY_FAILURE",
  DOWNLOAD_USERACTIVITY_REQUEST: "DOWNLOAD_USERACTIVITY_REQUEST",
  DOWNLOAD_USERACTIVITY_SUCCESS: "DOWNLOAD_USERACTIVITY_SUCCESS",
  DOWNLOAD_USERACTIVITY_FAILURE: "DOWNLOAD_USERACTIVITY_FAILURE",
  UPLOAD_USERACTIVITY_REQUEST: "UPLOAD_USERACTIVITY_REQUEST",
  UPLOAD_USERACTIVITY_SUCCESS: "UPLOAD_USERACTIVITY_SUCCESS",
  UPLOAD_USERACTIVITY_FAILURE: "UPLOAD_USERACTIVITY_FAILURE",
};
