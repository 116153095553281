import { PppProfileConstants } from "../constants/pppprofile.constants";

const PppProfileReducer = (state, action) => {
  switch (action.type) {
    case PppProfileConstants.FETCH_PPP_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PppProfileConstants.FETCH_PPP_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pppProfiles: action.payload.data,
        paging: action.payload.paging,
      };
    case PppProfileConstants.FETCH_PPP_PROFILE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PppProfileConstants.PPP_PROFILE_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PppProfileConstants.PPP_PROFILE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pppProfileSearchResults: action.payload,
      };
    case PppProfileConstants.PPP_PROFILE_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PppProfileConstants.PPP_PROFILE_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PppProfileConstants.PPP_PROFILE_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pppProfileAutoSearchResults: action.payload,
      };
    case PppProfileConstants.PPP_PROFILE_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PppProfileConstants.ADD_PPP_PROFILE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case PppProfileConstants.ADD_PPP_PROFILE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case PppProfileConstants.ADD_PPP_PROFILE_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case PppProfileConstants.GET_PPP_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PppProfileConstants.GET_PPP_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pppProfile: action.payload,
      };
    case PppProfileConstants.GET_PPP_PROFILE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PppProfileConstants.UPDATE_PPP_PROFILE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case PppProfileConstants.UPDATE_PPP_PROFILE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        pppProfile: action.payload,
      };
    case PppProfileConstants.UPDATE_PPP_PROFILE_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case PppProfileConstants.DOWNLOAD_PPP_PROFILE_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case PppProfileConstants.DOWNLOAD_PPP_PROFILE_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PppProfileConstants.DOWNLOAD_PPP_PROFILE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PppProfileConstants.UPLOAD_PPP_PROFILE_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case PppProfileConstants.UPLOAD_PPP_PROFILE_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case PppProfileConstants.UPLOAD_PPP_PROFILE_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default PppProfileReducer;
