//Users
import UnpaidInvoice from "../pages/invoices/unpaid_invoices/index.js";
import PaidInvoice from "../pages/invoices/paid_invoices/index.js";
//Composers
import UnpaidInvoiceComposer from "../composers/UnpaidInvoiceComposer.js";
import PaidInvoiceComposer from "../composers/PaidInvoiceComposer.js";
import { DashboardComposer } from "../composers/DashboardComposer.js";
// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";

const InvoiceRoutes = {
  path: "invoices",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "paid",
      element: (
        <PaidInvoiceComposer>
          <PaidInvoice />
        </PaidInvoiceComposer>
      ),
    },
    {
      path: "unpaid",
      element: (
        <UnpaidInvoiceComposer>
          <UnpaidInvoice />
        </UnpaidInvoiceComposer>
      ),
    },
  ],
};

export default InvoiceRoutes;
