import React, { useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import SaveCancel from "../../../components/SaveCancel";
import useAddon from "../../../hooks/useAddon";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import AddonDetail from "./components/AddonDetail";
import AllowedTenants from "./components/AllowedTenants";
import { useFormState, useFormDispatch } from "../../../contexts/AddOnFormContext";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  addOnName: Yup.string().required("Required"),
  totalLimit: Yup.number()
    .typeError("Total limit must be a number")
    .required("ERROR: The number is required!")
    .test("Is positive?", "ERROR: The number must be greater than 0!", (value) => value > 0),
  price: Yup.number()
    .typeError("Total limit must be a number")
    .required("ERROR: The number is required!")
    .test("Is positive?", "ERROR: The number must be greater than 0!", (value) => value > 0),
  taxEnabled: Yup.boolean().required("Required"),
  taxType: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function AddAddon() {
  const dispatch = useDispatch();
  const initialValues = {
    addOnName: "",
    totalLimit: null,
    price: "",
    taxEnabled: true,
    taxType: "INCLUDING_TAX",
    status: "ACTIVE",
    notes: "",
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const { t } = useTranslation();
  const { addAddon, hasError, errorMessage, isSubmitting } = useAddon();
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Radius</Typography>
        <Typography>Addon</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  const onSubmit = async (data) => {
    try {
      await addAddon(data);
      reset();
      dispatch(showSuccessSnackbar("Addon added successfully!"));
      cancelBtn();
      //resetForm();
      //setStatus({ sent: true });
      //setSubmitting(false);
    } catch (error) {
      console.log(error);
      dispatch(showErrorSnackbar("Failed to add addon"));
      //setStatus({ sent: false });
      //setErrors({ submit: error.message });
      //setSubmitting(false);
    }
  };

  const cancelBtn = () => {
    navigate("/radius/addon/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_addon")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value} onChange={handleTabChange} aria-label="Addon">
                    <Tab label="Addon Details" {...a11yProps(0)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <AddonDetail validationFields={validationSchema.fields} />
                  </TabPanel>
                </Box>

                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddAddon;
