import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { IpAddressProvider } from "../contexts/IpAddressContext";
import { DialogProvider } from "../contexts/DialogContext";
import { NasProvider } from "../contexts/NasContext";
import { InterfaceProvider } from "../contexts/InterfaceContext";

export const IpAddressComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <IpAddressProvider key={0} />,
        <NasProvider key={1} />,
        <InterfaceProvider key={2} />,
        <DialogProvider key={3} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
