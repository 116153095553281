export const DepartmentConstants = {
  FETCH_DEPARTMENT_REQUEST: "FETCH_DEPARTMENT_REQUEST",
  FETCH_DEPARTMENT_SUCCESS: "FETCH_DEPARTMENT_SUCCESS",
  FETCH_DEPARTMENT_FAILURE: "FETCH_DEPARTMENT_FAILURE",
  ADD_DEPARTMENT_REQUEST: "ADD_DEPARTMENT_REQUEST",
  ADD_DEPARTMENT_SUCCESS: "ADD_DEPARTMENT_SUCCESS",
  ADD_DEPARTMENT_FAILURE: "ADD_DEPARTMENT_FAILURE",
  DEPARTMENT_SEARCH_REQUEST: "DEPARTMENT_SEARCH_REQUEST",
  DEPARTMENT_SEARCH_SUCCESS: "DEPARTMENT_SEARCH_SUCCESS",
  DEPARTMENT_SEARCH_FAILURE: "DEPARTMENT_SEARCH_FAILURE",
  GET_DEPARTMENT_REQUEST: "GET_DEPARTMENT_REQUEST",
  GET_DEPARTMENT_SUCCESS: "GET_DEPARTMENT_SUCCESS",
  GET_DEPARTMENT_FAILURE: "GET_DEPARTMENT_FAILURE",
  UPDATE_DEPARTMENT_REQUEST: "UPDATE_DEPARTMENT_REQUEST",
  UPDATE_DEPARTMENT_SUCCESS: "UPDATE_DEPARTMENT_SUCCESS",
  UPDATE_DEPARTMENT_FAILURE: "UPDATE_DEPARTMENT_FAILURE",
  DOWNLOAD_DEPARTMENT_REQUEST: "DOWNLOAD_DEPARTMENT_REQUEST",
  DOWNLOAD_DEPARTMENT_SUCCESS: "DOWNLOAD_DEPARTMENT_SUCCESS",
  DOWNLOAD_DEPARTMENT_FAILURE: "DOWNLOAD_DEPARTMENT_FAILURE",
  UPLOAD_DEPARTMENT_REQUEST: "UPLOAD_DEPARTMENT_REQUEST",
  UPLOAD_DEPARTMENT_SUCCESS: "UPLOAD_DEPARTMENT_SUCCESS",
  UPLOAD_DEPARTMENT_FAILURE: "UPLOAD_DEPARTMENT_FAILURE",
};
