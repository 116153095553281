// import { useField } from "formik";
// import { useTranslation } from "react-i18next";
// import { TextField, Typography } from "@mui/material";

// const CustomTextField = ({ label, handleChange, register, errors, ...props }) => {
//   const { t } = useTranslation();

//   return (
//     <>
//       <TextField
//         name={props.id || props.name}
//         label={t(label)}
//         fullWidth
//         variant="outlined"
//         {...props}
//         {...register(props.name)}
//         error={errors[props.name] ? true : false}
//         InputLabelProps={{ shrink: true }}
//       />
//       {errors[props.name]?.message && (
//         <Typography variant="inherit" color="textSecondary">
//           {errors[props.name]?.message}
//         </Typography>
//       )}
//     </>
//   );
// };

// export default CustomTextField;

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

const useStyles = styled((theme) => ({
  text: {
    color: "#d32f2f",
  },
}));

const CustomTextField = ({
  name,
  label,
  control,
  type,
  errors,
  fields,
  disabled,
  helperText,
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const labelId = `${name}-label`;
  //console.log(errors, name, "errorserrors");

  const nameSplit = name.split(".");

  console.log(errors, name, nameSplit, fields, "errorserrors");
  console.log(errors, "errorName*****");

  let errorName = errors[name];

  if (nameSplit.length == 3 && Object.keys(errors).length != 0) {
    errorName = errors[nameSplit[0]]?.[Number(nameSplit[1])]?.[nameSplit[2]];
  }
  console.log(fields?.[name], "errorName****sss*");

  let required = false;
  //console.log(props, "requiredrequiredrequiredrrr");
  if (fields?.[name]) {
    required = true;
  }

  return (
    <FormControl {...props}>
      <Controller
        render={({ field }) => (
          <>
            <TextField
              autoComplete="new-password"
              name={props.id || name}
              label={t(label)}
              variant="outlined"
              type={type == undefined ? "text" : type}
              {...field}
              error={errorName ? true : false}
              InputLabelProps={{
                shrink: field.value?.toString() ? true : false,
                required: required,
                autoComplete: "new-password",
              }}
              helperText={helperText}
              disabled={disabled !== undefined ? disabled : false}
              multiline={props.multiline !== undefined ? true : false}
              rows={props.rows !== undefined ? props.rows : 0}
            />
            {errorName && <FormHelperText error>{errorName.message}</FormHelperText>}
          </>
        )}
        name={name}
        control={control}
      />
    </FormControl>
  );
};
export default CustomTextField;
