import { useContext } from "react";

import { TalukContext } from "../contexts/TalukContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useTaluk = () => {
  const context = useContext(TalukContext);

  if (!context) throw new Error("TalukContext must be placed within TalukProvider");

  return context;
};

export default useTaluk;
