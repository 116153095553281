import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import EmailLogReducer from "../reducers/EmailLogReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { EmailLogConstants } from "../constants/emaillog.constants";
import { saveFile } from "../utils/common";
const initialState = {
  emailLogs: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  emailLogSearchResults: [],
  emailLog: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  emailLogAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const EmailLogContext = createContext({
  ...initialState,
  getAllEmailLog: () => {},
  addEmailLog: () => {},
  searchEmailLog: () => {},
  autoSearchEmailLog: () => {},
  getEmailLog: () => {},
  updateEmailLog: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function EmailLogProvider({ children }) {
  const [state, dispatch] = useReducer(EmailLogReducer, initialState);

  const getAllEmailLog = async (payload) => {
    try {
      dispatch({
        type: EmailLogConstants.FETCH_EMAILLOG_REQUEST,
      });
      const response = await axios.post("/logs/email-logs/list", payload);
      dispatch({
        type: EmailLogConstants.FETCH_EMAILLOG_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: EmailLogConstants.FETCH_EMAILLOG_FAILURE,
      });
    }
  };
  const searchEmailLog = async (payload) => {
    try {
      const response = await axios.post("/logs/email-logs/search", payload);
      dispatch({
        type: EmailLogConstants.EMAILLOG_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: EmailLogConstants.EMAILLOG_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: EmailLogConstants.EMAILLOG_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchEmailLog = async (payload) => {
    try {
      const response = await axios.post("/logs/email-logs/auto-search", payload);
      dispatch({
        type: EmailLogConstants.EMAILLOG_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: EmailLogConstants.EMAILLOG_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: EmailLogConstants.EMAILLOG_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const getEmailLog = async (id) => {
    const response = await axios.get("/logs/email-logs/" + id);
    dispatch({
      type: EmailLogConstants.GET_EMAILLOG_REQUEST,
    });
    dispatch({
      type: EmailLogConstants.GET_EMAILLOG_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: EmailLogConstants.DOWNLOAD_EMAILLOG_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/logs/email-logs/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "email-log." + fileType);

    dispatch({
      type: EmailLogConstants.DOWNLOAD_EMAILLOG_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <EmailLogContext.Provider
      value={{
        ...state,
        getAllEmailLog,
        searchEmailLog,
        autoSearchEmailLog,
        getEmailLog,
        downloadFile,
      }}
    >
      {children}
    </EmailLogContext.Provider>
  );
}

export { EmailLogContext, EmailLogProvider };
