export const OperatorConstants = {
  string: {
    eq: "Equal",
    neq: "Not Equal",
    like: "like",
  },
  int: {
    eq: "Equal",
    neq: "Not Equal",
    gt: "Greater Than",
    gte: "Greater Than or Equal To",
    lt: "Less Than",
    lte: "Less Than or Equal To",
  },
  date: {
    btn: "Between",
  },
  dropdown: {
    eq: "Equal",
    neq: "Not Equal",
    in: "In",
    nin: "Not In",
  },
};
