import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { LoginHistoryProvider } from "../contexts/LoginHistoryContext";
import { DialogProvider } from "../contexts/DialogContext";

export const LoginHistoryComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<LoginHistoryProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};

export default LoginHistoryComposer;
