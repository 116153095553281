import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import CityReducer from "../reducers/CityReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { CityConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  states: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  city: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  citySearchResults: [],
  isDownload: false,
  isLoadingAutoSearch: false,
  cityAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const CityContext = createContext({
  ...initialState,
  getAllCity: () => {},
  addCity: () => {},
  editCity: () => {},
  searchCity: () => {},
  autoSearchCity: () => {},
  getCity: () => {},
  updateCity: () => {},
  downloadFile: () => {},
});

function CityProvider({ children }) {
  const [state, dispatch] = useReducer(CityReducer, initialState);

  const getAllCity = async (payload) => {
    try {
      dispatch({
        type: CityConstants.FETCH_CITY_REQUEST,
      });
      const response = await axios.post("/master/city/list", payload);

      dispatch({
        type: CityConstants.FETCH_CITY_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: CityConstants.FETCH_CITY_FAILURE,
      });
    }
  };
  const addCity = async (payload) => {
    const response = await axios.post("/master/city/add", payload);
    dispatch({
      type: CityConstants.ADD_CITY_REQUEST,
    });
    dispatch({
      type: CityConstants.ADD_CITY_SUCCESS,
      payload: response.data,
    });
  };
  const editCity = async (payload) => {
    console.log("1111111111111111", payload);
    const response = await axios.put("/master/city/update/" + payload.cityId, payload);
    dispatch({
      type: CityConstants.EDIT_CITY_REQUEST,
    });
    dispatch({
      type: CityConstants.EDIT_CITY_SUCCESS,
      payload: response.data,
    });
  };
  const searchCity = async (payload) => {
    try {
      const response = await axios.post("/master/city/search", payload);
      dispatch({
        type: CityConstants.CITY_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: CityConstants.CITY_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: CityConstants.CITY_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchCity = async (payload) => {
    try {
      const response = await axios.post("/master/city/auto-search", payload);
      dispatch({
        type: CityConstants.CITY_AUTO_SEARCH_REQUEST,
      });
      dispatch({
        type: CityConstants.CITY_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: CityConstants.CITY_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const getCity = async (id) => {
    console.log(id, "aaaaaaaaaa");
    const response = await axios.get("/master/city/" + id);
    dispatch({
      type: CityConstants.GET_CITY_REQUEST,
    });
    dispatch({
      type: CityConstants.GET_CITY_SUCCESS,
      payload: response.data,
    });
  };

  const updateCity = async (payload) => {
    console.log(payload, "Upatetaluk");
    const response = await axios.put("/master/city/update/" + payload.cityId, payload);
    dispatch({
      type: CityConstants.UPDATE_CITY_REQUEST,
    });
    dispatch({
      type: CityConstants.UPDATE_CITY_SUCCESS,
      payload: response.data,
    });
  };
  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: CityConstants.DOWNLOAD_CITY_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/master/city/export/" + fileType, payload, {
      responseType: "blob",
    });
    //console.log(response);
    await saveFile(response.data, "city." + fileType);
    dispatch({
      type: CityConstants.DOWNLOAD_CITY_SUCCESS,
      payload: response.data,
    });
  };
  const sampleFile = async (fileType) => {
    const response = await axios.get("/master/city/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_city." + fileType);
    dispatch({
      type: CityConstants.DOWNLOAD_CITY_REQUEST,
    });
    dispatch({
      type: CityConstants.DOWNLOAD_CITY_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: CityConstants.UPLOAD_CITY_REQUEST,
      });
      const response = await axios({
        url: "/master/city/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: CityConstants.UPLOAD_CITY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: CityConstants.UPLOAD_CITY_FAILURE,
      });
    }
  };

  return (
    <CityContext.Provider
      value={{
        ...state,
        getAllCity,
        addCity,
        editCity,
        searchCity,
        getCity,
        autoSearchCity,
        updateCity,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </CityContext.Provider>
  );
}

export { CityContext, CityProvider };
