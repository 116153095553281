import { RateConstants } from "../constants/rate.constants";

const RateReducer = (state, action) => {
  switch (action.type) {
    case RateConstants.FETCH_RATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RateConstants.FETCH_RATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rates: action.payload.data,
        paging: action.payload.paging,
      };
    case RateConstants.FETCH_RATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case RateConstants.RATE_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RateConstants.RATE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rateSearchResults: action.payload,
      };
    case RateConstants.RATE_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case RateConstants.RATE_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        autoSearchLoading: true,
        hasError: false,
      };
    case RateConstants.RATE_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        autoSearchLoading: false,
        rateAutoSearchResults: action.payload,
      };
    case RateConstants.RATE_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        autoSearchLoading: false,
      };

    case RateConstants.ADD_RATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RateConstants.ADD_RATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case RateConstants.ADD_RATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case RateConstants.GET_RATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RateConstants.GET_RATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rate: action.payload,
      };
    case RateConstants.GET_RATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case RateConstants.UPDATE_RATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RateConstants.UPDATE_RATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rate: action.payload,
      };
    case RateConstants.UPDATE_RATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case RateConstants.DOWNLOAD_RATE_REQUEST:
      return {
        ...state,
        isDownload: true,
        hasError: false,
      };
    case RateConstants.DOWNLOAD_RATE_SUCCESS:
      return {
        ...state,
        isDownload: false,
      };
    case RateConstants.DOWNLOAD_RATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isDownload: false,
      };
    case RateConstants.UPLOAD_RATE_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case RateConstants.UPLOAD_RATE_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case RateConstants.UPLOAD_RATE_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default RateReducer;
