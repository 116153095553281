import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { ComplaintTypeProvider } from "../contexts/ComplaintTypeContext";
import { DialogProvider } from "../contexts/DialogContext";

export const ComplaintTypeComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<ComplaintTypeProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};
