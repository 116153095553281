import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Formik, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";

import SaveCancel from "../../../components/SaveCancel";
import useDepartment from "../../../hooks/useDepartment";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import BasicDetail from "./components/BasicDetail";
import { useFormState, useFormDispatch } from "../../../contexts/DepartmentFormContext";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  departmentName: Yup.string().required("Required"),
  Tanent: Yup.string().required("Required"),
  branchName: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function AddDepartment() {
  const initialValues = {
    Tanent: "",
    branchName: "",
    departmentName: "",
    status: "ACTIVE",
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  React.useEffect(() => {
    console.log(errors, "ddddddd");
  }, [errors]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });
  const { t } = useTranslation();
  const { addDepartment, hasError, errorMessage } = useDepartment();
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>C-Panal</Typography>
        <Typography>Department</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  const onSubmit = async (data) => {
    try {
      await addDepartment(data);
      setStatus({ sent: true });
      reset();
    } catch (err) {
      setStatus({ sent: false });
      setErrorSubmit({ submit: err.message });
    }
  };

  const cancelBtn = () => {
    navigate("/cpanel/department/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form>
          <Card mb={6}>
            <CardHeader title={t("add_department")} subheader={breadCrump()} />
            <CardContent>
              {status && status.sent && (
                <Alert severity="success" my={3}>
                  Department added successfully!
                </Alert>
              )}

              {errorSubmit && errorSubmit.submit && (
                <Alert severity="error" my={3}>
                  {errorSubmit.submit}
                </Alert>
              )}

              {isSubmitting ? (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleTabChange} aria-label="Department">
                      <Tab label="Basic Details" {...a11yProps(0)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <BasicDetail />
                    </TabPanel>
                  </Box>

                  <SaveCancel cancelBtn={cancelBtn} handleSubmit={handleSubmit(onSubmit)} />
                </form>
              )}
            </CardContent>
          </Card>
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddDepartment;
