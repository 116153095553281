import { useContext } from "react";

import { KycDocumentContext } from "../contexts/KycDocumentContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useKycDocument = () => {
  const context = useContext(KycDocumentContext);

  if (!context) throw new Error("KycDocumentConstants must be placed within KycDocumentsProvider");

  return context;
};

export default useKycDocument;
