//Masters

import TenantList from "../pages/cpanel/tenant";
import AddTenant from "../pages/cpanel/tenant/add";
import EditTenant from "../pages/cpanel/tenant/edit";
import BranchList from "../pages/cpanel/branch";
import AddBranch from "../pages/cpanel/branch/add";
import EditBranch from "../pages/cpanel/branch/edit";
import DepartmentList from "../pages/cpanel/department";
import AddDepartment from "../pages/cpanel/department/add";
import EditDepartment from "../pages/cpanel/department/edit";

import TenantSharing from "../pages/cpanel/tenant_sharing";

//Composers

import { TenantComposer } from "../composers/TenantComposer";
import { BranchComposer } from "../composers/BranchComposer";
import { EmployeeComposer } from "../composers/EmployeeComposer";

import { DepartmentComposer } from "../composers/DepartmentComposer";

import { TenantSharingComposer } from "../composers/TenantSharingComposer";

import { DashboardComposer } from "../composers/DashboardComposer";
// Guards
import AuthGuard from "../components/guards/AuthGuard";
import DashboardLayout from "../layouts/Dashboard";
import AddEmployee from "../pages/cpanel/employee/add";
import CustomDataGrid from "../pages/cpanel/employee/components/CustomDataGrid";
import EditEmployee from "../pages/cpanel/employee/edit";
import EmployeeList from "../pages/cpanel/employee";
import TenantDetailPage from "../pages/cpanel/tenant/TenantDetailPage";

const CpanelRoutes = {
  path: "cpanel",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "tenant",
      children: [
        {
          path: "add",
          element: (
            <TenantComposer>
              <AddTenant />
            </TenantComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <TenantComposer>
              <EditTenant />
            </TenantComposer>
          ),
        },
        {
          path: "list",
          element: (
            <TenantComposer>
              <TenantList />
            </TenantComposer>
          ),
        },
        {
          path: "details",
          element: (
            <TenantComposer>
              <TenantDetailPage loadingFrom="normalPage" />
            </TenantComposer>
          ),
        },
      ],
    },
    {
      path: "branch",
      children: [
        {
          path: "add",
          element: (
            <BranchComposer>
              <AddBranch />
            </BranchComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <BranchComposer>
              <EditBranch />
            </BranchComposer>
          ),
        },
        {
          path: "list",
          element: (
            <BranchComposer>
              <BranchList />
            </BranchComposer>
          ),
        },
      ],
    },
    {
      path: "department",
      children: [
        {
          path: "add",
          element: (
            <DepartmentComposer>
              <AddDepartment />
            </DepartmentComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <DepartmentComposer>
              <EditDepartment />
            </DepartmentComposer>
          ),
        },
        {
          path: "list",
          element: (
            <DepartmentComposer>
              <DepartmentList />
            </DepartmentComposer>
          ),
        },
      ],
    },

    {
      path: "sharing",
      children: [
        {
          path: "list",
          element: (
            <TenantSharingComposer>
              <TenantSharing />
            </TenantSharingComposer>
          ),
        },
      ],
    },
    {
      path: "employee",
      children: [
        {
          path: "add",
          element: (
            <EmployeeComposer>
              <AddEmployee />
            </EmployeeComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <EmployeeComposer>
              <EditEmployee />
            </EmployeeComposer>
          ),
        },
        {
          path: "list",
          element: (
            <EmployeeComposer>
              <EmployeeList />
            </EmployeeComposer>
          ),
        },
      ],
    },
  ],
};

export default CpanelRoutes;
