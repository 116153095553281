import { useContext } from "react";

import { PlanGroupContext } from "../contexts/PlanGroupContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const usePlanGroup = () => {
  const context = useContext(PlanGroupContext);

  if (!context) throw new Error("Plan GroupContext must be placed within sharing group provider");

  return context;
};

export default usePlanGroup;
