import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { TenantSharingProvider } from "../contexts/TenantSharingContext";
import { TenantProvider } from "../contexts/TenantContext";

export const TenantSharingComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<TenantSharingProvider key={0} />, <TenantProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};
