export const IspConstants = {
  FETCH_ISP_REQUEST: "FETCH_ISP_REQUEST",
  FETCH_ISP_SUCCESS: "FETCH_ISP_SUCCESS",
  FETCH_ISP_FAILURE: "FETCH_ISP_FAILURE",
  ADD_ISP_REQUEST: "ADD_ISP_REQUEST",
  ADD_ISP_SUCCESS: "ADD_ISP_SUCCESS",
  ADD_ISP_FAILURE: "ADD_ISP_FAILURE",
  ISP_SEARCH_REQUEST: "ISP_SEARCH_REQUEST",
  ISP_SEARCH_SUCCESS: "ISP_SEARCH_SUCCESS",
  ISP_SEARCH_FAILURE: "ISP_SEARCH_FAILURE",
  GET_ISP_REQUEST: "GET_ISP_REQUEST",
  GET_ISP_SUCCESS: "GET_ISP_SUCCESS",
  GET_ISP_FAILURE: "GET_ISP_FAILURE",
  UPDATE_ISP_REQUEST: "UPDATE_ISP_REQUEST",
  UPDATE_ISP_SUCCESS: "UPDATE_ISP_SUCCESS",
  UPDATE_ISP_FAILURE: "UPDATE_ISP_FAILURE",
  DOWNLOAD_ISP_REQUEST: "DOWNLOAD_ISP_REQUEST",
  DOWNLOAD_ISP_SUCCESS: "DOWNLOAD_ISP_SUCCESS",
  DOWNLOAD_ISP_FAILURE: "DOWNLOAD_ISP_FAILURE",
  UPLOAD_ISP_REQUEST: "UPLOAD_ISP_REQUEST",
  UPLOAD_ISP_SUCCESS: "UPLOAD_ISP_SUCCESS",
  UPLOAD_ISP_FAILURE: "UPLOAD_ISP_FAILURE",
  ADD_ISP_POLICY_REQUEST: "ADD_ISP_POLICY_REQUEST",
  ADD_ISP_POLICY_SUCCESS: "ADD_ISP_POLICY_SUCCESS",
  ADD_ISP_POLICY_FAILURE: "ADD_ISP_POLICY_FAILURE",
  ADD_ISP_IPPOOL_REQUEST: "ADD_ISP_IPPOOL_REQUEST",
  ADD_ISP_IPPOOL_SUCCESS: "ADD_ISP_IPPOOL_SUCCESS",
  ADD_ISP_IPPOOL_FAILURE: "ADD_ISP_IPPOOL_FAILURE",
  FETCH_ISP_POLICY_REQUEST: "FETCH_ISP_POLICY_REQUEST",
  FETCH_ISP_POLICY_SUCCESS: "FETCH_ISP_POLICY_SUCCESS",
  FETCH_ISP_POLICY_FAILURE: "FETCH_ISP_POLICY_FAILURE",
  FETCH_ISP_IPPOOL_REQUEST: "FETCH_ISP_IPPOOL_REQUEST",
  FETCH_ISP_IPPOOL_SUCCESS: "FETCH_ISP_IPPOOL_SUCCESS",
  FETCH_ISP_IPPOOL_FAILURE: "FETCH_ISP_IPPOOL_FAILURE",
  ISP_AUTO_SEARCH_REQUEST: "ISP_AUTO_SEARCH_REQUEST",
  ISP_AUTO_SEARCH_SUCCESS: "ISP_AUTO_SEARCH_SUCCESS",
  ISP_AUTO_SEARCH_FAILURE: "ISP_AUTO_SEARCH_FAILURE",
};
