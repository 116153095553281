import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar, showErrorSnackbar } from "../../redux/slices/snackbar";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";

import SaveCancel from "../../components/SaveCancel";
import useComplaintType from "../../hooks/useComplaintType";
import ComplaintTypeFields from "../complaint_type/components/ComplaintTypeFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  complaintTypeName: Yup.string().required("Required"),
  notes: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function EditcomplaintType() {
  const dispatch = useDispatch();

  const { getComplaintType, complaintType, updateComplaintType, isSubmitting } = useComplaintType();

  React.useEffect(() => {
    if (Object.keys(complaintType).length !== 0) {
      console.log(complaintType.status, "ComplaintType.status");
      const initialValues = {
        complaintTypeId: complaintType.complaintTypeId,
        complaintTypeName: complaintType.complaintTypeName,
        notes: complaintType.notes,
        status: complaintType.status,
      };
      reset(initialValues);
    }
  }, [complaintType]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const [currentComplaintType, setCurrentComplaintType] = useState(null);
  const { state } = useLocation();
  console.log(state, "location");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      await updateComplaintType(data);
      navigate("/complaints/type/list");
      dispatch(showSuccessSnackbar("Complaint type edited successfully!"));
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to edit complaint type"));
    }
  };

  const cancelBtn = () => {
    navigate("/complaints/type/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Masters</Typography>
        <Typography>ComplaintType</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getComplaintType(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_complaint_type")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <ComplaintTypeFields />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditcomplaintType;
