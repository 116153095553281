//Users
import UserList from "../pages/users/userlist";
import AddUser from "../pages/users/userlist/add";
import EditUser from "../pages/users/userlist/edit";
import OnlineUsers from "../pages/users/online";
import InvoiceDetails from "../components/InvoiceDetails";
import Migration from "../pages/users/migration";
import ActiveUsers from "../pages/users/active_users";
import UserDetails from "../pages/users/user_details";
import ExpiredUsers from "../pages/users/expired_users";
import SuspendedUsers from "../pages/users/suspended_users";
import SessionHistory from "../pages/session_history/index.js";

//Composers
import { UserComposer } from "../composers/UserComposer";
import { MigrationComposer } from "../composers/MigrationComposer";
import { DashboardComposer } from "../composers/DashboardComposer";
import { SessionHistoryComposer } from "../composers/SessionHistoryComposer";
import { ScheduleUserComposer } from "../composers/ScheduleUserComposer";

// Guards
import AuthGuard from "../components/guards/AuthGuard";
import DashboardLayout from "../layouts/Dashboard";
import ScheduleUser from "../pages/users/user_details/ScheduleUser.js";

const UserRoutes = {
  path: "user",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "userlist",
      children: [
        {
          path: "add",
          element: (
            <UserComposer>
              <AddUser />
            </UserComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <UserComposer>
              <EditUser />
            </UserComposer>
          ),
        },
        {
          path: "list",
          element: (
            <UserComposer>
              <UserList />
            </UserComposer>
          ),
        },
      ],
    },
    {
      path: "active",
      element: (
        <UserComposer>
          <ActiveUsers />
        </UserComposer>
      ),
    },
    {
      path: "details",
      element: (
        <UserComposer>
          <UserDetails loadingFrom="normalPage" />
        </UserComposer>
      ),
    },
    {
      path: "online",
      element: (
        <UserComposer>
          <OnlineUsers />
        </UserComposer>
      ),
    },
    {
      path: "expired",
      element: (
        <UserComposer>
          <ExpiredUsers />
        </UserComposer>
      ),
    },
    {
      path: "suspended",
      element: (
        <UserComposer>
          <SuspendedUsers />
        </UserComposer>
      ),
    },
    {
      path: "migration",
      element: (
        <MigrationComposer>
          <Migration />
        </MigrationComposer>
      ),
    },
    {
      path: "invoice",
      children: [
        {
          path: "view",
          element: (
            <UserComposer>
              <InvoiceDetails />
            </UserComposer>
          ),
        },
      ],
    },
    {
      children: [
        {
          path: "session-history",
          element: (
            <SessionHistoryComposer>
              <SessionHistory />
            </SessionHistoryComposer>
          ),
        },
        {
          path: "schedule-user",
          element: (
            <ScheduleUserComposer>
              <ScheduleUser />
            </ScheduleUserComposer>
          ),
        },
      ],
    },
  ],
};

export default UserRoutes;
