import { TrafficReportConstants } from "../constants/trafficreport.constants";

const TrafficReportReducer = (state, action) => {
  switch (action.type) {
    case TrafficReportConstants.FETCH_TRAFFIC_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TrafficReportConstants.FETCH_TRAFFIC_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        trafficReports: action.payload.data,
        paging: action.payload.paging,
      };
    case TrafficReportConstants.FETCH_TRAFFIC_REPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TrafficReportConstants.TRAFFIC_REPORT_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TrafficReportConstants.TRAFFIC_REPORT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        trafficReportSearchResults: action.payload,
      };
    case TrafficReportConstants.TRAFFIC_REPORT_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TrafficReportConstants.TRAFFIC_REPORT_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TrafficReportConstants.TRAFFIC_REPORT_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        trafficReportAutoSearchResults: action.payload,
      };
    case TrafficReportConstants.TRAFFIC_REPORT_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TrafficReportConstants.ADD_TRAFFIC_REPORT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case TrafficReportConstants.ADD_TRAFFIC_REPORT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case TrafficReportConstants.ADD_TRAFFIC_REPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case TrafficReportConstants.GET_TRAFFIC_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TrafficReportConstants.GET_TRAFFIC_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        trafficReport: action.payload,
      };
    case TrafficReportConstants.GET_TRAFFIC_REPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TrafficReportConstants.UPDATE_TRAFFIC_REPORT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case TrafficReportConstants.UPDATE_TRAFFIC_REPORT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        trafficReport: action.payload,
      };
    case TrafficReportConstants.UPDATE_TRAFFIC_REPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case TrafficReportConstants.DOWNLOAD_TRAFFIC_REPORT_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case TrafficReportConstants.DOWNLOAD_TRAFFIC_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case TrafficReportConstants.DOWNLOAD_TRAFFIC_REPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case TrafficReportConstants.UPLOAD_TRAFFIC_REPORT_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case TrafficReportConstants.UPLOAD_TRAFFIC_REPORT_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case TrafficReportConstants.UPLOAD_TRAFFIC_REPORT_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default TrafficReportReducer;
