export const CountryConstants = {
  FETCH_COUNTRY_REQUEST: "FETCH_COUNTRY_REQUEST",
  FETCH_COUNTRY_SUCCESS: "FETCH_COUNTRY_SUCCESS",
  FETCH_COUNTRY_FAILURE: "FETCH_COUNTRY_FAILURE",
  ADD_COUNTRY_REQUEST: "ADD_COUNTRY_REQUEST",
  ADD_COUNTRY_SUCCESS: "ADD_COUNTRY_SUCCESS",
  ADD_COUNTRY_FAILURE: "ADD_COUNTRY_FAILURE",
  COUNTRY_SEARCH_REQUEST: "COUNTRY_SEARCH_REQUEST",
  COUNTRY_SEARCH_SUCCESS: "COUNTRY_SEARCH_SUCCESS",
  COUNTRY_SEARCH_FAILURE: "COUNTRY_SEARCH_FAILURE",
  COUNTRY_AUTO_SEARCH_REQUEST: "COUNTRY_AUTO_SEARCH_REQUEST",
  COUNTRY_AUTO_SEARCH_SUCCESS: "COUNTRY_AUTO_SEARCH_SUCCESS",
  COUNTRY_AUTO_SEARCH_FAILURE: "COUNTRY_AUTO_SEARCH_FAILURE",
  GET_COUNTRY_REQUEST: "GET_COUNTRY_REQUEST",
  GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_COUNTRY_FAILURE: "GET_COUNTRY_FAILURE",
  UPDATE_COUNTRY_REQUEST: "UPDATE_COUNTRY_REQUEST",
  UPDATE_COUNTRY_SUCCESS: "UPDATE_COUNTRY_SUCCESS",
  UPDATE_COUNTRY_FAILURE: "UPDATE_COUNTRY_FAILURE",
  DOWNLOAD_COUNTRY_REQUEST: "DOWNLOAD_COUNTRY_REQUEST",
  DOWNLOAD_COUNTRY_SUCCESS: "DOWNLOAD_COUNTRY_SUCCESS",
  DOWNLOAD_COUNTRY_FAILURE: "DOWNLOAD_COUNTRY_FAILURE",
  UPLOAD_COUNTRY_REQUEST: "UPLOAD_COUNTRY_REQUEST",
  UPLOAD_COUNTRY_SUCCESS: "UPLOAD_COUNTRY_SUCCESS",
  UPLOAD_COUNTRY_FAILURE: "UPLOAD_COUNTRY_FAILURE",
};
